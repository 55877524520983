import styled from 'styled-components/macro';
import { lighten } from 'polished';

import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as Done } from 'assets/icons/done.svg';
import { ReactComponent as Error } from 'assets/icons/warning.svg';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';

type DocumentUploaderProps = {
  uploaderStage?: string;
  error?: string;
};

export const UploadWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.default};
`;

export const UploadCompleteWrapper = styled(UploadWrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: ${(props) => props.theme.fonts.secondary};
`;

const getUploaderGrid = (isUploading?: boolean) => {
  if (isUploading) {
    return `
      margin: 0 auto;
      max-width: 240px;
      border-top: 0;
    `;
  }

  return ``;
};

const uploaderLayout = (isUploading?: boolean) => {
  if (isUploading) {
    return '1fr';
  }

  return '1fr 1fr';
};

export const UploadModalActions = styled.div<DocumentUploaderProps>`
  padding: ${(props) => props.theme.spacing.default};
  border-top: 1px solid
    ${(props) => lighten(0.5, props.theme.colorPalette.secondary.lightInk)};
  display: grid;
  grid-template-columns: ${(props) =>
    getUploaderGrid(props.uploaderStage === 'DURING_UPLOAD')};
  column-gap: ${(props) => props.theme.spacing.default};

  grid-template-columns: ${(props) =>
    uploaderLayout(props.uploaderStage === 'DURING_UPLOAD')};
`;

export const UploadIcon = styled(withIconStyles(Upload))`
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 24px;
`;

export const UploadFinishedIconWrapper = styled.div<DocumentUploaderProps>`
  background: ${(props) =>
    props.error
      ? props.theme.colorPalette.tertiary.datavizPurple
      : props.theme.colorPalette.tertiary.datavizGreen};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
`;

export const DoneIcon = styled(withIconStyles(Done))`
  fill: ${(props) => props.theme.colorPalette.text.white};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2em;
  height: 2em;
`;

export const ErrorIcon = styled(withIconStyles(Error))`
  fill: ${(props) => props.theme.colorPalette.text.white};
  position: absolute;
  left: 50%;
  top: calc(50% - 2px);
  transform: translate(-50%, -50%);
  width: 2em;
  height: 2em;
`;

export const ErrorWrapper = styled.div`
  padding: 10px;
`;

export const SuccessConfirmationTitle = styled.span`
  font-weight: 700;
  font-size: ${(props) => props.theme.typography.heading.small};
  padding: 10px 0 0;
`;

export const UploadCompleteStatus = styled.p`
  margin: 18px 0;
  font-size: 18px;
  word-break: break-word;
`;

export const UploadFailedNextPrompt = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.typography.paragraph.small};
`;
