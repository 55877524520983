import { SortDirections, SortTypes } from 'components/Table/types';
import { Vertical } from 'globalConstants';
import { VerticalPrimaryUse } from '../types/searchQuery';

export enum SORT_PAGE_ACTION_TYPES {
  UPDATE_PAGINATION = 'UPDATE_PAGINATION',
  UPDATE_SORT = 'UPDATE_SORT',
}

export enum FILTER_ACTION_TYPES {
  SET_VERTICAL = 'SET_VERTICAL',
  SET_PRIMARY_USE = 'SET_PRIMARY_USE',
}

export enum QUERY_URL_ACTION_TYPES {
  SET_QUERY_FROM_URL = 'SET_QUERY_FROM_URL',
}

export type SetVerticalAction = {
  type: FILTER_ACTION_TYPES.SET_VERTICAL;
  payload: Vertical;
};

export const setVertical = (vertical: Vertical): SetVerticalAction => ({
  type: FILTER_ACTION_TYPES.SET_VERTICAL,
  payload: vertical,
});

export type SetQueryFromURLAction = {
  type: QUERY_URL_ACTION_TYPES.SET_QUERY_FROM_URL;
  payload: {};
};

export const setQueryFromURL = (payload: {}): SetQueryFromURLAction => ({
  type: QUERY_URL_ACTION_TYPES.SET_QUERY_FROM_URL,
  payload,
});

export type SetPrimaryUseAction = {
  type: FILTER_ACTION_TYPES.SET_PRIMARY_USE;
  payload: VerticalPrimaryUse;
};

export const setPrimaryUse = (
  primaryUse: VerticalPrimaryUse,
): SetPrimaryUseAction => ({
  type: FILTER_ACTION_TYPES.SET_PRIMARY_USE,
  payload: primaryUse,
});

export type UpdatePagination = {
  type: SORT_PAGE_ACTION_TYPES.UPDATE_PAGINATION;
  payload: {
    pageSize: string;
    page: number;
  };
};

export const updatePagination = (payload: {
  pageSize: string;
  page: number;
}): UpdatePagination => ({
  type: SORT_PAGE_ACTION_TYPES.UPDATE_PAGINATION,
  payload,
});

export type UpdateSort = {
  type: SORT_PAGE_ACTION_TYPES.UPDATE_SORT;
  payload: {
    sortKey: string;
    sortDirection: SortDirections;
    sortType: SortTypes;
  };
};

export const updateSort = (payload: {
  sortKey: string;
  sortDirection: SortDirections;
  sortType: SortTypes;
}): UpdateSort => ({
  type: SORT_PAGE_ACTION_TYPES.UPDATE_SORT,
  payload,
});

export type SearchQueryActions =
  | SetVerticalAction
  | SetPrimaryUseAction
  | UpdateSort
  | UpdatePagination
  | SetQueryFromURLAction;
