export const PAGINATION_DEFAULT_LIMIT_OPTIONS = [
  {
    label: '5',
    value: '5',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: 'All',
    value: 'All',
  },
];

export const PAGINATION_LIMIT_DEFAULTS = [5, 10];

export enum PAGINATION_NAV {
  FIRST = 'first',
  LAST = 'last',
  NEXT = 'next',
  PREV = 'prev',
}

export const PAGINATION_COPY = {
  pagination: {
    show: 'Show',
    all: 'All',
  },
};
