import { RelativeFixedDateTypes } from './types';

export const RELATIVE_DATE_PICKER_COPY = {
  CURRENTLY: 'currently',
  STARTING: {
    HEADING: 'Starting',
    AMOUNT: 'starting_amount',
    PERIOD: 'starting_period',
    LENGTH: 'starting_length',
  },
  ENDING: {
    HEADING: 'Ending',
    AMOUNT: 'ending_amount',
    LENGTH: 'ending_length',
    LATER: 'later',
  },
};

export const AMOUNT_OPTIONS = '0';
export const FINISHING_AMOUNT_OPTIONS = '1';

export const LENGTH_OPTIONS = [
  { value: 'days', label: 'day(s)' },
  { value: 'weeks', label: 'week(s)' },
  { value: 'months', label: 'month(s)' },
  { value: 'years', label: 'year(s)' },
];

export const PERIOD_OPTIONS = [
  { value: 'ago', label: 'ago' },
  { value: 'from-now', label: 'from now' },
];

export const DATE_TYPES = [
  { label: 'between two fixed dates', value: RelativeFixedDateTypes.FIXED },
  { label: 'in a rolling period', value: RelativeFixedDateTypes.ROLLING },
];
