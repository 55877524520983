import React from 'react';
import MultiSelect from 'components/Forms/Inputs/MultiSelect/MultiSelect';

import { OptionProps } from 'connected/FormFlow/types';
import useClauseOptions from './useClauses.json';
import { UseClauseSelectProps } from './types';

const UseClauseSelect: React.FC<UseClauseSelectProps> = (props) => {
  const options = useClauseOptions as OptionProps[];

  return <MultiSelect {...props} options={options} />;
};

export default UseClauseSelect;
