import { lighten } from 'polished';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Information } from 'assets/icons/info.svg';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ReactComponent as Success } from 'assets/icons/check.svg';
import { NOTIFICATION_TYPES } from './types';

const getNotificationTypeStyles = (theme: any, type?: string) => {
  if (type) {
    return css`
      border-left: 4px solid ${theme[type]};
      background: ${lighten(0.4, theme[type])};
    `;
  }

  return ``;
};

export const NotificationPanelWrapper = styled.article<{
  type: NOTIFICATION_TYPES;
}>`
${(props) =>
  getNotificationTypeStyles(
    props.theme.colorPalette.notifications,
    props.type.toLowerCase(),
  )}

padding: ${(props) => props.theme.spacing.small};
display: flex;
flex-direction: row;
font-family: ${(props) => props.theme.fonts.secondary};
border-radius: 4px;
margin: 0 0 ${(props) => props.theme.spacing.default};
`;

export const WarningIcon = styled(Warning)`
  fill: ${(props) => props.theme.colorPalette.notifications.warning};
`;

export const InfoIcon = styled(Information)`
  fill: ${(props) => props.theme.colorPalette.notifications.information};
`;

export const ErrorIcon = styled(Error)`
  fill: ${(props) => props.theme.colorPalette.notifications.error};
`;

export const SuccessIcon = styled(Success)`
  fill: ${(props) => props.theme.colorPalette.branding.white};
  width: 16px;
`;

export const SuccessIconWrapper = styled.span`
  background: ${(props) => props.theme.colorPalette.notifications.success};
  border-radius: 30px;
  height: 22px;
  width: 22px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationContentWrapper = styled.div`
  padding: 0 ${(props) => props.theme.spacing.small};
`;

export const NotificationHeading = styled.h1`
  font-size: ${(props) => props.theme.typography.heading.xsmall};
  margin: 0 0 ${(props) => props.theme.spacing.xsmall};
  font-weight: bold;
`;

export const NotificationParagraph = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  margin: ${(props) => props.theme.spacing.xsmall} 0 0;
`;

export const NotificationBody = styled.div`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  margin: ${(props) => props.theme.spacing.xsmall} 0 0;
`;
