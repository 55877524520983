import { Vertical } from 'globalConstants';
import { GlobalApiFailError } from 'store/actions/types';

export enum EditRecordActionTypes {
  EDIT_RECORD = 'EDIT_RECORD',
  EDIT_MULTIPLE_RECORDS = 'EDIT_MULTIPLE_RECORDS',
  EDIT_RECORD_API_SUCCESS = 'EDIT_RECORD_API_SUCCESS',
  EDIT_RECORD_API_FAIL = 'EDIT_RECORD_API_FAIL',
  ADD_BREAK_REVIEW_EVENT = 'ADD_BREAK_REVIEW_EVENT',
  ADD_BREAK_REVIEW_EVENT_SUCCESS = 'ADD_BREAK_REVIEW_EVENT_SUCCESS',
  ADD_BREAK_REVIEW_EVENT_FAIL = 'ADD_BREAK_REVIEW_EVENT_FAIL',
}

type EditRecordSavePayload = {
  payload: any;
  vertical: Vertical;
};

type EditRecordAction = {
  type: EditRecordActionTypes.EDIT_RECORD;
  payload: EditRecordSavePayload;
};

type EditMultipleRecordsAction = {
  type: EditRecordActionTypes.EDIT_MULTIPLE_RECORDS;
  payload: EditRecordSavePayload;
};

export const editMultipleRecordsSave = (
  payload: EditRecordSavePayload,
): EditMultipleRecordsAction => ({
  type: EditRecordActionTypes.EDIT_MULTIPLE_RECORDS,
  payload,
});

export const editRecordSave = (
  payload: EditRecordSavePayload,
): EditRecordAction => ({
  type: EditRecordActionTypes.EDIT_RECORD,
  payload,
});

export type EditRecordApiSuccessAction = {
  type: EditRecordActionTypes.EDIT_RECORD_API_SUCCESS;
};

export const editRecordApiSuccess = (): EditRecordApiSuccessAction => ({
  type: EditRecordActionTypes.EDIT_RECORD_API_SUCCESS,
});

type EditRecordApiFailAction = {
  type: EditRecordActionTypes.EDIT_RECORD_API_FAIL;
  payload: GlobalApiFailError;
};

export const editRecordApiFail = (
  payload: GlobalApiFailError,
): EditRecordApiFailAction => ({
  type: EditRecordActionTypes.EDIT_RECORD_API_FAIL,
  payload,
});

type AddBreakRentEventPayload = {
  payload: any;
  eventType: string;
};

type AddBreakRentEventAction = {
  type: EditRecordActionTypes.ADD_BREAK_REVIEW_EVENT;
  payload: AddBreakRentEventPayload;
};

export const addBreakRentEvent = (
  payload: AddBreakRentEventPayload,
): AddBreakRentEventAction => ({
  type: EditRecordActionTypes.ADD_BREAK_REVIEW_EVENT,
  payload,
});

export type AddBreakRentEventSuccessAction = {
  type: EditRecordActionTypes.ADD_BREAK_REVIEW_EVENT_SUCCESS;
};

export const addBreakRentEventSuccess = (): AddBreakRentEventSuccessAction => ({
  type: EditRecordActionTypes.ADD_BREAK_REVIEW_EVENT_SUCCESS,
});

type AddBreakRentEventFailAction = {
  type: EditRecordActionTypes.ADD_BREAK_REVIEW_EVENT_FAIL;
  payload: GlobalApiFailError;
};

export const addBreakRentEventFail = (
  payload: GlobalApiFailError,
): AddBreakRentEventFailAction => ({
  type: EditRecordActionTypes.ADD_BREAK_REVIEW_EVENT_FAIL,
  payload,
});
