import { SelectedLease } from 'connected/FormFlow/IdentifyLeases/IdentifyLeases';
import { EDIT_MODAL_TYPE } from 'connected/GlobalModal/constants';
import { Vertical, VerticalSubElement } from 'globalConstants';
import { LeaseRecord } from 'pages/Details/types';

export const payloadTransform = (
  data: any,
  vertical: Vertical,
  modalType: string,
) => {
  if (vertical === Vertical.Sale || vertical === Vertical.PortfolioSale) {
    return {
      saleId: (data.identifyLeases.value[0] as SelectedLease).id,
      relatedBuildings: [
        {
          buildingId:
            data.identifyAddress.value.id ||
            data.identifyAddress.value.entityId,
        },
      ],
      vertical,
    };
  }

  if (
    vertical === Vertical.Building &&
    modalType === EDIT_MODAL_TYPE.MOVE_DEBT
  ) {
    return {
      debtId: (data.identifyLeases.value[0] as SelectedLease).id,
      buildingId:
        data.identifyAddress.value.id || data.identifyAddress.value.entityId,
      vertical,
      subElement: VerticalSubElement.Debt,
    };
  }

  if (
    vertical === Vertical.Building &&
    modalType === EDIT_MODAL_TYPE.MOVE_VALUATION
  ) {
    return {
      valuationId: (data.identifyLeases.value[0] as SelectedLease).id,
      buildingId:
        data.identifyAddress.value.id || data.identifyAddress.value.entityId,
      vertical,
      subElement: VerticalSubElement.Valuation,
    };
  }

  return {
    buildingId:
      data.identifyAddress.value.id || data.identifyAddress.value.entityId,
    [`${vertical}Ids`]: data.identifyLeases.value.map(
      (l: SelectedLease) => l.id,
    ),
    vertical,
  };
};

export const getLeasesInDeal = (
  currentLeaseId?: string,
  leases?: LeaseRecord[],
) => {
  if (!leases || !currentLeaseId) {
    return [];
  }

  const currentLease = leases.find((lease) => lease.leaseId === currentLeaseId);

  if (!currentLease) {
    return [];
  }

  const { demiseParentId } = currentLease;
  return leases?.filter((lease) => {
    return (
      (lease.leaseId !== currentLeaseId &&
        demiseParentId &&
        lease.demiseParentId === demiseParentId) ||
      lease.demiseParentId === currentLeaseId
    );
  });
};
