import AddressCard from 'components/AddressCard/AddressCard';
import { SIDEBAR_CARD_TYPES } from 'connected/FormFlow/FormSidebar/types';
import React from 'react';
import { SidebarCardProps } from '../types';
import MoveRecordSidebarCard from './MoveRecordSidebarCard';

const SidebarCard = ({
  address,
  name,
  content,
  sidebarCardType,
}: SidebarCardProps) => {
  if (sidebarCardType && sidebarCardType === SIDEBAR_CARD_TYPES.MOVE_RECORD) {
    return <MoveRecordSidebarCard />;
  }
  if (content) {
    return <>{content}</>;
  }
  if (address) {
    return <AddressCard isSquare address={address} />;
  }
  if (name) {
    return <span>{name}</span>;
  }
  return null;
};

export default SidebarCard;
