import { Reducer } from 'redux';

import { FilterDefinition } from 'constants/filters/types';
import {
  FilterPanelActionTypes,
  FilterActions,
} from 'store/actions/filterPanelActions';

interface FiltersState {
  activeFilter?: FilterDefinition;
}

export const initialState: FiltersState = {};

const FiltersReducer: Reducer<FiltersState, FilterActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case FilterPanelActionTypes.SET_ACTIVE_FILTER: {
      return {
        ...state,
        activeFilter: action.payload,
      };
    }

    case FilterPanelActionTypes.RESET_ACTIVE_FILTER: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default FiltersReducer;
