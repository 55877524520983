import React, { useState } from 'react';
import RequiredFlag from 'components/Forms/RequiredFlag';
import { LargeCheckboxProps } from './types';
import {
  CheckboxButtonStyled,
  CheckboxGroup,
  CheckboxIcon,
  CheckboxItemStyled,
  CheckboxLabel,
  CheckboxParagraph,
  CheckboxWrapper,
  StyledHeader,
} from './LargeCheckboxStyled';

const LargeCheckbox = ({
  name,
  items,
  handleOnChange,
  isDisabled,
  selectedValue,
  label,
  description,
  validation,
}: LargeCheckboxProps) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    selectedValue ? selectedValue?.split(',') : [],
  );

  const onChange = (checkboxValue: string) => {
    if (selectedCheckboxes && selectedCheckboxes.includes(checkboxValue)) {
      const selectedUpdatedCheckboxes = selectedCheckboxes.filter(
        (checkbox: string) => checkbox !== checkboxValue,
      );
      handleOnChange(selectedUpdatedCheckboxes);
      return setSelectedCheckboxes(selectedUpdatedCheckboxes);
    }
    const updatedCheckboxes = [...selectedCheckboxes, checkboxValue];
    handleOnChange(updatedCheckboxes);
    return setSelectedCheckboxes(updatedCheckboxes);
  };

  return (
    <div>
      <CheckboxGroup id={name}>
        <CheckboxParagraph>
          <StyledHeader>{label}</StyledHeader>
          {description}
          {validation?.isRequired && <RequiredFlag />}
        </CheckboxParagraph>

        <CheckboxWrapper>
          {items.map((item) => (
            <CheckboxItemStyled key={item.value}>
              <CheckboxButtonStyled
                onChange={() => onChange(item.value)}
                name={`${name}[]`}
                defaultChecked={selectedCheckboxes.includes(item.value)}
                id={item.value}
                value={item.value}
                type="checkbox"
              />
              <CheckboxLabel
                isDisabled={
                  !!(isDisabled && !selectedCheckboxes.includes(item.value))
                }
                htmlFor={item.value}
              >
                {item.icon && <CheckboxIcon>{item.icon}</CheckboxIcon>}
                {item.label}
              </CheckboxLabel>
            </CheckboxItemStyled>
          ))}
        </CheckboxWrapper>
      </CheckboxGroup>
    </div>
  );
};

export default LargeCheckbox;
