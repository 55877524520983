import { Reducer } from 'redux';

import {
  PollingNewRecordActions,
  PollingNewRecordActionTypes,
} from 'store/actions/pollingNewRecordActions';
import {
  GlobalModalActionTypes,
  GlobalModalHideAction,
} from 'connected/GlobalModal/types';

import {
  NewPortfolioSaleApiSuccessAction,
  NewSaleActionTypes,
  NewSaleApiSuccessAction,
} from 'pages/NewSale/types';
import {
  NewLeaseActionTypes,
  NewLeaseApiSuccessAction,
} from 'pages/NewLease/types';
import { MODAL_ID } from 'connected/GlobalModal/constants';

interface PollingNewRecordState {
  polling: boolean;
  success: 'success' | 'undetermined' | 'fail' | 'null';
}

export const initialState: PollingNewRecordState = {
  polling: false,
  success: 'null',
};

type PollingUpdateActions =
  | NewLeaseApiSuccessAction
  | NewSaleApiSuccessAction
  | NewPortfolioSaleApiSuccessAction
  | PollingNewRecordActions
  | GlobalModalHideAction;

const pollingUpdate: Reducer<PollingNewRecordState, PollingUpdateActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case NewLeaseActionTypes.NEW_LEASE_API_SUCCESS:
    case NewSaleActionTypes.NEW_SALE_API_SUCCESS:
    case NewSaleActionTypes.NEW_PORTFOLIO_SALE_API_SUCCESS: {
      return {
        ...state,
        polling: true,
      };
    }

    case PollingNewRecordActionTypes.POLLING_NEW_RECORD_SUCCESS: {
      return {
        ...state,
        polling: false,
        success: 'success',
      };
    }

    case PollingNewRecordActionTypes.POLLING_NEW_RECORD_UNKNOWN: {
      return {
        ...state,
        polling: false,
        success: 'undetermined',
      };
    }

    case PollingNewRecordActionTypes.POLLING_NEW_RECORD_FAIL: {
      return {
        ...state,
        polling: false,
        success: 'fail',
      };
    }

    case GlobalModalActionTypes.HIDE_MODAL: {
      if (action.payload === MODAL_ID.NEW_RECORD_THANKS) {
        return initialState;
      }

      return state;
    }

    default:
      return state;
  }
};

export default pollingUpdate;
