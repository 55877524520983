import { Vertical } from 'globalConstants';

export enum TableConfigActionsTypes {
  UPDATE_TABLE_HEADER_CONFIG = 'UPDATE_TABLE_HEADER_CONFIG',
}

export type UpdateTableHeaderConfigAction = {
  type: TableConfigActionsTypes.UPDATE_TABLE_HEADER_CONFIG;
  payload: any;
};

export const updateTableHeaderConfigAction = (payload: {
  vertical: Vertical;
  headers: any;
}): UpdateTableHeaderConfigAction => ({
  type: TableConfigActionsTypes.UPDATE_TABLE_HEADER_CONFIG,
  payload,
});

export type TableConfigActions = UpdateTableHeaderConfigAction;
