import React from 'react';
import { NegativeActionButton } from 'components/CallToActions/Button/Button';
import { DOCUMENT_UPLOADER_COPY } from './constants';

const DocumentUploaderCancelButton = ({
  cancelUpload,
}: {
  cancelUpload: (event: any) => void;
}) => (
  <NegativeActionButton type="button" onClick={cancelUpload}>
    {DOCUMENT_UPLOADER_COPY.cancelButton}
  </NegativeActionButton>
);

export default DocumentUploaderCancelButton;
