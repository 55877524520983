export enum ExportSearchResults {
  Start = 'EXPORT_SEARCH_RESULTS_START',
  Completed = 'EXPORT_SEARCH_RESULTS_COMPLETED',
  Failed = 'EXPORT_SEARCH_RESULTS_FAILED',
}

export type ExportSearchResultsAction = {
  type: ExportSearchResults;
};

export const exportSearchResults = (
  type: ExportSearchResults,
): ExportSearchResultsAction => ({
  type,
});

export enum ExportSearchResultsDocX {
  Start = 'EXPORT_SEARCH_RESULTS_DOCX_START',
  Completed = 'EXPORT_SEARCH_RESULTS_DOCX_COMPLETED',
  Failed = 'EXPORT_SEARCH_RESULTS_DOCX_FAILED',
}

export type ExportSearchResultsDocXAction = {
  type: ExportSearchResultsDocX;
};

export const exportSearchResultsDocX = (
  type: ExportSearchResultsDocX,
): ExportSearchResultsDocXAction => ({
  type,
});
