import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';

import { MapActionTypes } from 'pages/QueryBuilder/types';
import {
  mapFetchBoundaryApiSuccess,
  mapFetchBoundaryApiFail,
} from 'store/actions/mapsActions';
import { parseResponseToMapBoundaries } from 'services/savillsBoundary';

const boundariesEpic: Epic = (action$) => {
  return action$.pipe(
    ofType(MapActionTypes.MAP_FETCH_BOUNDARY),
    switchMap((action) => {
      const { id: boundaryId } = action.payload;

      return ajax
        .getJSON<[{ to_json: string }]>(
          `https://boundaries.athena-savills.com/Boundaries/${boundaryId}.geojson`,
        )
        .pipe(
          map(([{ to_json: geojson }]) => {
            const shape = JSON.parse(geojson);
            const payload = parseResponseToMapBoundaries(shape, boundaryId);

            return mapFetchBoundaryApiSuccess(payload);
          }),
        );
    }),
    catchError((error) => {
      return of(mapFetchBoundaryApiFail(error));
    }),
  );
};

export default boundariesEpic;
