import { Reducer } from 'redux';

import {
  GlobalModalActions,
  GlobalModalActionTypes,
} from 'connected/GlobalModal/types';
import { Vertical } from 'globalConstants';

export interface GlobalModalState {
  isOpen: boolean;
  currentModal: string;
  shouldRemainInPlace?: boolean;
  recordId?: string;
  vertical?: Vertical;
  modalType?: string;
}

export const initialState: GlobalModalState = {
  isOpen: false,
  currentModal: '',
  shouldRemainInPlace: false,
  recordId: '',
  modalType: '',
};

type ModalActions = GlobalModalActions;

const globalModalReducer: Reducer<GlobalModalState, ModalActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GlobalModalActionTypes.SHOW_MODAL:
      return {
        ...state,
        isOpen: true,
        currentModal: action.payload.modal,
        shouldRemainInPlace: action.payload.shouldRemainInPlace,
        recordId: action.payload.recordId || '',
        vertical: action.payload.vertical,
        modalType: action.payload.modalType || '',
      };
    case GlobalModalActionTypes.HIDE_MODAL:
      return {
        ...state,
        isOpen: false,
        currentModal: '',
        recordId: '',
        vertical: undefined,
        modalType: '',
      };
    case GlobalModalActionTypes.HIDE_SINGLE_MODAL:
      if (state.currentModal === action.payload) {
        return {
          ...state,
          isOpen: false,
          currentModal: '',
          recordId: '',
          vertical: undefined,
          modalType: '',
        };
      }
      return state;

    default:
      return state;
  }
};

export default globalModalReducer;
