import React from 'react';
import { StyledInput, StyledLabel } from './SearchInputStyled';

import { SearchTextInputType } from './types';

const SearchTextInput = ({
  label,
  name,
  placeholder,
  onChange,
  onFocus,
  onKeyUp,
  labelIsHidden,
  customStyles,
  inputRef,
  value,
}: SearchTextInputType) => (
  <>
    <StyledLabel htmlFor={name} labelIsHidden={labelIsHidden}>
      {label}
    </StyledLabel>
    <StyledInput
      id={name}
      name={name}
      type="text"
      onChange={onChange}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      autoComplete="off"
      customStyles={customStyles}
      ref={inputRef}
      value={value}
    />
  </>
);

export default SearchTextInput;
