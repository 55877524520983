import React from 'react';

import Tooltip from 'components/Tooltip/Tooltip';
import { AccessibleText } from 'styled/Global';
import {
  VerifiedIcon,
  CalculatedIcon,
  TooltipContentStyled,
} from 'components/DataValueItem/DataValueItemStyled';

import {
  InlineIcon,
  CalculatedInlineIcon,
} from 'connected/SummaryPanel/SummaryPanelStyled';
import { DATA_ITEM_COPY } from './constants';

const AnnualRentIcon = ({
  point,
  isInCell = false,
}: {
  point: any;
  isInCell?: boolean;
}) => {
  if (
    point.key === DATA_ITEM_COPY.annualRent.key &&
    point.isConfirmedTotalRent
  ) {
    return (
      <InlineIcon isInCell={isInCell}>
        {point.title}
        <Tooltip
          tooltipId={DATA_ITEM_COPY.annualRent.confirmed.id}
          button={
            <>
              <VerifiedIcon />
              <AccessibleText>
                {DATA_ITEM_COPY.annualRent.confirmed.title}
              </AccessibleText>
            </>
          }
        >
          {DATA_ITEM_COPY.annualRent.confirmed.tooltip}
        </Tooltip>
      </InlineIcon>
    );
  }
  if (
    point.key === DATA_ITEM_COPY.annualRent.key &&
    !point.isConfirmedTotalRent
  ) {
    return (
      <CalculatedInlineIcon isInCell={isInCell}>
        {point.title}
        <Tooltip
          tooltipId={DATA_ITEM_COPY.annualRent.calculated.id}
          button={
            <>
              <CalculatedIcon />
              <AccessibleText>
                {DATA_ITEM_COPY.annualRent.calculated.title}
              </AccessibleText>
            </>
          }
        >
          <TooltipContentStyled
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DATA_ITEM_COPY.annualRent.calculated.tooltip,
            }}
          />
        </Tooltip>
      </CalculatedInlineIcon>
    );
  }
  return <></>;
};

export default AnnualRentIcon;
