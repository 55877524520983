import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TooltipContent, TooltipWrapper, TooltipButton } from './TooltipStyled';
import { TooltipProps } from './types';

const Tooltip = ({
  children,
  button,
  tooltipId,
  isActionTooltip,
  isDisabled,
}: TooltipProps) => {
  return (
    <TooltipWrapper isActionTooltip={isActionTooltip || false}>
      <ReactTooltip
        globalEventOff="touchstart"
        eventOff="mouseleave mouseout scroll mousewheel blur touchmove"
        type="light"
        place="top"
        effect="solid"
        id={tooltipId}
      >
        <TooltipContent>{children}</TooltipContent>
      </ReactTooltip>
      <TooltipButton
        data-event="touchstart focus mouseover"
        data-event-off="mouseout"
        data-tip
        data-for={tooltipId}
        type="button"
        disabled={isDisabled}
      >
        {button}
      </TooltipButton>
    </TooltipWrapper>
  );
};

export default Tooltip;
