import equals from 'ramda/src/equals';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { RootState } from 'store/rootReducer';
import { urls } from 'globalConstants';

/**
 * redirects to `/search` if not `user.isAdmin`
 */
export const AdminRoute: React.FC<{
  path: string;
  component: React.ComponentType;
}> = ({ path, component: Component }) => {
  const user = useSelector(
    (state: RootState) => state.authentication.user,
    equals,
  );

  return (
    <Route path={path}>
      {user?.isAdmin ? <Component /> : <Redirect to={urls.search} />}
    </Route>
  );
};
