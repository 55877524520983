import { FORM_FLOWS } from 'connected/FormFlow/constants';
import { DetailsSummary } from 'pages/Details/types';

export type NewRecordCopy = {
  pageTitle: string;
  searchPromptTitle: string;
  addNewRecordButtonLabel: string;
};

export interface NewRecordWrapperProps {
  modalId: FORM_FLOWS;
  copy: NewRecordCopy;
}

export interface EntityProps {
  error?: string;
  address?: DetailsSummary;
  loading?: boolean;
  onCancelButtonClick: () => void;
  onNewRecordButtonClick: () => void;
  addNewRecordButtonLabel: string;
}

export enum NewRecordActionTypes {
  NEW_RECORD_RESET = 'NEW_RECORD_RESET',
}

export type NewRecordResetAction = {
  type: NewRecordActionTypes.NEW_RECORD_RESET;
};

export type NewRecordActions = NewRecordResetAction;

export enum ButtonTypes {
  DEFAULT = 'default',
  DROPDOWN = 'dropdown',
  INLINE = 'inline',
}

export type FormValue = {
  value: string;
  subSource?: string;
};
