import { Vertical } from 'globalConstants';

export enum GlobalModalActionTypes {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
  HIDE_SINGLE_MODAL = 'HIDE_SINGLE_MODAL',
}

export type GlobalModalProps = {
  isOpen: boolean;
  currentModal: string;
};

export type GlobalModalShowAction = {
  type: GlobalModalActionTypes.SHOW_MODAL;
  payload: {
    modal: string;
    shouldRemainInPlace?: boolean;
    recordId?: string;
    vertical?: Vertical;
    modalType?: string;
  };
  meta?: any;
};

export type GlobalModalHideAction = {
  type: GlobalModalActionTypes.HIDE_MODAL;
  payload?: string;
};
export type GlobalModalHideSingleAction = {
  type: GlobalModalActionTypes.HIDE_SINGLE_MODAL;
  payload?: string;
};

export type GlobalModalActions =
  | GlobalModalShowAction
  | GlobalModalHideAction
  | GlobalModalHideSingleAction;

export type GlobalModalWrapperProps = {
  children: React.ReactNode;
  modalId: string;
  isClickableOutsideToClose?: boolean;
  closeModalClicked?: () => void;
  hasCloseButton?: boolean;
  lightTheme?: boolean;
  size?: 'small' | 'large' | 'fullsize';
};
