import styled, { css } from 'styled-components/macro';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

import {
  getErrorStyles,
  getHiddenStyles,
  getMinimizedStyles,
} from 'styled/helpers';
import {
  inputWrapperStyles,
  inputWrapperDisabledStyles,
  labelStyles,
} from 'styled/Global';
import { LabelType } from './types';

export const StyledCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  right: 10px;
  opacity: 0.6;
  path {
    fill: ${(props) => props.theme.colorPalette.secondary.midGrey};
  }
  pointer-events: none;
  cursor: pointer;
`;

const getDisabledStyles = (theme: any, isDisabled?: boolean) => {
  if (isDisabled) {
    return css`
      ${inputWrapperDisabledStyles}

      input {
        background: none;
        color: ${theme.colorPalette.secondary.darkGrey};
      }

      label {
        color: ${theme.colorPalette.branding.ink};
      }
    `;
  }
  return ``;
};

export const StyledLabel = styled.label<LabelType>`
  ${(props) => getHiddenStyles(props.labelIsHidden)}
  ${labelStyles}
  pointer-events: none;
  padding: 0 ${(props) => props.theme.spacing.default} 0
    ${(props) => props.theme.spacing.small};

  ${(props) => getMinimizedStyles(props.labelIsMinimized)};

  transform: translate3d(0, -10px, 0);
`;

export const DatePickerWrapper = styled.div<{
  isDisabled: boolean;
  showValue?: boolean;
  hasErrors: boolean;
}>`
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  ${inputWrapperStyles}
  padding: 0;
  margin: 10px 0;
  min-width: 170px;

  ${(props) => getDisabledStyles(props.theme, props.isDisabled)}

  ${(props) => getErrorStyles(props.hasErrors)}
`;

export const InputField = styled.input<{ labelIsMinimized: boolean }>`
  padding: 8px 13px 0 13px;
  border: none;
  height: 100%;
  -webkit-appearance: none;

  &::-webkit-datetime-edit {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: ${(props) => props.theme.typography.paragraph.small};
    color: ${(props) => props.theme.colorPalette.secondary.darkGrey};
  }
`;

export const StyledPrompt = styled.div`
  margin: 20px 0;
`;

export const RelativeDatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionHeader = styled.div``;

export const SectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const Placeholder = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
`;

export const FixedRelativeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FixedRelativeItem = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const FixedRelativeRadio = styled.div`
  margin: ${(props) => props.theme.spacing.small} 0;
`;

export const FixedRelativeContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${(props) => props.theme.spacing.small} 0;
`;

export const TextWrapper = styled.div`
  width: 60px;

  input {
    padding: 0;
  }
`;
