import { STATE_FIELDS } from 'connected/FormFlow/constants';
import { formatPercentage, formatSector } from 'services/format';
import { MapProps } from './types';

export const COMPANY_DATA_COPY = {
  owner: 'Owner/Occupier',
  investor: 'Investor',
  privateInvestor: 'Private Investor',
  equity: 'Equity Stake',
  type: 'Type',
  nationality: 'Nationality',
  sector: 'Sector',
  name: 'Name',
} as const;

export const MINIMIZED_MAP: MapProps[] = [
  {
    key: 'companyName',
    label: COMPANY_DATA_COPY.name,
    isWide: true,
    hasLink: true,
  },
];

export const BASIC_MAP: MapProps[] = [
  ...MINIMIZED_MAP,
  {
    key: 'companySector',
    label: COMPANY_DATA_COPY.sector,
    isWide: true,
    formatter: (value: string | number) => formatSector(value as string),
  },
  {
    key: 'companyNationality',
    label: COMPANY_DATA_COPY.nationality,
    formatter: (value: string | number) => formatSector(value as string),
  },
];

export const BUYER_MAP: MapProps[] = [
  ...BASIC_MAP,
  {
    key: 'companyType',
    label: COMPANY_DATA_COPY.type,
    formatter: (value: string | number) => {
      const options = new Map([
        [STATE_FIELDS.OWNER, COMPANY_DATA_COPY.owner],
        [STATE_FIELDS.INVESTOR, COMPANY_DATA_COPY.investor],
        [STATE_FIELDS.PRIVATE_INVESTOR, COMPANY_DATA_COPY.privateInvestor],
      ]);
      return options.get(value as string);
    },
  },
  {
    key: 'companyEquity',
    label: COMPANY_DATA_COPY.equity,
    formatter: (value: string | number) => formatPercentage(value as number),
  },
];

export const FIELDS = {
  COMPANY_TYPE: 'owner-occupier',
  EQUITY: 'companyEquity',
  COMPANY_NAME: 'companyName',
};
