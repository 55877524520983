import styled from 'styled-components/macro';
import { ReactComponent as IntrohiveLogo } from 'assets/icons/introhive-logo.svg';
import { ReactComponent as IntrohiveNearestLogo } from 'assets/icons/introhive-nearest-logo.svg';
import { lighten } from 'polished';

export const CompanyIntrohiveNearestLogo = styled(IntrohiveNearestLogo)`
  width: 16px;
  margin-right: 5px;
  margin-top: -4px;
  margin-bottom: -4px;
`;

export const CompanyIntrohiveLogo = styled(IntrohiveLogo)`
  width: 16px;
  margin-right: 5px;
  margin-top: -4px;
  margin-bottom: -4px;
`;

export const CompanyIntrohiveAccentColour1 = lighten(0.45, '#003dd3');
export const CompanyIntrohiveAccentColour2 = lighten(0.25, '#7852ff');

export const CompanyIntrohivePillLink = styled.a`
  text-decoration: none;
  margin-left: 0px !important;
  margin-top: -1px;
  color: ${(props) => props.theme.colorPalette.text.dark};
`;

export const CompanyIntrohivePillOutline = styled.span<{ colour: string }>`
  background-color: ${(props) => props.colour};
  border: 1px solid #333333;
  padding: 1px 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  height: 1.2em;
`;

export const CompanyIntrohivePillValue = styled.span`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-weight: normal;
  white-space: nowrap;
`;
