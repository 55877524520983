import { useState, useMemo } from 'react';
import clone from 'ramda/src/clone';
import addDate from 'date-fns/add';
import formatDate from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { v4 as uuid } from 'uuid';
import { ACCOMMODATION_SCHEDULE_COPY } from 'pages/Details/Building/AccommodationSchedule/constants';
import { sortArrayByProp } from 'utils/arr';

import { MultilineFieldChangeHandler } from '../types';
import { LINE_PARAMS } from '../FormMultiline/constants';

function useMultiline(initialItems: any, handleOnChange: (state: any) => void) {
  const defaultState = useMemo(
    () => initialItems || [{ index: 0, id: uuid() }],
    [initialItems],
  );
  const [rowFields, setRowFields] = useState(defaultState);

  const onFieldChange: MultilineFieldChangeHandler = (
    index,
    value,
    name,
    label,
    componentType,
    resetRowState,
    resetFields,
    formattedValue,
    id,
  ) => {
    const list = [...rowFields];

    if (resetRowState) {
      const rowId = list[index] && list[index].id;

      list[index] = { index, id: rowId };
    }

    if (resetFields) {
      resetFields.forEach((key) => {
        list[index][key] = '';
      });
    }

    list[index][name] = {
      index,
      value,
      label,
      componentType,
      formattedValue,
      id,
    };

    setRowFields(list);
    handleOnChange(list);
  };

  const addOrderedRows = (quantity: number, key: string, position?: string) => {
    const list = [...rowFields];
    const listLength = list.length;
    let property = list[listLength - 1][key];

    switch (position) {
      case LINE_PARAMS.TOP:
        property = list[0][key];
        for (let i = property.value; i < quantity + property.value; i += 1) {
          list.push({
            [key]: { value: Math.floor(i + 1) },
          });
        }
        break;

      case LINE_PARAMS.BELOW:
        for (let i = 0; i < quantity; i += 1) {
          const val = property.value === -0.5 ? 0 : property.value;
          list.push({
            [key]: { value: val - i - 1 },
          });
        }
        break;

      case LINE_PARAMS.UPPER:
        for (let i = 0; i < quantity; i += 1) {
          list.push({
            [key]: { value: 0.5 },
            floorName: { value: ACCOMMODATION_SCHEDULE_COPY.upper },
          });
        }
        break;

      case LINE_PARAMS.LOWER:
        for (let i = 0; i < quantity; i += 1) {
          list.push({
            [key]: { value: -0.5 },
            floorName: { value: ACCOMMODATION_SCHEDULE_COPY.lower },
          });
        }
        break;

      default: {
        for (let i = 0; i < quantity; i += 1) {
          list.push({
            [key]: { value: Math.floor(property.value - i - 1) },
          });
        }
      }
    }

    const updatedOrderedList = sortArrayByProp(list as [], key, false, 'value');

    const newList = updatedOrderedList.map((item: any, index: number) => {
      return { ...item, index };
    });

    setRowFields(newList);
    handleOnChange(newList);
  };

  const addRows = (quantity: number) => {
    const list = [...rowFields];
    const listLength = list.length;

    for (let i = 0; i < quantity; i += 1) {
      list.push({
        index: listLength + i,
        id: uuid(),
      });
    }

    setRowFields(list);
    handleOnChange(list);
  };

  const addRepeatableRow = (keys: string[], yearsAhead: number) => {
    const lastRow = rowFields[rowFields.length - 1];
    const newRow = {
      ...clone(lastRow),
      index: rowFields.length,
      id: uuid(),
    };

    if (
      keys.every((key) => !Object.prototype.hasOwnProperty.call(newRow, key))
    ) {
      return;
    }

    keys.forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(newRow, key)) return;

      const oldDate = new Date(newRow[key].value);

      if (isValid(oldDate)) {
        const newDate = addDate(oldDate, { years: yearsAhead });
        newRow[key].value = formatDate(newDate, 'yyyy-MM-dd');
      }
    });

    const list = [...rowFields, newRow];

    handleOnChange(list);
    setRowFields(list);
  };

  const updateAllRows = (updatedRows: any[]) => {
    const rowsPayload = updatedRows.map((row, index) => {
      const output = { ...row, index };

      Object.keys(output).forEach((rowKey) => {
        if (Object.prototype.hasOwnProperty.call(output[rowKey], 'index')) {
          output[rowKey].index = index;
        }
      });

      return output;
    });

    setRowFields(rowsPayload);
    handleOnChange(rowsPayload);
  };

  const handleDeleteRow = (i: number) => {
    const list = [...rowFields];
    list.splice(i, 1);

    updateAllRows(list);
  };

  const handleDeletePartition = (i: number, partitionIndex: number) => {
    const list = [...rowFields];
    list[i].partitions.value.splice(partitionIndex, 1);

    updateAllRows(list);
  };

  return {
    onFieldChange,
    updateAllRows,
    addRows,
    addOrderedRows,
    addRepeatableRow,
    handleDeleteRow,
    handleDeletePartition,
    rowFields,
  };
}

export default useMultiline;
