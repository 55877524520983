/* eslint-disable no-underscore-dangle */
import {
  BreakOption,
  BuildingAddress,
  BuildingRecord,
  CompanyDebtRecord,
  CompanyRecord,
  DebtRecord,
  RentReview,
  SaleState,
} from 'pages/Details/types';
import {
  add,
  differenceInDays,
  differenceInMonths,
  formatDuration,
  intervalToDuration,
  isAfter,
  isBefore,
  isWithinInterval,
  parseISO,
  sub,
} from 'date-fns';
import {
  globalContent,
  DEAL_DETAILS_TITLE,
  DEAL_DETAILS_SUBTITLE_TITLE,
  OTHER_DEAL_DETAILS_TITLE,
  Vertical,
} from 'globalConstants';
import { v4 as uuid } from 'uuid';

import { ROLE_MAP } from 'constants/configMaps';
import { LeaseStatusEnum } from 'constants/leases';
import { STATE_FIELDS } from 'connected/FormFlow/constants';

import {
  convertNumberThousandSeparator,
  dynamicString,
  mapStatus,
} from 'utils/string';
import { EntityTypes } from 'connected/AuditLogPanel/types';
import { Valuation } from 'connected/ValuationPanel/types';
import { SaleAvailabilityStatusEnum } from 'constants/sales';

import {
  formatArea,
  formatDate,
  formatFromMap,
  formatPrice,
  titleCase,
} from './format';
import {
  Geom,
  EventDates,
  RelationRole,
  Employee,
  PreviousCompanyNames,
} from './types';
import { matchingRecordColours } from './constants';

export const getBuildingAddress = (
  building: BuildingRecord | BuildingAddress,
  renderTitleCase = false,
) => {
  let address = '';
  if (building.subBuilding)
    address += `${
      renderTitleCase ? titleCase(building.subBuilding) : building.subBuilding
    }, `;
  if (building.buildingName)
    address += `${
      renderTitleCase ? titleCase(building.buildingName) : building.buildingName
    }, `;
  if (building.buildingNumber)
    address += `${
      renderTitleCase
        ? titleCase(building.buildingNumber)
        : building.buildingNumber
    } `;
  if (building.street)
    address += `${
      renderTitleCase ? titleCase(building.street) : building.street
    }, `;
  if (building.town)
    address += `${
      renderTitleCase ? titleCase(building.town) : building.town
    }, `;
  if (building.postcode) address += `${building.postcode}`;
  return address;
};

export const convertGeomToLatLngLiteral = (geom: Geom) => ({
  lat: geom.coordinates[1],
  lng: geom.coordinates[0],
});

export const mapRelatedBuildings = (
  building: any,
  sale?: any,
): BuildingRecord => {
  // When sale is also supplied, we match up the correct unit sale to the building.
  // This is used in Portfolio Sales management.
  const unitSale = sale?.unitsSales?.find(
    (us: any) => us.buildingId === building.buildingId,
  );

  return {
    buildingId: building.buildingId,
    buildingName: building.buildingName,
    buildingNumber: building.buildingNumber,
    buildingsOtherNames: building.otherNames,
    buildingAddress: getBuildingAddress(building),
    country: building.country,
    street: building.street,
    town: building.town,
    postcode: building.postcode,
    nia: building.nia,
    uprn: building.uprns,
    market: building.market,
    mostRecentSoldSale: building.mostRecentSoldSale,
    landlords: building.landlords,
    entityName: building?.__entityName__,
    entityId: building.buildingId,
    coords: building.geom && convertGeomToLatLngLiteral(building.geom),
    primaryUse: building.primaryUse,
    totalArea: building.totalArea,
    unitSale: {
      id: unitSale?.id,
      entityName: unitSale?.__entityName__,
    },
    floors: building.floors,
    headerAddress: building.headerAddress,
    subBuilding: building.subBuilding,
  };
};

const mapIntrohiveFields = (company: any) => {
  return {
    introhiveCompanyId: company?.introhiveCompanyId,
    introhiveName: company?.introhiveName,
    introhiveRelationshipStrengthScore:
      company?.introhiveRelationshipStrengthScore,
    introhiveNearestRelationshipStrengthScore:
      company?.introhiveNearestRelationshipStrengthScore,
    introhiveScoreId: company?.introhiveScoreId,
  };
};

const mapIncansFields = (company: any) => {
  return {
    incansGlobalScore: company?.incansGlobalScore,
    equivalentBondRating: company?.equivalentBondRating,
  };
};

export const mapRelatedCompanies = (company: any): CompanyRecord => ({
  companyId: company.id,
  companyName: company.name,
  companyAddress: company.address,
  companySector: company.primaryBusinessType,
  companyNationality: company.countryOfOrigin,
  entityName: company.__entityName__,
  entityId: company.id,
  ...mapIntrohiveFields(company),
});

export const getLandlordTenant = (data: any, role: RelationRole) => {
  const match = data.crmLeases?.find(
    (lease: any) => lease.relationRole === role,
  );
  return (
    match && {
      companyName: match.company.name,
      companySector: match.company.primaryBusinessType,
      companyNationality: match.company.countryOfOrigin,
      companyId: match.company.id,
      entityName: match.__entityName__,
      entityId: match.company.id,
      isPrivate: match.company.isPrivate,
      savCrmId: match.company.savCrmId,
      savCrmNearestId: match.company.savCrmNearestId,
      ...mapIntrohiveFields(match.company),
      ...mapIncansFields(match.company),
    }
  );
};

export const getBorrowerLender = (data: any, role: RelationRole) => {
  const matches = data.crmDebts?.filter(
    (debt: any) => debt.relationRole === role,
  );
  return matches?.map((match: any) => ({
    companyName: match.company.name,
    companyId: match.company.id,
    companyNationality: match.company.nationality,
    entityName: match.__entityName__,
    entityId: match.id,
    isPrivate: match.company.isPrivate,
    savCrmId: match.company.savCrmId,
    savCrmNearestId: match.company.savCrmNearestId,
    ...mapIntrohiveFields(match.company),
  }));
};

export const getEmployeeName = (employee: Employee) => {
  let name = '';

  if (!employee) return name;

  if (employee.firstName) name = employee.firstName;

  if (employee.lastName) name += ` ${employee.lastName}`;

  return name;
};

export const getPreviousNames = (previousCompanies: PreviousCompanyNames[]) => {
  if (!previousCompanies) return [];
  return previousCompanies.map((company) => {
    let previousCompanyString = `${company.previousName}`;
    if (company.effectiveFrom) {
      previousCompanyString += ` - ${globalContent.from} ${formatDate(
        company.effectiveFrom,
      )}`;
    }
    if (company.ceasedOn) {
      previousCompanyString += ` ${globalContent.to} ${formatDate(
        company.ceasedOn,
      )}`;
    }
    return previousCompanyString;
  });
};

const findEmployee = (employees: any, employeeId: string) => {
  return employees.find((employee: any) => employee.id === employeeId);
};

export const getRoleInfo = (
  crmArr: any,
  role: RelationRole,
  isSale = false,
) => {
  if (!crmArr || crmArr.length === 0) return [];
  return crmArr?.reduce((accum: [], crmRelationship: any) => {
    const path = isSale
      ? findEmployee(
          crmRelationship.company?.employees,
          crmRelationship.employeeId,
        )
      : crmRelationship.employee;
    if (crmRelationship.relationRole === role) {
      return [
        ...accum,
        {
          key: crmRelationship.id,
          role: formatFromMap(crmRelationship.relationRole, ROLE_MAP),
          companyName: crmRelationship.company.name,
          companyId: crmRelationship.company.id,
          name: path?.firstName,
          surname: path?.surname,
          email: path?.email,
          telephone: path?.phoneNo,
          __entityName__: crmRelationship.__entityName__,
          entityName: crmRelationship.company?.__entityName__,
          entityId: crmRelationship.company.id,
          employeeEntityName: path?.__entityName__,
          employeeEntityId: path?.id,
          savCrmId: crmRelationship.company.savCrmId,
          savCrmNearestId: crmRelationship.company.savCrmNearestId,
          ...mapIntrohiveFields(crmRelationship.company),
        },
      ];
    }
    return accum;
  }, []);
};

export const getCompanyType = (role: RelationRole, company: any, data: any) => {
  if (role === RelationRole.Vendor && company.isPrivate) {
    return STATE_FIELDS.PRIVATE_INDIVIDUAL;
  }

  if (role === RelationRole.Vendor) {
    return STATE_FIELDS.COMPANY;
  }

  if (company.isPrivate) {
    return STATE_FIELDS.PRIVATE_INVESTOR;
  }

  if (data.ownerOccupierPurchase) {
    return STATE_FIELDS.OWNER;
  }

  return STATE_FIELDS.INVESTOR;
};

export const getSellerBuyer = (data: any, role: RelationRole) => {
  return data?.reduce((accum: [], sale: any) => {
    if (sale.relationRole === role) {
      return [
        ...accum,
        {
          companyName: sale.company.name,
          companyId: sale.company.id,
          companySector: sale.company.primaryBusinessType,
          companyNationality: sale.company.countryOfOrigin,
          companyEquity: sale.equityStake,
          companyType: getCompanyType(role, sale.company, data),
          savCrmId: sale.company.savCrmId,
          savCrmNearestId: sale.company.savCrmNearestId,
          entityName: sale.__entityName__,
          entityId: sale.company.id,
          isPrivate: sale.company.isPrivate,
          ...mapIntrohiveFields(sale.company),
        },
      ];
    }
    return accum;
  }, []);
};

/**
 * Formats the company array to a string for display on the merge modal
 * @param companyObj
 * @returns a (currently) untyped object with value, source, entity, isMostTrusted fields
 */
const formatCompanyObject = (companyObj: any) => {
  return companyObj.reduce(
    (companyAccum: any, company: any, i: number) => {
      const companyName =
        companyObj.length > i + 1
          ? `${companyAccum.value}${company.companyName}, `
          : `${companyAccum.value}${company.companyName}`;
      return {
        ...companyAccum,
        ...{
          value: companyName,
          source: company.source || companyAccum.source,
          entity: company.entityName || companyAccum.entityName,
          isMostTrusted: company.isMostTrusted,
        },
      };
    },
    { value: '' },
  );
};

const formatNotesArrayObject = (notesObj: any) => {
  return notesObj.reduce(
    (noteAccum: any, note: any) => {
      return {
        ...noteAccum,
        ...{
          value: notesObj.map((n: any) => n.description).join(', '),
          source: note.source,
          entity: note.entityName || noteAccum.entityName,
          isMostTrusted: note.isMostTrusted,
        },
      };
    },
    { value: '' },
  );
};

const formatDemisesArrayObject = (unitsLeasesObj: any) => {
  return unitsLeasesObj.reduce(
    (demiseAccum: any, unitLease: any) => {
      return {
        ...demiseAccum,
        ...{
          value: unitsLeasesObj.map((n: any) => n.description).join(', '),
          source: unitLease.source,
          entity: unitLease.entityName || demiseAccum.entityName,
          isMostTrusted: unitLease.isMostTrusted,
        },
      };
    },
    { value: '' },
  );
};
export const getSellerBuyerComparison = (
  comparisonData: any,
  role: RelationRole,
  trustscaleData: any,
  trustscaleKey: string,
) => {
  const companyObj = comparisonData?.reduce((accum: [], sale: any) => {
    if (sale.relationRole === role) {
      const recordSource = trustscaleData[trustscaleKey]?.sources?.find(
        (source: any) => source.entityId === sale.saleId,
      );

      const mostTrustedSource = trustscaleData[trustscaleKey]?.sources?.find(
        (source: any) => source.mostTrusted === true,
      );

      return [
        ...accum,
        {
          companyName: sale.company.name,
          companyId: sale.company.id,
          companyEquity: sale.equityStake,
          entityName: sale.__entityName__,
          source: recordSource?.source,
          isMostTrusted: mostTrustedSource?.entityId === sale.saleId,
        },
      ];
    }
    return accum;
  }, []);

  return formatCompanyObject(companyObj);
};

export const getFloorPartition = (data: any) => {
  return data?.unitsLeases?.map((unitLease: any) => ({
    floor: unitLease.floor?.floorName,
    partition: unitLease.partition?.partitionName,
    partitionId: unitLease.partitionId,
    floorId: unitLease.floorId,
    floorLevel: unitLease.floor?.floorLevel,
    entityName: unitLease.floor?.__entityName__,
    partitionEntityName: unitLease.partition?.__entityName__,
    isHistoric: unitLease.partition?.isHistoric,
  }));
};

export const getFloorPartitionName = (data: any) => {
  if (data.unitsLeases?.length > 0) {
    const hasPartition = data.unitsLeases[0]?.partition?.partitionName;
    return hasPartition
      ? `${data.unitsLeases[0]?.floor?.floorName}, (${data.unitsLeases[0]?.partition?.partitionName})`
      : data.unitsLeases[0]?.floor?.floorName;
  }
  return null;
};

export const getLeaseLength = (
  startDate: Date,
  endDate: Date,
  extended = false,
) => {
  const monthDifference = differenceInMonths(endDate, startDate);
  const yearDifference = Math.round(monthDifference / 12);
  const dayDifference = differenceInDays(endDate, startDate);
  if (Number.isNaN(yearDifference)) return null;
  const lessThanAYear = yearDifference < 1;

  if (lessThanAYear) return `${dayDifference} ${globalContent.days}`;

  if (!extended)
    return `${convertNumberThousandSeparator(yearDifference)} ${
      globalContent.years
    }`;

  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  return formatDuration(duration, { delimiter: ', ' });
};

export const getLongLease = (data?: any) => {
  return data?.reduce((acc: [], longLease: any) => {
    return [
      ...acc,
      {
        entityId: longLease.id,
        startDate: longLease.startDate,
        endDate: longLease.endDate,
        gearing: longLease.gearing,
        gearingProportion: longLease.gearingProportion,
        fixedValue: longLease.gearingFixed,
        rentType: longLease.gearingBasisType,
        review: longLease.gearingReviewFrequency,
        minRent: longLease.gearingMinRent,
        leaseLength: getLeaseLength(
          parseISO(longLease.startDate),
          parseISO(longLease.endDate),
        ),
        notes:
          longLease.notes?.length > 0
            ? longLease.notes[0]
            : {
                __entityName__: 'LongLeaseNote',
                entityId: longLease.id,
                description: null,
              },
        entityName: longLease.__entityName__,
      },
    ];
  }, []);
};

export const sortEventDate = (data: EventDates[]) => {
  return data.sort((a, b) => {
    const firstDate = a.reviewDate
      ? new Date(a.reviewDate)
      : new Date(a.rentFrom);
    const compareDate = b.reviewDate
      ? new Date(b.reviewDate)
      : new Date(b.rentFrom);
    return firstDate.getTime() - compareDate.getTime();
  });
};

export const dateIsValidAndInFuture = (item: any) => {
  const reviewDateLater =
    item.reviewDate && isAfter(new Date(item.reviewDate), new Date());

  const rentFromLater =
    item.rentFrom && isAfter(new Date(item.rentFrom), new Date());

  return !item.isVoided && (reviewDateLater || rentFromLater);
};

export const getNextRentReviewDate = (data: EventDates[]) => {
  const rentReviewItems = sortEventDate(data).filter((item: any) =>
    dateIsValidAndInFuture(item),
  );

  return rentReviewItems[0]?.reviewDate || rentReviewItems[0]?.rentFrom;
};

export const sortDates = (data: { date: Date }[]) => {
  return data.sort((a, b) => {
    const firstDate = new Date(a.date);
    const compareDate = new Date(b.date);
    return firstDate.getTime() - compareDate.getTime();
  });
};

export const getNextBreakReviewDate = (
  data: { date: Date; isVoided: boolean }[],
) => {
  const breakReviewItems = sortDates(data).filter(
    (item: any) =>
      !item.removed &&
      !item.isVoided &&
      isAfter(new Date(item.date), new Date()),
  );
  return breakReviewItems[0]?.date;
};

export const getGeomDetails = (data: any) => {
  if (!data.buildings || data.buildings.length === 0) return null;
  return {
    latLng: {
      lat: data.buildings[0].geom?.coordinates[1],
      lng: data.buildings[0].geom?.coordinates[0],
    },
    name: data.buildings[0].buildingName,
  };
};

export const getDealDetails = (data: any) => {
  if (!data.demiseParentId && !data.otherLeases) return null;

  const tenant = getLandlordTenant(data, RelationRole.Tenant);
  const otherLeaseCount = data.otherLeases?.deals.reduce(
    (accum: number, deals: []) => {
      return accum + deals?.length;
    },
    data.otherLeases?.standalone?.length,
  );
  const fallbackDemiseParentID = uuid();

  return {
    title: data.relatedLeases
      ? dynamicString(DEAL_DETAILS_TITLE, [
          convertNumberThousandSeparator(data.dealCurrentTotalArea),
          data.relatedLeases.length + 1,
          convertNumberThousandSeparator(data.dealInitialTotalArea),
        ])
      : '',
    showTitle: !!data.relatedLeases,
    dealTotal: data.dealCurrentTotalArea,
    dealLeases: data.relatedLeases ? data.relatedLeases.length + 1 : 0,
    subtitle:
      data.dealCurrentTotalArea !== data.dealInitialTotalArea
        ? dynamicString(DEAL_DETAILS_SUBTITLE_TITLE, [
            data.dealInitialTotalArea,
          ])
        : '',
    demiseParentId: data.demiseParentId,
    leases: data.relatedLeases
      ? data.relatedLeases.map((lease: any) => ({
          title: getFloorPartitionName(lease),
          id: lease.id,
          isHidden: lease.isHidden,
          list: [
            {
              title: 'Area:',
              body: formatArea(lease.totalArea),
            },
          ],
        }))
      : [],
    otherLeasesContent:
      data.otherLeases &&
      otherLeaseCount > 0 &&
      tenant &&
      tenant.companyName &&
      data.startDate &&
      data.endDate
        ? dynamicString(OTHER_DEAL_DETAILS_TITLE, [
            tenant.companyName,
            otherLeaseCount,
            formatDate(data.startDate),
            formatDate(data.endDate),
          ])
        : '',
    standaloneLeases: data.otherLeases?.standalone?.map((lease: any) => ({
      title: getFloorPartitionName(lease),
      id: lease.id,
      isHidden: lease.isHidden,
      link: 'Merge Lease',
      linkId: data.demiseParentId || fallbackDemiseParentID,
      list: [
        {
          title: 'Area:',
          body: formatArea(lease.totalArea),
        },
      ],
    })),
    leaseArea: data.dealInitialTotalArea,
    tenant: tenant?.companyName,
    startDate: formatDate(data.startDate),
    endDate: formatDate(data.endDate),
    showSubtitle: data.dealCurrentTotalArea !== data.dealInitialTotalArea,
    totalLeases: otherLeaseCount,
    otherLeases: data.otherLeases?.deals.reduce(
      (accum: [], lease: any, index: number) => {
        const group = lease.map((item: any) => ({
          title: getFloorPartitionName(item),
          id: item.id,
          link: 'Merge Lease',
          isHidden: lease.isHidden,
          linkId: data.demiseParentId || fallbackDemiseParentID,
          list: [
            {
              title: 'Area:',
              body: formatArea(item.totalArea),
            },
          ],
        }));

        return [...accum, { title: `Deal ${index + 1}`, items: group }];
      },
      [],
    ),
  };
};

export const calculateCapitalValue = (data: SaleState) => {
  if (
    data.totalAreaAtSale &&
    data.availabilityStatus !== LeaseStatusEnum.OutcomeUnknown
  ) {
    if (
      (data.availabilityStatus === 'available' ||
        data.availabilityStatus === 'withdrawn') &&
      data.askingPrice
    ) {
      return data.askingPrice / data.totalAreaAtSale;
    }
    if (data.availabilityStatus === 'under-offer' && data.offeredPrice) {
      return data.offeredPrice / data.totalAreaAtSale;
    }
    if (data.availabilityStatus === 'sold' && data.achievedPrice) {
      return data.achievedPrice / data.totalAreaAtSale;
    }
  }

  return '';
};

export const getSpaceAvailable = (data: any) => {
  if (data.minimumLettableArea === data.maximumLettableArea) {
    return `${convertNumberThousandSeparator(data.minimumLettableArea)}  ${
      globalContent.squareFt
    }`;
  }

  return `${convertNumberThousandSeparator(
    data.minimumLettableArea,
  )} - ${convertNumberThousandSeparator(data.maximumLettableArea)} ${
    globalContent.squareFt
  }`;
};

export const getOtherNames = (data: any) => {
  if (!data.otherNames || data.otherNames?.length === 0) return null;

  return data.otherNames.reduce(
    (accum: { buildingAddress: string }[], address: string) => {
      return [
        ...accum,
        {
          buildingAddress: address,
        },
      ];
    },
    [],
  );
};

export const checkIfCanonicalAddress = (otherAddresses: BuildingAddress[]) => {
  const canonicalAddresses = otherAddresses.filter(
    (address: BuildingAddress) => address.isCanonicalAddress,
  );

  return canonicalAddresses?.length === 0;
};

export const getCanonicalAddress = (otherAddresses: BuildingAddress[]) => {
  const canonicalAddress = otherAddresses.find(
    (address: BuildingAddress) => address.isCanonicalAddress,
  );

  return canonicalAddress ? canonicalAddress.buildingId : null;
};

export const getPriceDateString = (
  status: string,
  price?: number,
  date?: Date,
  isPortfolio = false,
) => {
  const additionalPortfolioText = isPortfolio
    ? '- %%SPLIT_STR_HERE%%part of portfolio'
    : '';
  if (price) {
    return `${formatPrice(price)} - ${mapStatus(
      status,
    )} (${date}) ${additionalPortfolioText}`;
  }

  return '';
};

export const getDefaultPrice = (sale: SaleState) => {
  if (sale.achievedPrice) {
    return sale.achievedPrice;
  }

  if (sale.offeredPrice) {
    return sale.offeredPrice;
  }

  return sale.askingPrice;
};

export const getLastPrice = (sale: SaleState) => {
  switch (sale.availabilityStatus) {
    case 'available':
      return getPriceDateString(
        sale.availabilityStatus,
        sale.askingPrice,
        sale.sortingDate,
        sale.isPortfolio,
      );
    case 'let':
      return getPriceDateString(
        sale.availabilityStatus,
        sale.achievedPrice,
        sale.sortingDate,
        sale.isPortfolio,
      );
    case 'under-offer':
      return getPriceDateString(
        sale.availabilityStatus,
        sale.offeredPrice,
        sale.sortingDate,
        sale.isPortfolio,
      );

    default:
      return getPriceDateString(
        sale.availabilityStatus || '',
        getDefaultPrice(sale),
        sale.sortingDate,
        sale.isPortfolio,
      );
  }
};

export const getOtherAddresses = (data: any) => {
  if (!data.otherAddresses || data.otherAddress?.length === 0) return null;

  return data.otherAddresses.reduce(
    (
      accum: {
        entityId: string;
        buildingAddress: string;
        isProtected?: boolean;
      }[],
      address: BuildingAddress,
    ) => {
      const addressType = address.isCanonicalAddress
        ? `(${globalContent.mainAddress})`
        : '';
      return [
        ...accum,
        {
          entityId: address.buildingId,
          buildingAddress: `${getBuildingAddress(address)} ${addressType}`,
          isProtected: address.isProtected,
        },
      ];
    },
    [],
  );
};

export const getPrice = (data: any) => {
  const additionalPortfolioText = data.isPortfolio
    ? ' (part of portfolio)'
    : '';
  if (
    data?.availabilityStatus === SaleAvailabilityStatusEnum.Available ||
    data?.availabilityStatus === SaleAvailabilityStatusEnum.Withdrawn
  ) {
    return `${formatPrice(data?.askingPrice)} ${additionalPortfolioText}`;
  }
  if (data?.availabilityStatus === SaleAvailabilityStatusEnum.UnderOffer) {
    return `${formatPrice(data?.offeredPrice)} ${additionalPortfolioText}`;
  }
  if (data?.availabilityStatus === SaleAvailabilityStatusEnum.Sold) {
    return `${formatPrice(data?.achievedPrice)} ${additionalPortfolioText}`;
  }
  if (data?.availabilityStatus === SaleAvailabilityStatusEnum.OutcomeUnknown) {
    if (data?.achievedPrice) {
      return `${formatPrice(data?.achievedPrice)} ${additionalPortfolioText}`;
    }
    if (data?.offeredPrice) {
      return `${formatPrice(data?.offeredPrice)} ${additionalPortfolioText}`;
    }
    return `${formatPrice(data?.askingPrice)} ${additionalPortfolioText}`;
  }
  return null;
};

export const formatMapPinCardData = (vertical: Vertical, data: any) => ({
  [vertical]: {
    summary: {
      buildingName: data.summary.buildingName,
      buildingNumber: data.summary.buildingNumber,
      street: data.summary.street,
      town: data.summary.town,
      postcode: data.summary.postcode,
      totalArea: data.totalArea,
      occupiedByOwner: data.ownerOccupied,
      latestLeaseEnd: formatDate(data.latestLeaseEnd),
      earliestLeaseStart: formatDate(data.earliestLeaseStart),
      subBuilding: data.summary.subBuilding,
    },
    leases: data.leases,
    sales: data.sales,
  },
});

export const formatForMap = (data: any) => {
  return data.reduce(
    (accum: { lat: number; lng: number; name: string }[], item: any) => {
      if (item && item.geom && item.geom.coordinates) {
        return [
          ...accum,
          {
            lat: item.geom.coordinates[1],
            lng: item.geom.coordinates[0],
            name: item.buildingName || '',
            use: item.primaryUse,
            id: item.buildingId,
          },
        ];
      }
      return accum;
    },
    [],
  );
};

export const getBuildingId = (data?: any) => {
  const buildings = data?.map(mapRelatedBuildings);
  return buildings?.length > 0 ? buildings[0].buildingId : null;
};

export const formatDebtPayload = (debts: DebtRecord[]) => {
  const activeDebts = debts.filter((debt: DebtRecord) => !debt.isVoided);
  return activeDebts.map((debt: DebtRecord) => {
    return {
      ...debt,
      lenders: getBorrowerLender(debt, RelationRole.Lender),
      borrowers: getBorrowerLender(debt, RelationRole.Borrower),
    };
  });
};

export const formatValuationPayload = (valuations: Valuation[]) => {
  return valuations.filter((valuation: Valuation) => !valuation.isVoided);
};

export const formatCompanyDebtPayload = (debts: CompanyDebtRecord[]) => {
  const activeDebts = debts.filter(
    (debt: CompanyDebtRecord) => !debt.debt.isVoided,
  );
  return activeDebts.map((debt: CompanyDebtRecord) => {
    return {
      ...debt.debt,
      address: debt.debt.buildings
        ? getBuildingAddress(debt.debt.buildings[0])
        : '',
      building:
        debt.debt?.buildings && debt.debt?.buildings.length > 0
          ? debt.debt?.buildings[0]
          : undefined,
      roleCompany: '',
      role: debt.relationRole,
      borrowers: debt?.borrowers?.map((borrower: any) => ({
        companyName: borrower.name,
        companyId: borrower.id,
      })),
    };
  });
};

export const getMatchingRecords = (
  data: any,
  allRecords: any,
  vertical: Vertical,
) => {
  if (vertical === Vertical.Sale) {
    if (!data.sortingDate) {
      return false;
    }

    const startOfRange = sub(parseISO(data.sortingDate), {
      days: 180,
    });
    const endOfRange = add(parseISO(data.sortingDate), {
      days: 180,
    });

    return [...allRecords].filter((record: any) => {
      if (!record.sortingDate) {
        return false;
      }

      return (
        isBefore(startOfRange, endOfRange) &&
        isWithinInterval(parseISO(record.sortingDate), {
          start: startOfRange,
          end: endOfRange,
        })
      );
    });
  }

  if (vertical === Vertical.Lease) {
    return [...allRecords].filter((record: any) => {
      if (!data.startDate || !data.endDate || data.unitsLeases?.length === 0) {
        return false;
      }
      const startOfStartRange = sub(parseISO(data.startDate), {
        days: 30,
      });
      const endOfStartRange = add(parseISO(data.startDate), {
        days: 30,
      });
      const startOfEndRange = sub(parseISO(data.endDate), {
        days: 30,
      });
      const endOfEndRange = add(parseISO(data.endDate), {
        days: 30,
      });

      const startDateWithin30Days = isWithinInterval(
        parseISO(record.startDate),
        {
          start: startOfStartRange,
          end: endOfStartRange,
        },
      );

      const endDateWithin30Days = isWithinInterval(parseISO(record.endDate), {
        start: startOfEndRange,
        end: endOfEndRange,
      });

      const hasSameDemise = record.floors[0].floorId === data.floors[0].floorId;

      const hasSameBuilding = record.buildingId === data.buildingId;
      if (record.floors[0].partitionId || data.floors[0].partitionId) {
        const hasPartitionAndIsSame =
          record.floors[0].partitionId === data.floors[0].partitionId;
        return (
          startDateWithin30Days &&
          endDateWithin30Days &&
          hasSameDemise &&
          hasPartitionAndIsSame &&
          hasSameBuilding
        );
      }

      return (
        startDateWithin30Days &&
        endDateWithin30Days &&
        hasSameDemise &&
        hasSameBuilding
      );
    });
  }

  return [];
};

export const sortMatchingRecords = (allRecords: any, vertical: Vertical) => {
  const matchingRecordId = vertical === Vertical.Lease ? 'leaseId' : 'saleId';
  let matchingRecordIndicator = 0;
  const sortedRecords: any = [];

  allRecords.map((record: any) => {
    if (sortedRecords.length === 0) {
      return sortedRecords.push({ records: [record] });
    }
    // check if existing sorted records match the record
    const hasMatchingRecords = sortedRecords.reduce(
      (accum: [], sortedRecord: any) => {
        const hasMatches = getMatchingRecords(
          record,
          sortedRecord.records,
          vertical,
        );

        if (hasMatches && hasMatches.length > 0) {
          return [...accum, hasMatches[0]];
        }

        return accum;
      },
      [],
    );

    if (hasMatchingRecords && hasMatchingRecords.length > 0) {
      return [...sortedRecords].map((item: any, i: number) => {
        const match = item.records.find(
          (matchingRecord: any) =>
            hasMatchingRecords[0][matchingRecordId] ===
            matchingRecord[matchingRecordId],
        );
        if (match) {
          sortedRecords[i].records.push(record);
        }
        if (
          !sortedRecords[i].indicator &&
          sortedRecords[i].records.length > 1
        ) {
          sortedRecords[i].indicator = matchingRecordIndicator;
          matchingRecordIndicator += 1;
          if (matchingRecordIndicator > 5) {
            matchingRecordIndicator = 0;
          }
        }
        return null;
      });
    }

    return sortedRecords.push({ records: [record] });
  });

  return sortedRecords;
};

export const getIndicator = (
  recordId: string,
  vertical: Vertical,
  sortedRecordsGroups: any,
) => {
  const matchingRecordId = vertical === Vertical.Lease ? 'leaseId' : 'saleId';

  const matchingIndicator = sortedRecordsGroups.find((sortedRecords: any) => {
    return sortedRecords.records?.find((sortedRecord: any) => {
      return sortedRecord[matchingRecordId] === recordId;
    });
  });

  if (matchingIndicator) {
    return matchingRecordColours[matchingIndicator.indicator];
  }
  return null;
};

export const getLongLeaseComparison = (trustScaleData: any, data?: any) => {
  return data?.reduce((acc: [], longLease: any) => {
    return [
      ...acc,
      {
        entityId: { value: longLease.id },
        startDate: { value: longLease.startDate },
        endDate: { value: longLease.endDate },
        gearing: { value: longLease.gearing },
        gearingProportion: { value: longLease.gearingProportion },
        fixedValue: { value: longLease.gearingFixed },
        rentType: { value: longLease.gearingBasisType },
        review: { value: longLease.gearingReviewFrequency },
        minRent: { value: longLease.gearingMinRent },
        leaseLength: {
          value: getLeaseLength(
            parseISO(longLease.startDate),
            parseISO(longLease.endDate),
          ),
        },
        notes: {
          value:
            longLease.notes?.length > 0
              ? longLease.notes[0]
              : {
                  __entityName__: 'LongLeaseNote',
                  entityId: longLease.id,
                  description: null,
                },
        },
        entityName: { value: longLease.__entityName__ },
        source: '',
        isMostTrusted: true,
      },
    ];
  }, []);
};

export const getNotesArrayComparison = (trustscaleData: any, data?: any) => {
  const notesObject = data?.reduce((acc: [], note: any) => {
    return [
      ...acc,
      {
        entityId: note.id,
        description: note.description,
        entityName: note.__entityName__,
        source: trustscaleData.description.source,
        isMostTrusted: true,
      },
    ];
  }, []);
  return formatNotesArrayObject(notesObject);
};

export const getDemiseComparison = (trustscaleData: any, data?: any) => {
  const demisesObject = data?.reduce((acc: [], unitLease: any) => {
    return [
      ...acc,
      {
        entityId: unitLease.id,
        description: getFloorPartitionName({
          unitsLeases: [unitLease],
        }),
        entityName: unitLease.__entityName__,
        source: trustscaleData.description.source,
        isMostTrusted: true,
      },
    ];
  }, []);
  return formatDemisesArrayObject(demisesObject);
};

export const getLandlordTenantComparison = (
  comparisonData: any,
  role: RelationRole,
  trustscaleData: any,
  trustscaleKey: string,
) => {
  const companyObj = comparisonData?.reduce((accum: [], lease: any) => {
    if (lease.relationRole === role) {
      const recordSource = trustscaleData[trustscaleKey]?.sources?.find(
        (source: any) => source.entityId === lease.leaseId,
      );
      return [
        ...accum,
        {
          companyName: lease.company.name,
          companyId: lease.company.id,
          entityName: lease.__entityName__,
          source: recordSource?.source,
          isMostTrusted:
            trustscaleData[trustscaleKey]?.mostTrusted === lease.leaseId,
        },
      ];
    }
    return accum;
  }, []);

  return formatCompanyObject(companyObj);
};

export const getLeaseEvents = (
  leaseEvents: { value: RentReview[] | BreakOption[] },
  type: EntityTypes,
) => {
  if (leaseEvents.value.length === 0) {
    return null;
  }
  if (type === EntityTypes.BREAK_OPTIONS) {
    const breakEvents = [...leaseEvents.value] as BreakOption[];
    return breakEvents?.reduce(
      (accum: string, event: BreakOption, i: number) => {
        if (i === breakEvents.length - 1) {
          return `${accum}${globalContent.breakOptions} - ${event.date}`;
        }
        return `${accum}${globalContent.breakOptions} - ${event.date}, `;
      },
      '',
    );
  }
  if (type === EntityTypes.RENT_REVIEW) {
    const rentReviews = [...leaseEvents.value] as RentReview[];
    return rentReviews?.reduce(
      (accum: string, event: RentReview, i: number) => {
        if (i === rentReviews.length - 1) {
          return `${accum}${globalContent.rentReview} - ${event.reviewDate}`;
        }
        return `${accum}${globalContent.rentReview} - ${event.reviewDate}, `;
      },
      '',
    );
  }
  return '';
};
