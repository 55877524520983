import { PAGINATION_DEFAULT_LIMIT_OPTIONS } from 'components/Pagination/constants';

export const TABLE_COPY = {
  actions: {
    lock: 'Locked',
    download: 'Download',
    delete: 'Delete',
    view: 'View',
    disputed: 'Disputed',
    docsCount: 'Documents',
    isPrivate: 'Confidential',
    nonCanonicalAddress: 'Non Canonical Address',
    hidden: 'Hidden',
    unwatch: 'Unwatch',
  },
  buttons: {
    sort: {
      asc: 'Sort Ascending',
      desc: 'Sort Descending',
    },
  },
  tooltip: {
    disputed: 'This record is disputed',
    docsCount: 'This record has documents associated with it - click to view',
    isPrivate: 'This record is confidential',
    nonCanonicalAddress: 'This record is connected to ',
    nonCanonicalAddressLink: 'another address ',
    nonCanonicalAddressPart2: 'for this building',
    mergeRecords: {
      lease:
        'Records with similar dates for this demise exist. Order the table by Demise to review them, and consider merging records if appropriate (this feature is only available to Administrators).',
      sale:
        'Records with similar dates exist. Order the table by Date to review them, and consider merging records if appropriate (this feature is only available to Administrators).',
    },
  },
};

export const TABLE_ACTIONS = {
  DOWNLOAD: 'download',
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
  DISPUTED: 'disputed',
  DOCS_COUNT: 'docsCount',
  CONFIDENTIAL: 'isPrivate',
  NON_CANONICAL_ADDRESS: 'nonCanonical',
  MODAL: 'modal',
  HIDDEN: 'hidden',
  UNWATCH: 'unwatch',
  MERGE: 'merge',
};

export const TABLE_CELL_TYPE = {
  ICON: 'icon',
  ACTION: 'action',
  MODAL_TRIGGER: 'modal_trigger',
};

export const defaultPagination = {
  limit: PAGINATION_DEFAULT_LIMIT_OPTIONS[0].value,
  page: 1,
  label: PAGINATION_DEFAULT_LIMIT_OPTIONS[0].label,
};

export const noPagination = {
  limit: PAGINATION_DEFAULT_LIMIT_OPTIONS[2].value,
  page: 1,
  label: PAGINATION_DEFAULT_LIMIT_OPTIONS[2].label,
};
