import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';

import { LinearLoader } from 'components/LinearLoader/LinearLoader';
import {
  QuickSearchOnResultItemClick,
  QuickSearchColorMode,
} from 'connected/QuickSearch/types';
import {
  QuickSearchResultsContainer,
  QuickSearchResultsHeader,
  ResultsUnorderList,
  QuickSearchResultsLoaderWrapper,
} from './SearchResultsStyled';
import ResultItem from '../ResultItem/ResultItem';
import NoResults from '../NoResults/NoResults';
import SearchResultsSummary from './SearchResultsSummary';
import ResultItemWithChildren from '../ResultItem/ResultItemWithChildren';
import ShowMoreResultsButton from '../ShowMoreResults/ShowMoreResultsButton';

interface QuickSearchResultsProps {
  showSummary: boolean;
  onShowMoreResultsClick(): void;
  canShowMoreResults?: boolean;
  onResultItemClick?: QuickSearchOnResultItemClick;
  mode?: QuickSearchColorMode;
  disableNonCanonicalResults?: boolean;
}

const QuickSearchResults: React.FC<QuickSearchResultsProps> = ({
  showSummary,
  onResultItemClick,
  mode = 'light',
  disableNonCanonicalResults,
  canShowMoreResults,
  onShowMoreResultsClick,
}: QuickSearchResultsProps) => {
  const { results, searchTerm, isFetching, error = '' } = useSelector(
    (state: RootState) => state.quickSearch,
  );
  const totalResults = results.length;

  const renderResults = () => (
    <ResultsUnorderList>
      {results.map((result) => {
        return result.otherAddresses && result.otherAddresses.length ? (
          <ResultItemWithChildren
            result={result}
            mode={mode}
            onResultItemClick={onResultItemClick}
            disableNonCanonicalResults={disableNonCanonicalResults}
          />
        ) : (
          <ResultItem
            key={result.id}
            result={result}
            onResultItemClick={onResultItemClick}
          />
        );
      })}
    </ResultsUnorderList>
  );

  if (!searchTerm) {
    return null;
  }

  if (isFetching && totalResults === 0) {
    return (
      <QuickSearchResultsLoaderWrapper>
        <LinearLoader mode="light" />
      </QuickSearchResultsLoaderWrapper>
    );
  }

  return (
    <QuickSearchResultsContainer>
      {showSummary && !error && (
        <QuickSearchResultsHeader>
          <SearchResultsSummary total={totalResults} />
        </QuickSearchResultsHeader>
      )}
      {totalResults ? (
        renderResults()
      ) : (
        <NoResults searchTerm={searchTerm} error={!!error} />
      )}
      {canShowMoreResults && (
        <ShowMoreResultsButton onClick={onShowMoreResultsClick} />
      )}
    </QuickSearchResultsContainer>
  );
};

export default QuickSearchResults;
