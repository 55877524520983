export type MalformedJSONError = {
  message: 'Malformed JSON';
  s3Path: string;
  json?: string;
};

type FeatureFlagValidationError = {
  message: 'Validation Failed';
};

type ConfigNotFoundError = {
  message: 'NotFound';
  s3Path: string;
};

export type FeatureFlagState = {
  features?: FeatureFlagType[];
  error?: MalformedJSONError | FeatureFlagValidationError | ConfigNotFoundError;
};

export type FeatureFlagError = Pick<Required<FeatureFlagState>, 'error'>;

export enum FEATURE_FLAGS {
  PORTFOLIO_SALES = 'PortfolioSales',
  EXPORT_SEARCH = 'ExportSearch',
  ACCOMMODATION_SCHEDULE = 'AccommodationSchedule',
  PROFILE = 'Profile',
  ANALYTICS = 'Analytics',
  SEARCH_STATISTICS = 'SearchStatistics',
}

export type FeatureFlagType = {
  key: string;
  value: boolean;
};
