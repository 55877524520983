import React from 'react';
import { useSelector } from 'react-redux';
import { Company, LeaseRecord, SaleRecord } from 'pages/Details/types';
import { globalContent, Vertical } from 'globalConstants';
import { formatArea, formatDate } from 'services/format';
import { RootState } from 'store/rootReducer';
import { mapFloorPartition } from 'connected/LeasesPanel/helpers';
import {
  getCurrentModalRecordIdSelector,
  getCurrentModalVerticalSelector,
} from 'store/selectors/globalModalSelectors';
import { getPrice } from 'services/helpers';
import { SIDEBAR_COPY } from './constants';
import { SidebarBody, SidebarTitle } from './SidebarCardsStyled';

const getDate = (lease: LeaseRecord) => {
  if (lease.startDate && lease.endDate) {
    return `${formatDate(lease.startDate)} - ${formatDate(lease.endDate)}`;
  }

  if (lease.startDate) {
    return `${formatDate(lease.startDate)} - ${globalContent.unknown}`;
  }

  return `${globalContent.unknown} - ${formatDate(lease.endDate)}`;
};

const getSpaceAvailable = (lease: LeaseRecord) => {
  return `${
    lease.minimumLettableArea
      ? formatArea(lease.minimumLettableArea)
      : global.undefined
  } - ${
    lease.maximumLettableArea
      ? formatArea(lease.maximumLettableArea)
      : global.undefined
  }`;
};

const CompanySection = ({
  title,
  companies,
}: {
  title: string;
  companies?: Company[];
}) => {
  if (!companies || companies.length === 0) {
    return <></>;
  }
  return (
    <>
      <SidebarTitle>{title}</SidebarTitle>
      <SidebarBody>
        {companies?.reduce((accum: string, company: Company, i: number) => {
          if (i === companies.length - 1) {
            return `${accum}${company.companyName}`;
          }

          return `${accum}${company.companyName}, `;
        }, '')}
      </SidebarBody>
    </>
  );
};

const MoveRecordSidebarCard = () => {
  const recordId = useSelector(getCurrentModalRecordIdSelector);
  const vertical = useSelector(getCurrentModalVerticalSelector);
  const leases = useSelector(
    (state: RootState) => state.details.building?.leases,
  );
  const sales = useSelector(
    (state: RootState) => state.details.building?.sales,
  );
  const building = useSelector((state: RootState) => state.details.building);
  const lease = useSelector((state: RootState) => state.details.lease);
  const sale = useSelector((state: RootState) => state.details.sale);

  let currentRecord;

  if (vertical === Vertical.Lease || Vertical.ToLet) {
    currentRecord = leases?.find((l) => l.leaseId === recordId) as LeaseRecord;

    if (!currentRecord) currentRecord = (lease as unknown) as LeaseRecord;
  } else {
    currentRecord = sales?.find((s) => s.saleId === recordId) as SaleRecord;

    if (!currentRecord) currentRecord = (sale as unknown) as SaleRecord;
  }

  if (!currentRecord) {
    return <></>;
  }

  if (vertical === Vertical.Lease) {
    const leaseRecord = currentRecord as LeaseRecord;
    return (
      <>
        <SidebarTitle>{SIDEBAR_COPY.MOVE_RECORDS.tenant}</SidebarTitle>
        <SidebarBody>{leaseRecord?.tenant?.companyName}</SidebarBody>
        <SidebarTitle>{SIDEBAR_COPY.MOVE_RECORDS.dates}</SidebarTitle>
        <SidebarBody>{getDate(leaseRecord)}</SidebarBody>
        <SidebarTitle>{SIDEBAR_COPY.MOVE_RECORDS.demise}</SidebarTitle>
        <SidebarBody>
          {(leaseRecord.floors && mapFloorPartition(leaseRecord.floors)) || ''}
        </SidebarBody>
      </>
    );
  }
  if (vertical === Vertical.ToLet) {
    const leaseRecord = currentRecord as LeaseRecord;
    return (
      <>
        <SidebarTitle>{SIDEBAR_COPY.MOVE_RECORDS.spaceAvailable}</SidebarTitle>
        <SidebarBody>{getSpaceAvailable(leaseRecord)}</SidebarBody>
        <SidebarTitle>
          {SIDEBAR_COPY.MOVE_RECORDS.readyToOccupyDate}
        </SidebarTitle>
        <SidebarBody>{formatDate(leaseRecord.readyToOccupyDate)}</SidebarBody>
      </>
    );
  }
  const holdingCompany = [];

  if (building?.spv) {
    holdingCompany.push(building.spv);
  }

  return (
    <>
      <CompanySection
        title={SIDEBAR_COPY.MOVE_RECORDS.sellers}
        companies={currentRecord?.sellerCompanies}
      />
      <CompanySection
        title={SIDEBAR_COPY.MOVE_RECORDS.buyers}
        companies={currentRecord?.buyerCompanies}
      />
      <CompanySection
        title={SIDEBAR_COPY.MOVE_RECORDS.spvs}
        companies={holdingCompany}
      />
      <SidebarTitle>{SIDEBAR_COPY.MOVE_RECORDS.date}</SidebarTitle>
      <SidebarBody>{formatDate(currentRecord.sortingDate)}</SidebarBody>
      <SidebarTitle>{SIDEBAR_COPY.MOVE_RECORDS.price}</SidebarTitle>
      <SidebarBody>{getPrice(currentRecord)}</SidebarBody>
    </>
  );
};

export default MoveRecordSidebarCard;
