export const INITIAL_LINES_STATE = {
  value: '1',
  label: '1',
};

export const INITIAL_POSITION_STATE = {
  value: 'below',
  label: 'Bottom',
};

export const DEFAULT_OPTIONS = [
  { value: 'top', label: 'Top' },
  { value: 'below', label: 'Bottom' },
];
