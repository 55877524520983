import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentIsDeletingSelector } from 'store/selectors/documentPanelSelectors';

import { LinearLoader } from 'components/LinearLoader/LinearLoader';
import { documentDelete } from 'store/actions/documentPanelActions';
import { getFileIcon } from 'components/Table/TableCell';
import { DocumentFileTypes } from 'components/Table/types';
import { DocumentUploadChangeEnum } from 'connected/DocumentUploader/types';
import { FileWithDeleteCopy } from './constants';
import { DocumentDeleteProps } from './types';
import { DeleteButton, DeleteIcon, FileItem } from './FileWithDeleteStyled';
import { getFileType } from './helpers';

const FileWithDelete = ({
  fileToDelete,
  onDocumentUploadChange,
}: DocumentDeleteProps) => {
  const dispatch = useDispatch();
  const isDeleting = useSelector(getDocumentIsDeletingSelector);
  const fileType = getFileType(fileToDelete.filename) as DocumentFileTypes;
  const fileIcon = getFileIcon(fileType);

  const onClickDeleteFile = () => {
    dispatch(documentDelete(fileToDelete, true));
    if (onDocumentUploadChange)
      onDocumentUploadChange({
        filenameToDelete: fileToDelete.filename,
        changeType: DocumentUploadChangeEnum.DELETE,
      });
  };

  return (
    <>
      <FileItem>
        <span>
          {fileIcon}
          <span>{fileToDelete.filename}</span>
        </span>
        <DeleteButton type="button" onClick={onClickDeleteFile}>
          <DeleteIcon />
          <span>{FileWithDeleteCopy.delete}</span>
        </DeleteButton>

        {isDeleting && <LinearLoader mode="light" />}
      </FileItem>
    </>
  );
};

export default FileWithDelete;
