/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Checkbox from 'components/Forms/Inputs/Checkbox/Checkbox';
import { getLeasesInDeal } from 'connected/EditModals/MoveRecord/helpers';
import { mapFloorPartition } from 'connected/LeasesPanel/helpers';
import { Vertical, globalContent } from 'globalConstants';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getVerticalSelector } from 'store/selectors/detailsSelectors';
import { FormStateContext } from '../context';
import { GroupPrompt } from '../FormView/FormViewStyled';
import { FIELD_COMPONENTS } from '../types';
import { ListItemStyled, ListStyled } from './IdentifyLeasesStyled';
import { IdentifyLeaseViewProps } from './types';

export type SelectedLease = {
  id: string;
  title?: string;
};

const getLabel = (lease: any) => {
  if (lease.title) {
    return lease.title;
  }

  if (lease.floors) {
    return mapFloorPartition(lease.floors);
  }

  if (lease.floorName) {
    return lease.floorName;
  }

  return '';
};

const getHiddenLabel = (isHidden?: boolean) => {
  if (isHidden) {
    return ` (${globalContent.hidden})`;
  }

  return ``;
};

const IdentifyLeases: React.FC<IdentifyLeaseViewProps> = ({
  onChange,
  leaseId,
  group,
  value,
}) => {
  let leases = useSelector(
    (state: RootState) => state.details.building?.leases,
  );
  const contextFormState = useContext(FormStateContext);

  const vertical = useSelector(getVerticalSelector);

  if (vertical === Vertical.Lease || vertical === Vertical.ToLet) {
    leases = contextFormState.formState.multiDeal.identifyLeases.relatedRecords;
  }

  const relatedLeases = getLeasesInDeal(leaseId, leases);

  const [selectedLeases, setSelectedLeases] = useState<SelectedLease[]>(value);

  useEffect(() => {
    onChange(selectedLeases, 'Move Leases?', 'identifyLeases', {
      groupId: 'leases',
      name: 'leases',
      componentType: FIELD_COMPONENTS.MOVE_OTHER_LEASES,
      label: '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeases]);

  const handleChange = (checkedLease: string) => {
    if (selectedLeases.map((l) => l.id).includes(checkedLease)) {
      setSelectedLeases(selectedLeases.filter((l) => l.id !== checkedLease));
    } else {
      setSelectedLeases([...selectedLeases, { id: checkedLease }]);
    }
  };

  return (
    <>
      <GroupPrompt>{group.prompt}</GroupPrompt>
      <ListStyled>
        {relatedLeases?.map((lease) => (
          <ListItemStyled key={lease.leaseId}>
            <Checkbox
              name={lease.leaseId}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.value)
              }
              onKeyPress={handleChange}
              value={lease.leaseId}
              label={`${getLabel(lease)}${getHiddenLabel(lease.isHidden)}`}
              isChecked={selectedLeases
                ?.map((l) => l.id)
                .includes(lease.leaseId)}
            />
          </ListItemStyled>
        ))}
      </ListStyled>
    </>
  );
};

export default IdentifyLeases;
