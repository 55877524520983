import React from 'react';
import FieldValidation from 'components/Forms/FieldValidation';
import RequiredFlag from 'components/Forms/RequiredFlag';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import useFieldInputValidation from 'hooks/useFieldInputValidation';
import { LargeRadioProps } from './types';
import {
  LargeRadioValidationWrapper,
  RadioButtonStyled,
  RadioGroup,
  RadioIcon,
  RadioItemStyled,
  RadioLabel,
  RadioParagraph,
  RadioWrapper,
  StyledHeader,
} from './LargeRadioStyled';

const LargeRadio = ({
  name,
  items,
  handleOnChange,
  isDisabled,
  selectedValue,
  label,
  description,
  validation,
}: LargeRadioProps) => {
  const { hasErrors } = useFieldInputValidation(
    selectedValue,
    FIELD_COMPONENTS.LARGE_RADIO,
    validation,
  );

  return (
    <div>
      <RadioGroup id={name}>
        <RadioParagraph>
          <StyledHeader>{label}</StyledHeader>
          {description}
          {validation?.isRequired && <RequiredFlag />}
        </RadioParagraph>

        <RadioWrapper>
          {items.map((item) => (
            <RadioItemStyled key={item.value}>
              <RadioButtonStyled
                onChange={() => handleOnChange(item.value, item.label)}
                name={name}
                defaultChecked={selectedValue === item.value}
                id={item.value}
                value={item.value}
                type="radio"
              />
              <RadioLabel
                isLarge={!!item.icon}
                isDisabled={!!(isDisabled && selectedValue !== item.value)}
                htmlFor={item.value}
              >
                {item.icon && <RadioIcon>{item.icon}</RadioIcon>}
                {item.label}
              </RadioLabel>
            </RadioItemStyled>
          ))}
        </RadioWrapper>
      </RadioGroup>
      {validation && (
        <LargeRadioValidationWrapper>
          <FieldValidation hasErrors={hasErrors} validation={validation} />
        </LargeRadioValidationWrapper>
      )}
    </div>
  );
};

export default LargeRadio;
