import styled, { css } from 'styled-components/macro';
import { lighten } from 'polished';

import { zIndex } from 'styled/Global';
import Select from 'components/Forms/Inputs/Select/Select';

export const InlineFormWrapper = styled.div`
  position: relative;
`;

export const InlineFormContent = styled.div<{
  offsetTop: string;
  marginRight: string;
  isWide: boolean;
}>`
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.defaultBoxShadow};
  padding: ${(props) => props.theme.spacing.small};
  border: 0;
  position: absolute;
  background: ${(props) => props.theme.colorPalette.branding.white};

  z-index: ${zIndex.modal};
  min-width: 320px;
  width: ${(props) => (props.isWide ? '560px' : 'auto')};
  left: ${(props) => `${props.marginRight}`};
  bottom: ${(props) => `${props.offsetTop}`};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 8px;
    width: 100%;
    background: linear-gradient(
      270deg,
      ${(props) => props.theme.colorPalette.branding.yellow} 0%,
      ${(props) => lighten('0.1', props.theme.colorPalette.branding.yellow)}
        100%
    );
    left: 0;

    ${(props) =>
      props.offsetTop.includes('-') &&
      css`
        top: auto;
        bottom: -2px;
      `}
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -8px;
    left: ${(props) => props.marginRight.replace('-', '')};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${(props) => props.theme.colorPalette.branding.white};

    ${(props) =>
      props.offsetTop.includes('-') &&
      css`
        transform: rotate(180deg);
        bottom: auto;
        top: -8px;
      `}

    ${(props) =>
      props.marginRight !== 'auto' &&
      css`
        margin-left: 10px;
      `}
  }
`;

export const StyledForm = styled.form`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr;
  height: 100%;
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing.small};
`;

export const PrimaryButton = styled.button`
  background: ${(props) => props.theme.colorPalette.branding.yellow};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  min-width: 78px;
  padding: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center;

  &:disabled {
    cursor: default;
    background: ${(props) => props.theme.colorPalette.secondary.lightGrey};
    color: ${(props) => props.theme.colorPalette.secondary.midGrey};
  }
`;

export const SecondaryButton = styled.button`
  padding: ${(props) => props.theme.spacing.xsmall};
  font-weight: bold;
`;

export const StyledSelect = styled(Select)`
  select {
    padding-top: 0;
  }
`;

export const StyledFormMain = styled.div`
  grid-row: 1;
  grid-column: 2;
  overflow: visible;
`;

export const NoticeContainer = styled.div`
  margin: ${(props) =>
    `${props.theme.spacing.xsmall} 0 ${props.theme.spacing.small}`};
`;

export const NoticeInfo = styled.p`
  font-weight: normal;
`;
