/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isNil from 'ramda/src/isNil';
import isEmpty from 'ramda/src/isEmpty';

import {
  FIELD_COMPONENTS,
  PayloadValue,
  ValidationProps,
} from 'connected/FormFlow/types';
import TextInput from 'components/Forms/Inputs/TextInput/TextInput';
import { Option } from 'components/Forms/types';
import { convertGroundLevel } from 'pages/Details/Building/AccommodationSchedule/helpers';
import { buildingFloorsSelector } from './store/selectors';
import { FloorSelectCopy } from './constants';

export type RowField = {
  index: number;
  floorLevel: Option;
  floorName: Option;
  floor: Option;
  totalArea?: Option;
  floorId?: PayloadValue<string>;
  measurementStandard?: Option;
};

type FloorSelectProps = {
  onChange: (floorName: string) => void;
  value?: string;
  name: string;
  validation?: ValidationProps;
  lineValue?: any;
  rowFields: RowField[];
  setRowFields?: React.Dispatch<any>;
  selectedValue?: string;
};

const isFieldDisabled = (
  floorLevel: number,
  matchedExistingRows: RowField[],
  lineValue: any,
) => {
  // IF NO FLOOR LEVEL SELECTED DISABLE FLOOR NAME FIELD
  // eslint-disable-next-line no-restricted-globals
  if (
    typeof floorLevel === 'undefined' ||
    // eslint-disable-next-line no-restricted-globals
    isNaN(floorLevel) ||
    (floorLevel > -1 && floorLevel < 1)
  )
    return true;

  // DISABLE ANY DUPLICATE LEVEL FLOOR FIELDS
  if (matchedExistingRows?.length > 1) {
    matchedExistingRows.sort((a, b) => a.index - b.index);

    return matchedExistingRows[0].index !== lineValue.index;
  }

  return false;
};

const FloorSelect = ({
  onChange,
  value,
  validation,
  lineValue,
  rowFields,
  setRowFields,
}: FloorSelectProps) => {
  const [floorValue, setFloorValue] = useState(value || '');
  const existingBuildingFloors = useSelector(buildingFloorsSelector);
  const floorLevel = lineValue?.floorLevel?.value;

  const matchedExistingFloor = useMemo(
    () =>
      existingBuildingFloors?.find((floor) => {
        return floor.floorLevel === floorLevel;
      }),
    [existingBuildingFloors, floorLevel],
  );

  const allMatchingExistingRows = useMemo(
    () =>
      rowFields.filter((demise) => {
        return demise.floorLevel?.value === floorLevel;
      }),
    [floorLevel, rowFields],
  );
  const additionalMatchingExistingRows = allMatchingExistingRows.filter(
    (demise) => {
      return lineValue.index !== demise.index;
    },
  );

  const disableFloorField = isFieldDisabled(
    floorLevel,
    allMatchingExistingRows,
    lineValue,
  );

  const onFloorFieldChange = (text: string) => {
    if (!isEmpty(additionalMatchingExistingRows) && setRowFields) {
      const substitutions = allMatchingExistingRows.map((row) => ({
        ...row,
        floor: {
          ...row.floor,
          value: text,
        },
      }));

      const rowsPayload = rowFields.map((row) => {
        return (
          substitutions.find((subRow) => subRow.index === row.index) || row
        );
      });

      setRowFields(rowsPayload);
      return;
    }

    setFloorValue(text);
  };

  // ON LEVEL CHANGE CLEAR OR SET MATCHING FLOOR
  useEffect(() => {
    if (
      !isNil(floorLevel) &&
      floorLevel !== 0 &&
      floorLevel !== -0.5 &&
      floorLevel !== 0.5 &&
      !matchedExistingFloor &&
      isEmpty(additionalMatchingExistingRows)
    ) {
      onFloorFieldChange('');
    }
    if (floorLevel === 0 || floorLevel === -0.5 || floorLevel === 0.5) {
      onFloorFieldChange(`${convertGroundLevel(parseFloat(floorLevel))}`);
    }

    if (!isNil(floorLevel) && additionalMatchingExistingRows?.length > 0) {
      const additionalMatchingFloor = additionalMatchingExistingRows[0];
      onFloorFieldChange(additionalMatchingFloor.floor?.value || '');
      return;
    }

    if (!isNil(floorLevel) && matchedExistingFloor) {
      onFloorFieldChange(matchedExistingFloor.floorName);
    }
  }, [floorLevel]);

  // ON FLOOR VALUE CHANGE SEND TO PARENT FORM STATE
  useEffect(() => {
    onChange(floorValue);
  }, [floorValue]);

  // UPDATE VALUE CHANGE FROM STATE ACROSS ALL DUPLICATE FLOORS IN UI
  useEffect(() => {
    if (!isEmpty(additionalMatchingExistingRows) && !isNil(value)) {
      setFloorValue(value);
    }
  }, [value]);

  // ONMOUNT ADD DEFAULT / PREPOPULATED FROM STATE
  useEffect(() => {
    if (floorValue) {
      onFloorFieldChange(floorValue);
    }
  }, []);
  return (
    <TextInput
      value={floorValue}
      onChange={(event) => onFloorFieldChange(event.target.value)}
      label={FloorSelectCopy.floorLabel}
      name={`floorName-${lineValue.index}`}
      validation={validation}
      isDisabled={disableFloorField}
      componentType={FIELD_COMPONENTS.TEXT_INPUT}
    />
  );
};

export default FloorSelect;
