import styled from 'styled-components/macro';
import { StandardPadding } from '../GlobalScaffold/GlobalScaffoldStyled';

const getDetailPageStyle = (isDetailPage?: boolean) => {
  if (isDetailPage) {
    return `
    grid-template-rows: 1fr;
    grid-template-areas:
      'page-main';
  `;
  }

  return `
  grid-template-rows: minmax(80px, auto) 1fr;
  grid-template-areas:
    'page-header'
    'page-main';
`;
};
export const PageLayout = styled.main<{
  isDetailPage?: boolean;
}>`
  grid-area: global-main;
  background: ${(props) => props.theme.colorPalette.body};
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  ${(props) => getDetailPageStyle(props.isDetailPage)}
`;

export const PageHeader = styled(StandardPadding)`
  grid-area: page-header;
  background: ${(props) => props.theme.colorPalette.branding.ink};
  color: ${(props) => props.theme.colorPalette.text.white};
`;

export const ProfilePageHeader = styled(StandardPadding)`
  grid-area: page-header;
  background: ${(props) => props.theme.colorPalette.branding.yellow};
  color: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const PageMain = styled.div`
  grid-area: page-main;
  display: flex;
  justify-content: center;
`;

export interface PageWrapperFullProps {
  backgroundColor?: string;
  textColor?: string;
  vertical?: string;
}

export const PageWrapperFull = styled.div<PageWrapperFullProps>`
  width: 100%;
  background: ${(props) =>
    props.backgroundColor || props.theme.colorPalette.branding.white};
  border-top: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightInk};
  color: ${(props) => props.textColor || props.theme.colorPalette.text.dark};
`;
