import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { globalModalHide } from 'store/actions/globalModalActions';
import { NotesActionTypes } from 'connected/NotesPanel/types';
import {
  notesDeleteApiFail,
  notesDeleteApiSuccess,
} from 'store/actions/notesActions';

type NoteDeleteEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const noteDeleteEndpoint = endpoints.deleteEntity;

const noteDeleteEpic: Epic = (
  action$,
  state$,
  dependencies: NoteDeleteEpicDependencies,
) => {
  return action$.pipe(
    ofType(NotesActionTypes.DELETE_NOTE),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'DELETE',
          url: noteDeleteEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((deleteResponse) => {
            if (deleteResponse.status > 204) {
              throw Error(`DELETE ${noteDeleteEndpoint} failed`);
            }

            return of(notesDeleteApiSuccess(), globalModalHide());
          }),
          catchError((error) => {
            return of(notesDeleteApiFail(error));
          }),
        );
    }),
  );
};

export default noteDeleteEpic;
