import React from 'react';
import { AccessibleText } from 'styled/Global';
import { globalContent } from 'globalConstants';
import { ButtonWrapper, StyledCloseIcon } from './CloseButtonStyled';
import { CloseButtonProps } from './types';

export const CloseButton = ({ onClick }: CloseButtonProps) => (
  <ButtonWrapper onClick={onClick}>
    <AccessibleText>{globalContent.close}</AccessibleText>
    <StyledCloseIcon aria-hidden="true" />
  </ButtonWrapper>
);
