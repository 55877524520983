import { css } from 'styled-components/macro';
import { hideVisually } from 'polished';
import {
  inputWrapperDisabledStyles,
  inputWrapperErrorStyles,
  minimizedLabelStyles,
} from './Global';

export const getMinimizedStyles = (isMinimized?: boolean) => {
  if (isMinimized) {
    return css`
      ${minimizedLabelStyles}
    `;
  }
  return ``;
};

export const getHiddenStyles = (isHidden?: boolean) => {
  if (isHidden) {
    return css`
      ${hideVisually}
    `;
  }
  return ``;
};

export const getErrorStyles = (hasErrors?: boolean) => {
  if (hasErrors) {
    return css`
      ${inputWrapperErrorStyles}
    `;
  }
  return ``;
};

export const getDisabledStyles = (isDisabled?: boolean) => {
  if (isDisabled) {
    return css`
      ${inputWrapperDisabledStyles}
    `;
  }
  return ``;
};
