import { GlobalApiFailError } from './types';

export enum TelemetryStatisticsActions {
  FETCH_TELEMETRY_STATISTICS = 'FETCH_TELEMETRY_STATISTICS',
  FETCH_TELEMETRY_STATISTICS_SUCCESS = 'FETCH_TELEMETRY_STATISTICS_SUCCESS',
  FETCH_TELEMETRY_STATISTICS_FAIL = 'FETCH_TELEMETRY_STATISTICS_FAIL',
}
export type TelemetryStatisticsPayloadType = {
  key: string;
  sevenDays: number;
  thirtyOneDays: number;
  allTime: number;
};

// GET init
export type FetchTelemetryStatisticsActionType = {
  type: TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS;
};
export const getTelemetryStatisticsAction = (): FetchTelemetryStatisticsActionType => ({
  type: TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS,
});
// GET OK
export type FetchTelemetryStatisticsSuccessActionType = {
  type: TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS_SUCCESS;
  payload: TelemetryStatisticsPayloadType[];
};
export const fetchTelemetryStatisticsAPISuccessAction = (
  payload: TelemetryStatisticsPayloadType[],
): FetchTelemetryStatisticsSuccessActionType => ({
  type: TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS_SUCCESS,
  payload,
});
// GET NOK
export type FetchTelemetryStatisticsFailActionType = {
  type: TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS_FAIL;
  payload: GlobalApiFailError;
};
export const fetchTelemetryStatisticsAPIFailAction = (
  payload: GlobalApiFailError,
): FetchTelemetryStatisticsFailActionType => ({
  type: TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS_FAIL,
  payload,
});
