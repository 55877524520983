import { useSelector } from 'react-redux';
import compose from 'ramda/src/compose';
import isEmpty from 'ramda/src/isEmpty';
import flatten from 'ramda/src/flatten';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { globalContent } from 'globalConstants';

import { Option } from 'components/Forms/types';
import countriesJSON from 'components/Forms/Inputs/CountrySelect/countries.json';
import { priorExistingRecordsSelector } from 'store/selectors/detailsSelectors';
import {
  convertCurrency,
  convertNumberThousandSeparator,
  capitalize,
  capitalizeEveryWord,
  sanitizeStr,
  isJsonString,
} from 'utils/string';

import { DataSourceRowItem, FIELD_COMPONENTS } from '../types';
import { CountryOption, FieldConfigProps } from './types';
import { sortSourcesRowByFields, sortSourcesRowByGroup } from './utils';
import { DEFAULT_KEYS, excludePaths } from './constants';

export const getCountryList = (countries: any) => {
  return countries.reduce((accum: CountryOption, curr: any) => {
    const options = curr.options.reduce(
      (allCountries: CountryOption, country: Option) => ({
        ...allCountries,
        [country.value]: country.label,
      }),
      {},
    );

    return {
      ...accum,
      ...options,
    };
  }, {});
};

const countryList = getCountryList(countriesJSON);

export const stripMissingViews = ({ formState, configs: formConfigs }: any) => {
  return Object.entries(formState).reduce(
    (accum: any, [viewKey, viewData]: [string, any]) => {
      if (Array.isArray(viewData)) return accum;

      const configs = formConfigs.views.find(
        (view: any) => view.viewId === viewKey,
      );

      if ((configs && configs.collectSources === false) || !viewData)
        return accum;

      return [
        ...accum,
        {
          viewId: viewKey,
          data: viewData,
          configs,
        },
      ];
    },
    [],
  );
};

export const getNestedFieldConfigs = (groupConfig: any, itemKey: string) => {
  if (!groupConfig) {
    return false;
  }
  return groupConfig.subGroups
    ? flatten(groupConfig.subGroups.map((group: any) => group.fields)).find(
        (field: any) => field.fieldId === itemKey,
      )
    : groupConfig.fields?.find((field: any) => field.fieldId === itemKey);
};

const getTitle = (fieldConfig: FieldConfigProps, label: string) => {
  if (fieldConfig?.sourceLabelOnly) {
    return fieldConfig?.sourceLabelOnly;
  }
  if (fieldConfig?.sourceLabel) {
    return fieldConfig?.sourceLabel;
  }

  return label;
};

const getFieldValue = (field: any, jsonKey?: string) => {
  if (jsonKey && field.value && isJsonString(field?.value)) {
    return JSON.parse(field.value)[jsonKey];
  }

  return field.value;
};

const getItemValue = (
  itemValue: string,
  itemLabel: string,
  sourceLabel?: string,
) => {
  if (sourceLabel) {
    return itemLabel;
  }

  return itemValue;
};

const getJsonKey = (fieldConfig: any, groupConfig: any) => {
  if (fieldConfig?.jsonKey) {
    return fieldConfig?.jsonKey;
  }
  if (groupConfig?.jsonKey) {
    return groupConfig.jsonKey;
  }

  return DEFAULT_KEYS.name;
};

const getValue = (viewData: any, groupConfig: any, viewPath: string) => {
  if (Array.isArray(viewData.value)) {
    return viewData.value.map((item: any, index: number) =>
      Object.keys(item).reduce((fieldAccum, itemKey) => {
        let value;
        let { componentType } = item[itemKey];
        const fieldConfig = getNestedFieldConfigs(groupConfig, itemKey);
        const jsonKey = getJsonKey(fieldConfig, groupConfig);
        const sourceOrder = fieldConfig?.sourceOrder || 0;

        if (typeof item[itemKey].value === DEFAULT_KEYS.number) {
          value = item[itemKey].value;
          componentType = componentType || FIELD_COMPONENTS.PERCENTAGE;
        } else if (
          viewData.componentType === FIELD_COMPONENTS.MOVE_OTHER_LEASES
        ) {
          if (itemKey === 'title') {
            return fieldAccum;
          }
          return [
            ...fieldAccum,
            {
              path: `${viewPath}.value.${index}.${itemKey}`,
              value: '',
              title: item.title,
              componentType,
              subSource: item[itemKey].subSource,
              formattedValue: item[itemKey].formattedValue,
              sourceOrder,
            },
          ];
        } else {
          value = getFieldValue(item[itemKey], jsonKey);
        }

        if (isEmpty(item[itemKey].value)) return fieldAccum;

        value = getItemValue(
          value,
          item[itemKey].label,
          fieldConfig?.sourceLabel,
        );

        return [
          ...fieldAccum,
          {
            path: `${viewPath}.value.${index}.${itemKey}`,
            value,
            title: item[itemKey].label,
            componentType,
            subSource: item[itemKey].subSource,
            formattedValue: item[itemKey].formattedValue,
            sourceOrder,
          },
        ];
      }, [] as DataSourceRowItem[]),
    );
  }

  if (groupConfig?.sourceLabel) {
    return viewData.label;
  }

  return viewData.value;
};

const getGroupRows = (
  groupData: any,
  groupConfig: any,
  viewId: string,
  groupKey: string,
) => {
  if (!groupConfig) return [];
  const getFieldObj = (
    field: any,
    fieldConfig: FieldConfigProps,
    value: any,
    fieldKey: string,
  ) => {
    const fieldValue = getItemValue(
      value,
      field.label,
      fieldConfig?.sourceLabel,
    );

    const fieldPath = `${viewId}.${groupKey}.${fieldKey}`;
    return {
      title: getTitle(fieldConfig, field.label),
      value: fieldValue,
      path: fieldPath,
      componentType: field.componentType,
      subSource: field.subSource,
      sourceOrder: field.sourceOrder,
    };
  };

  return Object.keys(groupData).reduce((groupAccum: any, fieldKey: string) => {
    const field = groupData[fieldKey];
    const fieldConfig = groupConfig.fields?.find(
      (testField: any) => testField.fieldId === fieldKey,
    );

    const value = getFieldValue(field, fieldConfig?.jsonKey);

    const fieldObj = getFieldObj(field, fieldConfig, value, fieldKey);
    const isFieldEmptyOrNotCollectSources =
      fieldObj.value === '' || fieldConfig?.collectSources === false;

    if (isFieldEmptyOrNotCollectSources) {
      return groupAccum;
    }

    return [...groupAccum, fieldObj];
  }, []);
};

export const arrangeTableStructure = (views: any) => {
  return views.map((view: any) => {
    const { viewId, title: viewTitle } = view.configs;

    let rows = Object.keys(view.data).reduce((accum: any, groupKey: string) => {
      const groupData = view.data[groupKey];

      if (!groupData) {
        return accum;
      }

      const groupObj: any = {};

      const groupConfig = view.configs.groups.find(
        (testGroup: any) => testGroup.groupId === groupKey,
      );

      const isExistingMatch =
        groupData.value &&
        groupData.componentType === FIELD_COMPONENTS.FIND_MATCHES;

      const propNotExistInState = !Object.prototype.hasOwnProperty.call(
        groupData,
        'value',
      );

      if (propNotExistInState) {
        groupObj.rows = getGroupRows(groupData, groupConfig, viewId, groupKey);
      }

      if (isExistingMatch) {
        const priorExistingRecords = useSelector(priorExistingRecordsSelector);

        const matchingLease = priorExistingRecords.leases.find(
          (record) => record.leaseId === groupData.value,
        );
        const fieldValue =
          matchingLease?.tenant?.companyName || groupData.value;

        groupObj.title = getTitle(groupConfig, groupData.label);
        groupObj.path = `${viewId}.${groupKey}`;
        groupObj.subSource = groupData.subSource;
        groupObj.value = fieldValue;

        return [...accum, groupObj];
      }

      groupObj.title = getTitle(groupConfig, groupData.label);
      groupObj.value = getValue(
        groupData,
        groupConfig,
        `${viewId}.${groupKey}`,
      );
      groupObj.multilineTitle = groupConfig?.multilineTitle;
      groupObj.isRecordType = groupConfig?.isRecordType;
      groupObj.path = `${viewId}.${groupKey}`;
      groupObj.subSource = groupData && groupData.subSource;

      if (groupObj.value === '') return accum;
      if (excludePaths.includes(groupObj.path)) return accum;
      return [...accum, groupObj];
    }, []);

    if (view.configs.dataSourcesGroupSort) {
      rows = sortSourcesRowByGroup(view.configs.dataSourcesGroupSort, rows);
    }

    if (view.configs.dataSourcesFieldSort) {
      rows = sortSourcesRowByFields(rows);
    }

    return {
      viewTitle,
      viewId,
      rows,
    };
  });
};

export const flattenRows = (views: any) => {
  return views.map((view: any) => ({
    ...view,
    rows: view.rows.reduce(
      (accum: any, row: any) =>
        row.rows ? [...accum, ...row.rows] : [...accum, row],
      [],
    ),
  }));
};

export const formatValuesForUi = (
  value: any,
  componentType: string,
  inputLabel: string,
) => {
  switch (componentType) {
    case 'PercentageInput': {
      return `${parseFloat((value * 100).toFixed(2))}%`;
    }

    case 'PriceInput': {
      return convertCurrency(value);
    }

    case 'DecimalPriceInput': {
      return convertCurrency(value, 2);
    }

    case 'SquareFtInput': {
      return `${convertNumberThousandSeparator(value)} ${
        globalContent.squareFt
      }`;
    }

    case 'PricePerSqFtInput': {
      return `${convertCurrency(value, 2)} ${globalContent.perSqFt}`;
    }

    case 'DatePicker':
    case 'DateLength': {
      if (!value) return null;
      return value && typeof value === 'number'
        ? value
        : format(parseISO(value), 'dd/MM/yyyy');
    }

    case 'CountrySelect': {
      return countryList[value];
    }

    case 'SelectInput': {
      if (inputLabel === 'Landlord Sector') {
        return typeof value === 'string' ? capitalize(value) : value;
      }
      if (inputLabel === 'On Market' || inputLabel === 'Off Market') {
        return typeof value === 'string'
          ? capitalizeEveryWord(sanitizeStr(value))
          : value;
      }
      return typeof value === 'string' ? capitalize(sanitizeStr(value)) : value;
    }

    case FIELD_COMPONENTS.USE_CLAUSE_SELECT: {
      return value
        .map(({ label: optionLabel }: { label: string }) => optionLabel)
        .join(', ');
    }

    default:
      return typeof value === 'string' ? capitalize(sanitizeStr(value)) : value;
  }
};

export const getTableStructure = (data: any) => {
  return compose(flattenRows, arrangeTableStructure, stripMissingViews)(data);
};
