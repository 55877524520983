import styled from 'styled-components/macro';

import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as UnlockIcon } from 'assets/icons/unlocked.svg';
import { zIndex } from 'styled/Global';

export const PanelWrapper = styled.div<{ isOpen: boolean }>`
  background: ${(props) => props.theme.colorPalette.branding.white};
  width: auto;
  height: calc(100vh - 144px);
  grid-template-rows: 60px 1fr 72px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  box-shadow: ${(props) => props.theme.defaultBoxShadow};
  display: grid;
  overflow: hidden;
  transform-origin: 100% 50%;
  transform: ${(props) =>
    props.isOpen ? 'translate(0, 0)' : 'translate(100%, 0)'};
  transition: all 0.5s ease-in-out;
`;

export const StyledHeader = styled.h4`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-weight: bold;
  padding: 0 ${(props) => props.theme.spacing.small};
`;

export const StyledLockIcon = styled(LockIcon)`
  fill: ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;
export const StyledUnlockIcon = styled(UnlockIcon)`
  fill: ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;

export const StyledTable = styled.div`
  display: grid;
  position: relative;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-content: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTableHeader = styled.span`
  padding: ${(props) => props.theme.spacing.xsmall}
    ${(props) => props.theme.spacing.tablet}
    ${(props) => props.theme.spacing.tablet};
  font-weight: bold;
  text-align: center;

  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;

export const StyledTableRow = styled.div`
  display: grid;
  padding: ${(props) => props.theme.spacing.xsmall};
  grid-template-columns: 1fr 64px 88px;
  z-index: 100;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  > span:first-child {
    text-align: left;
    justify-content: flex-start;
    white-space: nowrap;
  }
`;

export const StyledTableBody = styled.div`
  position: static;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const StyledTableCell = styled.span`
  padding: 0 ${(props) => props.theme.spacing.tablet};
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const StyledSortableTableCell = styled(StyledTableCell)`
  cursor: pointer;
`;

export const StyledDragHandle = styled.span`
  width: 10px;
  height: 4px;
  border-top: 1px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
  margin: 0 10px 0 0;
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.midGrey};
  display: inline-block;
  cursor: pointer;
`;

export const DragPlaceholder = styled.span`
  width: 20px;
`;

export const StyledButtonWrapper = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  width: 100%;
  border-top: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};

  button {
    margin: 8px;
    min-width: 128px;
  }
`;

export const PageBlur = styled.div<{ height?: string }>`
  z-index: ${zIndex.pageBlur};
  content: ' ';
  position: absolute;
  height: ${(props) => props.height || 'calc(100vh - 144px)'};
  width: 100vw;
  top: 0;
  background: white;
  opacity: 0.8;
`;
