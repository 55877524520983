export enum PollingUpdateActionTypes {
  POLLING_UPDATE_SUCCESS = 'POLLING_UPDATE_SUCCESS',
  POLLING_UPDATE_UNKNOWN = 'POLLING_UPDATE_UNKNOWN',
  POLLING_UPDATE_FAIL = 'POLLING_UPDATE_API_FAIL',
  POLLING_RETRY = 'POLLING_RETRY',
  POLLING_UPDATE_COMPLETE = 'POLLING_UPDATE_COMPLETE',
}

export enum PollingSuccessMetaType {
  Results = 'RESULTS',
  Details = 'Details',
}

export type PollingUpdateSuccessAction = {
  type: PollingUpdateActionTypes.POLLING_UPDATE_SUCCESS;
  onSuccess: PollingSuccessMetaType;
};

export const pollingUpdateSuccess = (
  onSuccess: PollingSuccessMetaType,
): PollingUpdateSuccessAction => ({
  type: PollingUpdateActionTypes.POLLING_UPDATE_SUCCESS,
  onSuccess,
});

export type PollingUpdateFailAction = {
  type: PollingUpdateActionTypes.POLLING_UPDATE_FAIL;
};

export const pollingUpdateFail = (): PollingUpdateFailAction => ({
  type: PollingUpdateActionTypes.POLLING_UPDATE_FAIL,
});

export type PollingUpdateUnknownAction = {
  type: PollingUpdateActionTypes.POLLING_UPDATE_UNKNOWN;
};

export const pollingUpdateUnknown = (): PollingUpdateUnknownAction => ({
  type: PollingUpdateActionTypes.POLLING_UPDATE_UNKNOWN,
});

export type PollingUpdateCompleteAction = {
  type: PollingUpdateActionTypes.POLLING_UPDATE_COMPLETE;
};

export const pollingUpdateComplete = (): PollingUpdateCompleteAction => ({
  type: PollingUpdateActionTypes.POLLING_UPDATE_COMPLETE,
});

export type PollingUpdateActions =
  | PollingUpdateSuccessAction
  | PollingUpdateFailAction
  | PollingUpdateUnknownAction
  | PollingUpdateCompleteAction;
