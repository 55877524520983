import { InlineLabelProps } from 'connected/FormFlow/types';
import { addYears, differenceInYears, format, isValid } from 'date-fns';
import { MAX_DATE } from './constants';
import { DateLengthLabels } from './types';

export const convertToValidDateFormat = (date?: Date | number | string) => {
  if (!isValid(date)) return MAX_DATE;
  return format(date as Date, 'yyyy-MM-dd');
};

export const getStartDate = (startDate?: Date) => {
  return startDate ? new Date(startDate).getFullYear() : null;
};

export const getEndDate = (startDate: Date, val: number) => {
  return new Date(val, startDate.getMonth(), startDate.getDate() + 1);
};

export const getEndYear = (startDate: Date, selectedLength: string) => {
  const parsedStartDate = new Date(startDate);
  return addYears(parsedStartDate, parseInt(selectedLength, 10));
};

export const getDefaultEndYear = (startDate: Date, selectedValue: Date) => {
  const parsedStartDate = new Date(startDate);
  const parsedEndDate = new Date(selectedValue);
  const endYear = new Date(selectedValue).getFullYear();
  const leaseLength = differenceInYears(parsedEndDate, parsedStartDate);

  return {
    endYear,
    leaseLength,
  };
};

export const getLabel = (
  labelKey: DateLengthLabels,
  labels?: InlineLabelProps[],
) => {
  if (!labels || labels.length === 0) return null;
  return labels?.find(
    (inlineLabel: InlineLabelProps) => inlineLabel.key === labelKey,
  );
};
