import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import { getUpdateHistory } from 'store/actions/detailsActions';
import { DebtsActionTypes } from 'connected/DebtPanel/types';
import {
  debtsDeleteApiFail,
  debtsDeleteApiSuccess,
} from 'store/actions/debtsActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const updateFieldApiEndpoint = endpoints.editDetails;

const deleteDebtEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(DebtsActionTypes.DELETE_DEBT),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          url: updateFieldApiEndpoint,
          method: 'PUT',
          body: action.payload,
        })()
        .pipe(
          map((results) => {
            if (results.status > 204) {
              throw new Error(`error loading ${updateFieldApiEndpoint}`);
            }

            getUpdateHistory(action.payload);

            return debtsDeleteApiSuccess();
          }),
          catchError((error) => of(debtsDeleteApiFail(error))),
        );
    }),
  );
};

export default deleteDebtEpic;
