import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap,
  map,
  withLatestFrom,
  catchError,
  retryWhen,
  takeWhile,
  delay,
} from 'rxjs/operators';
import pathOr from 'ramda/src/pathOr';

import { endpoints, Vertical } from 'globalConstants';
import { RootState } from 'store/rootReducer';
import { AuthenticatedRequestObservable } from 'apis/request';
import {
  NewSaleActionTypes,
  NewSaleApiSuccessAction,
  NewPortfolioSaleApiSuccessAction,
} from 'pages/NewSale/types';
import {
  PollingNewRecordActionTypes,
  pollingNewRecordFail,
  pollingNewRecordSuccess,
  pollingNewRecordUnknown,
} from 'store/actions/pollingNewRecordActions';
import {
  NewLeaseApiSuccessAction,
  NewLeaseActionTypes,
} from 'pages/NewLease/types';
import { LeaseRecord } from 'pages/Details/types';

type IncomingActions =
  | NewLeaseApiSuccessAction
  | NewSaleApiSuccessAction
  | NewPortfolioSaleApiSuccessAction;

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const verticalActionMap = {
  [NewSaleActionTypes.NEW_SALE_API_SUCCESS]: Vertical.Sale,
  [NewSaleActionTypes.NEW_PORTFOLIO_SALE_API_SUCCESS]: Vertical.PortfolioSale,
  [NewLeaseActionTypes.NEW_LEASE_API_SUCCESS]: Vertical.Lease,
};

const checkResponse = (
  vertical: Vertical,
  state: any,
  results: any,
  recordId: string,
) => {
  if (vertical !== Vertical.Lease && results.response?.id === recordId) {
    return pollingNewRecordSuccess();
  }

  if (vertical !== Vertical.Lease) {
    throw new Error(PollingNewRecordActionTypes.POLLING_RETRY);
  }

  const leaseType = pathOr(
    'let',
    ['newRecord', 'formData', 'lease', 'leaseStatus', 'value'],
    state,
  );
  if (leaseType === 'to-let') {
    const leases = pathOr(
      null,
      ['response', 'leases'],
      results,
    ) as LeaseRecord[];

    if (leases.find((lease) => lease.leaseId === state.newRecord.recordId)) {
      return pollingNewRecordSuccess();
    }
  }
  const existingLeases: LeaseRecord[] = pathOr(
    [],
    ['details', 'building', 'leases'],
    state,
  );
  const createdLeases: LeaseRecord[] = pathOr(
    [],
    ['newRecord', 'formData', 'lease', 'demises'],
    state,
  );
  const returnedLeases = results.response.leases;
  const calculatedLeases = returnedLeases?.length - existingLeases.length;
  if (calculatedLeases === createdLeases.length) {
    return pollingNewRecordSuccess();
  }

  // need to include when a to-let lease is update to a under-offer/let
  if (JSON.stringify(returnedLeases) !== JSON.stringify(existingLeases)) {
    return pollingNewRecordSuccess();
  }

  throw new Error(PollingNewRecordActionTypes.POLLING_RETRY);
};

const pollingNewRecordEpic: Epic<
  IncomingActions,
  any,
  RootState,
  EpicDependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(
      NewSaleActionTypes.NEW_SALE_API_SUCCESS,
      NewSaleActionTypes.NEW_PORTFOLIO_SALE_API_SUCCESS,
      NewLeaseActionTypes.NEW_LEASE_API_SUCCESS,
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      let { recordId } = state.newRecord;
      const vertical = verticalActionMap[action.type];
      let url = endpoints.vertical.byId(vertical, recordId);

      if (vertical === Vertical.Lease) {
        recordId = state.details.resourceID;
        url = endpoints.vertical.byId(Vertical.Building, recordId);
      }

      let retryAttempts = 0;

      // if vertical is lease then need to check the building
      // for correct number of leases with parent_demise_id??
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url,
        })()
        .pipe(
          map((results) => {
            return checkResponse(vertical, state, results, recordId);
          }),
          retryWhen((errors) =>
            errors.pipe(
              delay(1000),
              takeWhile((error) => {
                if (
                  (error.statusCode === 404 && retryAttempts < 2) ||
                  error.message === PollingNewRecordActionTypes.POLLING_RETRY ||
                  retryAttempts < 2
                ) {
                  retryAttempts += 1;
                  return true;
                }

                throw error;
              }),
            ),
          ),
          catchError((error) => {
            retryAttempts = 0;

            return error.message === PollingNewRecordActionTypes.POLLING_RETRY
              ? of(pollingNewRecordFail())
              : of(pollingNewRecordUnknown());
          }),
        );
    }),
  );
};

export default pollingNewRecordEpic;
