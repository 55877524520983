import React from 'react';

import { StepsList, StepItem } from './ProgressStepsStyled';

type ProgressStepsProps = {
  steps: any[];
  activeStep: string;
};

const ProgressSteps = ({ steps, activeStep }: ProgressStepsProps) => {
  return (
    <StepsList>
      {steps.map((step) => (
        <StepItem isSelected={step.id === activeStep} key={step.id}>
          {step.title}
        </StepItem>
      ))}
    </StepsList>
  );
};

export default ProgressSteps;
