import { Option, OptionGroup } from 'components/Forms/types';

export const getSelectedCountries = (
  countries: OptionGroup[],
  selectedValue?: string,
) => {
  if (!selectedValue) return [];

  return countries.reduce((accum: Option[], group: OptionGroup) => {
    const filtered = group.options.filter(
      (country: Option) => country.value === selectedValue,
    );

    if (filtered.length) {
      return [...accum, ...filtered];
    }

    return accum;
  }, []);
};
