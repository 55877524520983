import { TableHeaderCellProps } from 'components/Table/types';
import { RESULT_HEADERS } from 'connected/SearchResults/constants';
import { Vertical } from 'globalConstants';
import { Reducer } from 'redux';
import {
  TableConfigActions,
  TableConfigActionsTypes,
} from 'store/actions/tableConfigActions';

export interface TableConfigState {
  updatedHeaders: {
    [key in Vertical]: TableHeaderCellProps[];
  };
}

export const initialState = {
  updatedHeaders: {
    [Vertical.Sale]: RESULT_HEADERS[Vertical.Sale],
    [Vertical.PortfolioSale]: RESULT_HEADERS[Vertical.PortfolioSale],
    [Vertical.Building]: RESULT_HEADERS[Vertical.Building],
    [Vertical.ToLet]: RESULT_HEADERS[Vertical.ToLet],
    [Vertical.Lease]: RESULT_HEADERS[Vertical.Lease],
    [Vertical.Company]: RESULT_HEADERS[Vertical.Company],
  },
};

const tableConfigReducer: Reducer<TableConfigState, TableConfigActions> = (
  state = initialState,
  action: TableConfigActions,
) => {
  switch (action.type) {
    case TableConfigActionsTypes.UPDATE_TABLE_HEADER_CONFIG: {
      return {
        ...state,
        updatedHeaders: {
          ...state.updatedHeaders,
          [action.payload.vertical]: action.payload.headers,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default tableConfigReducer;
