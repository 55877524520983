import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';
import { createLogger } from 'redux-logger';

import { authenticatedRequest, unauthenticatedRequest } from 'apis/request';

import { MapPolygonWithBoundary } from 'components/GoogleMap/types';
import getPreloadedState, { ApiState } from './preloadedState';
import rootReducer from './rootReducer';
import rootEpic from './rootEpic';
import unauthorizedRedirect from './middleware/unauthorizedRedirect';
import { userTracking } from './middleware/UserTracking';
import errorLogging from './middleware/errorLogging';

export default function configureStore(
  history: History,
  apiState?: ApiState,
  boundaries?: MapPolygonWithBoundary,
  logRedux?: boolean,
) {
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      history,
      authRequest: authenticatedRequest,
      request: unauthenticatedRequest,
    },
  });
  const middlewares = [
    // user tracking actions are dependent on epic middleware
    userTracking,
    epicMiddleware,
    errorLogging,
    unauthorizedRedirect(history),
  ];

  if (logRedux) {
    // eslint-disable-next-line no-console
    console.info('enabling redux logs');
    const logger = createLogger({
      diff: true,
      timestamp: false,
      collapsed: true,
      level: {
        prevState: false,
        nextState: false,
      },
    });
    middlewares.push(logger);
  }

  const preloadedState = getPreloadedState(history, apiState, boundaries);
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  epicMiddleware.run(rootEpic);

  return store;
}
