import { createSelector } from 'reselect';
import {
  WatchedRecordPayloadType,
  WatchedRecordTypes,
} from 'store/reducers/userProfileReducer';

import { RootState } from 'store/rootReducer';

export const getUserProfile = (state: RootState) => state.userProfile;

export const getUserProfileQueryResults = createSelector(
  getUserProfile,
  (userProfile) => userProfile.userQueries,
);

export const getUserScheduledQueryResults = createSelector(
  getUserProfile,
  (userProfile) => userProfile.scheduledQueries,
);

export const getWatchedRecordSelector = (state: RootState) =>
  state.userProfile.watchedRecords;

export const getWatchedBuildingsSelector = createSelector(
  getWatchedRecordSelector,
  (records) =>
    records?.filter(
      (record: WatchedRecordPayloadType) =>
        record.entityType === WatchedRecordTypes.BUILDING,
    ),
);

export const getWatchedCompaniesSelector = createSelector(
  getWatchedRecordSelector,
  (records) =>
    records?.filter(
      (record: WatchedRecordPayloadType) =>
        record.entityType === WatchedRecordTypes.COMPANY,
    ),
);
