import { MODAL_ID } from 'connected/GlobalModal/constants';
import {
  GlobalModalActions,
  GlobalModalActionTypes,
} from 'connected/GlobalModal/types';
import {
  RelatedPartyActions,
  RelatedPartyActionTypes,
} from 'connected/RelatedPartiesPanel/types';
import { RoleState } from 'pages/Details/types';

export interface RelatedPartyDeleteState {
  id: string;
  entity: string;
  employeeEntityName?: string;
  employeeEntityId?: string;
  isDeleting: boolean;
  error?: string;
}

export interface RelatedPartyPanelState {
  isFetching: boolean;
  hasError: boolean;
  error?: string;
  relatedPartyToDelete: RelatedPartyDeleteState;
}

export const initialState: RelatedPartyPanelState = {
  isFetching: false,
  hasError: false,
  error: '',
  relatedPartyToDelete: {
    id: '',
    entity: '',
    employeeEntityId: '',
    employeeEntityName: '',
    isDeleting: false,
    error: '',
  },
};

const relatedPartyReducer = (
  state = initialState,
  action: RelatedPartyActions | GlobalModalActions,
) => {
  switch (action.type) {
    case RelatedPartyActionTypes.DELETE_RELATED_PARTY: {
      return {
        ...state,
        relatedPartyToDelete: {
          ...state.relatedPartyToDelete,
          isDeleting: true,
        },
      };
    }

    case GlobalModalActionTypes.SHOW_MODAL: {
      if (
        action.payload.modal === MODAL_ID.RELATED_PARTY_DELETE &&
        action.meta?.key
      ) {
        const relatedParty = action.meta as RoleState;
        const toDelete: RelatedPartyDeleteState = {
          // eslint-disable-next-line no-underscore-dangle
          entity: relatedParty.__entityName__,
          id: relatedParty.key,
          employeeEntityName: relatedParty.employeeEntityName,
          employeeEntityId: relatedParty.employeeEntityId,
          isDeleting: false,
          error: '',
        };

        return {
          ...state,
          relatedPartyToDelete: toDelete,
        };
      }

      return state;
    }

    case GlobalModalActionTypes.HIDE_MODAL: {
      if (action.payload === 'relatedPartyDelete') {
        return {
          ...state,
          relatedPartyToDelete: initialState.relatedPartyToDelete,
        };
      }

      return state;
    }

    case RelatedPartyActionTypes.DELETE_RELATED_PARTY_API_FAIL: {
      return {
        ...state,
        relatedPartyToDelete: {
          ...state.relatedPartyToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case RelatedPartyActionTypes.DELETE_RELATED_PARTY_API_SUCCESS: {
      return {
        ...state,
        relatedPartyToDelete: {
          ...state.relatedPartyToDelete,
          isDeleting: false,
        },
      };
    }

    default:
      return state;
  }
};

export default relatedPartyReducer;
