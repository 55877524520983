import { Vertical } from 'globalConstants';
import { GlobalApiFailError } from 'store/actions/types';

export type Valuation = {
  entityId: string;
  __entityName__: string;
  id: string;
  valuationAmount: number;
  valuationDate: string;
  annualRentAtValuation?: number;
  valuationSource?: string;
  isVoided?: boolean;
};

export interface ValuationPanelProps {
  onPanelToggleCb?: (anchorId: string) => void;
  modalIsOpen?: boolean;
  modalId?: string;
}

export type ValuationsTableProps = {
  modalIsOpen: boolean;
  modalId: string;
  valuations?: Valuation[];
};

export enum ValuationsActionTypes {
  ADD_VALUATION = 'ADD_VALUATION',
  ADD_VALUATION_API_FAIL = 'ADD_VALUATION_API_FAIL',
  ADD_VALUATION_API_SUCCESS = 'ADD_VALUATION_API_SUCCESS',
  EDIT_VALUATION = 'EDIT_VALUATION',
  EDIT_VALUATION_API_FAIL = 'EDIT_VALUATION_API_FAIL',
  EDIT_VALUATION_API_SUCCESS = 'EDIT_VALUATION_API_SUCCESS',
  DELETE_VALUATION = 'DELETE_VALUATION',
  DELETE_VALUATION_API_FAIL = 'DELETE_VALUATION_API_FAIL',
  DELETE_VALUATION_API_SUCCESS = 'DELETE_VALUATION_API_SUCCESS',
}

export type DeleteValuationPayload = {
  id: string;
  entity: string;
  property: string;
  value: boolean;
  subSource: string;
};

export type AddValuationPayload = {
  valuation: number;
  annualRent?: number;
  source?: string;
  valuationDate: string;
  resourceId: string;
};

export type EditValuationPayload = {
  valuation: number;
  annualRent?: number;
  source?: string;
  valuationDate: string;
  resourceId: string;
  id: string;
  vertical: Vertical;
};

export type AddValuationAction = {
  type: ValuationsActionTypes.ADD_VALUATION;
  payload: AddValuationPayload;
};

export type AddValuationApiFailAction = {
  type: ValuationsActionTypes.ADD_VALUATION_API_FAIL;
  payload: GlobalApiFailError;
};

export type AddValuationSuccessAction = {
  type: ValuationsActionTypes.ADD_VALUATION_API_SUCCESS;
};

export type EditValuationAction = {
  type: ValuationsActionTypes.EDIT_VALUATION;
  payload: EditValuationPayload;
};

export type EditValuationApiFailAction = {
  type: ValuationsActionTypes.EDIT_VALUATION_API_FAIL;
  payload: GlobalApiFailError;
};

export type EditValuationSuccessAction = {
  type: ValuationsActionTypes.EDIT_VALUATION_API_SUCCESS;
};

export type ValuationDeleteAction = {
  type: ValuationsActionTypes.DELETE_VALUATION;
  payload: DeleteValuationPayload;
};

export type ValuationDeleteApiFailAction = {
  type: ValuationsActionTypes.DELETE_VALUATION_API_FAIL;
  payload: GlobalApiFailError;
};

export type ValuationDeleteSuccessAction = {
  type: ValuationsActionTypes.DELETE_VALUATION_API_SUCCESS;
};

export type ValuationsActions =
  | AddValuationAction
  | AddValuationApiFailAction
  | AddValuationSuccessAction
  | EditValuationAction
  | EditValuationApiFailAction
  | EditValuationSuccessAction
  | ValuationDeleteAction
  | ValuationDeleteApiFailAction
  | ValuationDeleteSuccessAction;
