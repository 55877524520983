import { DOCUMENT_TYPES } from 'globalConstants';
import { FilterCategories } from 'components/Filter/types';

export const FILTER_SECTIONS = [
  {
    sectionLabel: 'By document types',
    sectionType: FilterCategories.DOCUMENT_CATEGORY,
    sectionFilters: DOCUMENT_TYPES,
  },
];

export const FILTER_COPY = {
  nav: {
    select: 'Select All',
    clear: 'Clear All',
  },
};
