import { FilterDefinition, SingleFilter } from 'constants/filters/types';

export enum FilterPanelActionTypes {
  SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER',
  RESET_ACTIVE_FILTER = 'RESET_ACTIVE_FILTER',
  ADD_SINGLE_FILTER = 'ADD_SINGLE_FILTER',
  REMOVE_SINGLE_FILTER = 'REMOVE_SINGLE_FILTER',
  REMOVE_FILTER_KEYS = 'REMOVE_FILTER_KEYS',
}

export type SetActiveFilterAction = {
  type: FilterPanelActionTypes.SET_ACTIVE_FILTER;
  payload: FilterDefinition;
};

export const setActiveFilter = (
  payload: FilterDefinition,
): SetActiveFilterAction => ({
  type: FilterPanelActionTypes.SET_ACTIVE_FILTER,
  payload,
});

export type ResetActiveFilterAction = {
  type: FilterPanelActionTypes.RESET_ACTIVE_FILTER;
};

export const resetActiveFilter = (): ResetActiveFilterAction => ({
  type: FilterPanelActionTypes.RESET_ACTIVE_FILTER,
});

export type AddSingleFilterAction = {
  type: FilterPanelActionTypes.ADD_SINGLE_FILTER;
  payload: SingleFilter;
};

export const addSingleFilter = (
  payload: SingleFilter,
): AddSingleFilterAction => ({
  type: FilterPanelActionTypes.ADD_SINGLE_FILTER,
  payload,
});

export type RemoveSingleFilterAction = {
  type: FilterPanelActionTypes.REMOVE_SINGLE_FILTER;
  payload: string;
};

export const removeSingleFilter = (
  payload: string,
): RemoveSingleFilterAction => ({
  type: FilterPanelActionTypes.REMOVE_SINGLE_FILTER,
  payload,
});

export type RemoveFilterKeysAction = {
  type: FilterPanelActionTypes.REMOVE_FILTER_KEYS;
  payload: string[];
};

export const removeFilterKeys = (
  payload: string[],
): RemoveFilterKeysAction => ({
  type: FilterPanelActionTypes.REMOVE_FILTER_KEYS,
  payload,
});

export type FilterActions =
  | SetActiveFilterAction
  | ResetActiveFilterAction
  | AddSingleFilterAction
  | RemoveSingleFilterAction
  | RemoveFilterKeysAction;
