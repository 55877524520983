import { GlobalApiFailError } from './types';

export enum UsersListActions {
  FETCH_USERS_LIST = 'FETCH_USERS_LIST',
  FETCH_USERS_LIST_SUCCESS = 'FETCH_USERS_LIST_SUCCESS',
  FETCH_USERS_LIST_FAIL = 'FETCH_USERS_LIST_FAIL',

  FREEZE_USER = 'FREEZE_USER',
  FREEZE_USER_SUCCESS = 'FREEZE_USER_SUCCESS',
  FREEZE_USER_FAIL = 'FREEZE_USER_FAIL',

  UNFREEZE_USER = 'UNFREEZE_USER',
  UNFREEZE_USER_SUCCESS = 'UNFREEZE_USER_SUCCESS',
  UNFREEZE_USER_FAIL = 'UNFREEZE_USER_FAIL',
}

type User = {
  email: string;
  username: string;
  name: {
    display: string;
    first: string;
    last: string;
  };
  isAdmin: boolean;
  isActive: boolean;
  reason?: string;
};

export type UsersListPayloadType = User;

// GET init
export type FetchUsersListActionType = {
  type: UsersListActions.FETCH_USERS_LIST;
};
export const getUsersListAction = (): FetchUsersListActionType => ({
  type: UsersListActions.FETCH_USERS_LIST,
});
// GET OK
export type FetchUsersListSuccessActionType = {
  type: UsersListActions.FETCH_USERS_LIST_SUCCESS;
  payload: UsersListPayloadType[];
};
export const fetchUsersListAPISuccessAction = (
  payload: UsersListPayloadType[],
): FetchUsersListSuccessActionType => ({
  type: UsersListActions.FETCH_USERS_LIST_SUCCESS,
  payload,
});
// GET NOK
export type FetchUsersListFailActionType = {
  type: UsersListActions.FETCH_USERS_LIST_FAIL;
  payload: GlobalApiFailError;
};
export const fetchUsersListAPIFailAction = (
  payload: GlobalApiFailError,
): FetchUsersListFailActionType => ({
  type: UsersListActions.FETCH_USERS_LIST_FAIL,
  payload,
});

// -----------
// PUT freeze
export type FreezeUserPayloadType = {
  username: string;
  reason: string;
};
export type FreezeUserStatusType = {
  type: UsersListActions.FREEZE_USER;
  payload: FreezeUserPayloadType;
};
export const freezeUser = (
  payload: FreezeUserPayloadType,
): FreezeUserStatusType => ({
  type: UsersListActions.FREEZE_USER,
  payload,
});
// SUCCESS
export type FreezeUserSuccessActionType = {
  type: UsersListActions.FREEZE_USER_SUCCESS;
  payload: FreezeUserPayloadType;
};
export const freezeUserSuccessAction = (
  payload: FreezeUserPayloadType,
): FreezeUserSuccessActionType => ({
  type: UsersListActions.FREEZE_USER_SUCCESS,
  payload,
});
// FAIL
export type FreezeUserFailActionType = {
  type: UsersListActions.FREEZE_USER_FAIL;
  payload: GlobalApiFailError;
};
export const freezeUserFailAction = (
  payload: GlobalApiFailError,
): FreezeUserFailActionType => ({
  type: UsersListActions.FREEZE_USER_FAIL,
  payload,
});

// --------------
// PUT unfreeze
export type UnFreezeUserPayloadType = {
  username: string;
};
export type UnfreezeUserStatusType = {
  type: UsersListActions.UNFREEZE_USER;
  payload: UnFreezeUserPayloadType;
};
export const unfreezeUser = (
  payload: UnFreezeUserPayloadType,
): UnfreezeUserStatusType => ({
  type: UsersListActions.UNFREEZE_USER,
  payload,
});
// SUCCESS
export type UnFreezeUserSuccessActionType = {
  type: UsersListActions.UNFREEZE_USER_SUCCESS;
  payload: UnFreezeUserPayloadType;
};
export const unfreezeUserSuccessAction = (
  payload: UnFreezeUserPayloadType,
): UnFreezeUserSuccessActionType => ({
  type: UsersListActions.UNFREEZE_USER_SUCCESS,
  payload,
});
// FAIL
export type UnFreezeUserFailActionType = {
  type: UsersListActions.UNFREEZE_USER_FAIL;
  payload: GlobalApiFailError;
};
export const unfreezeUserFailAction = (
  payload: GlobalApiFailError,
): UnFreezeUserFailActionType => ({
  type: UsersListActions.UNFREEZE_USER_FAIL,
  payload,
});
