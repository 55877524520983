export const LEASE_PANEL_COPY = {
  unknown: 'Unknown',
  breakLabel: 'Add Break',
  rentLabel: 'Add Review',
  tooltip:
    'This lease has been hidden by an administrator - check the Notes for details',
};

export const UNKNOWN_FLOOR_NAME = 'UNKNOWN';

export const ANCHOR_LINKS = {
  break: 'lease-break-options',
  rent: 'lease-rent-reviews',
};
