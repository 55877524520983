import { createSelector } from 'reselect';

import { RootState } from 'store/rootReducer';
import { Partition } from 'pages/Details/types';

type PartitionMap = {
  [key: number]: Partition[];
};

export const buildingFloorsSelector = (state: RootState) =>
  state.details.building?.floors;

export const getAllBuildingPartitions = createSelector(
  buildingFloorsSelector,
  (floors) => {
    return floors?.reduce((accum, floor) => {
      const updatedPartitions = { ...accum };

      if (floor.partitions?.length > 0) {
        updatedPartitions[floor.floorLevel] = floor.partitions;
      }

      return updatedPartitions;
    }, {} as PartitionMap);
  },
);
