export const QUICK_SEARCH_OPTIONS = {
  buildings: {
    id: 1,
    name: 'building',
    label: 'Buildings',
    formLabel: 'Search by building address',
    formPlaceholder: 'Search by building address',
  },
  companies: {
    id: 2,
    name: 'company',
    label: 'Companies',
    formLabel: 'Search by company name',
    formPlaceholder: 'Search by company name',
  },
};

export const QUICK_SEARCH_COPY = {
  title: 'Or go to a specific building or company',
  searchButtonText: 'Search',
  resultsSummary: {
    displaying: {
      lessThanCap: 'Displaying',
      capReached: 'Displaying top',
    },
    results: {
      singular: 'result',
      plural: 'results',
    },
  },
  noResultsTitle: 'No matches found',
  noResultsForTerm: 'No matches were found for',
  noResultsAlterYourTerm: 'Please alter your search term',
  errorTitle: 'Oops, there appears to be a problem',
  errorText: 'Please try again in a few moments',
  alias: 'Is known as',
  alternativeAddress: 'Has alternative address',
  formerName: 'Has former name',
  tooltip: {
    alias: 'Alias of the',
    formerName: 'Former name of the',
    alternativeAddress: 'Alternative address of the',
  },
  showMoreResultsButtonText: 'Show more results',
};

export default QUICK_SEARCH_OPTIONS;
