import {
  GlobalModalActions,
  GlobalModalActionTypes,
} from 'connected/GlobalModal/types';
import { DetailsDocuments } from 'pages/Details/types';
import {
  DocumentPanelActions,
  DocumentPanelActionTypes,
  DocumentFilter,
} from 'connected/DocumentPanel/types';

export interface DocumentDeleteState {
  filename: string;
  entityId: string;
  isDeleting: boolean;
  error?: string;
  isPrimary?: boolean;
}

export interface DocumentPanelState {
  isFetching: boolean;
  downloadLink?: string;
  hasError: boolean;
  error?: string;
  documentToDelete: DocumentDeleteState;
  documentFilters?: DocumentFilter[];
}

export const initialState: DocumentPanelState = {
  isFetching: false,
  hasError: false,
  documentToDelete: {
    filename: '',
    entityId: '',
    isDeleting: false,
    error: '',
    isPrimary: false,
  },
};

const documentPanelReducer = (
  state = initialState,
  action: DocumentPanelActions | GlobalModalActions,
) => {
  switch (action.type) {
    case DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_GET_SIGNED_URL: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_API_SUCCESS: {
      return {
        ...state,
        downloadLink: action.payload.signedUrl,
        isFetching: false,
      };
    }

    case DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_API_FAIL: {
      return {
        ...state,
        hasError: true,
        error: action.payload.error,
        isFetching: false,
      };
    }

    case DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_RESET: {
      return {
        ...state,
        isFetching: false,
        downloadLink: '',
      };
    }

    case GlobalModalActionTypes.SHOW_MODAL: {
      if (action.payload.modal === 'fileDelete' && action.meta?.file) {
        const document = action.meta as DetailsDocuments;
        const toDelete: DocumentDeleteState = {
          entityId: document.entityId,
          filename: document.file.name,
          isDeleting: false,
          error: '',
          isPrimary: document.file.image?.isPrimary,
        };

        return {
          ...state,
          documentToDelete: toDelete,
        };
      }

      return state;
    }

    case GlobalModalActionTypes.HIDE_MODAL: {
      if (action.payload === 'fileDelete') {
        return {
          ...state,
          documentToDelete: initialState.documentToDelete,
        };
      }

      return state;
    }

    case DocumentPanelActionTypes.DELETE_DOCUMENT: {
      return {
        ...state,
        documentToDelete: {
          ...state.documentToDelete,
          isDeleting: true,
        },
      };
    }

    case DocumentPanelActionTypes.DELETE_DOCUMENT_API_FAIL: {
      return {
        ...state,
        documentToDelete: {
          ...state.documentToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case DocumentPanelActionTypes.DELETE_DOCUMENT_API_SUCCESS: {
      return {
        ...state,
        documentToDelete: {
          ...state.documentToDelete,
          isDeleting: false,
        },
      };
    }

    case DocumentPanelActionTypes.DOCUMENT_FILTERS: {
      return {
        ...state,
        documentFilters: action.payload,
      };
    }

    case DocumentPanelActionTypes.DOCUMENT_RESET_FILTERS: {
      return {
        ...state,
        documentFilters: undefined,
      };
    }

    default:
      return state;
  }
};

export default documentPanelReducer;
