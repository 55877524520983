import React from 'react';
import { useSelector } from 'react-redux';

import { Vertical } from 'globalConstants';
import { getCurrentModalVerticalSelector } from 'store/selectors/globalModalSelectors';
import MoveRecordConfirm from './MoveRecordConfirm';
import { CONFIRM_TYPE } from './types';
import MoveSaleConfirm from './MoveSaleConfirm';
import MoveBuildingSubElementConfirm from './MoveBuildingSubElementConfirm';

export const getConfirmContent = (type: CONFIRM_TYPE, vertical?: Vertical) => {
  if (
    type === CONFIRM_TYPE.MOVE_RECORD &&
    (vertical === Vertical.Lease || vertical === Vertical.ToLet)
  ) {
    return <MoveRecordConfirm />;
  }

  if (
    type === CONFIRM_TYPE.MOVE_RECORD &&
    (vertical === Vertical.Sale || vertical === Vertical.PortfolioSale)
  ) {
    return <MoveSaleConfirm />;
  }

  if (
    (type === CONFIRM_TYPE.MOVE_BUILDING_DEBT_RECORD ||
      type === CONFIRM_TYPE.MOVE_BUILDING_VALUATION_RECORD) &&
    vertical === Vertical.Building
  ) {
    return <MoveBuildingSubElementConfirm type={type} />;
  }
  return <></>;
};

const ConfirmChanges: React.FC<{ type: CONFIRM_TYPE }> = ({ type }) => {
  const vertical = useSelector(getCurrentModalVerticalSelector);
  return <>{getConfirmContent(type, vertical)}</>;
};

export default ConfirmChanges;
