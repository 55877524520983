import { ApiState } from 'store/preloadedState';

export type GlobalApiFailError = {
  error: string;
  errorCode: number;
  stack?: unknown;
};

export enum GlobalErrors {
  GENERIC_ERROR = 'GENERIC_ERROR',
}

export enum AuthActionTypes {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  AUTH_ERROR = 'AUTH_ERROR',
  UNAUTHORIZED_USER = 'UNAUTHORIZED_USER',
}

export type AuthLoginSuccessPayload = {
  destination: string;
  state: ApiState;
};

export type AuthLoginSuccess = {
  type: AuthActionTypes.LOGIN_SUCCESS;
  payload: AuthLoginSuccessPayload;
};

export type AuthLogoutSuccess = {
  type: AuthActionTypes.LOGOUT_SUCCESS;
};

export type AuthError = {
  type: AuthActionTypes.AUTH_ERROR;
  payload: Error;
  error: boolean;
};

export type UnauthorizedUser = {
  type: AuthActionTypes.UNAUTHORIZED_USER;
};

export type GlobalGenericErrorAction = {
  type: GlobalErrors.GENERIC_ERROR;
  payload: GlobalApiFailError;
};

export type AuthenticationTypes =
  | AuthLoginSuccess
  | AuthLogoutSuccess
  | AuthError
  | UnauthorizedUser;
