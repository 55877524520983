import React from 'react';
import ResultItem, { ResultItemProps } from './ResultItem';

const ResultItemWithChildren: React.FC<ResultItemProps> = ({
  result,
  onResultItemClick,
  mode,
  disableNonCanonicalResults,
}) => {
  return (
    <li key={`${result.id}-parent`}>
      <ul>
        <ResultItem
          key={result.id}
          result={result}
          onResultItemClick={onResultItemClick}
        />
        {result.otherAddresses?.map((child) => (
          <ResultItem
            key={child.id}
            result={child}
            onResultItemClick={onResultItemClick}
            isChild
            mode={mode}
            disableNonCanonicalResults={disableNonCanonicalResults}
          />
        ))}
      </ul>
    </li>
  );
};

export default ResultItemWithChildren;
