export const LESSOR_VIEW_COPY = {
  noMatchesMessage:
    'Based on the Affected To Let record you selected, the Landlord for your new lease will be set to: %%STRING_TO_REPLACE%%',
  manuallyAddedId: 'manuallyAddLessor',
};

export const LESSOR_PATHS = {
  noAffectedLeaseSelected: ['identifyAffectedLease', 'noneOfAbove', 'value'],
  affectedLeaseSelected: ['identifyAffectedLease', 'affectedToLet', 'value'],
  landlord: ['identifyLandlord', 'landlord', 'landlord'],
  leaseType: ['selectStatus', 'selectUseAndType', 'leaseType', 'value'],
};
