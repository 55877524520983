import { Reducer } from 'redux';

import { DetailsActionTypes } from 'pages/Details/types';
import { PollingUpdateActionTypes } from 'store/actions/pollingUpdateActions';
import { PollingUpdateState, POLLING_STATUSES } from './types';

export const initialState: PollingUpdateState = {
  polling: false,
  property: null,
  id: null,
  entity: null,
};

const pollingUpdate: Reducer<PollingUpdateState, any> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case DetailsActionTypes.UPDATE_DETAILS: {
      return {
        polling: true,
        property: action.payload.property,
        time: Date.now(),
        id: action.payload.id,
        entity: action.payload.entity,
        confirmationStatus: undefined,
      };
    }

    case PollingUpdateActionTypes.POLLING_UPDATE_SUCCESS: {
      return {
        ...state,
        polling: false,
        confirmationStatus: POLLING_STATUSES.SUCCESS,
      };
    }

    case PollingUpdateActionTypes.POLLING_UPDATE_FAIL: {
      return {
        ...state,
        polling: false,
        confirmationStatus: POLLING_STATUSES.ERROR,
      };
    }

    case PollingUpdateActionTypes.POLLING_UPDATE_UNKNOWN: {
      return {
        ...state,
        polling: false,
        confirmationStatus: POLLING_STATUSES.UNKNOWN,
      };
    }

    case PollingUpdateActionTypes.POLLING_UPDATE_COMPLETE: {
      return {
        ...initialState,
        property: state.property, // we still need this for use in HeadlineStatistics
      };
    }
    default: {
      return state;
    }
  }
};

export default pollingUpdate;
