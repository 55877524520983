import { of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { DetailsActionTypes } from 'pages/Details/types';

import {
  renewEntitySuccess,
  renewEntityFailure,
} from 'store/actions/detailsActions';
import { endpoints } from 'globalConstants';

type DetailsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const renewEntityEpic: Epic = (
  action$,
  state$,
  dependencies: DetailsEpicDependencies,
) => {
  return action$.pipe(
    ofType(DetailsActionTypes.RENEW_ENTITY),
    mergeMap((action) => {
      const { vertical } = action.payload;
      return dependencies
        .authRequest(state$, {
          method: 'POST',
          url: endpoints.renewEntity(vertical),
          body: action.payload,
        })()
        .pipe(
          map((results) => {
            if (results.status !== 200) {
              throw new Error(`error loading`);
            }

            return renewEntitySuccess();
          }),
          catchError((error) => {
            return of(renewEntityFailure(error));
          }),
        );
    }),
  );
};

export default renewEntityEpic;
