import styled from 'styled-components/macro';

export const GlobalScaffold = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${(props) => props.theme.fixedHeights.header} 1fr;
  grid-template-areas:
    'global-header'
    'global-main';
  min-height: 100vh;
`;

export const StandardPadding = styled.div`
  padding: 0 ${(props) => props.theme.spacing.large};
`;
