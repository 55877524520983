import styled, { css } from 'styled-components/macro';

import { StyledFieldsetFieldsProps } from '../types';

export const StyledFormView = styled.div`
  padding: ${(props) => props.theme.spacing.default} 0;
  margin: 0 ${(props) => props.theme.spacing.small} 0
    ${(props) => props.theme.spacing.default};
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    margin: 0 ${(props) => props.theme.spacing.large};
  }
`;

export const StyledTitle = styled.h3`
  margin: 0 0 20px 0;
`;

export const StyledFieldset = styled.fieldset`
  margin: ${(props) => props.theme.spacing.xsmall} 0;
  position: relative;

  &:first-child {
    margin: ${(props) => props.theme.spacing.small} 0
      ${(props) => props.theme.spacing.xsmall};
  }

  h3 {
    margin: 10px 0 0;
  }
`;

export const GroupPrompt = styled.h3`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
`;

export const GroupSubtitle = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
`;

export const GroupDescription = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
`;

export const Column = styled.div<{ width?: number }>`
  margin: 0 0 10px 0;
  ${(props) =>
    props.width &&
    css`
      grid-column: span ${props.width};
      flex-wrap: nowrap;
      display: flex;

      > div {
        width: 100%;

        > div {
          margin: 0;
        }
      }
    `}
`;

export const StyledFieldsetFields = styled.div<StyledFieldsetFieldsProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : `repeat(3, 1fr)`};
  grid-column-gap: ${(props) => props.theme.spacing.tablet};

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    grid-column-gap: ${(props) => props.theme.spacing.small};
  }
  > div {
    flex: 1;
    margin: 0;
    justify-content: space-evenly;
  }

  ${(props) =>
    props.additionalStyles &&
    props.additionalStyles.includes('inlineCheckbox') &&
    css`
      grid-template-columns: 1fr 1fr 1fr 25px;
    `}
`;

export const ListContainer = styled.div`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
`;

export const ListContainerHeading = styled.p`
  font-weight: bold;
`;

export const ListContainerItem = styled.li`
  list-style-type: disc;
  margin: ${(props) => props.theme.spacing.xsmall} 0;
`;

export const ListContainerList = styled.ul`
  margin: 0 ${(props) => props.theme.spacing.small};
`;
