import { TableRowProps } from 'components/Table/types';
import { FloorPartition, Floor } from 'connected/FormFlow/types';
import { FLOOR_USE_MAP } from 'constants/configMaps';
import { formatArea, formatFromMap, formatUpper } from 'services/format';
import { ACCOMMODATION_SCHEDULE_COPY } from './constants';

export const checkIfFloorHasPartition = (floor: any) => {
  return floor.partitions?.value?.length > 0;
};

export const checkIfFloorCanBeRemoved = (data: any) => {
  return data.reduce((accum: [], item: any, i: number) => {
    const isGroundFloorWithPartition =
      item.floorLevel.value > -1 &&
      item.floorLevel.value < 1 &&
      checkIfFloorHasPartition(item);
    const isFirstOrLast = data.length - 1 === i || i === 0;
    const isUpperLowerGroundFloor =
      item.floorLevel.value === 0.5 || item.floorLevel.value === -0.5;
    const formattedObject = {
      ...item,
      canBeRemoved:
        !item.hasLeases?.value &&
        !isGroundFloorWithPartition &&
        (isFirstOrLast || isUpperLowerGroundFloor),
    };

    return [...accum, formattedObject];
  }, []);
};

export const mapResponseToTableFormattedData = (
  record: Floor,
): TableRowProps => ({
  id: {
    value: record.floorId,
  },
  floorLevel: {
    value:
      record.floorLevel || record.floorLevel === 0
        ? `${record.floorLevel}`
        : undefined,
  },
  floorName: {
    value: record.floorName,
  },
  partitionName: {
    value: record.partitionName,
  },
  useType: {
    value: record.useType
      ? formatFromMap(record.useType, FLOOR_USE_MAP)
      : record.useType,
  },
  area: {
    value: record.areaSqFt ? formatArea(record.areaSqFt) : '',
  },
  measurementStandard: {
    value: record.measurementStandard,
  },
});

export const convertGroundLevel = (level: number) => {
  if (level === 0) {
    return ACCOMMODATION_SCHEDULE_COPY.ground;
  }
  if (level === 0.5) {
    return ACCOMMODATION_SCHEDULE_COPY.upper;
  }
  if (level === -0.5) {
    return ACCOMMODATION_SCHEDULE_COPY.lower;
  }

  return level;
};

export const formatFloorsPayload = (floors: Floor[]): Floor[] => {
  const formattedFloors = floors.reduce((accum: any, floor: any) => {
    const { partitions, ...rest } = floor;
    let partitionArea = floor.areaSqFt || 0;
    const floorPartitions = floor.partitions.reduce(
      (partitionAccum: FloorPartition[], partition: FloorPartition) => {
        if (partition.isHistoric) {
          return partitionAccum;
        }
        if (partition.areaSqFt) {
          partitionArea -= partition.areaSqFt;
        }
        return [
          ...partitionAccum,
          {
            ...rest,
            originalFloorLevel: rest.floorLevel,
            floorLevel: convertGroundLevel(rest.floorLevel),
            partitionName: partition.partitionName,
            useType: partition.useType,
            areaSqFt: partition.areaSqFt,
            measurementStandard: partition.measurementStandard,
            isHistoric: partition.isHistoric,
          },
        ];
      },
      [],
    );
    if (
      partitionArea &&
      partitionArea > 0 &&
      partitions.length > 0 &&
      floorPartitions.length === 0
    ) {
      floorPartitions.push({
        ...rest,
        originalFloorLevel: rest.floorLevel,
        floorLevel: convertGroundLevel(rest.floorLevel),
        useType: 'Unknown',
        areaSqFt: partitionArea,
        measurementStandard: '',
        isHistoric: rest.isHistoric,
      });
    }
    if (partitions.length === 0) {
      floorPartitions.push({
        ...rest,
        originalFloorLevel: rest.floorLevel,
        floorLevel: convertGroundLevel(rest.floorLevel),
      });
    }

    return [...accum, ...floorPartitions];
  }, []);

  return formattedFloors;
};

export const sortFloorLevelOrder = (rows: TableRowProps[]) => {
  return rows.sort(
    (a, b) => b.originalFloorLevel.value - a.originalFloorLevel.value,
  );
};

export const mapFloorToTableFormattedData = (record: Floor): TableRowProps => {
  const mapped = {
    id: {
      value: record.floorId,
    },
    floorLevel: {
      value:
        record.floorLevel || record.floorLevel === 0
          ? `${record.floorLevel}`
          : undefined,
    },
    originalFloorLevel: {
      value: record.originalFloorLevel,
    },
    floorName: {
      value: record.floorName,
    },
    partitionName: {
      value: record.partitionName,
    },
    useType: {
      value: record.useType
        ? formatFromMap(record.useType, FLOOR_USE_MAP)
        : record.useType,
    },
    area: {
      value: record.areaSqFt ? formatArea(record.areaSqFt) : record.areaSqFt,
    },
    measurementStandard: {
      value: formatUpper(record.measurementStandard),
    },
  };
  return mapped;
};

export const checkIfLevelExists = (data: any, level: number) => {
  return data.find(
    (item: any) => item.floorLevel.value === level || item.floorLevel === level,
  );
};

export const shouldShowTitle = (data: any, row: any) => {
  if (
    row.floorLevel.value === -0.5 ||
    row.floorLevel.value === 0 ||
    row.floorLevel.value === 0.5
  ) {
    return false;
  }
  return true;
};
