import React from 'react';
import isNil from 'ramda/src/isNil';
import { dynamicString, translateIndexToUserFacing } from 'utils/string';
import { MULTILINE_TYPE } from 'connected/EditModals/CreateFloorPartition/constants';

import { GroupPrompt, GroupDescription } from '../FormView/FormViewStyled';
import FormField from '../FormField/FormField';
import {
  MultiLineStyled,
  MultiLineWrapper,
  StyledButton,
  StyledCloseIcon,
  MultiLineHeader,
  FieldColumns,
  StyledButtonWrapper,
} from './FormMultilineStyled';
import {
  MultilineProps,
  LineConfigProps,
  MultilineFieldChangeHandler,
} from '../types';
import { FORM_BUILDER_COPY } from '../constants';

type MultilineFieldsetProps = Pick<
  MultilineProps,
  | 'prompt'
  | 'description'
  | 'lineConfig'
  | 'multilineTitle'
  | 'lineLimit'
  | 'groupId'
  | 'multilineType'
> & {
  rowFields: any;
  setRowFields?: React.Dispatch<any>;
  onFieldChange: MultilineFieldChangeHandler;
  handleDeleteLine: (index: number) => void;
  type?: string;
  columns?: number;
  minimumLines?: number;
};

const getItemValue = (entity?: any) => {
  if (typeof entity === 'undefined') return '';

  if (!isNil(entity.value)) return entity.value;

  return '';
};

const MultilineFieldset = ({
  prompt,
  description,
  lineConfig,
  rowFields,
  setRowFields,
  onFieldChange,
  handleDeleteLine,
  multilineTitle = 'line',
  lineLimit,
  groupId,
  multilineType = 'default',
  columns,
  minimumLines,
}: MultilineFieldsetProps) => {
  const getDeleteButton = (i: number, line: any) => {
    if (multilineType === MULTILINE_TYPE.CREATE_FLOOR) {
      if (line.floorLevel.value === 0) {
        return null;
      }
      return (
        <StyledButtonWrapper>
          <StyledButton
            onClick={() => {
              handleDeleteLine(i);
            }}
          >
            <StyledCloseIcon />
            {FORM_BUILDER_COPY.multiline.buttons.delete}
          </StyledButton>
        </StyledButtonWrapper>
      );
    }

    if (i >= (minimumLines ?? 1)) {
      return (
        <StyledButtonWrapper>
          <StyledButton
            onClick={() => {
              handleDeleteLine(i);
            }}
          >
            <StyledCloseIcon />
            {FORM_BUILDER_COPY.multiline.buttons.delete} {multilineTitle}
          </StyledButton>
        </StyledButtonWrapper>
      );
    }

    return <></>;
  };
  const getLine = (item: any, i: number) => (
    <MultiLineWrapper columns={columns} key={`${item.id}_${i}`}>
      {(!lineLimit || lineLimit !== 1) && (
        <MultiLineHeader columns={columns}>
          {multilineType === MULTILINE_TYPE.CREATE_FLOOR ? (
            <>
              {dynamicString(multilineTitle, [
                `${rowFields[i].floorLevel.value}`,
              ])}
            </>
          ) : (
            <>
              {translateIndexToUserFacing(i)} {multilineTitle}
            </>
          )}
        </MultiLineHeader>
      )}
      {lineConfig.map((line: LineConfigProps) => {
        const itemValue = getItemValue(item[line.name]);
        return (
          <FieldColumns key={`item${line.name}`} columns={line.columns}>
            <FormField
              name={`${line.name}-${i}`}
              label={line.label}
              handleOnChange={(value) => {
                onFieldChange(
                  i,
                  value,
                  line.name,
                  line.label,
                  line.componentType,
                  line.resetFormState,
                  line.resetRefsOnChange,
                );
              }}
              groupId={groupId}
              options={line.options}
              type={line.componentType}
              value={itemValue}
              lineValues={item}
              validation={line.validation}
              rowFields={rowFields}
              setRowFields={setRowFields}
              isGroup
              itemProps={{ ...line, groupId: '', fieldType: undefined }}
            />
          </FieldColumns>
        );
      })}
      {getDeleteButton(i, item)}
    </MultiLineWrapper>
  );

  return (
    <>
      {prompt && <GroupPrompt>{prompt}</GroupPrompt>}
      {description && <GroupDescription>{description}</GroupDescription>}
      <MultiLineStyled>{rowFields.map(getLine)}</MultiLineStyled>
    </>
  );
};

export default MultilineFieldset;
