import { Epic, ofType } from 'redux-observable';
import { AjaxResponse } from 'rxjs/ajax';
import { ignoreElements, tap } from 'rxjs/operators';

import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import { UserTrackingAction, UserTrackingActionType } from './types';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const logError = (ajax: AjaxResponse) => {
  // eslint-disable-next-line no-console
  console.error(ajax.response);
};

export const submitTrackingEvent: Epic = (
  action$,
  state$,
  { authRequest }: EpicDependencies,
) => {
  return action$.pipe(
    ofType(UserTrackingActionType),
    tap(({ payload }: UserTrackingAction) => {
      return authRequest(state$, {
        method: 'POST',
        body: payload,
        url: endpoints.telemetry,
      })()
        .toPromise()
        .catch(logError);
    }),
    ignoreElements(),
  );
};
