import styled, { css } from 'styled-components/macro';

export const RadioGroup = styled.div<{
  isInline?: boolean;
  isFullWidth?: boolean;
}>`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 100%;
  font-family: ${(props) => props.theme.fonts.secondary};

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    max-width: ${(props) =>
      props.isInline || props.isFullWidth ? '100%' : '66%'};
  }
`;

export const RadioItemWrapper = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
`;

const getPadding = (padding: string, isInline?: boolean) => {
  if (isInline) {
    return `0 ${padding} 0 0`;
  }

  return '0';
};

const getDisabledStyles = (isDisabled: boolean, palette: any) => {
  if (isDisabled) {
    return css`
      cursor: default;
      color: ${palette.darkGrey};
      span:first-child {
        border: 1px solid ${palette.lightGrey};
        background: ${palette.lightGrey};
        color: ${palette.darkGrey};
      }

      svg {
        opacity: 0.4;
      }
    `;
  }
  return ``;
};

const getInlineStyles = (isInline?: boolean) => {
  if (isInline) {
    return css`
      position: relative;
      margin-bottom: 20px;
      align-items: center;
    `;
  }
  return ``;
};

export const RadioLabel = styled.label<{
  isInline?: boolean;
  isDisabled: boolean;
}>`
  padding: ${(props) =>
    getPadding(props.theme.spacing.default, !props.isInline)};
  cursor: pointer;
  display: flex;
  min-height: 28px;

  ${(props) => getInlineStyles(props.isInline)}

  ${(props) =>
    getDisabledStyles(props.isDisabled, props.theme.colorPalette.secondary)}
`;

export const RadioValidationWrapper = styled.div`
  margin: ${(props) => props.theme.spacing.small} 0;
`;

export const StyledHeader = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  margin: 0 ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.small} 0;
`;

export const StyledIntro = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
`;

const getInlineLabelStyles = (isInline?: boolean) => {
  if (isInline) {
    return css`
      margin: 0;
      padding-left: 30px;
      display: block;
    `;
  }
  return ``;
};

export const LabelText = styled.span<{ isInline?: boolean }>`
  margin: 2px 0 0;
  ${(props) => getInlineLabelStyles(props.isInline)}
`;

const getInlineInputStyles = (isInline?: boolean) => {
  if (isInline) {
    return css`
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    `;
  }
  return css`
    position: relative;
    display: inline-block;
  `;
};
export const StyledInputWrapper = styled.span<{ isInline?: boolean }>`
  width: 20px;
  height: 20px;
  border: 2px solid ${(props) => props.theme.colorPalette.branding.ink};
  border-radius: 30px;
  margin-right: ${(props) => props.theme.spacing.small};
  cursor: pointer;
  flex-shrink: 0;

  ${(props) => getInlineInputStyles(props.isInline)}

  input {
    height: 0;
    width: 0;

    &:disabled {
      opacity: 0.6;
    }

    &:checked + span {
      position: absolute;
      display: block;
      left: 3px;
      top: 3px;
      content: '';
      width: 10px;
      height: 10px;
      background: ${(props) => props.theme.colorPalette.branding.yellow};
      border-radius: 30px;
    }
  }
`;

export const StyledIcon = styled.i`
  background: none;
  margin-left: -10px;
  svg {
    height: 24px;
  }
`;
