import {
  TelemetryStatisticsActions,
  TelemetryStatisticsPayloadType,
  FetchTelemetryStatisticsActionType,
  FetchTelemetryStatisticsSuccessActionType,
  FetchTelemetryStatisticsFailActionType,
} from 'store/actions/telemetryStatisticsActions';

export type TelemetryStatistics = {
  isFetching: boolean;
  errors?: string[];
  results: TelemetryStatisticsPayloadType[];
};

export const initialState: TelemetryStatistics = {
  isFetching: false,
  errors: [],
  results: [],
};

const telemetryStatisticsReducer = (
  state = initialState,
  action:
    | FetchTelemetryStatisticsActionType
    | FetchTelemetryStatisticsSuccessActionType
    | FetchTelemetryStatisticsFailActionType,
) => {
  switch (action.type) {
    case TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS: {
      return {
        isFetching: true,
        errors: [],
        results: [],
      };
    }
    case TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS_SUCCESS: {
      return {
        isFetching: false,
        errors: [],
        results: action.payload,
      };
    }

    default:
      return state;
  }
};

export default telemetryStatisticsReducer;
