import React from 'react';

import NotificationPanel from 'components/NotificationPanel/NotificationPanel';
import { NOTIFICATION_TYPES } from 'components/NotificationPanel/types';

import FormSummary from '../FormSummary';
import FormField from '../FormField/FormField';
import FormViewErrors from '../FormErrors/FormErrors';
import DataSources from '../DataSources/DataSources';
import FindMatches from '../FindMatches';
import IdentifyBuildings from '../IdentifyBuildings/IdentifyBuildings';

import MultilineWithGroupedRepeat from '../FormMultiline/MultiLineWithGroupedRepeat';
import MultilineBuyer from '../FormMultiline/MultilineBuyer';

import {
  StyledFormView,
  StyledTitle,
  StyledFieldset,
  StyledFieldsetFields,
  GroupPrompt,
  Column,
  GroupSubtitle,
  ListContainer,
  ListContainerItem,
  ListContainerList,
  ListContainerHeading,
} from './FormViewStyled';

import {
  getGroupValue,
  getFieldComponentOptions,
  isReadOnly,
} from '../services/formState';

import {
  MANDATORY_VIEWS,
  FIELD_COMPONENTS,
  FormViewProps,
  FieldProps,
  UpdateRefsProps,
} from '../types';
import { getSummaryFields } from '../services/summary';
import CompanyFieldset from '../CompanyFieldset';
import InformationalPanel from '../InformationalPanel';
import IdentifyAddress from '../IdentifyAddress/IdentifyAddress';
import IdentifyLeases from '../IdentifyLeases/IdentifyLeases';
import ConfirmChanges from '../ConfirmChanges/ConfirmChanges';
import { getId, getLabel } from './helpers';

const getList = (list: { title: string; items: string[] }) => {
  return (
    <ListContainer>
      <ListContainerHeading>{list.title}</ListContainerHeading>
      <ListContainerList>
        {list.items.map((item) => (
          <ListContainerItem>{item}</ListContainerItem>
        ))}
      </ListContainerList>
    </ListContainer>
  );
};

const FormView = ({
  configs,
  viewConfig,
  formState,
  onChange,
  handleDocumentUploadChange,
  goDirectToView,
  onSourceChange,
  onAllSourcesChange,
  viewErrors,
  existingPriorRecords,
  existingValues,
  recordId,
}: FormViewProps) => {
  const {
    title,
    visibleGroups,
    viewId,
    hideTitle,
    summaryFields,
    informationalPanel,
    hideMultilineTitle,
    list,
  } = viewConfig;
  const currentState = formState[viewId];
  const viewSummary = summaryFields
    ? getSummaryFields(formState, summaryFields)
    : null;

  return (
    <StyledFormView>
      {title && !hideTitle && <StyledTitle>{title}</StyledTitle>}
      {list && getList(list)}
      {viewErrors && viewErrors.length > 0 && (
        <FormViewErrors viewErrors={viewErrors} />
      )}

      {informationalPanel && <InformationalPanel type={informationalPanel} />}
      {existingPriorRecords &&
        existingPriorRecords.returnedMatches?.length > 0 && (
          <NotificationPanel
            notificationType={NOTIFICATION_TYPES.WARNING}
            notificationBody={existingPriorRecords.body}
            notificationTitle={`${existingPriorRecords.returnedMatches?.length} ${existingPriorRecords.title}`}
          />
        )}

      {/* FORM VIEW MAIN CONTENTS */}

      {viewId === MANDATORY_VIEWS.DATA_SOURCES && (
        <DataSources
          formState={formState}
          configs={configs}
          goDirectToView={goDirectToView}
          onSourceChange={onSourceChange}
          onAllSourcesChange={onAllSourcesChange}
          hideMultilineTitle={hideMultilineTitle}
        />
      )}

      {viewSummary && <FormSummary summaryFields={viewSummary} />}

      {existingPriorRecords?.returnedMatches && (
        <FindMatches
          {...existingPriorRecords}
          onSelect={(
            value: string,
            label: string,
            id: string,
            updateRefOnChange: UpdateRefsProps[] | [],
          ) => {
            onChange(value, label, id, {
              resetRefsOnChange: existingPriorRecords.resetRefsOnChange,
              updateRefsOnChange: updateRefOnChange,
              componentType: FIELD_COMPONENTS.FIND_MATCHES,
            });
          }}
          selectedValue={
            existingPriorRecords && existingPriorRecords.isSelectable
              ? getGroupValue(
                  currentState,
                  formState,
                  existingPriorRecords.isSelectable.name,
                )
              : ''
          }
          sortBy={existingPriorRecords.sortBy}
          sortByType={existingPriorRecords.sortByType}
        />
      )}

      {visibleGroups?.map((group) => {
        const handleOnChangeHandler = (
          value: string,
          label?: string,
          id?: string,
        ) => {
          onChange(value, getLabel(group, label), getId(group, id), group);
        };

        const groupValue = getGroupValue(
          currentState,
          formState,
          group.groupId,
          null,
          group.prepopulated,
          group.defaultValue,
        );

        return (
          <StyledFieldset key={group.groupId}>
            {group.componentType ===
              FIELD_COMPONENTS.MULTILINE_GROUPED_REPEAT && (
              <MultilineWithGroupedRepeat
                group={group}
                value={groupValue}
                handleOnChange={handleOnChangeHandler}
              />
            )}

            {group.componentType === FIELD_COMPONENTS.MULTILINE_BUYER && (
              <MultilineBuyer
                group={group}
                value={groupValue}
                handleOnChange={handleOnChangeHandler}
              />
            )}

            {group.componentType === FIELD_COMPONENTS.COMPANY_FIELDSET && (
              <>
                {group.prompt && <GroupPrompt>{group.prompt}</GroupPrompt>}
                {group.subtitle && (
                  <GroupSubtitle>{group.subtitle}</GroupSubtitle>
                )}
                <CompanyFieldset
                  group={group}
                  currentState={currentState}
                  onChange={onChange}
                  formState={formState}
                  columns={group.columns || 3}
                />
              </>
            )}

            {group.componentType === FIELD_COMPONENTS.IDENTIFY_BUILDINGS && (
              <IdentifyBuildings
                onChange={onChange}
                group={group}
                value={getGroupValue(currentState, formState, group.groupId)}
              />
            )}

            {group.componentType === FIELD_COMPONENTS.IDENTIFY_ADDRESS && (
              <IdentifyAddress
                onChange={onChange}
                group={group}
                value={getGroupValue(currentState, formState, group.groupId)}
              />
            )}

            {group.componentType === FIELD_COMPONENTS.MOVE_OTHER_LEASES && (
              <IdentifyLeases
                onChange={onChange}
                group={group}
                leaseId={recordId}
                value={getGroupValue(currentState, formState, group.groupId)}
              />
            )}

            {group.confirmType &&
              group.componentType === FIELD_COMPONENTS.CONFIRM_CHANGES && (
                <ConfirmChanges type={group.confirmType} />
              )}

            {group.componentType === FIELD_COMPONENTS.FIELDSET && (
              <>
                {group.prompt && <GroupPrompt>{group.prompt}</GroupPrompt>}
                {group.subtitle && (
                  <GroupSubtitle>{group.subtitle}</GroupSubtitle>
                )}
                <StyledFieldsetFields
                  additionalStyles={group.additionalStyles}
                  columns={group.columns}
                >
                  {group.fields?.map((field: FieldProps) => (
                    <Column width={field.columns} key={field.fieldId}>
                      <FormField
                        existingValues={existingValues}
                        isGroup
                        type={field.componentType}
                        name={field.name}
                        subtitle={field.subtitle}
                        label={field.label}
                        groupId={group.groupId}
                        columns={group.columns}
                        inlineLabels={field.inlineLabels}
                        prompt={field.prompt}
                        formState={formState}
                        options={getFieldComponentOptions(field, formState)}
                        validation={field.validation}
                        readOnly={isReadOnly(formState, field.readOnly)}
                        itemProps={field}
                        value={getGroupValue(
                          currentState,
                          formState,
                          group.groupId,
                          field.fieldId,
                          field.prepopulated,
                          field.defaultValue,
                        )}
                        hasDefaultValue={!!field.defaultValue}
                        decimals={field.decimals}
                      />
                    </Column>
                  ))}
                </StyledFieldsetFields>
              </>
            )}

            {group.componentType !==
              FIELD_COMPONENTS.MULTILINE_GROUPED_REPEAT &&
              group.componentType !== FIELD_COMPONENTS.FIELDSET && (
                <FormField
                  existingValues={existingValues}
                  type={group.componentType}
                  name={group.name}
                  label={group.label}
                  prompt={group.prompt}
                  description={group.description}
                  groupId={group.groupId}
                  itemProps={group}
                  columns={group.columns}
                  options={getFieldComponentOptions(group, formState)}
                  validation={group.validation}
                  onDocumentUploadChange={handleDocumentUploadChange}
                  fileInputType={group.fileInputType}
                  fields={group.fields}
                  readOnly={isReadOnly(formState, group.readOnly)}
                  lineLimit={group.lineLimit}
                  multilineTitle={group.multilineTitle}
                  value={getGroupValue(
                    currentState,
                    formState,
                    group.groupId,
                    null,
                    group.prepopulated,
                    group.defaultValue,
                  )}
                />
              )}
          </StyledFieldset>
        );
      })}
    </StyledFormView>
  );
};

export default FormView;
