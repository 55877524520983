import React from 'react';

import { globalVariables } from 'globalConstants';
import SelectSearch from '../SelectSearch/SelectSearch';
import { MultiSelectProps } from './types';

const MultiSelect: React.FC<MultiSelectProps> = ({
  name,
  label,
  onChange,
  selectedValue,
  onDelete,
  options,
}) => {
  return (
    <SelectSearch
      id={name}
      name={name}
      options={options}
      label={label}
      placeholder=""
      onChange={(choices) => {
        const selectedValues = Object.values(choices).reduce((accum, value) => {
          if (value && Object.prototype.hasOwnProperty.call(value, 'value')) {
            return [...accum, value];
          }

          return accum;
        }, [] as any);

        onChange(selectedValues);
      }}
      multipleSelect
      maxWidth={globalVariables.SELECT_MAX_WIDTH}
      selectedOptions={selectedValue}
      onDelete={onDelete}
      labelZIndex={1}
    />
  );
};

export default MultiSelect;
