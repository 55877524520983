import { FormEvent } from 'react';
import { GlobalApiFailError } from 'store/actions/types';
import { ResourceIdentifiers } from 'pages/Details/types';
import { Vertical } from 'globalConstants';
import searchTypes from './constants';

export type BuildingsSearchType = typeof searchTypes.buildings;

export type CompaniesSearchType = typeof searchTypes.companies;

export enum QuickSearchActionTypes {
  QUICK_SEARCH_QUERY = 'QUICK_SEARCH_QUERY',
  QUICK_SEARCH_API_SUCCESS = 'QUICK_SEARCH_API_SUCCESS',
  QUICK_SEARCH_API_FAIL = 'QUICK_SEARCH_API_FAIL',
  QUICK_SEARCH_RESET = 'QUICK_SEARCH_RESET',
  QUICK_SEARCH_TOGGLE_VERTICAL = 'QUICK_SEARCH_TOGGLE_VERTICAL',
  QUICK_SEARCH_ITEM_CLICKED = 'QUICK_SEARCH_ITEM_CLICKED',
  QUICK_SEARCH_SET_VERTICAL = 'QUICK_SEARCH_SET_VERTICAL',
}

export type QuickSearchQueryAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_QUERY;
  payload: {
    keyword: string[];
    pageNumber: number;
    skipResetDetails?: boolean;
  };
};

export type QuickSearchQueryItemClickedAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_ITEM_CLICKED;
  payload: {
    vertical: string;
    entityId: string;
  };
};

export type QuickSearchApiSuccessAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_API_SUCCESS;
  payload: QuickSearchResponse;
};

export type QuickSearchApiFailAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_API_FAIL;
  payload: GlobalApiFailError;
};

export type QuickSearchResetAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_RESET;
  payload?: boolean;
};

export type QuickSearchToggleVerticalAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_TOGGLE_VERTICAL;
  payload: string;
};

export type QuickSearchSetVerticalAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_SET_VERTICAL;
  payload: string;
};

export type QuickSearchOnResultItemClick = (
  payload: ResourceIdentifiers,
) => void;

export type QuickSearchColorMode = 'light' | 'dark';

export type QuickSearchProps = {
  onCloseButtonClick?: () => void;
  onInputFocus?: () => void;
  onResultItemClick?: QuickSearchOnResultItemClick;
  onSearchTermSet?: () => void;
  searchIsActive: boolean;
  showSummary?: boolean;
  isSingleSearchType?: boolean;
  defaultSearchType?: BuildingsSearchType;
  hideCloseButton?: boolean;
  mode?: QuickSearchColorMode;
  disableNonCanonicalResults?: boolean;
  formEmbed?: boolean;
  resetDetails?: boolean;
};

export type QuickSearchFormProps = {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  formEmbed?: boolean;
};

export type QuickSearchTitleProps = {
  searchIsActive: boolean;
};

type QuickSearchResultBase = {
  id: string;
  address: string;
  otherAddresses?: QuickSearchResult[];
};

export type QuickSearchResultBuilding = QuickSearchResultBase & {
  type: Vertical.Building;
  primaryUse: string;
  alternativeAddress?: string;
  formerName?: string;
  alias?: string;
};

export type QuickSearchResultCompany = QuickSearchResultBase & {
  type: Vertical.Company;
  name: string;
};

export type QuickSearchResultMeta = {
  page: number;
  pages: number;
  pageSize: number;
};

export type QuickSearchResponse = {
  results: QuickSearchResult[];
  meta: QuickSearchResultMeta;
};

export type QuickSearchResult =
  | QuickSearchResultBuilding
  | QuickSearchResultCompany;

export type QuickSearchActions =
  | QuickSearchApiSuccessAction
  | QuickSearchApiFailAction
  | QuickSearchQueryAction
  | QuickSearchResetAction
  | QuickSearchQueryItemClickedAction
  | QuickSearchToggleVerticalAction
  | QuickSearchSetVerticalAction;

export type QuickSearchOptions = {
  id: number;
  name: string;
  label: string;
  formLabel: string;
  formPlaceholder: string;
};
