import { Vertical } from 'globalConstants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { detailsFetch, detailsReset } from 'store/actions/detailsActions';

import {
  getLeasePageData,
  getSalePageData,
} from 'store/selectors/detailsSelectors';

import { RecordNotFoundCopy } from './constants';
import {
  AthenaLogo,
  NotFoundDescription,
  NotFoundTitle,
  PageLayout,
} from './ErrorStyled';

const RecordNotFoundPage = () => {
  const { id, vertical } = useParams<{ id: string; vertical: Vertical }>();
  const dispatch = useDispatch();
  const fetchEntityDetails = () => {
    dispatch(detailsReset());

    if (id) {
      dispatch(detailsFetch({ id, vertical: vertical as Vertical }));
    }
  };

  useEffect(fetchEntityDetails, [id, dispatch]);

  const leaseData = useSelector(getLeasePageData);
  const saleData = useSelector(getSalePageData);
  const recordData =
    vertical === Vertical.Lease || vertical === Vertical.ToLet
      ? leaseData
      : saleData;

  return (
    <PageLayout>
      <AthenaLogo />
      <NotFoundTitle>{RecordNotFoundCopy[vertical].heading}</NotFoundTitle>
      <NotFoundDescription>
        {RecordNotFoundCopy[vertical].description} <br />
        <br />
        <span>{recordData?.deletedReason}</span>
      </NotFoundDescription>
    </PageLayout>
  );
};

export default RecordNotFoundPage;
