import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';

const GlobalErrorToast = () => {
  return (
    <ToastContainer
      autoClose={8000}
      position="bottom-left"
      transition={Slide}
      rtl
      newestOnTop
      pauseOnHover
      closeOnClick
      enableMultiContainer
      containerId="global-error-toast"
    />
  );
};

export default GlobalErrorToast;
