import isAfter from 'date-fns/isAfter';

export const checkIsValidDate = (
  input: string,
  minDate?: string,
  maxDate?: string,
) => {
  let isValid = true;

  if (minDate) {
    isValid = isAfter(new Date(input), new Date(minDate));
  }

  if (maxDate) {
    isValid = isAfter(new Date(maxDate), new Date(input));
  }

  return isValid;
};
