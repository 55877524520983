import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  ScheduleTeamQueryActions,
  scheduleTeamQueryFail,
  scheduleTeamQuerySuccess,
} from 'store/actions/scheduleTeamQueryActions';

type ScheduleTeamQueryEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const scheduleTeamQueryEndpoint = endpoints.scheduleTeamQuery;

const scheduleTeamQueryEpic: Epic = (
  action$,
  state$,
  dependencies: ScheduleTeamQueryEpicDependencies,
) => {
  return action$.pipe(
    ofType(ScheduleTeamQueryActions.SCHEDULE_TEAM_QUERY),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'POST',
          url: scheduleTeamQueryEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`POST ${scheduleTeamQueryEndpoint} failed`);
            }

            return of(scheduleTeamQuerySuccess(response.response));
          }),
          catchError((error) => {
            return of(scheduleTeamQueryFail(error));
          }),
        );
    }),
  );
};

export default scheduleTeamQueryEpic;
