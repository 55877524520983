import {
  FeatureFlagError,
  FEATURE_FLAGS,
  FeatureFlagState,
  FeatureFlagType,
} from './types';

export const ensureFeaturesValid = (features: any): FeatureFlagState => {
  if (!features) {
    return {};
  }

  if (features.error) {
    // eslint-disable-next-line no-console
    console.error('Features has error', features);

    return features;
  }

  if (!Array.isArray(features)) {
    // eslint-disable-next-line no-console
    console.error('Features are not in expected schema', features);

    return { error: { message: 'Validation Failed' } };
  }

  const isFeatureBoolCollection = features.every(
    (f) => f.key && typeof f.value === 'boolean',
  );

  if (!isFeatureBoolCollection) {
    // eslint-disable-next-line no-console
    console.error('Feature validation failed', features);

    return { error: { message: 'Validation Failed' } };
  }

  // ignore unknown feature flags
  return {
    features: features.filter((f) =>
      Object.values(FEATURE_FLAGS).includes(f.key),
    ),
  };
};

export const parseFeaturesFromQueryString = (
  qs?: string,
): FeatureFlagError | undefined | unknown => {
  if (!qs) return undefined;

  const json = new URLSearchParams(qs).get('features');

  if (!json) {
    return undefined;
  }

  try {
    const featureList = json.split(',');
    return featureList.reduce((accum: FeatureFlagType[], item) => {
      const itemAttributes = item.split(':');
      return [
        ...accum,
        { key: itemAttributes[0], value: itemAttributes[1] === 'on' },
      ];
    }, []);
  } catch {
    return {
      error: {
        json,
        message: 'Malformed JSON',
        s3Path: 'URL',
      },
    };
  }
};
