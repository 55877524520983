// eslint-disable-next-line no-restricted-imports
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colorPalette: {
    body: '#fff',
    branding: {
      yellow: '#ffdf00',
      ink: '#25273a',
      white: '#fff',
    },
    secondary: {
      red: '#CE181E',
      darkGrey: '#4C4D50',
      midGrey: '#79828C',
      grey: '#B2B2B2',
      lightGrey: '#d8d8d8',
      backgroundGrey: '#F4F4F5',
      steel: '#6A769F',
      lightInk: '#494b6a',
    },
    tertiary: {
      datavizGreen: '#46B75B',
      datavizTeal: '#3FB3C7',
      datavizPurple: '#821753',
      datavizOrange: '#E95307',
      datavizPink: '#DA33A2',
    },
    notifications: {
      warning: '#FFA51B',
      information: '#5AA2F2',
      error: '#CE181E',
      success: '#46B75B',
    },
    text: {
      white: '#fff',
      dark: '#25273a',
      placeholder: '#000000',
    },
    linearLoader: {
      dark: {
        base: '#8083a0',
        intermediate: '#c1c6ef',
        highlight: '#d2d4e8',
      },
    },
    panel: {
      default: '#F5F5F6',
      blue: '#F0F9FB',
      yellow: '#FFFDED',
      pink: '#F9F3F6',
      green: '#F1FAF2',
      orange: '#FCE6DD',
      red: '#EC9292',
      grey: '#e6e8ef',
    },
    headers: {
      default: '#494b6a',
      blue: '#F0F9FB',
      yellow: '#FFFDED',
      pink: '#821753',
      green: '#6c9173',
      orange: '#FCE6DD',
      red: '#CC3333',
      grey: '#79828c',
    },
    stackingPlan: {
      withinYear: '#EA5207',
      within2Years: '#FFDE00',
      within3Years: '#46B75B',
      over4Years: '#40B3C7',
      vacant: '#821753',
      unavailable: '#BABBC1',
    },
  },
  modal: {
    boxShadow: '0 0 40px 0 rgba(37,39,58,1)',
    light: 'rgba(255, 255, 255, .5)',
    dark: 'rgba(37,39,58,0.75)',
  },
  fonts: {
    primary: '"Domine", serif',
    secondary: '"Noto sans", sans-serif',
  },
  cardBoxShadow: '0 0 8px 0 rgba(37, 39, 58, 0.15)',
  breakpoints: {
    large: 1198,
    xlarge: 1448,
  },
  fixedHeights: {
    header: '64px',
  },
  spacing: {
    large: '50px',
    default: '30px',
    small: '15px',
    xsmall: '5px',
    tablet: '10px',
  },
  typography: {
    paragraph: {
      xlarge: '28px',
      large: '18px',
      default: '16px',
      small: '14px',
      xsmall: '12px',
    },
    heading: {
      xsmall: '20px',
      small: '24px',
      medium: '32px',
      large: '40px',
      xlarge: '48px',
    },
  },
  disabledButton: `
    cursor: default;
    svg {
      fill: #79828C;
    }
    &:focus,
    &:hover {
      text-decoration: none;
    }
  `,
  defaultBoxShadow: '0 0 40px 0 rgba(37,39,58,0.5)',
};

export default theme;
