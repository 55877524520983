import { of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';

import { endpoints } from 'globalConstants';
import {
  AthenaUserActionTypes,
  fetchAthenaUserAPIFail,
  fetchAthenaUserAPISuccess,
} from 'store/actions/athenaUserActions';

type AthenaUserEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const getAthenaUser: Epic = (
  action$,
  state$,
  dependencies: AthenaUserEpicDependencies,
) => {
  return action$.pipe(
    ofType(AthenaUserActionTypes.FETCH_ATHENA_USER),
    mergeMap(() => {
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url: endpoints.athenaUser,
        })()
        .pipe(
          map((ajaxResponse) => {
            if (ajaxResponse.status !== 200) {
              throw new Error(`error loading`);
            }

            return fetchAthenaUserAPISuccess(ajaxResponse.response);
          }),
          catchError((error) => {
            return of(fetchAthenaUserAPIFail(error));
          }),
        );
    }),
  );
};

export default getAthenaUser;
