import { CoreEntity, Vertical } from 'globalConstants';
import { MouseEventHandler } from 'react';
import { GlobalApiFailError } from 'store/actions/types';

export enum DocumentUploaderUIStage {
  BEFORE_UPLOAD = 'BEFORE_UPLOAD',
  DURING_UPLOAD = 'DURING_UPLOAD',
  AFTER_UPLOAD = 'AFTER_UPLOAD',
}

export interface DocumentUploaderState {
  uploaderStage: DocumentUploaderUIStage;
  progress: number;
  error?: string;
  isDuplicate?: boolean;
}

export type FileToUpload = Pick<File, 'name' | 'size'>;

export type FileUploadType = {
  value: string;
  label: string;
};

export enum DocumentUploadChangeEnum {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface DocumentUploadChangeHandlerParams {
  fileDetails?: File;
  fileType?: FileUploadType;
  filenameToDelete?: string;
  changeType?: DocumentUploadChangeEnum;
}

export type OnDocumentUploadChangeHandler = (
  params: DocumentUploadChangeHandlerParams,
) => void;

export type DocumentUploaderAdditionalEntity = {
  entityId: string;
  vertical: Vertical;
};

export type DocumentUploaderProps = {
  entityId: string;
  onCancelClick?: MouseEventHandler;
  preselectedFileType?: FileUploadType;
  disableFileTypeSelect?: boolean;
  showErrorPrompts?: boolean;
  showUploadControls?: boolean;
  uploaderContextInFormFlow?: boolean;
  onDocumentUploadChange?: OnDocumentUploadChangeHandler;
  additionalEntities?: DocumentUploaderAdditionalEntity[];
};

export type EntityDocumentUpload = {
  filename: string;
  entityId: string;
  entityType: CoreEntity;
  type: string;
  file: File;
  lastModified: Date;
};

export enum DocumentUploaderActionTypes {
  DOCUMENT_UPLOADER_START_UPLOAD = 'DOCUMENT_UPLOADER_START_UPLOAD',
  DOCUMENT_UPLOADER_CANCEL_UPLOAD = 'DOCUMENT_UPLOADER_CANCEL_UPLOAD',
  DOCUMENT_UPLOADER_PROGRESS_UPDATE_UPLOAD = 'DOCUMENT_UPLOADER_PROGRESS_UPDATE_UPLOAD',
  DOCUMENT_UPLOADER_API_SUCCESS = 'DOCUMENT_UPLOADER_API_SUCCESS',
  DOCUMENT_UPLOADER_SUCCESS_MESSAGE_SHOWN = 'DOCUMENT_UPLOADER_SUCCESS_MESSAGE_SHOWN',
  DOCUMENT_UPLOADER_API_FAIL = 'DOCUMENT_UPLOADER_API_FAIL',
  DOCUMENT_UPLOADER_RESET_UPLOAD = 'DOCUMENT_UPLOADER_RESET_UPLOAD',
}

export type StartFileUploadAction = {
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_START_UPLOAD;
  payload: EntityDocumentUpload;
  callback?: () => void;
  withFormPath?: string;
};

export type CancelFileUploadAction = {
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_CANCEL_UPLOAD;
};

export type FileUploadApiSuccessAction = {
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_SUCCESS;
  callback?: () => void;
};

export type FileUploadSuccessMessageShownAction = {
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_SUCCESS_MESSAGE_SHOWN;
};

export type FileUploadApiFailAction = {
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_FAIL;
  payload: GlobalApiFailError;
};

export type ProgressUpdateFileUploadAction = {
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_PROGRESS_UPDATE_UPLOAD;
  payload: number;
};

export type ResetFileUpload = {
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_RESET_UPLOAD;
};

export type DocumentUploaderActions =
  | StartFileUploadAction
  | ProgressUpdateFileUploadAction
  | CancelFileUploadAction
  | FileUploadApiSuccessAction
  | FileUploadSuccessMessageShownAction
  | FileUploadApiFailAction
  | ResetFileUpload;
