import styled from 'styled-components/macro';

export const StyledRequiredFlag = styled.span`
  padding: 0 0 0 5px;
  color: ${(props) => props.theme.colorPalette.secondary.red};
`;

export const StyledFieldErrorMessage = styled.span`
  color: ${(props) => props.theme.colorPalette.secondary.red};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  display: block;
  line-height: 1;
  margin: 0 0 ${(props) => props.theme.spacing.small};
`;

export const StyledFieldPlaceholderMessage = styled.span`
  color: ${(props) => props.theme.colorPalette.text.placeholder};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  display: block;
  line-height: 1;
`;
