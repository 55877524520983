import { of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { DetailsActionTypes } from 'pages/Details/types';

import {
  updateHistoryApiSuccess,
  updateHistoryApiFail,
} from 'store/actions/detailsActions';
import { endpoints } from 'globalConstants';

type DetailsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const updateHistoryEpic: Epic = (
  action$,
  state$,
  dependencies: DetailsEpicDependencies,
) => {
  return action$.pipe(
    ofType(DetailsActionTypes.GET_UPDATE_HISTORY),
    mergeMap((action) => {
      const { resourceId, vertical, entity } = action.payload;
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url: endpoints.updateHistory(resourceId, vertical, entity),
        })()
        .pipe(
          map((results) => {
            if (results.status !== 200) {
              throw new Error(`error loading`);
            }

            return updateHistoryApiSuccess({
              entity,
              history: {
                [entity]: results.response.history,
              },
            });
          }),
          catchError((error) => {
            return of(updateHistoryApiFail(error));
          }),
        );
    }),
  );
};

export default updateHistoryEpic;
