import { UserProfilePayload } from 'store/reducers/userProfileReducer';
import { GlobalApiFailError } from './types';
import { UserQueryPayloadType } from './userQueriesActions';

export enum UserProfileActions {
  SAVE_USER_PROFILE = 'SAVE_USER_PROFILE',
  SAVE_QUERY_API_SUCCESS = 'SAVE_QUERY_API_SUCCESS',
  SAVE_QUERY_API_FAIL = 'SAVE_QUERY_API_FAIL',

  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  UPDATE_QUERY_API_SUCCESS = 'UPDATE_QUERY_API_SUCCESS',
  UPDATE_QUERY_API_FAIL = 'UPDATE_QUERY_API_FAIL',

  FETCH_USER_PROFILE = 'FETCH_USER_PROFILE',
  FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS',
  FETCH_USER_PROFILE_FAIL = 'FETCH_USER_PROFILE_FAIL',
}

export type UserScheduledQueriesPayloadType = {
  scheduledQueryId: string;
  userQueryId: string;
  repeatedJobId: string;
  repeatFrequency: string;
  trigger: string;
  hourToSend: number;
  endDate: string;
};

export type ScheduledQuerySavePayloadType = {
  userQueryId: string;
  repeatFrequency: string;
  trigger: string;
  hourToSend: number;
  endDate: string;
};
export type ScheduledQueryUpdatePayloadType = {
  userQueryId: string;
  repeatFrequency: string;
  trigger: string;
  hourToSend: number;
  endDate: string;
  scheduledQueryId: string;
};

export type UserProfilePayloadType = {
  userQueries: UserQueryPayloadType[];
  scheduledQueries: UserScheduledQueriesPayloadType[];
};

export type FetchUserProfileAction = {
  type: UserProfileActions.FETCH_USER_PROFILE;
};
export const getUserProfileAction = (): FetchUserProfileAction => ({
  type: UserProfileActions.FETCH_USER_PROFILE,
});

export type FetchSuccessActionType = {
  type: UserProfileActions.FETCH_USER_PROFILE_SUCCESS;
  payload: UserProfilePayload;
};
export const fetchUserProfileAPISuccess = (
  payload: UserProfilePayload,
): FetchSuccessActionType => ({
  type: UserProfileActions.FETCH_USER_PROFILE_SUCCESS,
  payload,
});

export type FetchFailActionType = {
  type: UserProfileActions.FETCH_USER_PROFILE_FAIL;
  payload: GlobalApiFailError;
};
export const fetchUserProfileAPIFail = (
  payload: GlobalApiFailError,
): FetchFailActionType => ({
  type: UserProfileActions.FETCH_USER_PROFILE_FAIL,
  payload,
});

export type SaveUserAction = {
  type: UserProfileActions.SAVE_USER_PROFILE;
  payload: ScheduledQuerySavePayloadType;
};
export const saveUserProfile = (
  payload: ScheduledQuerySavePayloadType,
): SaveUserAction => ({
  type: UserProfileActions.SAVE_USER_PROFILE,
  payload,
});
export const saveUserProfileAPISuccess = () => ({
  type: UserProfileActions.SAVE_QUERY_API_SUCCESS,
});
export const saveUserProfileAPIFail = (payload: GlobalApiFailError) => ({
  type: UserProfileActions.SAVE_QUERY_API_FAIL,
  payload,
});

export type UpdateUserProfileAction = {
  type: UserProfileActions.UPDATE_USER_PROFILE;
  payload: ScheduledQueryUpdatePayloadType;
};
export const updateUserProfile = (
  payload: ScheduledQueryUpdatePayloadType,
): UpdateUserProfileAction => ({
  type: UserProfileActions.UPDATE_USER_PROFILE,
  payload,
});
export const updateUserProfileAPISuccess = () => ({
  type: UserProfileActions.UPDATE_QUERY_API_SUCCESS,
});
export const updateUserProfileAPIFail = (payload: GlobalApiFailError) => ({
  type: UserProfileActions.UPDATE_QUERY_API_FAIL,
  payload,
});
