export const SIDEBAR_COPY = {
  MOVE_RECORDS: {
    tenant: 'Tenant',
    dates: 'Dates',
    demise: 'Demise',
    buyers: 'Buyer(s)',
    sellers: 'Seller(s)',
    spvs: 'SPV(s)',
    date: 'Date',
    price: 'Price',
    spaceAvailable: 'Space Available',
    readyToOccupyDate: 'Ready to Occupy Date',
  },
};
