import { LEASE_TYPE_MAP } from 'constants/configMaps';

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const sanitizeStr = (str: string) => {
  return str.replace(/-/g, ' ');
};

export const translateIndexToUserFacing = (index: number, suffix?: string) => {
  const mappedIndexes: { [key: number]: string } = {
    0: 'First',
    1: 'Second',
    2: 'Third',
    3: 'Fourth',
    4: 'Fifth',
    5: 'Sixth',
    6: 'Seventh',
    7: 'Eighth',
    8: 'Ninth',
    9: 'Tenth',
  };

  const friendly = index > 9 ? 'Another' : mappedIndexes[index];

  return suffix ? `${friendly} ${suffix}` : friendly;
};

export const convertCurrency = (amount: number, decimals = 0) => {
  return new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
    style: 'currency',
    currency: 'GBP',
  }).format(amount);
};

export const convertNumberThousandSeparator = (value: number) => {
  return new Intl.NumberFormat('en-GB', {}).format(value);
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEveryWord = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

export const mapStatus = (status: string) => {
  const AVAILABILITY_STATUS: { [key: string]: string } = {
    'under-offer': 'Under Offer',
    'outcome-unknown': 'Outcome Unknown',
    'to-let': 'To Let',
    terminated: 'Terminated',
    let: 'Let',
    available: 'Available',
    withdrawn: 'Withdrawn',
    sold: 'Sold',
  };

  return AVAILABILITY_STATUS[status];
};

export const mapType = (type: string) => {
  return LEASE_TYPE_MAP.get(type);
};

export const dynamicString = (str: string, valuesToInsert: string[]) => {
  return valuesToInsert.reduce((accum: string, value: string) => {
    return accum.replace('%%STRING_TO_REPLACE%%', value);
  }, str);
};

export const urlString = (str: string) => {
  return str.search(/^https?:\/\//) === -1 ? `https://${str}` : str;
};
