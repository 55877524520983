export const MOCK_DEBT_DATA = [
  {
    baseRate: 0.6,
    crmDebts: [
      {
        __entityName__: 'CrmDebt',
        id: '1',
        relationRole: 'Borrower',
        companyName: 'test',
        companyId: '2',
      },
    ],
    debtAmount: 100,
    id: '123',
    interestRate: 3,
    isVoided: false,
    maturityDate: '2029-09-02',
    notes: 'test',
    __entityName__: 'Debt',
  },
];

export const matchingRecordColours = [
  'datavizGreen',
  'datavizTeal',
  'datavizPurple',
  'datavizOrange',
  'datavizPink',
];
