import { Reducer } from 'redux';
import { AuthActionTypes, AuthenticationTypes } from 'store/actions/types';
import { FeatureFlagState } from '../../feature_flags/types';
import { ensureFeaturesValid } from '../../feature_flags/util';

export const initialState: FeatureFlagState = {};

const featureFlagReducer: Reducer<FeatureFlagState, AuthenticationTypes> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        ...ensureFeaturesValid(action.payload.state.features),
      };
    }
    default:
      return state;
  }
};

export default featureFlagReducer;
