import styled, { css } from 'styled-components/macro';

import {
  minimizedLabelStyles,
  labelStyles,
  inputStyles,
  inputWrapperStyles,
  inputWrapperDisabledStyles,
} from 'styled/Global';
import NumberFormat from 'react-number-format';
import {
  getErrorStyles,
  getHiddenStyles,
  getMinimizedStyles,
} from 'styled/helpers';
import { LabelType, StyledInputProps } from './types';

const getDisabledStyles = (color: string, isDisabled?: boolean) => {
  if (isDisabled) {
    return css`
      ${inputWrapperDisabledStyles}
      label {
        color: ${color};
      }
    `;
  }
  return ``;
};

const getHiddenInputStyles = (isHidden?: boolean) => {
  if (isHidden) {
    return css`
      display: none;
    `;
  }
  return ``;
};

export const StyledInputField = styled.div<{
  hasErrors: boolean;
  isDisabled: boolean;
  isHidden: boolean;
}>`
  ${inputWrapperStyles}
  ${(props) => getErrorStyles(props.hasErrors)}
  ${(props) =>
    getDisabledStyles(props.theme.colorPalette.branding.ink, props.isDisabled)}
  ${(props) => getHiddenInputStyles(props.isHidden)}
`;

export const StyledLabel = styled.label<LabelType>`
  ${(props) => getHiddenStyles(props.labelIsHidden)};
  ${(props) => getMinimizedStyles(props.labelIsMinimized)};
  ${labelStyles}
`;

export const StyledInput = styled.input<StyledInputProps>`
  ${inputStyles}

  &:focus {
    + label {
      ${minimizedLabelStyles}
    }
  }
`;

export const StyledNumberFormat = styled(NumberFormat)`
  ${inputStyles}

  &:focus {
    + label {
      ${minimizedLabelStyles}
    }
  }
`;
