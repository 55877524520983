import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import {
  fetchTelemetryStatisticsAPIFailAction,
  fetchTelemetryStatisticsAPISuccessAction,
  TelemetryStatisticsActions,
} from 'store/actions/telemetryStatisticsActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const telemetryStatisticsEndpoint = endpoints.telemetryStatistics;

/**
 * Get Telemetry statistics
 *
 * @param action$
 * @param state$
 * @param dependencies
 * @returns
 */
const telemetryStatisticsGet: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(TelemetryStatisticsActions.FETCH_TELEMETRY_STATISTICS),
    mergeMap(() => {
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url: telemetryStatisticsEndpoint,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`GET ${telemetryStatisticsEndpoint} failed`);
            }
            return of(
              fetchTelemetryStatisticsAPISuccessAction(response.response),
            );
          }),
          catchError((error) => {
            return of(fetchTelemetryStatisticsAPIFailAction(error));
          }),
        );
    }),
  );
};

export default telemetryStatisticsGet;
