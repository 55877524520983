import {
  ensureFeaturesValid,
  parseFeaturesFromQueryString,
} from 'feature_flags/util';
import { endpoints } from 'globalConstants';
import { ApiState, ApiStateResponseBody } from 'store/preloadedState';

export const getApiState = async (): Promise<ApiState | undefined> => {
  const response = await fetch(endpoints.state, {
    mode: 'cors',
    credentials: 'include',
  });

  if (response.ok) {
    const apiState: ApiStateResponseBody = await response.json();
    const parsedFeatures = parseFeaturesFromQueryString(window.location.search);

    return {
      user: apiState.user,
      features: ensureFeaturesValid(parsedFeatures || apiState.features),
    };
  }

  return undefined;
};
