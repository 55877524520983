import {
  map,
  mergeMap,
  filter,
  withLatestFrom,
  catchError,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { Action } from 'redux';

import { endpoints } from 'globalConstants';
import { getSearchQuery } from 'store/selectors/searchQuery';
import { AuthenticatedRequestObservable } from 'apis/request';

import {
  SearchActionTypes,
  fetchSearchResultsSuccess,
  fetchSearchResultsFail,
} from 'store/actions/searchActions';
import {
  PollingUpdateActionTypes,
  PollingUpdateSuccessAction,
  PollingSuccessMetaType,
} from 'store/actions/pollingUpdateActions';

type DetailsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const searchEpic: Epic = (
  action$,
  state$,
  { authRequest }: DetailsEpicDependencies,
) =>
  action$.pipe(
    ofType<Action, Action, any>(
      SearchActionTypes.FETCH_SEARCH_RESULTS,
      PollingUpdateActionTypes.POLLING_UPDATE_SUCCESS,
    ),
    withLatestFrom(state$),
    filter(([action]) => {
      if (action.type === PollingUpdateActionTypes.POLLING_UPDATE_SUCCESS) {
        return (
          (action as PollingUpdateSuccessAction).onSuccess ===
          PollingSuccessMetaType.Results
        );
      }

      return true;
    }),
    mergeMap(([action, state]) => {
      const searchQuery = getSearchQuery(state);
      const params = new URLSearchParams(searchQuery as Record<string, string>);

      const url = `${endpoints.searchQuery}?${params.toString()}`;
      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }

          return fetchSearchResultsSuccess({
            results: results.response.results,
            meta: results.response.meta,
            isInlineEditComplete:
              (action as PollingUpdateSuccessAction).onSuccess ===
              PollingSuccessMetaType.Results,
          });
        }),
        catchError((error) => {
          return of(fetchSearchResultsFail(error));
        }),
      );
    }),
  );

export default searchEpic;
