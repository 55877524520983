import { COMPANY_DATA_COPY } from 'components/CompaniesDataItem/constants';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import { CompanyRecord } from 'pages/Details/types';
import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';

export const getEditSellers = createSelector(
  (state: RootState) => state.details.sale?.sellers,
  (input) => {
    const sellers = (input as unknown) as CompanyRecord[];
    return sellers?.map((seller, index) => {
      const output: any = {
        index,
        id: seller.companyId,
      };

      output.sellerType = {
        componentType: 'SelectInput',
        formattedValue: seller.isPrivate ? 'Private Individual' : 'Company',
        index,
        label: 'Seller Type',
        value: seller.companyType,
      };

      if (!seller.isPrivate) {
        output.sellerCompanyName = {
          componentType: FIELD_COMPONENTS.COMPANY_SELECT,
          index,
          label: 'Seller Name',
          value: `{"name": "${seller.companyName}", "id": "${seller.companyId}"}`,
        };
      }

      if (seller.isPrivate) {
        output.sellerNationality = {
          componentType: FIELD_COMPONENTS.COUNTRY_SELECT,
          label: 'Seller Nationality',
          index,
          value: seller.companyNationality,
        };
      }

      return output;
    });
  },
);

export const formatBuyerType = (buyerType?: string) => {
  switch (buyerType) {
    case 'investor':
      return 'Investor - Company';
    case 'investor-private':
      return 'Investor - Private Individual';
    default:
      return COMPANY_DATA_COPY.owner;
  }
};

export const getEditBuyers = createSelector(
  (state: RootState) => state.details.sale?.buyers,
  (input) => {
    const buyers = (input as unknown) as CompanyRecord[];
    return buyers?.map((buyer, index) => {
      const output: any = {
        index,
        id: buyer.companyId,
      };

      output.buyerInvestorOccupier = {
        componentType: FIELD_COMPONENTS.SELECT,
        formattedValue: formatBuyerType(buyer.companyType),
        index,
        label: 'Buyer Type',
        value: buyer.companyType,
      };

      output.buyerEquityStake = {
        componentType: FIELD_COMPONENTS.PERCENTAGE,
        index,
        label: 'Equity Stake',
        value: buyer.companyEquity,
      };

      if (!buyer.isPrivate) {
        output.buyerCompanyName = {
          componentType: FIELD_COMPONENTS.COMPANY_SELECT,
          index,
          label: 'Buyer Name',
          value: `{"name": "${buyer.companyName}", "id": "${buyer.companyId}"}`,
        };
      }

      if (buyer.isPrivate) {
        output.buyerNationality = {
          componentType: FIELD_COMPONENTS.COUNTRY_SELECT,
          label: 'Buyer Nationality',
          index,
          value: buyer.companyNationality,
        };
      }

      return output;
    });
  },
);

export const getEditHoldingEntities = createSelector(
  (state: RootState) => state.details.sale?.buyersSPV,
  (input) => {
    const holdingEntities = (input as unknown) as CompanyRecord[];

    return holdingEntities.map((holdingEntity, index) => {
      const output: any = {
        index,
        id: holdingEntity.companyId,
        sellerType: {
          componentType: FIELD_COMPONENTS.SELECT,
          index,
          label: 'Holding Entity Type',
          value: holdingEntity.isPrivate ? 'investor-private' : 'company',
        },
      };

      if (!holdingEntity.isPrivate) {
        output.holdingEntityCompanyName = {
          componentType: FIELD_COMPONENTS.COMPANY_SELECT,
          index,
          label: 'Holding Entity Name',
          value: `{"name": "${holdingEntity.companyName}", "id": "${holdingEntity.companyId}"}`,
        };
      }

      if (holdingEntity.isPrivate) {
        output.holdingEntityNationality = {
          componentType: FIELD_COMPONENTS.COUNTRY_SELECT,
          label: 'Holding Entity Nationality',
          index,
          value: holdingEntity.companyNationality,
        };
      }

      return output;
    });
  },
);
