import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { globalModalHide } from 'store/actions/globalModalActions';
import {
  scheduleDeleteApiFail,
  scheduleDeleteApiSuccess,
  UserQueryActions,
} from 'store/actions/userQueriesActions';

type ScheduleDeleteEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const scheduleDeleteEndpoint = endpoints.deleteScheduleQuery;

const scheduleDeleteEpic: Epic = (
  action$,
  state$,
  dependencies: ScheduleDeleteEpicDependencies,
) => {
  return action$.pipe(
    ofType(UserQueryActions.DELETE_SCHEDULE),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'DELETE',
          url: scheduleDeleteEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((deleteResponse) => {
            if (deleteResponse.status > 204) {
              throw Error(`DELETE ${scheduleDeleteEndpoint} failed`);
            }

            return of(scheduleDeleteApiSuccess(), globalModalHide());
          }),
          catchError((error) => {
            return of(scheduleDeleteApiFail(error));
          }),
        );
    }),
  );
};

export default scheduleDeleteEpic;
