import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { zIndex } from 'styled/Global';
import { StandardPadding } from '../../components/Layouts/GlobalScaffold/GlobalScaffoldStyled';

export const GlobalHeaderWrapper = styled(StandardPadding).attrs({
  as: 'header',
})`
  grid-area: global-header;
  background: ${(props) => props.theme.colorPalette.branding.ink};
  color: ${(props) => props.theme.colorPalette.text.white};
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightInk};
  display: flex;
  justify-content: space-between;
  z-index: ${zIndex.globalHeader};
`;

export const HomeLink = styled(Link)`
  display: flex;
`;

export const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const LogoImg = styled.img`
  width: 130px;
  display: block;
`;
