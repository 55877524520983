/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  INSIDE_OUTSIDE_MAP,
  LEASE_STATUS_MAP,
  LEASE_TYPE_MAP,
  MEASUREMENT_STANDARD_MAP,
  PRIMARY_USE_MAP,
  REPAIRING_OBLIGATIONS_MAP,
  TRUE_FALSE_MAP,
} from 'constants/configMaps';
import { LeaseField } from 'constants/leases';
import {
  formatArea,
  formatBooleanishYesNo,
  formatDate,
  formatMonths,
  formatMultiSelectField,
  formatPriceWithPence,
} from 'services/format';

export const mergeLeaseNotesAllMap = {
  description: {
    key: 'description',
    apiKey: 'description',
  },
  noteDate: {
    key: 'noteDate',
    apiKey: 'noteDate',
  },
};

export const mergeLeaseDemiseMap = {
  floor: {
    key: 'floor',
    apiKey: 'floor',
  },
  partition: {
    key: 'partition',
    apiKey: 'partition',
  },
  askingRent: {
    key: 'askingRent',
    apiKey: 'askingRent',
  },
  achievedRent: {
    key: 'achievedRent',
    apiKey: 'achievedRent',
  },
  netEffectiveRent: {
    key: 'netEffectiveRent',
    apiKey: 'netEffectiveRent',
  },
  offerRent: {
    key: 'offeredRent',
    apiKey: 'offeredRent',
  },
};

export const mergeLeaseMap = {
  disputed: {
    key: 'disputed',
    apiKey: 'isDisputed',
    keyLabel: 'Disputed',
    valuePath: ['isDisputed'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: LeaseField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Lease',
  },
  leaseStatus: {
    key: 'status',
    apiKey: 'availabilityStatus',
    keyLabel: 'Status',
    valuePath: ['status'],
    valueLabels: LEASE_STATUS_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Lease',
  },
  primaryUse: {
    key: 'primaryUse',
    apiKey: 'primaryUse',
    keyLabel: 'Primary Use',
    valuePath: ['primaryUse'],
    valueLabels: PRIMARY_USE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Lease',
  },
  leaseType: {
    key: 'type',
    apiKey: 'type',
    keyLabel: 'Lease Type',
    valuePath: ['type'],
    valueLabels: LEASE_TYPE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Lease',
  },
  leaseTitleNumber: {
    key: 'titleNumber',
    apiKey: 'titleNumber',
    keyLabel: 'Lease Title Number',
    valuePath: ['titleNumber'],
    entityName: 'Lease',
  },
  tenant: {
    key: 'tenant',
    apiKey: '$Tenant',
    keyLabel: 'Tenant',
    valuePath: ['tenant'],
  },
  isConfidential: {
    key: 'isConfidential',
    apiKey: 'dealIsPrivate',
    keyLabel: 'Confidential Deal',
    valuePath: ['isConfidential'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: LeaseField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Lease',
  },
  terminationDate: {
    key: 'terminationDate',
    apiKey: 'terminationDate',
    keyLabel: 'Termination Date',
    valuePath: ['terminationDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  terminationReason: {
    key: 'terminationReason',
    apiKey: 'terminationReason',
    keyLabel: 'Termination Reason',
    valuePath: ['terminationReason'],
    entityName: 'Lease',
  },
  landlord: {
    key: 'landlord',
    apiKey: '$Landlord',
    keyLabel: 'Lessor (at time of deal)',
    valuePath: ['landlord'],
  },
  startDate: {
    key: 'startDate',
    apiKey: 'startDate',
    keyLabel: 'Start Date',
    valuePath: ['startDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  endDate: {
    key: 'endDate',
    apiKey: 'endDate',
    keyLabel: 'End Date',
    valuePath: ['endDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  totalArea: {
    key: 'totalArea',
    apiKey: 'totalArea',
    keyLabel: 'Area (Sq Ft)',
    valuePath: ['totalArea'],
    formatter: (field: LeaseField) =>
      field.value ? formatArea(field.value) : null,
    entityName: 'Lease',
  },
  measurementStandard: {
    key: 'measurementStandard',
    apiKey: 'measurementStandard',
    keyLabel: 'Measurement Standard',
    valuePath: ['measurementStandard'],
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    valueLabels: MEASUREMENT_STANDARD_MAP,
  },
  askingRent: {
    key: 'askingRent',
    apiKey: 'askingRent',
    keyLabel: 'Asking Rent / Sq Ft',
    valuePath: ['askingRent'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  offerRent: {
    key: 'offeredRent',
    apiKey: 'offeredRent',
    keyLabel: 'Offered Rent / Sq Ft',
    valuePath: ['offeredRent'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  achievedRent: {
    key: 'achievedRent',
    apiKey: 'achievedRent',
    keyLabel: 'Achieved Rent / Sq Ft',
    valuePath: ['achievedRent'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  serviceCharge: {
    key: 'serviceCharge',
    apiKey: 'serviceCharge',
    keyLabel: 'Service Charge',
    valuePath: ['serviceCharge'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  act54: {
    key: 'act54',
    apiKey: 'act54',
    keyLabel: '1954 L&T Act',
    valuePath: ['act54'],
    valueLabels: INSIDE_OUTSIDE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Lease',
  },
  leaseObligations: {
    key: 'repairingObligations',
    apiKey: 'term',
    keyLabel: 'Repairing Obligations',
    valuePath: ['repairingObligations'],
    valueLabels: REPAIRING_OBLIGATIONS_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Lease',
  },
  zoneARent: {
    key: 'zoneARent',
    apiKey: 'zoneARent',
    keyLabel: 'Zone A Rent',
    valuePath: ['zoneARent'],
    conditional: [{ ref: 'primaryUse', value: ['retail'] }],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  linkedToTurnover: {
    key: 'linkedToTurnover',
    apiKey: 'linkedToTurnover',
    keyLabel: 'Linked to Turnover',
    valuePath: ['linkedToTurnover'],
    conditional: [{ ref: 'primaryUse', value: ['retail'] }],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: LeaseField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Lease',
  },
  premiumToLandlord: {
    key: 'premiumToLandlord',
    apiKey: 'premium',
    keyLabel: 'Premium to Landlord',
    valuePath: ['premiumToLandlord'],
    conditional: [{ ref: 'primaryUse', value: ['retail'] }],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  readyToOccupyDate: {
    key: 'readyToOccupyDate',
    apiKey: 'readyToOccupyDate',
    keyLabel: 'Ready to Occupy Date',
    valuePath: ['readyToOccupyDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  description: {
    key: 'description',
    apiKey: 'description',
    keyLabel: 'Description',
    valuePath: ['description'],
    entityName: 'Lease',
  },
  onMarketDate: {
    key: 'onMarketDate',
    apiKey: 'onMarketDate',
    keyLabel: 'On Market Date',
    valuePath: ['onMarketDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  offMarketDate: {
    key: 'offMarketDate',
    apiKey: 'offMarketDate',
    keyLabel: 'Off Market Date',
    valuePath: ['offMarketDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  contractSignedDate: {
    key: 'contractSignedDate',
    apiKey: 'contractSignedDate',
    keyLabel: 'Contract Signed Date',
    valuePath: ['contractSignedDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  headsOfTermsAgreedDate: {
    key: 'headsOfTermsAgreedDate',
    apiKey: 'headsOfTermsAgreedDate',
    keyLabel: 'Heads of Terms Date',
    valuePath: ['headsOfTermsAgreedDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  enquiriesCount: {
    key: 'numberOfEnquiries',
    apiKey: 'numberOfEnquiries',
    keyLabel: 'Number of Enquiries',
    valuePath: ['numberOfEnquiries'],
    entityName: 'Lease',
  },
  offersCount: {
    key: 'numberOfOffers',
    apiKey: 'numberOfOffers',
    keyLabel: 'Number of Offers',
    valuePath: ['numberOfOffers'],
    entityName: 'Lease',
  },
  useClause: {
    key: 'useClauses',
    apiKey: 'useClauses',
    keyLabel: 'Use Clause',
    valuePath: ['useClauses'],
    formatter: (field: LeaseField) => formatMultiSelectField(field.value),
    entityName: 'Lease',
  },
  jobCode: {
    key: 'jobCode',
    apiKey: 'jobCode',
    keyLabel: 'Job Code',
    valuePath: ['jobCode'],
    entityName: 'Lease',
  },
  instructionDate: {
    key: 'instructionDate',
    apiKey: 'instructionDate',
    keyLabel: 'Instruction Date',
    valuePath: ['instructionDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    entityName: 'Lease',
  },
  holdingOver: {
    key: 'holdingOver',
    apiKey: 'holdingOver',
    keyLabel: 'Holding Over',
    valuePath: ['holdingOver'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    conditional: [
      { ref: 'act54', value: [false] },
      { ref: 'endDate', value: ['BEFORE_CURRENT_DATE'] },
    ],
    entityName: 'Lease',
  },
  rentFreeMonthsInitial: {
    key: 'rentFreeMonths',
    apiKey: 'rentFreeMonthsInitial',
    keyLabel: 'Initial Rent Free Months',
    valuePath: ['rentFreeMonths'],
    formatter: (field: LeaseField) =>
      field.value ? formatMonths(field.value) : null,
    entityName: 'Lease',
  },
  halfRentMonthsInitial: {
    key: 'halfRentFreeMonths',
    apiKey: 'halfRentMonthsInitial',
    keyLabel: 'Half Rent Free Months',
    valuePath: ['halfRentFreeMonths'],
    formatter: (field: LeaseField) =>
      field.value ? formatMonths(field.value) : null,
    entityName: 'Lease',
  },
  rentFreeMonthsTotal: {
    key: 'rentFreeMonthsTotal',
    apiKey: 'rentFreeMonthsTotal',
    keyLabel: 'Total Rent Free Months',
    valuePath: ['rentFreeMonthsTotal'],
    formatter: (field: LeaseField) =>
      field.value ? formatMonths(field.value) : null,
    entityName: 'Lease',
  },
  capitalContribution: {
    key: 'capitalContribution',
    apiKey: 'capitalContribution',
    keyLabel: 'Capital Contribution',
    valuePath: ['capitalContribution'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  netEffectiveRent: {
    key: 'netEffectiveRent',
    apiKey: 'netEffectiveRent',
    keyLabel: 'Net Effective Rent',
    valuePath: ['netEffectiveRent'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    entityName: 'Lease',
  },
  rentReviews: {
    key: 'rentReviews',
    apiKey: 'rentReviews',
    keyLabel: 'Rent Reviews',
    valuePath: ['rentReviews'],
  },
  breakOptions: {
    key: 'breakOptions',
    apiKey: 'breakOptions',
    keyLabel: 'Break Options',
    valuePath: ['breakOptions'],
  },
  notesAll: {
    key: 'notesAll',
    keyLabel: 'Notes',
    apiKey: 'notesAll',
    valuePath: ['notesAll'],
  },
  tenantAgents: {
    key: 'tenantAgent',
    apiKey: '$Tenant Agent',
    keyLabel: "Tenant's Agent",
    valuePath: ['tenantAgent'],
  },
  tentantLeaseConsultant: {
    key: 'tenantLeaseConsultant',
    apiKey: "$Tenant's Lease Consultant",
    keyLabel: "Tenant's Lease Consultant",
    valuePath: ['tenantLeaseConsultant'],
  },
  tentantSolicitor: {
    key: 'tenantSolicitor',
    apiKey: "$Tenant's Solicitor",
    keyLabel: "Tenant's Solicitor",
    valuePath: ['tenantSolicitor'],
  },
  landlordAgents: {
    key: 'landlordAgent',
    apiKey: '$Landlord Agent',
    keyLabel: "Landlord's Agent",
    valuePath: ['landlordAgent'],
  },
  landlordLeaseConsultant: {
    key: 'landlordLeaseConsultant',
    apiKey: "$Landlord's Lease Consultant",
    keyLabel: "Landlord's Lease Consultant",
    valuePath: ['landlordLeaseConsultant'],
  },
  landlordSolicitor: {
    key: 'landlordSolicitor',
    apiKey: "$Landlord's Solicitor",
    keyLabel: "Landlord's Solicitor",
    valuePath: ['landlordSolicitor'],
  },
  other: {
    key: 'other',
    apiKey: '$Other',
    keyLabel: 'Other',
    valuePath: ['other'],
  },
  demises: {
    key: 'demise',
    keyLabel: 'Demise',
    apiKey: 'demise',
    valuePath: ['demise'],
  },
};
