import React from 'react';
import RequiredFlag from 'components/Forms/RequiredFlag';
import FieldValidation from 'components/Forms/FieldValidation';
import { Option } from 'components/Forms/types';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import useFieldInputValidation from 'hooks/useFieldInputValidation';
import { RadioProps } from './types';
import {
  RadioGroup,
  StyledIntro,
  StyledHeader,
  RadioItemWrapper,
  RadioLabel,
  StyledInputWrapper,
  LabelText,
  StyledIcon,
  RadioValidationWrapper,
} from './RadioStyled';

const Radio = ({
  name,
  label,
  items,
  handleOnChange,
  selectedValue,
  validation,
  description,
  direction,
  isInline = false,
  isFullWidth = false,
}: RadioProps) => {
  const { hasErrors } = useFieldInputValidation(
    selectedValue,
    FIELD_COMPONENTS.RADIO,
    validation,
  );

  return (
    <div>
      <RadioGroup id={name} isInline={isInline} isFullWidth={isFullWidth}>
        {(label || description || validation) && (
          <StyledIntro>
            <StyledHeader>{label}</StyledHeader>
            {description}
            {validation?.isRequired && <RequiredFlag />}
          </StyledIntro>
        )}
        <RadioItemWrapper direction={direction}>
          {(items as Option[]).map((item: Option) => (
            <div key={item.label}>
              <RadioLabel
                htmlFor={item.label}
                isInline={isInline}
                isDisabled={item.isDisabled || false}
              >
                <StyledInputWrapper isInline={isInline}>
                  <input
                    onChange={() => handleOnChange(item.value, item.label)}
                    name={name}
                    checked={selectedValue === item.value}
                    id={item.label}
                    value={item.value}
                    disabled={item.isDisabled || false}
                    type="radio"
                  />
                  <span />
                </StyledInputWrapper>
                {item.icon && <StyledIcon>{item.icon}</StyledIcon>}
                <LabelText isInline={isInline}>{item.label}</LabelText>
              </RadioLabel>
            </div>
          ))}
        </RadioItemWrapper>
      </RadioGroup>
      {validation && (
        <RadioValidationWrapper>
          <FieldValidation hasErrors={hasErrors} validation={validation} />
        </RadioValidationWrapper>
      )}
    </div>
  );
};

export default Radio;
