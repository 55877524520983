export const MOVE_LEASE_COPY = {
  heading: 'Move Lease',
};

export const MOVE_SALE_COPY = {
  heading: 'Move Sale',
};

export const MOVE_BUILDING_COPY = {
  heading: 'Move Record',
  verticalHeadings: {
    lease: 'Move Lease',
    building: '',
    company: '',
    sale: 'Move Sale',
  },
  addBtn: 'Confirm',
  updating: 'Updating Record',
};

export const MERGE_LEASE_COPY = {
  heading: 'Merge Lease',
};

export const MERGE_SALE_COPY = {
  heading: 'Merge Sale',
};

export const CLEAR_FIELD = 'clear-field';
