type EditModals = 'underOfferLet';

export type RecordCopy = {
  heading: string;
};

export const LEASE_COPY = {
  underOfferLet: {
    heading: 'Change Lease Status',
  },
  letTerminated: {
    heading: 'Change Lease Status',
  },
  terminatedLet: {
    heading: 'Change Lease Status',
  },
};

export const SALE_COPY = {
  availableUnderOffer: {
    heading: 'Change Sale Status',
  },
  availableSold: {
    heading: 'Change Sale Status',
  },
  availableWithdrawn: {
    heading: 'Change Sale Status',
  },
  underOfferSold: {
    heading: 'Change Sale Status',
  },
  underOfferWithdrawn: {
    heading: 'Change Sale Status',
  },
  detailsPanel: {
    done: 'Done',
    edit: 'Edit',
    title: 'Sale details',
  },
  relatedParties: {
    heading: 'Related Parties',
  },
};

export const BREAKS_COPY = {
  heading: 'Add Break Option',
};

export const RENT_REVIEWS_COPY = {
  heading: 'Add Rent Review',
};

export const EDIT_TENURE_COPY = {
  heading: ' Change Sale Tenure',
};

export const EDIT_SELLERS_COPY = {
  heading: 'Edit Sellers',
};

export const EDIT_BUYERS_COPY = {
  heading: 'Edit Buyers',
};

export const EDIT_SPV_COPY = {
  heading: 'Edit Holding Entity',
  label: 'Holding Entity Name',
  detailsLabel: 'Holding Entity',
  values: {
    yes: 'yes-know',
    no: 'no',
  },
};

export const EDIT_LANDLORD_COPY = {
  heading: 'Edit Landlord',
};

export const EDIT_TENANT_COPY = {
  heading: 'Edit Tenant',
};

export const EDIT_FREEHOLDER_COPY = {
  heading: 'Edit Freeholder',
};

export const RELATED_PARTIES_COPY = {
  add: 'Adding related party',
};

export const PORTFOLIO_SALE_COPY = {
  heading: 'Property List',
  addBtn: 'Add Buildings',
};

export const PORTFOLIO_SALE_ADD_BUILDING_COPY = {
  heading: 'Add buildings to portfolio sale',
};

export const FLOOR_PARTITION_COPY = {
  heading: 'Add buildings to portfolio sale',
};

export const EDIT_BUILDING_ALIASES_COPY = {
  heading: 'Manage Building Aliases',
};

export const EDIT_LEASE_TYPE_COPY = {
  heading: 'Edit Lease Type',
};
export const EDIT_TRADING_AS_NAMES_COPY = {
  heading: "Manage Company 'Trading As' Names",
};
export const EDIT_COMPANY_OWNERSHIP_COPY = {
  heading: 'Manage Parents',
};

export type EditRecordsCopy = Record<EditModals, RecordCopy>;
