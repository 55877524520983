import React from 'react';

import { globalContent } from 'globalConstants';
import FormField from './FormField/FormField';
import { Column, StyledFieldsetFields } from './FormView/FormViewStyled';
import {
  getFieldComponentOptions,
  getGroupValue,
  isReadOnly,
} from './services/formState';
import {
  getCompanyField,
  getCompanyType,
  shouldShowCompanyField,
} from './services/companies';
import { COMPANY_FIELDS, COMPANY_TYPE, FieldProps, GroupProps } from './types';

type CompanyFieldsetProps = {
  formState: any;
  currentState: any;
  group: GroupProps;
  columns?: number;
  onChange: (
    value: string,
    label: string,
    id: string,
    field: FieldProps,
    groupId: string,
  ) => void;
};

const showField = (field: any, group: GroupProps, currentState: any) => {
  let companyNameValue;
  if (group.fields) {
    const companyNameField = getCompanyField(group.fields, COMPANY_FIELDS.NAME);
    companyNameValue =
      companyNameField && companyNameField.name && currentState[group.groupId]
        ? currentState[group.groupId][companyNameField.name]
        : null;
  }
  return shouldShowCompanyField(
    field.fieldType,
    (companyNameValue && companyNameValue.value) || null,
  );
};

const Field = ({
  field,
  group,
  currentState,
  formState,
  onChange,
}: {
  field: FieldProps;
  group: GroupProps;
  currentState: any;
  formState: any;
  onChange: any;
}) => {
  const handleOnChange = (value: string, label?: string) => {
    if (field.fieldType && field.fieldType === COMPANY_FIELDS.NAME) {
      if (getCompanyType(value) === COMPANY_TYPE.CH && group.fields) {
        const nationalityItem = getCompanyField(
          group.fields,
          COMPANY_FIELDS.NATIONALITY,
        );

        if (nationalityItem) {
          onChange(
            globalContent.nationality,
            nationalityItem.label,
            nationalityItem.name,
            nationalityItem,
            group.groupId,
          );
        }
      }
    }

    onChange(value, label || field.label, field.fieldId, field, group.groupId);
  };

  if (showField(field, group, currentState)) {
    const readOnly = isReadOnly(formState, field.readOnly);

    return (
      <Column width={field.columns} key={field.fieldId}>
        <FormField
          isGroup
          type={field.componentType}
          handleOnChange={handleOnChange}
          name={field.name}
          label={field.label}
          groupId={group.groupId}
          inlineLabels={field.inlineLabels}
          prompt={field.prompt}
          formState={formState}
          options={getFieldComponentOptions(field, formState)}
          validation={field.validation}
          readOnly={readOnly}
          itemProps={field}
          fields={group.fields}
          hasDefaultValue={!!field.defaultValue}
          value={getGroupValue(
            currentState,
            formState,
            group.groupId,
            field.fieldId,
            field.prepopulated,
            field.defaultValue,
          )}
        />
      </Column>
    );
  }
  return <div> </div>;
};

const CompanyFieldset = ({
  group,
  formState,
  currentState,
  columns,
  onChange,
}: CompanyFieldsetProps) => {
  return (
    <StyledFieldsetFields
      columns={columns}
      additionalStyles={group.additionalStyles}
    >
      {group.fields?.map((field: FieldProps) => (
        <Field
          key={field.fieldId}
          field={field}
          group={group}
          currentState={currentState}
          formState={formState}
          onChange={onChange}
        />
      ))}
    </StyledFieldsetFields>
  );
};

export default CompanyFieldset;
