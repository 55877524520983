import styled, { css } from 'styled-components/macro';
import { ReactComponent as Tick } from 'assets/icons/check.svg';

export const CheckboxWrapper = styled.div<{ verticalAlign?: 'center' }>`
  display: flex;
  height: 100%;
  justify-content: ${(props) =>
    props.verticalAlign ? props.verticalAlign : 'flex-end'};
  flex-direction: column;
`;

export const TickIcon = styled(Tick)<{ disabled?: boolean }>`
  position: absolute;
  left: 0;

  ${(props) =>
    props.disabled &&
    css`
      fill: ${props.theme.colorPalette.secondary.midGrey};
    `}
`;

export const StyledCheckbox = styled.input`
  display: none;
`;

export const StyledCustomCheckbox = styled.span`
  position: relative;
  border-radius: 3px;
  height: 26px;
  min-width: 26px;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
  margin: 0 10px 0 0;
  display: inline-block;
`;

export const GroupPrompt = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-weight: bold;
`;

export const GroupSubtitle = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
`;

const getAlignment = (palette: any, align?: string) => {
  if (align === 'right') {
    return css`
      flex-direction: row-reverse;

      &:hover,
      &:focus {
        input + span {
          border: 2px solid ${palette.midGrey};
        }
      }
      input + span {
        width: 24px;
        height: 24px;
        margin: 0 0 0 10px;
        border: 2px solid ${palette.darkGrey};

        svg {
          width: 20px;
          height: 20px;
        }
      }
    `;
  }
  return css``;
};

const getCheckedStyles = (
  isChecked: boolean,
  isDisabled: boolean,
  color: string,
  disabledColor: string,
) => {
  if (isDisabled) {
    return css`
      input + span {
        cursor: default;
        border: 2px solid ${disabledColor};
        background: ${disabledColor};
      }

      &:hover,
      &:focus {
        input + span {
          border: 2px solid ${disabledColor};
        }
      }
    `;
  }

  if (isChecked) {
    return css`
      input + span {
        border: 2px solid ${color};
        background: ${color};
      }
    `;
  }
  return ``;
};

export const StyledLabel = styled.label<{
  align?: 'right' | 'left';
  isChecked: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  margin: 0.5em 0;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  line-height: 1.2;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};

  &:hover,
  &:focus {
    input + span {
      border: 2px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
    }
  }
  ${(props) => getAlignment(props.theme.colorPalette.secondary, props.align)}

  ${(props) =>
    getCheckedStyles(
      props.isChecked,
      props.isDisabled,
      props.theme.colorPalette.branding.yellow,
      props.theme.colorPalette.secondary.grey,
    )}
`;
