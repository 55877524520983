import styled from 'styled-components/macro';
import { lighten } from 'polished';

import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as Info } from 'assets/icons/info-outline.svg';

type InfoToolTipProps = {
  color: string;
};

export const InfoToolTip = styled.div<InfoToolTipProps>`
  background: ${(props) => lighten('0.4', props.color)};
  color: ${(props) => props.color};
  padding: 5px 24px 5px 8px;
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-weight: 700;
  border-radius: 3px;
  position: relative;
  font-family: ${(props) => props.theme.fonts.secondary};

  + .__react_component_tooltip {
    font-size: ${(props) => props.theme.typography.paragraph.small};
    padding: ${(props) => props.theme.spacing.small};
    text-align: center;
    line-height: 1.5;

    &.show {
      opacity: 1;
    }
  }
`;

const InfoIconStyled = withIconStyles(Info);
export const InfoIcon = styled(InfoIconStyled)`
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  top: 50%;
  right: 5px;
  position: absolute;
`;
