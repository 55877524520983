import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { UploadedDocStatus } from 'store/reducers/newRecordReducer';

export const hasDocumentUploadedSelector = createSelector(
  (state: RootState) => state.newRecord.uploadedDocs,
  (documents) =>
    !!documents.length && documents[0].status === UploadedDocStatus.uploaded,
);

export const documentSelector = createSelector(
  (state: RootState) => state.newRecord.uploadedDocs,
  (documents) => (documents.length ? documents[0].name : ''),
);

export const newRecordApiErrorSelector = (state: RootState) =>
  state.newRecord.error;
