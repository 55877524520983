import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { hideVisually, lighten } from 'polished';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    outline: none;
  }


  html {
    font-size: 62.5%;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    font-size: 16px;
    background: ${(props) => props.theme.colorPalette.body};
    color: ${(props) => props.theme.colorPalette.branding.ink};
    font-family: ${(props) => props.theme.fonts.primary};

    &.no-scroll {
      overflow-y: hidden;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  button {
    border: 0;
    cursor: pointer;
    background: none;
  }

  h1,
  h2,
  h3 {
    font-weight: 400;
  }

  h2 {
    font-size: ${(props) => props.theme.typography.heading.xsmall};
  }

  fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
  }

  input,
  select,
  textarea {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 16px;
    border-radius: 0;
  }

  .react-select-container { position: static!important;}

  .Toastify__toast {
    background: ${(props) => props.theme.colorPalette.branding.ink};
    color: ${(props) => props.theme.colorPalette.branding.white};
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: ${(props) => props.theme.typography.paragraph.small};
  }

  .Toastify__toast-body {
    text-align: left;
  }

  .Toastify__progress-bar--default {
    background: linear-gradient(to right, ${(props) =>
      props.theme.colorPalette.branding.yellow}, ${(props) =>
  lighten('0.2', props.theme.colorPalette.branding.yellow)})
  }

  .map-overlay-view-container {
    cursor: auto;
    height: 0;
    position: absolute;
  }

  .map-overlay-view-anchor {
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid white;
    }
  }

  .map-overlay-view-content {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    background-color: white;
    border-radius: 5px;
    font-family: sans-serif;
    overflow-y: auto;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const BoldEmphasized = styled.em`
  font-weight: 700;
  font-style: normal;
`;

export const UnderlineEmphasized = styled.em`
  text-decoration: underline;
  font-style: normal;
`;

export const AccessibleText = styled.span`
  ${hideVisually()}
`;

const minZIndex = 10;

function zIndexify(labels: string[]) {
  const list = labels.map((label, index) => ({
    [label]: minZIndex + index,
  }));

  return list.reduce((obj, item) => {
    return Object.assign(obj, item);
  }, {});
}

/**
 * the first name has value 10
 * each subsequent name is an increment of 1 over the previous name
 */
export const zIndex = zIndexify([
  'formNav',
  'navArrow',
  'filterButton',
  'datepicker',
  'tooltip',
  'globalNav',
  'detailsStickyBar',
  'scrollableTable',
  'scrollableTableFixedCol',
  'filterDropdown',
  'globalHeader',
  'pageBlur',
  'modal',
  'modalBtn',
  'completedIcon',
  'reactSelectPopper',
  'reactSelectLabel',
]);

export const inputWrapperStyles = css`
  display: flex;
  flex-flow: column-reverse;
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  height: 48px;
  padding: 0 ${(props) => props.theme.spacing.small};
  justify-content: center;
  border-radius: 4px;
  margin: ${(props) => props.theme.spacing.tablet} 0;
  position: relative;

  &:hover,
  &:focus-within,
  &:focus {
    border: 1px solid ${(props) => props.theme.colorPalette.branding.ink};
    color: ${(props) => props.theme.colorPalette.branding.ink};
  }
`;

export const inputWrapperErrorStyles = css`
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.red};
  &:focus,
  &:focus-within,
  &:hover {
    border: 1px solid ${(props) => props.theme.colorPalette.secondary.red};
  }
`;

export const inputWrapperDisabledStyles = css`
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  background: ${(props) => props.theme.colorPalette.secondary.lightGrey};
  color: ${(props) => props.theme.colorPalette.secondary.darkGrey};

  label {
    color: ${(props) => props.theme.colorPalette.branding.ink};
  }
  &:focus,
  &:focus-within,
  &:hover {
    border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  }
`;

export const inputStyles = css`
  background: none;
  border: 0;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  padding: 8px 0 0;
  width: 100%;
  height: 100%;
  transition: all 0.1s;
  touch-action: manipulation;
  position: relative;
  color: ${(props) => props.theme.colorPalette.secondary.darkGrey};
`;

export const labelStyles = css`
  color: ${(props) => props.theme.colorPalette.text.placeholder};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  transition: all 0.1s;
  touch-action: manipulation;
  position: absolute;
  transform: translate3d(0);
  cursor: text;
  color: ${(props) => props.theme.colorPalette.text.placeholder};
`;

export const minimizedLabelStyles = css`
  transform: translate3d(0, -10px, 0);
  color: ${(props) => props.theme.colorPalette.text.placeholder};
`;

export default GlobalStyle;
