import React, { useEffect } from 'react';

import { PanelTheme } from 'components/PanelWrapper/types';
import { TableWrapperProps } from './types';

import Table from './Table';
import { useRefinedData } from './helpers';
import { defaultPagination, noPagination } from './constants';

const SortPaginationTable = ({
  tableId,
  headers,
  rows,
  columns,
  useTheme = PanelTheme.DEFAULT,
  usePagination = true,
  totalRows,
  tableUseContext,
  isMergeRecordEnabled,
  vertical,
  tableItemsConfig,
}: TableWrapperProps) => {
  const refinedData = useRefinedData(
    rows,
    usePagination ? defaultPagination : noPagination,
    undefined,
    tableItemsConfig,
  );

  useEffect(() => {
    refinedData.resetPaginationPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <Table
      tableId={tableId}
      headers={headers}
      columns={columns}
      rows={refinedData.items}
      rowCount={totalRows || rows.length}
      useTheme={useTheme}
      tableItemsConfig={refinedData}
      tableUseContext={tableUseContext}
      isMergeRecordEnabled={isMergeRecordEnabled}
      vertical={vertical}
    />
  );
};

export default React.memo(SortPaginationTable);
