export enum PollingNewRecordActionTypes {
  POLLING_NEW_RECORD_SUCCESS = 'POLLING_NEW_RECORD_SUCCESS',
  POLLING_NEW_RECORD_UNKNOWN = 'POLLING_NEW_RECORD_UNKNOWN',
  POLLING_NEW_RECORD_FAIL = 'POLLING_NEW_RECORD_API_FAIL',
  POLLING_RETRY = 'POLLING_RETRY',
}

export type PollingNewRecordFailAction = {
  type: PollingNewRecordActionTypes.POLLING_NEW_RECORD_FAIL;
};

export const pollingNewRecordFail = (): PollingNewRecordFailAction => ({
  type: PollingNewRecordActionTypes.POLLING_NEW_RECORD_FAIL,
});

export type PollingNewRecordUnknownAction = {
  type: PollingNewRecordActionTypes.POLLING_NEW_RECORD_UNKNOWN;
};

export const pollingNewRecordUnknown = (): PollingNewRecordUnknownAction => ({
  type: PollingNewRecordActionTypes.POLLING_NEW_RECORD_UNKNOWN,
});

export type PollingNewRecordSuccessAction = {
  type: PollingNewRecordActionTypes.POLLING_NEW_RECORD_SUCCESS;
};

export const pollingNewRecordSuccess = (): PollingNewRecordSuccessAction => ({
  type: PollingNewRecordActionTypes.POLLING_NEW_RECORD_SUCCESS,
});

export type PollingNewRecordActions =
  | PollingNewRecordSuccessAction
  | PollingNewRecordFailAction
  | PollingNewRecordUnknownAction;
