import NotificationPanel from 'components/NotificationPanel/NotificationPanel';
import { NOTIFICATION_TYPES } from 'components/NotificationPanel/types';
import { getMostRecentSoldSale } from 'store/selectors/detailsSelectors';
import { INFORMATIONAL_PANEL_TYPES } from 'pages/NewLease/types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dynamicString } from 'utils/string';
import { INFORMATIONAL_PANEL_COPY } from './constants';

type InformationalPanelProps = {
  type: INFORMATIONAL_PANEL_TYPES;
};

const InformationalPanel = ({ type }: InformationalPanelProps) => {
  const mostRecentSoldSale = useSelector(getMostRecentSoldSale);
  const [panelContent, setPanelContent] = useState({ title: '', body: '' });

  useEffect(() => {
    if (
      type === INFORMATIONAL_PANEL_TYPES.CURRENT_LANDLORD &&
      mostRecentSoldSale?.soldDate &&
      mostRecentSoldSale?.purchaser
    ) {
      const body = mostRecentSoldSale.spv
        ? dynamicString(INFORMATIONAL_PANEL_COPY.currentLandlord.spvBody, [
            mostRecentSoldSale.purchaser,
            mostRecentSoldSale.spv,
            mostRecentSoldSale.soldDate,
          ])
        : dynamicString(INFORMATIONAL_PANEL_COPY.currentLandlord.body, [
            mostRecentSoldSale.purchaser,
            mostRecentSoldSale.soldDate,
          ]);
      setPanelContent({
        title: INFORMATIONAL_PANEL_COPY.currentLandlord.title,
        body,
      });
    }

    if (type === INFORMATIONAL_PANEL_TYPES.MULTI_LEASE_WARNING) {
      setPanelContent({
        title: INFORMATIONAL_PANEL_COPY.multiLeaseWarning.title,
        body: INFORMATIONAL_PANEL_COPY.multiLeaseWarning.body,
      });
    }
  }, [mostRecentSoldSale, type]);

  return panelContent.title && panelContent.body ? (
    <NotificationPanel
      notificationType={NOTIFICATION_TYPES.WARNING}
      notificationBody={panelContent.body}
      notificationTitle={panelContent.title}
    />
  ) : (
    <></>
  );
};

export default InformationalPanel;
