import { User } from 'services/auth';
import { AuthenticationTypes, AuthActionTypes } from 'store/actions/types';

export interface AuthenticationState {
  isLoggedIn: boolean;
  user?: User;
}

export const initialState: AuthenticationState = {
  isLoggedIn: false,
  user: undefined,
};

const authenticationReducer = (
  state = initialState,
  action: AuthenticationTypes,
): AuthenticationState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        isLoggedIn: true,
        user: action.payload.state.user,
      };
    case AuthActionTypes.UNAUTHORIZED_USER:
    case AuthActionTypes.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export default authenticationReducer;
