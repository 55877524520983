import styled from 'styled-components/macro';
import { ReactComponent as ArrowDown } from 'assets/icons/expand-more.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/expand-less.svg';
import { Link } from 'react-router-dom';

export const Menu = styled.div`
  position: absolute;
  top: calc(100% - 0px);
  right: 0;
  min-width: 200px;
  background: ${(props) => props.theme.colorPalette.branding.white};
  z-index: 1;
  border-radius: 0 0 5px 5px;
`;

export const AccountMenuContainer = styled.div`
  position: relative;
  display: flex;
  margin-left: auto;
`;

export const MenuButton = styled.button<{ isOpen: boolean }>`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  color: ${(props) =>
    props.isOpen
      ? props.theme.colorPalette.branding.yellow
      : props.theme.colorPalette.text.white};
  padding: 0 25px;
  position: relative;
  margin-left: 12px;
  border-bottom: ${(props) =>
    props.isOpen
      ? `4px solid ${props.theme.colorPalette.branding.yellow}`
      : `4px solid ${props.theme.colorPalette.branding.ink}`};
`;

export const Button = styled.button`
  padding: 15px;
  border: 0;
  margin: 0;
  text-align: left;
`;

export const MenuListItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const MenuListItemLink = styled(Link)`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  padding: 15px;
  position: relative;
  margin: 0;
`;

export const ArrowDownIcon = styled(ArrowDown)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.white};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

export const ArrowUpIcon = styled(ArrowUp)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.yellow};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;
