import React, { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { globalContent } from 'globalConstants';
import { translateIndexToUserFacing } from 'utils/string';
import { evaluateConditions } from 'connected/FormFlow/services/conditions';
import { getGroups } from 'connected/FormFlow/services/formState';
import {
  MultiLineGroupHeader,
  MultiLineStyled,
  MultiLineGroupWrapper,
  MultiLineGroupColumns,
  StyledButton,
  StyledCloseIcon,
  StyledButtonWrapper,
} from './FormMultilineStyled';
import RepeatableControl from './RepeatableControl';
import useMultiline from '../hooks/useMultiline';
import FormField from '../FormField/FormField';
import { StyledFieldset, Column } from '../FormView/FormViewStyled';
import {
  GroupProps,
  FieldProps,
  OptionProps,
  OptionSets,
  InvestorRow,
  COMPANY_FIELDS,
  COMPANY_TYPE,
  FIELD_COMPONENTS,
} from '../types';
import { FORM_BUILDER_COPY, STATE_FIELDS } from '../constants';
import {
  determineCompanyRowFieldVisibility,
  getCompanyField,
  getCompanyType,
  isInvestorSelected,
  isReadOnlyField,
} from '../services/companies';
import { getOptions } from '../services/utils';

export interface MultilineWithGroupedRepeatProps {
  group: GroupProps;
  value: any;
  handleOnChange: (value: any, label?: string) => void;
}

const INVESTOR_FIELDS = [STATE_FIELDS.INVESTOR, STATE_FIELDS.PRIVATE_INVESTOR];

export const DEFAULT_ROWS_VALUE = [
  {
    index: 0,
    id: uuid(),
    [STATE_FIELDS.BUYER.EQUITY]: {
      index: 0,
      value: 1,
      label: STATE_FIELDS.BUYER.EQUITY_LABEL,
    },
  },
];

const MultilineBuyer = ({
  group,
  value,
  handleOnChange,
}: MultilineWithGroupedRepeatProps) => {
  const formStateItems = value === '' ? DEFAULT_ROWS_VALUE : value;
  const {
    onFieldChange,
    addRows,
    addRepeatableRow,
    handleDeleteRow,
    rowFields,
    updateAllRows,
  } = useMultiline(formStateItems, handleOnChange);

  const handleAddSingleRepeat = () => {
    addRows(1);
  };

  const handleAddRepeatable = () => {
    if (group.repeatableKeys) addRepeatableRow(group.repeatableKeys, 5);
  };

  const isRepeatableHidden = () => {
    const latestRow = rowFields[rowFields.length - 1];
    return (
      group.hideRepeatable &&
      (evaluateConditions(group.hideRepeatable, latestRow) as boolean)
    );
  };

  const investorIsSelected = useMemo(() => {
    return rowFields.some((item: InvestorRow) => {
      return (
        INVESTOR_FIELDS.includes(item.buyerInvestorOccupier?.value) ||
        INVESTOR_FIELDS.includes(item.holdingEntityInvestorOccupier?.value)
      );
    });
  }, [rowFields]);

  const setPrePopulationRowValues = () => {
    const list = [...rowFields];

    if (list.length === 1 && group.groupId === STATE_FIELDS.BUYER.ID) {
      list[0][STATE_FIELDS.BUYER.EQUITY] = {
        index: 0,
        value: 1,
        label: STATE_FIELDS.BUYER.EQUITY_LABEL,
      };

      updateAllRows(list);
    }

    if (list.length === 1 && group.groupId === STATE_FIELDS.SPV.ID) {
      list[0][STATE_FIELDS.SPV.EQUITY] = {
        index: 0,
        value: 1,
        label: STATE_FIELDS.SPV.EQUITY_LABEL,
      };

      updateAllRows(list);
    }
  };

  const getFieldOptions = (options: OptionProps[], optionSet?: OptionSets) => {
    if (
      isInvestorSelected(rowFields) &&
      rowFields.length > 1 &&
      optionSet !== OptionSets.SECTOR
    ) {
      return options?.filter(
        (option: OptionProps) => option.value !== STATE_FIELDS.OWNER,
      );
    }

    return getOptions(options, optionSet);
  };

  const onChange = (field: FieldProps, i: number, subGroup: GroupProps) => (
    incomingValue: string,
    incomingLabel?: string,
  ) => {
    if (field.fieldType && field.fieldType === COMPANY_FIELDS.NAME) {
      if (
        getCompanyType(incomingValue) === COMPANY_TYPE.CH &&
        subGroup.fields
      ) {
        const nationalityItem = getCompanyField(
          subGroup.fields,
          COMPANY_FIELDS.NATIONALITY,
        );

        if (nationalityItem) {
          onFieldChange(
            i,
            globalContent.nationality,
            nationalityItem.name,
            nationalityItem.label,
            FIELD_COMPONENTS.COUNTRY_SELECT,
          );
        }
      }
    }

    const formattedValue =
      field.fieldType === COMPANY_FIELDS.NAME ? undefined : incomingLabel;

    onFieldChange(
      i,
      incomingValue,
      field.name,
      field.label,
      field.componentType,
      field.resetFormState,
      field.resetRefsOnChange,
      formattedValue,
    );
  };

  useEffect(() => {
    setPrePopulationRowValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowFields.length]);

  return (
    <StyledFieldset>
      <MultiLineStyled>
        {rowFields.map((row: any, i: number) => {
          const subGroups = group.subGroups && getGroups(group.subGroups, row);

          return (
            <MultiLineGroupWrapper key={row.index}>
              <MultiLineGroupHeader>
                {translateIndexToUserFacing(i)} {group.multilineTitle}
              </MultiLineGroupHeader>
              {subGroups?.map((subGroup, subGroupIndex: number) => {
                return (
                  <MultiLineGroupColumns key={`group-${subGroupIndex}`}>
                    {subGroup.fields?.map((field: FieldProps) => {
                      const fieldValue = row[field.name]?.value || '';
                      const companyUseCaseFieldVisible = determineCompanyRowFieldVisibility(
                        row,
                        field,
                        subGroup.fields as any,
                        'buyerInvestorOccupier',
                      );

                      return companyUseCaseFieldVisible ? (
                        <Column
                          width={field.columns}
                          key={`${field.fieldId}-${row.index}-${subGroupIndex}`}
                        >
                          <FormField
                            name={`${field.name}-${i}`}
                            label={field.label}
                            handleOnChange={onChange(field, i, subGroup)}
                            readOnly={isReadOnlyField(rowFields, field)}
                            options={getFieldOptions(
                              field.options,
                              field.optionSet,
                            )}
                            type={field.componentType}
                            value={fieldValue}
                            isGroup
                            validation={field.validation}
                          />
                        </Column>
                      ) : null;
                    })}
                  </MultiLineGroupColumns>
                );
              })}
              {i > 0 && (
                <StyledButtonWrapper>
                  <StyledButton
                    onClick={() => {
                      handleDeleteRow(i);
                    }}
                  >
                    <StyledCloseIcon />
                    {FORM_BUILDER_COPY.multiline.buttons.delete}{' '}
                    {group.multilineTitle}
                  </StyledButton>
                </StyledButtonWrapper>
              )}
            </MultiLineGroupWrapper>
          );
        })}
        {!group.lineLimit || rowFields.length < group.lineLimit ? (
          <RepeatableControl
            addButton={group.addButton}
            addRepeatable={group.addRepeatable}
            handleAddSingleRepeat={handleAddSingleRepeat}
            handleAddRepeatable={handleAddRepeatable}
            hideRepeatable={isRepeatableHidden()}
            disabled={!investorIsSelected}
          />
        ) : (
          <></>
        )}
      </MultiLineStyled>
    </StyledFieldset>
  );
};

export default MultilineBuyer;
