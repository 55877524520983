import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import {
  newLeaseApiFail,
  newLeaseApiSuccess,
} from 'store/actions/newLeaseAction';
import { NewLeaseActionTypes } from 'pages/NewLease/types';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const saveNewLeaseApiEndpoint = endpoints.lease;

const saveNewLeaseEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(NewLeaseActionTypes.NEW_LEASE_SAVE_LEASE),
    switchMap((action) => {
      return dependencies
        .authRequest(state$, {
          url: saveNewLeaseApiEndpoint,
          method: 'POST',
          body: action.payload,
        })()
        .pipe(
          map((results) => {
            if (results.status > 204) {
              throw new Error(`error loading ${saveNewLeaseApiEndpoint}`);
            }
            return newLeaseApiSuccess();
          }),
          catchError((error) => of(newLeaseApiFail(error))),
        );
    }),
  );
};

export default saveNewLeaseEpic;
