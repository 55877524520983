import styled from 'styled-components/macro';

import { ReactComponent as Warning } from 'assets/icons/warning.svg';

export type EntityActionProps = {
  error?: string;
};

export const EntitySearchPrompt = styled.h2`
  font-size: ${(props) => props.theme.typography.heading.small};
  text-align: center;
`;

export const NewRecordPageWrapperFull = styled.div`
  background: ${(props) => props.theme.colorPalette.panel.default};
  display: grid;
  grid-template-columns: 1fr 95% 1fr;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    grid-template-columns: 1fr 840px 1fr;
  }
  grid-template-rows: 112px 1fr 112px;
  width: 100%;
`;

export const EntityWrapper = styled.div`
  grid-column: 2;
  grid-row: 2;
`;

export const EntityDetails = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
  border-radius: 3px;
  margin-top: ${(props) => props.theme.spacing.small};
`;

export const EntityHeader = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing.default}
    ${(props) => props.theme.spacing.small};
  background: ${(props) => props.theme.colorPalette.branding.ink};
  color: ${(props) => props.theme.colorPalette.text.white};
`;

export const EntityActions = styled.div<EntityActionProps>`
  padding: ${(props) =>
    props.error
      ? `${props.theme.spacing.small} 300px`
      : `${props.theme.spacing.small} 120px`};
  display: grid;
  grid-template-columns: ${(props) => (props.error ? '1fr' : '1fr 1fr')};
  column-gap: ${(props) => props.theme.spacing.small};
  border-top: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;

export const EntityError = styled.div`
  text-align: center;
  padding: ${(props) => props.theme.spacing.default}
    ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.small};
  font-size: ${(props) => props.theme.typography.heading.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
`;

export const ErrorIcon = styled(Warning)`
  width: 2em;
  height: 2em;
  fill: ${(props) => props.theme.colorPalette.tertiary.datavizPurple};
`;

export const ErrorMessage = styled.p`
  margin: ${(props) => props.theme.spacing.xsmall};
`;
