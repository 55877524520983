import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import {
  newSaleApiFail,
  newSaleApiSuccess,
  newPortfolioSaleApiSuccess,
} from 'store/actions/newSaleAction';
import { NewSaleActionTypes } from 'pages/NewSale/types';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const saveNewDealApiEndpoint = endpoints.sale;

const saveNewDealEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(NewSaleActionTypes.NEW_SALE_SAVE_SALE),
    switchMap((action) => {
      return dependencies
        .authRequest(state$, {
          url: saveNewDealApiEndpoint,
          method: 'POST',
          body: action.payload,
        })()
        .pipe(
          map((results) => {
            if (results.status > 204) {
              throw new Error(`error loading ${saveNewDealApiEndpoint}`);
            }
            if (action.payload.portfolioName) {
              return newPortfolioSaleApiSuccess();
            }
            return newSaleApiSuccess();
          }),
          catchError((error) => of(newSaleApiFail(error))),
        );
    }),
  );
};

export default saveNewDealEpic;
