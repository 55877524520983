import React from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import GlobalStyle from 'styled/Global';
import styledTheme from 'styled/theme';

import logo from 'assets/athena_logo_xmas.svg';

const Wrapper = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.ink};
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
`;

const Logo = styled.img`
  width: 350px;
`;

const SplashScreen = () => (
  <ThemeProvider theme={styledTheme}>
    <GlobalStyle />
    <Wrapper>
      <Logo src={logo} alt="Savills Athena" />
    </Wrapper>
  </ThemeProvider>
);

export default SplashScreen;
