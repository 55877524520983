import { GlobalApiFailError } from 'store/actions/types';

import {
  NewSaleActionTypes,
  EntityNewSale,
  NewSaleSaveAction,
  NewSaleApiFailAction,
  NewSaleApiSuccessAction,
  NewSaleResetAction,
  NewPortfolioSaleApiSuccessAction,
} from 'pages/NewSale/types';

export const newSaleSave = (payload: EntityNewSale): NewSaleSaveAction => ({
  type: NewSaleActionTypes.NEW_SALE_SAVE_SALE,
  payload,
});

export const newSaleReset = (): NewSaleResetAction => ({
  type: NewSaleActionTypes.NEW_SALE_RESET,
});

export const newSaleApiSuccess = (): NewSaleApiSuccessAction => ({
  type: NewSaleActionTypes.NEW_SALE_API_SUCCESS,
});

export const newPortfolioSaleApiSuccess = (): NewPortfolioSaleApiSuccessAction => ({
  type: NewSaleActionTypes.NEW_PORTFOLIO_SALE_API_SUCCESS,
});

export const newSaleApiFail = (
  payload: GlobalApiFailError,
): NewSaleApiFailAction => ({
  type: NewSaleActionTypes.NEW_SALE_API_FAIL,
  payload,
});
