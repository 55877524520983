import { Middleware } from 'redux';
import { RootState } from 'store/rootReducer';
import { createTrackingAction } from './trackingAction';
import { AthenaAction } from './types';

export const userTracking: Middleware<{}, RootState> = (store) => (next) => (
  action: AthenaAction,
) => {
  // ensure incoming action is dispatched first
  // tracking actions can depend on state reduced by incoming actions
  next(action);

  const trackedAction = createTrackingAction(action, store.getState());

  if (trackedAction) {
    next(trackedAction);
  }
};
