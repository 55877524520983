import React from 'react';
import styled from 'styled-components/macro';
import { SELECT_SEARCH_INPUT_COPY } from './constants';

interface SelectSearchShowMoreButtonProps {
  onClick(): void;
}

const ShowMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ShowMoreButtonStyled = styled.button`
  cursor: pointer;
  padding: 0 12px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const SelectSearchShowMoreButton: React.FC<SelectSearchShowMoreButtonProps> = ({
  onClick,
}: SelectSearchShowMoreButtonProps) => {
  return (
    <ShowMoreButtonContainer>
      <ShowMoreButtonStyled onClick={onClick}>
        {SELECT_SEARCH_INPUT_COPY.showMore}
      </ShowMoreButtonStyled>
    </ShowMoreButtonContainer>
  );
};

export default SelectSearchShowMoreButton;
