import { isAfter } from 'date-fns';
import {
  CompanyDebtRecord,
  DebtRecord,
  LeaseRecord,
  SaleRecord,
} from 'pages/Details/types';
import isEmpty from 'ramda/src/isEmpty';
import { EpcCertificate } from 'connected/EpcCertificatesPanel/types';
import { FilterCategories } from 'components/Filter/types';
import { LeaseStatusEnum } from 'constants/leases';
import {
  DebtFilters,
  LeaseDateFilters,
  LeaseDisputedFilters,
  LeaseStatusFilters,
  RecordVisibilityFilters,
} from './types';

const getCompanyDebtItems = (items: any, debtFilters: any) => {
  const collectedItems: CompanyDebtRecord[] = [];
  collectedItems.push(
    ...items.filter((item: CompanyDebtRecord) =>
      debtFilters.includes(item.role),
    ),
  );

  return collectedItems;
};

const getDebtItems = (items: any, debtFilters: any) => {
  const collectedItems: DebtRecord[] = [];
  if (debtFilters?.includes(DebtFilters.CURRENT)) {
    collectedItems.push(
      ...items.filter(
        (item: DebtRecord) =>
          !item.maturityDate ||
          (item.maturityDate &&
            isAfter(new Date(item.maturityDate), new Date())),
      ),
    );
  }

  if (debtFilters?.includes(DebtFilters.HISTORIC)) {
    collectedItems.push(
      ...items.filter(
        (item: DebtRecord) =>
          item.maturityDate &&
          !isAfter(new Date(item.maturityDate), new Date()),
      ),
    );
  }

  return collectedItems;
};

const getEpcItems = (items: EpcCertificate[], epcFilters: any) => {
  const uniqueItems = new Set<string>();
  const now = new Date();

  if (epcFilters?.includes('active')) {
    items
      .filter(
        (item: EpcCertificate) =>
          !item.isSuperseded && new Date(item.expiryDate || 0) > now,
      )
      .forEach((item: EpcCertificate) => uniqueItems.add(item.lmkKey));
  }

  if (epcFilters?.includes('superseded')) {
    items
      .filter((item: EpcCertificate) => item.isSuperseded)
      .forEach((item: EpcCertificate) => uniqueItems.add(item.lmkKey));
  }

  if (epcFilters?.includes('expired')) {
    items
      .filter((item: EpcCertificate) => new Date(item.expiryDate || 0) <= now)
      .forEach((item: EpcCertificate) => uniqueItems.add(item.lmkKey));
  }

  return items.filter((item: EpcCertificate) => uniqueItems.has(item.lmkKey));
};

const getDisputedItems = (items: any, disputedFilters: any) => {
  const collectedItems: LeaseRecord[] | SaleRecord[] = [];

  if (disputedFilters?.includes(LeaseDisputedFilters.DISPUTED)) {
    collectedItems.push(
      ...items.filter((item: LeaseRecord | SaleRecord) => item.isDisputed),
    );
  }

  if (disputedFilters?.includes(LeaseDisputedFilters.NOT_DISPUTED)) {
    collectedItems.push(
      ...items.filter((item: LeaseRecord | SaleRecord) => !item.isDisputed),
    );
  }
  return collectedItems;
};

const getStatusItems = (items: any, statusFilters: any) => {
  const collectedItems: LeaseRecord[] = [];
  if (statusFilters?.includes(LeaseStatusFilters.TERMINATED)) {
    collectedItems.push(
      ...items.filter(
        (item: LeaseRecord) =>
          item.availabilityStatus === LeaseStatusEnum.Terminated,
      ),
    );
  }

  if (statusFilters?.includes(LeaseStatusFilters.LET)) {
    collectedItems.push(
      ...items.filter(
        (item: LeaseRecord) => item.availabilityStatus === LeaseStatusEnum.Let,
      ),
    );
  }
  if (statusFilters?.includes(LeaseStatusFilters.UNDER_OFFER)) {
    collectedItems.push(
      ...items.filter(
        (item: LeaseRecord) =>
          item.availabilityStatus === LeaseStatusEnum.UnderOffer,
      ),
    );
  }
  return collectedItems;
};

const getHiddenItems = (items: any, visibilityFilters: any) => {
  const collectedItems: LeaseRecord[] | SaleRecord[] = [];

  if (visibilityFilters?.includes(RecordVisibilityFilters.HIDDEN)) {
    collectedItems.push(
      ...items.filter((item: LeaseRecord | SaleRecord) => item.isHidden),
    );
  }

  if (visibilityFilters?.includes(RecordVisibilityFilters.VISIBLE)) {
    collectedItems.push(
      ...items.filter((item: LeaseRecord | SaleRecord) => !item.isHidden),
    );
  }
  return collectedItems;
};

const filterDateItems = (items: any, type: LeaseDateFilters) => {
  const now = new Date();
  return items.filter((item: any) => {
    if (!item.endDate && type === LeaseDateFilters.EXPIRED) {
      return false;
    }

    if (!item.startDate && type !== LeaseDateFilters.EXPIRED) {
      return item;
    }

    if (
      !item.endDate &&
      new Date(item.startDate) < now &&
      type === LeaseDateFilters.CURRENT
    ) {
      return true;
    }

    const endDate = new Date(item.endDate);
    endDate.setDate(endDate.getDate() + 1);

    if (type === LeaseDateFilters.CURRENT) {
      return new Date(item.startDate) < now && endDate > now;
    }

    if (type === LeaseDateFilters.FUTURE) {
      return now < new Date(item.startDate);
    }
    return endDate < now;
  });
};

const getDateItems = (items: any, dateFilters: any) => {
  const collectedItems = [];

  if (dateFilters?.includes(LeaseDateFilters.EXPIRED)) {
    collectedItems.push(...filterDateItems(items, LeaseDateFilters.EXPIRED));
  }
  if (dateFilters?.includes(LeaseDateFilters.CURRENT)) {
    collectedItems.push(...filterDateItems(items, LeaseDateFilters.CURRENT));
  }
  if (dateFilters?.includes(LeaseDateFilters.FUTURE)) {
    collectedItems.push(...filterDateItems(items, LeaseDateFilters.FUTURE));
  }
  return collectedItems;
};

export const getFilteredItems = (items: any = [], selectedFilters?: any) => {
  let allItems = items;
  const noSelectedFiltersExist = isEmpty(selectedFilters);
  if (noSelectedFiltersExist) {
    return allItems;
  }

  const dateFilters = selectedFilters[FilterCategories.LEASE_DATE_CATEGORY];

  if (dateFilters) {
    const dateFilteredItems = getDateItems(allItems, dateFilters);
    allItems = dateFilteredItems.filter((v, i, a) => a.indexOf(v) === i);
  }

  const disputedFilters =
    selectedFilters[FilterCategories.LEASE_DISPUTED_CATEGORY];

  if (disputedFilters) {
    allItems = getDisputedItems(allItems, disputedFilters);
  }

  const statusFilters = selectedFilters[FilterCategories.LEASE_STATUS_CATEGORY];

  if (statusFilters) {
    allItems = getStatusItems(allItems, statusFilters);
  }

  const hiddenFilters = selectedFilters[FilterCategories.RECORD_VISIBILITY];

  if (hiddenFilters) {
    allItems = getHiddenItems(allItems, hiddenFilters);
  }

  const debtFilters = selectedFilters[FilterCategories.DEBT_TYPE];
  if (debtFilters) {
    allItems = getDebtItems(allItems, debtFilters);
  }

  const companyDebtFilters = selectedFilters[FilterCategories.DEBT_ROLE];
  if (companyDebtFilters) {
    allItems = getCompanyDebtItems(allItems, companyDebtFilters);
  }

  const useClauseFilters =
    selectedFilters[FilterCategories.LEASE_USE_CLAUSE_CATEGORY];
  if (useClauseFilters) {
    allItems = allItems.filter((item: LeaseRecord) => {
      if (!item.useClauses) return false;

      return item.useClauses.some((currentClause) =>
        useClauseFilters.includes(currentClause),
      );
    });
  }

  const epcFilters = selectedFilters[FilterCategories.EPC_STATUS];
  if (epcFilters) {
    allItems = getEpcItems(allItems, epcFilters);
  }

  return allItems;
};
