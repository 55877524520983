export const ADMIN_QUERY_PANEL_COPY = {
  heading: 'Scheduled Query',
  addScheduleButton: 'Add schedule for email to team',
  noResults: 'Sorry there were no results found',
  resultsModal: {
    title: 'Results',
    successIds: 'Success ids:',
    failedIds: 'Failed ids:',
    ok: 'Ok',
  },
};
