import React, { useContext } from 'react';
import { BoldEmphasized } from 'styled/Global';
import { FormStateContext } from '../context';
import { ConfirmParagraph } from './ConfirmChangesStyled';
import { IDENTIFY_ADDRESSES_COPY } from './constants';
import { CONFIRM_TYPE } from './types';

const MoveBuildingSubElementConfirm: React.FC<{ type: CONFIRM_TYPE }> = ({
  type,
}) => {
  const contextFormState = useContext(FormStateContext);
  return (
    <ConfirmParagraph>
      {type === CONFIRM_TYPE.MOVE_BUILDING_DEBT_RECORD && (
        <>
          {IDENTIFY_ADDRESSES_COPY.confirm.moveDebt}
          <BoldEmphasized>
            {contextFormState.formState.moveDebt.identifyAddress.value
              .address ||
              contextFormState.formState.moveDebt.identifyAddress.value
                .buildingAddress}
          </BoldEmphasized>
        </>
      )}

      {type === CONFIRM_TYPE.MOVE_BUILDING_VALUATION_RECORD && (
        <>
          {IDENTIFY_ADDRESSES_COPY.confirm.moveValuation}
          <BoldEmphasized>
            {contextFormState.formState.moveValuation.identifyAddress.value
              .address ||
              contextFormState.formState.moveValuation.identifyAddress.value
                .buildingAddress}
          </BoldEmphasized>
        </>
      )}
    </ConfirmParagraph>
  );
};

export default MoveBuildingSubElementConfirm;
