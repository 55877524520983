export const IDENTIFY_ADDRESSES_COPY = {
  searchTitle: 'Search for buildings you would like to add to this portfolio',
  selectedTitle: 'Address added to your portfolio',
  confirmRemove: 'Confirm remove all',
  removeAll: 'Remove all',
  confirm: {
    moveLeases: 'The following lease(s) will be moved to ',
    remainLeases: 'The following lease(s) will remain attached to ',
    moveSales: 'The sale will be moved to ',
    moveToLet: 'The To Let will be moved to ',
    moveDebt: 'The Debt will be moved to ',
    moveValuation: 'The Valuation will be moved to ',
  },
};
