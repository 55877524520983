import {
  AuthLoginSuccessPayload,
  AuthLoginSuccess,
  AuthLogoutSuccess,
  AuthError,
  AuthActionTypes,
  UnauthorizedUser,
} from './types';

export const authLoginSuccess = (
  payload: AuthLoginSuccessPayload,
): AuthLoginSuccess => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  payload,
});

export const authLogoutSuccess = (): AuthLogoutSuccess => ({
  type: AuthActionTypes.LOGOUT_SUCCESS,
});

export const authError = (payload: Error): AuthError => ({
  type: AuthActionTypes.AUTH_ERROR,
  payload,
  error: true,
});

export const unauthorizedUser = (): UnauthorizedUser => ({
  type: AuthActionTypes.UNAUTHORIZED_USER,
});
