import { map, switchMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { Action } from 'redux';
import { AuthenticatedRequestObservable } from 'apis/request';
import { DetailsActionTypes, DetailEpicActions } from 'pages/Details/types';
import formatDataPerVertical from 'services/verticalDetails';
import { endpoints, Vertical } from 'globalConstants';

import { getBuildingLeasesSalesSuccess } from 'store/actions/detailsActions';
import { getPortfolioSalesMatchesSuccess } from 'store/actions/formBuilderActions';

type MatchingRecordsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const matchingRecordsEpic: Epic = (
  action$,
  state$,
  { authRequest }: MatchingRecordsEpicDependencies,
) => {
  return action$.pipe(
    ofType<Action, Action, DetailEpicActions>(
      DetailsActionTypes.GET_BUILDING_LEASES_SALES,
    ),
    switchMap((action: any) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const url = endpoints.vertical.byId(
        Vertical.Building,
        action.payload?.id,
      );
      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }

          const detailPayload = formatDataPerVertical(
            Vertical.Building,
            results.response,
          );

          const {
            leases,
            sales,
            mostRecentSoldSale,
            landlords,
            spv,
          } = detailPayload.building;

          getPortfolioSalesMatchesSuccess({ leases, sales });

          return getBuildingLeasesSalesSuccess({
            mostRecentSoldSale,
            landlords,
            spv,
          });
        }),
      );
    }),
  );
};

export default matchingRecordsEpic;
