import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store/rootReducer';

import { globalContent } from 'globalConstants';
import useOutsideClick from 'hooks/useOutsideClick';
import useFieldInputValidation from 'hooks/useFieldInputValidation';
import { updateDetailsSave } from 'store/actions/updateDetailsActions';
import { Notice } from 'components/MatchingRecord/MatchingRecord';
import CustomToast from 'components/CustomToast/CustomToast';

import {
  InlineFormWrapper,
  InlineFormContent,
  StyledForm,
  ButtonWrapper,
  PrimaryButton,
  SecondaryButton,
  StyledSelect,
  StyledFormMain,
  NoticeContainer,
  NoticeInfo,
} from './InlineEditForm.styled';
import { OPTION_SETS } from './constants';
import FormField from './FormField/FormField';
import { FIELD_COMPONENTS, OptionProps, ValidationProps } from './types';

type Option = {
  label: string;
  value: string;
};

const preventFormDefaultSubmission = (event: React.FormEvent) => {
  event.preventDefault();
};

const multiSelectComponents = [
  FIELD_COMPONENTS.MULTI_SELECT,
  FIELD_COMPONENTS.DYNAMIC_MULTI_SELECT,
  FIELD_COMPONENTS.USE_CLAUSE_SELECT,
];

export type EditStateProps = {
  dataSource?: Option;
  [x: string]: any;
};

type InlineEditFormProps = {
  label: string;
  name: string;
  apiKey: string;
  entityName?: string;
  entityId?: string;
  options?: OptionProps[];
  handleClose: () => void;
  validation?: ValidationProps;
  componentType: FIELD_COMPONENTS;
  existingValue?: string | number | Date;
  editMetaWarning?: string;
  editMetaInfo?: string;
  editMetaComponentType?: FIELD_COMPONENTS;
  editMetaOptions?: OptionProps[];
  editMetaKey?: string;
  metaUseContext?: 'DETAILS' | 'RESULTS';
  onSave?: (label: string) => void;
  decimals?: number;
  context?: any;
  optionsFilter?: (options: OptionProps[], context: any) => OptionProps[];
};

const InlineEditForm = ({
  componentType,
  label,
  name,
  options,
  handleClose,
  validation,
  apiKey,
  entityName,
  entityId,
  existingValue,
  editMetaWarning,
  editMetaInfo,
  editMetaComponentType,
  editMetaKey,
  editMetaOptions,
  metaUseContext = 'DETAILS',
  onSave,
  decimals,
  context,
  optionsFilter,
}: InlineEditFormProps) => {
  const toastId = React.useRef<string | number | null>(null);
  const { resourceID, isSaving } = useSelector(
    (state: RootState) => state.details,
  );

  const dispatch = useDispatch();
  const [isFormPopulated, setIsFormPopulated] = useState(false);
  const [offsetRight, setOffsetRight] = useState('auto');
  const [offsetTop, setOffsetTop] = useState('20px');

  const [editState, setEditState] = useState<EditStateProps>({
    [name]: existingValue || '',
    dataSource: undefined,
  });

  const { hasErrors } = useFieldInputValidation(
    editState[name],
    componentType,
    validation,
  );

  const onSelectAllChange = (
    field: string,
    value: string | number | Date | Option | Option[],
  ) => {
    const updatedObject = {
      ...editState,
      [field]: value,
    };

    setEditState(updatedObject);

    if (
      (editMetaKey && updatedObject[editMetaKey] && updatedObject[name]) ||
      (!editMetaKey && updatedObject[name])
    ) {
      setIsFormPopulated(true);
    } else {
      setIsFormPopulated(false);
    }
  };

  const onClickCancelEdit = () => {
    handleClose();
  };

  const notify = () => {
    toastId.current = toast(
      <CustomToast message={`${label} ${globalContent.updating}`} />,
      { containerId: 'results-toast', toastId: `${apiKey}-toast` },
    );
  };

  const onClickSaveEdit = () => {
    if (isSaving) return;
    let value = editState[name];

    if (multiSelectComponents.includes(componentType)) {
      value = value.map((option: OptionProps) => option.value);
    }

    const payload: any = {
      entity: entityName,
      id: entityId || resourceID,
      subSource: editState.dataSource && editState?.dataSource.value,
      property: apiKey,
      value,
    };

    if (editMetaKey && editState[editMetaKey]) {
      payload[editMetaKey] = editState[editMetaKey];
    }

    if (metaUseContext !== 'RESULTS') {
      notify();
    }

    if (onSave) {
      onSave(label);
    }

    dispatch(updateDetailsSave(payload, metaUseContext));
    handleClose();
  };

  const wasMultiSelectOptionClick = (element: HTMLElement) => {
    if (element.closest('.react-select__option')) return;

    handleClose();
  };

  const inlineForm = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (inlineForm && inlineForm.current) {
        const pos = inlineForm.current.getBoundingClientRect();

        // not enough space at the top
        if (pos.top < 0) {
          const difference = -pos.height - 30;
          setOffsetTop(`${difference}px`);
        }
        // not enough space at the right
        if (pos.right > document.documentElement.clientWidth) {
          const difference = window.innerWidth - pos.right - 20;
          setOffsetRight(`${difference}px`);
        } else {
          setOffsetRight('auto');
        }
      }
    };
    window.addEventListener('resize', updatePosition);
    updatePosition();
    return () => window.removeEventListener('resize', updatePosition);
  }, []);

  useOutsideClick(inlineForm, wasMultiSelectOptionClick, true);

  return (
    <InlineFormWrapper>
      <InlineFormContent
        isWide={componentType === FIELD_COMPONENTS.TEXTAREA}
        ref={inlineForm}
        offsetTop={offsetTop}
        marginRight={offsetRight}
      >
        <StyledForm onSubmit={preventFormDefaultSubmission}>
          <StyledFormMain>
            {editMetaKey &&
              editMetaWarning &&
              editMetaInfo &&
              editMetaOptions &&
              editMetaComponentType && (
                <>
                  <NoticeContainer>
                    <Notice
                      noticeText={editMetaWarning}
                      width={34}
                      height={34}
                    />
                  </NoticeContainer>
                  {editMetaInfo && <NoticeInfo>{editMetaInfo}</NoticeInfo>}
                  <FormField
                    type={editMetaComponentType}
                    label=""
                    handleOnChange={(value) =>
                      onSelectAllChange(editMetaKey, value)
                    }
                    name={editMetaKey}
                    options={editMetaOptions}
                    value={editState[editMetaKey]}
                    isInline
                    direction="column"
                    decimals={decimals}
                  />
                </>
              )}
            <FormField
              type={componentType}
              label={label}
              handleOnChange={(value) => onSelectAllChange(name, value)}
              name={name}
              options={options}
              value={editState[name]}
              validation={validation}
              isInline
              decimals={decimals}
              context={context}
              optionsFilter={optionsFilter}
            />
            <div>
              <StyledSelect
                options={OPTION_SETS.DATASOURCE}
                name="allSources"
                label={globalContent.updateSource}
                onSelectChange={(option) =>
                  onSelectAllChange('dataSource', option)
                }
                selectedValue={editState.dataSource}
                labelIsHidden={false}
              />
            </div>
            <ButtonWrapper>
              <SecondaryButton type="button" onClick={onClickCancelEdit}>
                {globalContent.cancel}
              </SecondaryButton>
              <PrimaryButton
                disabled={isSaving || !isFormPopulated || hasErrors}
                type="button"
                onClick={onClickSaveEdit}
              >
                {isSaving ? globalContent.saving : globalContent.save}
              </PrimaryButton>
            </ButtonWrapper>
          </StyledFormMain>
        </StyledForm>
      </InlineFormContent>
    </InlineFormWrapper>
  );
};

export default InlineEditForm;
