import {
  GetPortfolioNamesAction,
  PortfolioNameSelectActionTypes,
  PortfolioNameSelectApiFailAction,
  PortfolioNameSelectApiSuccessAction,
  PortfolioNameSelectQueryAction,
  PortfolioNameSelectResetAction,
  PortfolioNameFilteredSuccessAction,
  PortfolioNameSelectClearSelectedAction,
} from 'connected/PortfolioNameSelect/types';
import { GlobalApiFailError } from 'store/actions/types';

export const getPortfolioNames = (): GetPortfolioNamesAction => ({
  type: PortfolioNameSelectActionTypes.GET_PORTFOLIO_NAMES,
});

export const portfolioNameQuery = (
  payload: string,
): PortfolioNameSelectQueryAction => ({
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_QUERY,
  payload,
});

export const portfolioNameApiSuccess = (
  payload: string[],
): PortfolioNameSelectApiSuccessAction => ({
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_API_SUCCESS,
  payload,
});

export const portfolioNameFilteredSuccess = (
  payload: string[],
): PortfolioNameFilteredSuccessAction => ({
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_FILTERED_SUCCESS,
  payload,
});

export const portfolioNameApiFail = (
  payload: GlobalApiFailError,
): PortfolioNameSelectApiFailAction => ({
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_API_FAIL,
  payload,
});

export const portfolioNameReset = (): PortfolioNameSelectResetAction => ({
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_RESET,
});

export const portfolioNameClearSelected = (): PortfolioNameSelectClearSelectedAction => ({
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_CLEAR_SELECTED,
});
