import {
  DocumentUploaderActionTypes,
  DocumentUploaderActions,
  DocumentUploaderState,
  DocumentUploaderUIStage,
} from 'connected/DocumentUploader/types';

export const initialState: DocumentUploaderState = {
  uploaderStage: DocumentUploaderUIStage.BEFORE_UPLOAD,
  progress: 0,
};

const documentUploaderReducer = (
  state = initialState,
  action: DocumentUploaderActions,
) => {
  switch (action.type) {
    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_START_UPLOAD: {
      return {
        ...state,
        uploaderStage: DocumentUploaderUIStage.DURING_UPLOAD,
        error: '',
        isDuplicate: false,
      };
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_PROGRESS_UPDATE_UPLOAD: {
      return {
        ...state,
        progress: action.payload,
      };
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_CANCEL_UPLOAD: {
      return {
        ...state,
        uploaderStage: DocumentUploaderUIStage.BEFORE_UPLOAD,
        progress: 0,
        error: '',
        isDuplicate: false,
      };
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_SUCCESS: {
      return {
        ...state,
        uploaderStage: DocumentUploaderUIStage.AFTER_UPLOAD,
        error: '',
        isDuplicate: false,
      };
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_FAIL: {
      return {
        ...state,
        uploaderStage: DocumentUploaderUIStage.AFTER_UPLOAD,
        error: action.payload.error,
        isDuplicate: action.payload.errorCode === 409,
      };
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_RESET_UPLOAD: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default documentUploaderReducer;
