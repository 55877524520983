import { TABLE_CELL_TYPE, TABLE_ACTIONS } from 'components/Table/constants';
import { TableRowProps } from 'components/Table/types';
import { MODAL_ID } from 'connected/GlobalModal/constants';
import { urls } from 'globalConstants';
import { formatDate } from 'services/format';
import { UserQueryPayloadType } from 'store/actions/userQueriesActions';
import { ADMIN_QUERY_PANEL_COPY } from './constants';

export const mapQueriesToTableFormattedData = (
  record: UserQueryPayloadType,
  scheduledQuery: any,
): TableRowProps => {
  return {
    name: {
      value: record.queryName,
      linkTo: `${urls.searchFilters}?selectedUserQuery=${record.queryName}&${record.query}`,
    },
    created: {
      value: record.created ? formatDate(record.created) : null,
    },
    updated: {
      value: record.updated ? formatDate(record.updated) : null,
    },
    scheduleStatus: {
      value: scheduledQuery.status,
    },
    schedule: {
      value: scheduledQuery.frequency,
    },
    view: {
      value: '',
      cellType: TABLE_CELL_TYPE.ACTION,
      actions: [
        {
          type: TABLE_ACTIONS.MODAL,
          isDisabled: false,
          modalConfig: {
            modalId: MODAL_ID.SCHEDULE_TEAM_QUERY,
            triggerButton: ADMIN_QUERY_PANEL_COPY.addScheduleButton,
            recordId: record.userQueryId,
          },
        },
      ],
    },
  };
};
