import { of } from 'rxjs';
import { Action } from 'redux';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  UserProfileActions,
  fetchUserProfileAPIFail,
  fetchUserProfileAPISuccess,
} from 'store/actions/userProfileActions';
import { sortArrayByProp } from 'utils/arr';
import { UserQueryActions } from 'store/actions/userQueriesActions';
import { DetailsActionTypes } from 'pages/Details/types';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const userProfileEndpoint = endpoints.userProfile;

/**
 * Get list of User saved search queries
 *
 * @param action$
 * @param state$
 * @param dependencies
 * @returns
 */
const userProfileGet: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType<
      Action,
      Action,
      UserProfileActions | UserQueryActions | DetailsActionTypes
    >(
      UserProfileActions.FETCH_USER_PROFILE,
      UserQueryActions.DELETE_SCHEDULE_API_SUCCESS,
      DetailsActionTypes.DELETE_WATCH_RECORD_SUCCESS,
    ),
    mergeMap(() => {
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url: userProfileEndpoint,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`POST ${userProfileEndpoint} failed`);
            }

            const {
              scheduledQueries,
              userQueries,
              watches,
            } = response.response;

            const sortedUserQueries = sortArrayByProp(
              userQueries,
              'created',
              true,
            );
            return of(
              fetchUserProfileAPISuccess({
                ...response.response,
                scheduledQueries,
                userQueries: sortedUserQueries,
                watchedRecords: watches,
              }),
            );
          }),
          catchError((error) => {
            return of(fetchUserProfileAPIFail(error));
          }),
        );
    }),
  );
};

export default userProfileGet;
