export const parseQueryValue = (value: any) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;

    default:
      return value;
  }
};

export const createObjFromURLParams = (searchParams: URLSearchParams) => {
  return Array.from(searchParams.entries()).reduce(
    (accum, [key, currValue]) => {
      const value = currValue.split(',');
      const parsedValue =
        value.length > 1 ? parseQueryValue(value) : parseQueryValue(value[0]);

      return {
        ...accum,
        [key]: parsedValue,
      };
    },
    {},
  );
};
