import React from 'react';

import { globalContent } from 'globalConstants';
import { translateIndexToUserFacing } from 'utils/string';
import { evaluateConditions } from 'connected/FormFlow/services/conditions';
import { getGroups } from 'connected/FormFlow/services/formState';
import {
  MultiLineGroupHeader,
  MultiLineStyled,
  MultiLineGroupWrapper,
  MultiLineGroupColumns,
  StyledButton,
  StyledCloseIcon,
  StyledButtonWrapper,
} from './FormMultilineStyled';
import RepeatableControl from './RepeatableControl';
import useMultiline from '../hooks/useMultiline';
import FormField from '../FormField/FormField';
import { StyledFieldset, Column } from '../FormView/FormViewStyled';
import {
  GroupProps,
  FieldProps,
  COMPANY_FIELDS,
  COMPANY_TYPE,
  FIELD_COMPONENTS,
} from '../types';
import { FORM_BUILDER_COPY } from '../constants';
import {
  determineCompanyRowFieldVisibility,
  isReadOnlyField,
  getCompanyType,
  getCompanyField,
} from '../services/companies';
import { getOptions } from '../services/utils';

export interface MultilineWithGroupedRepeatProps {
  group: GroupProps;
  value: any;
  handleOnChange: (value: any, label?: string) => void;
  minimumDeleteRows?: number;
}

const MultilineWithGroupedRepeat = ({
  group,
  value,
  handleOnChange,
}: MultilineWithGroupedRepeatProps) => {
  const formStateItems = value === '' ? undefined : value;
  const {
    onFieldChange,
    addRows,
    addRepeatableRow,
    handleDeleteRow,
    rowFields,
  } = useMultiline(formStateItems, handleOnChange);

  const handleAddSingleRepeat = () => {
    addRows(1);
  };

  const handleAddRepeatable = () => {
    if (group.repeatableKeys) addRepeatableRow(group.repeatableKeys, 5);
  };

  const isRepeatableHidden = () => {
    const latestRow = rowFields[rowFields.length - 1];
    return (
      group.hideRepeatable &&
      (evaluateConditions(group.hideRepeatable, latestRow) as boolean)
    );
  };

  const handleFieldChange = (
    field: FieldProps,
    index: number,
    subGroup: GroupProps,
  ) => (incomingValue: string, incomingLabel?: string) => {
    if (field.fieldType && field.fieldType === COMPANY_FIELDS.NAME) {
      if (
        getCompanyType(incomingValue) === COMPANY_TYPE.CH &&
        subGroup.fields
      ) {
        const nationalityItem = getCompanyField(
          subGroup.fields,
          COMPANY_FIELDS.NATIONALITY,
        );

        if (nationalityItem) {
          onFieldChange(
            index,
            globalContent.nationality,
            nationalityItem.name,
            nationalityItem.label,
            FIELD_COMPONENTS.COUNTRY_SELECT,
          );
        }
      }
    }

    const formattedValue =
      field.fieldType === COMPANY_FIELDS.NAME ? undefined : incomingLabel;

    onFieldChange(
      index,
      incomingValue,
      field.name,
      field.label,
      field.componentType,
      field.resetFormState,
      field.resetRefsOnChange,
      formattedValue,
    );
  };

  return (
    <StyledFieldset>
      <MultiLineStyled>
        {rowFields.map((row: any, i: number) => {
          const subGroups = group.subGroups && getGroups(group.subGroups, row);
          return (
            <MultiLineGroupWrapper key={row.index}>
              <MultiLineGroupHeader>
                {translateIndexToUserFacing(i)} {group.multilineTitle}
              </MultiLineGroupHeader>
              {subGroups?.map((subGroup, subGroupIndex: number) => {
                return (
                  <MultiLineGroupColumns
                    key={`group-${row.index}-${subGroupIndex}`}
                  >
                    {subGroup.fields?.map((field: FieldProps) => {
                      const fieldValue = row[field.name]?.value || '';
                      const companyUseCaseFieldVisible = determineCompanyRowFieldVisibility(
                        row,
                        field,
                        subGroup.fields as any,
                        'sellerType',
                      );

                      return (
                        <Column
                          width={field.columns}
                          key={`${field.fieldId}-${row.index}-${subGroupIndex}`}
                        >
                          {companyUseCaseFieldVisible ? (
                            <FormField
                              name={`${field.name}-${i}`}
                              label={field.label}
                              handleOnChange={handleFieldChange(
                                field,
                                i,
                                subGroup,
                              )}
                              readOnly={isReadOnlyField(rowFields, field)}
                              options={getOptions(
                                field.options,
                                field.optionSet,
                              )}
                              type={field.componentType}
                              value={fieldValue}
                              isGroup
                              validation={field.validation}
                            />
                          ) : null}
                        </Column>
                      );
                    })}
                  </MultiLineGroupColumns>
                );
              })}
              {i >= (group.minimumDeleteRows ?? 1) && (
                <StyledButtonWrapper>
                  <StyledButton
                    onClick={() => {
                      handleDeleteRow(i);
                    }}
                  >
                    <StyledCloseIcon />
                    {FORM_BUILDER_COPY.multiline.buttons.delete}{' '}
                    {group.multilineTitle}
                  </StyledButton>
                </StyledButtonWrapper>
              )}
            </MultiLineGroupWrapper>
          );
        })}
        {!group.lineLimit || rowFields.length < group.lineLimit ? (
          <RepeatableControl
            addButton={group.addButton}
            addRepeatable={group.addRepeatable}
            handleAddSingleRepeat={handleAddSingleRepeat}
            handleAddRepeatable={handleAddRepeatable}
            hideRepeatable={isRepeatableHidden()}
          />
        ) : (
          <></>
        )}
      </MultiLineStyled>
    </StyledFieldset>
  );
};

export default MultilineWithGroupedRepeat;
