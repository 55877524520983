import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { ReactComponent as CRM } from 'assets/icons/crm.svg';
import { ReactComponent as CRMNearest } from 'assets/icons/crm-nearest.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import withIconStyles from 'components/Icon/Icon';

const getAlignment = (align?: string) => {
  if (align === 'left') {
    return css`flex-start`;
  }

  return css`space-between`;
};

export const CellItemLinkWrapper = styled.span<{ align?: string }>`
  display: flex;
  justify-content: ${(props) => getAlignment(props.align)};
  position: relative;
`;

export const CellItemLink = styled(Link)`
  color: ${(props) => props.theme.colorPalette.text.dark};

  span {
    display: inline-block;
  }
`;

export const CRMIcon = styled(withIconStyles(CRM))`
  position: absolute;
  right: 0px;
`;

export const CRMNearestIcon = styled(withIconStyles(CRMNearest))`
  position: absolute;
  right: 0px;
`;

export const ExternalLinkIcon = styled(withIconStyles(ExternalLink))`
  position: absolute;
  right: 5px;
  fill: ${(props) => props.theme.colorPalette.text.dark};
  height: 16px;
  width: 16px;
`;

export const IntrohiveIcon = styled(withIconStyles(ExternalLink))`
  position: absolute;
  right: 5px;
  fill: ${(props) => props.theme.colorPalette.text.dark};
  height: 16px;
  width: 16px;
`;
export const CellItemLinkText = styled.span`
  padding-right: 20px;
  min-width: 130px;
`;

export const NoResultsFound = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.default};
  padding: 1em 1em 2em;
`;

export const ExternalLinkStyled = styled.a`
  position: relative;
  padding: 0px 20px 0 10px;
  font-weight: normal;
  color: ${(props) => props.theme.colorPalette.text.dark};
  text-decoration: underline;
  font-size: ${(props) => props.theme.typography.paragraph.small};
`;
