import { RootState } from 'store/rootReducer';

export const getActivePollingUpdateEntity = (state: RootState) =>
  state.pollingUpdate;

export const getIsPolling = (state: RootState) => state.pollingUpdate.polling;

export const getPollingConfirmationStatus = (state: RootState) =>
  state.pollingUpdate.confirmationStatus;

export const getActivePollingProperty = (state: RootState) =>
  state.pollingUpdate.property;
