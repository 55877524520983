export const NotFoundCopy = {
  heading: '404 - Page Not Found',
  description:
    'The page you have requested could not be found. Please try again in a few seconds, or',
  link: 'return to the home page',
};

export const ServerErrorCopy = {
  heading: 'Error',
  description:
    'There was a problem loading the page you requested. If this problem persists, please report it to the Data and Insights team. You can also',
  link: 'return to the home page',
};

export const RecordNotFoundCopy: {
  [key: string]: {
    heading: string;
    description: string;
  };
} = {
  sale: {
    heading: 'Sale Deleted',
    description:
      'This sale has been deleted by an administrator, for the reason stated below:',
  },
  lease: {
    heading: 'Lease Deleted',
    description:
      'This lease has been deleted by an administrator, for the reason stated below:',
  },
  toLet: {
    heading: 'To Let Deleted',
    description:
      'This To Let has been deleted by an administrator, for the reason stated below:',
  },
  building: {
    heading: 'Building Deleted',
    description: 'This Building has been deleted by an administrator.',
  },
};
