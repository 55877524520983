export const setItemWithExpiry = (key: string, value: any, ttl: number) => {
  const now = new Date();
  const expiry = now.getTime() + ttl;
  const item = {
    value,
    expiry,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key: string) => {
  try {
    const serializedItem = localStorage.getItem(key);

    if (!serializedItem) {
      return undefined;
    }

    const now = new Date();
    const item = JSON.parse(serializedItem);

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return undefined;
    }

    return item.value;
  } catch (error) {
    return undefined;
  }
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
