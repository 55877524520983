import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { globalModalHide } from 'store/actions/globalModalActions';

import { CompanyParentActionTypes } from 'connected/EditModals/Company/types';
import {
  companyParentApiFail,
  companyParentApiSuccess,
} from 'store/actions/companyParentActions';

type CompanyParentsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const companyParentsEndpoint = endpoints.editCompanyParents;

const companyParentsEpic: Epic = (
  action$,
  state$,
  dependencies: CompanyParentsEpicDependencies,
) => {
  return action$.pipe(
    ofType(CompanyParentActionTypes.COMPANY_PARENT_EDIT),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'PUT',
          url: companyParentsEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((putResponse) => {
            if (putResponse.status > 204) {
              throw Error(`PUT ${companyParentsEndpoint} failed`);
            }

            return of(
              companyParentApiSuccess(putResponse.response),
              globalModalHide(),
            );
          }),
          catchError((error) => {
            return of(companyParentApiFail(error));
          }),
        );
    }),
  );
};

export default companyParentsEpic;
