import { RootState } from 'store/rootReducer';

export const getModalIsOpenSelector = (state: RootState) => state.modal.isOpen;

export const shouldRemainInPlace = (state: RootState) =>
  state.modal.shouldRemainInPlace;

export const getCurrentModalSelector = (state: RootState) =>
  state.modal.currentModal;

export const getModalSelector = (state: RootState) => state.modal;

export const getCurrentModalRecordIdSelector = (state: RootState) =>
  state.modal.recordId;

export const getCurrentModalVerticalSelector = (state: RootState) =>
  state.modal.vertical;
