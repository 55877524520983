import styled from 'styled-components/macro';

import dashedBorder from 'styled/mixins/dashedBorder';

export const StyledFileInputLabel = styled.label`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colorPalette.text.dark};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 100px;
  padding: ${(props) => props.theme.spacing.default}
    ${(props) => props.theme.spacing.small};
`;

export const FileInputAllowedTypes = styled.p`
  margin: ${(props) => props.theme.spacing.small} 0 0;
  color: ${(props) => props.theme.colorPalette.text.placeholder};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
`;

export const FileInputField = styled.div`
  ${dashedBorder};
`;
