import { FC } from 'react';
import styled from 'styled-components/macro';

export interface IconPropTypes {
  color?: string;
}

function withIconStyles(IconSVG: FC) {
  return styled(IconSVG)<IconPropTypes>`
    fill: ${(props) => props.color || 'currentColor'};
    width: 1.5em;
    height: 1.5em;
  `;
}

export default withIconStyles;
