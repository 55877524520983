import { createSelector } from 'reselect';

import { RootState } from 'store/rootReducer';

export const getUsersList = (state: RootState) => state.usersList;

export const getUsersListResults = createSelector(
  getUsersList,
  (userListResponse) => userListResponse.results,
);

export const getUsersListFetching = createSelector(
  getUsersList,
  (userListResponse) => userListResponse.isFetching,
);
