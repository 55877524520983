import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'ramda/src/isEmpty';

import { Floor } from 'pages/Details/types';
import SelectSearch from 'components/Forms/Inputs/SelectSearch/SelectSearch';
import { Option } from 'components/Forms/types';
import { ValidationProps } from 'connected/FormFlow/types';
import { RowField } from 'connected/FloorSelect/FloorSelect';
import { convertGroundLevel } from 'pages/Details/Building/AccommodationSchedule/helpers';
import { ACCOMMODATION_SCHEDULE_COPY } from 'pages/Details/Building/AccommodationSchedule/constants';
import { buildingFloorsSelector } from './store/selectors';

const MAX_FLOOR_RANGE = 106;
const NUMBER_SUB_LEVELS = 5;

const generateLevels = () => {
  const levelsCollection: Option[] = new Array(MAX_FLOOR_RANGE)
    .fill(null)
    .map((_, index) => {
      const level = `${index - NUMBER_SUB_LEVELS}`;

      return {
        label: `${convertGroundLevel(index - NUMBER_SUB_LEVELS)}`,
        value: level,
      };
    });
  const groundIndex = levelsCollection.findIndex(
    (item) => item.label === ACCOMMODATION_SCHEDULE_COPY.ground,
  );

  levelsCollection.splice(groundIndex, 0, {
    label: ACCOMMODATION_SCHEDULE_COPY.lower,
    value: '-0.5',
  });

  levelsCollection.splice(groundIndex + 2, 0, {
    label: ACCOMMODATION_SCHEDULE_COPY.upper,
    value: '0.5',
  });

  return levelsCollection.reverse();
};

interface FloorLevelSelectProps {
  name: string;
  label: string;
  selectedValue?: string | number;
  onChange: (value: number, label: string) => void;
  onDelete?: () => void;
  validation?: ValidationProps;
  rowFields: RowField[];
  setRowFields?: React.Dispatch<any>;
  lineValue?: any;
}

const FloorLevelSelect: React.FC<FloorLevelSelectProps> = ({
  onChange,
  selectedValue,
  rowFields,
  setRowFields,
  lineValue,
  name,
  ...rest
}) => {
  const existingBuildingFloors = useSelector(buildingFloorsSelector);
  const options = useMemo(() => generateLevels(), []);

  const selectedOptionValue = options.find((option) => {
    const parsedSelectedValue =
      typeof selectedValue === 'number' ? `${selectedValue}` : selectedValue;

    return option.value === parsedSelectedValue;
  });

  const setFloorNameIfGroundLevel = (floorLevel: number) => {
    if (setRowFields) {
      let rowsPayload: RowField[] = [];
      let updatedLine: RowField | null = null;

      if (floorLevel === 0 || floorLevel === 0.5 || floorLevel === -0.5) {
        updatedLine = {
          ...lineValue,
          floor: {
            componentType: 'FloorSelect',
            index: lineValue.index,
            label: 'Floor Name',
            value: convertGroundLevel(floorLevel),
          },
        };
      }

      if (updatedLine) {
        rowsPayload = rowFields.map((row) => {
          return row.index === updatedLine?.index ? updatedLine : row;
        });
      }

      if (!isEmpty(rowsPayload)) setRowFields(rowsPayload);
    }
  };

  const setPrepopulatedAreaAndMeasureMent = (existingLevel: Floor) => {
    if (!setRowFields) {
      return null;
    }

    let rowsPayload: RowField[] = [];
    let updatedLine: RowField | null = null;

    if (existingLevel.areaSqFt) {
      updatedLine = {
        ...lineValue,
        totalArea: {
          componentType: 'SquareFtInput',
          index: lineValue.index,
          label: 'Demise sq ft',
          value: existingLevel.areaSqFt,
        },
      };
    }

    if (existingLevel.measurementStandard) {
      updatedLine = updatedLine || ({ ...lineValue } as RowField);

      updatedLine.measurementStandard = {
        componentType: 'SelectInput',
        index: lineValue.index,
        label: 'Measurement Standard',
        value: existingLevel.measurementStandard,
      };
    }

    if (existingLevel.floorId) {
      updatedLine = updatedLine || ({ ...lineValue } as RowField);

      updatedLine.floorId = { value: existingLevel.floorId };
    }

    if (updatedLine) {
      rowsPayload = rowFields.map((row) => {
        return row.index === updatedLine?.index ? updatedLine : row;
      });
    }

    if (!isEmpty(rowsPayload)) {
      return setRowFields(rowsPayload);
    }

    return null;
  };

  const onSelectChange = (option: Option) => {
    const levelValue = parseFloat(option.value);
    const existingLevel = existingBuildingFloors?.find(
      (floor) => floor.floorLevel === levelValue,
    );

    onChange(levelValue, option.label);

    if (levelValue === 0 || levelValue === 0.5 || levelValue === -0.5) {
      setFloorNameIfGroundLevel(levelValue);
    }
    if (existingLevel) {
      setPrepopulatedAreaAndMeasureMent(existingLevel);
    }
  };

  return (
    <SelectSearch
      id={`${name}_floor-select`}
      name={`${name}_floor-select`}
      {...rest}
      multipleSelect={false}
      options={options}
      selectedOptions={selectedOptionValue ? [selectedOptionValue] : undefined}
      onChange={onSelectChange}
      placeholder=""
    />
  );
};

export default FloorLevelSelect;
