import React from 'react';
import { formatAddressPart } from 'services/format';
import { Paragraph, Headline } from './AddressCardStyled';
import { AddressProps } from './types';

const AddressCardContent = ({ address, isSquare }: AddressProps) => {
  const {
    street,
    buildingName,
    buildingNumber,
    town,
    postcode,
    subBuilding,
  } = address;

  if (isSquare) {
    return (
      <>
        {street && <Paragraph>{street},</Paragraph>}
        {town && <Paragraph>{town},</Paragraph>}
        <Paragraph>{postcode}</Paragraph>
      </>
    );
  }

  const headline = buildingName
    ? `${formatAddressPart(subBuilding)}${buildingName}`
    : `${formatAddressPart(subBuilding)}${buildingNumber}`;
  return (
    <Paragraph>
      {headline}
      &nbsp;
      {street && `${street}, `}
      {town && `${town}, `}
      {postcode}
    </Paragraph>
  );
};

const AddressCard = ({ address, isSquare }: AddressProps) => {
  const { street, buildingName, buildingNumber, subBuilding } = address;

  const headline = buildingName
    ? `${formatAddressPart(subBuilding)}${buildingName}`
    : `${formatAddressPart(subBuilding)}${buildingNumber} ${street}`;
  return (
    <>
      <Headline>{headline}</Headline>
      <AddressCardContent isSquare={isSquare} address={address} />
    </>
  );
};

export default AddressCard;
