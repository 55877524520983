import React from 'react';
import useMultiline from '../hooks/useMultiline';
import MultilineFieldset from './FormMultiline';
import { MultilineProps } from '../types';

import BatchControl from './BatchControl';

interface MultiLineWithBatchProps extends MultilineProps {
  addBatchLabel?: string[];
  removeLabel?: string;
  multilineTitle?: string;
}

const MultiLineWithBatch = ({
  lineConfig,
  handleOnChange,
  addButton,
  addBatchLabel,
  formStateItems,
  multilineTitle,
  prompt,
  description,
  groupId,
}: MultiLineWithBatchProps) => {
  const {
    onFieldChange,
    addRows,
    handleDeleteRow,
    rowFields,
    updateAllRows,
  } = useMultiline(formStateItems, handleOnChange);

  const onInsertRows = (quantity: number) => {
    addRows(quantity);
  };

  return (
    <div>
      <MultilineFieldset
        prompt={prompt}
        description={description}
        rowFields={rowFields}
        setRowFields={updateAllRows}
        lineConfig={lineConfig}
        onFieldChange={onFieldChange}
        handleDeleteLine={handleDeleteRow}
        multilineTitle={multilineTitle}
        groupId={groupId}
      />
      <BatchControl
        addBatchLabel={addBatchLabel}
        addButton={addButton}
        onInsertRows={onInsertRows}
      />
    </div>
  );
};

export default MultiLineWithBatch;
