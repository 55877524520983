import React from 'react';
import useMultiline from '../hooks/useMultiline';
import MultilineFieldset from './FormMultiline';
import { MultilineProps } from '../types';
import { FORM_BUILDER_COPY } from '../constants';
import { StyledAddIcon, StyledAddButton } from './FormMultilineStyled';

interface MultiLineWithSingleAddProps extends MultilineProps {
  removeLabel?: string;
  multilineTitle?: string;
  minimumLines?: number;
}

const MultiLineWithSingleAdd = ({
  addButton,
  lineConfig,
  handleOnChange,
  formStateItems,
  multilineTitle,
  lineLimit,
  prompt,
  description,
  groupId,
  minimumLines,
}: MultiLineWithSingleAddProps) => {
  const { onFieldChange, addRows, handleDeleteRow, rowFields } = useMultiline(
    formStateItems,
    handleOnChange,
  );

  const handleAddSingleRow = () => {
    addRows(1);
  };

  return (
    <div>
      <MultilineFieldset
        prompt={prompt}
        description={description}
        rowFields={rowFields}
        lineConfig={lineConfig}
        onFieldChange={onFieldChange}
        handleDeleteLine={handleDeleteRow}
        multilineTitle={multilineTitle}
        lineLimit={lineLimit}
        groupId={groupId}
        minimumLines={minimumLines}
      />

      {!lineLimit || rowFields.length < lineLimit ? (
        <StyledAddButton type="button" onClick={handleAddSingleRow}>
          <StyledAddIcon />
          {addButton || FORM_BUILDER_COPY.multiline.buttons.add}
        </StyledAddButton>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MultiLineWithSingleAdd;
