import {
  ofType,
  Epic,
  StateObservable,
  ActionsObservable,
} from 'redux-observable';
import { mergeMap, map, takeUntil, catchError, merge } from 'rxjs/operators';
import { of, Subscriber, Subject } from 'rxjs';

import { RootState } from 'store/rootReducer';
import {
  DocumentUploaderActionTypes,
  StartFileUploadAction,
} from 'connected/DocumentUploader/types';

import {
  AuthenticatedRequestObservable,
  UnauthenticatedRequestObservable,
} from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  progressUpdateFileUpload,
  fileUploadApiSuccess,
  fileUploadApiFail,
} from 'store/actions/documentUploaderActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
  request: UnauthenticatedRequestObservable;
};

type UploadResponse = {
  url: string;
  fields: Record<string, string>;
};

type PresignedResponseWithUploadActionPayload = [
  StartFileUploadAction,
  UploadResponse,
];

const sendFileToBucket = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>,
  dependencies: EpicDependencies,
) => ([action, uploadOptions]: PresignedResponseWithUploadActionPayload) => {
  const { payload, callback } = action;
  const form = new FormData();
  const onProgressSubject$ = new Subject<number>();

  Object.entries(uploadOptions.fields).forEach(([name, value]) =>
    form.append(name, value as string),
  );
  form.append('file', payload.file);

  const s3UploadRequest = dependencies.request({
    method: 'POST',
    url: uploadOptions.url,
    body: form,
    progressSubscriber: Subscriber.create((event?: ProgressEvent) => {
      if (event) {
        const percentage = Math.round((event.loaded / event.total) * 100);
        onProgressSubject$.next(percentage);
      }
    }),
  });

  const s3UploadRequestObservable = s3UploadRequest.pipe(
    mergeMap((uploadResponse) => {
      if (uploadResponse.status > 399) {
        throw new Error('error sending s3 file');
      }

      return dependencies
        .authRequest(state$, {
          method: 'POST',
          url: endpoints.document.onUploadSuccess,
          body: {
            filename: payload.filename,
            entityId: payload.entityId,
            entityType: payload.entityType,
          },
        })()
        .pipe(map(() => fileUploadApiSuccess(callback)));
    }),
    takeUntil(
      action$.pipe(
        ofType(DocumentUploaderActionTypes.DOCUMENT_UPLOADER_CANCEL_UPLOAD),
      ),
    ),
  );

  return onProgressSubject$.pipe(
    map((percentage) => progressUpdateFileUpload(percentage)),
    merge(s3UploadRequestObservable),
    catchError((error) =>
      of(fileUploadApiFail({ error: error.message, errorCode: error.status })),
    ),
  );
};

export const uploadToS3: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(DocumentUploaderActionTypes.DOCUMENT_UPLOADER_START_UPLOAD),
    mergeMap((action: StartFileUploadAction) => {
      const { filename, entityId, lastModified } = action.payload;

      return dependencies
        .authRequest(state$, {
          url: endpoints.document.upload,
          method: 'POST',
          body: {
            filename,
            entityId,
            lastModified,
            type: action.payload.type,
          },
        })()
        .pipe(
          map((ajaxResponse) => {
            const presignedUrlWithSourceAction: PresignedResponseWithUploadActionPayload = [
              action,
              ajaxResponse.response,
            ];
            return presignedUrlWithSourceAction;
          }),
          mergeMap(sendFileToBucket(action$, state$, dependencies)),
          catchError((error) =>
            of(
              fileUploadApiFail({
                error: error.message,
                errorCode: error.status,
              }),
            ),
          ),
        );
    }),
  );
};
