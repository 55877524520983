import styled, { css } from 'styled-components/macro';
import compose from 'ramda/src/compose';
import { desaturate, lighten } from 'polished';
import { Link } from 'react-router-dom';

import withIconStyles, { IconPropTypes } from 'components/Icon/Icon';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Industrial } from 'assets/icons/industrial.svg';
import { ReactComponent as Office } from 'assets/icons/office.svg';
import { ReactComponent as Retail } from 'assets/icons/retail.svg';
import { ReactComponent as SubDirectory } from 'assets/icons/subdirectory.svg';
import { QuickSearchColorMode } from '../types';

const withPrimaryUseIconStyles = (IconSVG: React.FC) => styled(IconSVG)<
  IconPropTypes
>`
  fill: ${(props) =>
    compose(
      desaturate('0.5'),
      lighten('0.3'),
    )(props.theme.colorPalette.branding.ink)};
  margin-right: 10px;
  height: 16px;
  width: 16px;
`;

const ChevronRightStyled = withIconStyles(ChevronRight);
export const ChevronRightIcon = styled(ChevronRightStyled)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  width: 2em;
  height: 2em;
`;

const IndustrialStyled = withPrimaryUseIconStyles(Industrial);
export const IndustrialIcon = styled(IndustrialStyled)``;

const OfficeStyled = withPrimaryUseIconStyles(Office);
export const OfficeIcon = styled(OfficeStyled)``;

const RetailStyled = withPrimaryUseIconStyles(Retail);
export const RetailIcon = styled(RetailStyled)``;

export const SubDirectoryIcon = styled(SubDirectory)<{
  mode?: QuickSearchColorMode;
}>`
  fill: ${(props) =>
    props.mode === 'dark'
      ? props.theme.colorPalette.branding.white
      : props.theme.colorPalette.branding.ink};
  height: 20px;
  width: 20px;
  margin: 10px;
`;

export const ResultListItem = styled.li<{ isChild?: boolean }>`
  margin-bottom: 8px;

  ${(props) =>
    props.isChild &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`;

const baseResultItemStyles = css`
  width: 100%;
  background: ${(props) => props.theme.colorPalette.branding.white};
  color: ${(props) => props.theme.colorPalette.text.dark};
  display: block;
  padding: 10px 40px 10px 12px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 42px;
  transition: opacity 0.1s linear;

  &:disabled {
    opacity: 0.5;
  }
`;

export const ResultItemButton = styled.button<{
  isChild?: boolean;
}>`
  ${baseResultItemStyles};

  ${(props) =>
    props.isChild &&
    css`
      width: calc(100% - 40px);
    `}
`;

export const ResultItemLink = styled(Link)`
  ${baseResultItemStyles};
`;

export const ResultAddressWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const ResultAddress = styled.span`
  text-align: left;
`;
