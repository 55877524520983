import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'store/selectors/featureFlagSelectors';

/**
 * @return {boolean} false when feature flag disabled, true otherwise
 */
function useFeatureFlag(feature?: string) {
  const [isSwitchedOn, setIsSwitchedOn] = useState(true);
  const featureFlags = useSelector(getFeatureFlags);

  useEffect(() => {
    const matchingFeature =
      featureFlags && featureFlags?.find((flag) => flag.key === feature);
    setIsSwitchedOn(matchingFeature ? matchingFeature.value : true);
  }, [featureFlags, feature]);

  return isSwitchedOn;
}

export default useFeatureFlag;
