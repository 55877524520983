import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  getCompareErrorsSelector,
  getHasCompareError,
  getRecordsToCompare,
  isFetchingRecordsToCompare,
} from 'store/selectors/detailsSelectors';

import {
  TableCellStyled,
  TableRowStyled,
  TableStyled,
} from 'components/Table/TableStyled';

import { Vertical, VerticalLabelMap } from 'globalConstants';
import { ErrorMessage } from 'connected/NewRecordWrapper/NewRecordWrapperStyled';

import { mergeSaleMap } from 'connected/EditModals/MergeRecords/mergeSalesMap';
import { mergeLeaseMap } from 'connected/EditModals/MergeRecords/mergeLeasesMap';
import { LoadingSpinnerContainer } from 'components/ResultsCount/ResultsCountStyled';
import { LoadingSpinner } from 'components/Spinner/Spinner';
import { getCurrentModalVerticalSelector } from 'store/selectors/globalModalSelectors';
import { BoldEmphasized } from 'styled/Global';

import { CLEAR_FIELD } from 'connected/EditModals/MergeRecords/constants';
import { getConditionalChildren, mapComparisonData } from './helpers';
import RowItem from './RowItem';

const saleMapValues = Object.values(mergeSaleMap);
type SaleMapValues = typeof saleMapValues;

const leaseMapValues = Object.values(mergeLeaseMap);
type LeaseMapValues = typeof leaseMapValues;

const checkIfSelected = (row: any, rawValue: any, value: any, rowItem: any) => {
  if (!row || !rowItem) {
    return false;
  }

  const parsedObj = row ? JSON.parse(row) : row;
  const matchesID = parsedObj.recordId === rowItem.id;

  if (parsedObj?.rawValue !== null || parsedObj?.value !== null) {
    return (
      (parsedObj?.value === value || parsedObj?.value === rawValue) && matchesID
    );
  }
  return false;
};

const MergeRecordsField: any = ({
  handleOnChange,
  defaultValue,
}: {
  handleOnChange: (mergePayload: any) => void;
  defaultValue: any;
}) => {
  const recordsToCompare = useSelector(getRecordsToCompare);
  const hasError = useSelector(getHasCompareError);
  const errorMessage = useSelector(getCompareErrorsSelector);
  const vertical = useSelector(getCurrentModalVerticalSelector);
  const isFetching = useSelector(isFetchingRecordsToCompare);

  const [selectedValues, setSelectedValues] = useState(defaultValue);
  const mapValues: any =
    vertical === Vertical.Lease ? leaseMapValues : saleMapValues;

  useEffect(() => {
    setSelectedValues(defaultValue);
    if (JSON.stringify(defaultValue) !== JSON.stringify(selectedValues)) {
      handleOnChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const comparisonGroups = useMemo(
    () => mapComparisonData(recordsToCompare, mapValues, selectedValues),
    [recordsToCompare, mapValues, selectedValues],
  );

  const handleSelectOption = (value: any, key: string, id: string) => {
    const selectedRecord = recordsToCompare.find(
      (record: any) => record.id.value === id,
    );

    const conditionalChildren = getConditionalChildren(
      mapValues,
      key,
      value,
      comparisonGroups,
      selectedRecord,
    );

    const allSelectedValues = {
      ...selectedValues,
      ...conditionalChildren,
    };

    const updatedObject = {
      ...allSelectedValues,
      [key]: JSON.stringify({ value: value ?? CLEAR_FIELD, recordId: id }),
    };
    setSelectedValues(updatedObject);
    handleOnChange(updatedObject);
  };

  const colHeaders = recordsToCompare.reduce(
    (accum: [], record: any, i: number) => {
      if (record.rowIsHidden) {
        return accum;
      }

      const colKey = record.id.value;

      const colHeader =
        vertical === Vertical.Lease
          ? VerticalLabelMap.lease
          : VerticalLabelMap.sale;

      return [
        ...accum,
        {
          key: colKey,
          heading: `${colHeader} ${i + 1}`,
        },
      ];
    },
    [],
  );

  if (isFetching) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    );
  }
  if (!isFetching && hasError) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  return (
    <TableStyled>
      <thead>
        <TableRowStyled>
          <TableCellStyled />
          {colHeaders.map((header: any) => (
            <TableCellStyled key={header.key}>
              <BoldEmphasized>{header.heading}</BoldEmphasized>
            </TableCellStyled>
          ))}
        </TableRowStyled>
      </thead>
      <tbody>
        {comparisonGroups.map((row: any) => (
          <TableRowStyled key={row.key}>
            <TableCellStyled>
              <BoldEmphasized>{row.label}</BoldEmphasized>
            </TableCellStyled>
            {row.items.map((rowItem: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <RowItem
                key={`${rowItem.id}_${row.key}`}
                updateSelectedRadios={() =>
                  handleSelectOption(rowItem.rawValue, row.key, rowItem.id)
                }
                label={row.label}
                itemKey={`${rowItem.id}_${row.key}`}
                row={rowItem}
                selected={checkIfSelected(
                  selectedValues[row.key],
                  rowItem.rawValue,
                  rowItem.value,
                  rowItem,
                )}
                isBlankOption={row.conditionIsNotMet}
                rowIsHidden={row.rowIsHidden}
              />
            ))}
          </TableRowStyled>
        ))}
      </tbody>
    </TableStyled>
  );
};

export default MergeRecordsField;
