import { Reducer } from 'redux';
import {
  ExportSearchResults,
  ExportSearchResultsAction,
  ExportSearchResultsDocX,
  ExportSearchResultsDocXAction,
} from 'store/actions/exportSearchResultsActions';

export type ExportSearchResultsState = {
  inProgress: boolean;
  type?: 'excel' | 'docx';
};

export const initialState: ExportSearchResultsState = {
  inProgress: false,
};

type ExportResultsActions =
  | ExportSearchResultsDocXAction
  | ExportSearchResultsAction;

const reducer: Reducer<ExportSearchResultsState, ExportResultsActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ExportSearchResults.Start:
      return {
        inProgress: true,
        type: 'excel',
      };

    case ExportSearchResultsDocX.Start: {
      return {
        inProgress: true,
        type: 'docx',
      };
    }

    case ExportSearchResults.Completed:
    case ExportSearchResults.Failed:
    case ExportSearchResultsDocX.Completed:
    case ExportSearchResultsDocX.Failed:
      return initialState;

    default: {
      return state;
    }
  }
};

export default reducer;
