import {
  ScheduleTeamQueryActions,
  ScheduleTeamQuerySuccessAction,
  ScheduleTeamQuerySuccessPayloadType,
} from 'store/actions/scheduleTeamQueryActions';

export const initialState: ScheduleTeamQuerySuccessPayloadType = {
  successIds: [],
  failedIds: [],
};

const scheduleTeamQueriesReducer = (
  state = initialState,
  action: ScheduleTeamQuerySuccessAction,
) => {
  switch (action.type) {
    case ScheduleTeamQueryActions.SCHEDULE_TEAM_QUERY_SUCCESS: {
      return {
        ...state,
        successIds: action.payload.successIds,
        failedIds: action.payload.failedIds,
      };
    }

    default:
      return state;
  }
};

export default scheduleTeamQueriesReducer;
