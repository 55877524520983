import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  children: React.ReactNode;
};
export function AppTitle({ children }: Props) {
  return (
    <Helmet>
      <title>Athena: {children}</title>
    </Helmet>
  );
}
