import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  UserQueryActions,
  fetchUserQueryAPIFail,
  fetchUserQueryAPISuccess,
} from 'store/actions/userQueriesActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const userQueryEndpoint = endpoints.userQuery;

/**
 * Get list of User saved search queries
 *
 * @param action$
 * @param state$
 * @param dependencies
 * @returns
 */
const userQueryGet: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(
      UserQueryActions.FETCH_USER_QUERIES,
      UserQueryActions.UPDATE_QUERY_API_SUCCESS,
    ),
    mergeMap(() => {
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url: userQueryEndpoint,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`POST ${userQueryEndpoint} failed`);
            }
            return of(fetchUserQueryAPISuccess(response.response));
          }),
          catchError((error) => {
            return of(fetchUserQueryAPIFail(error));
          }),
        );
    }),
  );
};

export default userQueryGet;
