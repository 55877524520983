import {
  map,
  switchMap,
  withLatestFrom,
  catchError,
  filter,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import isEmpty from 'ramda/src/isEmpty';

import { endpoints } from 'globalConstants';
import { getSearchQuery } from 'store/selectors/searchQuery';
import { AuthenticatedRequestObservable } from 'apis/request';
import {
  createSearchResultsStatisticsFail,
  createSearchResultsStatisticsSuccess,
  SearchActionTypes,
} from 'store/actions/searchActions';

type SearchStatisticsCreateEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const searchStatisticsCreateEpic: Epic = (
  action$,
  state$,
  { authRequest }: SearchStatisticsCreateEpicDependencies,
) =>
  action$.pipe(
    ofType<any>(SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS),
    filter((action) => {
      return !isEmpty(action.payload);
    }),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const query = getSearchQuery(state);
      const params = new URLSearchParams(query as Record<string, string>);
      const searchQuery = params.toString();
      const url = endpoints.searchStatistics;

      return authRequest(state$, {
        method: 'POST',
        url,
        body: {
          query: `${endpoints.searchQuery}?${searchQuery}`,
        },
      })().pipe(
        map((results) => {
          if (results.status !== 201) {
            throw new Error(`error loading ${url}`);
          }

          const { userSearchStatisticId } = results.response;

          return createSearchResultsStatisticsSuccess({
            userSearchStatisticId,
          });
        }),
        catchError(() => {
          return of(createSearchResultsStatisticsFail());
        }),
      );
    }),
  );

export default searchStatisticsCreateEpic;
