import React, { useState, ChangeEvent } from 'react';

import { add, sub, format, parse } from 'date-fns';

import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import { INPUT_FORMAT_TYPE } from 'globalConstants';
import { RelativeDatepickerType, RelativeDatepickerTypes } from './types';
import {
  RelativeDatePickerWrapper,
  SectionHeader,
  SectionWrapper,
  SectionContent,
  Placeholder,
  TextWrapper,
} from './DatePickerStyled';

import Select from '../Select/Select';
import {
  AMOUNT_OPTIONS,
  LENGTH_OPTIONS,
  PERIOD_OPTIONS,
  RELATIVE_DATE_PICKER_COPY,
  FINISHING_AMOUNT_OPTIONS,
} from './constants';
import TextInput from '../TextInput/TextInput';

const getUpdatedDate = (
  relativeDate: any,
  type: 'STARTING' | 'FINISHING',
  startDate: string,
) => {
  const { initialDate, period, amount, length } =
    type === 'STARTING'
      ? {
          initialDate: new Date(),
          period: relativeDate[RelativeDatepickerTypes.STARTING_PERIOD].value,
          amount: relativeDate[RelativeDatepickerTypes.STARTING_AMOUNT],
          length: relativeDate[RelativeDatepickerTypes.STARTING_LENGTH].value,
        }
      : {
          initialDate: parse(startDate, 'dd/MM/yyyy', new Date()),
          period: 'from-now',
          amount: relativeDate[RelativeDatepickerTypes.FINISHING_AMOUNT],
          length: relativeDate[RelativeDatepickerTypes.FINISHING_LENGTH].value,
        };
  if (period === 'ago') {
    return format(
      sub(initialDate, {
        [length]: [amount],
      }),
      'dd/MM/yyyy',
    );
  }

  return format(
    add(initialDate, {
      [length]: [amount],
    }),
    'dd/MM/yyyy',
  );
};

const RelativeDatePicker: React.FC<RelativeDatepickerType> = ({
  label,
  name,
  onChange,
  isDisabled,
  value,
  isPastDateOnly: isPastOnly,
}) => {
  const defaultPayload = {
    [RelativeDatepickerTypes.STARTING_AMOUNT]: AMOUNT_OPTIONS,
    [RelativeDatepickerTypes.STARTING_LENGTH]: LENGTH_OPTIONS[0],
    [RelativeDatepickerTypes.STARTING_PERIOD]: isPastOnly
      ? PERIOD_OPTIONS[0]
      : PERIOD_OPTIONS[1],
    [RelativeDatepickerTypes.FINISHING_AMOUNT]: FINISHING_AMOUNT_OPTIONS,
    [RelativeDatepickerTypes.FINISHING_LENGTH]: LENGTH_OPTIONS[0],
  };

  const defaultStartingDate = format(new Date(), 'dd/MM/yyyy');
  const defaultFinishingDate = format(
    add(new Date(), {
      days: 1,
    }),
    'dd/MM/yyyy',
  );

  const formattedStartingDate = value
    ? getUpdatedDate(value, 'STARTING', defaultStartingDate)
    : defaultStartingDate;

  const formattedFinishingDate = value
    ? getUpdatedDate(value, 'FINISHING', formattedStartingDate)
    : defaultFinishingDate;

  const [relativeDate, setRelativeDate] = useState(value || defaultPayload);
  const [startingDate, setStartingDate] = useState(formattedStartingDate);
  const [finishingDate, setFinishingDate] = useState(formattedFinishingDate);

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: RelativeDatepickerTypes,
    isStartingDate?: boolean,
  ) => {
    const updatedRelativeDate = { ...relativeDate, [type]: event };
    setRelativeDate(updatedRelativeDate);
    if (onChange) {
      let transformedString = '';
      if (
        updatedRelativeDate[RelativeDatepickerTypes.STARTING_PERIOD] ===
        PERIOD_OPTIONS[0]
      ) {
        transformedString = `-`;
      }

      transformedString += `${
        updatedRelativeDate[RelativeDatepickerTypes.STARTING_AMOUNT]
      }|${updatedRelativeDate[RelativeDatepickerTypes.STARTING_LENGTH].value},${
        updatedRelativeDate[RelativeDatepickerTypes.FINISHING_AMOUNT]
      }|${updatedRelativeDate[RelativeDatepickerTypes.FINISHING_LENGTH].value}`;
      onChange(transformedString);
    }

    if (isStartingDate) {
      const updatedStartDate = getUpdatedDate(
        updatedRelativeDate,
        'STARTING',
        startingDate,
      );
      setStartingDate(updatedStartDate);

      return setFinishingDate(
        getUpdatedDate(updatedRelativeDate, 'FINISHING', updatedStartDate),
      );
    }

    return setFinishingDate(
      getUpdatedDate(updatedRelativeDate, 'FINISHING', startingDate),
    );
  };
  return (
    <RelativeDatePickerWrapper>
      <SectionWrapper>
        <SectionHeader>
          {RELATIVE_DATE_PICKER_COPY.STARTING.HEADING}{' '}
          {`(${RELATIVE_DATE_PICKER_COPY.CURRENTLY}
          ${startingDate})`}
        </SectionHeader>
        <SectionContent>
          <TextWrapper>
            <TextInput
              formatInput={INPUT_FORMAT_TYPE.INTEGER}
              componentType={FIELD_COMPONENTS.INTEGER}
              label={`${label}_${RELATIVE_DATE_PICKER_COPY.STARTING.AMOUNT}`}
              labelIsHidden
              name={`${name}_${RELATIVE_DATE_PICKER_COPY.STARTING.AMOUNT}`}
              value={
                relativeDate[RelativeDatepickerTypes.STARTING_AMOUNT] as string
              }
              onChange={(e: any) =>
                handleOnChange(
                  e.target.value,
                  RelativeDatepickerTypes.STARTING_AMOUNT,
                  true,
                )
              }
              numberFormat="###"
              isDisabled={isDisabled}
            />
          </TextWrapper>
          <Select
            label={`${label}_${RELATIVE_DATE_PICKER_COPY.STARTING.LENGTH}`}
            labelIsHidden
            name={`${name}_${RELATIVE_DATE_PICKER_COPY.STARTING.LENGTH}`}
            options={LENGTH_OPTIONS}
            selectedValue={
              relativeDate[RelativeDatepickerTypes.STARTING_LENGTH]
            }
            onSelectChange={(e: any) =>
              handleOnChange(e, RelativeDatepickerTypes.STARTING_LENGTH, true)
            }
            disabled={isDisabled}
          />
          {isPastOnly ? (
            <Placeholder>
              {relativeDate[RelativeDatepickerTypes.STARTING_PERIOD].label}
            </Placeholder>
          ) : (
            <Select
              label={`${label}_${RELATIVE_DATE_PICKER_COPY.STARTING.PERIOD}`}
              labelIsHidden
              name={`${name}_${RELATIVE_DATE_PICKER_COPY.STARTING.PERIOD}`}
              options={PERIOD_OPTIONS}
              selectedValue={
                relativeDate[RelativeDatepickerTypes.STARTING_PERIOD]
              }
              onSelectChange={(e: any) =>
                handleOnChange(e, RelativeDatepickerTypes.STARTING_PERIOD, true)
              }
              disabled={isDisabled}
            />
          )}
        </SectionContent>
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeader>
          {RELATIVE_DATE_PICKER_COPY.ENDING.HEADING}{' '}
          {`(${RELATIVE_DATE_PICKER_COPY.CURRENTLY}
          ${finishingDate})`}
        </SectionHeader>
        <SectionContent>
          <TextWrapper>
            <TextInput
              label={`${label}_${RELATIVE_DATE_PICKER_COPY.ENDING.AMOUNT}`}
              labelIsHidden
              name={`${name}_${RELATIVE_DATE_PICKER_COPY.ENDING.AMOUNT}`}
              numberFormat="###"
              formatInput={INPUT_FORMAT_TYPE.INTEGER}
              value={
                relativeDate[RelativeDatepickerTypes.FINISHING_AMOUNT] as string
              }
              onChange={(e: any) =>
                handleOnChange(
                  e.target.value,
                  RelativeDatepickerTypes.FINISHING_AMOUNT,
                )
              }
              componentType={FIELD_COMPONENTS.INTEGER}
              isDisabled={isDisabled}
            />
          </TextWrapper>
          <Select
            label={`${label}_${RELATIVE_DATE_PICKER_COPY.ENDING.LENGTH}`}
            labelIsHidden
            name={`${name}_${RELATIVE_DATE_PICKER_COPY.ENDING.LENGTH}`}
            options={LENGTH_OPTIONS}
            selectedValue={
              relativeDate[RelativeDatepickerTypes.FINISHING_LENGTH]
            }
            onSelectChange={(e: any) =>
              handleOnChange(e, RelativeDatepickerTypes.FINISHING_LENGTH)
            }
            disabled={isDisabled}
          />
          <Placeholder>{RELATIVE_DATE_PICKER_COPY.ENDING.LATER}</Placeholder>
        </SectionContent>
      </SectionWrapper>
    </RelativeDatePickerWrapper>
  );
};

export default RelativeDatePicker;
