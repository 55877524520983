import pathOr from 'ramda/src/pathOr';
import assocPath from 'ramda/src/assocPath';
import isEmpty from 'ramda/src/isEmpty';

import { isJsonString } from 'utils/string';

import {
  UploadedDoc,
  UploadedDocStatus,
} from 'store/reducers/newRecordReducer';
import { Vertical } from 'globalConstants';

import { evaluateConditions } from './conditions';

import {
  ViewConfig,
  FormViewConfig,
  GroupProps,
  FieldProps,
  RefType,
  FormStateValue,
  UpdateRefsProps,
  OptionProps,
} from '../types';
import { OPTION_SETS } from '../constants';

export const constructInitialFormState = (
  views: ViewConfig[],
  preloadedFormState: any,
) => {
  if (preloadedFormState) return preloadedFormState;

  return views.reduce(
    (accum, view) => ({
      ...accum,
      [view.viewId]: [],
    }),
    {},
  );
};

export const getFieldComponentOptions = (
  field: FieldProps | GroupProps,
  formState: any,
  vertical?: Vertical,
) => {
  if (field.conditionalOptions) {
    return field.options?.filter((option: any) => {
      if (option.conditional) {
        return option.conditional.value.includes(
          pathOr(
            null,
            [...option.conditional.ref.split('.'), 'value'],
            formState,
          ),
        );
      }
      return true;
    }, []);
  }

  if (field.optionSet) {
    if (vertical && field.optionSet === 'ROLE') {
      return OPTION_SETS.ROLE[vertical] as OptionProps[];
    }
    return OPTION_SETS[field.optionSet] as OptionProps[];
  }

  return field.options;
};

export const isReadOnly = (formState: any, readOnly?: boolean | RefType) => {
  if (!readOnly) return false;

  if (typeof readOnly === 'boolean') {
    return readOnly;
  }

  const readOnlyRefState: string = pathOr(
    '',
    [...readOnly.ref.split('.'), 'value'],
    formState,
  );

  return readOnly.value.includes(readOnlyRefState);
};

const getFields = (group: GroupProps, formState: any, existingValues: any) => {
  return group.fields?.filter((field: FieldProps) => {
    if (field.conditional) {
      return evaluateConditions(field.conditional, formState, existingValues);
    }
    return field;
  });
};

export const getGroups = (
  groups: GroupProps[],
  formState: any,
  existingValues?: any,
) => {
  return groups.reduce(
    (visibleGroups: GroupProps[], currentGroup: GroupProps) => {
      const relevantFields = getFields(currentGroup, formState, existingValues);

      if (currentGroup.conditional) {
        return evaluateConditions(
          currentGroup.conditional,
          formState,
          existingValues,
        )
          ? visibleGroups.concat({
              ...currentGroup,
              fields: relevantFields,
            })
          : visibleGroups;
      }

      return visibleGroups.concat({
        ...currentGroup,
        fields: relevantFields,
      });
    },
    [],
  );
};

export const getGroupValue = (
  currentState: any,
  formState: any,
  groupId: string,
  fieldId?: string | null,
  prepopulated?: any,
  defaultValue?: any,
) => {
  if (fieldId && currentState[groupId] && currentState[groupId][fieldId]) {
    return currentState[groupId][fieldId].value;
  }
  if (!fieldId && currentState[groupId]) {
    return currentState[groupId].value;
  }

  if (prepopulated) {
    const prepopulatedValue = prepopulated.ref
      ? pathOr('', [...prepopulated.ref.split('.'), 'value'], formState)
      : prepopulated;

    if (prepopulated.property) {
      return prepopulatedValue && Array.isArray(prepopulatedValue)
        ? prepopulatedValue.map(
            (value: any) => value[prepopulated.property]?.value,
          )
        : [];
    }

    return prepopulatedValue;
  }

  return defaultValue || '';
};

export const getActiveViewConfigs = (
  view: ViewConfig,
  formState: any,
  existingValues: any,
) => {
  const { groups, ...rest } = view;
  const output: FormViewConfig = { ...rest };

  if (view.groups && view.groups.length > 0) {
    output.visibleGroups = getGroups(view.groups, formState, existingValues);
  }

  return output;
};

export const getSummaryLabel = (value: any, jsonKey?: string) => {
  return !!jsonKey && isJsonString(value) && JSON.parse(value)[jsonKey];
};

export const mergeNewFormGroupAndFieldValue = (
  id: string,
  currentState: any,
  stateFieldValue: FormStateValue,
  groupId?: string | null,
) => {
  let existingValue;
  let output;
  if (groupId) {
    existingValue = currentState[groupId] && currentState[groupId][id];
    output = {
      ...currentState,
      [groupId]: {
        ...currentState[groupId],
      },
    };

    output[groupId][id] = existingValue
      ? { ...existingValue, ...stateFieldValue }
      : stateFieldValue;
  } else {
    existingValue = currentState[id];
    output = { ...currentState };
    output[id] = existingValue
      ? { ...existingValue, ...stateFieldValue }
      : stateFieldValue;
  }

  return output;
};

export const updateStateRefsOnChange = (
  formState: any,
  references: UpdateRefsProps[],
) => {
  let output = formState;

  references.forEach((ref) => {
    const updateVal = ref.sourceKey;

    if (updateVal && updateVal.length > 0) {
      output = assocPath(ref.targetKey.split('.'), updateVal, output);
    }
  });

  return output;
};

export const resetStateRefsOnChange = (
  formState: any,
  references: string[],
) => {
  let output = formState;

  references.forEach((ref) => {
    output = assocPath(ref.split('.'), [], output);
  });

  return output;
};

export const parsePathWithIndicies = (path: string) => {
  return path.split('.').map((part) => {
    const parsedPart = parseInt(part, 10);
    return Number.isNaN(parsedPart) ? part : parsedPart;
  });
};

export const updateStateAtPath = (
  formState: any,
  path: string,
  value: FormStateValue,
) => {
  const pathWithIndices = parsePathWithIndicies(path);

  return assocPath(pathWithIndices, value, formState);
};

export const mergedStateWithDocsData = (
  formState: any,
  viewId: string,
  uploadedDoc: UploadedDoc,
) => {
  return uploadedDoc && uploadedDoc.status === UploadedDocStatus.uploaded
    ? {
        ...formState,
        [uploadedDoc.path]: {
          ...formState[uploadedDoc.path],
          uploaded: true,
        },
      }
    : {
        ...formState,
        [viewId]: [],
      };
};

export const getStateValueAtPath = (
  formState: any,
  path: string,
  defaultVal?: string | boolean | null,
): FormStateValue => {
  return pathOr(defaultVal, path.split('.'), formState) as FormStateValue;
};

export const updateSubSourceOnAllStateValues = (
  formState: any,
  subSource: string,
) => {
  function deepMergeOfSource(obj: any): any {
    if (typeof obj !== 'object') return obj;

    return (
      obj &&
      Object.entries(obj).reduce((accum, [objKey, objValue]: [string, any]) => {
        if (Array.isArray(objValue) && isEmpty(objValue)) {
          return {
            ...accum,
            [objKey]: objValue,
          };
        }
        if (
          objValue &&
          Object.prototype.hasOwnProperty.call(objValue, 'value')
        ) {
          if (Array.isArray(objValue.value)) {
            return {
              ...accum,
              [objKey]: {
                ...objValue,
                value: objValue.value.map(deepMergeOfSource),
              },
            };
          }

          return {
            ...accum,
            [objKey]: {
              ...objValue,
              subSource,
            },
          };
        }

        return {
          ...accum,
          [objKey]: deepMergeOfSource(objValue),
        };
      }, {})
    );
  }

  return deepMergeOfSource(formState);
};

export const getLineConfig = (
  fields: FieldProps[] | undefined,
  formState: any,
  vertical?: Vertical,
) => {
  return fields?.map((field) => {
    // eslint-disable-next-line no-nested-ternary
    let prepopulatedVal;

    if (field.prepopulated) {
      prepopulatedVal = field.prepopulated.ref;
    }

    if (
      field.prepopulated?.ref &&
      pathOr('', field.prepopulated.ref.split('.'), formState)
    ) {
      prepopulatedVal = field.prepopulated;
    }

    return {
      name: field.name,
      label: field.label,
      componentType: field.componentType,
      validation: field.validation,
      options:
        field.options || getFieldComponentOptions(field, formState, vertical),
      columns: field.columns,
      optionSet: field.optionSet,
      prepopulated: prepopulatedVal,
      resetFormState: field.resetFormState,
      resetRefsOnChange: field.resetRefsOnChange,
      fieldType: field.fieldType,
      childFields: field.childFields,
    };
  });
};
