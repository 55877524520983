export const setItem = (key: string, value: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    return false;
  }
};

export const getItem = (key: string) => {
  try {
    const serializedItem = sessionStorage.getItem(key);

    if (!serializedItem) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (error) {
    return undefined;
  }
};

export const removeItem = (key: string) => {
  sessionStorage.removeItem(key);

  return true;
};
