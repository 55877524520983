import React from 'react';
import { BoldEmphasized } from 'styled/Global';
import { RowCheckbox, RowLabel } from './MergeRecordsStyled';
import { RowItemProps } from './types';

const RowItem = ({
  row,
  itemKey,
  label,
  updateSelectedRadios,
  selected,
  isBlankOption,
  rowIsHidden,
}: RowItemProps) => {
  if (rowIsHidden) {
    return <></>;
  }

  // TODO: review this and the isBlankOption variable as they don't seem to be in use
  if (isBlankOption && !row.value) {
    return (
      <td key={itemKey}>
        <RowLabel htmlFor={`${itemKey}`}>
          <RowCheckbox
            type="radio"
            value=""
            name={`${label}[]`}
            id={`${itemKey}`}
            checked
            disabled
          />
          <span />
        </RowLabel>
      </td>
    );
  }
  // TODO: review this and the isBlankOption variable as they don't seem to be in use
  if (isBlankOption) {
    return (
      <td key={itemKey}>
        <RowLabel htmlFor={`${itemKey}`}>
          <RowCheckbox
            type="radio"
            value=""
            name={`${label}[]`}
            id={`${itemKey}`}
            disabled
          />
          <span>
            <BoldEmphasized>{row.value}</BoldEmphasized>
            {row.source && ` (${row.source})`}
          </span>
        </RowLabel>
      </td>
    );
  }

  if (!row.value) {
    return (
      <td key={itemKey}>
        <RowLabel htmlFor={`${itemKey}`}>
          <RowCheckbox
            onChange={updateSelectedRadios}
            type="radio"
            value="clear-field"
            name={`${label}[]`}
            id={`${itemKey}`}
          />
          <span>[Clear Field]</span>
        </RowLabel>
      </td>
    );
  }

  return (
    <td key={itemKey}>
      <RowLabel htmlFor={`${itemKey}`}>
        <RowCheckbox
          onChange={updateSelectedRadios}
          type="radio"
          value={`${row.rawValue}`}
          name={`${label}[]`}
          id={`${itemKey}`}
          checked={selected}
        />
        <span>
          <BoldEmphasized>{row.value}</BoldEmphasized>
          {row.source && ` (${row.source})`}
        </span>
      </RowLabel>
    </td>
  );
};

export default RowItem;
