export type AuditLogProps = {
  label: string;
  source: string;
  subSource: string;
  itemChanged: {
    entity: string;
    property: string;
    valid: boolean;
  };
  toValue?: string | number | Date | boolean;
  fromValue?: string | number | Date | boolean;
  date: string;
};

export enum EntityTypes {
  LEASE = 'Lease',
  BUILDING = 'Building',
  COMPANY = 'Company',
  SALES = 'Sale',
  TO_LET = 'ToLet',
  BREAK_OPTIONS = 'BreakOption',
  RENT_REVIEW = 'RentReview',
  DEBT = 'Debt',
  VALUATION = 'Valuation',
}
