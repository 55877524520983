export type Coords = {
  latitude: number;
  longitude: number;
};

export type GoogleCoords = google.maps.LatLng | google.maps.LatLngLiteral;

export type GoogleCoordLatLngFn = google.maps.LatLng;

export type GoogleCoordLatLngLiteral = google.maps.LatLngLiteral;

export type GeoJSONPoints = number[][];
export enum MARKER_TYPES {
  RETAIL = 'retail',
  INDUSTRIAL = 'industrial',
  OFFICE = 'office',
  DEFAULT = 'default',
}
export interface LocationProps {
  lat: number;
  lng: number;
  name: string;
  use: string | null;
  id: string;
  marker: MARKER_TYPES;
}

export type GoogleScriptsProps = {
  scriptError?: string | Error | Event;
  scriptLoaded: boolean;
};
export interface MapProps {
  defaultZoom: number;
  defaultCenter: google.maps.LatLng | google.maps.LatLngLiteral;
  fullscreenControl?: boolean;
  streetViewControl?: boolean;
  mapTypeControlPosition?: 'right' | 'left';
  gestureHandling?: 'greedy' | 'cooperative';
  styles?: google.maps.MapTypeStyle[];
  maxZoom?: number;
  onMapZoom?: () => void;
  clickableIcons?: boolean;
}

export interface MapState {
  isLoaded: boolean;
}

export interface GoogleMapProps extends MapProps {
  apiKey: string;
  MapLoading?: React.FC;
  scriptError?: string | Error | Event;
  scriptLoaded: boolean;
}

export type Context = {
  map: google.maps.Map | null;
  placeName: string;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
};

export type MapEvent = {
  latLng: google.maps.LatLng;
};

export type MapEventListener = google.maps.MapsEventListener;

export type MapPath =
  | google.maps.MVCArray<google.maps.LatLng>
  | google.maps.LatLng[]
  | google.maps.LatLngLiteral[];

export type MapCircle = {
  center: GoogleCoordLatLngFn;
  radius: number;
};

export type MapPolygon = {
  center: GoogleCoordLatLngLiteral;
  path: GoogleCoordLatLngFn[];
};

export type MapPolygonWithBoundary = {
  boundaryId: string;
  center: GoogleCoordLatLngLiteral;
  paths: MapPolygon[];
};

export type MapCorridor = {
  center: GoogleCoordLatLngLiteral;
  path: GoogleCoords[];
  polyline: GoogleCoords[];
  distance: number;
};

export type MVCObject = google.maps.MVCObject;

export type ClusterMarkerLocation = {
  location: LocationProps;
  marker: MVCObject;
};

export type BoundBox = {
  east: number;
  south: number;
  west: number;
  north: number;
};
