import React from 'react';
import { mapFloorPartition } from 'connected/LeasesPanel/helpers';
import { LeaseRecord } from 'pages/Details/types';
import { globalContent } from 'globalConstants';
import { IdentifyLeaseViewProps } from './types';
import FormField from '../FormField/FormField';
import {
  FieldColumns,
  MultiLineStyled,
  MultiLineWrapper,
} from '../FormMultiline/FormMultilineStyled';
import useMultiline from '../hooks/useMultiline';
import { FIELD_COMPONENTS, LineConfigProps } from '../types';

const checkIfDisabled = (line: LineConfigProps, item?: any) => {
  if (line.componentType === FIELD_COMPONENTS.PRICE_PER_SQ_FT_INPUT && item) {
    return !item.selectRecord || !item.selectRecord.value;
  }

  return false;
};

const getLabel = (line: LineConfigProps, item?: LeaseRecord) => {
  let hiddenLabel = '';
  if (item && item.isHidden && line.name === 'selectRecord') {
    hiddenLabel = ` (${globalContent.hidden})`;
  }

  if (line.componentType === FIELD_COMPONENTS.CHECKBOX && item) {
    return (
      `${item.floors && mapFloorPartition(item.floors)}${hiddenLabel}` ||
      `${line.label}${hiddenLabel}`
    );
  }

  return `${line.label}${hiddenLabel}`;
};

const getValue = (line: LineConfigProps, value?: any) => {
  return value || '';
};

const RenewLeases: React.FC<IdentifyLeaseViewProps> = ({
  handleOnChange,
  lineConfig,
  formStateItems,
  leases,
}) => {
  const { onFieldChange, rowFields } = useMultiline(
    formStateItems,
    handleOnChange,
  );

  const getLine = (item: any, i: number) => (
    <MultiLineWrapper key={item.index}>
      {lineConfig.map((line: LineConfigProps) => {
        const currentLease = leases?.find(
          (lease) => lease.leaseId === item.leaseId.value,
        );
        const itemValue = getValue(line, item[line.name]?.value);
        const itemLabel = getLabel(line, currentLease);
        const isDisabled = checkIfDisabled(line, item);
        return (
          <FieldColumns key={`item${line.name}`} columns={line.columns}>
            <FormField
              name={`${line.name}-${i}`}
              label={itemLabel}
              handleOnChange={(value) => {
                onFieldChange(
                  i,
                  value,
                  line.name,
                  itemLabel,
                  line.componentType,
                  line.resetFormState,
                  line.resetRefsOnChange,
                  undefined,
                  leases[i].id,
                );
              }}
              readOnly={isDisabled}
              groupId={leases[i].id}
              options={line.options}
              type={line.componentType}
              value={itemValue}
              lineValues={item}
              validation={line.validation}
              isGroup
              itemProps={{ ...line, groupId: '', fieldType: undefined }}
            />
          </FieldColumns>
        );
      })}
    </MultiLineWrapper>
  );

  return <MultiLineStyled>{rowFields.map(getLine)}</MultiLineStyled>;
};

export default RenewLeases;
