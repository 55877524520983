import React, { useState, useEffect } from 'react';
import { components } from 'react-select';
import Highlighter from 'react-highlight-words';
import RequiredFlag from 'components/Forms/RequiredFlag';
import { ValidationProps } from 'connected/FormFlow/types';
import { OptionGroup, Option } from 'components/Forms/types';
import FieldValidation from 'components/Forms/FieldValidation';
import {
  CreateNewStyled,
  StyledSelect,
  StyledCreatableSelect,
  StyledSelectWrapper,
  StyledLabel,
  OptionTitle,
  StyledSearchIcon,
} from './SelectSearchStyled';

import { SELECT_SEARCH_INPUT_COPY } from './constants';
import MenuList from './SelectSearchMenuList';

const formatCreateLabel = (label: string) => {
  return (
    <CreateNewStyled>
      {SELECT_SEARCH_INPUT_COPY.notFound}
      <span>{` ${SELECT_SEARCH_INPUT_COPY.add} ${label}`}</span>
    </CreateNewStyled>
  );
};

const formatOptionLabel = (
  {
    label,
    subLabel,
    regNumber,
  }: { label: string; subLabel?: string; regNumber?: string },
  { inputValue }: { inputValue: string },
) => {
  if (typeof label === 'string') {
    return (
      <>
        <OptionTitle>
          <Highlighter
            searchWords={inputValue.split(/\s/).filter((word) => word)}
            textToHighlight={label}
            autoEscape
          />
          {regNumber && (
            <span className="companyNumber">{`(${regNumber})`}</span>
          )}
        </OptionTitle>
        {subLabel && (
          <span>
            <Highlighter
              searchWords={inputValue.split(/\s/).filter((word) => word)}
              textToHighlight={subLabel}
              autoEscape
            />
          </span>
        )}
      </>
    );
  }
  return label;
};

type MultiSelectOnChangeOptions = {
  [key: string]: Option;
};

interface SelectSearchInputProps {
  id: string;
  name: string;
  label: string;
  options: Option[] | OptionGroup[];
  selectedOptions?: Option[];
  multipleSelect?: boolean;
  maxWidth?: number;
  canAddNew?: boolean;
  placeholder?: string;
  labelIsHidden?: boolean;
  labelZIndex?: number;
  isSearchable?: boolean;
  isAsync?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  validation?: ValidationProps;
  onChange: (
    option: Option,
  ) => void | ((options: MultiSelectOnChangeOptions) => void);
  onInputChange?: (value: string) => void;
  isWide?: boolean;
  loadingMessage?: string;
  noOptionsMessage?: string;
  onDelete?: () => void;
  changeLength?: number;
  hasErrors?: boolean;
}

const SelectSearch: React.FC<SelectSearchInputProps> = ({
  id,
  label,
  name,
  options,
  selectedOptions = [],
  onChange,
  onInputChange,
  onDelete,
  multipleSelect = true,
  maxWidth,
  placeholder,
  isAsync = false,
  canAddNew = false,
  isDisabled = false,
  isSearchable = true,
  isLoading = false,
  validation,
  labelIsHidden = false,
  labelZIndex,
  isWide = false,
  loadingMessage,
  noOptionsMessage,
  changeLength = 2,
  hasErrors,
}: SelectSearchInputProps) => {
  const [minimizeLabel, setMinimizeLabel] = useState(false);
  const [created, setCreated] = useState<Option | null>(null);
  const [inputValue, setInputValue] = useState<Option[] | null>(
    selectedOptions,
  );

  const isLabelMinimized =
    !!minimizeLabel ||
    !!(selectedOptions.length > 0 && selectedOptions[0].value) ||
    !!placeholder;

  const handleOnChange = (option: Option) => {
    if (!option || option.value === '') {
      setMinimizeLabel(false);

      if (!multipleSelect) {
        setInputValue(null);
      }

      if (onDelete) {
        onDelete();
      }

      onChange({ value: '', label: '' });
    } else {
      setMinimizeLabel(true);
      const updatedOption = { ...option };

      if (typeof option.label !== 'string') {
        updatedOption.label = option.value;
      }

      if (!multipleSelect) {
        setInputValue([
          { value: updatedOption.value, label: updatedOption.label },
        ]);
      }

      onChange(updatedOption);
    }
  };

  const createOption = (createLabel: string) => ({
    label: createLabel,
    value: createLabel,
  });

  const handleCreateOption = (value: any) => {
    const newOption = createOption(value);
    setCreated(newOption);
    handleOnChange(newOption);
  };

  const handleInputChange = (value: string) => {
    if (onInputChange && value.length >= changeLength) onInputChange(value);
    if (value) return setMinimizeLabel(true);
    return setMinimizeLabel(false);
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <StyledSearchIcon />
      </components.DropdownIndicator>
    );
  };

  const selectStyles = {
    singleValue: (
      base: { color: string },
      state: { selectProps: { menuIsOpen: boolean } },
    ) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? 'transparent' : base.color,
    }),
    menu: (styles: any) => ({ ...styles, zIndex: 999 }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: 'fit-content',
    }),
  };

  const componentProps = {
    id,
    name,
    label,
    options: created ? [...options, created] : options,
    maxWidth,
    placeholder,
    isSearchable,
    isClearable: inputValue && inputValue.length > 0,
    isLoading,
    isMulti: multipleSelect,
    className: 'react-select-container',
    classNamePrefix: 'react-select',
    formatOptionLabel,
    formatCreateLabel,
    isDisabled,
    isWide,
    noOptionsMessage: () =>
      noOptionsMessage || SELECT_SEARCH_INPUT_COPY.noOptions,
    loadingMessage: () => loadingMessage || SELECT_SEARCH_INPUT_COPY.loading,
    value: inputValue,
    onChange: handleOnChange,
    onInputChange: handleInputChange,
    styles: selectStyles,
    defaultValue: options[0],
  };

  useEffect(() => {
    setCreated(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    const updateValue = () => {
      if (selectedOptions !== inputValue) {
        setInputValue(selectedOptions);
      }
      if (selectedOptions.length === 0 || !selectedOptions[0].value) {
        setInputValue(null);
      }
    };
    updateValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <div>
      <StyledSelectWrapper
        isDisabled={isDisabled}
        isWide={isWide}
        isMulti={multipleSelect}
        hasErrors={hasErrors || false}
      >
        {canAddNew ? (
          <StyledCreatableSelect
            onCreateOption={handleCreateOption}
            filterOption={() => true}
            allowCreateWhileLoading={isAsync}
            {...componentProps}
            components={
              isAsync ? { DropdownIndicator, MenuList } : { DropdownIndicator }
            }
          />
        ) : (
          <StyledSelect
            components={{ DropdownIndicator }}
            {...componentProps}
          />
        )}
        <StyledLabel
          labelIsMinimized={isLabelMinimized}
          htmlFor={name}
          labelIsHidden={labelIsHidden}
          labelZIndex={labelZIndex}
          isWide={isWide}
          isMulti={multipleSelect}
        >
          {label}
          {validation?.isRequired && <RequiredFlag />}
        </StyledLabel>
      </StyledSelectWrapper>
      {validation && (
        <FieldValidation validation={validation} hasErrors={hasErrors} />
      )}
    </div>
  );
};

export default SelectSearch;
