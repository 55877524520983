import {
  GoogleCoordLatLngFn,
  GeoJSONPoints,
  GoogleCoordLatLngLiteral,
} from '../types';
import { CORRIDOR_ZOOM_MAP } from '../constants';

declare global {
  const jsts: any;
}

type GeoInput = {
  type: string;
  coordinates: GeoJSONPoints;
};

const CORRIDOR_BUFFER_DIVISOR = 111.12;
const DEFAULT_LOCUS = 0.2 / CORRIDOR_BUFFER_DIVISOR;

export const convertToGeoJSON = (points: GoogleCoordLatLngFn[]) => {
  return points.map((point) => [point.lat(), point.lng()]);
};

export const convertToGeoJSONFromLiteral = (
  points: GoogleCoordLatLngLiteral[],
) => {
  return points.map((point) => [point.lat, point.lng]);
};

export const convertToGoogleLatLng = (path: GeoJSONPoints) => {
  return path.map(([lat, lng]) => ({
    lat,
    lng,
  }));
};

export const convertToGoogleLatLngLiteral = (path: GeoJSONPoints) => {
  return path.map(([latitude, longitude]) => ({
    latitude,
    longitude,
  }));
};

const getLocus = (mapZoom: number) => {
  const distance = CORRIDOR_ZOOM_MAP.get(mapZoom);

  return distance ? distance / CORRIDOR_BUFFER_DIVISOR : DEFAULT_LOCUS;
};

const convertToBuffer = (locus: number, geoInput: GeoInput) => {
  const geoReader = new jsts.io.GeoJSONReader();
  const geoWriter = new jsts.io.GeoJSONWriter();
  const geometry = geoReader.read(geoInput);
  const geometryBuffer = geometry.buffer(locus, 8, 3);
  const geoJsonPolygon = geoWriter.write(geometryBuffer);

  return convertToGoogleLatLng(geoJsonPolygon.coordinates[0]);
};

export const getCorridorBufferByZoom = (
  path: GeoJSONPoints,
  mapZoom: number,
) => {
  const geoInput = {
    type: 'LineString',
    coordinates: path,
  };
  const locus = getLocus(mapZoom);

  return convertToBuffer(locus, geoInput);
};

export const getCorridorBufferByDistance = (
  path: GeoJSONPoints,
  distance: number,
) => {
  const locus = distance / CORRIDOR_BUFFER_DIVISOR;
  const geoInput = {
    type: 'LineString',
    coordinates: path,
  };

  return convertToBuffer(locus, geoInput);
};
