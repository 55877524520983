import { GlobalApiFailError } from 'store/actions/types';

import {
  CompanySelectActionTypes,
  CompanySelectQueryAction,
  CompanySelectApiSuccessAction,
  CompanySelectApiFailAction,
  CompanySelectResetAction,
  CompanySelectResult,
} from 'connected/CompanySelect/types';

export const companySelectQuery = (
  searchTerm: string,
  includeIsPrivate = false,
  excludeCurrentCompany?: string,
  page = 0,
): CompanySelectQueryAction => ({
  type: CompanySelectActionTypes.COMPANY_SELECT_QUERY,
  payload: { searchTerm, page },
  includeIsPrivate,
  excludeCurrentCompany,
});

export const companySelectApiSuccess = (
  payload: CompanySelectResult,
): CompanySelectApiSuccessAction => ({
  type: CompanySelectActionTypes.COMPANY_SELECT_API_SUCCESS,
  payload,
});

export const companySelectApiFail = (
  payload: GlobalApiFailError,
): CompanySelectApiFailAction => ({
  type: CompanySelectActionTypes.COMPANY_SELECT_API_FAIL,
  payload,
});

export const companySelectReset = (): CompanySelectResetAction => ({
  type: CompanySelectActionTypes.COMPANY_SELECT_RESET,
});
