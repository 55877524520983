import { FORM_FLOWS } from 'connected/FormFlow/constants';
import { FormFlowActionTypes } from 'connected/FormFlow/types';
import { QuickSearchActionTypes } from 'connected/QuickSearch/types';
import { Vertical } from 'globalConstants';
import { LoginActionTypes } from 'pages/Login/types';
import { NewLeaseActionTypes } from 'pages/NewLease/types';
import { NewSaleActionTypes } from 'pages/NewSale/types';
import { ExportSearchResults } from 'store/actions/exportSearchResultsActions';
import { SearchActionTypes } from 'store/actions/searchActions';
import { FILTER_ACTION_TYPES } from 'store/actions/searchQuery';
import { AuthActionTypes } from 'store/actions/types';
import { RootState } from 'store/rootReducer';

import { UserTrackingAction, TrackingEvent, AthenaAction } from './types';

const trackingAction = (
  event: TrackingEvent,
  meta?: Record<string, unknown>,
): UserTrackingAction => ({
  type: 'USER_TRACKING',
  payload: {
    event,
    meta,
  },
});

const getRowsExported = (total?: number) => {
  if (total && total >= 250) {
    return 250;
  }

  if (total) {
    return total;
  }
  return 0;
};

const getModal = (modal: string, recordId: string) => {
  if (modal === FORM_FLOWS.SALE) {
    return trackingAction(TrackingEvent.EnterNewSaleFlow, {
      saleId: recordId,
    });
  }
  if (modal === FORM_FLOWS.LEASE) {
    return trackingAction(TrackingEvent.EnterNewLeaseFlow, {
      leaseId: recordId,
    });
  }
  return undefined;
};

const getSuccessVerticalTrackingEvent = (
  hasResults: boolean,
  vertical?: Vertical,
  sortKey?: string,
) => {
  const verticalTrackingEvents = {
    [Vertical.Building]: hasResults
      ? TrackingEvent.ArriveAtBuildingWithResults
      : TrackingEvent.ArriveAtBuildingNoResults,
    [Vertical.ToLet]: hasResults
      ? TrackingEvent.ArriveAtToLetWithResults
      : TrackingEvent.ArriveAtBuildingNoResults,
    [Vertical.Lease]: hasResults
      ? TrackingEvent.ArriveAtLeasingWithResults
      : TrackingEvent.ArriveAtLeasingNoResults,
    [Vertical.Sale]: hasResults
      ? TrackingEvent.ArriveAtSalesWithResults
      : TrackingEvent.ArriveAtSalesNoResults,
    [Vertical.PortfolioSale]: hasResults
      ? TrackingEvent.ArriveAtPortfolioSalesWithResults
      : TrackingEvent.ArriveAtPortfolioSalesNoResults,
    [Vertical.Company]: hasResults
      ? TrackingEvent.ArriveAtCompanyWithResults
      : TrackingEvent.ArriveAtCompanyNoResults,
  };

  if (sortKey === 'sales.portfolio_name' && vertical === Vertical.Sale) {
    return trackingAction(verticalTrackingEvents[Vertical.PortfolioSale]);
  }

  if (vertical) {
    return trackingAction(verticalTrackingEvents[vertical]);
  }

  return trackingAction(verticalTrackingEvents[Vertical.Sale]);
};

export const createTrackingAction = (
  action: AthenaAction,
  state: RootState,
): UserTrackingAction | undefined => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      return trackingAction(TrackingEvent.LogIn);
    case LoginActionTypes.LOGOUT:
      return trackingAction(TrackingEvent.LogOut);
    case FormFlowActionTypes.INITIALIZE_FORM_BUILDER: {
      return getModal(state.modal.currentModal, state.newRecord.recordId);
    }
    case NewSaleActionTypes.NEW_SALE_API_SUCCESS:
      return trackingAction(TrackingEvent.CompleteNewSaleFlow, {
        saleId: state.newRecord.recordId,
      });
    case NewLeaseActionTypes.NEW_LEASE_API_SUCCESS: {
      return trackingAction(TrackingEvent.CompleteNewLeaseFlow, {
        leaseId: state.newRecord.recordId,
      });
    }

    case FILTER_ACTION_TYPES.SET_VERTICAL: {
      const verticalTrackingEvents = {
        [Vertical.PortfolioSale]: TrackingEvent.StartPortfolioSaleSearch,
        [Vertical.Lease]: TrackingEvent.StartLeaseSearch,
        [Vertical.ToLet]: TrackingEvent.StartToLetSearch,
        [Vertical.Sale]: TrackingEvent.StartSaleSearch,
        [Vertical.Building]: TrackingEvent.StartBuildingsSearch,
        [Vertical.Company]: TrackingEvent.StartCompanySearch,
      };

      return trackingAction(
        verticalTrackingEvents[action.payload as Vertical] as TrackingEvent,
      );
    }

    case SearchActionTypes.FETCH_SEARCH_RESULTS_API_SUCCESS: {
      const payload = action.payload as {
        results: string[];
        meta: { sortKey: string };
      };
      const hasResults = payload.results.length > 0;

      return getSuccessVerticalTrackingEvent(
        hasResults,
        state.searchQuery.vertical,
        payload.meta.sortKey,
      );
    }

    case ExportSearchResults.Start: {
      const rowsExported = getRowsExported(
        state.searchResults.pagination.total,
      );
      const exportVerticalTrackingEvents = {
        [Vertical.Building]: TrackingEvent.TriggerExportBuildingResults,
        [Vertical.ToLet]: TrackingEvent.TriggerExportToLetResults,
        [Vertical.Lease]: TrackingEvent.TriggerExportLeaseResults,
        [Vertical.PortfolioSale]:
          TrackingEvent.TriggerExportPortfolioSaleResults,
        [Vertical.Sale]: TrackingEvent.TriggerExportSaleResults,
        [Vertical.Company]: TrackingEvent.TriggerExportSaleResults,
      };

      if (state.searchQuery.sortKey === 'sales.portfolioName') {
        return trackingAction(
          exportVerticalTrackingEvents[Vertical.PortfolioSale],
          {
            rowsExported,
          },
        );
      }

      return trackingAction(
        exportVerticalTrackingEvents[state.searchQuery.vertical as Vertical],
        {
          rowsExported,
        },
      );
    }

    case QuickSearchActionTypes.QUICK_SEARCH_ITEM_CLICKED: {
      const payload = action.payload as {
        vertical: string;
        entityId: string;
      };
      if (payload?.vertical === Vertical.Building) {
        return trackingAction(TrackingEvent.ClickOnBuildingQuickSearchResult, {
          entityId: payload.entityId,
        });
      }

      if (payload?.vertical === Vertical.Company) {
        return trackingAction(TrackingEvent.ClickOnCompanyQuickSearchResult, {
          entityId: payload.entityId,
        });
      }
      return undefined;
    }

    default:
      return undefined;
  }
};
