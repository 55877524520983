import styled from 'styled-components/macro';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

export const MultiLineStyled = styled.fieldset<{ lineLimit?: number }>`
  padding: ${(props) =>
      props.lineLimit && props.lineLimit !== 1
        ? props.theme.spacing.default
        : 0}
    0 0;
`;

export const DemiseWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing.default};
`;

export const MultiLineWrapper = styled.div<{ columns?: number }>`
  position: relative;
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(3, 1fr);'};
  grid-column-gap: ${(props) => props.theme.spacing.tablet};

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    grid-column-gap: ${(props) => props.theme.spacing.small};
  }
  margin: 0 0 ${(props) => props.theme.spacing.small};
  padding: 0 0 ${(props) => props.theme.spacing.default};
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;

export const FloorPartitionMultiLineWrapper = styled(MultiLineWrapper)`
  margin: 0 ${(props) => props.theme.spacing.default}
    ${(props) => props.theme.spacing.small};
`;

export const StyledButton = styled.button`
  text-align: left;
  width: auto;
  padding: 10px;
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  display: flex;
  align-items: center;
`;

export const StyledDeleteButton = styled(StyledButton)`
  align-items: center;
  height: 70px;
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  min-width: 168px;
`;

export const StyledAddButton = styled.button`
  text-align: left;
  outline: none;
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: bold;
  margin: ${(props) => props.theme.spacing.small} 0 0 auto;
  border: 1px dotted ${(props) => props.theme.colorPalette.secondary.lightGrey};
  border-radius: 4px;
  width: 100%;
  display: block;
  padding: 10px;

  &:disabled {
    cursor: default;

    svg {
      fill: ${(props) => props.theme.colorPalette.secondary.midGrey};
    }
  }
`;

export const StyledAddLineButton = styled.button<{ isChild?: boolean }>`
  text-align: left;
  outline: none;
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: bold;
  margin: ${(props) =>
    props.isChild
      ? `${props.theme.spacing.small} 0 30px ${props.theme.spacing.small}`
      : `${props.theme.spacing.small} 0 30px auto`};
  width: 100%;
  padding: 0;
  display: block;
  &:disabled {
    cursor: default;

    svg {
      fill: ${(props) => props.theme.colorPalette.secondary.midGrey};
    }
  }
`;

export const StyledAddPartitionButton = styled(StyledAddLineButton)`
  margin: 0;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  background-color: ${(props) => props.theme.colorPalette.branding.ink};
  border-radius: 30px;
  padding: 2px;
  height: 20px;
  width: 20px;
  margin: 0 10px 0 0;
  fill: ${(props) => props.theme.colorPalette.branding.white};
`;

export const StyledAddIcon = styled(AddIcon)`
  background-color: ${(props) => props.theme.colorPalette.branding.white};
  padding: 0;
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  width: 14px;
  height: 14px;
  margin: 0 5px 0 0;
  position: relative;
  top: 2px;
`;

export const MultiLineHeader = styled.p<{ columns?: number }>`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  grid-column: ${(props) =>
    props.columns ? `span ${props.columns}` : 'span 3'};
  font-weight: bold;
  margin: 3px 0;
  width: 100%;
`;

export const MultiLineGroupColumns = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${(props) => props.theme.spacing.tablet};

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    grid-column-gap: ${(props) => props.theme.spacing.small};
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const DeletePartitionContainer = styled.div`
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledButtonDeleteWrapper = styled(StyledButtonWrapper)`
  position: absolute;
  right: -40px;
  top: 25px;
`;

export const MultiLineGroupWrapper = styled.div`
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
  margin: 0 0 ${(props) => props.theme.spacing.small};
  padding: 0 0 ${(props) => props.theme.spacing.default};
`;

export const MultiLineGroupHeader = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  width: 100%;
  font-weight: bold;
  margin: 3px 0;
`;

export const FieldColumns = styled.div<{
  columns?: number;
  hasChildren?: boolean;
}>`
  ${(props) =>
    props.columns &&
    `
    grid-column: span ${props.columns};
    display: grid;
    grid-template-columns: repeat(${props.columns}, 1fr);
    grid-column-gap: 15px;
  `}

  ${(props) =>
    props.hasChildren &&
    props.columns &&
    `
    margin-right: -15px;
    grid-template-columns: repeat(${props.columns}, 1fr) 0;
  `}
`;

export const PartitionFieldColumns = styled(FieldColumns)`
  position: absolute;
`;

export const StyledSelectWrapper = styled.span`
  margin: 0 5px 0 0;
`;

export const RowWrapper = styled.div`
  grid-column: span 5;
`;
