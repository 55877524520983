import { of } from 'rxjs';
import { map, catchError, switchMap, filter } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { Action } from 'redux';
import { AuthenticatedRequestObservable } from 'apis/request';
import { DocumentPanelActionTypes } from 'connected/DocumentPanel/types';
import { DocumentUploaderActionTypes } from 'connected/DocumentUploader/types';
import { DetailsActionTypes, DetailEpicActions } from 'pages/Details/types';
import { EditRecordActionTypes } from 'store/actions/editRecordActions';
import { NewSaleActionTypes } from 'pages/NewSale/types';
import { NewLeaseActionTypes } from 'pages/NewLease/types';
import formatDataPerVertical from 'services/verticalDetails';
import { endpoints } from 'globalConstants';

import {
  detailsApiSuccess,
  detailsApiFail,
  mapPinDetailsApiSuccess,
  mapPinDetailsApiFail,
} from 'store/actions/detailsActions';
import { getEntityIdAndVerticalSelector } from 'store/selectors/detailsSelectors';
import { NotesActionTypes } from 'connected/NotesPanel/types';
import {
  PollingSuccessMetaType,
  PollingUpdateActionTypes,
} from 'store/actions/pollingUpdateActions';
import { VerticalKeys } from 'services/types';
import { formatMapPinCardData } from 'services/helpers';
import { DebtsActionTypes } from 'connected/DebtPanel/types';
import { ValuationsActionTypes } from 'connected/ValuationPanel/types';

type DetailsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const detailsEpic: Epic = (
  action$,
  state$,
  { authRequest }: DetailsEpicDependencies,
) => {
  return action$.pipe(
    ofType<Action, Action, DetailEpicActions>(
      DetailsActionTypes.DETAILS_FETCH,
      DetailsActionTypes.MAP_PIN_DETAILS_FETCH,
      DetailsActionTypes.DETAILS_REFRESH,
      DocumentPanelActionTypes.DELETE_DOCUMENT_API_SUCCESS,
      DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_SUCCESS,
      NewSaleActionTypes.NEW_SALE_API_SUCCESS,
      NewLeaseActionTypes.NEW_LEASE_API_SUCCESS,
      PollingUpdateActionTypes.POLLING_UPDATE_SUCCESS,
      EditRecordActionTypes.EDIT_RECORD_API_SUCCESS,
      NotesActionTypes.DELETE_NOTE_API_SUCCESS,
      DetailsActionTypes.DELETE_BUILDING_API_SUCCESS,
      DetailsActionTypes.DELETE_FLOOR_API_SUCCESS,
      DetailsActionTypes.UPDATE_DETAILS_API_SUCCESS,
      EditRecordActionTypes.EDIT_RECORD_API_SUCCESS,
      DetailsActionTypes.MOVE_ENTITY_SUCCESS,
      DetailsActionTypes.RENEW_ENTITY_SUCCESS,
      DetailsActionTypes.SPLIT_ENTITY_SUCCESS,
      DetailsActionTypes.HIDE_ENTITY_SUCCESS,
      DetailsActionTypes.DELETE_ENTITY_SUCCESS,
      DebtsActionTypes.DELETE_DEBT_API_SUCCESS,
      DetailsActionTypes.ADD_WATCH_RECORD_SUCCESS,
      DetailsActionTypes.DELETE_WATCH_RECORD_SUCCESS,
      DetailsActionTypes.MERGE_RECORDS_SUCCESS,
      ValuationsActionTypes.DELETE_VALUATION_API_SUCCESS,
    ),
    filter((action: any) => {
      if (
        action.type === DocumentPanelActionTypes.DELETE_DOCUMENT_API_SUCCESS
      ) {
        return !action.payload;
      }

      if (
        action.type ===
        DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_SUCCESS
      ) {
        return action.payload;
      }

      if (action.type === PollingUpdateActionTypes.POLLING_UPDATE_SUCCESS) {
        return action.onSuccess === PollingSuccessMetaType.Details;
      }

      return true;
    }),
    switchMap((action) => {
      let resourceID = action.payload?.id;
      let vertical = action.payload?.vertical;

      if (!vertical && !resourceID) {
        const resource = getEntityIdAndVerticalSelector(state$.value);
        vertical = resource.vertical;
        resourceID = resource.resourceID;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const url = endpoints.vertical.byId(vertical!, resourceID);

      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }
          if (results.response.isDeleted) {
            const deletedPayload = {
              id: results.response.id,
              [vertical]: {
                isDeleted: true,
                deletedReason: results.response.deletedReason ?? '',
              },
              documents: [],
              vertical,
            };
            return detailsApiSuccess(deletedPayload);
          }

          const detailPayload = formatDataPerVertical(
            vertical as VerticalKeys,
            results.response,
          );

          if (action.type === DetailsActionTypes.MAP_PIN_DETAILS_FETCH) {
            return mapPinDetailsApiSuccess(
              formatMapPinCardData(vertical, detailPayload[vertical]),
            );
          }

          return detailsApiSuccess(detailPayload);
        }),
        catchError((error) => {
          if (action.type === DetailsActionTypes.MAP_PIN_DETAILS_FETCH) {
            return of(mapPinDetailsApiFail(error));
          }

          return of(detailsApiFail(error));
        }),
      );
    }),
  );
};

export default detailsEpic;
