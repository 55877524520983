import { getTimeOptions, getMonthOptions } from 'utils/date';
import countries from '../../components/Forms/Inputs/CountrySelect/countries.json';
import { Option } from '../../components/Forms/types';

export const FORM_BUILDER_COPY = {
  errors: {
    title: 'Encountered an error',
    description:
      'Something went wrong when adding the new sale. Please check the following values:',
    emptyFieldMessage: 'Please enter a value',
    leaseEndDate: 'Please enter a valid lease end year and length',
    errorPartition: 'Please enter partition details',
  },
  navigation: {
    back: 'Back',
    next: 'Next',
    finish: 'Finish now',
    additional: 'Add extra information',
    submit: 'Save Record',
  },
  matches: {
    notAvailable: '-',
  },
  multiline: {
    buttons: {
      add: 'Add new line',
      delete: 'Remove',
      addLineAbove: 'Add floor above',
      addLineBelow: 'Add floor below',
      addLineAboveRetailIndustrial: 'Add floor/unit above',
      addLineBelowRetailIndustrial: 'Add floor/unit below',
      addGroundFloorLineAbove: 'Add UPPER GROUND',
      addGroundFloorLineBelow: 'Add LOWER GROUND',
      addPartition: 'Add partition',
      deleteFloor: 'Remove floor',
      deleteFloorRetailIndustrial: 'Remove floor/unit',
      deletePartition: 'Remove partition',
    },
    batch: {
      label: 'Select an option',
      labelRetailIndustrial: ['Add', 'floor/units to the', 'of this building'],
    },
  },
  portfolioIdentifyBuildings: {
    searchTitle: 'Search for buildings you would like to add to this portfolio',
    selectedTitle: 'Buildings added to your portfolio',
    confirmRemove: 'Confirm remove all',
    removeAll: 'Remove all',
  },
  buyerSeller: {
    companyExists: 'Duplicate buyer/seller company exists',
    buyer: 'buyer',
    seller: 'seller',
    equity: 'Equity Stake fields should total no more than 100',
    title: {
      buyer: 'buyer',
      seller: 'seller',
    },
    buttons: {
      add: 'Additional',
      addBuyer: 'Additional buyer',
      addSeller: 'Additional seller',
      remove: 'Remove',
      removeBuyer: 'Remove buyer',
      removeSeller: 'Remove seller',
    },
  },
  dataSources: {
    allSourcesLabel: 'Set all sources to:',
    singleSourceLabel: 'Set source',
    defaultSelectOptionLabel: 'Please select a source',
  },
  statusUnknown: 'Outcome Unknown',
  defaultSector: 'real-estate',
  defaultNationality: 'GB',
  groundTitle: 'Ground Level(s)',
};

export const OPTION_SETS = {
  FILTER_RADIO_OPTIONS: [
    {
      label: 'is one of ',
      value: 'in',
    },
    {
      label: 'is not one of',
      value: 'not in',
    },
  ],
  FILTER_RADIO_EXISTS_OPTIONS: [
    {
      label: 'exists for',
      value: true,
    },
    {
      label: 'does not exist for',
      value: false,
    },
  ],
  FILTER_RADIO_OPTIONS_SINGULAR: [
    {
      label: 'is',
      value: '=',
    },
    {
      label: 'is not',
      value: '!=',
    },
  ],
  FILTER_RADIO_OPTIONS_DESCENDANT_COMPANIES: [
    {
      label: 'only',
      value: 'in',
    },
    {
      label: 'or any of its subsidiaries',
      value: 'include_children',
    },
  ],
  MEASUREMENT: [
    {
      label: 'NIA',
      value: 'NIA',
    },
    {
      label: 'GIA',
      value: 'GIA',
    },
    {
      label: 'GEA',
      value: 'GEA',
    },
    {
      label: 'IPMS',
      value: 'IPMS',
    },
  ],
  USE: [
    {
      value: 'ancillary',
      label: 'Ancillary',
    },
    {
      value: 'bma',
      label: 'BMA',
    },
    {
      value: 'industrial',
      label: 'Industrial',
    },
    {
      value: 'lost-space',
      label: 'Lost Space',
    },
    {
      value: 'office',
      label: 'Office',
    },
    {
      value: 'reception',
      label: 'Reception',
    },
    {
      value: 'retail',
      label: 'Retail',
    },
    {
      value: 'retail-ancillary',
      label: 'Retail Ancillary',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ],
  SECTOR: [
    {
      label: 'Administrative & Support Services',
      value: 'administrative-support-services',
    },
    {
      label: 'Agriculture, Forestry & Fishing',
      value: 'agriculture-forestry-fishing',
    },
    {
      label: 'Architecture, Engineering & Related Technical Consultancy',
      value: 'architecture-engineering-related-technical-consultancy',
    },
    {
      label: 'Charity & Non-Profit',
      value: 'charity-non-profit',
    },
    {
      label: 'Construction',
      value: 'construction',
    },
    {
      label: 'Creative',
      value: 'creative',
    },
    {
      label: 'Education',
      value: 'education',
    },
    {
      label: 'Finance',
      value: 'finance',
    },
    {
      label: 'Government',
      value: 'government',
    },
    {
      label: 'Health & Social Care',
      value: 'health-social-care',
    },
    {
      label: 'Individual',
      value: 'individual',
    },
    {
      label: 'Insurance',
      value: 'insurance',
    },
    {
      label: 'Legal',
      value: 'legal',
    },
    {
      label: 'Manufacturing',
      value: 'manufacturing',
    },
    {
      label: 'Materials',
      value: 'materials',
    },
    {
      label: 'Media',
      value: 'media',
    },
    {
      label: 'Oil & Gas',
      value: 'oil-gas',
    },
    {
      label: 'Other',
      value: 'other',
    },
    {
      label: 'Real Estate - Advisory',
      value: 'real-estate',
    },
    {
      label: 'Real Estate - Charity',
      value: 'real-estate - charity',
    },
    {
      label: 'Real Estate - Council',
      value: 'real-estate - council',
    },
    {
      label: 'Real Estate - Developer',
      value: 'real-estate - developer',
    },
    {
      label: 'Real Estate - Institution',
      value: 'real-estate - institution',
    },
    {
      label: 'Real Estate - Other',
      value: 'real-estate - other',
    },
    {
      label: 'Real Estate - Private Equity',
      value: 'real-estate - private-equity',
    },
    {
      label: 'Real Estate - Property Company',
      value: 'real-estate - property-company',
    },
    {
      label: 'Real Estate - REIT',
      value: 'real-estate - reit',
    },
    {
      label: 'Recreation & Leisure',
      value: 'recreation-leisure',
    },
    {
      label: 'Recruitment',
      value: 'recruitment',
    },
    {
      label: 'Religious Institution',
      value: 'religious-institution',
    },
    {
      label: 'Retail',
      value: 'retail',
    },
    {
      label: 'Scientific & Technical',
      value: 'scientific-technical',
    },
    {
      label: 'Security Services',
      value: 'security-services',
    },
    {
      label: 'Serviced Offices',
      value: 'serviced-offices',
    },
    {
      label: 'Technology',
      value: 'technology',
    },
    {
      label: 'Telecoms',
      value: 'telecoms',
    },
    {
      label: 'Transportation & Storage',
      value: 'transportation-storage',
    },
    {
      label: 'Utilities',
      value: 'utilities',
    },
  ],
  DATASOURCE: [
    {
      label: 'Please Select',
      value: '-',
    },
    {
      label: 'Not Specified',
      value: 'not-specified',
    },
    {
      label: 'Investment Brochure',
      value: 'investment-brochure',
    },
    {
      label: 'Investment Summary',
      value: 'investment-summary',
    },
    {
      label: 'Marketing Particulars',
      value: 'marketing-particulars',
    },
    {
      label: 'SQUID Data',
      value: 'squid-data',
    },
    {
      label: 'Heads of Term',
      value: 'heads-of-term',
    },
    {
      label: 'Word of Mouth',
      value: 'word-of-mouth',
    },
    {
      label: 'Lease Contract',
      value: 'lease-contract',
    },
    {
      label: 'Savills Research',
      value: 'savills-research',
    },
    {
      label: 'Company Accounts',
      value: 'company-accounts',
    },
    {
      label: 'Press Release / Industry News',
      value: 'press-release-industry-news',
    },
    {
      label: "Comp Call - Landlord's Agent",
      value: 'comp-call-landlords-agent',
    },
    {
      label: "Comp Call - Tenant's Agent",
      value: 'comp-call-tenants-agent',
    },
    {
      label: "Comp Call - Seller's Agent",
      value: 'comp-call-sellers-agent',
    },
    {
      label: "Comp Call - Buyer's Agent",
      value: 'comp-call-buyers-agent',
    },
    {
      label: 'Comp Call - Landlord',
      value: 'comp-call-landlord',
    },
    {
      label: 'Comp Call - Tenant',
      value: 'comp-call-tenant',
    },
    {
      label: 'Comp Call - Buyer',
      value: 'comp-call-buyer',
    },
    {
      label: 'Comp Call - Seller',
      value: 'comp-call-seller',
    },
    {
      label: 'Rent Review Memo',
      value: 'rent-review-memo',
    },
    {
      label: 'Floor Plan & Measurements',
      value: 'floor-plan-measurements',
    },
    {
      label: 'Valuation Report',
      value: 'valuation-report',
    },
    {
      label: 'Planning Application/Report',
      value: 'planning-application-report',
    },
    {
      label: 'EPC Report',
      value: 'epc-report',
    },
    {
      label: 'Tenancy Schedule',
      value: 'tenancy-schedule',
    },
    {
      label: 'Title Document',
      value: 'title-document',
    },
    {
      label: 'Building Website',
      value: 'building-website',
    },
    {
      label: 'Scottish Assessors Association',
      value: 'scottish-assessors-association',
    },
    {
      label: 'Valuation Office Agency',
      value: 'valuation-office-agency',
    },
  ],
  ROLE: {
    sale: [
      {
        label: "Buyer's Agent",
        value: 'Acquiring Agent',
      },
      {
        label: "Seller's Agent",
        value: 'Disposing Agent',
      },
      {
        label: "Buyer's Solicitor",
        value: "Buyer's Solicitor",
      },
      {
        label: "Seller's Solicitor",
        value: "Seller's Solicitor",
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    portfolioSale: [
      {
        label: "Buyer's Agent",
        value: 'Acquiring Agent',
      },
      {
        label: "Seller's Agent",
        value: 'Disposing Agent',
      },
      {
        label: "Buyer's Solicitor",
        value: "Buyer's Solicitor",
      },
      {
        label: "Seller's Solicitor",
        value: "Seller's Solicitor",
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    lease: [
      {
        label: "Tenant's Agent",
        value: 'Tenant Agent',
      },
      {
        label: "Tenant's Lease Consultant",
        value: "Tenant's Lease Consultant",
      },
      {
        label: "Tenant's Solicitor",
        value: "Tenant's Solicitor",
      },
      {
        label: "Landlord's Agent",
        value: 'Landlord Agent',
      },
      {
        label: "Landlord's Lease Consultant",
        value: "Landlord's Lease Consultant",
      },
      {
        label: "Landlord's Solicitor",
        value: "Landlord's Solicitor",
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    toLet: [
      {
        label: "Landlord's Agent",
        value: 'Landlord Agent',
      },
      {
        label: "Landlord's Solicitor",
        value: "Landlord's Solicitor",
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    building: [
      {
        label: 'Asset Manager',
        value: 'Asset Manager',
      },
      {
        label: 'Debt Provider',
        value: 'Debt Provider',
      },
      {
        label: 'Fund Manager',
        value: 'Fund Manager',
      },
      {
        label: 'Property Manager',
        value: 'Property Manager',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Dilapidations Manager',
        value: 'Dilapidations Manager',
      },
      {
        label: 'Building and Property Consultant',
        value: 'Building and Property Consultant',
      },
      {
        label: 'Property Valuer',
        value: 'Property Valuer',
      },
      {
        label: 'Landlord',
        value: 'Landlord',
      },
    ],
    company: [
      {
        label: 'Occupier Services Provider',
        value: 'Occupier Services Provider',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Property Valuer',
        value: 'Property Valuer',
      },
    ],
  },
  BUILDING_GRADE: [
    {
      label: 'Design & Build',
      value: 'design-and-build',
      id: 'design-and-build',
    },
    {
      label: 'Local Authority Allocation Site',
      value: 'local-authority-allocation-site',
      id: 'local-authority-allocation-site',
    },
    {
      label: 'New - New Build (existing)',
      value: 'new-build-existing',
      id: 'new-build-existing',
    },
    {
      label: 'New - New Build (pre-construction)',
      value: 'new-build-pre-construction',
      id: 'new-build-pre-construction',
    },
    {
      label: 'New - New Build (under-construction)',
      value: 'new-build-under-construction',
      id: 'new-build-under-construction',
    },
    {
      label: 'New - Refurb (existing)',
      value: 'refurb-existing',
      id: 'refurb-existing',
    },
    {
      label: 'New - Refurb (pre-construction)',
      value: 'refurb-pre-construction',
      id: 'refurb-pre-construction',
    },
    {
      label: 'New - Refurb (under-construction)',
      value: 'refurb-under-construction',
      id: 'refurb-under-construction',
    },
    {
      label: 'Not Applicable',
      value: 'not-applicable',
      id: 'not-applicable',
    },
    {
      label: 'Second-hand (Retail)',
      value: 'second-hand-retail',
      id: 'second-hand-retail',
    },
    {
      label: 'Second-hand (Undisclosed)',
      value: 'second-hand-undisclosed',
      id: 'second-hand-undisclosed',
    },
    {
      label: 'Second-hand Purpose-Built Office',
      value: 'second-hand-purpose-built-office',
      id: 'second-hand-purpose-built-office',
    },
    {
      label: 'Second-hand Converted Office',
      value: 'second-hand-converted-office',
      id: 'second-hand-converted-office',
    },
  ],
  LEASE_TYPE: [
    {
      label: 'Pre-let',
      value: 'pre-let',
      id: 'pre-let',
    },
    {
      label: 'Lease',
      value: 'lease',
      id: 'lease',
    },
    {
      label: 'Sub-lease',
      value: 'sub-lease',
      id: 'sub-lease',
    },
    {
      label: 'Assignment',
      value: 'assignment',
      id: 'assignment',
    },
    {
      label: 'Reversionary',
      value: 'reversionary',
      id: 'reversionary',
    },
    {
      label: 'License',
      value: 'license',
      id: 'license',
    },
    {
      label: 'Renewal',
      value: 'renewal',
      id: 'renewal',
    },
  ],
  TO_LET_TYPE: [
    {
      label: 'Lease',
      value: 'lease',
      id: 'lease',
    },
    {
      label: 'Only Sub-lease',
      value: 'sub-lease',
      id: 'sub-lease',
    },
    {
      label: 'Only Assignment',
      value: 'assignment',
      id: 'assignment',
    },
    {
      label: 'Sub-lease or Assignment',
      value: 'sub-lease-or-assignment',
      id: 'sub-lease-or-assignment',
    },
  ],
  INSIDE_OUTSIDE: [
    {
      label: 'Inside',
      value: true,
    },
    {
      label: 'Outside',
      value: false,
    },
  ],
  OBLIGATION_TERMS: [
    {
      label: 'FRI',
      value: 'fri',
      id: 'fri',
    },
    {
      label: 'IRI',
      value: 'iri',
      id: 'iri',
    },
    {
      label: 'Other',
      value: 'other',
      id: 'other',
    },
    {
      label: 'Unknown',
      value: 'unknown',
      id: 'unknown',
    },
  ],
  REVIEW_TYPES: [
    {
      label: 'Open Market Review',
      value: 'open-market-review',
      id: 'open-market-review',
    },
    {
      label: 'Stepped Rent',
      value: 'stepped-rent',
      id: 'stepped-rent',
    },
    {
      label: 'CPI',
      value: 'cpi',
      id: 'cpi',
    },
    {
      label: 'RPI',
      value: 'rpi',
      id: 'rpi',
    },
    {
      label: 'Other Index',
      value: 'other-index',
      id: 'other-index',
    },
  ],
  EPC_OPTIONS: [
    {
      label: 'A+',
      value: 'A+',
      id: 'A-plus',
    },
    {
      label: 'A',
      value: 'A',
      id: 'A',
    },
    {
      label: 'B',
      value: 'B',
      id: 'B',
    },
    {
      label: 'C',
      value: 'C',
      id: 'C',
    },
    {
      label: 'D',
      value: 'D',
      id: 'D',
    },
    {
      label: 'E',
      value: 'E',
      id: 'E',
    },
    {
      label: 'F',
      value: 'F',
      id: 'F',
    },
    {
      label: 'G',
      value: 'G',
      id: 'G',
    },
    {
      label: 'Not Supplied',
      value: 'not-supplied',
      id: 'not-supplied',
    },
  ],
  LEASE_COMPARABLE_TRANSACTION_STATUS: [
    {
      label: 'Let',
      value: 'let',
      id: 'let',
    },
    {
      label: 'Under Offer',
      value: 'under-offer',
      id: 'under-offer',
    },
    {
      label: 'Terminated',
      value: 'terminated',
      id: 'terminated',
    },
  ],
  TIME: getTimeOptions(),
  MONTH: getMonthOptions(),
  COMPANY_OWNERSHIP_STAKE: [
    {
      label: 'Less than 50% ownership',
      value: 'ownership-of-shares-25-to-50-percent',
    },
    {
      label: '50-75% ownership',
      value: 'ownership-of-shares-50-to-75-percent',
    },
    {
      label: 'More than 75% ownership',
      value: 'ownership-of-shares-75-to-100-percent',
    },
    {
      label: 'Unknown ownership proportion',
      value: 'right-to-appoint-and-remove-person',
    },
    {
      label: 'Other/Non Ownership',
      value: 'significant-influence-or-control',
    },
  ],
};

export const COUNTRY_OPTIONS_FLAT = countries.reduce((accum, curr) => {
  return accum.concat(curr.options);
}, [] as Option[]);

export const STATE_FIELDS = {
  INVESTOR: 'investor',
  PRIVATE_INVESTOR: 'investor-private',
  PRIVATE_INDIVIDUAL: 'private-individual',
  COMPANY: 'company',
  OWNER: 'owner-occupier',
  BUYER: {
    ID: 'buyerDetails',
    EQUITY: 'buyerEquityStake',
    COMPANY: 'buyerDetails.buyerCompanyName',
    INVESTOR_OWNER: 'buyerInvestorOccupier',
    EQUITY_LABEL: 'Equity Stake',
    INVESTOR_OCCUPIER_LABEL: 'Investor/Occupier',
  },
  SPV: {
    ID: 'holdingEntityDetails',
    EQUITY: 'holdingEntityEquityStake',
    COMPANY: 'holdingEntity.holdingEntityCompanyName',
    INVESTOR_OWNER: 'holdingEntityInvestorOccupier',
    EQUITY_LABEL: 'Equity Stake',
    INVESTOR_OCCUPIER_LABEL: 'Investor/Occupier',
  },
  SELLER: {
    ID: 'sellerDetails',
    COMPANY: 'sellerDetails.sellerCompanyName',
  },
};

export enum FORM_FLOWS {
  SALE = 'NewSale',
  PORTFOLIO_SALE = 'NewPortfolioSale',
  LEASE = 'NewLease',
  TO_LET_LEASE = 'NewToLetLease',
  EDIT_LEASE_UNDER_OFFER_LET = 'EditLeaseUnderOfferLet',
  EDIT_LEASE_UNDER_OFFER_LET_MULTI_DEMISE = 'EditLeaseUnderOfferLetMultiDemise',
  EDIT_LEASE_LET_TERMINATED = 'EditLeaseLetTerminated',
  EDIT_LEASE_TERMINATED_LET = 'EditLeaseTerminatedLet',
  EDIT_SALE_AVAILABLE_UNDER_OFFER = 'EditSaleAvailableUnderOffer',
  EDIT_SALE_AVAILABLE_WITHDRAWN = 'EditSaleAvailableWithdrawn',
  EDIT_SALE_AVAILABLE_SOLD = 'EditSaleAvailableSold',
  EDIT_SALE_UNDER_OFFER_SOLD = 'EditSaleUnderOfferSold',
  NEW_BREAK_OPTION = 'NewBreakOption',
  NEW_RENT_REVIEW = 'NewRentReview',
  EDIT_TENURE_SALE = 'EditTenureSale',
  EDIT_SELLERS = 'EditSellers',
  EDIT_BUYERS = 'EditBuyers',
  EDIT_SPV = 'EditSPV',
  EDIT_LANDLORD = 'EditLandlord',
  EDIT_TENANT = 'EditTenant',
  RELATED_PARTIES = 'RelatedParties',
  ADD_BUILDINGS = 'AddBuildings',
  CREATE_FLOOR_PARTITION = 'CreateFloorPartition',
  FLOOR_PARTITION_LEVEL = 'FloorPartitionLevel',
  UPDATE_BUILDING_ALIASES = 'BuildingAlias',
  FREEHOLDER = 'Freeholder',
  SCHEDULE_QUERY = 'ScheduleQuery',
  MOVE_RECORD = 'MoveRecord',
  RENEW_LEASE = 'RenewLease',
  DELETE_RECORD = 'DeleteRecord',
  HIDE_RECORD = 'HideRecord',
  ADD_DEBT = 'AddDebt',
  EDIT_DEBT = 'EditDebt',
  MERGE_RECORDS = 'MergeRecords',
  ADD_VALUATION = 'AddValuation',
  EDIT_VALUATION = 'EditValuation',
  EDIT_LEASE_TYPE = 'EditLeaseType',
  EDIT_TRADING_AS = 'TradingAsNames',
  EDIT_COMPANY_OWNERSHIP = 'CompanyOwnership',
  SCHEDULE_TEAM_QUERY = 'ScheduleTeamQuery',
}

export const INFORMATIONAL_PANEL_COPY = {
  currentLandlord: {
    title: 'Most recent sale information',
    body:
      'This property was most recently sold to %%STRING_TO_REPLACE%% on %%STRING_TO_REPLACE%%',
    spvBody:
      'This property was most recently sold to %%STRING_TO_REPLACE%% via %%STRING_TO_REPLACE%% on %%STRING_TO_REPLACE%%',
  },
  multiLeaseWarning: {
    title: 'Are multiple leases being assigned or sub-let?',
    body:
      'You will have to enter each of the new leases individually, selecting the correct Lessor each time.',
  },
};

export const TRUTHY_VALUES = ['yes', 'inside', 'on-market', STATE_FIELDS.OWNER];
