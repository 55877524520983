/* eslint global-require: 0 */
/* eslint-disable @typescript-eslint/no-var-requires */

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import TagManager from 'react-gtm-module';

import SplashScreen from 'connected/SplashScreen/SplashScreen';
import App from 'connected/App/App';
import configureStore from 'store/configureStore';
import { ApiState } from 'store/preloadedState';
import { getApiState } from 'services/apiState';
import { getSavillsBoundary } from 'services/savillsBoundary';
import { MapPolygonWithBoundary } from 'components/GoogleMap/types';

import * as serviceWorker from './serviceWorker';
import { ENVIRONMENT, GTM_ID } from './globalConstants';

type RenderAppInput = (ApiState | undefined | MapPolygonWithBoundary)[];

const renderApp = (input: RenderAppInput) => {
  const history = createBrowserHistory();
  const [state, boundaries] = input;

  const store = configureStore(
    history,
    state as ApiState,
    boundaries as MapPolygonWithBoundary,
    !!localStorage.getItem('LOG_REDUX'),
  );

  ReactDOM.render(
    <Router history={history}>
      <App store={store} />
    </Router>,
    document.getElementById('root'),
  );
};

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
    onlyLogs: true,
    titleColor: 'green',
    diffNameColor: 'darkturquoise',
  });
}

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_COGNITO_ATHENA_ENVIRONMENT !== ENVIRONMENT.DEV
) {
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}

ReactDOM.render(<SplashScreen />, document.getElementById('root'));

Promise.all([getApiState(), getSavillsBoundary()])
  .then(renderApp)
  .catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
    renderApp([]);
  })
  .finally(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
