import React, { useState, useMemo, useRef } from 'react';

import { globalVariables } from 'globalConstants';
import useFieldInputValidation from 'hooks/useFieldInputValidation';
import RequiredFlag from 'components/Forms/RequiredFlag';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import FieldValidation from 'components/Forms/FieldValidation';
import { DatepickerType } from './types';
import {
  DatePickerWrapper,
  InputField,
  StyledCalendarIcon,
  StyledLabel,
  StyledPrompt,
} from './DatePickerStyled';
import { checkIsValidDate } from './helpers';

const DatePicker: React.FC<DatepickerType> = ({
  label,
  name,
  onChange,
  labelIsHidden,
  minDate,
  maxDate,
  value = '',
  validation,
  isDisabled = false,
  placeholder,
  prompt,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { hasErrors } = useFieldInputValidation(
    value,
    FIELD_COMPONENTS.DATE_PICKER,
    validation,
  );
  const [isMinimized, setIsMinimized] = useState(
    !!value || !!placeholder || false,
  );
  const startDate = useMemo(() => {
    if (!value) return undefined;

    return new Date(value);
  }, [value]);

  const handleOnChange = (event: any) => {
    const dateString = (event.target as HTMLInputElement).value;
    if (onChange) onChange(dateString);
  };

  const handleOnBlur = () => {
    if (!value && onChange && inputRef.current) {
      onChange('');
      inputRef.current.value = '';
    }

    if (value && (minDate || maxDate)) {
      const isValid = checkIsValidDate(value, minDate, maxDate);

      if (!isValid && onChange && inputRef.current) {
        onChange('');
        inputRef.current.value = '';
      }
    }

    setIsMinimized(false);
  };

  return (
    <div>
      {prompt && <StyledPrompt>{prompt}</StyledPrompt>}
      <DatePickerWrapper
        isDisabled={isDisabled || false}
        showValue
        hasErrors={hasErrors || false}
      >
        <InputField
          ref={inputRef}
          type="date"
          onFocus={() => setIsMinimized(true)}
          placeholder={globalVariables.DATE_PLACEHOLDER}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          value={value}
          labelIsMinimized={!!startDate || isMinimized}
          min={minDate}
          max={maxDate || '9999-12-31'}
          disabled={isDisabled}
        />
        <StyledLabel
          labelIsMinimized
          htmlFor={name}
          labelIsHidden={labelIsHidden}
        >
          {label}
          {validation?.isRequired && <RequiredFlag />}
        </StyledLabel>
        <StyledCalendarIcon />
      </DatePickerWrapper>
      {validation && (
        <FieldValidation hasErrors={hasErrors} validation={validation} />
      )}
    </div>
  );
};

export default DatePicker;
