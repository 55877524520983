import styled from 'styled-components/macro';
import { ReactComponent as Information } from 'assets/icons/info-outline.svg';

export const RowLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const StyledInfoIcon = styled(Information)`
  fill: ${(props) => props.theme.colorPalette.notifications.information};
  margin: -3px 0 0 ${(props) => props.theme.spacing.xsmall};
  border-radius: 30px;
  height: 18px;
  width: 18px;
  position: absolute;
`;

export const RowCheckbox = styled.input`
  margin: 0 10px 0 0;
`;
