import { BuildingRecord, DetailsSummary } from 'pages/Details/types';
import { GlobalApiFailError } from 'store/actions/types';

export type EntityActionProps = {
  error?: string;
};

export type NewSaleEntityProps = {
  error?: string;
  address?: DetailsSummary;
  loading?: boolean;
  onCancelButtonClick: () => void;
  onNewSaleButtonClick: () => void;
};

export type EntityNewSale = {
  saleId: string;
  relatedBuildings: [Pick<BuildingRecord, 'buildingId'>];
};

export enum NewSaleActionTypes {
  NEW_SALE_SAVE_SALE = 'NEW_SALE_SAVE_SALE',
  NEW_SALE_API_SUCCESS = 'NEW_SALE_API_SUCCESS',
  NEW_PORTFOLIO_SALE_API_SUCCESS = 'NEW_PORTFOLIO_SALE_API_SUCCESS',
  NEW_SALE_API_FAIL = 'NEW_SALE_API_FAIL',
  NEW_SALE_RESET = 'NEW_SALE_RESET',
}

export type NewSaleSaveAction = {
  type: NewSaleActionTypes.NEW_SALE_SAVE_SALE;
  payload: EntityNewSale;
};

export type NewSaleApiSuccessAction = {
  type: NewSaleActionTypes.NEW_SALE_API_SUCCESS;
};

export type NewPortfolioSaleApiSuccessAction = {
  type: NewSaleActionTypes.NEW_PORTFOLIO_SALE_API_SUCCESS;
};

export type NewSaleApiFailAction = {
  type: NewSaleActionTypes.NEW_SALE_API_FAIL;
  payload: GlobalApiFailError;
};

export type NewSaleResetAction = {
  type: NewSaleActionTypes.NEW_SALE_RESET;
};

export type NewSaleActions =
  | NewSaleSaveAction
  | NewPortfolioSaleApiSuccessAction
  | NewSaleApiSuccessAction
  | NewSaleResetAction
  | NewSaleApiFailAction;

export type SidebarCardProps = {
  address: DetailsSummary;
};
