import {
  GoogleCoordLatLngFn,
  Coords,
  GoogleCoords,
  GoogleCoordLatLngLiteral,
  BoundBox,
} from 'components/GoogleMap/types';

enum CoordString {
  Latitude = 'latitude',
  Longitude = 'longitude',
}

const getCoords = (path: GoogleCoordLatLngFn[]) => {
  return path.map((latLng) => ({
    latitude: latLng.lat(),
    longitude: latLng.lng(),
  }));
};

const getLowest = (collection: Coords[], key: CoordString) => {
  return [...collection].sort((a, b) => a[key] - b[key])[0];
};

const getHighest = (collection: Coords[], key: CoordString) => {
  return [...collection].sort((a, b) => b[key] - a[key])[0];
};

export const getCenterFromCoords = (coords: Coords[]) => {
  const lowestLat = getLowest(coords, CoordString.Latitude).latitude;
  const lowestLng = getLowest(coords, CoordString.Longitude).longitude;
  const highestLat = getHighest(coords, CoordString.Latitude).latitude;
  const highestLng = getHighest(coords, CoordString.Longitude).longitude;
  return {
    lat: parseFloat((lowestLat + (highestLat - lowestLat) / 2).toFixed(5)),
    lng: parseFloat((lowestLng + (highestLng - lowestLng) / 2).toFixed(5)),
  };
};

export const getCenter = (path: GoogleCoordLatLngFn[]) => {
  const coords = getCoords(path);

  return getCenterFromCoords(coords);
};

export const getBoundsPolygon = (bounds: google.maps.LatLngBounds) => {
  const NECorner = bounds.getNorthEast();
  const SWCorner = bounds.getSouthWest();
  const NWCorner = new google.maps.LatLng(NECorner.lat(), SWCorner.lng());
  const SECorner = new google.maps.LatLng(SWCorner.lat(), NECorner.lng());

  return `${NECorner.lat()}|${NECorner.lng()},${NWCorner.lat()}|${NWCorner.lng()},${SWCorner.lat()}|${SWCorner.lng()},${SECorner.lat()}|${SECorner.lng()},${NECorner.lat()}|${NECorner.lng()}`;
};

export const removeLastPathItem = (path: GoogleCoords[]) => {
  return path.filter((item, index, arr) => {
    return index !== arr.length - 1;
  });
};

export const getOuterMostLiteralPoints = (path: GoogleCoordLatLngLiteral[]) => {
  return path.reduce((accum, curr, i) => {
    if (i === 0) {
      return {
        east: curr.lng,
        west: curr.lng,
        north: curr.lat,
        south: curr.lat,
      };
    }

    return {
      east: Math.max(accum.east, curr.lng),
      west: Math.min(accum.west, curr.lng),
      north: Math.max(accum.north, curr.lat),
      south: Math.min(accum.south, curr.lat),
    };
  }, {} as BoundBox);
};
