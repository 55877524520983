import {
  GlobalModalActions,
  GlobalModalActionTypes,
} from 'connected/GlobalModal/types';
import { NotesActions, NotesActionTypes } from 'connected/NotesPanel/types';
import { Notes } from 'pages/Details/types';

export interface NoteDeleteState {
  id: string;
  entity: string;
  isDeleting: boolean;
  error?: string;
}

export interface NotesPanelState {
  isFetching: boolean;
  hasError: boolean;
  error?: string;
  noteToDelete: NoteDeleteState;
}

export const initialState: NotesPanelState = {
  isFetching: false,
  hasError: false,
  error: '',
  noteToDelete: {
    id: '',
    entity: '',
    isDeleting: false,
    error: '',
  },
};

const notesReducer = (
  state = initialState,
  action: NotesActions | GlobalModalActions,
) => {
  switch (action.type) {
    case NotesActionTypes.DELETE_NOTE: {
      return {
        ...state,
        noteToDelete: {
          ...state.noteToDelete,
          isDeleting: true,
        },
      };
    }

    case GlobalModalActionTypes.SHOW_MODAL: {
      if (action.payload.modal === 'noteDelete' && action.meta?.id) {
        const note = action.meta as Notes;
        const toDelete: NoteDeleteState = {
          // eslint-disable-next-line no-underscore-dangle
          entity: note.__entityName__,
          id: note.id,
          isDeleting: false,
          error: '',
        };

        return {
          ...state,
          noteToDelete: toDelete,
        };
      }

      return state;
    }

    case GlobalModalActionTypes.HIDE_MODAL: {
      if (action.payload === 'noteDelete') {
        return {
          ...state,
          noteToDelete: initialState.noteToDelete,
        };
      }

      return state;
    }

    case NotesActionTypes.DELETE_NOTE_API_FAIL: {
      return {
        ...state,
        noteToDelete: {
          ...state.noteToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case NotesActionTypes.DELETE_NOTE_API_SUCCESS: {
      return {
        ...state,
        noteToDelete: {
          ...state.noteToDelete,
          isDeleting: false,
        },
      };
    }

    default:
      return state;
  }
};

export default notesReducer;
