import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import pathOr from 'ramda/src/pathOr';

import { globalModalHide } from 'store/actions/globalModalActions';
import MatchingRecord from 'components/MatchingRecord/MatchingRecord';
import { LeaseRecord, SaleRecord } from 'pages/Details/types';
import { dynamicString, mapStatus } from 'utils/string';
import { sortArrayByProp, sortNestedArrayByProp } from 'utils/arr';
import {
  FindMatchesPanelProps,
  MatchConfigProps,
  EditableProps,
  ConfigFieldType,
  SelectableProps,
  UpdateRefsProps,
  FindMatchesNoticeProps,
  ErrorMessageConfig,
} from './types';
import { formatField, formatRecordStatus } from './services/utils';
import { FORM_BUILDER_COPY } from './constants';
import { showMatchesNotice } from './services/matches';
import { FormStateContext } from './context';

const StyledMatchesErrorMessageWrapper = styled.div`
  margin: ${(props) => props.theme.spacing.default} 0;
`;

const StyledMatchesError = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
`;

export const MatchesErrorMessage = ({
  messages,
  formState,
}: {
  formState: any;
  messages: ErrorMessageConfig[];
}) => {
  const getErrorMessages = (message: string, ref?: string, subKey?: string) => {
    if (ref) {
      const formValue: string = pathOr('', ref.split('.'), formState);
      if (subKey && Array.isArray(formValue)) {
        const formItem = formValue.find((val: any) => val[subKey]);
        return (
          <StyledMatchesError>
            {dynamicString(message, [formItem[subKey]])}
          </StyledMatchesError>
        );
      }
    }
    return <StyledMatchesError>{message}</StyledMatchesError>;
  };
  return (
    <StyledMatchesErrorMessageWrapper>
      {messages.map((message: ErrorMessageConfig) =>
        getErrorMessages(message.msg, message.ref, message.subKey),
      )}
    </StyledMatchesErrorMessageWrapper>
  );
};

const MatchesWrapper = styled.div`
  margin: 0 0 ${(props) => props.theme.spacing.default};
`;

type ItemProps = {
  title: string;
  body: string | string[];
  isWide?: boolean;
};

/**
 * Sorts returned matches based on sortBy set in the config
 * @param {any} matches matching records returned from details API
 * @param {string | {ref:string;key:string}} sortBy property within matching record to sort
 * returned results allows a string for flat array or key value pair for nested arrays
 * @param {boolean} isDate sets if property to sort by is a date
 * @returns {any} Returns sorted matches
 */
const sortFormatMatches = (
  matches: any,
  isDate = false,
  sortBy?: string | { ref: string; key: string },
) => {
  let sortedMatches = matches;

  if (sortBy) {
    if (typeof sortBy === 'string') {
      sortedMatches = sortArrayByProp(sortedMatches, sortBy, isDate);
    } else {
      const { key, ref } = sortBy;
      sortedMatches = sortNestedArrayByProp(sortedMatches, ref, key);
    }
  }

  return sortedMatches.map((match: SaleRecord | LeaseRecord) => {
    if (match.availabilityStatus) {
      return {
        ...match,
        availabilityStatus: mapStatus(match.availabilityStatus),
      };
    }

    return match;
  });
};

const shouldHideDate = (status: string, key: string) => {
  if (status === 'Available' && key === 'listingDate') return false;
  if (status === 'Under Offer' && key === 'underOfferDate') return false;
  if (status === 'Sold' && key === 'soldDate') return false;
  if (status === 'Withdrawn' && key === 'withdrawnDate') return false;
  return true;
};

const getKey = (configItemKey?: any, configItemType?: any) => {
  if (configItemKey === 'availabilityStatus') {
    return formatRecordStatus(configItemKey);
  }

  if (configItemType === 'DEMISE') {
    if (configItemKey.length > 0 && configItemKey[0].partitionName) {
      return `${configItemKey[0].floorName} (${configItemKey[0].partitionName})`;
    }
    if (configItemKey.length > 0) {
      return configItemKey[0].floorName ? `${configItemKey[0].floorName}` : '';
    }
    return `${configItemKey[0]}`;
  }

  if (configItemKey) {
    return formatField(configItemKey, configItemType);
  }

  return [];
};

const MatchItem = ({
  config,
  match,
  isEditable,
  isSelectable,
  columnsToSpan,
  onSelect,
  selectedValue,
  label,
  hasNotice,
}: {
  config: MatchConfigProps[];
  match: any;
  isEditable?: EditableProps;
  columnsToSpan: number;
  isSelectable?: SelectableProps;
  onSelect: (
    val: string,
    label: string,
    id: string,
    updateRefs: UpdateRefsProps[],
  ) => void;
  selectedValue?: string;
  label: string;
  hasNotice?: FindMatchesNoticeProps;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const contextFormState = useContext(FormStateContext);
  const items = config.reduce((accum: ItemProps[], configItem) => {
    const itemMatch = getKey(match[configItem.key], configItem.type);
    // Skip columns if Row status is `sold`
    const columnsToSkip = ['askingPrice', 'offeredPrice'];
    const isSkipped =
      match.status === 'sold' && columnsToSkip.includes(configItem.key);
    if (isSkipped) return accum;

    if (
      !configItem.hideIfEmpty ||
      (itemMatch &&
        itemMatch.length > 0 &&
        !(
          configItem.type === 'DATE' &&
          shouldHideDate(match.availabilityStatus, configItem.key)
        ))
    ) {
      return [
        ...accum,
        {
          title: configItem.value,
          body:
            itemMatch && itemMatch.length > 0
              ? itemMatch
              : FORM_BUILDER_COPY.matches.notAvailable,
          isWide: !!configItem.isWide,
        },
      ];
    }

    return accum;
  }, []);
  const editObj = isEditable
    ? {
        isEditable: {
          text: isEditable.text,
          link: `${isEditable.path}/${match[isEditable.key]}`,
        },
      }
    : {};

  const updateProps =
    isSelectable && isSelectable.updateRefsOnChange
      ? isSelectable.updateRefsOnChange.map((ref) => ({
          targetKey: ref.targetKey,
          sourceKey: match[ref.sourceKey],
        }))
      : [];
  const selectObj = isSelectable
    ? {
        isSelectable: {
          ...isSelectable,
          value: match[isSelectable.key],
          isSelected: match[isSelectable.key] === selectedValue,
          onSelect: () =>
            onSelect(
              match[isSelectable.key],
              label,
              isSelectable.name,
              updateProps,
            ),
        },
      }
    : {};

  const onEditLinkClick = (link: string) => {
    history.push(link);
    dispatch(globalModalHide());
  };

  return (
    <MatchingRecord
      {...editObj}
      {...selectObj}
      matchingRecordItems={items}
      columnsToSpan={columnsToSpan}
      onEditLinkClick={onEditLinkClick}
      showNotice={showMatchesNotice(
        match,
        contextFormState.formState,
        hasNotice,
      )}
    />
  );
};

const FindMatches = ({
  returnedMatches,
  matchConfig,
  isEditable,
  isSelectable,
  columnsToSpan,
  onSelect,
  selectedValue,
  label,
  sortBy,
  sortByType,
  hasNotice,
}: FindMatchesPanelProps) => {
  const sortedMatches = sortFormatMatches(
    returnedMatches,
    sortByType === ConfigFieldType.DATE,
    sortBy,
  );

  return (
    <MatchesWrapper>
      {sortedMatches.map((match: LeaseRecord | SaleRecord) => (
        <MatchItem
          key={JSON.stringify(match)}
          isEditable={isEditable}
          isSelectable={isSelectable}
          config={matchConfig}
          match={match}
          columnsToSpan={columnsToSpan}
          onSelect={onSelect}
          selectedValue={selectedValue}
          label={label}
          hasNotice={hasNotice}
        />
      ))}
    </MatchesWrapper>
  );
};

export default FindMatches;
