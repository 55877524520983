import React from 'react';
import styled from 'styled-components/macro';
import { lighten } from 'polished';

import { BoldEmphasized } from 'styled/Global';

import { FileToUpload } from '../types';

type Progress = { progress: number };

type FileItemWithProgressProps = Progress & {
  file: FileToUpload;
};

const UploadProgressLi = styled.li<Progress>`
  display: flex;
  background: ${(props) => `
    linear-gradient(
      90deg,
      ${lighten(0.25, props.theme.colorPalette.branding.yellow)} 0%,
      ${lighten(0.25, props.theme.colorPalette.branding.yellow)} ${
    props.progress
  }%,
      ${props.theme.colorPalette.panel.default} ${props.progress}%,
      ${props.theme.colorPalette.panel.default} 100%
    );
  `}
  color: ${(props) => props.theme.colorPalette.text.dark};
  height: 75px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.secondary};
`;

const UploadTitle = styled.span`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 0 ${(props) => props.theme.spacing.small};
}
`;

const FileItemWithProgress = ({
  file,
  progress,
}: FileItemWithProgressProps) => (
  <UploadProgressLi progress={progress}>
    <UploadTitle>{file.name}</UploadTitle>
    <BoldEmphasized>{progress}%</BoldEmphasized>
  </UploadProgressLi>
);

export default FileItemWithProgress;
