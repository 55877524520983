export const LINE_PARAMS = {
  TOP: 'top',
  BELOW: 'bottom',
  UPPER: 'upper',
  LOWER: 'lower',
};

export const FIELDS = {
  PARTITIONS: 'partitions',
  FLOOR_LEVEL: 'floorLevel',
  FLOOR_NAME: 'floorName',
};
