import { PanelTheme } from 'components/PanelWrapper/types';
import React from 'react';
import { SortIcon } from './SortIcon';

import {
  ContractButton,
  ExpandButton,
  SortButtonStyled,
  SortHeader,
  TableHeaderStyled,
  TableHeading,
} from './TableStyled';
import { SortDirections, TableHeaderProps } from './types';

const TableHeaderCell = ({
  title,
  isHeadingHidden = false,
  isSortable,
  isWide = false,
  columns,
  useTheme = PanelTheme.DEFAULT,
  sortConfig,
  sortRows,
  toggleChildren,
  headerKey,
  isParent = false,
  childrenCount,
  isChild = false,
  isLastChild = false,
  parentCol,
  childrenVisible = false,
  useWhiteBackground = true,
}: TableHeaderProps) => {
  const sortableTitle = isSortable && isSortable.key ? isSortable.key : title;
  return (
    <TableHeaderStyled
      columns={columns}
      isWide={isWide}
      useTheme={useTheme}
      isHeadingHidden={isHeadingHidden}
    >
      <TableHeading
        isChild={isChild}
        isNotLastChild={isChild && !isLastChild}
        hasChildrenExpanded={isParent && childrenVisible}
        isHidden={isHeadingHidden}
        useWhiteBackground={useWhiteBackground}
      >
        {isSortable ? (
          <SortButtonStyled>
            <SortIcon
              title={sortableTitle}
              direction={SortDirections.ASC}
              sortConfig={sortConfig}
              onClick={() =>
                sortRows(sortableTitle, isSortable.type, SortDirections.ASC)
              }
            />
            <SortHeader
              role="button"
              onClick={() => sortRows(sortableTitle, isSortable.type)}
              isActive={
                (sortConfig && sortConfig.key === isSortable.key) || false
              }
            >
              {title}
            </SortHeader>
            <SortIcon
              onClick={() =>
                sortRows(sortableTitle, isSortable.type, SortDirections.DESC)
              }
              title={sortableTitle}
              direction={SortDirections.DESC}
              sortConfig={sortConfig}
            />
          </SortButtonStyled>
        ) : (
          title
        )}
        {isParent && !childrenVisible && toggleChildren && headerKey && (
          <ExpandButton onClick={() => toggleChildren(headerKey)}>
            <span>+{childrenCount}</span>
          </ExpandButton>
        )}
        {isLastChild && toggleChildren && parentCol && (
          <ContractButton
            onClick={() => toggleChildren(parentCol)}
            useWhiteBackground={useWhiteBackground}
          >
            <span>-{childrenCount}</span>
          </ContractButton>
        )}
      </TableHeading>
    </TableHeaderStyled>
  );
};

export default TableHeaderCell;
