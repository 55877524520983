import { Vertical } from 'globalConstants';

export enum POLLING_STATUSES {
  SUCCESS = 'SUCCESS',
  WITHDRAWN = 'WITHDRAWN',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export interface PollingUpdateState {
  polling: boolean;
  id: string | null;
  property: string | null;
  entity: Vertical | null;
  confirmationStatus?: POLLING_STATUSES;
  time?: number;
}
