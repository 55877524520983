import {
  CompanySelectActions,
  CompanySelectActionTypes,
  CompanySelectResult,
} from 'connected/CompanySelect/types';

export interface CompanySelectState {
  isFetching: boolean;
  results: CompanySelectResult;
  searchTerm: string;
  error?: string;
}

export const initialState: CompanySelectState = {
  isFetching: false,
  searchTerm: '',
  results: {
    athena: {
      data: [],
    },
    companiesHouse: {
      data: [],
    },
  },
};

const quickSearchReducer = (
  state = initialState,
  action: CompanySelectActions,
) => {
  switch (action.type) {
    case CompanySelectActionTypes.COMPANY_SELECT_QUERY: {
      const { searchTerm } = action.payload;

      return {
        ...state,
        isFetching: !!searchTerm.length,
        searchTerm,
      };
    }

    case CompanySelectActionTypes.COMPANY_SELECT_API_SUCCESS: {
      const { data } = action.payload.athena;
      const pageNumber = action.payload.athena.meta?.page ?? 0;
      const shouldAppendResults = pageNumber > 0;
      return {
        ...state,
        isFetching: false,
        results: {
          athena: {
            data: shouldAppendResults
              ? [...state.results.athena.data, ...data]
              : data,
            meta: action.payload.athena.meta,
          },
          companiesHouse: {
            data: action.payload.companiesHouse.data,
          },
        },
      };
    }

    case CompanySelectActionTypes.COMPANY_SELECT_API_FAIL: {
      return {
        ...state,
        isFetching: false,
        results: { athena: { data: [] }, companiesHouse: { data: [] } },
        error: action.payload.error,
      };
    }

    case CompanySelectActionTypes.COMPANY_SELECT_RESET: {
      return {
        ...state,
        isFetching: false,
        searchTerm: '',
        results: { athena: { data: [] }, companiesHouse: { data: [] } },
      };
    }

    default:
      return state;
  }
};

export default quickSearchReducer;
