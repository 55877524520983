import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  UserProfileActions,
  saveUserProfileAPIFail,
  saveUserProfileAPISuccess,
  getUserProfileAction,
} from 'store/actions/userProfileActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const userProfileEndpoint = endpoints.userProfileScheduledQuery;

/**
 * Save user Search Query
 *
 * @param action$
 * @param state$
 * @param dependencies
 * @returns
 */
const userProfileSave: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(UserProfileActions.SAVE_USER_PROFILE),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'POST',
          url: userProfileEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`POST ${userProfileEndpoint} failed`);
            }
            return of(saveUserProfileAPISuccess());
          }),

          // fetch update list of queries
          concatMap(() => {
            return of(getUserProfileAction());
          }),

          catchError((error) => {
            return of(saveUserProfileAPIFail(error));
          }),
        );
    }),
  );
};

export default userProfileSave;
