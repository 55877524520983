import styled from 'styled-components/macro';
import { ExpandMoreIcon, ExpandLessIcon } from 'components/Icon/Icon.stories';
import { IconPropTypes } from 'components/Icon/Icon';

import { PanelWrapperStyledProps } from './types';

export const PanelWrapperStyled = styled.article<PanelWrapperStyledProps>`
  width: 100%;
  border-radius: 4px;
  background: ${(props) => props.theme.colorPalette.panel[props.useTheme]};
  margin: ${(props) => props.theme.spacing.tablet} 0;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    margin: ${(props) => props.theme.spacing.default} 0 0;
  }
`;

export const PanelMenuCollapseButtonStyled = styled.button`
  justify-self: flex-end;
  background: none;
  margin: 0 0 0 1em;
  padding: 0.5em 1.5em;
  border-left: 1px solid ${(props) => props.theme.colorPalette.branding.ink};
`;

export const PanelMenuCollapseIcon = styled(ExpandLessIcon)`
  height: 2em;
  width: 2em;
`;
export const PanelMenuExpandIcon = styled(ExpandMoreIcon)`
  height: 2em;
  width: 2em;
`;
export const PanelMenuOptionsStyled = styled.div`
  color: ${(props) => props.theme.colorPalette.branding.ink};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
`;

export const PanelMenuTitleStyled = styled.h2`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  margin: 0;
  margin-right: auto;
`;

const getBorderStyles = (color: string, hasMulti?: boolean) => {
  if (hasMulti) {
    return `6px solid ${color}`;
  }

  return `2px solid ${color}`;
};

export const PanelMenuStyled = styled.div<{ containsMultiChildren?: boolean }>`
  color: ${(props) => props.theme.colorPalette.branding.ink};
  padding: 0 0 0 1em;
  display: flex;
  border-bottom: ${(props) =>
    getBorderStyles(
      props.theme.colorPalette.branding.white,
      props.containsMultiChildren,
    )};
  min-height: 48px;
  justify-content: space-between;
  align-items: center;

  & span.created {
    margin-right: 20px;
  }
  & span.normal-font {
    font-weight: normal;
  }
  & span.bold-font {
    font-weight: bold;
  }
`;

export const PanelBodyStyled = styled.div``;

export const withInlineIconStyles = (IconSVG: React.FC) => styled(IconSVG)<
  IconPropTypes
>`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  height: 1.5em;
  width: 1.5em;
  margin: 0 0.5em 0 0;
`;

export const MenuButton = styled.button`
  display: flex;
  justify-self: flex-end;
  align-items: center;
  background: none;
  margin: 0 0 0 1em;
  padding: 0.5em 1.5em;
  font-weight: 700;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const NoResultsStyled = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.default};
  padding: 1em 1em 2em;
`;
