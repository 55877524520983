import React from 'react';
import EditSellers from 'connected/EditModals/Sale/EditSellers';
import EditBuyers from 'connected/EditModals/Sale/EditBuyers';
import EditSPV from 'connected/EditModals/Sale/EditSPV';
import EditLandlordModal from 'connected/EditModals/Lease/EditLandlordModal';
import EditTenant from 'connected/EditModals/Lease/EditTenantModal';
import { FORM_FLOWS } from 'connected/FormFlow/constants';

type EditAssociatedCompaniesProps = {
  modalName: FORM_FLOWS;
  recordId: string;
};

const EditAssociatedCompanies: React.FC<EditAssociatedCompaniesProps> = ({
  modalName,
  recordId,
}) => {
  switch (modalName) {
    case 'EditSellers': {
      return <EditSellers recordId={recordId} />;
    }

    case 'EditBuyers': {
      return <EditBuyers recordId={recordId} />;
    }

    case 'EditSPV': {
      return <EditSPV recordId={recordId} />;
    }

    case 'EditLandlord': {
      return <EditLandlordModal recordId={recordId} />;
    }

    case 'EditTenant': {
      return <EditTenant recordId={recordId} />;
    }

    default: {
      return null;
    }
  }
};

export default EditAssociatedCompanies;
