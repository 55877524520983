import React, { ChangeEvent } from 'react';
import debounce from 'utils/debounce';

import useFieldInputValidation from 'hooks/useFieldInputValidation';
import RequiredFlag from 'components/Forms/RequiredFlag';
import FieldValidation from 'components/Forms/FieldValidation';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import { TextAreaType } from './types';
import {
  StyledLabel,
  StyledTextarea,
  StyledTextareaField,
} from './TextareaStyled';
import { StyledPrompt } from '../Select/SelectStyled';

const TextArea = ({
  label,
  name,
  onChange,
  labelIsHidden,
  inputRef,
  validation,
  value,
  isInline = false,
  prompt,
}: TextAreaType) => {
  const { hasErrors } = useFieldInputValidation(
    value,
    FIELD_COMPONENTS.TEXTAREA,
    validation,
  );
  const isLabelMinimized = !!value;

  const debouncedChange = debounce((e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(e);
  }, 200);

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.persist();
    debouncedChange(event);
  };

  return (
    <div>
      {prompt && <StyledPrompt>{prompt}</StyledPrompt>}
      <StyledTextareaField hasErrors={hasErrors || false} isInline={isInline}>
        <StyledTextarea
          ref={inputRef}
          onChange={handleOnChange}
          id={name}
          name={name}
          defaultValue={value}
          autoComplete="off"
        />
        <StyledLabel
          labelIsMinimized={isLabelMinimized}
          htmlFor={name}
          labelIsHidden={labelIsHidden}
        >
          {label}
          {validation?.isRequired && <RequiredFlag />}
        </StyledLabel>
      </StyledTextareaField>
      {validation && (
        <FieldValidation hasErrors={hasErrors} validation={validation} />
      )}
    </div>
  );
};

export default TextArea;
