import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalModalShow } from 'store/actions/globalModalActions';
import { MenuListItem } from 'components/ActionsMenu/ActionsMenuStyled';
import { getIsAdmin } from 'store/selectors/authenticatedUserSelectors';
import { TABLE_COPY, TABLE_ACTIONS } from './constants';
import { Actions } from './types';
import {
  InlineActionButton,
  InlineActionLink,
  ActionPlaceholder,
} from './TableStyled';

const ActionCell = ({
  type,
  handleAction,
  isDisabled,
  isAdminOnly,
  link,
  showTooltipIfDisabled,
  modalConfig,
}: Actions) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);

  const shouldHideMenuItem = isAdminOnly && !isAdmin;

  if (shouldHideMenuItem) {
    return <></>;
  }

  if (isDisabled && !showTooltipIfDisabled) {
    return (
      <MenuListItem>
        <ActionPlaceholder />
      </MenuListItem>
    );
  }

  switch (type) {
    case TABLE_ACTIONS.DELETE: {
      return (
        <MenuListItem>
          <InlineActionButton type="button" onClick={handleAction}>
            {TABLE_COPY.actions.delete}
          </InlineActionButton>
        </MenuListItem>
      );
    }
    case TABLE_ACTIONS.UNWATCH: {
      return (
        <MenuListItem>
          <InlineActionButton type="button" onClick={handleAction}>
            {TABLE_COPY.actions.unwatch}
          </InlineActionButton>
        </MenuListItem>
      );
    }

    case TABLE_ACTIONS.DOWNLOAD: {
      return (
        <MenuListItem>
          <InlineActionButton
            disabled={isDisabled}
            onClick={handleAction}
            type="button"
          >
            {TABLE_COPY.actions.download}
          </InlineActionButton>
        </MenuListItem>
      );
    }

    case TABLE_ACTIONS.VIEW: {
      if (!link) {
        return (
          <MenuListItem>
            <ActionPlaceholder />
          </MenuListItem>
        );
      }
      return (
        <MenuListItem>
          <InlineActionLink to={link}>
            {TABLE_COPY.actions.view}
          </InlineActionLink>
        </MenuListItem>
      );
    }

    case TABLE_ACTIONS.MODAL: {
      if (modalConfig) {
        const handleOnClick = () => {
          return dispatch(
            globalModalShow(
              modalConfig.modalId,
              true,
              null,
              modalConfig.recordId,
              modalConfig.vertical,
              modalConfig.modalType,
            ),
          );
        };
        return (
          <MenuListItem>
            <InlineActionButton onClick={handleOnClick}>
              {modalConfig.triggerButton}
            </InlineActionButton>
          </MenuListItem>
        );
      }
      return <ActionPlaceholder />;
    }

    default: {
      return (
        <MenuListItem>
          <ActionPlaceholder />
        </MenuListItem>
      );
    }
  }
};

export default ActionCell;
