import styled from 'styled-components/macro';
import { zIndex } from 'styled/Global';

export const StepsList = styled.ul`
  padding-left: ${(props) => props.theme.spacing.default};
  background: ${(props) => props.theme.colorPalette.secondary.backgroundGrey};
  padding: 0 0 0 ${(props) => props.theme.spacing.small};

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    padding: 0 0 0 ${(props) => props.theme.spacing.default};
  }
`;

export const StepItem = styled.li<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  background: ${(props) =>
    props.isSelected ? props.theme.colorPalette.branding.yellow : 'none'};
  padding: 8px 10px;
  color: ${(props) => props.theme.colorPalette.text.dark};
  min-height: 40px;
  justify-content: left;
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    right: -15px;
    bottom: 0;
    width: 0;
    height: 0;
    z-index: ${zIndex.navArrow};
    border-left: 15px solid
      ${(props) =>
        props.isSelected
          ? props.theme.colorPalette.branding.yellow
          : 'transparent'};
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
`;
