export const CREATE_FLOOR_PARTITION_COPY = {
  heading: 'Accommodation Schedule',
  addBtn: 'Add Buildings',
  updating: 'Updating Floor/Partition for Building',
};

export const FLOOR_LEVEL_PARTITION_COPY = {
  heading: 'Change Demise',
};

export enum MULTILINE_TYPE {
  CREATE_FLOOR = 'CREATE_FLOOR',
  DEFAULT = 'DEFAULT',
}

export const PARTITIONS = 'partitions';
