import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { globalModalHide } from 'store/actions/globalModalActions';
import { DetailsActionTypes } from 'pages/Details/types';
import {
  buildingProtectApiFail,
  buildingProtectApiSuccess,
  detailsRefresh,
} from 'store/actions/detailsActions';

type BuildingProtectedEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const buildingProtectedEndpoint = endpoints.buildingProtected;

const buildingProtectedEpic: Epic = (
  action$,
  state$,
  dependencies: BuildingProtectedEpicDependencies,
) => {
  return action$.pipe(
    ofType(DetailsActionTypes.PROTECT_BUILDING),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'PUT',
          url: buildingProtectedEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((deleteResponse) => {
            if (deleteResponse.status > 204) {
              throw Error(`PUT ${buildingProtectedEndpoint} failed`);
            }

            return of(
              buildingProtectApiSuccess(),
              globalModalHide(),
              detailsRefresh(),
            );
          }),
          catchError((error) => {
            return of(buildingProtectApiFail(error));
          }),
        );
    }),
  );
};

export default buildingProtectedEpic;
