import { map, switchMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import { FormFlowActionTypes } from 'connected/FormFlow/types';
import { getPortfolioSalesMatchesSuccess } from 'store/actions/formBuilderActions';

type MatchingRecordsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const portfolioSalesMatchingRecordsEpic: Epic = (
  action$,
  state$,
  { authRequest }: MatchingRecordsEpicDependencies,
) => {
  return action$.pipe(
    ofType(FormFlowActionTypes.GET_PORTFOLIO_MATCHING_RECORDS),
    switchMap(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const url = endpoints.portfolioSaleMatchingRecords;
      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }

          return getPortfolioSalesMatchesSuccess({
            sales: results.response,
          });
        }),
      );
    }),
  );
};

export default portfolioSalesMatchingRecordsEpic;
