import { of } from 'rxjs';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints, Vertical } from 'globalConstants';

import {
  DetailEpicActions,
  DetailsActionTypes,
  TrustScaleResultPayload,
} from 'pages/Details/types';
import {
  compareRecordsFailure,
  compareRecordsSuccess,
} from 'store/actions/detailsActions';
import {
  parseLeaseComparisonDetails,
  parseSaleComparisonDetails,
} from 'services/verticalDetails';

type DetailsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const compareRecordsEpic: Epic = (
  action$,
  state$,
  { authRequest }: DetailsEpicDependencies,
) => {
  return action$.pipe(
    ofType<Action, Action, DetailEpicActions>(
      DetailsActionTypes.COMPARE_RECORDS,
    ),
    switchMap((action: any) => {
      const resourceID = action.payload?.recordId;
      const recordVertical = action.payload.vertical as Vertical;
      const url = endpoints.vertical.compareRecords(recordVertical, resourceID);

      return authRequest(state$, {
        url,
        method: 'GET',
      })().pipe(
        map((results) => {
          if (results.status > 204) {
            throw new Error(`error loading ${url}`);
          }

          let trustScaleResults = {};

          if (results.response.trustScaleResults) {
            trustScaleResults = results.response.trustScaleResults.reduce(
              (accum: {}, trustScaleResult: TrustScaleResultPayload) => {
                return {
                  ...accum,
                  [trustScaleResult.propertyName]: {
                    sources: trustScaleResult.sources,
                    mostTrusted: trustScaleResult.entityId,
                    source: trustScaleResult.source,
                  },
                };
              },
              {},
            );
          }

          const detailPayload =
            recordVertical === Vertical.Sale
              ? parseSaleComparisonDetails(
                  results.response.entities,
                  trustScaleResults,
                )
              : parseLeaseComparisonDetails(
                  results.response.entities,
                  trustScaleResults,
                );
          return compareRecordsSuccess({
            records: detailPayload as any,
            vertical: recordVertical,
            documents: [],
            trustscale: results.response.trustScaleResults,
            rawEntitiesToCompare: results.response.entities,
          });
        }),
        catchError((error) => {
          return of(compareRecordsFailure(error));
        }),
      );
    }),
  );
};

export default compareRecordsEpic;
