import React from 'react';

import {
  Button,
  TertiaryActionButton,
} from 'components/CallToActions/Button/Button';
import { evaluateConditions } from '../services/conditions';
import {
  StyledFormNav,
  StyledNextSteps,
  PreviousButton,
} from './FormNavigationStyled';
import { FORM_BUILDER_COPY } from '../constants';
import { ViewConfig } from '../types';

interface FormNavigationProps {
  onNextClick: () => void;
  onPrevClick: () => void;
  onGoToFinishClick: () => void;
  handleSubmit: () => void;
  handleAltSubmit?: () => void;
  hasErrors: boolean;
  submitDisabled: boolean;
  isLastView: boolean;
  activeView: ViewConfig;
  activeViewIndex: number;
  totalViews: number;
  disableNavigationProceed?: boolean;
  disableNavigationBack?: boolean;
  formState: any;
}

const FormNavigation = ({
  onNextClick,
  onPrevClick,
  onGoToFinishClick,
  handleSubmit,
  handleAltSubmit,
  hasErrors,
  submitDisabled,
  isLastView,
  activeView,
  activeViewIndex,
  totalViews,
  disableNavigationProceed,
  disableNavigationBack,
  formState,
}: FormNavigationProps) => {
  const {
    altSubmitButtonCopy,
    submitButtonCopy,
    canJumpToFinish,
    nextStepButton,
    customFinishStepButton,
    customNextStepButton,
    customNavigation,
    hideNextBtnIfFileExists,
  } = activeView;
  const isPenultimateView = activeViewIndex === totalViews - 2;
  const isLastViewWithAltSubmit = isLastView && handleAltSubmit;
  const isDisabled = hasErrors || submitDisabled;
  const proceedIsDisabled = disableNavigationProceed || hasErrors;

  const showCustomLabels = customNavigation
    ? evaluateConditions(customNavigation, formState)
    : true;

  const nextCopy = customNextStepButton || FORM_BUILDER_COPY.navigation.next;
  const submitCopy = submitButtonCopy || FORM_BUILDER_COPY.navigation.submit;
  const additionalCopy =
    showCustomLabels && nextStepButton
      ? nextStepButton
      : FORM_BUILDER_COPY.navigation.additional;

  const finishCopy =
    showCustomLabels && customFinishStepButton
      ? customFinishStepButton
      : FORM_BUILDER_COPY.navigation.finish;

  const canJumpToFinishEvaluated =
    (typeof canJumpToFinish === 'object' &&
      evaluateConditions(canJumpToFinish, formState)) ||
    canJumpToFinish === true;

  const getNextStepButtons = () => {
    if (isLastViewWithAltSubmit) {
      return (
        <>
          <TertiaryActionButton
            disabled={isDisabled}
            type="button"
            onClick={handleAltSubmit}
          >
            {altSubmitButtonCopy}
          </TertiaryActionButton>
          <Button disabled={isDisabled} type="button" onClick={handleSubmit}>
            {submitCopy}
          </Button>
        </>
      );
    }

    if (isLastView) {
      return (
        <Button disabled={isDisabled} type="button" onClick={handleSubmit}>
          {submitCopy}
        </Button>
      );
    }

    if (canJumpToFinishEvaluated && !isPenultimateView) {
      return (
        <>
          {!hideNextBtnIfFileExists && (
            <TertiaryActionButton
              type="button"
              onClick={onNextClick}
              disabled={proceedIsDisabled}
            >
              {additionalCopy}
            </TertiaryActionButton>
          )}
          <Button
            type="button"
            onClick={onGoToFinishClick}
            disabled={disableNavigationProceed || hasErrors}
          >
            {finishCopy}
          </Button>
        </>
      );
    }

    return (
      <Button type="button" onClick={onNextClick} disabled={proceedIsDisabled}>
        {nextCopy}
      </Button>
    );
  };

  return (
    <StyledFormNav>
      {activeViewIndex !== 0 && (
        <PreviousButton
          type="button"
          onClick={onPrevClick}
          disabled={disableNavigationBack}
        >
          {FORM_BUILDER_COPY.navigation.back}
        </PreviousButton>
      )}
      <StyledNextSteps>{getNextStepButtons()}</StyledNextSteps>
    </StyledFormNav>
  );
};

export default FormNavigation;
