import { ofType, Epic } from 'redux-observable';
import { tap, ignoreElements } from 'rxjs/operators';

import { AuthActionTypes } from 'store/actions/types';

const loginRedirectEpic: Epic = (action$, _, dependencies) =>
  action$.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((action) => {
      const destination = action.payload.destination || '/';

      dependencies.history.push(destination);
    }),
    ignoreElements(),
  );

export default loginRedirectEpic;
