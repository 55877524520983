/* eslint-disable no-nested-ternary */
import React from 'react';
import pathOr from 'ramda/src/pathOr';
import styled from 'styled-components/macro';

import { dynamicString } from 'utils/string';
import NotificationPanel from 'components/NotificationPanel/NotificationPanel';
import { NOTIFICATION_TYPES } from 'components/NotificationPanel/types';
import { IndividualCompany } from 'connected/InvestmentPanel/types';
import { LeaseRecord } from 'pages/Details/types';
import configs from './lessorMatchesConfig.json';

import FormViewErrors from '../FormErrors/FormErrors';
import FindMatches, { MatchesErrorMessage } from '../FindMatches';

import {
  StyledFormView,
  StyledTitle,
  StyledFieldset,
  GroupPrompt,
  GroupSubtitle,
  StyledFieldsetFields,
  Column,
} from '../FormView/FormViewStyled';

import {
  getFieldComponentOptions,
  getGroupValue,
  isReadOnly,
} from '../services/formState';

import {
  FieldProps,
  FIELD_COMPONENTS,
  LessorViewProps,
  UpdateRefsProps,
} from '../types';
import { getSummaryFields } from '../services/summary';
import CompanyFieldset from '../CompanyFieldset';
import FormSummary from '../FormSummary';
import { LESSOR_VIEW_COPY, LESSOR_PATHS } from './constants';
import InformationalPanel from '../InformationalPanel';
import FormField from '../FormField/FormField';

enum LESSOR_TYPES {
  ASSIGNMENT = 'assignment',
  SUBLEASE = 'sub-lease',
}

const StyledMatchesErrorMessageWrapper = styled.div`
  margin: ${(props) => props.theme.spacing.default} 0;
`;

const StyledMatchesError = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
`;

// If lease type is assignment then filter all matches based on those with the landlord matching the landlord of the selected Affected To Let match.
const filterMatches = (
  type: string,
  landlords: string[],
  isNoAffectedToLetSelected: boolean,
  matches?: LeaseRecord[],
) => {
  if (
    isNoAffectedToLetSelected ||
    (!isNoAffectedToLetSelected && landlords.length === 0)
  )
    return matches;
  return matches?.filter((match: LeaseRecord) => {
    if (type === LESSOR_TYPES.ASSIGNMENT) {
      return (
        match &&
        match.landlords?.find((matchLandlord: IndividualCompany) =>
          landlords.includes(matchLandlord.companyName),
        )
      );
    }

    // else lease type is sublease then filter all matches based on those with the tenant matching the landlord of the selected Affected To Let match.
    return (
      match && match.tenant && landlords.includes(match.tenant.companyName)
    );
  });
};

const LessorView = ({
  viewConfig,
  formState,
  onChange,
  viewErrors,
  existingPriorRecords,
}: LessorViewProps) => {
  const {
    title,
    visibleGroups,
    viewId,
    hideTitle,
    summaryFields,
    showErrorsIfNoMatchingRecords,
    informationalPanel,
  } = viewConfig;
  const currentState = formState[viewId];

  const viewSummary = summaryFields
    ? getSummaryFields(formState, summaryFields)
    : null;

  const isNoAffectedToLetSelected = pathOr(
    false,
    LESSOR_PATHS.noAffectedLeaseSelected,
    formState,
  );

  const isAffectedToLetSelected = pathOr(
    null,
    LESSOR_PATHS.affectedLeaseSelected,
    formState,
  );

  const landlordsInState: IndividualCompany[] = pathOr(
    [],
    LESSOR_PATHS.landlord,
    formState,
  );
  const landlords = landlordsInState.map(
    (landlord: IndividualCompany) => landlord.companyName,
  );

  const leaseType: string = pathOr(
    LESSOR_TYPES.ASSIGNMENT,
    LESSOR_PATHS.leaseType,
    formState,
  );

  const filteredRecords = filterMatches(
    leaseType,
    landlords,
    !!isNoAffectedToLetSelected,
    existingPriorRecords?.returnedMatches as LeaseRecord[],
  );
  const matchingRecordConfig: any = {
    ...existingPriorRecords,
    ...(leaseType === LESSOR_TYPES.ASSIGNMENT
      ? configs.ASSIGNMENT
      : configs.SUBLEASE),
    returnedMatches: filteredRecords,
  };

  const shouldHideForm = () => {
    return (
      isAffectedToLetSelected &&
      ((filteredRecords && filteredRecords.length > 0) ||
        (landlords && landlords.length > 0))
    );
  };

  return (
    <StyledFormView>
      {title && !hideTitle && <StyledTitle>{title}</StyledTitle>}

      {viewErrors && viewErrors.length > 0 && (
        <FormViewErrors viewErrors={viewErrors} />
      )}

      {informationalPanel && <InformationalPanel type={informationalPanel} />}
      {existingPriorRecords &&
        filteredRecords &&
        filteredRecords?.length > 0 && (
          <NotificationPanel
            notificationType={NOTIFICATION_TYPES.WARNING}
            notificationBody={
              shouldHideForm()
                ? existingPriorRecords.body
                : existingPriorRecords.bodyWithForm || ''
            }
            notificationTitle={`${filteredRecords?.length} ${existingPriorRecords.title}`}
          />
        )}

      {viewSummary && <FormSummary summaryFields={viewSummary} />}

      {
        // if no filtered matches exist but affected to let has been selected then show no matches message with landlord name replaced with landlord from affected to let record.
        landlords && landlords.length > 0 && filteredRecords?.length === 0 && (
          <StyledMatchesErrorMessageWrapper>
            <StyledMatchesError>
              {dynamicString(LESSOR_VIEW_COPY.noMatchesMessage, landlords)}
            </StyledMatchesError>
          </StyledMatchesErrorMessageWrapper>
        )
      }

      {matchingRecordConfig && matchingRecordConfig.returnedMatches && (
        <FindMatches
          {...matchingRecordConfig}
          onSelect={(
            value: string,
            label: string,
            id: string,
            updateRefOnChange: UpdateRefsProps[] | [],
          ) => {
            onChange(value, label, id, {
              resetRefsOnChange: matchingRecordConfig.resetRefsOnChange,
              updateRefsOnChange: updateRefOnChange,
              componentType: FIELD_COMPONENTS.FIND_MATCHES,
            });
          }}
          selectedValue={
            existingPriorRecords && existingPriorRecords.isSelectable
              ? getGroupValue(
                  currentState,
                  formState,
                  existingPriorRecords.isSelectable.name,
                )
              : ''
          }
          sortBy={matchingRecordConfig.sortBy}
          sortByType={matchingRecordConfig.sortByType}
        />
      )}
      {showErrorsIfNoMatchingRecords && (
        <MatchesErrorMessage
          formState={formState}
          messages={showErrorsIfNoMatchingRecords}
        />
      )}
      {
        // only show form if no affected to let record has been selected or no appropriate 'let' records exist.
        !shouldHideForm() &&
          visibleGroups?.map((group) => {
            let groupPrompt = <></>;

            if (matchingRecordConfig?.returnedMatches && group.altPrompt) {
              groupPrompt = <GroupPrompt>{group.altPrompt}</GroupPrompt>;
            }

            if (group.prompt) {
              groupPrompt = <GroupPrompt>{group.prompt}</GroupPrompt>;
            }

            const updatedGroup =
              filteredRecords && filteredRecords.length > 0
                ? { ...group }
                : {
                    // if no appropriate 'let' records are shown we should remove the manually added checkbox as this is only needed to toggle between manually adding a lessor and selecting an existing 'let' record.
                    ...group,
                    fields: group.fields?.filter(
                      (field) =>
                        field.fieldId !== LESSOR_VIEW_COPY.manuallyAddedId,
                    ),
                  };
            return (
              <StyledFieldset key={group.groupId}>
                {group.componentType === FIELD_COMPONENTS.COMPANY_FIELDSET && (
                  <>
                    {groupPrompt}
                    {group.subtitle && (
                      <GroupSubtitle>{group.subtitle}</GroupSubtitle>
                    )}
                    <CompanyFieldset
                      group={updatedGroup}
                      currentState={currentState}
                      onChange={onChange}
                      formState={formState}
                      columns={group.columns || 3}
                    />
                  </>
                )}

                {group.componentType === FIELD_COMPONENTS.FIELDSET && (
                  <>
                    {group.prompt && <GroupPrompt>{group.prompt}</GroupPrompt>}
                    {group.subtitle && (
                      <GroupSubtitle>{group.subtitle}</GroupSubtitle>
                    )}
                    <StyledFieldsetFields
                      additionalStyles={group.additionalStyles}
                      columns={group.columns}
                    >
                      {group.fields?.map((field: FieldProps) => (
                        <Column width={field.columns} key={field.fieldId}>
                          <FormField
                            isGroup
                            type={field.componentType}
                            name={field.name}
                            subtitle={field.subtitle}
                            label={field.label}
                            groupId={group.groupId}
                            columns={group.columns}
                            inlineLabels={field.inlineLabels}
                            prompt={field.prompt}
                            formState={formState}
                            options={getFieldComponentOptions(field, formState)}
                            validation={field.validation}
                            readOnly={isReadOnly(formState, field.readOnly)}
                            itemProps={field}
                            value={getGroupValue(
                              currentState,
                              formState,
                              group.groupId,
                              field.fieldId,
                              field.prepopulated,
                              field.defaultValue,
                            )}
                            hasDefaultValue={!!field.defaultValue}
                            decimals={field.decimals}
                          />
                        </Column>
                      ))}
                    </StyledFieldsetFields>
                  </>
                )}
              </StyledFieldset>
            );
          })
      }
    </StyledFormView>
  );
};

export default LessorView;
