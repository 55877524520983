import { Middleware } from 'redux';
import { RootState } from 'store/rootReducer';
import { toast } from 'react-toastify';
import { DocumentUploaderActionTypes } from 'connected/DocumentUploader/types';
import { DetailsActionTypes } from 'pages/Details/types';

export const DEFAULT_ERROR_MESSAGE = 'Sorry Something went wrong';

const errorLogging: Middleware<{}, RootState> = () => (next) => (action) => {
  if (action.type.includes('_API_FAIL')) {
    // eslint-disable-next-line no-console
    console.warn('APPLICATION ERROR:', action);

    if (action.type === DetailsActionTypes.DETAILS_API_FAIL) {
      next(action);
      return;
    }

    if (
      action.type === DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_FAIL &&
      action.payload.errorCode === 409
    ) {
      next(action);
      return;
    }
    const clientErrorMessage =
      (action.payload && action.payload.error) || DEFAULT_ERROR_MESSAGE;

    toast(clientErrorMessage, {
      containerId: 'global-error-toast',
    });
  }

  next(action);
};

export default errorLogging;
