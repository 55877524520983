import { Vertical, urls, globalContent } from 'globalConstants';

import { TableRowProps } from 'components/Table/types';
import { TABLE_ACTIONS, TABLE_CELL_TYPE } from 'components/Table/constants';
import { DATA_ITEM_COPY } from 'components/DataValueItem/constants';
import { BUILDING_DETAILS_PAGE_COPY } from 'pages/Details/Building/constants';
import { Company, FloorAndPartition, LeaseRecord } from 'pages/Details/types';
import { mapCompanyCells } from 'components/Table/TableCellUtils';
import { EDIT_MODAL_TYPE, MODAL_ID } from 'connected/GlobalModal/constants';
import { formatArea, formatDate, formatPriceWithPence } from 'services/format';
import { mapStatus, mapType } from 'utils/string';

import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import {
  ANCHOR_LINKS,
  LEASE_PANEL_COPY,
  UNKNOWN_FLOOR_NAME,
} from './constants';

export const mapFloorPartition = (floors: FloorAndPartition[]): string => {
  const hasFloors = floors.length > 0 && floors[0].floorName;
  if (!hasFloors) {
    return '-';
  }

  const hasPartition = floors[0].partitionName;

  if (hasPartition) {
    return `${floors[0].floorName} (${floors[0].partitionName})`;
  }

  return `${floors[0].floorName}`;
};

export const checkUnknownFloors = (record: LeaseRecord) => {
  const hasFloor = record.floors?.find((floor) => floor.floorId);
  if (!hasFloor) {
    return LEASE_PANEL_COPY.unknown;
  }

  if (record.floors) {
    return mapFloorPartition(record.floors);
  }

  return null;
};

export const mapLeaseRecordToTableFormattedData = (
  record: LeaseRecord,
  companyMapper: (_?: Company[], isAddable?: boolean) => any = mapCompanyCells,
): TableRowProps => ({
  icons: {
    value: BUILDING_DETAILS_PAGE_COPY.investmentPanel.viewRecord,
    cellType: TABLE_CELL_TYPE.ICON,
    actions: [
      {
        type: TABLE_ACTIONS.MERGE,
        isAdminOnly: true,
        isDisabled: false,
        id: record.leaseId,
      },
      {
        type: TABLE_ACTIONS.DISPUTED,
        isDisabled: !record.isDisputed,
      },
      {
        type: TABLE_ACTIONS.DOCS_COUNT,
        isDisabled: !record.docsCount,
        link: urls.searchDetails(Vertical.Lease, record.leaseId, '#documents'),
      },
      {
        type: TABLE_ACTIONS.CONFIDENTIAL,
        isDisabled: !record.dealIsPrivate,
      },
      {
        type: TABLE_ACTIONS.NON_CANONICAL_ADDRESS,
        isDisabled: record.isCanonicalAddress,
        link: urls.searchDetails(Vertical.Building, record.buildingId),
      },
      {
        type: TABLE_ACTIONS.HIDDEN,
        isDisabled: !record.isHidden,
        tooltipContent: LEASE_PANEL_COPY.tooltip,
      },
    ],
  },
  id: {
    value: record.leaseId,
  },
  leaseType: {
    value: record.type ? mapType(record.type) : null,
  },
  status: {
    value: record.availabilityStatus
      ? mapStatus(record.availabilityStatus)
      : null,
  },
  landlord: {
    value:
      record.landlords && record.landlords?.length > 0
        ? companyMapper(record.landlords, true)
        : null,
    subValue:
      (record &&
        record.landlords &&
        record.landlords[0] &&
        record.landlords[0].companyName) ||
      ' ',
    cellType: TABLE_CELL_TYPE.MODAL_TRIGGER,
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    isEditable: true,
    editModal: { ref: 'companyName', name: 'EditLandlord' },
  },
  tenant: {
    value: record.tenant ? companyMapper([record.tenant], true) : null,
    subValue: record.tenant?.companyName,
    cellType: TABLE_CELL_TYPE.MODAL_TRIGGER,
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    isEditable: true,
    editModal: { ref: 'companyName', name: 'EditTenant' },
  },
  tenantEquivalentBondRating: {
    value: record.tenant ? record.tenant.equivalentBondRating : null,
  },
  tenantIncansGlobalScore: {
    value: record.tenant ? record.tenant.incansGlobalScore : null,
  },
  demise: {
    value: checkUnknownFloors(record),
    subValue: {
      value:
        (record &&
          record.floors &&
          record.floors[0] &&
          record.floors[0].floorLevel) ||
        0,
    },
    hasMatchingRecords: record.hasMatchingRecords,
    hasHighlight:
      !record.floors?.find((floor) => floor.floorId) ||
      !!record.floors?.find((floor) => floor.floorName === UNKNOWN_FLOOR_NAME),
  },
  startDate: {
    value: record.startDate ? formatDate(record.startDate) : null,
  },
  nextBreak: {
    value: record.nextBreakDate ? formatDate(record.nextBreakDate) : null,
    link: {
      path: `${urls.searchDetails(Vertical.Lease, record.leaseId)}#${
        ANCHOR_LINKS.break
      }`,
      label: LEASE_PANEL_COPY.breakLabel,
    },
  },
  nextRentReview: {
    value: record.nextRentReview ? formatDate(record.nextRentReview) : null,
    link: {
      path: `${urls.searchDetails(Vertical.Lease, record.leaseId)}#${
        ANCHOR_LINKS.rent
      }`,
      label: LEASE_PANEL_COPY.rentLabel,
    },
  },
  endDate: {
    value: record.endDate ? formatDate(record.endDate) : null,
  },
  area: {
    value: record.totalArea ? formatArea(record.totalArea) : null,
  },
  rent: {
    value: record.rent ? formatPriceWithPence(record.rent) : null,
  },
  rentFigure: {
    value: record.totalRent ? formatPriceWithPence(record.totalRent) : null,
    cellKey: DATA_ITEM_COPY.annualRent.key,
    isConfirmedTotalRent: record.isConfirmedTotalRent,
  },
  useClauses: {
    value: record.useClauses ? record.useClauses.join(', ') : null,
  },
  view: {
    value: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.view,
    cellType: TABLE_CELL_TYPE.ACTION,
    actions: [
      {
        type: TABLE_ACTIONS.VIEW,
        isDisabled: false,
        link: urls.searchDetails(Vertical.Lease, record.leaseId),
      },
      {
        type: TABLE_ACTIONS.MODAL,
        isDisabled: false,
        isAdminOnly: true,
        modalConfig: {
          modalId: MODAL_ID.EDIT_RECORD,
          triggerButton: globalContent.move,
          recordId: record.leaseId,
          vertical: Vertical.Lease,
          modalType: EDIT_MODAL_TYPE.MOVE_RECORD,
        },
      },
      {
        type: TABLE_ACTIONS.MODAL,
        isDisabled: record.availabilityStatus !== 'let',
        modalConfig: {
          modalId: MODAL_ID.EDIT_RECORD,
          triggerButton: globalContent.renew,
          recordId: record.leaseId,
          vertical: Vertical.Lease,
          modalType: EDIT_MODAL_TYPE.RENEW_LEASE,
        },
      },
      {
        type: TABLE_ACTIONS.MODAL,
        isDisabled: false,
        isAdminOnly: true,
        modalConfig: {
          modalId: MODAL_ID.EDIT_RECORD,
          triggerButton: globalContent.delete,
          recordId: record.leaseId,
          vertical: Vertical.Lease,
          modalType: EDIT_MODAL_TYPE.DELETE_RECORD,
        },
      },
      {
        type: TABLE_ACTIONS.MODAL,
        isDisabled: false,
        isAdminOnly: true,
        modalConfig: {
          modalId: MODAL_ID.EDIT_RECORD,
          triggerButton: globalContent.split,
          recordId: record.leaseId,
          vertical: Vertical.Lease,
          modalType: EDIT_MODAL_TYPE.SPLIT_RECORD,
        },
      },
      {
        type: TABLE_ACTIONS.MODAL,
        isDisabled: false,
        isAdminOnly: true,
        modalConfig: {
          modalId: MODAL_ID.EDIT_RECORD,
          triggerButton: record.isHidden
            ? globalContent.unhide
            : globalContent.hide,
          recordId: record.leaseId,
          vertical: Vertical.Lease,
          modalType: EDIT_MODAL_TYPE.HIDE_RECORD,
        },
      },
    ],
  },
});
