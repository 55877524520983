import React from 'react';

import { SidebarWrapper, SidebarCard } from './FormSidebarStyled';
import ProgressSteps from '../ProgressSteps/ProgressSteps';
import { FormSidebarProps } from './types';

const FormSidebar = ({
  children,
  parentSection,
  progressSections,
  hideProgressSteps = false,
  visibleViews,
}: FormSidebarProps) => {
  const activeViews = visibleViews.map((view) => view.parentSection);
  const activeSteps = progressSections.filter((section) =>
    activeViews.includes(section.id),
  );
  return (
    <SidebarWrapper>
      <SidebarCard>{children}</SidebarCard>
      {!hideProgressSteps && (
        <ProgressSteps activeStep={parentSection} steps={activeSteps} />
      )}
    </SidebarWrapper>
  );
};

export default FormSidebar;
