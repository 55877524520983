import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints, Vertical } from 'globalConstants';
import {
  editRecordApiFail,
  editRecordApiSuccess,
  EditRecordActionTypes,
} from 'store/actions/editRecordActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const editRecordApiEndpoint = {
  [Vertical.Lease]: endpoints.editLeaseRecord,
  [Vertical.ToLet]: endpoints.editLeaseRecord,
  [Vertical.Sale]: endpoints.editSaleRecord,
  [Vertical.Building]: endpoints.editBuildingRecord,
  [Vertical.Company]: endpoints.editCompanyRecord,
  [Vertical.PortfolioSale]: endpoints.editSaleRecord,
};

const editMultipleRecordsEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(EditRecordActionTypes.EDIT_MULTIPLE_RECORDS),
    mergeMap((action) => {
      const recordVertical = action.payload.vertical as Vertical;
      const url = editRecordApiEndpoint[recordVertical];

      return dependencies
        .authRequest(state$, {
          url,
          method: 'PUT',
          body: action.payload.payload,
        })()
        .pipe(
          map((results) => {
            if (results.status > 204) {
              throw new Error(`error loading ${url}`);
            }

            return editRecordApiSuccess();
          }),
          catchError((error) => of(editRecordApiFail(error))),
        );
    }),
  );
};

export default editMultipleRecordsEpic;
