import styled from 'styled-components/macro';

import { getHiddenStyles, getMinimizedStyles } from 'styled/helpers';
import { LabelType, StyledInputProps } from './types';

export const StyledLabel = styled.label<LabelType>`
  ${(props) => getHiddenStyles(props.labelIsHidden)};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  transition: all 0.5s;
  touch-action: manipulation;
  position: absolute;

  ${(props) => getMinimizedStyles(props.labelIsMinimized)};
`;

export const StyledInput = styled.input<StyledInputProps>`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  padding: 0 ${(props) => props.theme.spacing.small};
  width: 100%;
  height: 100%;
  transition: all 0.1s;
  touch-action: manipulation;
  position: relative;
  top: 0;
  border-radius: 0 4px 4px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    padding: 0 ${(props) => props.theme.spacing.tablet};
  }
  &:focus,
  &:hover {
    border: 1px solid ${(props) => props.theme.colorPalette.branding.ink};
    + label {
      top: 7px;
      font-size: 10px;
      color: ${(props) => props.theme.colorPalette.text.placeholder};
    }
  }
`;
