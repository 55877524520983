import styled from 'styled-components/macro';

export const NoResultsContainer = styled.div`
  text-align: center;
  border: 1px dotted ${(props) => props.theme.colorPalette.secondary.midGrey};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
`;

export const NoResultsHeader = styled.header`
  svg {
    width: 32px;
    height: 32px;
  }

  h3 {
    font-size: ${(props) => props.theme.typography.heading.small};
    line-height: 1.25;
    margin: 10px 0;
  }
`;

export const NoResultsText = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  line-height: 1.5;
`;
