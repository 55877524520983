import { Reducer } from 'redux';

import {
  CompanyGetDeletionDataActions,
  CompanyGetDeletionDataActionTypes,
  CompanyGetDeletionDataResult,
} from 'store/actions/companyDeleteActions';

export interface CompanyGetDeletionDataState {
  isFetching: boolean;
  results: CompanyGetDeletionDataResult;
  error?: string;
}

export const initialState: CompanyGetDeletionDataState = {
  isFetching: false,
  results: {},
};

const companyGetDeletionData: Reducer<
  CompanyGetDeletionDataState,
  CompanyGetDeletionDataActions
> = (state = initialState, action: CompanyGetDeletionDataActions) => {
  switch (action.type) {
    case CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_API_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    }

    case CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_API_FAIL: {
      return {
        ...state,
        isFetching: false,
        results: {},
        error: action.payload.error,
      };
    }

    case CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_RESET: {
      return {
        ...state,
        isFetching: false,
        results: {},
      };
    }

    default:
      return state;
  }
};

export default companyGetDeletionData;
