import styled from 'styled-components/macro';

import { ReactComponent as Tick } from 'assets/icons/tick.svg';

export const SuccessTick = styled(Tick)`
  fill: ${(props) => props.theme.colorPalette.branding.yellow};
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

export const ToastContainer = styled.div`
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const ToastContents = styled.div`
  position: relative;
`;

export const ToastMessage = styled.p`
  padding: 8px;
  margin: 0;
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
`;
