export enum LeaseDateFilters {
  EXPIRED = 'expired',
  CURRENT = 'current',
  FUTURE = 'future',
}

export enum LeaseDisputedFilters {
  DISPUTED = 'disputed',
  NOT_DISPUTED = 'not-disputed',
}

export enum LeaseStatusFilters {
  TERMINATED = 'terminated',
  LET = 'let',
  UNDER_OFFER = 'under-offer',
}

export enum RecordVisibilityFilters {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export enum DebtFilters {
  CURRENT = 'current',
  HISTORIC = 'historic',
}

export enum CompanyDebtFilters {
  LENDER = 'Lender',
  BORROWER = 'Borrower',
}
