import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import { DetailsActionTypes } from 'pages/Details/types';
import {
  mergeRecordsFailure,
  mergeRecordsSuccess,
} from 'store/actions/detailsActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const mergeRecordsEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(DetailsActionTypes.MERGE_RECORDS),
    switchMap((action) => {
      const { payload } = action;
      const mergeRecordsApiEndpoint = endpoints.vertical.mergeRecords(
        payload.vertical,
      );
      return dependencies
        .authRequest(state$, {
          url: mergeRecordsApiEndpoint,
          method: 'PUT',
          body: action.payload,
        })()
        .pipe(
          map((results) => {
            if (results.status > 204) {
              throw new Error(`error loading ${mergeRecordsApiEndpoint}`);
            }

            return mergeRecordsSuccess();
          }),
          catchError((error) => of(mergeRecordsFailure(error))),
        );
    }),
  );
};

export default mergeRecordsEpic;
