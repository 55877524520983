import React from 'react';
import { AccessibleText } from 'styled/Global';
import Select from 'components/Forms/Inputs/Select/Select';
import { Option } from 'components/Forms/types';
import {
  PAGINATION_DEFAULT_LIMIT_OPTIONS,
  PAGINATION_NAV,
  PAGINATION_COPY,
} from './constants';
import {
  LastIcon,
  PaginationButtonStyled,
  FirstIcon,
  NextIcon,
  PrevIcon,
  PaginationNavStyled,
  PaginationStyled,
  SortSelectStyled,
  PaginationTextStyled,
  StyledLabel,
} from './PaginationStyled';
import { PaginationProps } from './types';

const Pagination = ({
  total,
  pagination,
  lastPage,
  onClick,
  handleSelectChange,
  paginationOptions = PAGINATION_DEFAULT_LIMIT_OPTIONS,
  hideBlankOption,
}: PaginationProps) => {
  const { page, limit, label } = pagination;
  const selectedValue = paginationOptions.find(
    (option) => option.label === label,
  );

  return (
    <PaginationStyled>
      <SortSelectStyled>
        <StyledLabel>{PAGINATION_COPY.pagination.show}</StyledLabel>
        <Select
          isInlineSelect
          label=""
          onSelectChange={(option: Option) =>
            handleSelectChange(option.value, option.label)
          }
          defaultValue={paginationOptions[0]}
          selectedValue={selectedValue}
          name="paginationLimit"
          options={paginationOptions}
          hideBlankOption={hideBlankOption}
        />
      </SortSelectStyled>
      <PaginationNavStyled>
        <PaginationButtonStyled
          disabled={page === 1}
          onClick={() => onClick(PAGINATION_NAV.FIRST, limit, total)}
        >
          <FirstIcon />
          <AccessibleText>{PAGINATION_NAV.FIRST}</AccessibleText>
        </PaginationButtonStyled>
        <PaginationButtonStyled
          disabled={page === 1}
          onClick={() => onClick(PAGINATION_NAV.PREV, limit, total)}
        >
          <PrevIcon />
          <AccessibleText>{PAGINATION_NAV.PREV}</AccessibleText>
        </PaginationButtonStyled>

        <PaginationTextStyled>
          {page} / {lastPage}
        </PaginationTextStyled>

        <PaginationButtonStyled
          disabled={page === lastPage}
          onClick={() => onClick(PAGINATION_NAV.NEXT, limit, total)}
        >
          <NextIcon />
          <AccessibleText>{PAGINATION_NAV.NEXT}</AccessibleText>
        </PaginationButtonStyled>
        <PaginationButtonStyled
          disabled={page === lastPage}
          onClick={() => onClick(PAGINATION_NAV.LAST, limit, total)}
        >
          <LastIcon />
          <AccessibleText>{PAGINATION_NAV.LAST}</AccessibleText>
        </PaginationButtonStyled>
      </PaginationNavStyled>
    </PaginationStyled>
  );
};

export default Pagination;
