import React from 'react';
import MultilineFieldset from 'connected/FormFlow/FormMultiline/FormMultiline';

import BatchControlOrdered from './BatchControlOrdered/BatchControlOrdered';
import { StyledAddLineButton, StyledAddIcon } from './FormMultilineStyled';
import useMultiline from '../hooks/useMultiline';
import { MultilineProps } from '../types';
import { FORM_BUILDER_COPY } from '../constants';

interface MultiLineWithBatchOrderedProps extends MultilineProps {
  addBatchLabel?: string[];
  removeLabel?: string;
  multilineTitle?: string;
  type?: string;
  multilineType?: string;
  columns?: number;
}

const MultiLineWithBatchOrdered = ({
  lineConfig,
  handleOnChange,
  addButton,
  addBatchLabel,
  formStateItems,
  multilineTitle,
  prompt,
  description,
  groupId,
  type,
  multilineType,
  columns,
}: MultiLineWithBatchOrderedProps) => {
  const {
    onFieldChange,
    addOrderedRows,
    handleDeleteRow,
    rowFields,
  } = useMultiline(formStateItems, handleOnChange);

  const onInsertRows = (quantity: number, position: string) => {
    addOrderedRows(quantity, 'floorLevel', position);
  };

  return (
    <div>
      <StyledAddLineButton type="button" onClick={() => onInsertRows(1, 'top')}>
        <StyledAddIcon />
        {FORM_BUILDER_COPY.multiline.buttons.addLineAbove}
      </StyledAddLineButton>
      <MultilineFieldset
        prompt={prompt}
        description={description}
        rowFields={rowFields}
        lineConfig={lineConfig}
        onFieldChange={onFieldChange}
        handleDeleteLine={handleDeleteRow}
        multilineTitle={multilineTitle}
        multilineType={multilineType}
        groupId={groupId}
        type={type}
        columns={columns}
      />

      <StyledAddLineButton
        type="button"
        onClick={() => onInsertRows(1, 'below')}
      >
        <StyledAddIcon />
        {FORM_BUILDER_COPY.multiline.buttons.addLineBelow}
      </StyledAddLineButton>
      <BatchControlOrdered
        addBatchLabel={addBatchLabel}
        addButton={addButton}
        onInsertRows={onInsertRows}
      />
    </div>
  );
};

export default MultiLineWithBatchOrdered;
