export const DOCUMENT_UPLOADER_COPY = {
  title: 'Upload Document',
  cancelButton: 'Cancel',
  uploadButton: 'Upload',
  tryAgainButton: 'Start again',
  unsupportedFile: 'is not a supported file type.',
  removeItem: 'Remove Item',
  fileInputLabel: 'Drag your file here, or click to browse for it',
  selectLabel: 'Please select a document type',
  success: 'Success',
  uploaded: 'uploaded',
  uploadAnother: 'Upload another',
  return: 'Close',
  uploadFailed: 'upload failed',
  uploadFailedDuplicate: 'already exists.',
  failedNextActionPrompt: 'Please click below to try again or cancel to quit',
  levertonLargeFileWarning:
    'Please note that Leverton does not allow files over %%STRING_TO_REPLACE%%mb, any such files will not be sent for extraction',
};

export const PORTFOLIO_SALE_BROCHURE = 'portfolio-sale-brochure';
export const BUILDING_SERVICE_CHARGE_BUDGET = 'service-charge-budget';
