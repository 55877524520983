import { GlobalApiFailError } from 'store/actions/types';
import { DrawTools } from 'components/GoogleMap';
import {
  Coords,
  MapPolygon,
  MapCorridor,
  MapPolygonWithBoundary,
} from 'components/GoogleMap/types';

export enum MapActionTypes {
  MAP_SUGGESTED_PLACES_API_SUCCESS = 'MAP_SUGGESTED_PLACES_API_SUCCESS',
  MAP_FETCH_SUGGESTED_PLACES = 'MAP_FETCH_SUGGESTED_PLACES',
  MAP_SUGGESTED_PLACES_API_FAIL = 'MAP_SUGGESTED_PLACES_API_FAIL',
  MAP_FETCH_BOUNDARY = 'MAP_FETCH_BOUNDARY',
  MAP_FETCH_BOUNDARY_API_SUCCESS = 'MAP_FETCH_BOUNDARY_API_SUCCESS',
  MAP_FETCH_BOUNDARY_API_FAIL = 'MAP_FETCH_BOUNDARY_API_FAIL',
  MAP_RESET = 'MAP_RESET',
  MAP_INIT_SET = 'MAP_INIT_SET',
  MAP_UPDATE_SELECTED_PLACE = 'MAP_UPDATE_SELECTED_PLACE',
  MAP_GET_GEOCODE_INFO_API_SUCCESS = 'MAP_GET_GEOCODE_INFO_API_SUCCESS',
  SELECT_DRAW_TOOL = 'SELECT_DRAW_TOOL',
  MAP_ADD_SHAPE = 'MAP_ADD_SHAPE',
  MAP_CIRCLE_MOVE_END = 'MAP_CIRCLE_MOVE_END',
  MAP_POLYGON_EDIT = 'MAP_POLYGON_EDIT',
}

export type SelectPlacePayload = {
  place: string;
};

export type GeocodePayload = {
  latLng?: google.maps.LatLng | google.maps.LatLngLiteral;
  bounds?: google.maps.LatLngBounds;
  placeName?: string;
};

export type MatchingSubstringType = {
  length?: number;
  offset?: number;
};

export type PlacesTypes = {
  name: string;
  id: string;
  matchingSubstring: MatchingSubstringType[];
  userFriendlyName: string;
};

export type SelectedPlaceType = {
  placeId: string;
  userFriendlyName?: string;
  latLng?: google.maps.LatLng | google.maps.LatLngLiteral;
  boundaries?: google.maps.LatLngBounds;
};

export type DefaultPlaceType = {
  name: string;
  latLng: google.maps.LatLng | google.maps.LatLngLiteral;
  zoom: number;
};

export enum BoundaryType {
  LocalAuthority = 'Local Authority',
  Postcode = 'Postcode District',
  SubMarket = 'Savills Submarket',
  Market = 'Savills Market',
  PostcodeGroup = 'Postcode Group',
}

export type BoundariesType = {
  id: string;
  label: string;
  type: BoundaryType;
};

export type PlacesPayload = {
  results: google.maps.places.AutocompletePrediction[];
  boundaries: BoundariesType[];
};

export type PlacesQueryParameters = {
  input: string;
  key?: string;
  sessionToken: string;
  offset?: number;
  location?: google.maps.LatLng | google.maps.LatLngLiteral;
};

export type ResourceIdentifiers = {
  placeName: string;
};

export type MapFetchSuggestedPlacesAction = {
  type: MapActionTypes.MAP_FETCH_SUGGESTED_PLACES;
  payload: ResourceIdentifiers;
};

export type MapApiSuccessAction = {
  type: MapActionTypes.MAP_SUGGESTED_PLACES_API_SUCCESS;
  payload: PlacesPayload;
};

export type MapGetGeoCodeInfoAction = {
  type: MapActionTypes.MAP_GET_GEOCODE_INFO_API_SUCCESS;
  payload: GeocodePayload;
};

export type MapApiFailAction = {
  type: MapActionTypes.MAP_SUGGESTED_PLACES_API_FAIL;
  payload: GlobalApiFailError;
};

export type MapFetchBoundaryAction = {
  type: MapActionTypes.MAP_FETCH_BOUNDARY;
  payload: BoundariesType;
};

export type MapFetchBoundarySuccessAction = {
  type: MapActionTypes.MAP_FETCH_BOUNDARY_API_SUCCESS;
  payload: MapPolygonWithBoundary;
};

export type MapFetchBoundaryFailAction = {
  type: MapActionTypes.MAP_FETCH_BOUNDARY_API_FAIL;
  payload: GlobalApiFailError;
};

export type MapUpdateSelectedPlaceAction = {
  type: MapActionTypes.MAP_UPDATE_SELECTED_PLACE;
  payload: SelectPlacePayload;
};

export type SelectDrawToolAction = {
  type: MapActionTypes.SELECT_DRAW_TOOL;
  payload: DrawTools | null;
};

export type MapCircle = {
  center: Coords;
  radius: number;
};

export type MapShapes = {
  circle?: MapCircle;
  polygon?: MapPolygon;
  boundaries?: MapPolygon[];
  corridor?: MapCorridor;
};

export type MapAddShapeAction = {
  type: MapActionTypes.MAP_ADD_SHAPE;
  payload: MapShapes;
  contextIgnore?: boolean;
};

export type MapCircleMoveEndAction = {
  type: MapActionTypes.MAP_CIRCLE_MOVE_END;
  payload: MapCircle;
};

export type MapPolygonEditAction = {
  type: MapActionTypes.MAP_POLYGON_EDIT;
  payload: MapPolygon;
};

export type MapResetAction = {
  type: MapActionTypes.MAP_RESET;
};

export type MapInitSetAction = {
  type: MapActionTypes.MAP_INIT_SET;
  payload: any;
};

export type MapActions =
  | MapFetchBoundaryAction
  | MapFetchBoundarySuccessAction
  | MapFetchBoundaryFailAction
  | MapFetchSuggestedPlacesAction
  | MapApiSuccessAction
  | MapApiFailAction
  | MapUpdateSelectedPlaceAction
  | MapGetGeoCodeInfoAction
  | MapResetAction
  | MapInitSetAction
  | SelectDrawToolAction
  | MapAddShapeAction
  | MapCircleMoveEndAction
  | MapPolygonEditAction;

export const mapErrorStatus = (
  status: google.maps.places.PlacesServiceStatus | google.maps.GeocoderStatus,
) => {
  const mapErrorCodes = new Map([
    ['INVALID_REQUEST', { errorCode: 500, error: 'This request was invalid.' }],
    [
      'NOT_FOUND',
      { errorCode: 404, error: 'The place referenced was not found.' },
    ],
    [
      'OVER_QUERY_LIMIT',
      {
        errorCode: 500,
        error: 'The application has gone over its request quota.',
      },
    ],
    [
      'REQUEST_DENIED',
      {
        errorCode: 500,
        error: 'The application is not allowed to use the PlacesService.',
      },
    ],
    [
      'UNKNOWN_ERROR',
      {
        errorCode: 500,
        error:
          'The PlacesService request could not be processed due to a server error. The request may succeed if you try again.',
      },
    ],
  ]);

  return mapErrorCodes.get(status);
};
