import React from 'react';
import { components } from 'react-select';
import SelectSearchShowMoreButton from './SelectSearchShowMoreButton';

const MenuList: React.FC<any> = (props: any) => {
  const { children } = props;

  return (
    <components.MenuList {...props}>
      {React.Children.toArray(children).map((child: any) => {
        const canShowMoreResults = child.props.data?.canShowMoreResults;

        return canShowMoreResults ? (
          <div key={child.key}>
            {child}
            <SelectSearchShowMoreButton
              onClick={child.props.data?.onShowMoreResults}
            />
          </div>
        ) : (
          child
        );
      })}
    </components.MenuList>
  );
};

export default MenuList;
