export interface EpcCertificatesPanelProps {
  onPanelToggleCb?: (anchorId: string) => void;
}

export enum EpcCertificateType {
  NonDomestic = 'nonDomestic',
  Display = 'display',
}

export type EpcCertificate = {
  buildingsEpcCertificatesId: string;
  lmkKey: string;
  buildingUprn: string;
  certificateType: EpcCertificateType;
  address?: string;
  floorArea?: number;
  assetRatingNumber?: number;
  assetRatingBand?: string;
  assessmentDate?: string;
  expiryDate?: string;
  mainHeatingFuel?: string;
  transactionType?: string;
  assessmentLevel?: number;
  buildingEmissions?: number;
  operationalRatingNumber?: number;
  operationalRatingBand?: string;
  electricCo2?: number;
  heatingCo2?: number;
  renewablesCo2?: number;
  yr1ElectricityCo2?: number;
  yr1HeatingCo2?: number;
  yr1RenewablesCo2?: number;
  lodgementDate?: string;
  annualThermalFuelUsage?: number;
  typicalThermalFuelUsage?: number;
  buildingId: string;
  isSuperseded: boolean;
};
