import { Reducer } from 'redux';
import { AuthActionTypes, AuthenticationTypes } from 'store/actions/types';
import { FeatureFlagState } from './types';
import { ensureFeaturesValid } from './util';

export const initialState: FeatureFlagState = {};

const featureFlagReducer: Reducer<FeatureFlagState, AuthenticationTypes> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return ensureFeaturesValid(action.payload.state.features);
    }
    default:
      return state;
  }
};

export default featureFlagReducer;
