import React from 'react';
import styled from 'styled-components/macro';

import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';

export const Header = styled.header`
  padding: 6px 15px;
`;

export const SourceHeader = styled(Header)`
  padding: 6px 0 6px 15px;
  display: flex;
  justify-content: flex-end;

  select {
    max-width: 200px;
  }

  div {
    border: 0 !important;
    margin: 0;
  }

  label {
    font-size: 10px;
    display: block;
    padding: 2px 15px;
  }
`;

export const ViewHeader = styled(Header)`
  background: ${(props) => props.theme.colorPalette.panel.default};
  padding: 15px;
  font-size: ${(props) => props.theme.typography.paragraph.default};
  display: flex;
`;

export const ViewHeading = styled.h4`
  margin: 0 ${(props) => props.theme.spacing.default} 0 0;
`;

export const DataSourcesContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  width: 100%;
  border-radius: 3px;
  font-family: ${(props) => props.theme.fonts.secondary};
  margin-top: ${(props) => props.theme.spacing.default};
`;

const getTitle = (color: string, showTitle?: boolean) => {
  if (showTitle) {
    return '0';
  }

  return `2px solid ${color}`;
};

export const GroupRowSet = styled.div<{ showTitle: boolean }>`
  border-bottom: ${(props) =>
    getTitle(props.theme.colorPalette.secondary.lightGrey, props.showTitle)};
`;

export const DataPointRow = styled.div`
  display: flex;
  padding: 2px 0 2px ${(props) => props.theme.spacing.small};
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
  justify-content: space-between;
`;

export const DataPointSet = styled.ul`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const DataPointLabel = styled.li`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  flex: 0 1 auto;
  min-width: 150px;
  padding: 0 ${(props) => props.theme.spacing.small} 0 0;
`;

export const DataPointValue = styled.span`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: 700;
  display: flex;
  padding-right: 25px;
  > span {
    margin-top: 2px;
  }
`;

export const DataPointSource = styled.div`
  flex: 0 1 200px;

  div {
    border: 0 !important;
    margin: 0;
  }
`;

export const GroupRowMultilineTitle = styled(DataPointRow)`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: 700;
`;

const EditStyled = withIconStyles(Edit);

export const EditIcon = () => <EditStyled />;

export const EditButton = styled.button`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-weight: 700;
  position: relative;
  padding: 5px 0 2px 25px;
  line-height: 1;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;
