import styled from 'styled-components/macro';

import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export const StyledCloseIcon = styled(withIconStyles(CloseIcon))`
  color: ${(props) => props.theme.colorPalette.branding.white};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-in-out;
`;

export const ButtonWrapper = styled.button`
  background: ${(props) => props.theme.colorPalette.branding.ink};
  height: 40px;
  width: 40px;
  padding: 0;
  position: relative;

  &:hover svg,
  &:focus svg {
    transform: matrix(1.2, 0, 0, 1.2, -14, -14);
  }
`;
