import { GlobalApiFailError } from 'store/actions/types';

export enum PortfolioNameSelectActionTypes {
  GET_PORTFOLIO_NAMES = 'GET_PORTFOLIO_NAMES',
  PORTFOLIO_NAME_SELECT_QUERY = 'PORTFOLIO_NAME_SELECT_QUERY',
  PORTFOLIO_NAME_SELECT_API_SUCCESS = 'PORTFOLIO_NAME_SELECT_API_SUCCESS',
  PORTFOLIO_NAME_SELECT_API_FAIL = 'PORTFOLIO_NAME_SELECT_API_FAIL',
  PORTFOLIO_NAME_SELECT_RESET = 'PORTFOLIO_NAME_SELECT_RESET',
  PORTFOLIO_NAME_SELECT_CLEAR_SELECTED = 'PORTFOLIO_NAME_SELECT_CLEAR_SELECTED',
  PORTFOLIO_NAME_SELECT_FILTERED_SUCCESS = 'PORTFOLIO_NAME_SELECT_FILTERED_SUCCESS',
}

export type GetPortfolioNamesAction = {
  type: PortfolioNameSelectActionTypes.GET_PORTFOLIO_NAMES;
};

export type PortfolioNameSelectQueryAction = {
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_QUERY;
  payload: string;
};

export type PortfolioNameSelectApiSuccessAction = {
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_API_SUCCESS;
  payload: string[];
};

export type PortfolioNameFilteredSuccessAction = {
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_FILTERED_SUCCESS;
  payload: string[];
};

export type PortfolioNameSelectApiFailAction = {
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_API_FAIL;
  payload: GlobalApiFailError;
};

export type PortfolioNameSelectResetAction = {
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_RESET;
};

export type PortfolioNameSelectClearSelectedAction = {
  type: PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_CLEAR_SELECTED;
};

export type PortfolioNameSelectActions =
  | GetPortfolioNamesAction
  | PortfolioNameFilteredSuccessAction
  | PortfolioNameSelectApiSuccessAction
  | PortfolioNameSelectApiFailAction
  | PortfolioNameSelectQueryAction
  | PortfolioNameSelectResetAction
  | PortfolioNameSelectClearSelectedAction;
