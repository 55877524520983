import {
  QuickSearchResult,
  QuickSearchActions,
  QuickSearchActionTypes,
  QuickSearchResultMeta,
} from 'connected/QuickSearch/types';

export interface QuickSearchState {
  isFetching: boolean;
  results: QuickSearchResult[];
  meta: QuickSearchResultMeta;
  searchTerm: string;
  vertical: string;
  error?: string;
}

export const initialState: QuickSearchState = {
  isFetching: false,
  results: [],
  searchTerm: '',
  vertical: 'building',
  meta: { page: 0, pages: 0, pageSize: 0 },
};

const quickSearchReducer = (
  state = initialState,
  action: QuickSearchActions,
) => {
  switch (action.type) {
    case QuickSearchActionTypes.QUICK_SEARCH_QUERY: {
      const [searchTerm, vertical] = action.payload.keyword;

      return {
        ...state,
        isFetching: searchTerm.length >= 2,
        searchTerm,
        vertical,
      };
    }

    case QuickSearchActionTypes.QUICK_SEARCH_API_SUCCESS: {
      const { results, meta } = action.payload;
      const resultsToSet =
        meta.page > 0 ? [...state.results, ...results] : results;
      return {
        ...state,
        results: resultsToSet,
        isFetching: false,
        meta: action.payload.meta,
      };
    }

    case QuickSearchActionTypes.QUICK_SEARCH_API_FAIL: {
      return {
        ...state,
        isFetching: false,
        results: [],
        error: action.payload.error,
      };
    }

    case QuickSearchActionTypes.QUICK_SEARCH_RESET: {
      return {
        ...state,
        isFetching: false,
        searchTerm: '',
        results: [],
      };
    }

    case QuickSearchActionTypes.QUICK_SEARCH_TOGGLE_VERTICAL: {
      return {
        ...initialState,
        vertical: action.payload,
      };
    }

    case QuickSearchActionTypes.QUICK_SEARCH_SET_VERTICAL: {
      return {
        ...state,
        vertical: action.payload,
        isFetching: false,
        results: [],
      };
    }

    default:
      return state;
  }
};

export default quickSearchReducer;
