import { BuildingRecord } from 'pages/Details/types';
import { GlobalApiFailError } from 'store/actions/types';

type FormPayloadValue<T> = { value: T; subSource?: string };

export type EntityNewLease = {
  leaseId: string;
  relatedBuildings: Pick<BuildingRecord, 'buildingId'>[];
  leaseStatus: FormPayloadValue<'let' | 'to-let' | 'under-offer'>;
};

export enum NewLeaseActionTypes {
  NEW_LEASE_SAVE_LEASE = 'NEW_LEASE_SAVE_LEASE',
  NEW_LEASE_API_SUCCESS = 'NEW_LEASE_API_SUCCESS',
  NEW_LEASE_API_FAIL = 'NEW_LEASE_API_FAIL',
  NEW_LEASE_RESET = 'NEW_LEASE_RESET',
}

export enum INFORMATIONAL_PANEL_TYPES {
  CURRENT_LANDLORD = 'CURRENT_LANDLORD',
  MULTI_LEASE_WARNING = 'MULTI_LEASE_WARNING',
}

export type NewLeaseSaveAction = {
  type: NewLeaseActionTypes.NEW_LEASE_SAVE_LEASE;
  payload: EntityNewLease;
};

export type NewLeaseApiSuccessAction = {
  type: NewLeaseActionTypes.NEW_LEASE_API_SUCCESS;
};

export type NewLeaseApiFailAction = {
  type: NewLeaseActionTypes.NEW_LEASE_API_FAIL;
  payload: GlobalApiFailError;
};

export type NewLeaseResetAction = {
  type: NewLeaseActionTypes.NEW_LEASE_RESET;
};

export type NewLeaseActions =
  | NewLeaseSaveAction
  | NewLeaseApiSuccessAction
  | NewLeaseResetAction
  | NewLeaseApiFailAction;
