import styled, { css } from 'styled-components/macro';
import { PanelTheme } from 'components/PanelWrapper/types';
import { Link } from 'react-router-dom';
import withIconStyles from 'components/Icon/Icon';
import { InfoIcon } from 'components/Icon/Icon.stories';

interface SummaryDataItemProps {
  useTheme: PanelTheme;
  isWide: boolean;
  isExtraWide: boolean;
}

export const SummaryPanelContent = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: ${(props) => props.theme.spacing.small};
`;

export const GalleryContainer = styled.div`
  grid-column: 1 / -1;
  padding-top: ${(props) => props.theme.spacing.small};
`;

export const MapPlaceholder = styled.div`
  grid-column: 8 / 13;
`;

export const SummaryWrapper = styled.div<{ fullWidth: boolean }>`
  width: 100%;
  grid-column: ${(props) => (props.fullWidth ? '1/13' : '1/8')};
`;

export const MapAndSavillsMapsLinkContainer = styled.div`
  height: 100%;
  padding-bottom: 40px;
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    padding-bottom: 0;
  }
`;
export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    height: 487px;
  }
`;

export const CanonicalNotificationPanel = styled.div`
  margin-right: ${(props) => props.theme.spacing.small};
`;

export const SummaryGroupDataPoints = styled.ul<{ fullWidth: boolean }>`
  grid-column: ${(props) => (props.fullWidth ? '1/13' : '1/8')};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.tablet};

  @media (min-width: ${(props) => props.theme.breakpoints.xlarge}px) {
    grid-template-columns: ${(props) =>
      props.fullWidth
        ? 'repeat(5, minmax(120px, 1fr))'
        : 'repeat(4, minmax(120px, 1fr))'};
  }
`;

const getWideStyles = (isWide?: boolean, isExtraWide?: boolean) => {
  if (isWide) {
    return css`
      grid-column: 2 span;
    `;
  }

  if (isExtraWide) {
    return css`
      grid-column: 4 span;
    `;
  }

  return ``;
};

export const SummaryDataItem = styled.li<SummaryDataItemProps>`
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.colorPalette.panel[props.useTheme]};

  background: ${(props) => props.theme.colorPalette.panel[props.useTheme]};
  padding: 8px;
  ${(props) => getWideStyles(props.isWide, props.isExtraWide)};
`;

export const SummaryDataPointTitle = styled.h4`
  margin: 0;
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  text-transform: uppercase;
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 300;
  letter-spacing: 0.3px;
  margin-bottom: 3px;
`;

export const SummaryDataPointValue = styled.span`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  white-space: pre-line;
`;

export const SummaryCellItemLink = styled(Link)`
  text-decoration: underline;
  color: ${(props) => props.theme.colorPalette.text.dark};
  display: block;
  padding: 0 0 ${(props) => props.theme.spacing.xsmall} 0;
`;

const getInCellStyles = (isInCell: boolean) => {
  if (isInCell) {
    return '0';
  }
  return 'auto';
};

export const InlineIcon = styled.span<{ isInCell: boolean }>`
  position: absolute;
  margin-top: -4px;
  right: ${(props) => getInCellStyles(props.isInCell)};

  svg {
    height: 20px;
  }
`;

export const CalculatedInlineIcon = styled(InlineIcon)`
  margin-top: -2px;
  svg {
    height: 16px;
  }

  > button {
    padding: 0;
  }
`;

export const StyledInfoIcon = styled(withIconStyles(InfoIcon))``;
