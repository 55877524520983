import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { mapFloorPartition } from 'connected/LeasesPanel/helpers';
import { BuildingAddress, LeaseRecord } from 'pages/Details/types';
import { getBuildingAddress } from 'services/helpers';
import { RootState } from 'store/rootReducer';
import { getBuildingPageData } from 'store/selectors/detailsSelectors';
import { BoldEmphasized } from 'styled/Global';
import { LeaseStatusEnum } from 'constants/leases';
import { FormStateContext } from '../context';
import {
  ConfirmParagraph,
  ConfirmListStyled,
  ConfirmListItemStyled,
} from './ConfirmChangesStyled';
import { IDENTIFY_ADDRESSES_COPY } from './constants';
import { SelectedLease } from '../IdentifyLeases/IdentifyLeases';

const MoveRecordConfirm: React.FC = () => {
  const buildingData = useSelector(getBuildingPageData);
  const contextFormState = useContext(FormStateContext);
  const {
    relatedRecords,
  } = contextFormState.formState.multiDeal.identifyLeases;

  const records = useSelector(
    (state: RootState) => state.details.building?.leases,
  );

  const selectedLeasesIds = contextFormState.formState.multiDeal.identifyLeases.value.map(
    (l: SelectedLease) => l.id,
  );
  const leasesToMove: LeaseRecord[] = [];
  const leasesToRemain: LeaseRecord[] = [];

  records?.map((lease: LeaseRecord) => {
    if (selectedLeasesIds?.includes(lease.leaseId)) {
      return leasesToMove.push(lease);
    }
    return false;
  });

  relatedRecords?.map((lease: LeaseRecord) => {
    if (!selectedLeasesIds?.includes(lease.leaseId)) {
      return leasesToRemain.push(lease);
    }
    return false;
  });

  let address = '';

  if (buildingData && buildingData.headerAddress) {
    address = getBuildingAddress(buildingData.headerAddress as BuildingAddress);
  }
  return (
    <>
      <ConfirmParagraph>
        {leasesToMove[0].availabilityStatus === LeaseStatusEnum.ToLet
          ? IDENTIFY_ADDRESSES_COPY.confirm.moveToLet
          : IDENTIFY_ADDRESSES_COPY.confirm.moveLeases}
        <BoldEmphasized>
          {contextFormState.formState.moveRecord.identifyAddress.value
            .address ||
            contextFormState.formState.moveRecord.identifyAddress.value
              .buildingAddress}
        </BoldEmphasized>
      </ConfirmParagraph>
      <ConfirmListStyled>
        {leasesToMove.map(
          (lease) =>
            lease.availabilityStatus !== LeaseStatusEnum.ToLet && (
              <ConfirmListItemStyled>
                {(lease.floors && mapFloorPartition(lease.floors)) || ''}
              </ConfirmListItemStyled>
            ),
        )}
      </ConfirmListStyled>

      {leasesToRemain.length > 0 && (
        <>
          <ConfirmParagraph>
            {IDENTIFY_ADDRESSES_COPY.confirm.remainLeases}
            <BoldEmphasized>{address}</BoldEmphasized>
          </ConfirmParagraph>
          <ConfirmListStyled>
            {leasesToRemain.map((lease) => (
              <ConfirmListItemStyled>
                {(lease.floors && mapFloorPartition(lease.floors)) || ''}
              </ConfirmListItemStyled>
            ))}
          </ConfirmListStyled>
        </>
      )}
    </>
  );
};

export default MoveRecordConfirm;
