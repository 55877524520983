import { path, pathOr } from 'ramda';
import { ConditionalConfig } from 'connected/FormFlow/types';
import { isJsonString } from 'utils/string';

export const getItemValue = (field: any, data: any) => {
  if (field.valuePath) {
    return path(field.valuePath, data);
  }
  return null;
};

export const evaluateConditions = (
  conditions: ConditionalConfig[],
  formState: any,
) => {
  const met = conditions.filter((condition: ConditionalConfig) => {
    if (!condition.ref) {
      return true;
    }

    const formStateValue: any = pathOr(
      '',
      [...condition.ref.split('.')],
      formState,
    );

    let parsedFormState = formStateValue;

    if (formStateValue && isJsonString(formStateValue)) {
      const parsedObj = JSON.parse(formStateValue);
      parsedFormState = parsedObj.value;
    }

    return condition.inverse
      ? !condition.value.includes(parsedFormState)
      : condition.value.includes(parsedFormState);
  });

  return met.length > 0;
};

export const mapComparisonData = (
  records: any,
  config: any,
  currentSelection: any,
) => {
  return config.reduce((accum: [], configItem: any) => {
    const items = records.reduce((recordAccum: [], record: any) => {
      const itemValue: any = getItemValue(configItem, record);

      if (configItem.entityIdPath && configItem.entityNamePath) {
        const itemSubKeyValue = itemValue
          ? path(configItem.entityNamePath, itemValue.value)
          : itemValue?.value;

        return [
          ...recordAccum,
          {
            id: record.id.value,
            key: configItem.key,
            rawValue: itemSubKeyValue?.value,
            value: itemSubKeyValue?.value,
            source: itemValue?.source,
            isMostTrusted: itemValue?.isMostTrusted,
          },
        ];
      }

      const formattedValue = configItem.formatter
        ? configItem.formatter({
            ...configItem,
            value: itemValue?.value,
          })
        : itemValue?.value;

      return [
        ...recordAccum,
        {
          id: record.id.value,
          key: configItem.key,
          rawValue: itemValue?.value,
          value: formattedValue,
          source: itemValue?.source,
          isMostTrusted: itemValue?.isMostTrusted,
        },
      ];
    }, []);
    const isUnique = items.find((item: any) => item.value !== items[0].value);
    const hasDifferentOptions = items && items.length > 1;

    const meetsConditions = configItem.conditional
      ? evaluateConditions(configItem.conditional, currentSelection)
      : true;

    if (
      meetsConditions &&
      !configItem.isHidden &&
      hasDifferentOptions &&
      isUnique
    ) {
      return [
        ...accum,
        { key: configItem.apiKey, label: configItem.keyLabel, items },
      ];
    }

    return accum;
  }, []);
};

export const getConditionalChildren = (
  mapValues: any,
  key: string,
  value: any,
  comparisonGroups: any,
  selectedRecord: any,
) => {
  const hasChildren = mapValues.filter((group: any) => {
    const matchingConditionalItem = group.conditional?.filter(
      (conditionalItem: any) => conditionalItem.ref === key,
    );

    // we only want to add values that are affected by the selected item
    if (!matchingConditionalItem || matchingConditionalItem.length === 0) {
      return false;
    }
    return matchingConditionalItem?.map((item: any) =>
      item.value.includes(value),
    );
  });

  if (hasChildren && hasChildren.length > 0) {
    return hasChildren.reduce((accum: {}, child: any) => {
      const childItem = comparisonGroups.find(
        (group: any) => group.key === child.apiKey,
      );

      const itemValue: any = getItemValue(child, selectedRecord);
      const childValue = childItem?.items.find(
        (item: any) => item.isMostTrusted || item.rawValue,
      );

      if (!childValue && !itemValue) {
        return accum;
      }

      return {
        ...accum,
        [child.key]: JSON.stringify({
          value: itemValue?.value,
          recordId: childValue?.id || selectedRecord.id.value,
        }),
      };
    }, {});
  }
  return [];
};
