import React, { FunctionComponent } from 'react';
import { ErrorPillWrapper, InfoIcon } from './ErrorPillStyled';

const ErrorPill: FunctionComponent = ({ children }) => (
  <ErrorPillWrapper>
    <InfoIcon />
    {children}
  </ErrorPillWrapper>
);

export default ErrorPill;
