import styled from 'styled-components/macro';

export const ConfirmParagraph = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  margin: ${(props) => props.theme.spacing.small} 0;
`;

export const ConfirmListStyled = styled.ul`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-family: ${(props) => props.theme.fonts.secondary};
  margin: 0 0 ${(props) => props.theme.spacing.default};
`;

export const ConfirmListItemStyled = styled.li`
  padding: ${(props) => props.theme.spacing.xsmall} 0;
  font-size: ${(props) => props.theme.typography.paragraph.small};
`;
