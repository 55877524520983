/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import { Option } from 'components/Forms/types';

const BlankOption = ({
  selectedValue,
  defaultValue,
  hideBlankOption,
}: {
  selectedValue: Option;
  defaultValue: Option;
  hideBlankOption?: boolean;
}) => {
  if (hideBlankOption) return <></>;
  return (
    (selectedValue && !selectedValue.value && (
      <option key="default" value={defaultValue.value} disabled>
        {''}
      </option>
    )) || (
      <option key="default" value="">
        {''}
      </option>
    )
  );
};

export default BlankOption;
