import React from 'react';

import {
  StyledFieldErrorMessage,
  StyledFieldPlaceholderMessage,
} from './FormsStyled';
import { ValidationWrapperProps } from './types';

const FieldValidation = ({ validation, hasErrors }: ValidationWrapperProps) => (
  <>
    {!hasErrors && validation?.placeholder && (
      <StyledFieldPlaceholderMessage>
        {validation.placeholder}
      </StyledFieldPlaceholderMessage>
    )}
    {hasErrors && validation?.message && (
      <StyledFieldErrorMessage>{validation.message}</StyledFieldErrorMessage>
    )}
  </>
);

export default FieldValidation;
