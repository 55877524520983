import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

import { RootState } from 'store/rootReducer';
import { urls } from 'globalConstants';

const SearchRoute = ({ component: Page, ...rest }: RouteProps) => {
  const location = useLocation();
  const destination = location.pathname;
  const { isLoggedIn } = useSelector(
    (state: RootState) => state.authentication,
  );

  return isLoggedIn ? (
    <Route {...rest} render={(props) => (Page ? <Page {...props} /> : null)} />
  ) : (
    <Redirect
      to={`${urls.login}?destination=${destination}${location.search}`}
    />
  );
};

export default SearchRoute;
