import { Reducer } from 'redux';
import isEmpty from 'ramda/src/isEmpty';

import { SearchActions, SearchActionTypes } from 'store/actions/searchActions';
import { MapActionTypes, MapActions } from 'pages/QueryBuilder/types';
import {
  FilterPanelActionTypes,
  FilterActions,
} from 'store/actions/filterPanelActions';
import {
  FILTER_ACTION_TYPES,
  SearchQueryActions,
} from 'store/actions/searchQuery';
import { ResultView } from 'connected/SearchResults/constants';

export interface SearchResultsState {
  isBusy: boolean;
  isCountBusy: boolean;
  results: any;
  pagination: {
    total?: number;
    totalPages?: any;
  };
  mapPins: {
    isBusy: boolean;
    results: any[];
    page: number;
    totalPages?: number;
    count?: number;
  };
  resultView: ResultView;
  isInlineEditComplete: boolean;
}

export const initialState = {
  isBusy: false,
  isCountBusy: false,
  results: [],
  pagination: {
    total: 0,
    totalPages: 0,
  },
  builder: {
    isBusy: false,
  },
  mapPins: {
    isBusy: false,
    results: [],
    page: 1,
  },
  resultView: ResultView.List,
  isInlineEditComplete: false,
};

type IncomingReducerActions =
  | SearchActions
  | MapActions
  | FilterActions
  | SearchQueryActions;

const searchResultsReducer: Reducer<
  SearchResultsState,
  IncomingReducerActions
> = (state = initialState, action) => {
  switch (action.type) {
    case SearchActionTypes.FETCH_SEARCH_RESULTS: {
      return {
        ...state,
        isBusy: true,
      };
    }

    case SearchActionTypes.FETCH_SEARCH_RESULTS_API_SUCCESS: {
      return {
        ...state,
        isInlineEditComplete: action.payload.isInlineEditComplete,
        isBusy: false,
        results: action.payload.results,
        pagination: {
          total: action.payload.meta.rows,
          totalPages: action.payload.meta.pages,
        },
      };
    }

    case SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT:
    case MapActionTypes.MAP_ADD_SHAPE:
    case MapActionTypes.MAP_CIRCLE_MOVE_END:
    case MapActionTypes.MAP_POLYGON_EDIT:
    case FilterPanelActionTypes.ADD_SINGLE_FILTER:
    case FilterPanelActionTypes.REMOVE_SINGLE_FILTER:
    case FilterPanelActionTypes.REMOVE_FILTER_KEYS: {
      if ('payload' in action && isEmpty(action.payload)) return state;

      return {
        ...state,
        isBusy: true,
        isCountBusy: true,
      };
    }

    case MapActionTypes.MAP_FETCH_BOUNDARY: {
      return {
        ...state,
        isCountBusy: true,
      };
    }

    case SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT_SUCCESS: {
      return {
        ...state,
        isCountBusy: false,
        pagination: {
          ...state.pagination,
          total: action.count,
        },
      };
    }

    case SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT_FAIL: {
      return {
        ...state,
        isCountBusy: false,
      };
    }

    case MapActionTypes.SELECT_DRAW_TOOL: {
      return {
        ...state,
        isBusy: false,
      };
    }

    case FILTER_ACTION_TYPES.SET_VERTICAL: {
      return {
        ...state,
        isBusy: false,
        pagination: initialState.pagination,
      };
    }

    case SearchActionTypes.FETCH_SEARCH_RESULTS_MAP_PINS: {
      return {
        ...state,
        mapPins: {
          ...state.mapPins,
          isBusy: true,
          page: action.page,
          count: action.count,
        },
      };
    }

    case SearchActionTypes.FETCH_SEARCH_RESULTS_MAP_PINS_API_SUCCESS: {
      const mapPins = {
        ...state.mapPins,
        isBusy: false,
        totalPages: action.meta.pages,
      };

      mapPins.results =
        parseInt(action.meta.page, 10) + 1 === state.mapPins.page
          ? [...state.mapPins.results, ...action.payload]
          : action.payload;

      return {
        ...state,
        mapPins,
      };
    }

    case SearchActionTypes.RESET_MAP_VIEW_PINS: {
      return {
        ...state,
        mapPins: initialState.mapPins,
      };
    }

    case SearchActionTypes.SWITCH_RESULT_VIEW: {
      return {
        ...state,
        resultView: action.payload,
      };
    }

    case SearchActionTypes.RESET_SEARCH_RESULTS: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default searchResultsReducer;
