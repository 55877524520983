import { SortDirections, SortTypes } from 'components/Table/types';
import { Vertical } from 'globalConstants';

export enum VerticalPrimaryUse {
  All = 'all',
  Office = 'office',
  Retail = 'retail',
  Industrial = 'industrial',
}

export type LatLngQueryParam = number[] | string[];

export const DEFAULT_SORT_KEYS = {
  [Vertical.Lease]: 'leases.startDate',
  [Vertical.ToLet]: 'salesSortingAddress',
  [Vertical.Sale]: 'salesSortingDate',
  [Vertical.PortfolioSale]: 'sales.portfolioName',
  [Vertical.Building]: 'buildings.postcode',
  [Vertical.Company]: '',
};

export const DEFAULT_SORT_TYPE = {
  [Vertical.Lease]: SortTypes.date,
  [Vertical.ToLet]: SortTypes.string,
  [Vertical.Sale]: SortTypes.date,
  [Vertical.PortfolioSale]: SortTypes.string,
  [Vertical.Building]: SortTypes.string,
  [Vertical.Company]: SortTypes.string,
};

export const DEFAULT_SORT_DIRECTION = {
  [Vertical.Lease]: SortDirections.DESC,
  [Vertical.ToLet]: SortDirections.DESC,
  [Vertical.Sale]: SortDirections.DESC,
  [Vertical.PortfolioSale]: SortDirections.ASC,
  [Vertical.Building]: SortDirections.ASC,
  [Vertical.Company]: SortDirections.DESC,
};
