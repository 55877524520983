export const sortSourcesRowByGroup = (
  order: string[],
  rows: { path: string }[],
) => {
  return rows.sort((a, b) => order.indexOf(a.path) - order.indexOf(b.path));
};

export const sortSourcesRowByFields = (rows: any) => {
  return rows.reduce((accum: [], row: any) => {
    if (Array.isArray(row.value)) {
      const sortedArrayValues = row.value.map((item: any) => {
        item?.sort((a: any, b: any) => {
          const aVal = a.sourceOrder || 0;
          const bVal = b.sourceOrder || 0;

          return aVal - bVal;
        });
        return item;
      });
      return [
        ...accum,
        {
          ...row,
          value: sortedArrayValues,
        },
      ];
    }

    const sortedValues = row.value.sort(
      (a: any, b: any) => a.sourceOrder - b.sourceOrder,
    );
    return [
      ...accum,
      {
        ...row,
        value: sortedValues,
      },
    ];
  }, []);
};
