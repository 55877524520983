import { GroupProps } from '../types';

export const getLabel = (group: GroupProps, label?: string) => {
  if (label) {
    return label;
  }

  if (group.label) {
    return group.label;
  }

  return group.sourceLabel;
};

export const getId = (group: GroupProps, id?: string) => {
  if (id) {
    return id;
  }

  return group.groupId;
};
