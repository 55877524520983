import styled from 'styled-components/macro';

export const ResultCountWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing.default};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const LoadingSpinnerContainer = styled.div`
  height: 36px;
`;

export const CountText = styled.span`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${(props) => props.theme.typography.heading.medium};
  color: ${(props) => props.theme.colorPalette.text.white};
`;

export const SuffixText = styled.span`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  color: ${(props) => props.theme.colorPalette.text.white};
`;
