import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { AuthenticatedRequestObservable } from 'apis/request';
import { LoginActionTypes } from 'pages/Login/types';
import { globalError } from 'store/actions/globalError';
import { authLogoutSuccess } from 'store/actions/auth';
import { endpoints } from 'globalConstants';

type LogoutEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const logoutEpic: Epic = (
  action$,
  state$,
  { authRequest }: LogoutEpicDependencies,
) => {
  return action$.pipe(
    ofType(LoginActionTypes.LOGOUT),
    mergeMap(() =>
      authRequest(state$, {
        url: endpoints.auth.logout,
        method: 'POST',
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status !== 200) {
            throw Error('Error POST /auth/logout');
          }

          const logoutUrl = ajaxResponse.response.cognitoLogoutUrl;

          window.location.assign(logoutUrl);
          return authLogoutSuccess();
        }),
        catchError((error) => of(globalError(error))),
      ),
    ),
  );
};

export default logoutEpic;
