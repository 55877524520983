import { GlobalApiFailError } from 'store/actions/types';

import {
  RelatedPartyActionTypes,
  RelatedPartyDeleteAction,
  DeleteRelatedPartyPayload,
  RelatedPartyDeleteApiFailAction,
  RelatedPartyDeleteSuccessAction,
} from 'connected/RelatedPartiesPanel/types';

export const relatedPartyDeleteAction = (
  payload: DeleteRelatedPartyPayload,
) => {
  const action: RelatedPartyDeleteAction = {
    type: RelatedPartyActionTypes.DELETE_RELATED_PARTY,
    payload,
  };

  return action;
};

export const relatedPartyDeleteApiFail = (
  payload: GlobalApiFailError,
): RelatedPartyDeleteApiFailAction => ({
  type: RelatedPartyActionTypes.DELETE_RELATED_PARTY_API_FAIL,
  payload,
});

export const relatedPartyDeleteApiSuccess = (): RelatedPartyDeleteSuccessAction => ({
  type: RelatedPartyActionTypes.DELETE_RELATED_PARTY_API_SUCCESS,
});
