import isEmpty from 'ramda/src/isEmpty';
import { format } from 'date-fns';

import { isJsonString, convertCurrency, capitalize } from 'utils/string';
import {
  ConfigFieldType,
  Demise,
  FIELD_COMPONENTS,
  OptionProps,
  OptionSets,
} from '../types';
import { FORM_BUILDER_COPY, OPTION_SETS, STATE_FIELDS } from '../constants';

export const formatDataSourceValue = (value: any, dataSource = '') => ({
  value,
  subSource: dataSource,
});

const multiSelectComponents = [FIELD_COMPONENTS.USE_CLAUSE_SELECT];

function flatten(o: any): any {
  return (
    o &&
    Object.entries(o).reduce((accum, [key, objectValue]: [string, any]) => {
      if (!objectValue) {
        return accum;
      }

      const isMultiLineComponent =
        objectValue?.componentType === FIELD_COMPONENTS.MOVE_OTHER_LEASES ||
        objectValue?.componentType === FIELD_COMPONENTS.RENEW_OTHER_LEASES ||
        Object.prototype.hasOwnProperty.call(objectValue, 'isMultiDemiseDeal');
      const isArray = Array.isArray(objectValue?.value);
      const hasValue = Object.prototype.hasOwnProperty.call(
        objectValue,
        'value',
      );
      const hasNoValue =
        isEmpty(objectValue.value) ||
        objectValue.value === '' ||
        (!objectValue.value && objectValue.value !== 0);

      if (hasValue && hasNoValue) {
        return accum;
      }

      if (hasValue && isArray && isMultiLineComponent) {
        return {
          ...accum,
          [key]: {
            value: objectValue.value,
            subSource: objectValue.subSource,
          },
        };
      }

      if (
        hasValue &&
        isArray &&
        multiSelectComponents.includes(objectValue.componentType)
      ) {
        return {
          ...accum,
          [key]: {
            value: objectValue.value.map((arrObj: any) => arrObj.value),
            subSource: objectValue.subSource,
          },
        };
      }

      if (isArray) {
        return {
          ...accum,
          [key]: objectValue.value.map((arrObj: any) => flatten(arrObj)),
        };
      }

      if (hasValue) {
        return {
          ...accum,
          [key]: {
            value: objectValue.value,
            subSource: objectValue.subSource,
            id: objectValue.id,
          },
        };
      }

      if (typeof objectValue === 'object') {
        return {
          ...accum,
          ...flatten(objectValue),
        };
      }

      return accum;
    }, {} as any)
  );
}

export const flattenFormState = (formState: any) => flatten(formState);

/**
 * @param  {string} companyDetails - company name if manually added or JSON string of company name and companyId if Athena company or JSON string of company name and company number if CH company.
 * @param  {string} dataSource
 */
export const getCompanyDetails = (
  companyDetails: string,
  dataSource: string,
  includeSector = false,
) => {
  if (!isJsonString(companyDetails)) {
    return {
      companyName: formatDataSourceValue(companyDetails, dataSource),
      ...(includeSector && {
        sector: formatDataSourceValue(FORM_BUILDER_COPY.defaultSector),
      }),
    };
  }

  const parsed = JSON.parse(companyDetails);

  return {
    companyName: formatDataSourceValue(parsed.name, dataSource),
    ...(parsed.id && {
      companyId: formatDataSourceValue(parsed.id, dataSource),
    }),
    ...(parsed.companyRegistrationNumber && {
      ...(includeSector && {
        sector: formatDataSourceValue(FORM_BUILDER_COPY.defaultSector),
      }),
      companyRegistrationNumber: formatDataSourceValue(
        parsed.companyRegistrationNumber,
        dataSource,
      ),
    }),
  };
};

export const formatDemises = (input: Demise[]) => {
  return input.map(
    ({
      floor,
      floorId,
      partition,
      floorLevel,
      partitionId,
      totalArea,
      measurementStandard,
      ...rest
    }) => {
      let formattedPartition: any;
      const formattedFloor: any = { floorName: floor, floorLevel };

      if (partition && 'value' in partition && isJsonString(partition.value)) {
        const parsedPartition = JSON.parse(partition.value);

        formattedPartition =
          typeof parsedPartition === 'number'
            ? { partitionName: { value: `${parsedPartition}` } }
            : {
                partitionName: {
                  value: parsedPartition.partitionName,
                  subSource: partition.subSource,
                },
                partitionId: {
                  value: parsedPartition.partitionId,
                  subSource: partition.subSource,
                },
              };
      } else {
        formattedPartition = partition
          ? { partitionName: partition }
          : undefined;
      }

      if (formattedPartition) {
        if (totalArea) {
          formattedPartition.areaSqFt = totalArea;
        }

        if (measurementStandard) {
          formattedPartition.measurementStandard = measurementStandard;
        }
      }

      if (!formattedPartition) {
        if (totalArea) {
          formattedFloor.areaSqFt = totalArea;
        }

        if (measurementStandard) {
          formattedFloor.measurementStandard = measurementStandard;
        }
      }

      return {
        ...rest,
        totalArea,
        floor: formattedFloor,
        partition: formattedPartition,
        partitionId:
          formattedPartition && formattedPartition.value ? partitionId : null,
      };
    },
  );
};

/**
 * Formats the data returned from the API to the user facing format
 * @param {any} field
 * @param {ConfigFieldType} type
 * @returns {any} - formatted string depending on field type
 */
export const formatField = (field: any, type: ConfigFieldType) => {
  switch (type) {
    case ConfigFieldType.PRICE: {
      return convertCurrency(field);
    }
    case ConfigFieldType.DATE: {
      return format(new Date(field), 'dd/MM/yyyy');
    }
    case ConfigFieldType.SELLER: {
      const summaryNames = field.map((entity: any) => {
        const sellerType = entity.sellerType.value;

        if (sellerType === STATE_FIELDS.PRIVATE_INDIVIDUAL) {
          return 'Private Seller';
        }

        return isJsonString(entity.sellerCompanyName.value)
          ? JSON.parse(entity.sellerCompanyName.value)?.name
          : entity.sellerCompanyName.value;
      });

      return summaryNames.join(', ');
    }
    case ConfigFieldType.BUYER: {
      const summaryNames = field.map((entity: any) => {
        const buyerType = entity.buyerInvestorOccupier.value;

        if (buyerType === 'investor-private') {
          return 'Private Buyer';
        }

        return isJsonString(entity.buyerCompanyName.value)
          ? JSON.parse(entity.buyerCompanyName.value)?.name
          : entity.buyerCompanyName.value;
      });

      return summaryNames.join(', ');
    }
    case ConfigFieldType.COMPANY: {
      return field.map((company: { companyName: string }, i: number) => {
        const punctuation =
          field.length > 1 && i !== field.length - 1 ? ', ' : '';
        return `${company.companyName}${punctuation}`;
      });
    }
    case ConfigFieldType.SINGLE_COMPANY: {
      return field.companyName;
    }
    case ConfigFieldType.SQ_FEET: {
      return `${field.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} sq ft`;
    }
    case ConfigFieldType.FLOOR_PARTITION: {
      return field.reduce((accum: string, floor: any, i: number) => {
        const floorPartitionName = floor.partitionName
          ? `${floor.floorName} (${floor.partitionName})`
          : floor.floorName;
        if (i === field.length - 1) return `${accum}${floorPartitionName}`;
        return `${accum}${floorPartitionName}, `;
      }, '');
    }
    default: {
      if (isJsonString(field)) {
        const parsed = JSON.parse(field);
        return parsed.name;
      }

      return capitalize(`${field}`);
    }
  }
};

export const formatRecordStatus = (status: string) => {
  if (!status) return FORM_BUILDER_COPY.statusUnknown;
  return status
    .replace(/-/g, ' ')
    .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
};

export const getOptions = (options?: OptionProps[], optionSet?: OptionSets) => {
  if (!options && !optionSet) return [];
  if (optionSet) return OPTION_SETS[optionSet] as OptionProps[];

  return options;
};
