import React from 'react';
import styled, { css } from 'styled-components/macro';

import { BoldEmphasized } from 'styled/Global';
import ErrorPill from 'components/ErrorPill/ErrorPill';

import { ErrorProps } from '../types';

interface FormErrorsProps {
  viewErrors?: ErrorProps[];
  apiErrors?: string[];
  inline?: boolean;
}

const StyledErrorWrapper = styled.div<{ inline: boolean }>`
  margin: 0;
  ${(props) =>
    !props.inline &&
    css`
      margin-top: ${props.theme.spacing.small};
    `}
`;

const ErrorItem = styled.span`
  display: block;
  padding: 5px 0;
`;

const FormErrors = ({
  viewErrors,
  apiErrors,
  inline = false,
}: FormErrorsProps) => (
  <StyledErrorWrapper inline={inline}>
    <ErrorPill>
      {viewErrors?.map((error) => (
        <ErrorItem key={error.message}>
          <BoldEmphasized key={error.message}>{error.message}</BoldEmphasized>
        </ErrorItem>
      ))}
      {apiErrors?.map((error) => (
        <ErrorItem key={error}>
          <BoldEmphasized key={error}>{error}</BoldEmphasized>
        </ErrorItem>
      ))}
    </ErrorPill>
  </StyledErrorWrapper>
);

export default FormErrors;
