export const SCHEDULE_QUERY_COPY = {
  heading: 'Scheduled Query',
  addButton: 'Add Schedule',
  editButton: 'Edit Schedule',
  deleteButton: 'Remove Schedule',
  deleteQueryButton: 'Delete Query',
  deleteScheduledQuery: {
    title: 'Remove Schedule',
    deletePrompt:
      'Are you sure you want to remove this schedule? Removing the schedule will not delete the saved query it relates to.',
    confirmButton: 'Yes, remove',
    cancelButton: 'Cancel',
    deleteFailPart1: 'Sorry, the operation failed whilst trying to delete',
    deleteFailPart2: 'Please try again later.',
    closeButton: 'Close',
  },
  deleteUserQuery: {
    title: 'Delete Saved Query',
    deletePrompt:
      'Are you sure you want to permanently delete this query? Any schedule associated with the query will also be deleted.',
    confirmButton: 'Yes, delete',
    cancelButton: 'Cancel',
    deleteFailPart1: 'Sorry, the operation failed whilst trying to delete',
    deleteFailPart2: 'Please try again later.',
    closeButton: 'Close',
  },
};
