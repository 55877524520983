import React, { useState, useRef } from 'react';

import useOutsideClick from 'hooks/useOutsideClick';

import {
  ActionsContainer,
  ArrowDownIcon,
  ArrowUpIcon,
  InlineMenu,
  InlineMenuButton,
  MoreIcon,
} from './ActionsMenuStyled';

const InlineActionMenu: React.FC = ({ children }) => {
  const actionMenuRef = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  useOutsideClick(actionMenuRef, () => closeMenu(), menuIsOpen);

  return (
    <ActionsContainer ref={actionMenuRef}>
      <InlineMenuButton
        isActive={menuIsOpen}
        type="button"
        onClick={toggleMenu}
      >
        <MoreIcon />
        {menuIsOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </InlineMenuButton>

      {menuIsOpen && (
        <InlineMenu>
          <ul>{children}</ul>
        </InlineMenu>
      )}
    </ActionsContainer>
  );
};

export default InlineActionMenu;
