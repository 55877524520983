import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import omit from 'ramda/src/omit';

import { FilterDefinitions } from 'constants/filters/types';
import { permanentSearchQueryKeys } from 'store/reducers/searchQuery';
import { DownloadableVertical } from 'connected/SearchResults/types';
import { Vertical } from 'globalConstants';

export const getSearchQuery = (state: RootState) => state.searchQuery;

export const getVertical = createSelector(
  getSearchQuery,
  (searchQuery) => searchQuery.vertical,
);

export const getDownloadableVertical = createSelector(
  getSearchQuery,
  ({ vertical }): DownloadableVertical | null => {
    if (!vertical || vertical === Vertical.Company) {
      return null;
    }

    return vertical;
  },
);

export const getPrimaryUse = createSelector(
  getSearchQuery,
  (searchQuery) => searchQuery.primaryUse,
);

export const getIsPortfolioSale = createSelector(
  getSearchQuery,
  (searchquery) => searchquery['sales.isPortfolio'],
);

export const getSelectedFilters = createSelector(
  getSearchQuery,
  (searchQuery) => omit(permanentSearchQueryKeys, searchQuery),
);

export const getSelectedFiltersLength = (configs: FilterDefinitions) =>
  createSelector(getSelectedFilters, (selectedFilters) => {
    const filters = Object.keys(selectedFilters).reduce((acc, key) => {
      const foundDefinition = Object.values(configs).find((def) => {
        return 'paramKeys' in def
          ? def.paramKeys?.includes(key)
          : def.paramKey === key;
      });

      return foundDefinition && !acc.includes(foundDefinition.paramKey)
        ? [...acc, foundDefinition.paramKey]
        : acc;
    }, [] as string[]);

    return filters.length;
  });

export const getQuerySort = createSelector(getSearchQuery, (searchQuery) => ({
  key: searchQuery.sortKey,
  direction: searchQuery.sortDirection,
  type: searchQuery.sortType,
}));

export const getQueryPagination = createSelector(
  getSearchQuery,
  (searchQuery) => ({
    page: Number(searchQuery.page), // ensure the number from the url is converted from a string to a number
    limit: searchQuery.pageSize,
    label: `${searchQuery.pageSize}`,
  }),
);
