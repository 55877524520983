import { GlobalApiFailError } from 'store/actions/types';

import {
  NotesActionTypes,
  NoteDeleteAction,
  DeleteNotePayload,
  NoteDeleteApiFailAction,
  NoteDeleteSuccessAction,
} from 'connected/NotesPanel/types';

export const notesDelete = (payload: DeleteNotePayload) => {
  const action: NoteDeleteAction = {
    type: NotesActionTypes.DELETE_NOTE,
    payload,
  };

  return action;
};

export const notesDeleteApiFail = (
  payload: GlobalApiFailError,
): NoteDeleteApiFailAction => ({
  type: NotesActionTypes.DELETE_NOTE_API_FAIL,
  payload,
});

export const notesDeleteApiSuccess = (): NoteDeleteSuccessAction => ({
  type: NotesActionTypes.DELETE_NOTE_API_SUCCESS,
});
