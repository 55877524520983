import {
  LoginActionTypes,
  LoginAction,
  LogoutAction,
  LoginActionPayload,
} from 'pages/Login/types';

export const login = (payload: LoginActionPayload): LoginAction => ({
  type: LoginActionTypes.LOGIN,
  payload,
});

export const logout = (): LogoutAction => ({
  type: LoginActionTypes.LOGOUT,
});
