import {
  MapCorridor,
  MapPolygon,
  GoogleCoordLatLngFn,
} from 'components/GoogleMap/types';
import { metersToMiles } from 'components/GoogleMap/utils/general';
import { MapCircle } from 'pages/QueryBuilder/types';

type CoordNumbers = {
  lat: number;
  lng: number;
};

const mapCoordToCoordString = (coord: CoordNumbers) => {
  return `${coord.lat.toFixed(6)}|${coord.lng.toFixed(6)}`;
};

const mapCoordFnToCoordString = (coord: GoogleCoordLatLngFn) => {
  return `${coord.lat().toFixed(6)}|${coord.lng().toFixed(6)}`;
};

export const constructLocationStateFromCircle = (circle: MapCircle) => ({
  location: [circle.center.latitude, circle.center.longitude],
  locationRadius: metersToMiles(circle.radius).toFixed(5),
});

export const constructLocationStateFromPolygon = (polygon: MapPolygon) => {
  const location = [polygon.center.lat, polygon.center.lng];
  const locationPath = polygon.path.map(mapCoordFnToCoordString);
  const lastPoint = locationPath[locationPath.length - 1];

  if (locationPath[0] !== lastPoint) {
    locationPath.push(locationPath[0]);
  }

  return {
    location,
    locationPath,
  };
};

export const constructLocationStateFromCorridor = (corridor: MapCorridor) => {
  const location = [corridor.center.lat, corridor.center.lng];
  const locationPath = (corridor.path as CoordNumbers[]).map(
    mapCoordToCoordString,
  );
  const locationPolyline =
    typeof corridor.polyline[0].lat === 'number'
      ? (corridor.polyline as CoordNumbers[]).map(mapCoordToCoordString)
      : (corridor.polyline as GoogleCoordLatLngFn[]).map(
          mapCoordFnToCoordString,
        );

  const locationBuffer = corridor.distance;

  return {
    location,
    locationPath,
    locationPolyline,
    locationBuffer,
  };
};
