import styled from 'styled-components/macro';

export const QuickSearchResultsContainer = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
`;

export const QuickSearchResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.small} 0;

  button {
    padding-right: 0;
  }
`;

export const ResultsSummaryContainer = styled.p`
  margin: 0;
`;

export const ResultsUnorderList = styled.ul`
  &:hover,
  &:focus-within {
    button:enabled,
    a {
      opacity: 0.4;

      &:hover,
      &:focus,
      &:focus-within {
        opacity: 1;
        border: 1px solid
          ${(props) => props.theme.colorPalette.secondary.lightInk};
      }
    }
  }
`;

export const QuickSearchResultsLoaderWrapper = styled.div`
  margin: -5px 1px 0;
  border-radius: 3px 0;
`;
