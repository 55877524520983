import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';

import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import { PortfolioNameSelectActionTypes } from 'connected/PortfolioNameSelect/types';
import {
  portfolioNameApiFail,
  portfolioNameApiSuccess,
} from 'store/actions/portfolioNameSelectActions';

type GetPortfolioNamesEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const getPortfolioNamesEpic: Epic = (
  action$,
  state$,
  { authRequest }: GetPortfolioNamesEpicDependencies,
) =>
  action$.pipe(
    ofType(PortfolioNameSelectActionTypes.GET_PORTFOLIO_NAMES),
    mergeMap(() => {
      const url = endpoints.portfolioNames;

      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status !== 200) {
            throw new Error(`error loading ${url}`);
          }
          return portfolioNameApiSuccess(ajaxResponse.response);
        }),
      );
    }),
    catchError((error) => of(portfolioNameApiFail(error))),
  );

export default getPortfolioNamesEpic;
