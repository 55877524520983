import { GlobalApiFailError } from 'store/actions/types';

import {
  ValuationsActionTypes,
  ValuationDeleteAction,
  DeleteValuationPayload,
  ValuationDeleteApiFailAction,
  ValuationDeleteSuccessAction,
} from 'connected/ValuationPanel/types';

export const valuationsDelete = (payload: DeleteValuationPayload) => {
  const action: ValuationDeleteAction = {
    type: ValuationsActionTypes.DELETE_VALUATION,
    payload,
  };

  return action;
};

export const valuationsDeleteApiFail = (
  payload: GlobalApiFailError,
): ValuationDeleteApiFailAction => ({
  type: ValuationsActionTypes.DELETE_VALUATION_API_FAIL,
  payload,
});

export const valuationsDeleteApiSuccess = (): ValuationDeleteSuccessAction => ({
  type: ValuationsActionTypes.DELETE_VALUATION_API_SUCCESS,
});
