import {
  FetchUserProfileAction,
  FetchSuccessActionType,
  FetchFailActionType,
  UserProfileActions,
  UserScheduledQueriesPayloadType,
} from 'store/actions/userProfileActions';
import { UserQueryPayloadType } from 'store/actions/userQueriesActions';

export enum WatchedRecordTypes {
  BUILDING = 'Building',
  COMPANY = 'Company',
}
export interface WatchedRecordPayloadType {
  userId: string;
  watchId: string;
  entityId: string;
  entityType: WatchedRecordTypes;
  label: string;
}

export type UserProfilePayload = {
  isFetching: boolean;
  hasError: boolean;
  error?: string;
  userQueries: UserQueryPayloadType[];
  scheduledQueries: UserScheduledQueriesPayloadType[];
  watchedRecords: WatchedRecordPayloadType[];
};

export const initialState: UserProfilePayload = {
  isFetching: false,
  hasError: false,
  error: '',
  userQueries: [],
  scheduledQueries: [],
  watchedRecords: [],
};

const userProfileReducer = (
  state = initialState,
  action: FetchUserProfileAction | FetchSuccessActionType | FetchFailActionType,
) => {
  switch (action.type) {
    case UserProfileActions.FETCH_USER_PROFILE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UserProfileActions.FETCH_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        userQueries: action.payload.userQueries,
        scheduledQueries: action.payload.scheduledQueries,
        watchedRecords: action.payload.watchedRecords,
      };
    }

    default:
      return state;
  }
};

export default userProfileReducer;
