import styled from 'styled-components/macro';

import withIconStyles from 'components/Icon/Icon';

import { ReactComponent as Info } from 'assets/icons/info.svg';

export const InfoIcon = withIconStyles(Info);

export const ErrorPillWrapper = styled.div`
  background: ${(props) => props.theme.colorPalette.tertiary.datavizPurple};
  padding: 12px 12px 12px 36px;
  border-radius: 3px;
  color: ${(props) => props.theme.colorPalette.text.white};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
`;
