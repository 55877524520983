import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as AddIcon } from 'assets/icons/add-circle-outline.svg';
import Select from 'components/Forms/Inputs/Select/Select';
import { Option } from 'components/Forms/types';
import { FORM_BUILDER_COPY } from '../constants';

const createLinesToInsertOptions = () => {
  const options: Option[] = [];

  for (let i = 1; i <= 10; i += 1) {
    options.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  return options;
};
const insertOptions = createLinesToInsertOptions();

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;

  select {
    padding: ${(props) => `2px ${props.theme.spacing.default} 0 10px`};
    min-width: 60px;
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  width: 18px;
  height: 18px;
`;

export const AddBatchLabel = styled.span`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  margin: 0 10px 0 0;
  font-weight: 700;
`;

const AddButton = styled.button`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  color: ${(props) => props.theme.colorPalette.text.white};
  padding: 8px 12px;
  background: ${(props) => props.theme.colorPalette.branding.ink};
  margin-left: 10px;
`;

const INITIAL_LINES_STATE = {
  value: '1',
  label: '1',
};

interface BatchControlProps {
  addBatchLabel?: string[];
  addButton?: string;
  onInsertRows: (q: number) => void;
}

const BatchControl = ({
  addBatchLabel = ['Add lines:'],
  addButton = 'Insert',
  onInsertRows,
}: BatchControlProps) => {
  const [linesToInsert, setLinesToInsert] = useState(INITIAL_LINES_STATE);

  const onClickAddRows = () => {
    const quantity = parseInt(linesToInsert.value, 10);
    onInsertRows(quantity);
    setLinesToInsert(INITIAL_LINES_STATE);
  };

  return (
    <ControlContainer>
      <StyledAddIcon />
      <AddBatchLabel>{addBatchLabel[0]}</AddBatchLabel>
      <Select
        options={insertOptions}
        name="addLines"
        label={FORM_BUILDER_COPY.multiline.batch.label}
        labelIsHidden
        onSelectChange={(option) => setLinesToInsert(option)}
        selectedValue={linesToInsert}
      />
      <AddButton type="button" onClick={onClickAddRows}>
        {addButton}
      </AddButton>
    </ControlContainer>
  );
};

export default BatchControl;
