import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'ramda/src/isEmpty';
import { v4 as uuid } from 'uuid';

import { Vertical } from 'globalConstants';
import { editRecordSave } from 'store/actions/editRecordActions';
import { globalModalHide } from 'store/actions/globalModalActions';
import { FORM_FLOWS } from 'connected/FormFlow/constants';
import { FIELD_COMPONENTS, FormFlowConfigs } from 'connected/FormFlow/types';
import { payloadTransformForUpdates } from 'pages/NewSale/NewSaleForm/payloadTransform';
import { getEditHoldingEntities } from 'store/selectors/buyersSellersSelectors';
import { RootState } from 'store/rootReducer';
import { EDIT_SPV_COPY } from '../constants';
import EditRecordForm from '../EditRecordForm';
import configs from './editSPV.json';

type EditSPVModalProps = {
  recordId: string;
};

type SPVEditPayload = {
  saleId: string;
  holdingEntitySPV?: {
    value: string;
    subSource?: string;
  };
  holdingEntity?: {
    value: string;
    subSource?: string;
  }[];
};

const preloadedFormState: any = {
  identifyHoldingEntity: {
    holdingEntityDetails: {
      componentType: 'MultilineWithGroupedRepeat',
    },
  },
  specifyDataSources: [],
};

const EditSPVModal: React.FC<EditSPVModalProps> = ({ recordId }) => {
  const dispatch = useDispatch();
  const building = useSelector(
    (state: RootState) => state.details.building?.summary,
  );
  const existingHoldingEntities = useSelector(getEditHoldingEntities);

  const initialFormState = useMemo(() => {
    const updatedState = { ...preloadedFormState };

    if (existingHoldingEntities.length > 0) {
      updatedState.identifyHoldingEntity.holdingEntitySPV = {
        componentType: FIELD_COMPONENTS.COMPANY_SELECT,
        label: EDIT_SPV_COPY.label,
        value: EDIT_SPV_COPY.values.yes,
      };
    } else {
      updatedState.identifyHoldingEntity.holdingEntitySPV = {
        componentType: FIELD_COMPONENTS.COMPANY_SELECT,
        label: EDIT_SPV_COPY.label,
        value: EDIT_SPV_COPY.values.no,
      };
    }
    updatedState.identifyHoldingEntity.holdingEntityDetails.value = isEmpty(
      existingHoldingEntities,
    )
      ? [
          {
            index: 0,
            id: uuid(),
          },
        ]
      : existingHoldingEntities;

    return updatedState;
  }, [existingHoldingEntities]);

  const constructFormPayload = (formState: any) => {
    const payload: SPVEditPayload = {
      ...payloadTransformForUpdates(formState),
      saleId: recordId,
    };

    if (payload?.holdingEntitySPV?.value === 'no') {
      payload.holdingEntity = [];

      delete payload.holdingEntitySPV;
    }

    dispatch(
      editRecordSave({
        payload,
        vertical: Vertical.Sale,
      }),
    );
    dispatch(globalModalHide(FORM_FLOWS.EDIT_SPV));
  };

  return (
    <EditRecordForm
      recordId={recordId}
      copy={EDIT_SPV_COPY}
      onSubmission={constructFormPayload}
      formId={FORM_FLOWS.EDIT_SPV}
      configs={configs as FormFlowConfigs}
      initialFormState={initialFormState}
      building={building}
    />
  );
};

export default EditSPVModal;
