import { ajax, AjaxRequest } from 'rxjs/ajax';
import { StateObservable } from 'redux-observable';

import { RootState } from 'store/rootReducer';

interface AdditionalRequestHeaders {
  contentType?: string;
}

export const authenticatedRequest = (
  state$: StateObservable<RootState>,
  options: AjaxRequest & AdditionalRequestHeaders,
) => () => {
  return ajax({
    ...options,
    withCredentials: true,
    headers: {
      'Content-Type': options.contentType || 'application/json',
    },
  });
};

export type AuthenticatedRequestObservable = typeof authenticatedRequest;

export const unauthenticatedRequest = ajax;
export type UnauthenticatedRequestObservable = typeof unauthenticatedRequest;
