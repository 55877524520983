import { GlobalApiFailError } from 'store/actions/types';

export enum RelatedPartyActionTypes {
  DELETE_RELATED_PARTY = 'DELETE_RELATED_PARTY',
  DELETE_RELATED_PARTY_API_FAIL = 'DELETE_RELATED_PARTY_API_FAIL',
  DELETE_RELATED_PARTY_API_SUCCESS = 'DELETE_RELATED_PARTY_API_SUCCESS',
}

export type DeleteRelatedPartyPayload = {
  id: string;
  entity: string;
};

export type RelatedPartyDeleteAction = {
  type: RelatedPartyActionTypes.DELETE_RELATED_PARTY;
  payload: DeleteRelatedPartyPayload;
};

export type RelatedPartyDeleteApiFailAction = {
  type: RelatedPartyActionTypes.DELETE_RELATED_PARTY_API_FAIL;
  payload: GlobalApiFailError;
};

export type RelatedPartyDeleteSuccessAction = {
  type: RelatedPartyActionTypes.DELETE_RELATED_PARTY_API_SUCCESS;
};

export type RelatedPartyActions =
  | RelatedPartyDeleteAction
  | RelatedPartyDeleteApiFailAction
  | RelatedPartyDeleteSuccessAction;
