import React, { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import FileInput from 'components/Forms/Inputs/FileInput/FileInput';
import { BoldEmphasized } from 'styled/Global';
import ErrorPill from 'components/ErrorPill/ErrorPill';
import {
  startFileUpload,
  cancelFileUpload,
  resetFileUpload,
} from 'store/actions/documentUploaderActions';
import { globalModalHide } from 'store/actions/globalModalActions';
import { getCurrentModalSelector } from 'store/selectors/globalModalSelectors';
import {
  Button,
  TertiaryActionButton,
} from 'components/CallToActions/Button/Button';
import { dynamicString } from 'utils/string';
import FileItem from './FileItem/FileItem';
import FileItemWithProgress from './FileItemWithProgress/FileItemWithProgress';
import {
  UploadWrapper,
  UploadCompleteWrapper,
  UploadModalActions,
  ErrorWrapper,
  UploadFinishedIconWrapper,
  DoneIcon,
  ErrorIcon,
} from './DocumentUploaderStyled';
import { DOCUMENT_UPLOADER_COPY, PORTFOLIO_SALE_BROCHURE } from './constants';
import {
  FileUploadType,
  DocumentUploaderProps,
  EntityDocumentUpload,
  DocumentUploaderUIStage,
} from './types';

import {
  ACCEPTED_FILE_MIME_TYPES,
  CoreEntity,
  Vertical,
} from '../../globalConstants';
import { slugify } from './slugify';
import DocumentUploaderSuccess from './DocumentUploaderSuccess';
import DocumentUploaderError from './DocumentUploaderError';
import DocumentUploaderCancelButton from './DocumentUploaderCancelButton';

const LEVERTON_MAX_FILE_SIZE_WARNING_MB = 100;

export const initialFileDetailState: File = {
  name: '',
  size: 0,
} as File;

export const initialFileTypeState: FileUploadType = {
  value: '',
  label: DOCUMENT_UPLOADER_COPY.selectLabel,
};

export function getFileExtension(fileName: string) {
  return fileName.split('.').pop()?.toLowerCase();
}

export function validateFileMimeType(fileMimeType: string) {
  return fileMimeType && ACCEPTED_FILE_MIME_TYPES.includes(fileMimeType);
}

const DocumentUploader = ({
  entityId,
  onCancelClick,
  preselectedFileType = initialFileTypeState,
  disableFileTypeSelect,
  showErrorPrompts = true,
  showUploadControls = true,
  uploaderContextInFormFlow = false,
  onDocumentUploadChange,
  additionalEntities,
}: DocumentUploaderProps) => {
  const [fileDetails, setFileDetails] = useState(initialFileDetailState);
  const [fileIsValid, setFileIsValid] = useState(true);
  const [fileSizeIsValid, setFileSizeIsValid] = useState(true);
  const [fileType, setFileType] = useState(preselectedFileType);
  const uploaderStage = useSelector(
    (state: RootState) => state.documentUploader.uploaderStage,
  );
  const uploadProgress = useSelector(
    (state: RootState) => state.documentUploader.progress,
  );
  const uploaderError = useSelector(
    (state: RootState) => state.documentUploader.error,
  );
  const fileIsDuplicateError = useSelector(
    (state: RootState) => state.documentUploader.isDuplicate,
  );
  const currentModal = useSelector(getCurrentModalSelector);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const vertical = useSelector(({ details }: RootState) => details.vertical!);
  const dispatch = useDispatch();

  function onFileInputDrop(files: File[]) {
    if (files && files.length) {
      const file = files[0];

      setFileDetails(file);

      if (!validateFileMimeType(file.type)) {
        setFileIsValid(false);
        return;
      }

      setFileIsValid(true);
    }
  }

  function onSelectFileType(type: FileUploadType) {
    setFileType(type);
    setFileSizeIsValid(
      !(
        type.value === 'lease' &&
        fileDetails.size > LEVERTON_MAX_FILE_SIZE_WARNING_MB * 1024 * 1024
      ),
    );
  }

  function removeFileItem() {
    setFileIsValid(true);
    setFileDetails(initialFileDetailState);
    setFileType(preselectedFileType);
  }

  function resetUploadComponent() {
    dispatch(resetFileUpload());
    removeFileItem();
  }

  function onUploadClick() {
    let entityType: CoreEntity;

    switch (vertical) {
      case Vertical.ToLet:
        entityType = Vertical.Lease;
        break;
      case Vertical.PortfolioSale:
        entityType = Vertical.Sale;
        break;
      default:
        entityType = vertical;
    }

    setFileSizeIsValid(true);

    // generate URL friendly file name
    const fileExtension = getFileExtension(fileDetails.name);
    const fileName = fileDetails.name.replace(`.${fileExtension}`, '');
    const urlFriendlyFileName = `${slugify(fileName)}.${fileExtension}`;

    const entityDoc: EntityDocumentUpload = {
      entityId,
      entityType,
      filename: urlFriendlyFileName,
      type: fileType.value,
      file: fileDetails,
      lastModified: new Date(fileDetails.lastModified),
    };

    dispatch(startFileUpload(entityDoc));

    // currently used when a Portfolio Sale Brochure is uploaded and we want to save it to each building too.
    if (fileType.value === PORTFOLIO_SALE_BROCHURE) {
      additionalEntities?.forEach((additionalEntity) => {
        const additionalEntityDoc: EntityDocumentUpload = {
          entityId: additionalEntity.entityId,
          entityType: additionalEntity.vertical as CoreEntity,
          filename: urlFriendlyFileName,
          type: fileType.value,
          file: fileDetails,
          lastModified: new Date(fileDetails.lastModified),
        };

        dispatch(startFileUpload(additionalEntityDoc));
      });
    }
  }

  function cancelUpload() {
    dispatch(cancelFileUpload());
  }

  function cancelUploadAndClose(event: MouseEvent<HTMLButtonElement>) {
    dispatch(cancelFileUpload());

    if (onCancelClick) {
      onCancelClick(event);
    }
  }

  useEffect(() => {
    if (onDocumentUploadChange) {
      onDocumentUploadChange({ fileDetails, fileType });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fileDetails, fileType]);

  useEffect(
    () => () => {
      resetUploadComponent();

      if (currentModal === 'fileUpload') {
        dispatch(globalModalHide('fileUpload'));
      }
    },
    [],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const uploaderCompleteContent = uploaderError ? (
    <DocumentUploaderError
      fileIsDuplicateError={fileIsDuplicateError}
      showErrorPrompts={showErrorPrompts}
      name={fileDetails.name}
    />
  ) : (
    <DocumentUploaderSuccess name={fileDetails.name} />
  );

  const uploaderCompleteButton = uploaderError ? <ErrorIcon /> : <DoneIcon />;

  const isDisabled = !fileIsValid || !fileDetails.name || !fileType.value;

  return (
    <>
      {!fileSizeIsValid && (
        <ErrorWrapper>
          <ErrorPill>
            {dynamicString(DOCUMENT_UPLOADER_COPY.levertonLargeFileWarning, [
              LEVERTON_MAX_FILE_SIZE_WARNING_MB.toString(),
            ])}
          </ErrorPill>
        </ErrorWrapper>
      )}
      {!fileIsValid && (
        <ErrorWrapper>
          <ErrorPill>
            <BoldEmphasized>{fileDetails.name}</BoldEmphasized>{' '}
            {DOCUMENT_UPLOADER_COPY.unsupportedFile}
          </ErrorPill>
        </ErrorWrapper>
      )}

      {/* STEP 1 - BEFORE_UPLOAD, Selecting file and document type */}
      {uploaderStage === DocumentUploaderUIStage.BEFORE_UPLOAD && (
        <>
          <UploadWrapper>
            {fileDetails.size && fileIsValid ? (
              <FileItem
                file={fileDetails}
                removeFileItem={removeFileItem}
                onSelectFileType={onSelectFileType}
                defaultSelectValue={fileType}
                disableFileTypeSelect={disableFileTypeSelect}
              />
            ) : (
              <FileInput
                label={DOCUMENT_UPLOADER_COPY.fileInputLabel}
                onFileDrop={onFileInputDrop}
              />
            )}
          </UploadWrapper>
          {showUploadControls && (
            <UploadModalActions uploaderStage={uploaderStage}>
              <DocumentUploaderCancelButton
                cancelUpload={cancelUploadAndClose}
              />
              <Button
                type="button"
                disabled={isDisabled}
                onClick={onUploadClick}
              >
                {DOCUMENT_UPLOADER_COPY.uploadButton}
              </Button>
            </UploadModalActions>
          )}
        </>
      )}

      {/* STEP 2 - DURING_UPLOAD, track upload progress via progress bar */}
      {uploaderStage === DocumentUploaderUIStage.DURING_UPLOAD && (
        <>
          <ul>
            <FileItemWithProgress
              file={fileDetails}
              progress={uploadProgress}
            />
          </ul>
          <UploadModalActions uploaderStage={uploaderStage}>
            <DocumentUploaderCancelButton cancelUpload={cancelUpload} />
          </UploadModalActions>
        </>
      )}

      {/* STEP 3 - AFTER_UPLOAD, Upload confirmation or fail state */}
      {uploaderStage === DocumentUploaderUIStage.AFTER_UPLOAD && (
        <>
          <UploadCompleteWrapper>
            <UploadFinishedIconWrapper error={uploaderError}>
              {uploaderCompleteButton}
            </UploadFinishedIconWrapper>
            {uploaderCompleteContent}
          </UploadCompleteWrapper>
          {showUploadControls && (
            <UploadModalActions uploaderStage={uploaderStage}>
              {uploaderError ? (
                <>
                  <DocumentUploaderCancelButton
                    cancelUpload={cancelUploadAndClose}
                  />
                  <Button type="button" onClick={resetUploadComponent}>
                    {DOCUMENT_UPLOADER_COPY.tryAgainButton}
                  </Button>
                </>
              ) : (
                <>
                  <TertiaryActionButton
                    type="button"
                    onClick={resetUploadComponent}
                  >
                    {DOCUMENT_UPLOADER_COPY.uploadAnother}
                  </TertiaryActionButton>
                  <Button type="button" onClick={cancelUploadAndClose}>
                    {DOCUMENT_UPLOADER_COPY.return}
                  </Button>
                </>
              )}
            </UploadModalActions>
          )}
          {uploaderContextInFormFlow && uploaderError && (
            <UploadModalActions uploaderStage={uploaderStage}>
              <Button type="button" onClick={resetUploadComponent}>
                {DOCUMENT_UPLOADER_COPY.tryAgainButton}
              </Button>
            </UploadModalActions>
          )}
        </>
      )}
    </>
  );
};

export default DocumentUploader;
