import { useMemo } from 'react';
import isEmpty from 'ramda/src/isEmpty';
import path from 'ramda/src/path';

import {
  isValueMissingFromViewState,
  configureErrorMessage,
  getNewSaleErrors,
  mapMultilineFieldValidationErrors,
  mapMultilineChildrenValidationErrors,
} from '../services/validation';
import { getGroups } from '../services/formState';
import { FORM_BUILDER_COPY, FORM_FLOWS } from '../constants';
import {
  FIELD_COMPONENTS,
  GroupProps,
  ErrorProps,
  ValidationEntityKey,
} from '../types';

const handleMultiLineViewValidation = (
  viewState: any,
  group: GroupProps,
  allErrors: ErrorProps[],
  formId: string,
) => {
  let validationErrors = [];

  if (viewState[group.groupId]?.value) {
    validationErrors = viewState[group.groupId].value.map(
      mapMultilineFieldValidationErrors(group, allErrors, viewState),
    );
  }

  if (!viewState[group.groupId]?.value && group.validation?.isRequired) {
    validationErrors = [
      configureErrorMessage(
        group.groupId,
        {},
        FORM_BUILDER_COPY.errors.emptyFieldMessage,
      ),
    ];
  }

  if (formId === FORM_FLOWS.CREATE_FLOOR_PARTITION) {
    let childFieldErrors = [];

    if (viewState[group.groupId]?.value) {
      childFieldErrors = viewState[group.groupId].value.reduce(
        (accum: [], item: any) => {
          if (!item.partitions) {
            return accum;
          }
          const errors = item.partitions?.value.map(
            mapMultilineChildrenValidationErrors(group, allErrors),
          );
          if (errors) return [...accum, ...errors];
          return accum;
        },
        [],
      );
    }

    if (!viewState[group.groupId]?.value && group.validation?.isRequired) {
      childFieldErrors = [
        configureErrorMessage(
          group.groupId,
          {},
          FORM_BUILDER_COPY.errors.emptyFieldMessage,
        ),
      ];
    }

    validationErrors = [...validationErrors, ...childFieldErrors];
  }

  if (formId === FORM_FLOWS.SALE) {
    validationErrors = [
      ...validationErrors,
      ...getNewSaleErrors(viewState, group.groupId),
    ];
  }

  return validationErrors.reduce(
    (errors: ErrorProps[], lineErrors: ErrorProps[]) =>
      errors.concat(lineErrors),
    [],
  );
};

const handleMultiLineGroupedViewValidation = (
  viewState: any,
  group: GroupProps,
  formId: string,
) => {
  const rowStates = viewState[group.groupId]?.value;
  // eslint-disable-next-line no-nested-ternary
  let validationErrors: any = [];

  if (rowStates) {
    validationErrors = rowStates.reduce(
      (accum: ErrorProps[], rowState: any) => {
        const visibleGroups =
          group.subGroups && getGroups(group.subGroups, rowState);
        const groupErrors = visibleGroups
          ?.map((singleGroup) => {
            return mapMultilineFieldValidationErrors(
              singleGroup,
              accum,
            )(rowState);
          })
          .reduce((groupErrorSet, errorSet) => {
            if (!errorSet || isEmpty(errorSet)) return groupErrorSet;

            return groupErrorSet?.concat(errorSet);
          }, []);

        if (!groupErrors || !groupErrors.length) return accum;

        return groupErrors;
      },
      [] as ErrorProps[],
    );
  }

  if (!rowStates && group.validation?.isRequired) {
    validationErrors = [
      configureErrorMessage(
        group.groupId,
        {},
        FORM_BUILDER_COPY.errors.emptyFieldMessage,
      ),
    ];
  }

  if (formId === FORM_FLOWS.SALE) {
    validationErrors = [
      ...validationErrors,
      ...getNewSaleErrors(viewState, group.groupId),
    ];
  }

  return validationErrors;
};

export const checkIfOneFieldIsSelected = (
  viewState: any,
  requiredFields: string[],
) => {
  const isOneFieldPopulated = requiredFields.find((field: string) =>
    path([...field.split('.'), 'value'], viewState),
  );
  return isOneFieldPopulated
    ? []
    : [{ field: requiredFields.join(), message: '', display: false }];
};

const MULTILINE_ARRAY = [
  FIELD_COMPONENTS.MULTILINE_BATCH,
  FIELD_COMPONENTS.MULTILINE_BATCH_ORDERED,
  FIELD_COMPONENTS.MULTILINE_FIELDSET,
  FIELD_COMPONENTS.MULTILINE_REPEAT,
  FIELD_COMPONENTS.MULTILINE_DEMISE,
  FIELD_COMPONENTS.MULTILINE_FLOOR_PARTITION,
  FIELD_COMPONENTS.RENEW_OTHER_LEASES,
];

export const getViewErrors = (
  viewState: any,
  formId: string,
  groups?: GroupProps[],
  requireOneOfFields?: string[],
) => {
  if (!groups) return [];

  if (requireOneOfFields) {
    return checkIfOneFieldIsSelected(viewState, requireOneOfFields);
  }

  return groups.reduce((allErrors, group) => {
    if (MULTILINE_ARRAY.includes(group.componentType)) {
      return allErrors.concat(
        handleMultiLineViewValidation(viewState, group, allErrors, formId),
      );
    }

    if (
      group.componentType === FIELD_COMPONENTS.MULTILINE_GROUPED_REPEAT ||
      group.componentType === FIELD_COMPONENTS.MULTILINE_BUYER
    ) {
      return handleMultiLineGroupedViewValidation(viewState, group, formId);
    }

    let validationViewErrors: any = [];

    if (group.fields) {
      validationViewErrors = group.fields.reduce((fieldAccum, field) => {
        return field.validation &&
          viewState &&
          isValueMissingFromViewState(
            field,
            ValidationEntityKey.fieldId,
            viewState[group.groupId],
          )
          ? fieldAccum.concat(
              configureErrorMessage(
                field.fieldId,
                field.validation,
                FORM_BUILDER_COPY.errors.emptyFieldMessage,
              ),
            )
          : fieldAccum;
      }, [] as ErrorProps[]);
    }

    if (
      !group.fields &&
      group.validation &&
      isValueMissingFromViewState(group, ValidationEntityKey.groupId, viewState)
    ) {
      validationViewErrors = [
        configureErrorMessage(
          group.groupId,
          group.validation,
          FORM_BUILDER_COPY.errors.emptyFieldMessage,
        ),
      ];
    }

    if (formId === FORM_FLOWS.SALE) {
      allErrors.concat(getNewSaleErrors(viewState, group.groupId));
    }

    return allErrors.concat(validationViewErrors);
  }, [] as ErrorProps[]);
};

export default function useViewValidation(
  viewState: any,
  formId: string,
  groups?: GroupProps[],
  requireOneOfFields?: string[],
) {
  const viewErrors = getViewErrors(
    viewState,
    formId,
    groups,
    requireOneOfFields,
  );
  const errorsToDisplay = useMemo(
    () => viewErrors.filter((error) => error?.display),
    [viewErrors],
  );

  return {
    viewErrors,
    errorsToDisplay,
  };
}
