import { STATE_FIELDS, TRUTHY_VALUES } from 'connected/FormFlow/constants';
import {
  formatDataSourceValue,
  getCompanyDetails,
} from 'connected/FormFlow/services/utils';
import { FormValue } from './types';

export const transformBooleanPayload = (payload?: FormValue) => {
  if (!payload) return null;

  return formatDataSourceValue(
    TRUTHY_VALUES.includes(payload.value),
    payload.subSource,
  );
};

export const transformCompanyPayload = (
  defaultDataSource: string,
  company?: { value: any; subSource?: string },
  type?: { value: string },
  nationality?: string,
  sector?: string,
  equityStake?: FormValue,
  ownerOccupierPurchase?: FormValue,
  includeDefaultSector?: boolean,
) => {
  const isPrivate =
    type?.value === STATE_FIELDS.PRIVATE_INDIVIDUAL ||
    type?.value === STATE_FIELDS.PRIVATE_INVESTOR;
  if (!company?.value && !nationality && !isPrivate) return {};

  let companyTransformation = {};
  if (
    type &&
    (type.value === STATE_FIELDS.COMPANY ||
      type.value !== STATE_FIELDS.PRIVATE_INVESTOR ||
      type.value !== STATE_FIELDS.PRIVATE_INDIVIDUAL) &&
    company
  ) {
    companyTransformation = {
      ...getCompanyDetails(
        company.value,
        company.subSource || defaultDataSource,
        includeDefaultSector,
      ),
    };
  }

  if (nationality) {
    companyTransformation = {
      ...companyTransformation,
      nationality,
    };
  }

  if (type && isPrivate) {
    companyTransformation = {
      ...companyTransformation,
      isPrivate: {
        value: isPrivate,
      },
    };
  }

  if (sector) {
    companyTransformation = {
      ...companyTransformation,
      sector,
    };
  }

  if (equityStake) {
    companyTransformation = {
      ...companyTransformation,
      equityStake,
    };
  }

  if (ownerOccupierPurchase) {
    companyTransformation = {
      ...companyTransformation,
      ownerOccupierPurchase: transformBooleanPayload(ownerOccupierPurchase),
    };
  }
  return companyTransformation;
};

export const mapAgentsPayload = (agents: any, defaultDataSource: string) => {
  return agents?.map(
    (agent: { company: { value: string; subSource: string } }) => {
      const agentCompany = transformCompanyPayload(
        defaultDataSource,
        agent.company,
        { value: STATE_FIELDS.COMPANY },
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );
      const { company, ...updatedAgents } = agent;
      return {
        ...agentCompany,
        ...updatedAgents,
      };
    },
  );
};
