import React from 'react';
import {
  PageLayout,
  PageHeader,
  PageMain,
} from 'components/Layouts/PageLayout/PageLayoutStyled';

import { AdminStatistics } from 'connected/AdminStatistics';
import { AdminUsers } from 'connected/AdminUsers';
import { AppTitle } from 'components/AppTitle';
import { AdminQueryPanel } from 'connected/AdminQueryPanel';

import { AdminContainerStyled } from './Admin.styles';
import { ADMIN_CONTAINER_COPY } from './constants';

export const Admin: React.FC = () => (
  <PageLayout>
    <AppTitle>{ADMIN_CONTAINER_COPY.title}</AppTitle>
    <PageHeader>
      <h1>{ADMIN_CONTAINER_COPY.title}</h1>
    </PageHeader>
    <PageMain>
      <AdminContainerStyled>
        <AdminStatistics />
        <AdminUsers />
        <AdminQueryPanel />
      </AdminContainerStyled>
    </PageMain>
  </PageLayout>
);
