import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import {
  fetchUsersListAPIFailAction,
  fetchUsersListAPISuccessAction,
  UsersListActions,
} from 'store/actions/usersListActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const usersListEndpoint = endpoints.listUsers;

const usersListGet: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(UsersListActions.FETCH_USERS_LIST),
    mergeMap(() => {
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url: usersListEndpoint,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`GET ${usersListEndpoint} failed`);
            }
            return of(fetchUsersListAPISuccessAction(response.response));
          }),
          catchError((error) => {
            return of(fetchUsersListAPIFailAction(error));
          }),
        );
    }),
  );
};

export default usersListGet;
