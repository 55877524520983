import React, { useRef, useState, KeyboardEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useOutsideClick from 'hooks/useOutsideClick';
import { KEYBOARD_EVENT_VALUES } from 'globalConstants';
import useFeatureFlag from 'hooks/useFeatureFlag';
import {
  MenuList,
  MenuListItem,
  MenuLink,
  SubMenuList,
  SubMenuListItem,
  SubMenuLink,
  MenuTitle,
} from './GlobalMenuStyled';
// prevent conflict with MenuItem component name be prefixing with `I`
import { MenuItem as IMenuItem, MenuItemProps } from './types';
import { menuItems } from './constants';

const ChildMenuItem = ({
  child,
  index,
  pathname,
  handleSelect,
}: {
  child: IMenuItem;
  index: number;
  pathname: any;
  handleSelect: any;
}) => {
  const isSwitchedOn = useFeatureFlag(child.featureFlag);

  if (isSwitchedOn) {
    return (
      <SubMenuListItem key={child.key}>
        <SubMenuLink
          to={child.href}
          onKeyDown={(e) => handleSelect(e, index)}
          isActive={pathname.includes(child.href)}
          tabIndex={child.tabIndex}
        >
          {child.title}
        </SubMenuLink>
      </SubMenuListItem>
    );
  }

  return null;
};

const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const handleOpenMenu = () => {
    setMenuVisibility(true);
  };
  const menuRef = useRef<HTMLLIElement>(null);

  const checkIsActive = (children: IMenuItem['children']): boolean => {
    if (!children) return false;
    const matchesChildPath = children.filter((child) =>
      pathname.includes(child.href),
    );
    return matchesChildPath.length > 0;
  };

  const handleCloseMenu = () => {
    setMenuVisibility(false);
  };

  const handleFirstLastItem = (
    event: KeyboardEvent<HTMLSpanElement>,
    index: number,
  ) => {
    if (
      index + 1 === item.children?.length &&
      event.keyCode === KEYBOARD_EVENT_VALUES.TAB
    ) {
      handleCloseMenu();
    }

    if (
      index === -1 &&
      event.shiftKey &&
      event.keyCode === KEYBOARD_EVENT_VALUES.TAB
    ) {
      handleCloseMenu();
    }
  };

  const handleRootBlur = () => {
    if (!item.children) handleCloseMenu();
  };

  const handleSelectMenuListItemKeyDown = (
    event: KeyboardEvent<HTMLSpanElement>,
    index?: number,
  ) => {
    if (
      event.keyCode === KEYBOARD_EVENT_VALUES.SPACE ||
      event.keyCode === KEYBOARD_EVENT_VALUES.ENTER
    ) {
      handleOpenMenu();
    }

    // case where we have a href on a root menu item (e.g. Search)
    // but the actual current focus tab item isn't the <MenuLink>
    if (
      !item.children &&
      (event.keyCode === KEYBOARD_EVENT_VALUES.SPACE ||
        event.keyCode === KEYBOARD_EVENT_VALUES.ENTER)
    ) {
      history.push(item.href);
    }

    // This closes root menus when you tab through to the next root
    if (event.keyCode === KEYBOARD_EVENT_VALUES.TAB) {
      handleFirstLastItem(event, index ?? -1);
    }
  };

  useOutsideClick(menuRef, () => handleCloseMenu(), isMenuVisible);

  if (!useFeatureFlag(item.featureFlag)) {
    return null;
  }

  return (
    <MenuListItem
      ref={menuRef}
      onMouseEnter={handleOpenMenu}
      onMouseLeave={handleCloseMenu}
      hasChildren={item.children && item.children.length > 0}
      tabIndex={item.tabIndex}
      onKeyDown={handleSelectMenuListItemKeyDown}
      isMenuVisible={isMenuVisible}
      onFocus={handleOpenMenu}
      onBlur={handleRootBlur}
    >
      {item.children ? (
        <MenuTitle isActive={checkIsActive(item.children)}>
          {item.title}
        </MenuTitle>
      ) : (
        <MenuLink
          to={{ pathname: item.href }}
          isActive={pathname.includes(item.href)}
          target={item.target}
          rel={item.rel}
        >
          {item.title}
        </MenuLink>
      )}
      {item.children && (
        <SubMenuList>
          {item &&
            item.children.map((child, i) => (
              <ChildMenuItem
                key={child.key}
                handleSelect={handleSelectMenuListItemKeyDown}
                child={child}
                index={i}
                pathname={pathname}
              />
            ))}
        </SubMenuList>
      )}
    </MenuListItem>
  );
};

const GlobalMenu: React.FC<{ showAdmin?: boolean }> = ({ showAdmin }) => {
  const removeAdminMenuIfUserNotAdmin = (item: typeof menuItems[0]) => {
    if (!item.admin) {
      return true;
    }

    if (showAdmin) {
      return true;
    }

    return false;
  };

  const items = menuItems.filter(removeAdminMenuIfUserNotAdmin);

  return (
    <nav>
      <MenuList role="menubar">
        {items.map((item) => (
          <MenuItem key={item.key} item={item} />
        ))}
      </MenuList>
    </nav>
  );
};

export default GlobalMenu;
