import React from 'react';
import { useDispatch } from 'react-redux';

import {
  QuickSearchResult,
  QuickSearchOnResultItemClick,
  QuickSearchResultBuilding,
  QuickSearchResultCompany,
  QuickSearchColorMode,
} from 'connected/QuickSearch/types';
import { quickSearchItemClicked } from 'store/actions/quickSearchActions';
import { urls, Vertical } from 'globalConstants';
import InfoPill, {
  ResultItemProps as InfoPillProps,
} from '../InfoPill/InfoPill';
import {
  ResultListItem,
  ResultItemLink,
  ResultItemButton,
  ResultAddress,
  ResultAddressWrapper,
  ChevronRightIcon,
  OfficeIcon,
  IndustrialIcon,
  RetailIcon,
  SubDirectoryIcon,
} from './ResultItemStyled';

export type ResultItemProps = {
  result: QuickSearchResult;
  onResultItemClick?: QuickSearchOnResultItemClick;
  isChild?: boolean;
  mode?: QuickSearchColorMode;
  disableNonCanonicalResults?: boolean;
};

export type ResultItemCtaPros = {
  vertical: Vertical;
  entityId: string;
  entity: QuickSearchResult;
  onResultItemClick?: QuickSearchOnResultItemClick;
  children: React.ReactNode;
  isChild?: boolean;
  isDisabled?: boolean;
};

function renderPrimaryUseIcon(primaryUse: string) {
  switch (primaryUse) {
    case 'office': {
      return <OfficeIcon />;
    }

    case 'industrial': {
      return <IndustrialIcon />;
    }

    case 'retail': {
      return <RetailIcon />;
    }

    default: {
      return <OfficeIcon />;
    }
  }
}

function renderInfoPill(result: QuickSearchResultBuilding) {
  let props: InfoPillProps | null = {
    id: result.id,
    searchType: result.type,
    type: 'alias',
    text: '',
  };

  switch (true) {
    case result.alternativeAddress !== undefined: {
      props.type = 'alternativeAddress';
      props.text = result.alternativeAddress || '';
      break;
    }

    case result.formerName !== undefined: {
      props.type = 'formerName';
      props.text = result.formerName || '';
      break;
    }

    case result.alias !== undefined: {
      props.type = 'alias';
      props.text = result.alias || '';
      break;
    }

    default: {
      props = null;
    }
  }

  return props && <InfoPill {...props} />;
}

const ResultItemCta = ({
  children,
  onResultItemClick,
  vertical,
  entityId,
  entity,
  isChild,
  isDisabled,
}: ResultItemCtaPros) => {
  const dispatch = useDispatch();

  const onLinkClick = () => {
    dispatch(quickSearchItemClicked({ vertical, entityId }));
  };

  return onResultItemClick ? (
    <ResultItemButton
      type="button"
      onClick={() =>
        onResultItemClick({
          id: entityId,
          entity,
          vertical,
        })
      }
      disabled={isDisabled}
      isChild={isChild}
    >
      {children}
    </ResultItemButton>
  ) : (
    <ResultItemLink
      to={urls.searchDetails(vertical, entityId)}
      onClick={onLinkClick}
    >
      {children}
    </ResultItemLink>
  );
};

const BuildingResult = (building: QuickSearchResultBuilding) => (
  <>
    <ResultAddressWrapper>
      <div>{renderPrimaryUseIcon(building.primaryUse)}</div>
      <ResultAddress>{building.address}</ResultAddress>
    </ResultAddressWrapper>
    {renderInfoPill(building)}
  </>
);

const CompanyResult = ({ name, address }: QuickSearchResultCompany) => (
  <ResultAddressWrapper>
    <OfficeIcon />
    <ResultAddress>{address ? `${name}, ${address}` : name}</ResultAddress>
  </ResultAddressWrapper>
);

const ResultItem: React.FC<ResultItemProps> = ({
  result,
  onResultItemClick,
  isChild,
  mode,
  disableNonCanonicalResults,
}: ResultItemProps) => (
  <ResultListItem isChild={isChild}>
    {isChild && <SubDirectoryIcon mode={mode} />}
    <ResultItemCta
      vertical={result.type}
      entityId={result.id}
      entity={result}
      onResultItemClick={onResultItemClick}
      isChild={isChild}
      isDisabled={disableNonCanonicalResults}
    >
      {result.type === Vertical.Building ? (
        <BuildingResult {...result} />
      ) : (
        <CompanyResult {...result} />
      )}
      <ChevronRightIcon />
    </ResultItemCta>
  </ResultListItem>
);

export default ResultItem;
