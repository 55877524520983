import styled, { css } from 'styled-components/macro';
import { zIndex } from 'styled/Global';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import OverlayBg from 'assets/overlay-bg.png';
import withIconStyles from '../../components/Icon/Icon';

import { GlobalModalWrapperProps } from './types';

export const GlobalModalCloseIcon = styled(withIconStyles(CloseIcon))`
  color: ${(props) => props.theme.colorPalette.secondary.darkGrey};
  width: 28px;
  height: 28px;
`;

export const GlobalModalCloseButton = styled.button`
  position: absolute;
  right: 27px;
  top: 27px;
  background: none;
  z-index: ${zIndex.modalBtn};
  &:focus {
    outline-color: ${(props) => props.theme.colorPalette.branding.ink};
    outline-style: auto;
  }
`;

export const GlobalModalOverlayStyled = styled.div<
  Pick<GlobalModalWrapperProps, 'lightTheme'>
>`
  z-index: ${zIndex.modal};
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GlobalModalPageBlur = styled.div`
  z-index: ${zIndex.pageBlur};
  content: ' ';
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: url(${OverlayBg}) repeat;
`;

export const GlobalModalContainerStyled = styled.div<
  Pick<GlobalModalWrapperProps, 'size'>
>`
  box-shadow: ${(props) => props.theme.modal.boxShadow};
  min-width: 320px;
  width: ${(props) => (props.size === 'fullsize' ? '100vw' : '90%')};
  position: relative;

  ${(props) =>
    props.size === 'fullsize' &&
    css`
      height: 100vh;
      border-radius: 0;
    `}

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    border-radius: 8px;
    width: ${(props) =>
      props.size === 'large' || props.size === 'fullsize' ? '92%' : '540px'};
      ${(props) =>
        props.size === 'fullsize' &&
        css`
          height: 92vh;
        `}
    ${(props) =>
      props.size === 'large' &&
      css`
        max-width: ${props.theme.breakpoints.large}px;
      `}
`;
