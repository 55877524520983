import { createSelector } from 'reselect';

import { RootState } from 'store/rootReducer';

export const getTelemetryStatistics = (state: RootState) =>
  state.telemetryStatistics;

export const getTelemetryStatisticsResults = createSelector(
  getTelemetryStatistics,
  (userQueries) => userQueries.results,
);

export const getTelemetryStatisticsResultsFetching = createSelector(
  getTelemetryStatistics,
  (userQueries) => userQueries.isFetching,
);
