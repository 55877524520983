import styled, { keyframes } from 'styled-components/macro';
import { lighten } from 'polished';
import { LinearLoaderProps } from './types';

const initialAnimationStep = keyframes`
  0% {
    left: -100%;
    width: 100%;
  }
  90% {
    left: 100%;
    width: 100%;
  }
  99% {
    left: 100%;
    width: 0;
  }
  100% {
    left: -100%;
    width: 0;
  }
`;

export const Wrapper = styled.div<LinearLoaderProps>`
  width: 100%;
  overflow: hidden;
  height: 4px;
  background-color: ${(props) =>
    props.mode === 'light'
      ? lighten('0.5', props.theme.colorPalette.branding.yellow)
      : props.theme.colorPalette.linearLoader.dark.base};
  margin: 0 auto;
`;

export const Indeterminate = styled.div<LinearLoaderProps>`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${(props) =>
      props.mode === 'light'
        ? props.theme.colorPalette.branding.yellow
        : props.theme.colorPalette.linearLoader.dark.intermediate};
    animation: ${initialAnimationStep} 1.5s infinite ease-out;
  }
`;
