import pathOr from 'ramda/src/pathOr';
import path from 'ramda/src/path';

import { SaleRecord, LeaseRecord } from 'pages/Details/types';
import { evaluateViewMatches } from './matches';
import { ConditionalConfig, ViewConfig } from '../types';

/**
 * Determine if view meets conditions needed to display
 * @param  {ConditionalConfig} condition set within the config
 * @param  {any} formState
 * @returns {boolean}
 */
export const evaluateSingleCondition = (
  condition: ConditionalConfig,
  formState: any,
  existingValues?: any,
) => {
  if (condition.existingValue && condition.existingRef) {
    return (
      condition.existingValue.includes(
        pathOr('', [...condition.existingRef.split('.')], existingValues),
      ) &&
      condition.value.includes(
        pathOr('', [...condition.ref.split('.'), 'value'], formState),
      )
    );
  }

  if (condition.value === '*') {
    return condition.inverse
      ? path([...condition.ref.split('.'), 'value'], formState) === undefined
      : pathOr(null, [...condition.ref.split('.'), 'value'], formState) !==
          null;
  }

  if (condition.value === '**') {
    return pathOr(false, [...condition.ref.split('.'), 'value'], formState);
  }

  return condition.inverse
    ? !condition.value.includes(
        pathOr('', [...condition.ref.split('.'), 'value'], formState),
      )
    : condition.value.includes(
        pathOr('', [...condition.ref.split('.'), 'value'], formState),
      );
};

export const evaluateConditions = (
  conditions: ConditionalConfig[],
  formState: any,
  existingValues?: any,
) => {
  return Array.isArray(conditions)
    ? conditions.every((condition) =>
        evaluateSingleCondition(condition, formState, existingValues),
      )
    : evaluateSingleCondition(conditions, formState, existingValues);
};

const evaluateMatchingRecordViews = (
  view: ViewConfig,
  existingRecords: (SaleRecord | LeaseRecord)[],
  formState: any,
) => {
  return (
    !view.findMatches ||
    (view.findMatches.displayNoMatches && view.findMatches) ||
    evaluateViewMatches(view.findMatches, formState, existingRecords)
  );
};

export const viewsToDiscard = (
  formState: any,
  existingRecords: (SaleRecord | LeaseRecord)[],
  existingValues: any,
) => (view: ViewConfig) => {
  return (
    (!view.conditional ||
      evaluateConditions(view.conditional, formState, existingValues)) &&
    evaluateMatchingRecordViews(view, existingRecords, formState)
  );
};

export const uniqueViews = (views: ViewConfig[]) => {
  return views.reduce((accum: ViewConfig[], view: ViewConfig) => {
    if (
      !accum.find((viewItem) => view.hideIfScreenVisible === viewItem.viewId)
    ) {
      accum.push(view);
    }
    return accum;
  }, []);
};
