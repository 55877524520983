/* eslint-disable react/prop-types */
import React from 'react';
import FormField from '../FormField/FormField';
import {
  MultiLineStyled,
  MultiLineWrapper,
  MultiLineHeader,
  FieldColumns,
  DemiseWrapper,
} from './FormMultilineStyled';
import { MultilineProps, LineConfigProps } from '../types';
import useMultiline from '../hooks/useMultiline';

type MultiLineDemiseProps = Pick<MultilineProps, 'lineConfig'> & {
  formStateItems: any;
  handleOnChange: (state: any) => void;
  existingValues?: any;
};

const getPrompt = (lease: any) => {
  if (lease.floorPartition) {
    const { floor, partition } = lease.floorPartition;
    return partition
      ? `${floor}, ${partition} (this lease)`
      : `${floor} (this lease)`;
  }
  return lease.title;
};

const MultiLineDemise = ({
  lineConfig,
  formStateItems,
  handleOnChange,
  existingValues,
}: MultiLineDemiseProps) => {
  const { onFieldChange, rowFields } = useMultiline(
    formStateItems,
    handleOnChange,
  );

  const leases = [existingValues, ...existingValues.dealDetails.leases];
  const labels = leases.map((lease: any) => getPrompt(lease));

  const getLine = (item: any, i: number) => (
    <DemiseWrapper>
      <MultiLineWrapper key={item.index}>
        <MultiLineHeader>{labels[i]}</MultiLineHeader>
        {lineConfig.map((line: LineConfigProps) => {
          const itemValue = item[line.name]?.value || '';
          return (
            <FieldColumns key={`item${line.name}`} columns={line.columns}>
              <FormField
                name={`${line.name}-${i}`}
                label={line.label}
                handleOnChange={(value) => {
                  onFieldChange(
                    i,
                    value,
                    line.name,
                    labels ? `${line.label} - ${labels[i]}` : line.label,
                    line.componentType,
                    line.resetFormState,
                    line.resetRefsOnChange,
                    undefined,
                    leases[i].id,
                  );
                }}
                groupId={leases[i].id}
                options={line.options}
                type={line.componentType}
                value={itemValue}
                lineValues={item}
                validation={line.validation}
                isGroup
                itemProps={{ ...line, groupId: '', fieldType: undefined }}
              />
            </FieldColumns>
          );
        })}
      </MultiLineWrapper>
    </DemiseWrapper>
  );

  return <MultiLineStyled>{rowFields.map(getLine)}</MultiLineStyled>;
};

export default MultiLineDemise;
