import { Vertical } from 'globalConstants';

export type EventDates = {
  reviewDate?: Date;
  rentFrom: Date;
  isVoided: boolean;
};

export type VerticalKeys =
  | Vertical.Building
  | Vertical.ToLet
  | Vertical.Lease
  | Vertical.PortfolioSale
  | Vertical.Company
  | Vertical.Sale;

export enum RelationRole {
  Landlord = 'Landlord',
  Tenant = 'Tenant',
  Vendor = 'Vendor',
  Purchaser = 'Purchaser',
  HoldingEntity = 'Holding Entity',
  BuyersSolicitor = "Buyer's Solicitor",
  SellersSolicitor = "Seller's Solicitor",
  AcquiringAgent = 'Acquiring Agent',
  DisposingAgent = 'Disposing Agent',
  TenantAgent = 'Tenant Agent',
  TenantLeaseConsultant = "Tenant's Lease Consultant",
  LandlordAgent = 'Landlord Agent',
  LandlordLeaseConsultant = "Landlord's Lease Consultant",
  TenantsSolicitor = "Tenant's Solicitor",
  LandlordsSolicitor = "Landlord's Solicitor",
  OccupierServicersProvider = 'Occupier Services Provider',
  AssetManager = 'Asset Manager',
  DebtProvider = 'Debt Provider',
  FundManager = 'Fund Manager',
  PropertyManager = 'Property Manager',
  Other = 'Other',
  Freeholder = 'Freeholder',
  Borrower = 'Borrower',
  Lender = 'Lender',
  DilapidationsManager = 'Dilapidations Manager',
  BuildingAndPropertyConsultant = 'Building and Property Consultant',
  PropertyValuer = 'Property Valuer',
}

export type Employee = {
  firstName: string;
  lastName: string;
};

export type PreviousCompanyNames = {
  previousName: string;
  ceasedOn?: string;
  effectiveFrom?: string;
};

export type Geom = {
  coordinates: number[];
  type: string;
};
