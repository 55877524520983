import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { globalModalHide } from 'store/actions/globalModalActions';
import {
  documentDeleteApiFail,
  documentDeleteApiSuccess,
} from 'store/actions/documentPanelActions';
import { DocumentPanelActionTypes } from 'connected/DocumentPanel/types';

type DocumentDeleteEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const deleteDocumentEndpoint = endpoints.document.delete;

const documentDeleteEpic: Epic = (
  action$,
  state$,
  dependencies: DocumentDeleteEpicDependencies,
) => {
  return action$.pipe(
    ofType(DocumentPanelActionTypes.DELETE_DOCUMENT),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'DELETE',
          url: deleteDocumentEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((deleteResponse) => {
            if (deleteResponse.status > 204) {
              throw Error(`DELETE ${deleteDocumentEndpoint} failed`);
            }

            const actionsToDispatch =
              action.meta && action.meta.preventDetailRefresh
                ? [
                    documentDeleteApiSuccess({
                      preventDetailRefresh: true,
                    }),
                  ]
                : [documentDeleteApiSuccess(), globalModalHide()];

            return of(...actionsToDispatch);
          }),
          catchError((error) => {
            return of(documentDeleteApiFail(error));
          }),
        );
    }),
  );
};

export default documentDeleteEpic;
