import { useEffect, useState } from 'react';

import { FIELD_COMPONENTS, ValidationProps } from 'connected/FormFlow/types';
import { testAgainstRegularExpression } from 'connected/FormFlow/services/validation';
import { isAfter, isBefore, parseISO } from 'date-fns';

type ValueProp = string | number | boolean | Date | undefined;

type FieldInputValidationHook = (
  value: ValueProp,
  componentType: FIELD_COMPONENTS,
  validation?: ValidationProps,
) => {
  hasErrors: boolean;
  isTouched: boolean;
};

const useFieldInputValidation: FieldInputValidationHook = (
  value,
  componentType,
  validation,
) => {
  const [hasErrors, setErrors] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const handleFieldValidation = (val: ValueProp, touched: boolean) => {
    const stringVal = `${val}`;

    if (!touched) {
      return setErrors(false);
    }

    if (!validation) {
      return setErrors(false);
    }

    const isRequiredWithEmptyValue =
      (validation.isRequired && (!stringVal || stringVal.length === 0)) ||
      (stringVal && !stringVal.trim());

    const failsValidationPatternMatching =
      stringVal &&
      stringVal.length > 0 &&
      validation.match &&
      !testAgainstRegularExpression(val, validation.match, componentType);
    const failsMinMaxValidation =
      val &&
      ((validation.minValue && val < validation.minValue) ||
        (validation.maxValue && val > validation.maxValue));

    const failsMinMaxDateValidation =
      val &&
      ((validation.minDate &&
        isBefore(parseISO(val as string), validation.minDate)) ||
        (validation.maxDate &&
          isAfter(parseISO(val as string), validation.maxDate)));

    if (
      isRequiredWithEmptyValue ||
      failsValidationPatternMatching ||
      failsMinMaxValidation ||
      failsMinMaxDateValidation
    ) {
      return setErrors(true);
    }

    return setErrors(false);
  };

  useEffect(() => {
    if (!isTouched && value) {
      setIsTouched(true);
    }

    handleFieldValidation(value, isTouched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isTouched]);

  return {
    isTouched,
    hasErrors,
  };
};

export default useFieldInputValidation;
