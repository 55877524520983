import { getStateValueAtPath } from './formState';
import { ConfigFieldType } from '../types';
import { formatField } from './utils';

type FormSummaryItemProps = {
  title: string;
  body: string;
};

export type SummaryConfig = {
  summaryTitle: string;
  path: string;
  summaryFormat: ConfigFieldType;
};

/**
 * SummaryFields set in config contains a hardcoded title or a ref to the form state for the title and body
 * @param  {any} formState
 * @param  {any} summaryFields List of summary fields set in config
 */
export const getSummaryFields = (formState: any, summaryFields: any) => {
  return summaryFields.reduce(
    (
      acc: FormSummaryItemProps[],
      { summaryTitle, path, summaryFormat }: SummaryConfig,
    ) => {
      const state = getStateValueAtPath(formState, path, '');
      const itemTitle = getStateValueAtPath(formState, summaryTitle, '');
      if (state) {
        return [
          ...acc,
          {
            title: itemTitle || summaryTitle,
            body: formatField(state, summaryFormat),
          },
        ];
      }
      return acc;
    },
    [],
  );
};
