import compose from 'ramda/src/compose';
import { formatDemises } from 'connected/FormFlow/services/utils';
import {
  transformBooleanPayload,
  transformCompanyPayload,
} from 'connected/NewRecordWrapper/helpers';
import { STATE_FIELDS } from 'connected/FormFlow/constants';

const getBooleanPayload = (key: string, booleanVal?: any) => {
  if (booleanVal) {
    return { [key]: transformBooleanPayload(booleanVal) };
  }
  return null;
};

const addConditionalKeys = (input: any, defaultDataSource: string) => {
  const output = input;
  const hasTenantFieldsEntered =
    output.tenant || output.tenantNationality || output.tenantType;
  const hasFreeholderFieldsEntered =
    output.freeholder || output.freeholderNationality || output.freeholderType;
  const hasLandlordFieldsEntered =
    output.landlord || output.landlordNationality || output.landlordType;

  if (input.isConfidential) {
    output.isConfidential = transformBooleanPayload(input.isConfidential);
  }

  if (input.lenderDetails) {
    output.relatedCompanies = input.lenderDetails.map((lender: any) => {
      return {
        ...transformCompanyPayload(
          defaultDataSource,
          lender.lenderCompanyName,
          lender.lenderType,
          lender.lenderNationality,
          lender.lenderSector,
        ),
        debtRole: {
          value: 'Lender',
        },
      };
    });

    delete output.lenderDetails;
  }

  if (input.borrowers) {
    output.relatedCompanies = [
      ...output.relatedCompanies,
      ...input.borrowers.map((borrower: any) => {
        return {
          ...transformCompanyPayload(
            defaultDataSource,
            borrower.borrowerCompanyName,
            borrower.borrowerType,
            borrower.borrowerNationality,
            borrower.borrowerSector,
          ),
          debtRole: {
            value: 'Borrower',
          },
        };
      }),
    ];

    delete output.borrowers;
    delete output.borrowerNationality;
    delete output.borrowerSector;
    delete output.borrowerType;
  }

  if (hasTenantFieldsEntered) {
    output.tenant = transformCompanyPayload(
      defaultDataSource,
      output.tenant,
      output.tenantType,
      output.tenantNationality,
      output.tenantSector,
    );

    delete output.tenantSector;
    delete output.tenantNationality;
    delete output.tenantType;
  }

  if (hasFreeholderFieldsEntered) {
    output.freeholder = transformCompanyPayload(
      defaultDataSource,
      output.freeholder,
      output.freeholderType,
      output.freeholderNationality,
      output.freeholderSector,
    );

    delete output.freeholderSector;
    delete output.freeholderNationality;
    delete output.freeholderType;
  }

  if (output.demises) {
    output.demises = formatDemises(output.demises);
  }

  if (input.breakOptions) {
    output.breakOptions = input.breakOptions.map((breakOption: any) => {
      const redevelopmentBreakObj = getBooleanPayload(
        'redevelopmentBreak',
        breakOption.redevelopmentBreak,
      );

      const timeOfTheEssenceObj = getBooleanPayload(
        'rollingBreak',
        breakOption.rollingBreak,
      );

      return {
        ...breakOption,
        ...redevelopmentBreakObj,
        ...timeOfTheEssenceObj,
      };
    });
  }

  if (input.rentReviews) {
    output.rentReviews = input.rentReviews.map((review: any) => {
      const upwardOnlyObj = getBooleanPayload('upwardOnly', review.upwardOnly);

      const timeOfTheEssenceObj = getBooleanPayload(
        'timeOfTheEssence',
        review.timeOfTheEssence,
      );

      return {
        ...review,
        cap: review.collar,
        ...upwardOnlyObj,
        ...timeOfTheEssenceObj,
      };
    });
  }

  if (input.act54) {
    output.act54 = transformBooleanPayload(input.act54);
  }

  if (input.linkedToTurnover) {
    output.linkedToTurnover = transformBooleanPayload(input.linkedToTurnover);
  }

  if (hasLandlordFieldsEntered) {
    output.landlord = transformCompanyPayload(
      defaultDataSource,
      output.landlord,
      output.landlordType,
      output.landlordNationality,
      output.landlordSector,
    );

    delete output.landlordSector;
    delete output.landlordNationality;
    delete output.landlordType;
  }

  if (input.renewRecords) {
    output.demises = output.renewRecords?.value?.reduce(
      (accum: [], record: any) => {
        if (!record.selectRecord || !record.selectRecord.value) {
          return accum;
        }

        if (!record.achievedRent || !record.achievedRent.value) {
          return [
            ...accum,
            {
              leaseId: record.leaseId.value,
            },
          ];
        }

        return [
          ...accum,
          {
            leaseId: record.leaseId.value,
            achievedRent: {
              value: record.achievedRent.value,
              subSource:
                record.achievedRent.subSource || output.renewRecords.subSource,
            },
          },
        ];
      },
      [],
    );

    delete output.isMultiDemiseDeal;
    delete output.renewRecords;
  }

  delete output.salePrice;
  delete output.transactionDate;

  delete output.showBreakOptions;
  delete output.showRentReviews;

  return { output, defaultDataSource };
};

type FormValue = {
  value: string;
  subSource?: string;
};

type CompanyAgentPreTransform = {
  company?: FormValue;
  companyName?: FormValue;
  companyId?: FormValue;
  email?: FormValue;
  name?: FormValue;
  phoneNumber?: FormValue;
};

const getCompanyPayload = (defaultDataSource: any, company?: FormValue) => {
  if (company) {
    return transformCompanyPayload(defaultDataSource, company, {
      value: STATE_FIELDS.COMPANY,
    });
  }
  return {};
};

export const mapSingleAgent = (defaultDataSource: any) => (
  agent: CompanyAgentPreTransform,
) => {
  const agentCompany = getCompanyPayload(defaultDataSource, agent.company);
  const { company, ...updatedAgents } = agent;

  return {
    ...agentCompany,
    ...updatedAgents,
  };
};

const mapAgents = ({ output: input, defaultDataSource }: any) => {
  const { landlordAgents, tenantAgents, ...rest } = input;

  const output = { ...rest };

  if (landlordAgents) {
    output.landlordAgents = landlordAgents?.map(
      mapSingleAgent(defaultDataSource),
    );
  }

  if (tenantAgents) {
    output.tenantAgents = tenantAgents?.map(mapSingleAgent(defaultDataSource));
  }

  return output;
};

const payloadTransformForApi = (formData: any) =>
  compose(mapAgents, addConditionalKeys)(formData, '');

export const payloadTransformForUpdates = (formData: any) => {
  const payload = payloadTransformForApi(formData);

  return Object.keys(payload).reduce((accum, key) => {
    const output: { [key: string]: any } = { ...accum };

    if (payload[key]) output[key] = payload[key];

    return output;
  }, {});
};

export default payloadTransformForApi;
