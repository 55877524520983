import React from 'react';

import { DOCUMENT_TYPES, Vertical } from 'globalConstants';
import { formatBytes } from 'utils/formatBytes';
import Select from 'components/Forms/Inputs/Select/Select';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import {
  FileItemLi,
  FileItemHeader,
  FileItemHeading,
  RemoveItemButton,
} from './FileItemStyled';
import {
  BUILDING_SERVICE_CHARGE_BUDGET,
  DOCUMENT_UPLOADER_COPY,
  PORTFOLIO_SALE_BROCHURE,
} from '../constants';
import { FileUploadType } from '../types';

type FileItemProps = {
  file: Pick<File, 'name' | 'size'>;
  removeFileItem: Function;
  onSelectFileType: (type: FileUploadType) => void;
  defaultSelectValue?: FileUploadType;
  disableFileTypeSelect?: boolean;
};

const FileItem = ({
  file,
  removeFileItem,
  onSelectFileType,
  defaultSelectValue,
  disableFileTypeSelect,
}: FileItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const vertical = useSelector(({ details }: RootState) => details.vertical!);
  let filteredDocumentTypes = DOCUMENT_TYPES;

  if ([Vertical.Lease, Vertical.ToLet].includes(vertical))
    filteredDocumentTypes = filteredDocumentTypes.filter(
      (documentType) => documentType.value !== PORTFOLIO_SALE_BROCHURE,
    );

  if (vertical !== Vertical.Building)
    filteredDocumentTypes = filteredDocumentTypes.filter(
      (documentType) => documentType.value !== BUILDING_SERVICE_CHARGE_BUDGET,
    );

  return (
    <FileItemLi>
      <FileItemHeader>
        <FileItemHeading>
          {file.name} <span>({formatBytes(file.size)})</span>
        </FileItemHeading>
        <RemoveItemButton onClick={() => removeFileItem()} />
      </FileItemHeader>
      <div>
        <Select
          options={filteredDocumentTypes}
          onSelectChange={onSelectFileType}
          label={DOCUMENT_UPLOADER_COPY.selectLabel}
          name="document-upload"
          selectedValue={defaultSelectValue}
          disabled={disableFileTypeSelect}
        />
      </div>
    </FileItemLi>
  );
};

export default FileItem;
