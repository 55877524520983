import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { globalContent, urls } from 'globalConstants';
import useOutsideClick from 'hooks/useOutsideClick';

import { FEATURE_FLAGS } from 'feature_flags/types';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { logout } from 'store/actions/loginActions';
import {
  Menu,
  AccountMenuContainer,
  ArrowDownIcon,
  MenuButton,
  MenuListItem,
  Button,
  ArrowUpIcon,
  MenuListItemLink,
} from './AccountMenuStyled';

const AccountMenu = ({
  name,
  menuOpen = false,
}: {
  name?: string;
  menuOpen?: boolean;
}) => {
  const accountMenuRef = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(menuOpen);

  const profileIsEnabled = useFeatureFlag(FEATURE_FLAGS.PROFILE);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  useOutsideClick(accountMenuRef, () => closeMenu(), menuIsOpen);

  const dispatch = useDispatch();

  return (
    <AccountMenuContainer ref={accountMenuRef}>
      <MenuButton isOpen={menuIsOpen} type="button" onClick={toggleMenu}>
        {name}
        {menuIsOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </MenuButton>

      {menuIsOpen && (
        <Menu>
          <ul>
            {profileIsEnabled && (
              <MenuListItem>
                <MenuListItemLink to={urls.profile}>
                  {globalContent.profile}
                </MenuListItemLink>
              </MenuListItem>
            )}
            {profileIsEnabled && (
              <MenuListItem>
                <MenuListItemLink
                  to={{ pathname: urls.training }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {globalContent.training}
                </MenuListItemLink>
              </MenuListItem>
            )}
            <MenuListItem>
              <Button type="button" onClick={() => dispatch(logout())}>
                {globalContent.logout}
              </Button>
            </MenuListItem>
          </ul>
        </Menu>
      )}
    </AccountMenuContainer>
  );
};

export default AccountMenu;
