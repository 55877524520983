import styled, { css } from 'styled-components/macro';
import { darken, lighten } from 'polished';

import { PanelTheme } from 'components/PanelWrapper/types';

import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';

interface PanelGroupHeadingProps {
  useTheme: PanelTheme;
}

interface DataItemProps {
  useTheme: PanelTheme;
  isEditable?: boolean;
  isWide: boolean;
  isExtraWide: boolean;
}

const getMultiChildStyles = (theme: any, isMultiChild?: boolean) => {
  if (isMultiChild) {
    return `
    margin-bottom: 6px;
    border: 1px solid ${darken(0.25, theme)};
    box-shadow: 0px 6px 0px white;`;
  }
  return ``;
};
export const PanelGroupContainer = styled.div<{
  isMultiChild?: boolean;
  useTheme: PanelTheme;
}>`
  ${(props) =>
    getMultiChildStyles(
      props.theme.colorPalette.panel[props.useTheme],
      props.isMultiChild,
    )}
`;

export const PanelGroupHeading = styled.h3<PanelGroupHeadingProps>`
  width: 100%;
  padding: 12px ${(props) => props.theme.spacing.small};
  background: ${(props) =>
    darken(0.05, props.theme.colorPalette.panel[props.useTheme])};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.secondary};
  margin: 0;
  box-shadow: 0 1px 0px #dcdcdc;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
`;

export const PanelGroupHeadingActions = styled.div`
  justify-self: flex-end;
`;

const getWidthStyles = (isWide?: boolean, isExtraWide?: boolean) => {
  if (isWide) {
    return css`
      grid-column: 2 span;
    `;
  }

  if (isExtraWide) {
    return css`
      grid-column: 4 span;
    `;
  }

  return ``;
};

export const DataItemWrapper = styled.li<DataItemProps>`
  border-radius: 4px;
  border: 2px solid
    ${(props) =>
      props.isEditable
        ? darken(0.2, props.theme.colorPalette.panel[props.useTheme])
        : props.theme.colorPalette.panel[props.useTheme]};

  background: ${(props) =>
    props.isEditable
      ? darken(0.05, props.theme.colorPalette.panel[props.useTheme])
      : props.theme.colorPalette.panel[props.useTheme]};
  padding: 8px;
  ${(props) => getWidthStyles(props.isWide, props.isExtraWide)}
`;

export const PanelGroupContents = styled.div`
  padding: ${(props) => props.theme.spacing.small};
`;

export const GroupDataPoints = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.tablet};

  @media (min-width: ${(props) => props.theme.breakpoints.xlarge}px) {
    grid-template-columns: repeat(8, minmax(120px, 1fr));
  }
`;

const getDisabledStyle = (theme: any, isDisabled?: boolean) => {
  if (isDisabled) {
    return theme.colorPalette.secondary.midGrey;
  }

  return theme.colorPalette.text.dark;
};

export const DataPointTitle = styled.h4<{ isDisabled?: boolean }>`
  margin: 0;
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  text-transform: uppercase;
  color: ${(props) => getDisabledStyle(props.theme, props.isDisabled)};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 300;
  letter-spacing: 0.3px;
  margin-bottom: 3px;
`;

export const DataPointValue = styled.span<{ isDisabled?: boolean }>`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  color: ${(props) => getDisabledStyle(props.theme, props.isDisabled)};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  white-space: pre-line;
  overflow-wrap: break-word;
  display: flex;

  button {
    text-align: left;
  }
`;

export const StyledButton = styled.button`
  font-weight: bold;
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  padding: 0;
  line-height: unset;
  text-align: left;
`;

export const StyledUnderlinedButton = styled.button`
  color: ${(props) => props.theme.colorPalette.secondary.red};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-style: italic;
  padding: 0;
  line-height: unset;
  text-align: left;
  text-decoration: underline;
`;

export const StyledExternal = styled.a`
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  text-decoration: underline;
`;

export const StyledEmptyButton = styled.span`
  color: ${(props) => props.theme.colorPalette.secondary.red};
  font-style: italic;
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: normal;
  text-decoration: underline;
  padding: 0;
  margin-top: 6px;
  line-height: unset;
  min-width: 60px;
  text-align: left;
  cursor: pointer;
`;
export const StyledEmptySpan = styled.span`
  color: ${(props) => props.theme.colorPalette.secondary.red};
  font-style: italic;
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  text-decoration: underline;
  padding: 0;
  line-height: unset;
  min-width: 60px;
  align-self: baseline;
  text-align: left;
  font-weight: normal;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WarningIcon = styled(withIconStyles(Warning))`
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 24px;
`;

export const DeleteMessage = styled.p`
  margin: 0;
  text-align: left;
`;

export const DeleteActions = styled.div`
  padding: ${(props) => props.theme.spacing.default};
  border-top: 1px solid
    ${(props) => lighten(0.5, props.theme.colorPalette.secondary.lightInk)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${(props) => props.theme.spacing.default};
`;

export const DeleteWrapper = styled.div`
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.default};
  line-height: 1.5;
  padding: ${(props) => props.theme.spacing.default};
  text-align: center;
`;
