import React from 'react';

import { Button } from 'components/CallToActions/Button/Button';
import {
  ModalCardWrapper,
  ModalCardHeader,
  GeneralModalTitle,
  ModalCardMain,
} from 'components/ModalCard/ModalCard';
import { ModalPromptActions } from 'components/ModalPrompt/ModalPromptStyled';
import {
  InfoIcon,
  ReleaseNotesBodyWrapper,
} from 'connected/ReleaseNotes/ReleaseNotesStyled';
import { useDispatch, useSelector } from 'react-redux';
import { globalModalHide } from 'store/actions/globalModalActions';
import {
  getScheduleTeamQueryResultFailedIds,
  getScheduleTeamQueryResultSuccessIds,
} from 'store/selectors/scheduleTeamQuerySelectors';
import { ADMIN_QUERY_PANEL_COPY } from './constants';

const ScheduleTeamQueryResult: React.FC = () => {
  const dispatch = useDispatch();
  const successIds = useSelector(getScheduleTeamQueryResultSuccessIds);
  const failedIds = useSelector(getScheduleTeamQueryResultFailedIds);

  const handleClick = () => {
    dispatch(globalModalHide());
  };
  return (
    <ModalCardWrapper>
      <ModalCardHeader>
        <InfoIcon />
        <GeneralModalTitle>
          {ADMIN_QUERY_PANEL_COPY.resultsModal.title}
        </GeneralModalTitle>
      </ModalCardHeader>
      <ModalCardMain>
        <ReleaseNotesBodyWrapper>
          <p>{ADMIN_QUERY_PANEL_COPY.resultsModal.successIds}</p>
          {successIds && successIds.length > 0 ? (
            <ul>
              {successIds.map((id) => (
                <li key={id}>{id}</li>
              ))}
            </ul>
          ) : (
            <p>&#8212;</p>
          )}
          <p>{ADMIN_QUERY_PANEL_COPY.resultsModal.failedIds}</p>
          {failedIds && failedIds.length > 0 ? (
            <ul>
              {failedIds.map((id) => (
                <li key={id}>{id}</li>
              ))}
            </ul>
          ) : (
            <p>&#8212;</p>
          )}
        </ReleaseNotesBodyWrapper>
        <ModalPromptActions actionError>
          <Button onClick={handleClick}>
            {ADMIN_QUERY_PANEL_COPY.resultsModal.ok}
          </Button>
        </ModalPromptActions>
      </ModalCardMain>
    </ModalCardWrapper>
  );
};

export default ScheduleTeamQueryResult;
