import { isJsonString } from 'utils/string';
import { Option } from 'components/Forms/types';

export const parseSelectedValue = (
  valueToParse: string[],
): Option[] | undefined => {
  return valueToParse?.map((value: string) => {
    if (!isJsonString(value)) {
      return { value, label: value };
    }
    const parsed = JSON.parse(value);
    return {
      value: parsed.id || parsed.companyRegistrationNumber,
      label: parsed.name,
    };
  });
};
