import React from 'react';

interface ContextProps {
  formState: any;
  initialState: any;
  viewId: string;
  dispatchFormState: ({
    type,
    payload,
  }: {
    type: string;
    payload: any;
  }) => void;
}

export const FormStateContext = React.createContext({} as ContextProps);
