import React from 'react';
import { useSelector } from 'react-redux';
import DocumentUploader from 'connected/DocumentUploader/DocumentUploader';
import {
  hasDocumentUploadedSelector,
  documentSelector,
} from 'store/selectors/newRecordSelectors';
import FileWithDelete from './FileWithDelete';

import { UploadDeleteProps } from './types';

const UploadDelete = ({
  entityId,
  onDocumentUploadChange,
  preselectedFileType,
}: UploadDeleteProps) => {
  const filename = useSelector(documentSelector);
  const hasDocument = useSelector(hasDocumentUploadedSelector);

  return (
    <>
      {hasDocument && (
        <FileWithDelete
          fileToDelete={{
            entityId,
            filename,
          }}
          onDocumentUploadChange={onDocumentUploadChange}
        />
      )}
      {!hasDocument && (
        <DocumentUploader
          entityId={entityId}
          onCancelClick={() => {}}
          onDocumentUploadChange={onDocumentUploadChange}
          preselectedFileType={preselectedFileType}
          disableFileTypeSelect
          showErrorPrompts={false}
          showUploadControls={false}
          uploaderContextInFormFlow
        />
      )}
    </>
  );
};

export default UploadDelete;
