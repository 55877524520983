import styled from 'styled-components/macro';

export const ActiveStyled = styled.b`
  color: ${(props) => props.theme.colorPalette.tertiary.datavizGreen};
`;

export const FrozenStyled = styled.b`
  color: ${(props) => props.theme.colorPalette.secondary.red};
`;

export const StyledForm = styled.div`
  padding: ${(props) => props.theme.spacing.default};
`;

export const FormButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing.default};
`;

export const FormCheckboxes = styled.div`
  margin-top: ${(props) => props.theme.spacing.default};
`;

export const UserStatusCell = styled.div`
  min-width: 160px;
`;

export const ChangeBtn = styled.button`
  color: ${(props) => props.theme.colorPalette.secondary.red};
  font-style: italic;
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.secondary};
  text-decoration: underline;
`;
