import {
  GlobalModalActionTypes,
  GlobalModalHideAction,
  GlobalModalHideSingleAction,
  GlobalModalShowAction,
} from 'connected/GlobalModal/types';
import { Vertical } from 'globalConstants';

export const globalModalShow = (
  modal: string,
  shouldRemainInPlace?: boolean,
  meta?: any,
  recordId?: string,
  vertical?: Vertical,
  modalType?: string,
): GlobalModalShowAction => ({
  type: GlobalModalActionTypes.SHOW_MODAL,
  payload: {
    modal,
    shouldRemainInPlace: shouldRemainInPlace || false,
    recordId: recordId || '',
    vertical,
    modalType: modalType || '',
  },
  meta,
});

export const globalModalHide = (payload?: string): GlobalModalHideAction => ({
  type: GlobalModalActionTypes.HIDE_MODAL,
  payload,
});

export const globalSingleModalHide = (
  payload?: string,
): GlobalModalHideSingleAction => ({
  type: GlobalModalActionTypes.HIDE_SINGLE_MODAL,
  payload,
});
