import { Reducer } from 'redux';

import {
  PortfolioNameSelectActions,
  PortfolioNameSelectActionTypes,
} from 'connected/PortfolioNameSelect/types';

export interface PortfolioNameSelectState {
  isFetching: boolean;
  results: string[];
  filteredResults: string[];
  searchTerm: string;
  error?: string;
}

export const initialState: PortfolioNameSelectState = {
  isFetching: false,
  searchTerm: '',
  results: [],
  filteredResults: [],
};

const portfolioNameSelectReducer: Reducer<
  PortfolioNameSelectState,
  PortfolioNameSelectActions
> = (state = initialState, action: PortfolioNameSelectActions) => {
  switch (action.type) {
    case PortfolioNameSelectActionTypes.GET_PORTFOLIO_NAMES: {
      return {
        ...state,
        isFetching: true,
        filteredResults: [],
      };
    }

    case PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_QUERY: {
      return {
        ...state,
        searchTerm: action.payload,
        filteredResults: [],
      };
    }

    case PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_API_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    }

    case PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_FILTERED_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        filteredResults: action.payload,
      };
    }

    case PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_API_FAIL: {
      return {
        ...state,
        isFetching: false,
        results: [],
        error: action.payload.error,
      };
    }

    case PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_RESET: {
      return {
        ...state,
        isFetching: false,
        searchTerm: '',
        results: [],
        filteredResults: [],
      };
    }

    case PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_CLEAR_SELECTED: {
      return {
        ...state,
        searchTerm: '',
        filteredResults: [],
      };
    }

    default:
      return state;
  }
};

export default portfolioNameSelectReducer;
