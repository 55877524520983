import styled from 'styled-components/macro';

export const SidebarTitle = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-weight: bold;
`;

export const SidebarBody = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-family: ${(props) => props.theme.fonts.secondary};
`;
