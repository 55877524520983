import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  documentDownloadApiSuccess,
  documentDownloadApiFail,
} from 'store/actions/documentPanelActions';
import { DocumentPanelActionTypes } from 'connected/DocumentPanel/types';

type DocumentDownloadEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const documentDownloadEpic: Epic = (
  action$,
  state$,
  { authRequest }: DocumentDownloadEpicDependencies,
) => {
  return action$.pipe(
    ofType(DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_GET_SIGNED_URL),
    switchMap((action) => {
      const { entityId, filename } = action.payload;
      const url = endpoints.document.download(entityId, filename);
      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }
          return documentDownloadApiSuccess(results.response);
        }),
      );
    }),
    catchError((error) =>
      of(
        {
          type: 'ERROR',
          payload: error,
          error: true,
        },
        documentDownloadApiFail(error),
      ),
    ),
  );
};

export default documentDownloadEpic;
