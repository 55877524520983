import { AthenaUserPayload } from 'store/reducers/athenaUserReducer';
import { GlobalApiFailError } from './types';

export enum AthenaUserActionTypes {
  SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION = 'SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION',
  SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION_API_SUCCESS = 'SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION_API_SUCCESS',
  SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION_API_FAIL = 'SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION_API_FAIL',

  FETCH_ATHENA_USER = 'FETCH_ATHENA_USER',
  FETCH_ATHENA_USER_SUCCESS = 'FETCH_ATHENA_USER_SUCCESS',
  FETCH_ATHENA_USER_FAIL = 'FETCH_ATHENA_USER_FAIL',
}

export type SaveAthenaUserPayloadType = {
  version: string;
};
export type AthenaUserPayloadType = {
  viewedReleaseNotesVersion: string;
  viewedReleaseNotesVersionDate: string;
};

export type FetchAthenaUserAction = {
  type: AthenaUserActionTypes.FETCH_ATHENA_USER;
};
export const fetchAthenaUser = (): FetchAthenaUserAction => ({
  type: AthenaUserActionTypes.FETCH_ATHENA_USER,
});

export type FetchAthenaUserSuccessActionType = {
  type: AthenaUserActionTypes.FETCH_ATHENA_USER_SUCCESS;
  payload: AthenaUserPayload;
};
export const fetchAthenaUserAPISuccess = (
  payload: AthenaUserPayload,
): FetchAthenaUserSuccessActionType => ({
  type: AthenaUserActionTypes.FETCH_ATHENA_USER_SUCCESS,
  payload,
});

export type FetchAthenaUserFailActionType = {
  type: AthenaUserActionTypes.FETCH_ATHENA_USER_FAIL;
  payload: GlobalApiFailError;
};
export const fetchAthenaUserAPIFail = (
  payload: GlobalApiFailError,
): FetchAthenaUserFailActionType => ({
  type: AthenaUserActionTypes.FETCH_ATHENA_USER_FAIL,
  payload,
});

export type SaveUserAction = {
  type: AthenaUserActionTypes.SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION;
  payload: SaveAthenaUserPayloadType;
};
export const saveAthenaUserViewedReleaseNotesVersion = (
  payload: SaveAthenaUserPayloadType,
): SaveUserAction => ({
  type: AthenaUserActionTypes.SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION,
  payload,
});
export const saveAthenaUserAPISuccess = () => ({
  type:
    AthenaUserActionTypes.SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION_API_SUCCESS,
});
export const saveAthenaUserAPIFail = (payload: GlobalApiFailError) => ({
  type:
    AthenaUserActionTypes.SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION_API_FAIL,
  payload,
});
