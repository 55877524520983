import compose from 'ramda/src/compose';
import { STATE_FIELDS } from 'connected/FormFlow/constants';
import {
  mapAgentsPayload,
  transformBooleanPayload,
  transformCompanyPayload,
} from 'connected/NewRecordWrapper/helpers';

const addConditionalKeys = (input: any, defaultDataSource: string) => {
  const output = input;
  if (input.isConfidential) {
    output.isConfidential = transformBooleanPayload(input.isConfidential);
  }

  if (input.vacantPossession) {
    output.vacantPossession = transformBooleanPayload(input.vacantPossession);
  }
  if (input.hasBuyerAgent) {
    output.hasBuyerAgent = transformBooleanPayload(input.hasBuyerAgent);
  }
  if (input.hasSellerAgent) {
    output.hasSellerAgent = transformBooleanPayload(input.hasSellerAgent);
  }

  if (input.saleBuyerInvestor) {
    output.ownerOccupiedPurchased =
      input.saleBuyerInvestor.value === STATE_FIELDS.OWNER;
  }

  if (input.onMarket) {
    output.onMarket = transformBooleanPayload(input.onMarket);
  }

  if (input.holdingEntitySPV && input.holdingEntitySPV.value !== 'no') {
    output.holdingEntity =
      input.holdingEntitySPV.value === 'yes-know'
        ? input.holdingEntityDetails.map((entityCompany: any) => {
            return transformCompanyPayload(
              defaultDataSource,
              entityCompany.holdingEntityCompanyName,
              entityCompany.sellerType,
              entityCompany.holdingEntityNationality,
              entityCompany.holdingEntitySector,
            );
          })
        : [{}];

    delete output.holdingEntitySPV;
    delete output.holdingEntityDetails;
    delete output.holdingEntityUnknown;
  }

  if (input['longLeaseholdInformation|gearing']) {
    output['longLeaseholdInformation|gearing'] = transformBooleanPayload(
      input['longLeaseholdInformation|gearing'],
    );
  }

  if (input.availabilityStatus) {
    switch (input.availabilityStatus.value) {
      case 'available':
        output.askingPrice = input.salePrice;
        output.listingDate = input.transactionDate;
        break;
      case 'under-offer':
        output.offerPrice = input.salePrice;
        output.underOfferDate = input.transactionDate;
        break;
      case 'sold':
        output.achievedPrice = input.salePrice;
        output.soldDate = input.transactionDate;
        break;
      case 'withdrawn':
        output.askingPrice = input.salePrice;
        output.withdrawnDate = input.transactionDate;
        break;
      default:
    }
  }
  delete output.salePrice;
  delete output.transactionDate;

  return { output, defaultDataSource };
};

const mapAgents = ({ output: input, defaultDataSource }: any) => {
  const { sellerAgents, buyerAgents, ...rest } = input;
  const output = { ...rest };
  if (sellerAgents) {
    output.sellerAgents = mapAgentsPayload(sellerAgents, defaultDataSource);
  }
  if (buyerAgents) {
    output.buyerAgents = mapAgentsPayload(buyerAgents, defaultDataSource);
  }

  if (output.freeholder || output.freeholderNationality) {
    output.freeholder = transformCompanyPayload(
      defaultDataSource,
      output.freeholder,
      output.freeholderType,
      output.freeholderNationality,
      output.freeholderSector,
    );
    delete output.freeholderSector;

    delete output.freeholderNationality;
    delete output.freeholderType;
  }
  return { output, defaultDataSource };
};

export const mapCompanies = ({ output: input, defaultDataSource }: any) => {
  const { sellerDetails, buyerDetails, ...rest } = input;
  const output = { ...rest };

  output.sellerCompanies = sellerDetails?.map((sellerCompany: any) => {
    return transformCompanyPayload(
      defaultDataSource,
      sellerCompany.sellerCompanyName,
      sellerCompany.sellerType,
      sellerCompany.sellerNationality,
      sellerCompany.sellerSector,
    );
  });

  output.buyerCompanies = buyerDetails?.map((buyerCompany: any) => {
    return transformCompanyPayload(
      defaultDataSource,
      buyerCompany.buyerCompanyName,
      buyerCompany.buyerInvestorOccupier,
      buyerCompany.buyerNationality,
      buyerCompany.buyerSector,
      buyerCompany.buyerEquityStake,
      buyerCompany.buyerInvestorOccupier,
    );
  });

  return output;
};

const payloadTransformForApi = (formData: any) =>
  compose(mapCompanies, mapAgents, addConditionalKeys)(formData, '');

export const payloadTransformForUpdates = (formData: any) => {
  const payload = payloadTransformForApi(formData);
  return Object.keys(payload).reduce((accum, key) => {
    const output: { [key: string]: any } = { ...accum };
    if (payload[key]) output[key] = payload[key];

    return output;
  }, {});
};

export default payloadTransformForApi;
