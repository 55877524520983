import styled from 'styled-components/macro';

export const Headline = styled.h1`
  margin: 5px 0;
`;

export const Paragraph = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    font-size: ${(props) => props.theme.typography.paragraph.small};
  }
  align-self: right;
  margin: 0;
  line-height: 1.75;

  a {
    color: ${(props) => props.theme.colorPalette.branding.white};
    text-decoration: underline;
  }
`;
