import { isJsonString } from 'utils/string';
import { STATE_FIELDS } from '../constants';
import {
  COMPANY_FIELDS,
  COMPANY_TYPE,
  LineConfigProps,
  InvestorRow,
  FieldProps,
} from '../types';

export const getCompanyType = (value: string | number | Date) => {
  const formattedVal =
    typeof value === 'string' && isJsonString(value)
      ? JSON.parse(value)
      : value;

  if (formattedVal.id) {
    return COMPANY_TYPE.DB;
  }
  if (formattedVal.companyRegistrationNumber) {
    return COMPANY_TYPE.CH;
  }
  return COMPANY_TYPE.MANUAL;
};

export const getCompanyField = (line: LineConfigProps[], key: string) => {
  if (Array.isArray(line)) {
    return line.find((lineItem) => lineItem.fieldType === key);
  }

  return null;
};

const PRIVATE_ENTITY_NAMES = ['private-individual', 'investor-private'];

export const shouldShowCompanyField = (
  fieldType: string,
  companyName?: string,
  type?: string,
) => {
  if (type && PRIVATE_ENTITY_NAMES.includes(type)) return true;

  if (
    fieldType !== COMPANY_FIELDS.SECTOR &&
    fieldType !== COMPANY_FIELDS.NATIONALITY
  ) {
    return true;
  }

  if (companyName) {
    const companyType = getCompanyType(companyName);

    if (companyType === COMPANY_TYPE.DB) {
      return false;
    }

    return true;
  }

  return false;
};

export const determineCompanyRowFieldVisibility = (
  row: any,
  field: any,
  configs: LineConfigProps[],
  key: 'sellerType' | 'buyerInvestorOccupier',
) => {
  const type = row[key]?.value;
  const companyNameField = getCompanyField(configs, 'COMPANY_NAME');
  const companyNameValue =
    row && companyNameField && row[companyNameField.name];

  return shouldShowCompanyField(
    field.fieldType,
    companyNameValue && companyNameValue.value,
    type,
  );
};

export const isInvestorSelected = (row: InvestorRow[]) => {
  return !!row.find(
    (item) =>
      item.buyerInvestorOccupier?.value === STATE_FIELDS.INVESTOR ||
      item.buyerInvestorOccupier?.value === STATE_FIELDS.PRIVATE_INVESTOR ||
      item.holdingEntityInvestorOccupier?.value === STATE_FIELDS.INVESTOR ||
      item.holdingEntityInvestorOccupier?.value ===
        STATE_FIELDS.PRIVATE_INVESTOR,
  );
};

const EQUITY_FIELDS = [STATE_FIELDS.BUYER.EQUITY, STATE_FIELDS.SPV.EQUITY];

export const isReadOnlyField = (rowFields: any, field: FieldProps) => {
  if (
    EQUITY_FIELDS.includes(field.name) &&
    (!isInvestorSelected(rowFields) || rowFields.length === 1)
  ) {
    return true;
  }

  return false;
};
