import { Vertical } from 'globalConstants';
import { DebtRecord } from 'pages/Details/types';
import { GlobalApiFailError } from 'store/actions/types';

export interface DebtPanelProps {
  onPanelToggleCb?: (anchorId: string) => void;
  modalIsOpen?: boolean;
  modalId?: string;
  isCompany?: boolean;
}

export interface AddDebtProps {
  onCancelClick: () => void;
  vertical: Vertical;
  resourceId: string;
}

export enum DebtsActionTypes {
  ADD_DEBT = 'ADD_DEBT',
  ADD_DEBT_API_FAIL = 'ADD_DEBT_API_FAIL',
  ADD_DEBT_API_SUCCESS = 'ADD_DEBT_API_SUCCESS',
  DELETE_DEBT = 'DELETE_DEBT',
  DELETE_DEBT_API_FAIL = 'DELETE_DEBT_API_FAIL',
  DELETE_DEBT_API_SUCCESS = 'DELETE_DEBT_API_SUCCESS',
  EDIT_DEBT = 'EDIT_DEBT',
  EDIT_DEBT_API_FAIL = 'EDIT_DEBT_API_FAIL',
  EDIT_DEBT_API_SUCCESS = 'EDIT_DEBT_API_SUCCESS',
}

export type DeleteDebtPayload = {
  id: string;
  entity: string;
  property: string;
  value: boolean;
  subSource: string;
};

export type AddDebtPayload = {
  debt: DebtRecord;
  buildingId: string;
  vertical: Vertical;
};

export type EditDebtPayload = {
  debt: DebtRecord;
  resourceId: string;
  vertical: Vertical;
};

export type AddDebtAction = {
  type: DebtsActionTypes.ADD_DEBT;
  payload: AddDebtPayload;
};

export type AddDebtApiFailAction = {
  type: DebtsActionTypes.ADD_DEBT_API_FAIL;
  payload: GlobalApiFailError;
};

export type AddDebtSuccessAction = {
  type: DebtsActionTypes.ADD_DEBT_API_SUCCESS;
};

export type EditDebtAction = {
  type: DebtsActionTypes.EDIT_DEBT;
  payload: EditDebtPayload;
};

export type EditDebtApiFailAction = {
  type: DebtsActionTypes.EDIT_DEBT_API_FAIL;
  payload: GlobalApiFailError;
};

export type EditDebtSuccessAction = {
  type: DebtsActionTypes.EDIT_DEBT_API_SUCCESS;
};

export type DebtDeleteAction = {
  type: DebtsActionTypes.DELETE_DEBT;
  payload: DeleteDebtPayload;
};

export type DebtDeleteApiFailAction = {
  type: DebtsActionTypes.DELETE_DEBT_API_FAIL;
  payload: GlobalApiFailError;
};

export type DebtDeleteSuccessAction = {
  type: DebtsActionTypes.DELETE_DEBT_API_SUCCESS;
};

export type DebtsActions =
  | AddDebtAction
  | AddDebtApiFailAction
  | AddDebtSuccessAction
  | EditDebtAction
  | EditDebtApiFailAction
  | EditDebtSuccessAction
  | DebtDeleteAction
  | DebtDeleteApiFailAction
  | DebtDeleteSuccessAction;
