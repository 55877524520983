import styled from 'styled-components/macro';

export const ListStyled = styled.ul`
  margin: ${(props) => props.theme.spacing.small} 0;
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const ListItemStyled = styled.li`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
`;
