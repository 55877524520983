import React from 'react';
import {
  NotificationPanelWrapper,
  WarningIcon,
  InfoIcon,
  ErrorIcon,
  SuccessIcon,
  SuccessIconWrapper,
  NotificationContentWrapper,
  NotificationHeading,
  NotificationParagraph,
  NotificationBody,
} from './NotificationPanelStyled';
import { NOTIFICATION_TYPES, NotificationPanelProps } from './types';

const NotificationPanel = ({
  notificationTitle,
  notificationBody,
  notificationType,
}: NotificationPanelProps) => (
  <NotificationPanelWrapper type={notificationType}>
    {notificationType === NOTIFICATION_TYPES.WARNING && <WarningIcon />}
    {notificationType === NOTIFICATION_TYPES.INFORMATION && <InfoIcon />}
    {notificationType === NOTIFICATION_TYPES.ERROR && <ErrorIcon />}
    {notificationType === NOTIFICATION_TYPES.SUCCESS && (
      <SuccessIconWrapper>
        <SuccessIcon />
      </SuccessIconWrapper>
    )}

    <NotificationContentWrapper>
      <NotificationHeading>{notificationTitle}</NotificationHeading>
      {typeof notificationBody === 'string' ? (
        <NotificationParagraph>{notificationBody}</NotificationParagraph>
      ) : (
        <NotificationBody>{notificationBody}</NotificationBody>
      )}
    </NotificationContentWrapper>
  </NotificationPanelWrapper>
);

export default NotificationPanel;
