import pathOr from 'ramda/src/pathOr';
import { LeaseRecord, SaleRecord } from 'pages/Details/types';
import { dynamicString, isJsonString } from 'utils/string';
import {
  FindMatchesProps,
  MatchesFilter,
  FormViewConfig,
  MatchType,
  FindMatchesNoticeProps,
} from '../types';

/**
 * @param  {(SaleRecord|LeaseRecord)[]} records sale or lease record returned from API
 * @param  {any} formState
 * @param  {MatchesFilter[]} filters list of filters set in the config
 */
export const filterRecords = (
  records: (SaleRecord | LeaseRecord)[],
  formState: any,
  filters?: MatchesFilter[],
) => {
  if (!filters) return records;
  return records.reduce((accum: (SaleRecord | LeaseRecord)[], record: any) => {
    const matchingFilter = filters.every((filter) => {
      const { key, subKey, value, ref, alsoAllow } = filter;
      const recordValue: any = pathOr('', key.split('.'), record);
      if (value) {
        return value.includes(recordValue);
      }
      if (ref) {
        const formValue: string = pathOr('', ref.split('.'), formState);
        if (subKey && Array.isArray(recordValue)) {
          if (Array.isArray(formValue)) {
            const matchingRecords = recordValue.reduce((acc: any, val: any) => {
              const formArrayItem = formValue.find((item) => {
                return val[subKey] === item[subKey];
              });
              if (formArrayItem) return [...accum, formArrayItem];
              return acc;
            }, []);
            return matchingRecords.length > 0;
          }
          return recordValue.find((val: any) => val[subKey] === formValue);
        }
        if (
          subKey &&
          Object.prototype.hasOwnProperty.call(recordValue, subKey)
        ) {
          if (isJsonString(formValue)) {
            const parsed = JSON.parse(formValue);
            return recordValue[subKey] === parsed.name;
          }
          if (Array.isArray(formValue)) {
            const formArrayItem = formValue.find((item) => {
              return recordValue[subKey] === item[subKey];
            });
            if (formArrayItem) return true;
          }
          return recordValue[subKey] === formValue;
        }
        if (
          alsoAllow &&
          alsoAllow.find((allowArr: string[]) => allowArr.includes(formValue))
        ) {
          const allow = alsoAllow.find((allowArr: string[]) =>
            allowArr.includes(formValue),
          );
          return allow && allow.includes(recordValue);
        }
        return recordValue === formValue;
      }
      return false;
    });

    if (matchingFilter) return [...accum, record];

    return accum;
  }, []);
};

/**
 * Filter returned records to return if matching records exist for the view
 * @param  {FindMatchesProps} matches findMatches set within the config
 * @param  {any} formState
 * @param  {(SaleRecord|LeaseRecord)[]} existingRecords records returned from the API
 */
export const evaluateViewMatches = (
  matches: FindMatchesProps,
  formState: any,
  existingRecords: (SaleRecord | LeaseRecord)[],
) => {
  const checkMatchExists = (records: (SaleRecord | LeaseRecord)[]) => {
    const filteredMatches =
      records && matches.filters && matches.filters.length > 0
        ? filterRecords(records, formState, matches.filters)
        : records;

    return filteredMatches && filteredMatches.length > 0;
  };

  return checkMatchExists(existingRecords);
};

export const getFindMatchesProps = (
  formState: any,
  viewConfig: FormViewConfig,
  records: (SaleRecord | LeaseRecord)[],
) => {
  const { findMatches } = viewConfig;
  const filteredRecordsByType = records.filter((record) => {
    return findMatches && findMatches.matchType === MatchType.SALE
      ? Object.prototype.hasOwnProperty.call(record, 'saleId')
      : Object.prototype.hasOwnProperty.call(record, 'leaseId');
  });
  const returnedMatches =
    findMatches &&
    filterRecords(filteredRecordsByType, formState, findMatches.filters);

  return returnedMatches
    ? {
        ...findMatches,
        returnedMatches,
      }
    : {};
};

export const showMatchesNotice = (
  match: any,
  formState: any,
  hasNotice?: FindMatchesNoticeProps,
) => {
  if (!hasNotice) return undefined;
  const { ref, value, statePath } = hasNotice.conditional;

  const selectedStatus: string = pathOr('', statePath.split('.'), formState);
  const formattedNotice = dynamicString(hasNotice.noticeText, [
    `"${match[ref]}"`,
  ]);
  return value.includes(selectedStatus) && value.includes(match[ref])
    ? formattedNotice
    : undefined;
};
