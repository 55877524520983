import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as AddIconDuplicate } from 'assets/icons/add-circle-outline-duplicate.svg';

import { ControlContainer, StyledAddIcon, AddBatchLabel } from './BatchControl';

interface RepeatableControlProps {
  addRepeatable?: string;
  addButton?: string;
  hideRepeatable?: boolean;
  handleAddSingleRepeat: () => void;
  handleAddRepeatable: () => void;
  disabled?: boolean;
}

export const StyledAddDuplicateIcon = styled(AddIconDuplicate)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const AddIcon = styled(StyledAddIcon)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const AddButton = styled(AddBatchLabel)`
  padding-left: 24px;
  position: relative;
  text-decoration: underline;
  text-transform: uppercase;

  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.colorPalette.secondary.midGrey};

    svg {
      fill: ${(props) => props.theme.colorPalette.secondary.midGrey};
    }
  }
`;

const RepeatableControl = ({
  addRepeatable,
  addButton,
  handleAddSingleRepeat,
  handleAddRepeatable,
  hideRepeatable,
  disabled,
}: RepeatableControlProps) => {
  return (
    <ControlContainer>
      {!hideRepeatable && (
        <AddButton
          as="button"
          type="button"
          onClick={handleAddRepeatable}
          disabled={disabled}
        >
          <StyledAddDuplicateIcon />
          {addRepeatable}
        </AddButton>
      )}

      <AddButton
        as="button"
        type="button"
        disabled={disabled}
        onClick={handleAddSingleRepeat}
      >
        <AddIcon />
        {addButton}
      </AddButton>
    </ControlContainer>
  );
};

export default RepeatableControl;
