import React from 'react';
import { StyledToggleButton, StyledToggleIcon } from './ToggleButtonStyled';
import { ToggleButtonProps } from './types';

export const ToggleButton = ({ onClick, children }: ToggleButtonProps) => (
  <StyledToggleButton type="button" onClick={onClick}>
    {children}
    <StyledToggleIcon aria-hidden="true" />
  </StyledToggleButton>
);
