import styled from 'styled-components/macro';

export const StyledForm = styled.form`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr;
  height: 100%;
  margin: 0;
`;

export const StyledFormMain = styled.div`
  grid-row: 1;
  grid-column: 2;
  overflow-y: auto;
`;

export const FormFlowErrors = styled.div`
  margin: 0 ${(props) => props.theme.spacing.large};
`;
