import React, { ChangeEvent, useState, useEffect } from 'react';
import { getYear, addYears, differenceInYears } from 'date-fns';
import { INPUT_FORMAT_TYPE } from 'globalConstants';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import TextInput from '../TextInput/TextInput';
import { DateLengthLabels, DateLengthProps, DateLengthTypes } from './types';
import {
  DATE_VALIDATION,
  DISABLED_DEFAULT,
  FIELDS,
  MAX_DATE,
  MIN_DATE,
  PLACEHOLDER,
} from './constants';

import {
  convertToValidDateFormat,
  getDefaultEndYear,
  getEndDate,
  getEndYear,
  getLabel,
  getStartDate,
} from './helpers';
import { LengthWrapper } from './DateLengthStyled';

const DateLength = ({
  label,
  name,
  onChange,
  validation,
  selectedValue,
  startDate,
  inlineLabels,
}: DateLengthProps) => {
  const [selectedYear, setSelectedYear] = useState<string | undefined>('');
  const [selectedLength, setSelectedLength] = useState<string | undefined>('');

  const lengthLabel = getLabel(DateLengthLabels.LENGTH, inlineLabels);

  const yearLabel = getLabel(DateLengthLabels.END_YEAR, inlineLabels);

  const startYear = getStartDate(startDate);

  const resetFields = () => {
    setSelectedYear('');
    setSelectedLength('');
    onChange();
    onChange('', FIELDS.LENGTH.LABEL, FIELDS.LENGTH.KEY);
  };

  useEffect(() => {
    const updateYearLength = () => {
      if (!startDate) {
        resetFields();
      } else {
        if (!selectedLength) return;
        const endYear = getEndYear(startDate, selectedLength);
        setSelectedYear(`${endYear}`);
      }
    };
    updateYearLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    const setDefaultYearLength = () => {
      if (!startDate || !selectedValue) return;
      const defaultYearLength = getDefaultEndYear(startDate, selectedValue);
      setSelectedYear(`${defaultYearLength.endYear}`);
      setSelectedLength(`${defaultYearLength.leaseLength}`);
    };
    if (selectedValue) setDefaultYearLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: DateLengthTypes,
  ) => {
    if (!startDate) return;
    const parsedStartDate = new Date(startDate);
    const val = parseInt(event.target.value, 10);
    let endDate;
    if (Number.isNaN(val)) {
      resetFields();
      return;
    }
    if (type === DateLengthTypes.LENGTH) {
      endDate = addYears(parsedStartDate, val);
      const endYear = getYear(endDate);
      const updatedValues = {
        length: val,
        year:
          endYear > 9999 || Number.isNaN(endYear)
            ? DISABLED_DEFAULT
            : `${endYear}`,
        date: endYear > 9999 ? MAX_DATE : convertToValidDateFormat(endDate),
      };
      setSelectedLength(`${updatedValues.length}`);
      if (val < 1) return;
      setSelectedYear(updatedValues.year);
      onChange(updatedValues.date);
      onChange(updatedValues.length, FIELDS.LENGTH.LABEL, FIELDS.LENGTH.KEY);
      return;
    }
    setSelectedYear(`${val}`);

    endDate = getEndDate(parsedStartDate, val);
    const leaseLength = differenceInYears(endDate, parsedStartDate);

    setSelectedLength(`${leaseLength}`);
    onChange(convertToValidDateFormat(endDate));
    onChange(leaseLength, FIELDS.LENGTH.LABEL, FIELDS.LENGTH.KEY);
  };

  const resetOnUnMount = () => {
    return () => {
      setSelectedYear('');
      setSelectedLength('');
    };
  };

  useEffect(resetOnUnMount, []);

  return (
    <>
      <LengthWrapper>
        <TextInput
          label={lengthLabel ? lengthLabel.label : label}
          name={`${name}_${DateLengthTypes.LENGTH}`}
          validation={{
            ...validation,
            match: DATE_VALIDATION,
            minValue: MIN_DATE,
          }}
          isDisabled={!startDate}
          formatInput={INPUT_FORMAT_TYPE.NUMERIC}
          value={selectedLength}
          onChange={(e: any) => handleOnChange(e, DateLengthTypes.LENGTH)}
          allowNegative={false}
          componentType={FIELD_COMPONENTS.DATE_LENGTH}
        />
      </LengthWrapper>

      <LengthWrapper>
        <TextInput
          label={yearLabel ? yearLabel.label : label}
          name={`${name}_${DateLengthTypes.YEAR}`}
          validation={{
            ...validation,
            minValue: startYear ? startYear + 1 : null,
          }}
          isDisabled={!startDate || selectedYear === DISABLED_DEFAULT}
          allowNegative={false}
          numberFormat={PLACEHOLDER}
          formatInput={INPUT_FORMAT_TYPE.NUMERIC}
          value={selectedYear}
          onChange={(e) => handleOnChange(e, DateLengthTypes.YEAR)}
          componentType={FIELD_COMPONENTS.YEAR_INPUT}
        />
      </LengthWrapper>
    </>
  );
};

export default DateLength;
