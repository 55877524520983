import { GlobalApiFailError } from 'store/actions/types';

import { DrawTools } from 'components/GoogleMap';
import { MapPolygon, MapPolygonWithBoundary } from 'components/GoogleMap/types';

import {
  MapActionTypes,
  MapApiSuccessAction,
  MapApiFailAction,
  MapFetchSuggestedPlacesAction,
  MapFetchBoundaryAction,
  MapFetchBoundarySuccessAction,
  MapFetchBoundaryFailAction,
  ResourceIdentifiers,
  PlacesPayload,
  MapResetAction,
  MapInitSetAction,
  SelectPlacePayload,
  MapUpdateSelectedPlaceAction,
  GeocodePayload,
  MapGetGeoCodeInfoAction,
  SelectDrawToolAction,
  MapAddShapeAction,
  MapShapes,
  MapCircle,
  MapCircleMoveEndAction,
  MapPolygonEditAction,
  BoundariesType,
} from 'pages/QueryBuilder/types';

export const mapFetchSuggestedPlaces = (
  payload: ResourceIdentifiers,
): MapFetchSuggestedPlacesAction => ({
  type: MapActionTypes.MAP_FETCH_SUGGESTED_PLACES,
  payload,
});

export const mapSuggestedPlacesApiSuccess = (
  payload: PlacesPayload,
): MapApiSuccessAction => ({
  type: MapActionTypes.MAP_SUGGESTED_PLACES_API_SUCCESS,
  payload,
});

export const mapSuggestedPlacesApiFail = (
  payload: GlobalApiFailError,
): MapApiFailAction => ({
  type: MapActionTypes.MAP_SUGGESTED_PLACES_API_FAIL,
  payload,
});

export const mapFetchBoundary = (
  payload: BoundariesType,
): MapFetchBoundaryAction => ({
  type: MapActionTypes.MAP_FETCH_BOUNDARY,
  payload,
});

export const mapFetchBoundaryApiSuccess = (
  payload: MapPolygonWithBoundary,
): MapFetchBoundarySuccessAction => ({
  type: MapActionTypes.MAP_FETCH_BOUNDARY_API_SUCCESS,
  payload,
});

export const mapFetchBoundaryApiFail = (
  payload: GlobalApiFailError,
): MapFetchBoundaryFailAction => ({
  type: MapActionTypes.MAP_FETCH_BOUNDARY_API_FAIL,
  payload,
});

export const mapUpdateSelectedPlace = (
  payload: SelectPlacePayload,
): MapUpdateSelectedPlaceAction => ({
  type: MapActionTypes.MAP_UPDATE_SELECTED_PLACE,
  payload,
});

export const mapGetGeoCodeInfo = (
  payload: GeocodePayload,
): MapGetGeoCodeInfoAction => ({
  type: MapActionTypes.MAP_GET_GEOCODE_INFO_API_SUCCESS,
  payload,
});

export const mapReset = (): MapResetAction => ({
  type: MapActionTypes.MAP_RESET,
});

export const mapInitSet = (payload: any): MapInitSetAction => ({
  type: MapActionTypes.MAP_INIT_SET,
  payload,
});

export const selectDrawTool = (
  payload: DrawTools | null,
): SelectDrawToolAction => ({
  type: MapActionTypes.SELECT_DRAW_TOOL,
  payload,
});

export const mapAddShape = (
  payload: MapShapes,
  contextIgnore?: boolean,
): MapAddShapeAction => ({
  type: MapActionTypes.MAP_ADD_SHAPE,
  payload,
  contextIgnore,
});

export const mapCircleMoveEnd = (
  payload: MapCircle,
): MapCircleMoveEndAction => ({
  type: MapActionTypes.MAP_CIRCLE_MOVE_END,
  payload,
});

export const mapPolygonEditEnd = (
  payload: MapPolygon,
): MapPolygonEditAction => ({
  type: MapActionTypes.MAP_POLYGON_EDIT,
  payload,
});
