import styled, { css } from 'styled-components/macro';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

const selectableStyles = (borderCol: string, isSelectable?: boolean) => {
  if (isSelectable) {
    return css`
      cursor: pointer;
      &:focus,
      &:hover {
        border: 1px solid ${borderCol};
      }
    `;
  }

  return ``;
};

const showNoticeStyled = (warning: string, showNotice?: boolean) => {
  if (showNotice) {
    return css`
      border: 1px solid ${warning};
      border-left: 4px solid ${warning};
    `;
  }
  return ``;
};

export const MatchingRecordWrapper = styled.div<{
  showNotice?: boolean;
  isSelectable?: boolean;
}>`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  margin: 0 0 ${(props) => props.theme.spacing.small};

  ${(props) =>
    selectableStyles(
      props.theme.colorPalette.secondary.midGrey,
      props.isSelectable,
    )};
  ${(props) =>
    showNoticeStyled(
      props.theme.colorPalette.notifications.warning,
      props.showNotice,
    )}
`;

export const StyledMatchingRecord = styled.div`
  padding: ${(props) => props.theme.spacing.xsmall};
  display: grid;
  grid-template-columns: 1fr 150px;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    padding: ${(props) => props.theme.spacing.small};
  }
`;

export const MatchingRecordNotice = styled.div`
  background: ${(props) =>
    lighten(0.4, props.theme.colorPalette.notifications.warning)};
  padding: ${(props) => props.theme.spacing.small};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  border-radius: 4px;
  margin: 0;

  p {
    margin: 0 0 0 ${(props) => props.theme.spacing.small};
  }
`;

export const WarningIcon = styled(Warning)`
  fill: ${(props) => props.theme.colorPalette.notifications.warning};
`;

export const MatchingRecordList = styled.ul<{
  columns: number;
}>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
`;

const getWideStyles = (isWide?: boolean) => {
  if (isWide) {
    return css`
      grid-column: span 2;
    `;
  }
  return ``;
};

export const MatchingRecordItem = styled.li<{ isWide: boolean }>`
padding: 0 ${(props) => props.theme.spacing.xsmall};
font-family: ${(props) => props.theme.fonts.secondary};
word-break: break-word;
display: flex;
flex-direction: column;

justify-content: flex-start;

${(props) => getWideStyles(props.isWide)}

@media (min-width: ${(props) => props.theme.breakpoints.large}px) {
  padding: 0 ${(props) => props.theme.spacing.small};
}
`;

export const ItemTitle = styled.p`
  font-size: 9px;
  text-transform: uppercase;
  margin: 0;
  min-height: 20px;
`;

export const ItemBody = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  margin: ${(props) => props.theme.spacing.xsmall} 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    font-size: ${(props) => props.theme.typography.paragraph.small};
  }
`;

export const LineItem = styled.span`
  display: inline-block;
  line-height: 1.25;
  margin: 0 0 10px;
`;

export const MatchingRecordActions = styled.div`
  padding: ${(props) => props.theme.spacing.xsmall} 0;
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    padding: ${(props) => props.theme.spacing.small} 0;
  }
`;

export const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  text-transform: uppercase;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  display: flex;
  text-decoration: underline;
  &:hover,
  &:focus {
    span {
      color: ${(props) => props.theme.colorPalette.secondary.midGrey};
    }
  }
`;

export const EditIcon = styled(Edit)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  height: 16px;
  width: 16px;
`;

export const LinkText = styled.span`
  padding: 2px ${(props) => props.theme.spacing.xsmall};
  display: none;
  white-space: nowrap;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    display: inline-block;
  }
`;

export const StyledInputWrapper = styled.span`
  width: 20px;
  height: 20px;
  border: 2px solid ${(props) => props.theme.colorPalette.branding.ink};
  border-radius: 30px;
  display: inline-block;
  position: relative;
  margin-right: ${(props) => props.theme.spacing.small};
  cursor: pointer;

  &:hover,
  &:focus {
    border: 2px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
  }
`;

export const StyledSelectedRadio = styled.input`
  height: 0;
  width: 0;
  &:checked {
    &:after {
      position: absolute;
      display: block;
      left: 3px;
      top: 3px;
      content: '';
      width: 10px;
      height: 10px;
      background: ${(props) => props.theme.colorPalette.branding.yellow};
      border-radius: 30px;
    }
  }
`;

export const RadioLabel = styled.label`
  padding: 0 ${(props) => props.theme.spacing.default} 0 0;
  cursor: pointer;
  pointer-events: none;
`;
