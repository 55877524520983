/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CommonDetailRecordProps, Vertical } from 'globalConstants';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import {
  formatDate,
  formatPriceWithPence,
  formatPercentage,
  formatArea,
  formatMonths,
  formatCountry,
  formatYears,
  formatBooleanishYesNo,
  formatSector,
  formatMultiSelectField,
  formatTransactionQuarter,
} from 'services/format';
import { Conditional } from 'services/conditions';
import { OPTION_SETS } from 'connected/FormFlow/constants';
import {
  BREAK_TYPE_MAP,
  COLLAR_TYPE_MAP,
  INSIDE_OUTSIDE_MAP,
  LEASE_STATUS_MAP,
  LEASE_TYPE_MAP,
  PRIMARY_USE_MAP,
  REPAIRING_OBLIGATIONS_MAP,
  REVIEW_TYPE_MAP,
  TRUE_FALSE_MAP,
  MEASUREMENT_STANDARD_MAP,
  GRADE_MAP,
  GRADE2_MAP,
  FIT_OUT_CATEGORY_MAP,
  BREEAM_RATING_MAP,
  WIRED_SCORE_RATING_MAP,
  WELL_CERTIFICATION_MAP,
} from './configMaps';

export enum LeaseGroups {
  STATUS = 'Status & Use',
  DETAILS = 'Details',
  RENT_FREE = 'Rent Free & Incentives',
}

export interface LeaseField extends CommonDetailRecordProps {
  group: LeaseGroups;
  valuePath: string[];
  formatter?: (field: LeaseField) => string | null;
  conditional?: Conditional[];
  isHidden?: boolean;
}

export interface BreakOptionField extends CommonDetailRecordProps {
  valuePath: string[];
  formatter?: (field: BreakOptionField) => string | undefined | null;
  conditional?: Conditional[];
}

type Dependency = {
  key: string;
  path: string[];
};

type DependencyValues = {
  [key: string]: string | number | null;
};

const getFormattedPrice = (field: any) => {
  if (!field.value) return null;

  if (field.dependencyValues?.collar === 'pounds-and-pence') {
    return formatPriceWithPence(field.value);
  }

  return formatPercentage(field.value);
};

const getComponentType = (field: any) => {
  if (field.dependencyValues?.collar === 'pounds-and-pence') {
    return FIELD_COMPONENTS.DECIMAL_PRICE_INPUT;
  }

  return FIELD_COMPONENTS.PERCENTAGE;
};

export interface RentReviewField extends CommonDetailRecordProps {
  valuePath: string[];
  formatter?: (field: RentReviewField) => any;
  componentTypeFn?: (field: RentReviewField) => FIELD_COMPONENTS;
  conditional?: Conditional[];
  dependencies?: Dependency[];
  dependencyValues?: DependencyValues;
  entityIdPath?: string[];
  entityNamePath?: string[];
}

export type EntityDataFields = LeaseField | BreakOptionField | RentReviewField;

export const recordEditableStatuses = ['under-offer', 'let', 'terminated'];

export const leaseSummaryMap = {
  floor: {
    key: 'floor',
    keyLabel: 'Floor',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['floorPartition', '0', 'floor'],
  },
  partition: {
    key: 'partition',
    keyLabel: 'Partition/Unit',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['floorPartition', '0', 'partition'],
  },
  address: {
    key: 'address',
    apiKey: 'buildingAddress',
    keyLabel: 'Address',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['relatedBuildings', '0', 'buildingAddress'],
    isWide: true,
    verticalType: Vertical.Building,
    entityIdPath: ['relatedBuildings', 0, 'buildingId'],
    showLink: true,
  },
  buildingAlias: {
    key: 'buildingAliases',
    apiKey: 'buildingsOtherNames',
    keyLabel: 'Building Aliases',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    entityIdPath: ['buildings', 0, 'buildingId'],
    entityNamePath: ['buildings', 0, '__entityName__'],
    valuePath: ['relatedBuildings', '0', 'buildingsOtherNames'],
    inspectValues: true,
  },
  leaseType: {
    key: 'leaseType',
    keyLabel: 'Lease Type',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['type'],
    valueLabels: LEASE_TYPE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  status: {
    key: 'status',
    apiKey: 'availabilityStatus',
    keyLabel: 'Lease Status',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['status'],
    valueLabels: LEASE_STATUS_MAP,
    editStatusOptions: new Map([
      ['under-offer', ['let']],
      ['let', ['terminated']],
    ]),
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    inspectValues: true,
  },
  lessor: {
    key: 'landlord',
    keyLabel: 'Lessor (at time of deal)',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    valuePath: ['landlord', 'companyName'],
    isWide: true,
    entityIdPath: ['landlord', 'companyId'],
    isPrivatePath: ['landlord', 'isPrivate'],
    verticalType: Vertical.Company,
    showLink: true,
    crmPath: ['landlord', 'savCrmId'],
    crmNearestPath: ['landlord', 'savCrmNearestId'],
  },
  tenant: {
    key: 'tenant',
    keyLabel: 'Tenant',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    valuePath: ['tenant', 'companyName'],
    isWide: true,
    verticalType: Vertical.Company,
    showLink: true,
    entityIdPath: ['tenant', 'companyId'],
    isPrivatePath: ['tenant', 'isPrivate'],
    crmPath: ['tenant', 'savCrmId'],
    crmNearestPath: ['tenant', 'savCrmNearestId'],
  },
  primaryUse: {
    key: 'primaryUse',
    keyLabel: 'Primary Use',
    apiKey: 'primaryUse',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['primaryUse'],
    valueLabels: PRIMARY_USE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    inspectValues: true,
  },
  areaSqFt: {
    key: 'areaSqFt',
    apiKey: 'totalArea',
    keyLabel: 'Total Area',
    componentType: FIELD_COMPONENTS.SQUARE_FT_INPUT,
    valuePath: ['totalArea'],
    formatter: (field: LeaseField) =>
      field.value ? formatArea(field.value) : null,
    inspectValues: true,
  },
  offeredRent: {
    key: 'offeredRent',
    apiKey: 'offeredRent',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Offered Rent / Sq Ft',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['offeredRent'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    conditional: [{ ref: 'status', value: ['under-offer'] }],
    inspectValues: true,
  },
  achievedRent: {
    key: 'achievedRent',
    apiKey: 'achievedRent',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Achieved Rent / Sq Ft',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['achievedRent'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    conditional: [{ ref: 'status', value: ['let', 'terminated'] }],
    inspectValues: true,
  },
  totalRent: {
    key: 'totalRent',
    keyLabel: 'Annual Rent',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['totalRent'],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
  },
  serviceCharge: {
    key: 'serviceCharge',
    apiKey: 'serviceCharge',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Service Charge',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['serviceCharge'],
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
  },
  leaseStartDate: {
    key: 'leaseStartDate',
    apiKey: 'startDate',
    keyLabel: 'Lease Start Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['startDate'],
    inspectValues: true,
    formatter: (field: LeaseField) => formatDate(field.value),
  },
  nextRentReview: {
    key: 'nextRentReview',
    keyLabel: 'Next Rent Review',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['nextRentReview'],
    formatter: (field: LeaseField) => formatDate(field.value),
  },
  nextBreakDate: {
    key: 'nextBreakDate',
    keyLabel: 'Next Break Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['nextBreakDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
  },
  leaseEndDate: {
    key: 'leaseEndDate',
    apiKey: 'endDate',
    keyLabel: 'Lease End Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['endDate'],
    inspectValues: true,
    formatter: (field: LeaseField) => formatDate(field.value),
  },
  leaseTerm: {
    key: 'leaseTerm',
    keyLabel: 'Lease Term',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['leaseTerm'],
  },
  act54: {
    key: 'act54',
    apiKey: 'act54',
    keyLabel: '1954 L&T Act',
    valuePath: ['act54'],
    valueLabels: INSIDE_OUTSIDE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    inspectValues: true,
  },
};

export const leaseMap = {
  isDisputed: {
    key: 'isDisputed',
    apiKey: 'isDisputed',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.SELECT,
    keyLabel: 'Disputed',
    formatter: (field: LeaseField) => formatBooleanishYesNo(field.value),
    valueLabels: TRUE_FALSE_MAP,
    valuePath: ['isDisputed'],
    isEditable: true,
    inspectValues: true,
  },
  leaseStatus: {
    key: 'status',
    apiKey: 'availabilityStatus',
    group: LeaseGroups.STATUS,
    keyLabel: 'Status',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['status'],
    valueLabels: LEASE_STATUS_MAP,
    editStatusOptions: new Map([
      ['under-offer', ['let']],
      ['let', ['terminated']],
      ['terminated', ['letFromTerminated']],
    ]),
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    inspectValues: true,
  },
  primaryUse: {
    key: 'primaryUse',
    apiKey: 'primaryUse',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.SELECT,
    keyLabel: 'Primary Use',
    valuePath: ['primaryUse'],
    valueLabels: PRIMARY_USE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    inspectValues: true,
    isEditable: true,
    isClearable: true,
  },
  leaseType: {
    key: 'type',
    apiKey: 'type',
    group: LeaseGroups.STATUS,
    keyLabel: 'Lease Type',
    inspectValues: true,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['type'],
    valueLabels: LEASE_TYPE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    isEditable: true,
    editModal: { ref: 'type', name: 'editLeaseType' },
  },
  leaseTitleNumber: {
    key: 'titleNumber',
    apiKey: 'titleNumber',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    keyLabel: 'Lease Title Number',
    valuePath: ['titleNumber'],
    inspectValues: true,
  },
  tenant: {
    key: 'tenant',
    apiKey: '$Tenant',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    keyLabel: 'Tenant Name',
    isEditable: true,
    editModal: { ref: 'tenant', name: 'EditTenant' },
    valuePath: ['tenant', 'companyName'],
    entityIdPath: ['tenant', 'entityId'],
    isWide: true,
    isPrivatePath: ['tenant', 'isPrivate'],
    crmPath: ['tenant', 'savCrmId'],
    crmNearestPath: ['tenant', 'savCrmNearestId'],
  },
  tenantSector: {
    key: 'tenantSector',
    apiKey: 'tenantSector',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.SELECT,
    optionSet: OPTION_SETS.SECTOR,
    keyLabel: 'Tenant Sector',
    valuePath: ['tenant', 'companySector'],
    formatter: (field: LeaseField) => formatSector(field.value),
  },
  tenantNationality: {
    key: 'tenantNationality',
    apiKey: 'tenantNationality',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.COUNTRY_SELECT,
    keyLabel: 'Tenant Country',
    valuePath: ['tenant', 'companyNationality'],
    formatter: (field: LeaseField) => formatCountry(field.value),
  },
  tenantEquivalentBondRating: {
    key: 'equivalentBondRating',
    apiKey: 'equivalentBondRating',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    keyLabel: 'Tenant Equivalent Bond Rating',
    valuePath: ['tenant', 'equivalentBondRating'],
  },
  tenantIncansGlobalScore: {
    key: 'incansGlobalScore',
    apiKey: 'incansGlobalScore',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    keyLabel: 'Tenant Incans Global Score',
    valuePath: ['tenant', 'incansGlobalScore'],
  },
  isConfidential: {
    key: 'isConfidential',
    apiKey: 'dealIsPrivate',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.SELECT,
    keyLabel: 'Confidential Deal',
    valuePath: ['isConfidential'],
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    validation: {
      isRequired: true,
      message: 'Please enter if it is a confidential deal',
    },
  },
  terminationDate: {
    key: 'terminationDate',
    apiKey: 'terminationDate',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    keyLabel: 'Termination Date',
    valuePath: ['terminationDate'],
    inspectValues: true,
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
  },
  terminationReason: {
    key: 'terminationReason',
    apiKey: 'terminationReason',
    group: LeaseGroups.STATUS,
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    keyLabel: 'Termination Reason',
    valuePath: ['terminationReason'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  lessor: {
    key: 'landlord',
    apiKey: '$Landlord',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Lessor (at time of deal)',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    valuePath: ['landlord', 'companyName'],
    isEditable: true,
    editModal: { ref: 'landlord', name: 'EditLandlord' },
    isWide: true,
    entityIdPath: ['landlord', 'companyId'],
    verticalType: Vertical.Company,
    showLink: true,
    inspectValues: true,
    isPrivatePath: ['landlord', 'isPrivate'],
    crmPath: ['landlord', 'savCrmId'],
    crmNearestPath: ['landlord', 'savCrmNearestId'],
  },
  floor: {
    key: 'floor',
    apiKey: 'floorName',
    valuePath: ['floorPartition', '0', 'floor'],
    entityIdPath: ['floorPartition', '0', 'floorId'],
    entityNamePath: ['floorPartition', '0', 'entityName'],
    group: LeaseGroups.DETAILS,
    keyLabel: 'Floor',
    componentType: FIELD_COMPONENTS.FLOOR_SELECT,
    inspectValues: true,
    isEditable: true,
    editModal: { ref: 'floor', name: 'floorPartitionLevel' },
  },
  partition: {
    key: 'partition',
    apiKey: 'partitionName',
    valuePath: ['floorPartition', '0', 'partition'],
    entityIdPath: ['floorPartition', '0', 'floorId'],
    entityNamePath: ['floorPartition', '0', 'partitionEntityName'],
    group: LeaseGroups.DETAILS,
    keyLabel: 'Partition',
    componentType: FIELD_COMPONENTS.FLOOR_SELECT,
    inspectValues: true,
    isEditable: true,
    editModal: { ref: 'partition', name: 'floorPartitionLevel' },
  },
  startDate: {
    key: 'startDate',
    apiKey: 'startDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Start Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['startDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    editMetaConditional: [{ ref: 'demiseParentId', value: ['*'] }],
    editMetaWarning: 'This lease record is part of a larger transaction',
    editMetaInfo: 'Making this change should also:',
    editMetaKey: 'action',
    editMetaComponentType: FIELD_COMPONENTS.RADIO,
    editMetaOptions: [
      {
        label: 'update all other connected leases',
        value: 'update',
        id: 'update',
      },
      {
        label: 'move this lease to a separate transaction',
        value: 'split',
        id: 'split',
      },
    ],
    inspectValues: true,
  },
  transactionQuarter: {
    key: 'transactionQuarter',
    apiKey: 'transactionQuarter',
    valuePath: ['startDate'],
    group: LeaseGroups.DETAILS,
    keyLabel: 'Transaction Quarter',
    formatter: (field: LeaseField) => formatTransactionQuarter(field.value),
  },
  endDate: {
    key: 'endDate',
    apiKey: 'endDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'End Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['endDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    editMetaConditional: [{ ref: 'demiseParentId', value: ['*'] }],
    editMetaWarning: 'This lease record is part of a larger transaction',
    editMetaInfo: 'Making this change should also:',
    editMetaKey: 'action',
    editMetaComponentType: FIELD_COMPONENTS.RADIO,
    editMetaOptions: [
      {
        label: 'update all other connected leases',
        value: 'update',
      },
      {
        label: 'move this lease to a separate transaction',
        value: 'split',
      },
    ],
    inspectValues: true,
  },
  areaSqFt: {
    key: 'totalArea',
    apiKey: 'totalArea',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Area (Sq Ft)',
    componentType: FIELD_COMPONENTS.SQUARE_FT_INPUT,
    valuePath: ['totalArea'],
    formatter: (field: LeaseField) =>
      field.value ? formatArea(field.value) : null,
    isEditable: true,
    validation: {
      match: '^[1-9]|[1-9][0-9]+(,\\d{3})*$',
      isRequired: true,
      message: 'Please enter the total area in this demise',
    },
    inspectValues: true,
  },
  measurementStandard: {
    key: 'measurementStandard',
    apiKey: 'measurementStandard',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Measurement Standard',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['measurementStandard'],
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    isEditable: true,
    validation: {
      isRequired: true,
      message: 'Please enter the Measurement standard',
    },
    valueLabels: MEASUREMENT_STANDARD_MAP,
    inspectValues: true,
  },
  askingRent: {
    key: 'askingRent',
    apiKey: 'askingRent',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Asking Rent / Sq Ft',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['askingRent'],
    isEditable: true,
    validation: {
      match: '^[1-9]|[1-9][0-9]+(,\\d{3})*$',
      message: 'Please enter the asking rent per sq ft',
    },
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    isClearable: true,
    inspectValues: true,
  },
  offeredRent: {
    key: 'offeredRent',
    apiKey: 'offeredRent',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Offered Rent / Sq Ft',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['offeredRent'],
    isEditable: true,
    isClearable: true,
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    inspectValues: true,
  },
  achievedRent: {
    key: 'achievedRent',
    apiKey: 'achievedRent',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Achieved Rent / Sq Ft',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['achievedRent'],
    isEditable: true,
    isClearable: true,
    validation: {
      isRequired: true,
      match: '^[1-9]|[1-9][0-9]+(,\\d{3})*$',
      message: 'Please enter the offered rent per sq ft',
    },
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    conditional: [{ ref: 'status', value: ['let', 'terminated'] }],
    inspectValues: true,
  },
  serviceCharge: {
    key: 'serviceCharge',
    apiKey: 'serviceCharge',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Service Charge',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['serviceCharge'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
  },
  act54: {
    key: 'act54',
    apiKey: 'act54',
    group: LeaseGroups.DETAILS,
    keyLabel: '1954 L&T Act',
    valuePath: ['act54'],
    componentType: FIELD_COMPONENTS.SELECT,
    valueLabels: INSIDE_OUTSIDE_MAP,
    isEditable: true,
    inspectValues: true,
    isClearable: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  repairingObligations: {
    key: 'repairingObligations',
    apiKey: 'term',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Repairing Obligations',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['repairingObligations'],
    valueLabels: REPAIRING_OBLIGATIONS_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  zoneARent: {
    key: 'zoneARent',
    apiKey: 'zoneARent',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Zone A Rent',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['zoneARent'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    conditional: [{ ref: 'primaryUse', value: ['retail'] }],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
  },
  linkedToTurnover: {
    key: 'linkedToTurnover',
    apiKey: 'linkedToTurnover',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Linked to Turnover',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['linkedToTurnover'],
    isEditable: true,
    isClearable: true,
    conditional: [{ ref: 'primaryUse', value: ['retail'] }],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: LeaseField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
  },
  premiumToLandlord: {
    key: 'premiumToLandlord',
    apiKey: 'premium',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Premium to Landlord',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['premiumToLandlord'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    conditional: [{ ref: 'primaryUse', value: ['retail'] }],
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
  },
  readyToOccupyDate: {
    key: 'readyToOccupyDate',
    apiKey: 'readyToOccupyDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Ready to Occupy Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['readyToOccupyDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  description: {
    key: 'description',
    apiKey: 'description',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Description',
    componentType: FIELD_COMPONENTS.TEXTAREA,
    valuePath: ['description'],
    isEditable: true,
    isPrepopulated: true,
    isWide: true,
    isClearable: true,
    inspectValues: true,
  },
  onMarketDate: {
    key: 'onMarketDate',
    apiKey: 'onMarketDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'On Market Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['onMarketDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  offMarketDate: {
    key: 'offMarketDate',
    apiKey: 'offMarketDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Off Market Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['offMarketDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  contractSignedDate: {
    key: 'contractSignedDate',
    apiKey: 'contractSignedDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Contract Signed Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['contractSignedDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  headsOfTermsDate: {
    key: 'headsOfTermsAgreedDate',
    apiKey: 'headsOfTermsAgreedDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Heads of Terms Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['headsOfTermsAgreedDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  numberOfEnquiries: {
    key: 'numberOfEnquiries',
    apiKey: 'numberOfEnquiries',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Number of Enquiries',
    componentType: FIELD_COMPONENTS.INTEGER,
    valuePath: ['numberOfEnquiries'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  numberOfOffers: {
    key: 'numberOfOffers',
    apiKey: 'numberOfOffers',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Number of Offers',
    componentType: FIELD_COMPONENTS.INTEGER,
    valuePath: ['numberOfOffers'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  useClause: {
    key: 'useClauses',
    apiKey: 'useClauses',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Use Clause',
    componentType: FIELD_COMPONENTS.USE_CLAUSE_SELECT,
    isEditable: true,
    isClearable: true,
    isPrepopulated: true,
    inspectValues: true,
    valuePath: ['useClauses'],
    formatter: (field: LeaseField) => formatMultiSelectField(field.value),
  },
  jobCode: {
    key: 'jobCode',
    apiKey: 'jobCode',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Job Code',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['jobCode'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    validation: {
      isRequired: false,
      message: 'Job Codes consist of six numbers',
      match: '^([0-9]{6,6})?$',
    },
  },
  instructionDate: {
    key: 'instructionDate',
    apiKey: 'instructionDate',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Instruction Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['instructionDate'],
    formatter: (field: LeaseField) => formatDate(field.value),
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  holdingOver: {
    key: 'holdingOver',
    apiKey: 'holdingOver',
    group: LeaseGroups.DETAILS,
    keyLabel: 'Holding Over',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['holdingOver'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
    conditional: [
      { ref: 'act54', value: [false] },
      { ref: 'endDate', value: ['BEFORE_CURRENT_DATE'] },
    ],
  },
  rentFreeMonths: {
    key: 'rentFreeMonths',
    apiKey: 'rentFreeMonthsInitial',
    group: LeaseGroups.RENT_FREE,
    keyLabel: 'Initial Rent Free Months',
    componentType: FIELD_COMPONENTS.MONTH_INPUT,
    valuePath: ['rentFreeMonths'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value ? formatMonths(field.value) : null,
    validation: {
      match: '^[1-9][0-9]*( month)?( months)?$',
      message: 'Please enter a valid number of months',
    },
  },
  halfRentFreeMonths: {
    key: 'halfRentFreeMonths',
    apiKey: 'halfRentMonthsInitial',
    group: LeaseGroups.RENT_FREE,
    keyLabel: 'Half Rent Free Months',
    componentType: FIELD_COMPONENTS.MONTH_INPUT,
    valuePath: ['halfRentFreeMonths'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value ? formatMonths(field.value) : null,
    validation: {
      match: '^[1-9][0-9]*( month)?( months)?$',
      message: 'Please enter a valid number of months',
    },
  },
  rentFreeMonthsTotal: {
    key: 'rentFreeMonthsTotal',
    apiKey: 'rentFreeMonthsTotal',
    group: LeaseGroups.RENT_FREE,
    keyLabel: 'Total Rent Free Months',
    componentType: FIELD_COMPONENTS.MONTH_INPUT,
    valuePath: ['rentFreeMonthsTotal'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value ? formatMonths(field.value) : null,
    validation: {
      match: '^[1-9][0-9]*( month)?( months)?$',
      message: 'Please enter a valid number of months',
    },
  },
  capitalContribution: {
    key: 'capitalContribution',
    apiKey: 'capitalContribution',
    group: LeaseGroups.RENT_FREE,
    keyLabel: 'Capital Contribution',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['capitalContribution'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    validation: {
      match: '^£?[0-9]+(,\\d{3})*\\.?[0-9]{0,2}$',
      message: 'Please enter a valid amount in pounds and pence',
    },
  },
  netEffectiveRent: {
    key: 'netEffectiveRent',
    apiKey: 'netEffectiveRent',
    group: LeaseGroups.RENT_FREE,
    keyLabel: 'Net Effective Rent',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['netEffectiveRent'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    validation: {
      match: '^£?[0-9]+(,\\d{3})*\\.?[0-9]{0,2}$',
      message: 'Please enter a valid amount in pounds and pence',
    },
  },
  desmiseParentId: {
    key: 'demiseParentId',
    valuePath: ['demiseParentId'],
    keyLabel: 'Demise Parent Id',
    isHidden: true,
  },
  grade: {
    key: 'grade',
    keyLabel: 'Grade',
    apiKey: 'grade',
    group: LeaseGroups.DETAILS,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['grade'],
    valueLabels: GRADE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  grade2: {
    key: 'grade2',
    keyLabel: 'Grade 2',
    apiKey: 'grade2',
    group: LeaseGroups.DETAILS,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['grade2'],
    valueLabels: GRADE2_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  fitOutCategory: {
    key: 'fitOutCategory',
    keyLabel: 'Fit Out Category',
    apiKey: 'fitOutCategory',
    group: LeaseGroups.DETAILS,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['fitOutCategory'],
    valueLabels: FIT_OUT_CATEGORY_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  breeamRating: {
    key: 'breeamRating',
    keyLabel: 'BREEAM Rating',
    apiKey: 'breeamRating',
    group: LeaseGroups.DETAILS,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['breeamRating'],
    valueLabels: BREEAM_RATING_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  wiredScoreRating: {
    key: 'wiredScoreRating',
    keyLabel: 'WiredScore Rating',
    apiKey: 'wiredScoreRating',
    group: LeaseGroups.DETAILS,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['wiredScoreRating'],
    valueLabels: WIRED_SCORE_RATING_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  wellCertification: {
    key: 'wellCertification',
    keyLabel: 'WELL Certification',
    apiKey: 'wellCertification',
    group: LeaseGroups.DETAILS,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['wellCertification'],
    valueLabels: WELL_CERTIFICATION_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: LeaseField) => {
      return field.valueLabels!.get(field.value);
    },
  },
};

export const leaseBreakOptionsMap = {
  breakDate: {
    key: 'date',
    apiKey: 'date',
    keyLabel: 'Break Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['date'],
    isEditable: true,
    inspectValues: true,
    formatter: (field: BreakOptionField) => {
      return formatDate(field.value);
    },
  },
  breakType: {
    key: 'type',
    apiKey: 'type',
    keyLabel: 'Break Type',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['type'],
    valueLabels: BREAK_TYPE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: BreakOptionField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  breakNoticeDate: {
    key: 'breakNoticeDate',
    apiKey: 'breakNoticeDate',
    valuePath: ['breakNoticeDate'],
    keyLabel: 'Break Notice Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: BreakOptionField) => {
      return formatDate(field.value);
    },
  },
  penalty: {
    key: 'globalPenalty',
    apiKey: 'globalPenalty',
    valuePath: ['globalPenalty'],
    keyLabel: 'Penalty',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    formatter: (field: BreakOptionField) =>
      field.value ? formatPriceWithPence(field.value) : null,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    validation: {
      match: '^£?[0-9]+(,\\d{3})*\\.?[0-9]{0,2}$',
      message: 'Please enter a valid amount in pounds and pence',
    },
  },
  monthsRentPenalty: {
    key: 'monthsRentPenalty',
    apiKey: 'monthsRentPenalty',
    valuePath: ['monthsRentPenalty'],
    keyLabel: 'Months Rent Penalty',
    componentType: FIELD_COMPONENTS.MONTH_INPUT,
    formatter: (field: BreakOptionField) =>
      field.value ? formatMonths(field.value) : null,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    validation: {
      match: '^[1-9][0-9]*( month)?( months)?$',
      message: 'Please enter a valid number of months',
    },
  },
  redevelopmentBreak: {
    key: 'redevelopmentBreak',
    apiKey: 'hasRedevelopmentBreakClause',
    valuePath: ['hasRedevelopmentBreakClause'],
    keyLabel: 'Development Break',
    componentType: FIELD_COMPONENTS.SELECT,
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: BreakOptionField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  rollingBreak: {
    key: 'rollingBreak',
    apiKey: 'hasRollingBreakClause',
    valuePath: ['hasRollingBreakClause'],
    keyLabel: 'Rolling Break',
    componentType: FIELD_COMPONENTS.SELECT,
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: BreakOptionField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  additionalRentFree: {
    key: 'additionalRentFree',
    apiKey: 'additionalMonthsRentFree',
    valuePath: ['additionalMonthsRentFree'],
    keyLabel: 'Additional Rent Free Months',
    componentType: FIELD_COMPONENTS.MONTH_INPUT,
    formatter: (field: BreakOptionField) =>
      field.value ? formatMonths(field.value) : null,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    validation: {
      match: '^[1-9][0-9]*( month)?( months)?$',
      message: 'Please enter a valid number of months',
    },
  },
  additionalHalfRent: {
    key: 'additionalHalfRent',
    apiKey: 'additionalMonthsHalfRent',
    valuePath: ['additionalMonthsHalfRent'],
    keyLabel: 'Additional Rent Half Free Months',
    componentType: FIELD_COMPONENTS.MONTH_INPUT,
    formatter: (field: BreakOptionField) =>
      field.value ? formatMonths(field.value) : null,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    validation: {
      match: '^[1-9][0-9]*( month)?( months)?$',
      message: 'Please enter a valid number of months',
    },
  },
  breakRemoved: {
    key: 'breakRemoved',
    apiKey: 'removed',
    valuePath: ['removed'],
    keyLabel: 'Break Removed',
    componentType: FIELD_COMPONENTS.SELECT,
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: BreakOptionField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  breakRemovedDate: {
    key: 'breakRemovedDate',
    apiKey: 'removedDate',
    valuePath: ['removedDate'],
    keyLabel: 'Break Removed Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: BreakOptionField) => {
      return formatDate(field.value);
    },
  },
};

export const leaseRentReviewsMap = {
  reviewType: {
    key: 'reviewType',
    apiKey: 'reviewType',
    keyLabel: 'Review Type',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['reviewType'],
    valueLabels: REVIEW_TYPE_MAP,
    isEditable: false,
    formatter: (field: RentReviewField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  reviewDate: {
    key: 'reviewDate',
    apiKey: 'reviewDate',
    keyLabel: 'Review Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['reviewDate'],
    isEditable: true,
    isClearable: false,
    inspectValues: true,
    formatter: (field: RentReviewField) => {
      return formatDate(field.value);
    },
    conditional: [
      { ref: 'reviewType', value: ['stepped-rent'], inverse: true },
    ],
  },
  upwardOnly: {
    key: 'upwardOnly',
    apiKey: 'upwardOnly',
    keyLabel: 'Upwards Only',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['upwardOnly'],
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: RentReviewField) => {
      return field.valueLabels!.get(field.value);
    },
    conditional: [{ ref: 'reviewType', value: ['open-market-review'] }],
  },
  timeOfEssence: {
    key: 'timeOfTheEssence',
    apiKey: 'timeOfTheEssenceClauseInvokable',
    keyLabel: 'Time of Essence',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['timeOfTheEssenceClauseInvokable'],
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: RentReviewField) => {
      return field.valueLabels!.get(field.value);
    },
    conditional: [{ ref: 'reviewType', value: ['open-market-review'] }],
  },
  hypotheticalTerm: {
    key: 'hypotheticalTerm',
    apiKey: 'hypotheticalTerm',
    keyLabel: 'Hypothetical Term',
    componentType: FIELD_COMPONENTS.YEAR_INPUT,
    valuePath: ['hypotheticalTerm'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: RentReviewField) =>
      field.value ? formatYears(field.value) : null,
    conditional: [{ ref: 'reviewType', value: ['open-market-review'] }],
  },
  collarAndCapType: {
    key: 'collar',
    apiKey: 'collar',
    keyLabel: 'Collar & Cap Type',
    valuePath: ['collar'],
    valueLabels: COLLAR_TYPE_MAP,
    formatter: (field: RentReviewField) => {
      return field.valueLabels!.get(field.value);
    },
    isEditable: false,
    isClearable: true,
    inspectValues: true,
    conditional: [
      { ref: 'reviewType', value: ['stepped-rent'], inverse: true },
    ],
  },
  collarValue: {
    key: 'collarValue',
    apiKey: 'collarValue',
    keyLabel: 'Collar Value',
    valuePath: ['collarValue'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    componentTypeFn: (field: RentReviewField) => getComponentType(field),
    formatter: (field: RentReviewField) => getFormattedPrice(field),
    dependencies: [{ key: 'collar', path: ['collar'] }],
    conditional: [
      { ref: 'reviewType', value: ['stepped-rent'], inverse: true },
    ],
  },
  capValue: {
    key: 'capValue',
    apiKey: 'capValue',
    keyLabel: 'Cap Value',
    valuePath: ['capValue'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    componentTypeFn: (field: RentReviewField) => getComponentType(field),
    formatter: (field: RentReviewField) => getFormattedPrice(field),
    dependencies: [{ key: 'collar', path: ['collar'] }],
    conditional: [
      { ref: 'reviewType', value: ['stepped-rent'], inverse: true },
    ],
  },
  note: {
    key: 'note',
    apiKey: 'note',
    keyLabel: 'Notes',
    valuePath: ['note'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    componentType: FIELD_COMPONENTS.TEXTAREA,
    conditional: [{ ref: 'reviewType', value: ['cpi', 'rpi', 'other-index'] }],
    isPrepopulated: true,
  },
  rentFrom: {
    key: 'rentFrom',
    apiKey: 'rentFrom',
    keyLabel: 'Rent From',
    valuePath: ['rentFrom'],
    isEditable: true,
    inspectValues: true,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    conditional: [{ ref: 'reviewType', value: ['stepped-rent'] }],
    formatter: (field: RentReviewField) => {
      return formatDate(field.value);
    },
  },
  rentTo: {
    key: 'rentTo',
    apiKey: 'rentTo',
    keyLabel: 'Rent To',
    valuePath: ['rentTo'],
    isEditable: true,
    inspectValues: true,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    conditional: [{ ref: 'reviewType', value: ['stepped-rent'] }],
    formatter: (field: RentReviewField) => {
      return formatDate(field.value);
    },
  },
  rentAmount: {
    key: 'rentAmount',
    apiKey: 'rentAmount',
    keyLabel: 'Rent Amount',
    valuePath: ['rentAmount'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    conditional: [{ ref: 'reviewType', value: ['stepped-rent'] }],
    formatter: (field: BreakOptionField) =>
      field.value ? formatPriceWithPence(field.value) : null,
  },
};

export enum LeaseStatusEnum {
  ToLet = 'to-let',
  UnderOffer = 'under-offer',
  Let = 'let',
  OutcomeUnknown = 'outcome-unknown',
  Terminated = 'terminated',
}

export const leaseAuditMap = {
  leaseStatus: {
    apiKey: 'availabilityStatus',
    keyLabel: 'Status',
    formatter: (field: string) => {
      return LEASE_STATUS_MAP.get(field);
    },
  },
  primaryUse: {
    apiKey: 'primaryUse',
    keyLabel: 'Primary Use',
    formatter: (field: string) => {
      return PRIMARY_USE_MAP.get(field);
    },
  },
  leaseType: {
    apiKey: 'type',
    keyLabel: 'Lease Type',
    formatter: (field: string) => {
      return LEASE_TYPE_MAP.get(field);
    },
  },
  leaseTitleNumber: {
    apiKey: 'titleNumber',
    keyLabel: 'Lease Title Number',
  },
  tenant: {
    apiKey: '$Tenant',
    keyLabel: 'Tenant Name',
  },
  landlord: {
    apiKey: '$Landlord',
    keyLabel: 'Landlord Name',
  },
  tenantSector: {
    apiKey: 'tenantSector',
    keyLabel: 'Tenant Sector',
    formatter: (field: string) => formatSector(field),
  },
  tenantNationality: {
    apiKey: 'tenantNationality',
    keyLabel: 'Tenant Country',
    formatter: (field: string) => formatCountry(field),
  },
  isConfidential: {
    apiKey: 'dealIsPrivate',
    keyLabel: 'Confidential Deal',
    formatter: (field: string) => formatBooleanishYesNo(field),
  },
  terminationDate: {
    apiKey: 'terminationDate',
    keyLabel: 'Termination Date',
    formatter: (field: string) => formatDate(field),
  },
  terminationReason: {
    apiKey: 'terminationReason',
    keyLabel: 'Termination Reason',
  },
  startDate: {
    apiKey: 'startDate',
    keyLabel: 'Start Date',
    formatter: (field: string) => formatDate(field),
  },
  endDate: {
    apiKey: 'endDate',
    keyLabel: 'End Date',
    formatter: (field: string) => formatDate(field),
  },
  areaSqFt: {
    apiKey: 'totalArea',
    keyLabel: 'Area (Sq Ft)',
    formatter: (field: number) => (field ? formatArea(field) : null),
  },
  measurementStandard: {
    apiKey: 'measurementStandard',
    keyLabel: 'Measurement Standard',
    formatter: (field: string) => {
      return MEASUREMENT_STANDARD_MAP.get(field);
    },
  },
  askingRent: {
    apiKey: 'askingRent',
    keyLabel: 'Asking Rent / Sq Ft',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  offeredRent: {
    apiKey: 'offeredRent',
    keyLabel: 'Offered Rent / Sq Ft',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  achievedRent: {
    apiKey: 'achievedRent',
    keyLabel: 'Achieved Rent / Sq Ft',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  serviceCharge: {
    apiKey: 'serviceCharge',
    keyLabel: 'Service Charge',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  act54: {
    apiKey: 'act54',
    keyLabel: '1954 L&T Act',
  },
  repairingObligations: {
    apiKey: 'term',
    keyLabel: 'Repairing Obligations',
    formatter: (field: string) => {
      return REPAIRING_OBLIGATIONS_MAP.get(field);
    },
  },
  zoneARent: {
    apiKey: 'zoneARent',
    keyLabel: 'Zone A Rent',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  linkedToTurnover: {
    apiKey: 'linkedToTurnover',
    keyLabel: 'Linked to Turnover',
    formatter: (field: string) => formatBooleanishYesNo(field),
  },
  premiumToLandlord: {
    apiKey: 'premium',
    keyLabel: 'Premium to Landlord',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  readyToOccupyDate: {
    apiKey: 'readyToOccupyDate',
    keyLabel: 'Ready to Occupy Date',
    formatter: (field: string) => formatDate(field),
  },
  description: {
    apiKey: 'description',
    keyLabel: 'Description',
  },
  onMarketDate: {
    apiKey: 'onMarketDate',
    keyLabel: 'On Market Date',
    formatter: (field: string) => formatDate(field),
  },
  offMarketDate: {
    apiKey: 'offMarketDate',
    keyLabel: 'Off Market Date',
    formatter: (field: string) => formatDate(field),
  },
  contractSignedDate: {
    apiKey: 'contractSignedDate',
    keyLabel: 'Contract Signed Date',
    formatter: (field: string) => formatDate(field),
  },
  headsOfTermsDate: {
    apiKey: 'headsOfTermsAgreedDate',
    keyLabel: 'Heads of Terms Date',
    formatter: (field: string) => formatDate(field),
  },
  numberOfEnquiries: {
    apiKey: 'numberOfEnquiries',
    keyLabel: 'Number of Enquiries',
  },
  numberOfOffers: {
    apiKey: 'numberOfOffers',
    keyLabel: 'Number of Offers',
  },
  useClause: {
    apiKey: 'useClauses',
    keyLabel: 'Use Clause',
  },
  jobCode: {
    apiKey: 'jobCode',
    keyLabel: 'Job Code',
  },
  instructionDate: {
    apiKey: 'instructionDate',
    keyLabel: 'Instruction Date',
    formatter: (field: string) => formatDate(field),
  },
  holdingOver: {
    apiKey: 'holdingOver',
    keyLabel: 'Holding Over',
    formatter: (field: string) => {
      return formatBooleanishYesNo(field);
    },
  },
  rentFreeMonths: {
    apiKey: 'rentFreeMonthsInitial',
    keyLabel: 'Initial Rent Free Months',
    formatter: (field: number) => (field ? formatMonths(field) : null),
  },
  halfRentFreeMonths: {
    apiKey: 'halfRentMonthsInitial',
    keyLabel: 'Half Rent Free Months',
    formatter: (field: number) => (field ? formatMonths(field) : null),
  },
  rentFreeMonthsTotal: {
    apiKey: 'rentFreeMonthsTotal',
    keyLabel: 'Total Rent Free Months',
    formatter: (field: number) => (field ? formatMonths(field) : null),
  },
  capitalContribution: {
    apiKey: 'capitalContribution',
    keyLabel: 'Capital Contribution',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  netEffectiveRent: {
    apiKey: 'netEffectiveRent',
    keyLabel: 'Net Effective Rent',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  breakDate: {
    apiKey: 'date',
    keyLabel: 'Break Date',
    formatter: (field: string) => {
      return formatDate(field);
    },
  },
  breakType: {
    apiKey: 'type',
    keyLabel: 'Break Type',
    formatter: (field: string) => {
      return BREAK_TYPE_MAP.get(field);
    },
  },
  breakNoticeDate: {
    apiKey: 'breakNoticeDate',
    keyLabel: 'Break Notice Date',
    formatter: (field: string) => {
      return formatDate(field);
    },
  },
  penalty: {
    apiKey: 'globalPenalty',
    keyLabel: 'Penalty',
    formatter: (field: BreakOptionField) =>
      field.value ? formatPriceWithPence(field.value) : null,
  },
  monthsRentPenalty: {
    apiKey: 'monthsRentPenalty',
    keyLabel: 'Months Rent Penalty',
    formatter: (field: BreakOptionField) =>
      field.value ? formatMonths(field.value) : null,
  },
  redevelopmentBreak: {
    apiKey: 'hasRedevelopmentBreakClause',
    keyLabel: 'Development Break',
    formatter: (field: boolean) => {
      return TRUE_FALSE_MAP.get(field);
    },
  },
  rollingBreak: {
    apiKey: 'hasRollingBreakClause',
    keyLabel: 'Rolling Break',
    formatter: (field: boolean) => {
      return TRUE_FALSE_MAP.get(field);
    },
  },
  additionalRentFree: {
    apiKey: 'additionalMonthsRentFree',
    keyLabel: 'Additional Rent Free Months',
    formatter: (field: number) => (field ? formatMonths(field) : null),
  },
  additionalHalfRent: {
    apiKey: 'additionalMonthsHalfRent',
    keyLabel: 'Additional Rent Half Free Months',
    componentType: FIELD_COMPONENTS.MONTH_INPUT,
    formatter: (field: number) => (field ? formatMonths(field) : null),
  },
  breakRemoved: {
    apiKey: 'removed',
    keyLabel: 'Break Removed',
    formatter: (field: boolean) => {
      return TRUE_FALSE_MAP.get(field);
    },
  },
  breakRemovedDate: {
    apiKey: 'removedDate',
    keyLabel: 'Break Removed Date',
    formatter: (field: string) => {
      return formatDate(field);
    },
  },
  reviewType: {
    apiKey: 'reviewType',
    keyLabel: 'Review Type',
    formatter: (field: string) => {
      return REVIEW_TYPE_MAP.get(field);
    },
  },
  reviewDate: {
    apiKey: 'reviewDate',
    keyLabel: 'Review Date',
    formatter: (field: string) => {
      return formatDate(field);
    },
  },
  upwardOnly: {
    apiKey: 'upwardOnly',
    keyLabel: 'Upwards Only',
    formatter: (field: boolean) => {
      return TRUE_FALSE_MAP.get(field);
    },
  },
  timeOfEssence: {
    apiKey: 'timeOfTheEssenceClauseInvokable',
    keyLabel: 'Time of Essence',
    formatter: (field: boolean) => {
      return TRUE_FALSE_MAP.get(field);
    },
  },
  hypotheticalTerm: {
    apiKey: 'hypotheticalTerm',
    keyLabel: 'Hypothetical Term',
    formatter: (field: number) => (field ? formatYears(field) : null),
  },
  collarAndCapType: {
    apiKey: 'collar',
    keyLabel: 'Collar & Cap Type',
    formatter: (field: string) => {
      return COLLAR_TYPE_MAP.get(field);
    },
  },
  collarValue: {
    apiKey: 'collarValue',
    keyLabel: 'Collar Value',
  },
  capValue: {
    apiKey: 'capValue',
    keyLabel: 'Cap Value',
  },
  note: {
    apiKey: 'note',
    keyLabel: 'Notes',
  },
  rentFrom: {
    apiKey: 'rentFrom',
    keyLabel: 'Rent From',
    formatter: (field: string) => {
      return formatDate(field);
    },
  },
  rentTo: {
    apiKey: 'rentTo',
    keyLabel: 'Rent To',
    formatter: (field: string) => {
      return formatDate(field);
    },
  },
  rentAmount: {
    apiKey: 'rentAmount',
    keyLabel: 'Rent Amount',
    formatter: (field: number) => (field ? formatPriceWithPence(field) : null),
  },
  grade: {
    keyLabel: 'Grade',
    apiKey: 'grade',
  },
  grade2: {
    keyLabel: 'Grade 2',
    apiKey: 'grade2',
  },
  fitOutCategory: {
    keyLabel: 'Fit Out Category',
    apiKey: 'fitOutCategory',
  },
  breeamRating: {
    keyLabel: 'BREEAM Rating',
    apiKey: 'breeamRating',
    formatter: (field: string) => {
      return BREEAM_RATING_MAP.get(field);
    },
  },
  wiredScoreRating: {
    keyLabel: 'WiredScore Rating',
    apiKey: 'wiredScoreRating',
  },
  wellCertification: {
    keyLabel: 'WELL Certification',
    apiKey: 'wellCertification',
  },
};
