import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Vertical } from 'globalConstants';
import { AccessibleText } from 'styled/Global';
import Tooltip from 'components/Tooltip/Tooltip';
import Checkbox from 'components/Forms/Inputs/Checkbox/Checkbox';
import { getRecordsToMerge } from 'store/selectors/detailsSelectors';
import { saveRecordsToMerge } from 'store/actions/detailsActions';
import { getIsAdmin } from 'store/selectors/authenticatedUserSelectors';
import { TABLE_COPY, TABLE_ACTIONS } from './constants';
import { Actions } from './types';

import {
  LockButtonIcon,
  DeleteButtonIcon,
  ActionButton,
  ActionLink,
  ActionSpan,
  WarningButtonIcon,
  DocumentIcon,
  ActionPlaceholder,
  ConfidentialButtonIcon,
  ActionTextLink,
  CanonicalButtonIcon,
  DownloadButtonIcon,
  HiddenIcon,
} from './TableStyled';

const IconCell = ({
  type,
  handleAction,
  isDisabled,
  link,
  showTooltipIfDisabled,
  tooltipContent,
  id,
  isAdminOnly,
  vertical,
}: Actions) => {
  const recordsToMerge = useSelector(getRecordsToMerge);

  const dispatch = useDispatch();
  const userIsAdmin = useSelector(getIsAdmin);

  if (isDisabled && !showTooltipIfDisabled) {
    return <ActionPlaceholder />;
  }

  switch (type) {
    case TABLE_ACTIONS.DOWNLOAD: {
      return (
        <ActionButton type="button" onClick={handleAction}>
          <DownloadButtonIcon />
          <AccessibleText>{TABLE_COPY.actions.download}</AccessibleText>
        </ActionButton>
      );
    }

    case TABLE_ACTIONS.DELETE: {
      if (showTooltipIfDisabled && isDisabled) {
        return (
          <Tooltip
            tooltipId="deleteTooltip"
            button={
              <ActionSpan>
                <LockButtonIcon />
                <AccessibleText>{TABLE_COPY.actions.lock}</AccessibleText>
              </ActionSpan>
            }
          >
            {showTooltipIfDisabled}
          </Tooltip>
        );
      }
      return (
        <ActionButton type="button" onClick={handleAction}>
          <DeleteButtonIcon />
          <AccessibleText>{TABLE_COPY.actions.delete}</AccessibleText>
        </ActionButton>
      );
    }

    case TABLE_ACTIONS.DISPUTED: {
      return (
        <Tooltip
          isActionTooltip
          tooltipId="disputedTooltip"
          button={
            <ActionSpan>
              <WarningButtonIcon />
              <AccessibleText>{TABLE_COPY.actions.disputed}</AccessibleText>
            </ActionSpan>
          }
        >
          {TABLE_COPY.tooltip.disputed}
        </Tooltip>
      );
    }

    case TABLE_ACTIONS.CONFIDENTIAL: {
      return (
        <Tooltip
          isActionTooltip
          tooltipId="confidentialTooltip"
          button={
            <ActionSpan>
              <ConfidentialButtonIcon />
              <AccessibleText>{TABLE_COPY.actions.isPrivate}</AccessibleText>
            </ActionSpan>
          }
        >
          {TABLE_COPY.tooltip.isPrivate}
        </Tooltip>
      );
    }

    case TABLE_ACTIONS.NON_CANONICAL_ADDRESS: {
      const content = link ? (
        <>
          {TABLE_COPY.tooltip.nonCanonicalAddress}
          <ActionTextLink to={link}>
            {TABLE_COPY.tooltip.nonCanonicalAddressLink}
          </ActionTextLink>

          {TABLE_COPY.tooltip.nonCanonicalAddressPart2}
        </>
      ) : (
        TABLE_COPY.tooltip.nonCanonicalAddress
      );

      return (
        <Tooltip
          isActionTooltip
          tooltipId="nonCanonicalAddressTooltip"
          button={
            <ActionSpan>
              <CanonicalButtonIcon />
              <AccessibleText>
                {TABLE_COPY.actions.nonCanonicalAddress}
              </AccessibleText>
            </ActionSpan>
          }
        >
          {content}
        </Tooltip>
      );
    }

    case TABLE_ACTIONS.DOCS_COUNT: {
      if (!link) {
        return <ActionPlaceholder />;
      }

      return (
        <Tooltip
          isActionTooltip
          tooltipId="docCountTooltip"
          button={
            <ActionLink to={link}>
              <DocumentIcon />
              <AccessibleText>{TABLE_COPY.actions.docsCount}</AccessibleText>
            </ActionLink>
          }
        >
          {TABLE_COPY.tooltip.docsCount}
        </Tooltip>
      );
    }

    case TABLE_ACTIONS.HIDDEN: {
      return (
        <Tooltip
          tooltipId="hiddenTooltip"
          button={
            <ActionSpan>
              <HiddenIcon />
              <AccessibleText>{TABLE_COPY.actions.hidden}</AccessibleText>
            </ActionSpan>
          }
        >
          {tooltipContent}
        </Tooltip>
      );
    }

    case TABLE_ACTIONS.MERGE: {
      if (!id) {
        return <></>;
      }
      const verticalRecords =
        vertical === Vertical.Sale ? recordsToMerge.sale : recordsToMerge.lease;

      const verticalRecordsToMerge = verticalRecords || [];
      const mergeRecord = () => {
        if (id && verticalRecords?.includes(id)) {
          dispatch(
            saveRecordsToMerge({
              [vertical as string]: [
                ...verticalRecordsToMerge?.filter((record) => record !== id),
              ],
            }),
          );
        }

        if (id && !verticalRecordsToMerge?.includes(id)) {
          dispatch(
            saveRecordsToMerge({
              [vertical as string]: [...verticalRecordsToMerge, id],
            }),
          );
        }
      };

      const isSelected = verticalRecordsToMerge?.includes(id);

      if (isAdminOnly && !userIsAdmin) {
        return <></>;
      }

      return (
        <Checkbox
          onChange={mergeRecord}
          onKeyPress={mergeRecord}
          name={id}
          label=""
          value={id}
          isChecked={isSelected}
        />
      );
    }
    default: {
      return <ActionPlaceholder />;
    }
  }
};

export default IconCell;
