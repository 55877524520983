import { Vertical } from 'globalConstants';
import { Notes } from 'pages/Details/types';
import { GlobalApiFailError } from 'store/actions/types';

export type NotesTableProps = {
  modalIsOpen: boolean;
  modalId: string;
  notes?: Notes[];
};

export enum NotesActionTypes {
  ADD_NOTE = 'ADD_NOTE',
  ADD_NOTE_API_FAIL = 'ADD_NOTE_API_FAIL',
  ADD_NOTE_API_SUCCESS = 'ADD_NOTE_API_SUCCESS',
  DELETE_NOTE = 'DELETE_NOTE',
  DELETE_NOTE_API_FAIL = 'DELETE_NOTE_API_FAIL',
  DELETE_NOTE_API_SUCCESS = 'DELETE_NOTE_API_SUCCESS',
}

export type DeleteNotePayload = {
  id: string;
  entity: string;
};

export type AddNotePayload = {
  note: string;
  resourceId: string;
  vertical: Vertical;
};

export type AddNoteAction = {
  type: NotesActionTypes.ADD_NOTE;
  payload: AddNotePayload;
};

export type AddNoteApiFailAction = {
  type: NotesActionTypes.ADD_NOTE_API_FAIL;
  payload: GlobalApiFailError;
};

export type AddNoteSuccessAction = {
  type: NotesActionTypes.ADD_NOTE_API_SUCCESS;
};

export type NoteDeleteAction = {
  type: NotesActionTypes.DELETE_NOTE;
  payload: DeleteNotePayload;
};

export type NoteDeleteApiFailAction = {
  type: NotesActionTypes.DELETE_NOTE_API_FAIL;
  payload: GlobalApiFailError;
};

export type NoteDeleteSuccessAction = {
  type: NotesActionTypes.DELETE_NOTE_API_SUCCESS;
};

export type NotesActions =
  | AddNoteAction
  | AddNoteApiFailAction
  | AddNoteSuccessAction
  | NoteDeleteAction
  | NoteDeleteApiFailAction
  | NoteDeleteSuccessAction;
