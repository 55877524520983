import {
  UsersListActions,
  UsersListPayloadType,
  FetchUsersListActionType,
  FetchUsersListSuccessActionType,
  FetchUsersListFailActionType,
  FreezeUserStatusType,
  FreezeUserFailActionType,
  FreezeUserSuccessActionType,
  UnfreezeUserStatusType,
  UnFreezeUserFailActionType,
  UnFreezeUserSuccessActionType,
} from 'store/actions/usersListActions';

export type UsersList = {
  isFetching: boolean;
  errors?: string[];
  results?: UsersListPayloadType[];
};

export const initialState: UsersList = {
  isFetching: false,
  errors: [],
  results: [],
};

const usersListReducer = (
  state = initialState,
  action:
    | FetchUsersListActionType
    | FetchUsersListSuccessActionType
    | FetchUsersListFailActionType
    | FreezeUserStatusType
    | FreezeUserSuccessActionType
    | FreezeUserFailActionType
    | UnfreezeUserStatusType
    | UnFreezeUserSuccessActionType
    | UnFreezeUserFailActionType,
) => {
  switch (action.type) {
    case UsersListActions.FETCH_USERS_LIST: {
      return {
        isFetching: true,
        errors: [],
        results: [],
      };
    }
    case UsersListActions.FETCH_USERS_LIST_SUCCESS: {
      return {
        isFetching: false,
        errors: [],
        results: action.payload,
      };
    }

    case UsersListActions.FREEZE_USER:
    case UsersListActions.UNFREEZE_USER: {
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    }
    case UsersListActions.FREEZE_USER_FAIL:
    case UsersListActions.UNFREEZE_USER_FAIL: {
      return {
        isFetching: false,
        errors: [action.payload.error],
        results: [],
      };
    }
    case UsersListActions.FREEZE_USER_SUCCESS: {
      return {
        isFetching: false,
        errors: [],
        results: state.results
          ? state.results.map((user) => {
              if (user.username === action.payload.username) {
                return {
                  ...user,
                  reason: action.payload.reason,
                  isActive: false,
                };
              }
              return user;
            })
          : [],
      };
    }
    case UsersListActions.UNFREEZE_USER_SUCCESS: {
      return {
        isFetching: false,
        errors: [],
        results: state.results
          ? state.results.map((user) => {
              if (user.username === action.payload.username) {
                return {
                  ...user,
                  isActive: true,
                };
              }
              return user;
            })
          : [],
      };
    }

    default:
      return state;
  }
};

export default usersListReducer;
