import { v4 as uuid } from 'uuid';
import {
  DocumentUploaderActionTypes,
  DocumentUploaderActions,
} from 'connected/DocumentUploader/types';
import {
  EntityNewSale,
  NewSaleActions,
  NewSaleActionTypes,
} from 'pages/NewSale/types';
import {
  EntityNewLease,
  NewLeaseActions,
  NewLeaseActionTypes,
} from 'pages/NewLease/types';
import { FormFlowActionTypes, FormFlowActions } from 'connected/FormFlow/types';
import { FORM_FLOWS } from 'connected/FormFlow/constants';
import {
  GlobalModalShowAction,
  GlobalModalActionTypes,
} from 'connected/GlobalModal/types';
import {
  DocumentPanelActions,
  DocumentPanelActionTypes,
} from 'connected/DocumentPanel/types';
import {
  NewRecordActions,
  NewRecordActionTypes,
} from 'connected/NewRecordWrapper/types';

export enum UploadedDocStatus {
  uploading = 'uploading',
  uploaded = 'uploaded',
}

export type UploadedDoc = {
  status: UploadedDocStatus;
  name: string;
  path: string;
};

export interface NewRecordState {
  recordId: string;
  formFlow?: FORM_FLOWS;
  error?: string;
  isSaving: boolean;
  uploadedDocs: UploadedDoc[];
  isSubmitted: boolean;
  formData: {
    lease?: EntityNewLease;
    sale?: EntityNewSale;
  };
}

export const initialState: NewRecordState = {
  error: undefined,
  formFlow: undefined,
  recordId: '',
  isSaving: false,
  uploadedDocs: [],
  isSubmitted: false,
  formData: {},
};

type AcceptedReducerActions =
  | NewSaleActions
  | NewLeaseActions
  | NewRecordActions
  | FormFlowActions
  | DocumentUploaderActions
  | DocumentPanelActions
  | GlobalModalShowAction;

const newRecordReducer = (
  state = initialState,
  action: AcceptedReducerActions,
): NewRecordState => {
  switch (action.type) {
    case NewSaleActionTypes.NEW_SALE_SAVE_SALE: {
      return {
        ...state,
        error: undefined,
        isSaving: true,
        formData: {
          sale: action.payload,
        },
      };
    }

    case NewLeaseActionTypes.NEW_LEASE_SAVE_LEASE: {
      return {
        ...state,
        error: undefined,
        isSaving: true,
        formData: {
          lease: action.payload,
        },
      };
    }

    case NewSaleActionTypes.NEW_SALE_API_SUCCESS:
    case NewSaleActionTypes.NEW_PORTFOLIO_SALE_API_SUCCESS:
    case NewLeaseActionTypes.NEW_LEASE_API_SUCCESS: {
      return {
        ...state,
        error: undefined,
        isSaving: false,
        isSubmitted: true,
      };
    }

    case NewSaleActionTypes.NEW_SALE_API_FAIL:
    case NewLeaseActionTypes.NEW_LEASE_API_FAIL: {
      return {
        ...state,
        error: action.payload.error,
        isSaving: false,
      };
    }

    case NewSaleActionTypes.NEW_SALE_RESET:
    case NewLeaseActionTypes.NEW_LEASE_RESET:
    case NewRecordActionTypes.NEW_RECORD_RESET: {
      return {
        ...initialState,
      };
    }

    case FormFlowActionTypes.INITIALIZE_FORM_BUILDER: {
      return {
        ...initialState,
        formFlow: state.formFlow,
        recordId: uuid(),
      };
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_START_UPLOAD: {
      if (action.withFormPath) {
        return {
          ...state,
          uploadedDocs: [
            {
              name: action.payload.filename,
              status: UploadedDocStatus.uploading,
              path: action.withFormPath,
            },
          ],
        };
      }

      return state;
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_SUCCESS_MESSAGE_SHOWN: {
      if (state.uploadedDocs.length) {
        return {
          ...state,
          uploadedDocs: [
            {
              ...state.uploadedDocs[0],
              status: UploadedDocStatus.uploaded,
            },
          ],
        };
      }

      return state;
    }

    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_CANCEL_UPLOAD:
    case DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_FAIL:
    case DocumentPanelActionTypes.DELETE_DOCUMENT_API_SUCCESS: {
      return {
        ...state,
        uploadedDocs: [],
      };
    }

    case GlobalModalActionTypes.SHOW_MODAL: {
      return action.meta && action.meta.formFlow
        ? {
            ...state,
            formFlow: action.meta.formFlow,
          }
        : state;
    }

    default: {
      return state;
    }
  }
};

export default newRecordReducer;
