import { Reducer } from 'redux';
import { TYPE_TYPES, USE_TYPES } from 'pages/Details/Company/constants';
import { DetailsActions, DetailsActionTypes } from 'pages/Details/types';

export interface PropertyInterestsState {
  propertyUse: string;
  propertyType: string;
}

export const initialState: PropertyInterestsState = {
  propertyUse: USE_TYPES.ALL,
  propertyType: TYPE_TYPES.OWNERSHIP,
};

const propertyInterestsReducer: Reducer<
  PropertyInterestsState,
  DetailsActions
> = (state = initialState, action: DetailsActions) => {
  switch (action.type) {
    case DetailsActionTypes.PROPERTY_INTERESTS_UPDATE_USE: {
      return {
        ...state,
        propertyUse: action.payload,
      };
    }
    case DetailsActionTypes.PROPERTY_INTERESTS_UPDATE_TYPE: {
      return {
        ...state,
        propertyType: action.payload,
        propertyUse: USE_TYPES.ALL,
      };
    }

    default:
      return state;
  }
};

export default propertyInterestsReducer;
