import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PanelTheme } from 'components/PanelWrapper/types';
import { TableInlineActionButton } from 'components/CallToActions/Button/Button';
import { globalContent, Vertical } from 'globalConstants';
import { globalModalShow } from 'store/actions/globalModalActions';
import { getRecordsToMerge } from 'store/selectors/detailsSelectors';
import {
  TableStyled,
  TableRowStyled,
  TableBodyStyled,
  TableFooterStyled,
} from './TableStyled';
import { TableProps } from './types';
import Pagination from '../Pagination/Pagination';
import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';
import { getEntity, getRowId, getRowKey } from './helpers';

const Table = ({
  tableId,
  headers,
  rows,
  rowCount,
  columns,
  useTheme = PanelTheme.DEFAULT,
  usePagination = true,
  tableItemsConfig,
  hideBlankOption,
  editInSitu = true,
  tableUseContext,
  isMergeRecordEnabled,
  vertical,
}: TableProps) => {
  const {
    sortRows,
    sortConfig,
    updatePaginationPage,
    updatePaginationLimit,
    pagination,
    updatedLimit,
    paginationOptions,
    toggleChildren,
  } = tableItemsConfig;

  const dispatch = useDispatch();

  const recordsToMerge = useSelector(getRecordsToMerge);
  const verticalRecordsToMerge =
    vertical === Vertical.Lease ? recordsToMerge.lease : recordsToMerge.sale;
  const handleMergeRecord = () => {
    dispatch(
      globalModalShow('editRecord', true, null, '', vertical, 'mergeRecords'),
    );
  };

  const actionHeaderColSpan = 2;
  const headerColSpan = isMergeRecordEnabled
    ? headers.length - 2
    : headers.length;

  return (
    <TableStyled>
      <thead>
        <TableRowStyled>
          {headers.map((header) => (
            <TableHeaderCell
              key={`${JSON.stringify(header)}_${tableId}`}
              headerKey={header.key}
              title={header.title}
              isHeadingHidden={header.isHeadingHidden}
              isSortable={header.isSortable}
              isWide={header.isWide || false}
              useTheme={useTheme}
              columns={columns}
              sortConfig={sortConfig}
              sortRows={sortRows}
              toggleChildren={toggleChildren}
              isParent={header.isParent}
              childrenCount={header.childrenCount}
              isChild={header.isChild}
              isLastChild={header.isLastChild}
              parentCol={header.parentCol}
              childrenVisible={header.childrenVisible}
              useWhiteBackground={header.useWhiteBackground}
            />
          ))}
        </TableRowStyled>
      </thead>
      <TableBodyStyled useTheme={useTheme}>
        {rows.map((row: any, i: number) => (
          <TableRowStyled key={getRowKey(row, i)}>
            <TableRow
              row={row}
              editInSitu={editInSitu}
              rowId={getRowId(row)}
              entity={getEntity(row)}
              useContext={tableUseContext}
              vertical={vertical}
            />
          </TableRowStyled>
        ))}
      </TableBodyStyled>
      {usePagination && (
        <TableFooterStyled>
          <tr>
            {isMergeRecordEnabled && (
              <td colSpan={actionHeaderColSpan}>
                <TableInlineActionButton
                  disabled={verticalRecordsToMerge?.length < 2}
                  onClick={handleMergeRecord}
                >
                  {globalContent.merge}
                  {verticalRecordsToMerge?.length > 1 &&
                    ` (${verticalRecordsToMerge?.length})`}
                </TableInlineActionButton>
              </td>
            )}
            <td colSpan={headerColSpan}>
              <Pagination
                pagination={pagination}
                total={rowCount}
                lastPage={
                  pagination.totalPages || Math.ceil(rowCount / updatedLimit)
                }
                onClick={updatePaginationPage}
                handleSelectChange={updatePaginationLimit}
                paginationOptions={paginationOptions}
                hideBlankOption={hideBlankOption}
              />
            </td>
          </tr>
        </TableFooterStyled>
      )}
    </TableStyled>
  );
};

export default Table;
