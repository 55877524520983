const debounce = <T extends (...args: any[]) => void>(fn: T, wait = 20) => {
  let timeout: number | undefined;

  return function executedFn(...args: any[]) {
    const later = () => {
      timeout = undefined;

      fn(...args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};

export default debounce;
