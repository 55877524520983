/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuickSearch from 'connected/QuickSearch/QuickSearch';
import {
  QuickSearchOnResultItemClick,
  QuickSearchResult,
} from 'connected/QuickSearch/types';
import { ResultsUnorderList } from 'connected/QuickSearch/SearchResults/SearchResultsStyled';

import { quickSearchReset } from 'store/actions/quickSearchActions';
import { getBuildingPageData } from 'store/selectors/detailsSelectors';

import { IDENTIFY_ADDRESSES_COPY } from './constants';
import { IdentifyAddressViewProps, SelectedAddressProps } from './types';
import {
  SelectedAddressItem,
  OfficeIcon,
  SectionHeader,
  SectionTitle,
  Section,
  OtherAddressStyled,
  Paragraph,
  AddressList,
} from './IdentifyAddressStyled';
import { FIELD_COMPONENTS } from '../types';
import { GroupPrompt } from '../FormView/FormViewStyled';

const isSelected = (building: any, value: any) => {
  if (!value || value.length === 0) return false;
  return building.entityId === value.entityId;
};
const SelectedAddress: React.FC<SelectedAddressProps> = ({ address }) => (
  <SelectedAddressItem>
    <OfficeIcon />
    <span>{address}</span>
  </SelectedAddressItem>
);

const IdentifyAddress: React.FC<IdentifyAddressViewProps> = ({
  onChange,
  group,
  value,
}) => {
  const buildingData = useSelector(getBuildingPageData);

  const dispatch = useDispatch();

  const [selectedAddresses, setSelectedAddresses] = useState<
    QuickSearchResult[]
  >([]);

  const handleOnClick = (building: any) => {
    onChange(building, 'identifyAddress', 'identifyAddress', {
      groupId: 'address',
      componentType: FIELD_COMPONENTS.IDENTIFY_ADDRESS,
      name: 'identifyAddress',
      label: 'Address',
    });
  };

  const onResultItemClick: QuickSearchOnResultItemClick = (payload) => {
    const building = payload.entity!;

    handleOnClick(building);
    setSelectedAddresses([building] as QuickSearchResult[]);

    dispatch(quickSearchReset(true));
  };

  useEffect(() => {
    const payload = selectedAddresses.map((buildingDetails) => ({
      building: {
        label: 'Address',
        value: JSON.stringify(buildingDetails),
      },
    }));

    onChange(payload, group.sourceLabel!, group.groupId, group);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddresses]);

  return (
    <div>
      {!group.hideNonCanonical && (
        <>
          <GroupPrompt>{group.prompt}</GroupPrompt>
          {buildingData && buildingData.otherAddresses?.length === 0 ? (
            <Paragraph>{IDENTIFY_ADDRESSES_COPY.noneFound}</Paragraph>
          ) : (
            <AddressList>
              <ResultsUnorderList>
                {buildingData &&
                  buildingData?.otherAddresses?.map((building: any) => (
                    <li>
                      <OtherAddressStyled
                        isSelected={isSelected(building, value)}
                        type="button"
                        onClick={() => handleOnClick(building)}
                      >
                        <SelectedAddress
                          key={building.entityId}
                          address={building.buildingAddress}
                        />
                      </OtherAddressStyled>
                    </li>
                  ))}
              </ResultsUnorderList>
            </AddressList>
          )}{' '}
        </>
      )}

      <SectionHeader>
        <SectionTitle>{IDENTIFY_ADDRESSES_COPY.searchTitle}</SectionTitle>
      </SectionHeader>
      <QuickSearch
        searchIsActive
        showSummary={false}
        isSingleSearchType
        onResultItemClick={onResultItemClick}
        hideCloseButton
        formEmbed
        disableNonCanonicalResults={group.hideNonCanonical}
        resetDetails={false}
      />

      {selectedAddresses.length > 0 && (
        <Section>
          <SectionHeader>
            <SectionTitle>{IDENTIFY_ADDRESSES_COPY.selectedTitle}</SectionTitle>
          </SectionHeader>
          <ul>
            {selectedAddresses.map((building) => (
              <li>
                <OtherAddressStyled
                  isSelected={building.id === value.id}
                  type="button"
                  onClick={() => handleOnClick(building)}
                >
                  <SelectedAddress
                    key={building.id}
                    address={building.address}
                  />
                </OtherAddressStyled>
              </li>
            ))}
          </ul>
        </Section>
      )}
    </div>
  );
};

export default IdentifyAddress;
