import React, { useState } from 'react';
import isNil from 'ramda/src/isNil';
import { globalContent } from 'globalConstants';

import { translateIndexToUserFacing } from 'utils/string';
import Select from 'components/Forms/Inputs/Select/Select';
import { Option } from 'components/Forms/types';

import {
  DataSourcesContainer,
  SourceHeader,
  ViewHeader,
  ViewHeading,
  GroupRowSet,
  GroupRowMultilineTitle,
  DataPointRow,
  DataPointSet,
  DataPointLabel,
  DataPointValue,
  DataPointSource,
  EditButton,
  EditIcon,
} from './DataSourcesStyled';

import { FORM_BUILDER_COPY, OPTION_SETS } from '../constants';

import { FIELD_COMPONENTS } from '../types';
import { formatValuesForUi, getTableStructure } from './helpers';
import { DataSourcesProps } from './types';

const multiSelectComponents = [FIELD_COMPONENTS.USE_CLAUSE_SELECT];

const DataSources = ({
  formState,
  configs,
  goDirectToView,
  onSourceChange,
  onAllSourcesChange,
  hideMultilineTitle,
}: DataSourcesProps) => {
  const tableStructure = getTableStructure({ formState, configs });
  const allDataSourceOptions = OPTION_SETS.DATASOURCE;

  // if additionalDataSources are supplied then add them to the end.
  // this is currently only used to add `companies-house` as an option
  // for when users set the company parents
  configs.views.map((view) => {
    if (view.additionalDataSources)
      allDataSourceOptions.push(...view.additionalDataSources);
    return view;
  });

  const [allSources, setAllSources] = useState(OPTION_SETS.DATASOURCE[0]);
  const onViewButtonClick = (viewId: string) => () => {
    goDirectToView(viewId);
  };

  const onSelectAllChange = (option: Option) => {
    setAllSources(option);
    onAllSourcesChange(option);
  };

  const onSourceSelectChange = (option: Option, path: string) => {
    onSourceChange(option, path);
  };

  const renderUiDataRow = (
    path: string,
    label: string,
    value: string | string[],
    subSource: string,
    componentType: string,
    formattedValue?: string,
  ) => {
    // eslint-disable-next-line react/prop-types
    const foundSource = OPTION_SETS.DATASOURCE.find(
      (option) => option.value === subSource,
    );
    const selectedValue = foundSource || OPTION_SETS.DATASOURCE[0];
    const uiFormattedValue = formatValuesForUi(value, componentType, label);
    const noRowsExist = Array.isArray(value) && value.length === 0;
    if (noRowsExist) {
      return null;
    }

    if (!label) return null;

    return (
      !isNil(value) && (
        <DataPointRow key={`${label}-${value}`}>
          <DataPointSet>
            <DataPointLabel>{label}</DataPointLabel>
            <DataPointValue>
              {formattedValue || uiFormattedValue}
            </DataPointValue>
          </DataPointSet>
          <DataPointSource>
            <Select
              options={OPTION_SETS.DATASOURCE}
              name={path}
              label={FORM_BUILDER_COPY.dataSources.defaultSelectOptionLabel}
              onSelectChange={(option) => onSourceSelectChange(option, path)}
              selectedValue={selectedValue}
            />
          </DataPointSource>
        </DataPointRow>
      )
    );
  };

  const renderSubRecordRows = (
    rows: any,
    index: number,
    multilineTitle: string,
    multilineItems: number,
    recordSelected: any,
  ) => {
    return (
      <GroupRowSet
        showTitle={hideMultilineTitle || false}
        key={`group-${index}`}
      >
        {!hideMultilineTitle && multilineItems > 1 && (
          <GroupRowMultilineTitle>
            {recordSelected.title}
          </GroupRowMultilineTitle>
        )}
        {rows.map((field: any) => {
          const pathArr = field.path.split('.');
          const shouldIncludeLine = !pathArr.includes('selectRecord');

          return !isNil(field.value) && shouldIncludeLine
            ? renderUiDataRow(
                field.path,
                field.title,
                field.value,
                field.subSource,
                field.componentType,
                field.formattedValue,
              )
            : null;
        })}
      </GroupRowSet>
    );
  };

  const renderSubRows = (
    rows: any,
    index: number,
    multilineTitle: string,
    multilineItems: number,
  ) => {
    return (
      <GroupRowSet
        showTitle={hideMultilineTitle || false}
        key={`group-${index}`}
      >
        {!hideMultilineTitle && multilineItems > 1 && (
          <GroupRowMultilineTitle>
            {translateIndexToUserFacing(index)} {multilineTitle}
          </GroupRowMultilineTitle>
        )}
        {rows.map((field: any) => {
          return !isNil(field.value)
            ? renderUiDataRow(
                field.path,
                field.title,
                field.value,
                field.subSource || allSources.value,
                field.componentType,
                field.formattedValue,
              )
            : null;
        })}
      </GroupRowSet>
    );
  };

  const renderRow = (row: any, index: number) => {
    if (multiSelectComponents.includes(row.componentType)) {
      return renderUiDataRow(
        row.path,
        row.title,
        row.value,
        row.subSource,
        row.componentType,
        row.formattedValue,
      );
    }

    if (Array.isArray(row.value)) {
      if (row.isRecordType) {
        return (
          <div key={`${row.title}-${index}`}>
            {row.value.map((rowSub: any, indexSub: number) => {
              const recordSelected = rowSub.find((rowItem: any) => {
                const pathArr = rowItem.path.split('.');
                return pathArr.includes('selectRecord');
              });

              if (!recordSelected) {
                return <></>;
              }

              return renderSubRecordRows(
                rowSub,
                indexSub,
                row.multilineTitle,
                row.value.length,
                recordSelected,
              );
            })}
          </div>
        );
      }
      return (
        <div key={`${row.title}-${index}`}>
          {row.value.map((rowSub: any, indexSub: number) =>
            renderSubRows(
              rowSub,
              indexSub,
              row.multilineTitle,
              row.value.length,
            ),
          )}
        </div>
      );
    }

    return renderUiDataRow(
      row.path,
      row.title,
      row.value,
      row.subSource,
      row.componentType,
      row.formattedValue,
    );
  };

  return (
    <DataSourcesContainer>
      <SourceHeader>
        <div>
          <Select
            options={OPTION_SETS.DATASOURCE}
            name="allSources"
            label={FORM_BUILDER_COPY.dataSources.allSourcesLabel}
            onSelectChange={(option) => onSelectAllChange(option)}
            selectedValue={allSources}
            labelIsHidden={false}
          />
        </div>
      </SourceHeader>
      <ul>
        {tableStructure.map((view: any) => {
          const hasPopulatedRows = view.rows.find((row: any) => row.value);

          return hasPopulatedRows ? (
            <li key={view.viewTitle}>
              <div>
                <ViewHeader>
                  <ViewHeading>{view.viewTitle}</ViewHeading>
                  <EditButton
                    type="button"
                    onClick={onViewButtonClick(view.viewId)}
                  >
                    <EditIcon />
                    {globalContent.edit}
                  </EditButton>
                </ViewHeader>
                {view.rows.map(renderRow)}
              </div>
            </li>
          ) : (
            <></>
          );
        })}
      </ul>
    </DataSourcesContainer>
  );
};

export default DataSources;
