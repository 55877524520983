import styled, { css } from 'styled-components/macro';
import { lighten } from 'polished';

import { ReactComponent as Office } from 'assets/icons/office.svg';

export const Section = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.default};
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SectionTitle = styled.h4`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colorPalette.text.dark};
`;

export const SelectedAddressItem = styled.li`
  background: ${(props) => props.theme.colorPalette.secondary.backgroundGrey};
  padding: 10px 10px 10px 36px;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  border-radius: 4px;
  font-size: ${(props) => props.theme.typography.paragraph.small};
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const getSelectedStyle = (color: any, isSelected?: boolean) => {
  if (isSelected) {
    return css`
      li {
        background: ${color};
        width: 100%;
        border: 0;
      }
    `;
  }

  return ``;
};

export const OtherAddressStyled = styled.button<{ isSelected?: boolean }>`
  margin: 0 0 10px;
  padding: 0;
  width: 100%;
  border: 0 !important;

  li {
    margin: 0;
    width: 100%;
  }

  ${(props) =>
    getSelectedStyle(
      props.theme.colorPalette.branding.yellow,
      props.isSelected,
    )}
`;

export const OfficeIcon = styled(Office)`
  width: 15px;
  height: 15px;
  fill: ${(props) => lighten(0.2, props.theme.colorPalette.secondary.darkGrey)};
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
`;

export const Paragraph = styled.p`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  margin: ${(props) => props.theme.spacing.small} 0;
`;

export const AddressList = styled.div`
  margin: ${(props) => props.theme.spacing.small} 0 0;
`;
