import { Action } from 'redux';

export const UserTrackingActionType = 'USER_TRACKING' as const;

export type UserTrackingPayload = {
  event: string;
  meta?: Record<string, unknown>;
};

export type UserTrackingAction = Action<typeof UserTrackingActionType> & {
  payload: UserTrackingPayload;
};

export type AthenaAction = Action<string> & { payload?: unknown };

export enum TrackingEvent {
  LogIn = 'Log In',
  LogOut = 'Log Out',
  EnterNewSaleFlow = 'Enter New Sale Flow',
  EnterNewLeaseFlow = 'Enter New Lease Flow',
  CompleteNewSaleFlow = 'Complete New Sale Flow',
  CompleteNewLeaseFlow = 'Complete New Lease Flow',

  StartBuildingsSearch = 'Start Buildings Search',
  StartToLetSearch = 'Start To Let Search',
  StartLeaseSearch = 'Start Lease Search',
  StartSaleSearch = 'Start Sale Search',
  StartPortfolioSaleSearch = 'Start Portfolio Sale Search',
  StartCompanySearch = 'Start Company Search',

  ArriveAtBuildingNoResults = 'Arrive At Building No Results',
  ArriveAtBuildingWithResults = 'Arrive At Building With Results',
  ArriveAtToLetNoResults = 'Arrive At To Let No Results',
  ArriveAtToLetWithResults = 'Arrive At To Let With Results',
  ArriveAtLeasingNoResults = 'Arrive At Leasing No Results',
  ArriveAtLeasingWithResults = 'Arrive At Leasing With Results',
  ArriveAtSalesNoResults = 'Arrive At Sales No Results',
  ArriveAtSalesWithResults = 'Arrive At Sales With Results',
  ArriveAtPortfolioSalesNoResults = 'Arrive At Portfolio Sales No Results',
  ArriveAtPortfolioSalesWithResults = 'Arrive At Portfolio Sales With Results',
  ArriveAtCompanyNoResults = 'Arrive At Company No Results',
  ArriveAtCompanyWithResults = 'Arrive At Company With Results',

  TriggerExportBuildingResults = 'Trigger Export Building Results',
  TriggerExportToLetResults = 'Trigger Export To Let Results',
  TriggerExportLeaseResults = 'Trigger Export Lease Results',
  TriggerExportSaleResults = 'Trigger Export Sale Results',
  TriggerExportPortfolioSaleResults = 'Trigger Export Portfolio Sale Results',
  TriggerExportCompanyResults = 'Trigger Export Company Results',

  ClickOnBuildingQuickSearchResult = 'Click On Building Quick Search Result',
  ClickOnCompanyQuickSearchResult = 'Click On Company Quick Search Result',
}
