import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';

import {
  mapGetGeoCodeInfo,
  mapSuggestedPlacesApiFail,
} from 'store/actions/mapsActions';
import { MapActionTypes, mapErrorStatus } from 'pages/QueryBuilder/types';

const geometryEpic: Epic = (action$) =>
  action$.pipe(
    ofType(MapActionTypes.MAP_UPDATE_SELECTED_PLACE),
    mergeMap((action) => {
      const { place } = action.payload;
      const request = {
        placeId: place,
      };

      const service = new google.maps.Geocoder();

      return Observable.create((observer: any) => {
        service.geocode(request, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const { location, bounds, viewport } = results[0].geometry;
            const { short_name: placeName } = results[0].address_components[0];
            const lat = location.lat();
            const lng = location.lng();
            observer.next(
              mapGetGeoCodeInfo({
                latLng: { lat, lng },
                bounds: bounds || viewport,
                placeName,
              }),
            );
          } else {
            const error = mapErrorStatus(status);
            if (error) observer.next(mapSuggestedPlacesApiFail(error));
          }
          observer.complete();
        });
      });
    }),
  );

export default geometryEpic;
