import {
  updateSubSourceOnAllStateValues,
  getStateValueAtPath,
  updateStateAtPath,
  mergedStateWithDocsData,
  getSummaryLabel,
  mergeNewFormGroupAndFieldValue,
  updateStateRefsOnChange,
  resetStateRefsOnChange,
} from '../services/formState';
import { FormStateValue } from '../types';

export const formStateReducer = (formState: any, action: any) => {
  switch (action.type) {
    case 'handleFieldDelete': {
      const { props, initialFormState } = action.payload;

      if (!props) return formState;

      const { resetFormState, updateRefsOnChange, resetRefsOnChange } = props;
      let updatedFormState: any = resetFormState
        ? { ...initialFormState }
        : { ...formState };

      if (updateRefsOnChange) {
        updatedFormState = updateStateRefsOnChange(
          updatedFormState,
          updateRefsOnChange,
        );
      }
      if (resetRefsOnChange) {
        updatedFormState = resetStateRefsOnChange(
          updatedFormState,
          resetRefsOnChange,
        );
      }

      return updatedFormState;
    }

    case 'handleFieldUpdate': {
      const {
        value,
        id,
        label,
        groupId,
        viewId,
        props,
        initialFormState,
      } = action.payload;

      const {
        componentType,
        resetFormState,
        jsonKey,
        updateRefsOnChange,
        resetRefsOnChange,
      } = props;
      const summaryLabel = getSummaryLabel(value, jsonKey);
      let updatedFormState: any = resetFormState
        ? { ...initialFormState }
        : { ...formState };

      updatedFormState[viewId] = mergeNewFormGroupAndFieldValue(
        id,
        updatedFormState[viewId],
        { value, label, summaryLabel, componentType },
        groupId,
      );

      if (updateRefsOnChange) {
        updatedFormState = updateStateRefsOnChange(
          updatedFormState,
          updateRefsOnChange,
        );
      }
      if (resetRefsOnChange) {
        updatedFormState = resetStateRefsOnChange(
          updatedFormState,
          resetRefsOnChange,
        );
      }
      return updatedFormState;
    }
    case 'onAllDataSourcesChange': {
      return updateSubSourceOnAllStateValues(
        formState,
        action.payload.option.value,
      );
    }
    case 'onSingleDataSourceChange': {
      const stateValueAtPath = getStateValueAtPath(
        formState,
        action.payload.path,
      );
      const updatedValue: FormStateValue = {
        ...stateValueAtPath,
        subSource: action.payload.option.value,
      };
      return updateStateAtPath(formState, action.payload.path, updatedValue);
    }
    case 'updateFormStateWithDocs': {
      return mergedStateWithDocsData(
        formState,
        action.payload.viewId,
        action.payload.uploadedDoc,
      );
    }
    default:
      throw new Error();
  }
};
