import styled, { css } from 'styled-components/macro';

export const RadioIcon = styled.span``;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: left;
  margin-bottom: 30px;
`;

const getDisabledStyles = (isDisabled: boolean) => {
  if (isDisabled) {
    return css`
      pointer-events: none;
      cursor: default;
      color: ${(props) => props.theme.colorPalette.secondary.midGrey};
    `;
  }
  return ``;
};

export const RadioLabel = styled.label<{
  isDisabled: boolean;
  isLarge: boolean;
}>`
  min-height: ${(props) => (props.isLarge ? '152px' : '80px')};
  font-weight: bold;
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-family: ${(props) => props.theme.fonts.secondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
  background: ${(props) => props.theme.colorPalette.branding.white};
  border-radius: 4px;
  min-width: 100px;
  padding: 10px;
  height: calc(100% - 10px);
  cursor: pointer;

  ${(props) => getDisabledStyles(props.isDisabled)}
  text-align: center;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.colorPalette.secondary.lightGrey};
  }
`;

export const RadioButtonStyled = styled.input`
  clip-path: circle(0);
  height: 0;
  width: 0;
  &:checked + label {
    background: ${(props) => props.theme.colorPalette.branding.ink};
    color: ${(props) => props.theme.colorPalette.branding.white};
    border: 2px solid ${(props) => props.theme.colorPalette.branding.ink};
  }
  &:focus + label {
    color: ${(props) => props.theme.colorPalette.branding.white};
    background: ${(props) => props.theme.colorPalette.branding.ink};
  }
`;

export const RadioItemStyled = styled.div`
  flex: 1;
  margin: 0 ${(props) => props.theme.spacing.tablet} 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    margin: 0 ${(props) => props.theme.spacing.small} 0 0;
    max-width: 150px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const LargeRadioValidationWrapper = styled.div`
  text-align: center;
  margin: 10px 0;
`;

export const StyledHeader = styled.span`
  font-weight: bold;
  display: block;
  margin: 0 0 5px;
`;

export const RadioParagraph = styled.p`
  margin: 0;
`;
