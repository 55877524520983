import React from 'react';
import { useDropzone } from 'react-dropzone';

import { INPUT_COPY } from './constants';
import { FileInputProps } from './types';
import {
  FileInputAllowedTypes,
  FileInputField,
  StyledFileInputLabel,
} from './FileInputStyled';

const FileInput = ({ label, onFileDrop }: FileInputProps) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFileDrop });

  return (
    <FileInputField {...getRootProps()}>
      <input {...getInputProps({ placeholder: label })} />
      <StyledFileInputLabel>
        {label}
        <FileInputAllowedTypes>
          {INPUT_COPY.fileInputAcceptedFiles}
        </FileInputAllowedTypes>
      </StyledFileInputLabel>
    </FileInputField>
  );
};

export default FileInput;
