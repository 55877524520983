/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  PRIMARY_USE_MAP,
  RENT_TYPE_MAP,
  SALE_STATUS,
  TENURE_MAP,
  TRUE_FALSE_MAP,
} from 'constants/configMaps';
import { SaleField } from 'constants/sales';
import {
  formatArea,
  formatBooleanishYesNo,
  formatDate,
  formatDecimal,
  formatPercentage,
  formatPrice,
  formatPriceWithPence,
  formatYears,
  getFormattedPrice,
} from 'services/format';

export const mergeSaleMap = {
  availabilityStatus: {
    key: 'availabilityStatus',
    apiKey: 'availabilityStatus',
    keyLabel: 'Status',
    valuePath: ['availabilityStatus'],
    valueLabels: SALE_STATUS,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Sale',
  },
  tenure: {
    key: 'tenure',
    keyLabel: 'Tenure',
    apiKey: 'tenure',
    valuePath: ['tenure'],
    valueLabels: TENURE_MAP,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Sale',
  },
  listingDate: {
    key: 'onMarketDate',
    apiKey: 'onMarketDate',
    keyLabel: 'Available Date',
    valuePath: ['listingDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    entityName: 'Sale',
  },
  underOfferDate: {
    key: 'underOfferDate',
    keyLabel: 'Under Offer Date',
    apiKey: 'underOfferDate',
    valuePath: ['underOfferDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    entityName: 'Sale',
    conditional: [
      {
        ref: 'availabilityStatus',
        value: ['under-offer', 'sold', 'outcome-unknown', 'withdrawn'],
      },
    ],
  },
  soldDate: {
    key: 'soldDate',
    keyLabel: 'Sold Date',
    apiKey: 'soldDate',
    valuePath: ['soldDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [
      { ref: 'availabilityStatus', value: ['sold', 'outcome-unknown'] },
    ],
    entityName: 'Sale',
  },
  withdrawnDate: {
    key: 'offMarketDate',
    keyLabel: 'Withdrawn Date',
    apiKey: 'offMarketDate',
    valuePath: ['withdrawnDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [
      { ref: 'availabilityStatus', value: ['withdrawn', 'outcome-unknown'] },
    ],
    entityName: 'Sale',
  },
  askingPrice: {
    key: 'askingPrice',
    apiKey: 'askingPrice',
    keyLabel: 'Asking Price',
    valuePath: ['askingPrice'],
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    entityName: 'Sale',
  },
  offerPrice: {
    key: 'offeredPrice',
    keyLabel: 'Offer Price',
    apiKey: 'offeredPrice',
    valuePath: ['offeredPrice'],
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    conditional: [
      {
        ref: 'availabilityStatus',
        value: ['under-offer', 'sold', 'outcome-unknown', 'withdrawn'],
      },
    ],
    entityName: 'Sale',
  },
  achievedPrice: {
    key: 'achievedPrice',
    keyLabel: 'Price Achieved',
    apiKey: 'achievedPrice',
    valuePath: ['achievedPrice'],
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    conditional: [
      { ref: 'availabilityStatus', value: ['sold', 'outcome-unknown'] },
    ],
    entityName: 'Sale',
  },
  isConfidential: {
    key: 'dealIsPrivate',
    apiKey: 'dealIsPrivate',
    keyLabel: 'Confidential',
    valuePath: ['isConfidential'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Sale',
  },
  sellerCompanies: {
    key: 'sellerCompanies',
    apiKey: '$Vendor',
    keyLabel: 'Seller',
    valuePath: ['sellers'],
  },
  buyerCompanies: {
    key: 'buyerCompanies',
    apiKey: '$Purchaser',
    keyLabel: 'Buyer',
    valuePath: ['buyers'],
    conditional: [
      {
        ref: 'availabilityStatus',
        value: ['under-offer', 'sold', 'outcome-unknown', 'withdrawn'],
      },
    ],
  },
  holdingEntity: {
    key: 'holdingEntity',
    apiKey: '$Holding Entity',
    keyLabel: "Buyer's Holding Entity",
    valuePath: ['buyersSPV'],
    conditional: [
      {
        ref: 'availabilityStatus',
        value: ['under-offer', 'sold', 'outcome-unknown', 'withdrawn'],
      },
    ],
  },
  buyerAgents: {
    key: 'buyerAgents',
    apiKey: '$Acquiring Agent',
    keyLabel: "Buyer's Agent",
    valuePath: ['buyerAgents'],
  },
  buyerSolicitors: {
    key: 'buyerSolicitors',
    apiKey: "$Buyer's Solicitor",
    keyLabel: "Buyer's Solicitor",
    valuePath: ['buyerSolicitors'],
  },
  sellerAgents: {
    key: 'sellerAgents',
    apiKey: '$Disposing Agent',
    keyLabel: "Seller's Agent",
    valuePath: ['sellerAgents'],
  },
  sellerSolicitors: {
    key: 'sellerSolicitors',
    apiKey: "$Seller's Solicitor",
    keyLabel: "Seller's Solicitor",
    valuePath: ['sellerSolicitors'],
  },
  other: {
    key: 'other',
    apiKey: '$Other',
    keyLabel: 'Other',
    valuePath: ['other'],
  },
  totalAreaAtSale: {
    key: 'totalAreaAtSale',
    apiKey: 'totalAreaAtSale',
    keyLabel: 'Total Area',
    valuePath: ['totalAreaAtSale'],
    formatter: (field: SaleField) =>
      field.value ? formatArea(field.value) : null,
    entityName: 'Sale',
  },
  'longLeaseholdInformation|startDate': {
    key: 'startDate',
    keyLabel: 'Lease Start Date',
    apiKey: 'startDate',
    valuePath: ['longLease', '0', 'startDate'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    formatter: (field: SaleField) => formatDate(field.value),
  },
  'longLeaseholdInformation|endDate': {
    key: 'endDate',
    keyLabel: 'Lease End Date',
    apiKey: 'endDate',
    valuePath: ['longLease', '0', 'endDate'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    formatter: (field: SaleField) => formatDate(field.value),
  },
  'longLeaseholdInformation|gearing': {
    key: 'gearing',
    keyLabel: 'Lease Gearing',
    apiKey: 'gearing',
    valuePath: ['longLease', '0', 'gearing'],
    valueLabels: TRUE_FALSE_MAP,
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    entityIdPath: ['longLease', '0', 'entityId', 'value'],
    formatter: (field: SaleField) => field.valueLabels!.get(field.value),
  },
  'longLeaseholdInformation|gearingProportion': {
    key: 'gearingProportion',
    keyLabel: 'Lease Gearing Proportion',
    apiKey: 'gearingProportion',
    valuePath: ['longLease', '0', 'gearingProportion'],
    entityIdPath: ['longLease', '0', 'entityId', 'value'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    formatter: (field: SaleField) => formatPercentage(field.value),
  },
  'longLeaseholdInformation|fixedValue': {
    key: 'gearingFixed',
    keyLabel: 'Fixed Value',
    apiKey: 'gearingFixed',
    valuePath: ['longLease', '0', 'fixedValue'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    entityIdPath: ['longLease', '0', 'entityId', 'value'],
    formatter: (field: SaleField) => formatPrice(field.value),
  },
  'longLeaseholdInformation|rentType': {
    key: 'gearingBasisType',
    keyLabel: 'Rent Type',
    apiKey: 'gearingBasisType',
    valuePath: ['longLease', '0', 'rentType'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    entityIdPath: ['longLease', '0', 'entityId', 'value'],
    valueLabels: RENT_TYPE_MAP,
    formatter: (field: SaleField) => field.valueLabels!.get(field.value),
  },
  'longLeaseholdInformation|rentReviewPeriod': {
    key: 'gearingReviewFrequency',
    keyLabel: 'Review Period',
    apiKey: 'gearingReviewFrequency',
    valuePath: ['longLease', '0', 'review'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    entityIdPath: ['longLease', '0', 'entityId', 'value'],
    formatter: (field: SaleField) => formatYears(field.value),
  },
  'longLeaseholdInformation|rentMinimum': {
    key: 'gearingMinRent',
    keyLabel: 'Minimum Rent',
    valuePath: ['longLease', '0', 'minRent'],
    apiKey: 'gearingMinRent',
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLease',
    entityIdPath: ['longLease', '0', 'entityId', 'value'],
    formatter: (field: SaleField) => formatPriceWithPence(field.value),
  },
  'longLeaseholdInformation|rentAdditional': {
    key: 'description',
    keyLabel: 'Other Details',
    apiKey: 'description',
    valuePath: ['longLeases', '0', 'notes', 'value', 'description'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'LongLeaseNote',
    entityIdPath: ['longLease', '0', 'notes', 'value', 'entityId', 'value'],
  },
  rentalTopUp: {
    key: 'rentalTopUpAmount',
    apiKey: 'rentalTopUpAmount',
    keyLabel: 'Rental Top Up Amount',
    valuePath: ['rentalTopUpAmount'],
    formatter: (field: SaleField) =>
      field.value ? formatPrice(field.value) : null,
    entityName: 'Sale',
  },
  freeholder: {
    key: 'freeholder',
    keyLabel: 'Freeholder',
    apiKey: '$Freeholder',
    valuePath: ['freeholder'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    entityName: 'Sale',
  },
  yearlyRentalIncome: {
    key: 'annualRentalIncome',
    keyLabel: 'Annual Rental Income',
    apiKey: 'rentalIncome',
    valuePath: ['annualRentalIncome'],
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    entityName: 'Sale',
  },
  netYield: {
    key: 'netInitialYield',
    keyLabel: 'Net Initial Yield',
    valuePath: ['netInitialYield'],
    apiKey: 'netInitialYield',
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    entityName: 'Sale',
  },
  purchaserCost: {
    key: 'purchaserCosts',
    apiKey: 'purchaserCosts',
    keyLabel: 'Purchaser Costs',
    valuePath: ['purchaserCosts'],
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    entityName: 'Sale',
  },
  equivalentYield: {
    key: 'equivalentYield',
    apiKey: 'equivalentYield',
    keyLabel: 'Equivalent Yield',
    valuePath: ['equivalentYield'],
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    entityName: 'Sale',
  },
  reversionaryYield: {
    key: 'reversionaryYield',
    apiKey: 'reversionaryYield',
    keyLabel: 'Reversionary Yield',
    valuePath: ['reversionaryYield'],
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    entityName: 'Sale',
  },
  waultc: {
    key: 'waultc',
    apiKey: 'weightedAverageUnexpiredLeaseTermCertain',
    keyLabel: 'WAULTC',
    valuePath: ['waultc'],
    formatter: (field: SaleField) =>
      field.value ? formatDecimal(field.value) : null,
    entityName: 'Sale',
  },
  wault: {
    key: 'wault',
    apiKey: 'weightedAverageUnexpiredLeaseTerm',
    keyLabel: 'WAULT',
    valuePath: ['wault'],
    formatter: (field: SaleField) =>
      field.value ? formatDecimal(field.value) : null,
    entityName: 'Sale',
  },
  onMarket: {
    key: 'onMarketTransaction',
    keyLabel: 'On Market Transaction',
    apiKey: 'onMarketTransaction',
    valuePath: ['onMarketTransaction'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Sale',
  },
  vacantPossession: {
    key: 'vacantPossession',
    keyLabel: 'Vacant Possession',
    apiKey: 'vacantPossession',
    valuePath: ['vacantPossession'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Sale',
  },
  hasBuyerAgent: {
    key: 'hasBuyerAgent',
    keyLabel: "Has Buyer's Agent",
    apiKey: 'hasBuyerAgent',
    valuePath: ['hasBuyerAgent'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Sale',
  },
  hasSellerAgent: {
    key: 'hasSellerAgent',
    keyLabel: "Has Seller's Agent",
    apiKey: 'hasSellerAgent',
    valuePath: ['hasSellerAgent'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    entityName: 'Sale',
  },
  propertyWebsite: {
    key: 'propertyUrl',
    keyLabel: 'Property Website',
    apiKey: 'propertyUrl',
    valuePath: ['propertyUrl'],
    entityName: 'Sale',
  },
  offersCount: {
    key: 'numberOfOffers',
    keyLabel: 'Number of Offers',
    apiKey: 'numberOfOffers',
    valuePath: ['numberOfOffers'],
    entityName: 'Sale',
  },
  enquiriesCount: {
    key: 'numberOfEnquiries',
    keyLabel: 'Number of Enquiries',
    apiKey: 'numberOfEnquiries',
    valuePath: ['numberOfEnquiries'],
    entityName: 'Sale',
  },
  viewingsCount: {
    key: 'numberOfViewings',
    keyLabel: 'Number of Viewings',
    apiKey: 'numberOfViewings',
    valuePath: ['numberOfViewings'],
    entityName: 'Sale',
  },
  jobCode: {
    key: 'jobCode',
    keyLabel: 'Job Code',
    apiKey: 'jobCode',
    valuePath: ['jobCode'],
    formatter: (field: SaleField) =>
      field.value !== null ? `${field.value}` : null,
    entityName: 'Sale',
  },
  instructionDate: {
    key: 'instructionDate',
    keyLabel: 'Instruction Date',
    apiKey: 'instructionDate',
    valuePath: ['instructionDate'],
    formatter: (field: SaleField) =>
      field.value ? formatDate(field.value) : null,
    entityName: 'Sale',
  },
  practicalCompletionDate: {
    key: 'practicalCompletionDate',
    keyLabel: 'Practical Completion Date',
    apiKey: 'practicalCompletion',
    valuePath: ['practicalCompletionDate'],
    formatter: (field: SaleField) =>
      field.value ? formatDate(field.value) : null,
    entityName: 'Sale',
  },
  readyToOccupyDate: {
    key: 'readyToOccupyDate',
    keyLabel: 'Ready to Occupy Date',
    apiKey: 'readyToOccupyDate',
    valuePath: ['readyToOccupyDate'],
    formatter: (field: SaleField) =>
      field.value ? formatDate(field.value) : null,
    entityName: 'Sale',
  },
  assetClass: {
    key: 'primaryUse',
    keyLabel: 'Asset Class',
    apiKey: 'primaryUse',
    valuePath: ['primaryUse'],
    valueLabels: PRIMARY_USE_MAP,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
    entityName: 'Sale',
  },
  notesAll: {
    key: 'notesAll',
    keyLabel: 'Notes',
    apiKey: 'notesAll',
    valuePath: ['notesAll'],
  },
};
