import { isBefore, parseISO } from 'date-fns';

const getDateSortOrder = (a: any, b: any, prop: string) => {
  const isDateBefore = isBefore(parseISO(a[prop]), parseISO(b[prop]));
  const isDateAfter = isBefore(parseISO(b[prop]), parseISO(a[prop]));

  if (isDateBefore) {
    return 1;
  }

  if (isDateAfter) {
    return -1;
  }

  return 0;
};

const getSortOrder = (a: any, b: any, prop: string) => {
  if (a[prop] < b[prop]) {
    return 1;
  }
  if (b[prop] < a[prop]) {
    return -1;
  }
  return 0;
};

const getNestedSortOrder = (
  a: any,
  b: any,
  prop: string,
  nestedProp: string,
) => {
  if (a[prop][nestedProp || ''] < b[prop][nestedProp]) {
    return 1;
  }
  if (a[prop][nestedProp] > b[prop][nestedProp]) {
    return -1;
  }

  return 0;
};

export const sortArrayByProp = <T = any>(
  arr: T[],
  prop: string,
  isDate = false,
  nestedProp?: string,
  inverse = false,
) => {
  return arr.sort((a: any, b: any) => {
    const firstItem = inverse ? b : a;
    const secondItem = inverse ? a : b;

    if (isDate) {
      return getDateSortOrder(firstItem, secondItem, prop);
    }

    if (nestedProp) {
      return getNestedSortOrder(firstItem, secondItem, prop, nestedProp);
    }

    return getSortOrder(firstItem, secondItem, prop);
  });
};

export const sortNestedArrayByProp = (
  arr: [],
  ref: string,
  nestedProp: string,
) => {
  return arr.sort((a: any, b: any) => {
    const optionA = a[ref].sort(
      (c: any, d: any) => c[nestedProp] > d[nestedProp],
    )[0];
    const optionB = b[ref].sort(
      (c: any, d: any) => c[nestedProp] > d[nestedProp],
    )[0];
    const keyA = optionA ? optionA[nestedProp] : '';
    const keyB = optionB ? optionB[nestedProp] : '';
    if (keyA === '') return 1;
    if (keyB === '') return -1;

    if (keyA > keyB) {
      return 1;
    }

    if (keyB > keyA) {
      return -1;
    }
    return 0;
  });
};
