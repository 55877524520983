import React from 'react';
import {
  ExternalLinkStyled,
  CRMNearestIcon,
} from 'connected/BasePanel/BasePanelStyled';
import { urls } from 'globalConstants';
import { CRMNearestLinkProps } from './types';

const CRMNearestLink: React.FC<CRMNearestLinkProps> = ({ crmNearestId }) => (
  <ExternalLinkStyled
    href={`${urls.crm}${crmNearestId}`}
    rel="noopener noreferrer"
    target="_blank"
    title="view nearest CRM record"
  >
    <CRMNearestIcon />
  </ExternalLinkStyled>
);

export default CRMNearestLink;
