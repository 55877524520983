import { InlineLabelProps, ValidationProps } from 'connected/FormFlow/types';

export type DateLengthProps = {
  label: string;
  name: string;
  validation?: ValidationProps;
  selectedValue?: Date;
  onChange: (
    date?: string | number | Date,
    label?: string,
    id?: string,
  ) => void;
  startDate?: Date;
  inlineLabels?: InlineLabelProps[];
};

export enum DateLengthTypes {
  LENGTH = 'Length',
  YEAR = 'Year',
}

export enum DateLengthLabels {
  LENGTH = 'length',
  END_YEAR = 'endYear',
}
