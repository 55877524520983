import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  CompanyGetDeletionDataActionTypes,
  companyGetDeletionDataApiFail,
  companyGetDeletionDataApiSuccess,
} from 'store/actions/companyDeleteActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

/**
 * Get deletion data for given company
 *
 * @param action$
 * @param state$
 * @param dependencies
 * @returns
 */
const companyGetDeletionDataEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA),
    mergeMap((action) => {
      const companyGetDeletionDataEndpoint = endpoints.getCompanyDeletionData(
        action.payload.companyId,
      );

      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url: companyGetDeletionDataEndpoint,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`GET ${companyGetDeletionDataEndpoint} failed`);
            }
            return of(companyGetDeletionDataApiSuccess(response.response));
          }),
          catchError((error) => {
            return of(companyGetDeletionDataApiFail(error));
          }),
        );
    }),
  );
};

export default companyGetDeletionDataEpic;
