import { Reducer } from 'redux';
import { FormFlowActions, FormFlowActionTypes } from 'connected/FormFlow/types';
import { SaleRecord, LeaseRecord } from 'pages/Details/types';

export interface FormFlowState {
  activeSection: number;
  activeSectionName: string;
  previousSection: number;
  sectionInstantiated?: boolean;
  matchingRecords?: {
    leases?: LeaseRecord[];
    sales?: SaleRecord[];
  };
}

export const initialState = {
  activeSection: 0,
  activeSectionName: '',
  previousSection: 0,
  sectionInstantiated: false,
};

const formBuilderReducer: Reducer<FormFlowState, FormFlowActions> = (
  state = initialState,
  action: FormFlowActions,
) => {
  switch (action.type) {
    case FormFlowActionTypes.SET_ACTIVE_SECTION: {
      return {
        ...state,
        activeSection: action.payload.index,
        activeSectionName: action.payload.name,
        previousSection: state.activeSection,
        sectionInstantiated: false,
      };
    }

    case FormFlowActionTypes.INITIALIZE_FORM_BUILDER: {
      return {
        ...state,
        activeSectionName: action.payload.name,
      };
    }

    case FormFlowActionTypes.INSTANTIATE_SECTION: {
      return {
        ...state,
        sectionInstantiated: true,
      };
    }

    case FormFlowActionTypes.GET_PORTFOLIO_MATCHING_RECORDS_SUCCESS: {
      return {
        ...state,
        sectionInstantiated: true,
        matchingRecords: {
          leases: action.payload.leases,
          sales: action.payload.sales,
        },
      };
    }

    case FormFlowActionTypes.RESET_FORM_BUILDER:
      return initialState;

    default: {
      return state;
    }
  }
};

export default formBuilderReducer;
