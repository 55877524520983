import {
  AthenaUserActionTypes,
  FetchAthenaUserAction,
  FetchAthenaUserFailActionType,
  FetchAthenaUserSuccessActionType,
} from 'store/actions/athenaUserActions';

export type AthenaUserPayload = {
  isFetching: boolean;
  hasError: boolean;
  error?: string;
  viewedReleaseNotesVersion: string | null | undefined;
  viewedReleaseNotesVersionDate: string;
};

export const initialState: AthenaUserPayload = {
  isFetching: false,
  hasError: false,
  error: '',
  viewedReleaseNotesVersion: undefined,
  viewedReleaseNotesVersionDate: '',
};

const athenaUserReducer = (
  state = initialState,
  action:
    | FetchAthenaUserAction
    | FetchAthenaUserSuccessActionType
    | FetchAthenaUserFailActionType,
) => {
  switch (action.type) {
    case AthenaUserActionTypes.FETCH_ATHENA_USER: {
      return {
        ...state,
        isFetching: true,
        viewedReleaseNotesVersion: undefined,
      };
    }
    case AthenaUserActionTypes.FETCH_ATHENA_USER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        viewedReleaseNotesVersion:
          action.payload === null
            ? null
            : action.payload.viewedReleaseNotesVersion,
      };
    }

    default:
      return state;
  }
};

export default athenaUserReducer;
