import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { globalVariables } from 'globalConstants';
import { RootState } from 'store/rootReducer';
import { Option } from 'components/Forms/types';
import SelectSearch from 'components/Forms/Inputs/SelectSearch/SelectSearch';
import { ValidationProps } from 'connected/FormFlow/types';
import {
  companySelectQuery,
  companySelectReset,
} from 'store/actions/companySelectActions';
import { COMPANY_SELECT_COPY } from './constants';
import { parseSelectedValue } from './helpers';

interface CompanySelectProps {
  onChange: (option: Option) => void;
  selectedValue?: string[];
  label: string;
  name?: string;
  isDisabled?: boolean;
  validation?: ValidationProps;
  isMultiSelect?: boolean;
  onDelete: (isCompany: boolean) => void;
  canAddNew?: boolean;
  omitCompanyHouse?: boolean;
  includeIsPrivate?: boolean;
  excludeCurrentCompany?: string;
}

const CompanySelect: React.FC<CompanySelectProps> = ({
  onChange,
  selectedValue,
  isDisabled = false,
  label,
  name,
  validation,
  isMultiSelect = false,
  onDelete,
  canAddNew = false,
  omitCompanyHouse = false,
  includeIsPrivate = false,
  excludeCurrentCompany,
}: CompanySelectProps) => {
  const dispatch = useDispatch();

  const {
    results: { athena, companiesHouse },
    isFetching,
    searchTerm,
  } = useSelector((state: RootState) => state.companySelect);

  const pageNumber = athena.meta?.page ?? 0;
  const totalPages = athena.meta?.pages ?? 0;
  const canShowMoreResults = totalPages > pageNumber + 1;

  const fetchMoreResults = useCallback(
    () =>
      dispatch(
        companySelectQuery(
          searchTerm,
          includeIsPrivate,
          excludeCurrentCompany,
          (athena.meta?.page ?? 0) + 1,
        ),
      ),
    [dispatch, searchTerm, athena, includeIsPrivate, excludeCurrentCompany],
  );

  const resetOnUnMount = () => {
    return () => {
      dispatch(companySelectReset());
    };
  };

  const fetchNewOptions = (newSearchTerm: string) => {
    dispatch(
      companySelectQuery(
        newSearchTerm,
        includeIsPrivate,
        excludeCurrentCompany,
      ),
    );
  };

  const resetOptions = () => {
    dispatch(companySelectReset());
    onDelete(true);
  };

  useEffect(resetOnUnMount, [name]);

  const athenaOptions = athena.data.map(
    ({ id, name: companyName, address }) => ({
      label: companyName,
      subLabel: address || '',
      value: JSON.stringify({
        name: companyName,
        id,
      }),
    }),
  );

  const companiesHouseOptions = companiesHouse.data.map(
    ({ name: companyHouseName, address, companyRegistrationNumber }) => ({
      label: companyHouseName,
      regNumber: companyRegistrationNumber,
      subLabel: address || '',
      value: JSON.stringify({
        name: companyHouseName,
        companyRegistrationNumber,
      }),
    }),
  );

  const athenaResults = {
    canShowMoreResults,
    onShowMoreResults: fetchMoreResults,
    label: COMPANY_SELECT_COPY.dbLabel,
    options: athenaOptions,
  };

  const companiesHouseResult = {
    label: COMPANY_SELECT_COPY.companyHouseLabel,
    options: companiesHouseOptions,
  };

  return (
    <SelectSearch
      id={name || 'company-select'}
      name={name || 'company-select'}
      options={
        omitCompanyHouse
          ? [athenaResults]
          : [athenaResults, companiesHouseResult]
      }
      isAsync
      canAddNew={canAddNew}
      isLoading={isFetching}
      label={label}
      onInputChange={fetchNewOptions}
      onChange={onChange}
      multipleSelect={
        isMultiSelect || (selectedValue && selectedValue.length > 1)
      }
      maxWidth={globalVariables.SELECT_MAX_WIDTH}
      isWide
      placeholder=""
      isDisabled={isDisabled}
      noOptionsMessage={COMPANY_SELECT_COPY.noOptions}
      loadingMessage={COMPANY_SELECT_COPY.loading}
      selectedOptions={selectedValue ? parseSelectedValue(selectedValue) : []}
      validation={validation}
      onDelete={resetOptions}
    />
  );
};

export default CompanySelect;
