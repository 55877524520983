import {
  GlobalApiFailError,
  GlobalErrors,
  GlobalGenericErrorAction,
} from './types';

export const globalError = (
  payload: GlobalApiFailError,
): GlobalGenericErrorAction => ({
  type: GlobalErrors.GENERIC_ERROR,
  payload,
});
