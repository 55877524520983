import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/athena_logo_xmas.svg';

export const PageLayout = styled.main`
  grid-area: global-main;
  background: ${(props) => props.theme.colorPalette.branding.ink};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const NotFoundTitle = styled.h1`
  font-size: ${(props) => props.theme.typography.heading.medium};
  color: ${(props) => props.theme.colorPalette.text.white};
  margin: 0;
`;

export const NotFoundDescription = styled.p`
  color: ${(props) => props.theme.colorPalette.text.white};
  font-size: ${(props) => props.theme.typography.paragraph.large};
  padding: 10px;
`;

export const ReturnLink = styled(Link)`
  text-decoration: underline;
  color: ${(props) => props.theme.colorPalette.text.white};
  font-size: ${(props) => props.theme.typography.paragraph.large};
`;

export const AthenaLogo = styled(Logo)`
  width: 300px;
  height: 300px;
`;
