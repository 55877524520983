import { GlobalApiFailError } from 'store/actions/types';

import {
  NewLeaseActionTypes,
  EntityNewLease,
  NewLeaseSaveAction,
  NewLeaseApiFailAction,
  NewLeaseApiSuccessAction,
  NewLeaseResetAction,
} from 'pages/NewLease/types';

export const newLeaseSave = (payload: EntityNewLease): NewLeaseSaveAction => ({
  type: NewLeaseActionTypes.NEW_LEASE_SAVE_LEASE,
  payload,
});

export const newLeaseReset = (): NewLeaseResetAction => ({
  type: NewLeaseActionTypes.NEW_LEASE_RESET,
});

export const newLeaseApiSuccess = (): NewLeaseApiSuccessAction => ({
  type: NewLeaseActionTypes.NEW_LEASE_API_SUCCESS,
});

export const newLeaseApiFail = (
  payload: GlobalApiFailError,
): NewLeaseApiFailAction => ({
  type: NewLeaseActionTypes.NEW_LEASE_API_FAIL,
  payload,
});
