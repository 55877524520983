import React from 'react';
import { withKnobs, color } from '@storybook/addon-knobs';

import { ReactComponent as Add } from 'assets/icons/add.svg';
import { ReactComponent as AddCircleOutline } from 'assets/icons/add-circle-outline.svg';
import { ReactComponent as AddCircleOutlineDuplicate } from 'assets/icons/add-circle-outline-duplicate.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as Building } from 'assets/icons/building.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import { ReactComponent as Company } from 'assets/icons/company.svg';
import { ReactComponent as ChevronFirst } from 'assets/icons/first-arrow.svg';
import { ReactComponent as ChevronLast } from 'assets/icons/last-arrow.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as CRM } from 'assets/icons/crm.svg';
import { ReactComponent as DoubleArrow } from 'assets/icons/double-arrow.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as ExpandMore } from 'assets/icons/expand-more.svg';
import { ReactComponent as ExpandLess } from 'assets/icons/expand-less.svg';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as Sliders } from 'assets/icons/sliders.svg';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as Industrial } from 'assets/icons/industrial.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as InfoOutline } from 'assets/icons/info-outline.svg';
import { ReactComponent as Office } from 'assets/icons/office.svg';
import { ReactComponent as PDF } from 'assets/icons/pdf.svg';
import { ReactComponent as Retail } from 'assets/icons/retail.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { ReactComponent as SplitView } from 'assets/icons/split-view.svg';
import { ReactComponent as Spreadsheet } from 'assets/icons/spreadsheet.svg';
import { ReactComponent as Tick } from 'assets/icons/tick.svg';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import { ReactComponent as View } from 'assets/icons/view.svg';
import { ReactComponent as Void } from 'assets/icons/void.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as World } from 'assets/icons/world.svg';
import { ReactComponent as Telephone } from 'assets/icons/phone.svg';
import withIconStyles from './Icon';

export default {
  component: ArrowForward,
  title: 'Icon',
  decorators: [withKnobs],
};

const colorLabel = 'Color';
const defaultColor = 'black';

const AddStyled = withIconStyles(Add);
export const AddIcon = () => (
  <AddStyled color={color(colorLabel, defaultColor)} />
);

const AddCircleOutlineStyled = withIconStyles(AddCircleOutline);
export const AddCircleOutlineIcon = () => (
  <AddCircleOutlineStyled color={color(colorLabel, defaultColor)} />
);

const AddCircleOutlineDuplicateStyled = withIconStyles(
  AddCircleOutlineDuplicate,
);
export const AddCircleOutlineDuplicateIcon = () => (
  <AddCircleOutlineDuplicateStyled color={color(colorLabel, defaultColor)} />
);

const ArrowForwardStyled = withIconStyles(ArrowForward);
export const ArrowForwardIcon = () => (
  <ArrowForwardStyled color={color(colorLabel, defaultColor)} />
);

const ArrowBackStyled = withIconStyles(ArrowBack);
export const ArrowBackIcon = () => (
  <ArrowBackStyled color={color(colorLabel, defaultColor)} />
);

const ArrowUpStyled = withIconStyles(ArrowUp);
export const ArrowUpIcon = () => (
  <ArrowUpStyled color={color(colorLabel, defaultColor)} />
);

const ArrowDownStyled = withIconStyles(ArrowDown);
export const ArrowDownIcon = () => (
  <ArrowDownStyled color={color(colorLabel, defaultColor)} />
);

const BuildingStyled = withIconStyles(Building);
export const BuildingIcon = () => (
  <BuildingStyled color={color(colorLabel, defaultColor)} />
);

const CancelStyled = withIconStyles(Cancel);
export const CancelIcon = () => (
  <CancelStyled color={color(colorLabel, defaultColor)} />
);

const ChevronLeftStyled = withIconStyles(ChevronLeft);
export const ChevronLeftIcon = () => (
  <ChevronLeftStyled color={color(colorLabel, defaultColor)} />
);

const ChevronRightStyled = withIconStyles(ChevronRight);
export const ChevronRightIcon = () => (
  <ChevronRightStyled color={color(colorLabel, defaultColor)} />
);

const CRMStyled = withIconStyles(CRM);
export const CRMIcon = () => (
  <CRMStyled color={color(colorLabel, defaultColor)} />
);

const DoubleArrowStyled = withIconStyles(DoubleArrow);
export const DoubleArrowIcon = () => (
  <DoubleArrowStyled color={color(colorLabel, defaultColor)} />
);

const ExpandMoreStyled = withIconStyles(ExpandMore);
export const ExpandMoreIcon = () => (
  <ExpandMoreStyled color={color(colorLabel, defaultColor)} />
);

const ExpandLessStyled = withIconStyles(ExpandLess);
export const ExpandLessIcon = () => (
  <ExpandLessStyled color={color(colorLabel, defaultColor)} />
);

const CompanyStyled = withIconStyles(Company);
export const CompanyIcon = () => (
  <CompanyStyled color={color(colorLabel, defaultColor)} />
);

const DeleteStyled = withIconStyles(Delete);
export const DeleteIcon = () => (
  <DeleteStyled color={color(colorLabel, defaultColor)} />
);

const ErrorStyled = withIconStyles(Error);
export const ErrorIcon = () => (
  <ErrorStyled color={color(colorLabel, defaultColor)} />
);

const EditStyled = withIconStyles(Edit);
export const EditIcon = () => (
  <EditStyled color={color(colorLabel, defaultColor)} />
);

const UploadStyled = withIconStyles(Upload);
export const UploadIcon = () => (
  <UploadStyled color={color(colorLabel, defaultColor)} />
);

const IndustrialStyled = withIconStyles(Industrial);
export const IndustrialIcon = () => (
  <IndustrialStyled color={color(colorLabel, defaultColor)} />
);

const InfoStyled = withIconStyles(Info);
export const InfoIcon = () => (
  <InfoStyled color={color(colorLabel, defaultColor)} />
);

const InfoOutlineStyled = withIconStyles(InfoOutline);
export const InfoOutlineIcon = () => (
  <InfoOutlineStyled color={color(colorLabel, defaultColor)} />
);

const OfficeStyled = withIconStyles(Office);
export const OfficeIcon = () => (
  <OfficeStyled color={color(colorLabel, defaultColor)} />
);

const RetailStyled = withIconStyles(Retail);
export const RetailIcon = () => (
  <RetailStyled color={color(colorLabel, defaultColor)} />
);

const RefreshStyled = withIconStyles(Refresh);
export const RefreshIcon = () => (
  <RefreshStyled color={color(colorLabel, defaultColor)} />
);

const SpinnerStyled = withIconStyles(Spinner);
export const SpinnerIcon = () => (
  <SpinnerStyled color={color(colorLabel, defaultColor)} />
);

export const SplitViewStyled = withIconStyles(SplitView);
export const SplitViewIcon = () => (
  <SplitViewStyled color={color(colorLabel, defaultColor)} />
);

const WarningStyled = withIconStyles(Warning);
export const WarningIcon = () => (
  <WarningStyled color={color(colorLabel, defaultColor)} />
);

const WorldStyled = withIconStyles(World);
export const WorldIcon = () => (
  <WorldStyled color={color(colorLabel, defaultColor)} />
);

const DocumentStyled = withIconStyles(Document);
export const DocumentIcon = () => (
  <DocumentStyled color={color(colorLabel, defaultColor)} />
);

const PDFStyled = withIconStyles(PDF);
export const PDFIcon = () => (
  <PDFStyled color={color(colorLabel, defaultColor)} />
);

const ImageStyled = withIconStyles(Image);
export const ImageIcon = () => (
  <ImageStyled color={color(colorLabel, defaultColor)} />
);

const SpreadsheetStyled = withIconStyles(Spreadsheet);
export const SpreadsheetIcon = () => (
  <SpreadsheetStyled color={color(colorLabel, defaultColor)} />
);

const ChevronLastStyled = withIconStyles(ChevronLast);
export const ChevronLastIcon = () => (
  <ChevronLastStyled color={color(colorLabel, defaultColor)} />
);

const ChevronFirstStyled = withIconStyles(ChevronFirst);
export const ChevronFirstIcon = () => (
  <ChevronFirstStyled color={color(colorLabel, defaultColor)} />
);

const FilterStyled = withIconStyles(Filter);
export const FilterIcon = () => (
  <FilterStyled color={color(colorLabel, defaultColor)} />
);

const TickStyled = withIconStyles(Tick);
export const TickIcon = () => (
  <TickStyled color={color(colorLabel, defaultColor)} />
);

const ViewStyled = withIconStyles(View);
export const ViewIcon = () => (
  <ViewStyled color={color(colorLabel, defaultColor)} />
);

const VoidStyled = withIconStyles(Void);
export const VoidIcon = () => (
  <VoidStyled color={color(colorLabel, defaultColor)} />
);

const SlidersStyled = withIconStyles(Sliders);
export const SlidersIcon = () => (
  <SlidersStyled color={color(colorLabel, defaultColor)} />
);
const TelephoneStyled = withIconStyles(Telephone);
export const TelephoneIcon = () => (
  <TelephoneStyled color={color(colorLabel, defaultColor)} />
);
