import { GlobalApiFailError } from 'store/actions/types';

import {
  DocumentUploaderActionTypes,
  EntityDocumentUpload,
  StartFileUploadAction,
  CancelFileUploadAction,
  FileUploadApiSuccessAction,
  FileUploadSuccessMessageShownAction,
  FileUploadApiFailAction,
  ResetFileUpload,
  ProgressUpdateFileUploadAction,
} from 'connected/DocumentUploader/types';

export const startFileUpload = (
  payload: EntityDocumentUpload,
  callback?: () => void,
  withFormPath?: string,
): StartFileUploadAction => ({
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_START_UPLOAD,
  payload,
  callback,
  withFormPath,
});

export const cancelFileUpload = (): CancelFileUploadAction => ({
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_CANCEL_UPLOAD,
});

export const progressUpdateFileUpload = (
  payload: number,
): ProgressUpdateFileUploadAction => ({
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_PROGRESS_UPDATE_UPLOAD,
  payload,
});

export const fileUploadApiSuccess = (
  callback?: () => void,
): FileUploadApiSuccessAction => ({
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_SUCCESS,
  callback,
});

export const fileUploadSuccessMessageShown = (): FileUploadSuccessMessageShownAction => ({
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_SUCCESS_MESSAGE_SHOWN,
});

export const fileUploadApiFail = (
  payload: GlobalApiFailError,
): FileUploadApiFailAction => ({
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_FAIL,
  payload,
});

export const resetFileUpload = (): ResetFileUpload => ({
  type: DocumentUploaderActionTypes.DOCUMENT_UPLOADER_RESET_UPLOAD,
});
