import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { fetchAthenaUser } from 'store/actions/athenaUserActions';
import { globalModalShow } from 'store/actions/globalModalActions';
import { MODAL_ID } from 'connected/GlobalModal/constants';
import { ReleaseNotesModal } from './ReleaseNotesModal';

const ReleaseNotes = () => {
  const dispatch = useDispatch();
  const [releaseNotesBody, setReleaseNotesBody] = useState('');

  const { viewedReleaseNotesVersion } = useSelector(
    (state: RootState) => state.athenaUser,
  );

  const athenaFriendlyVersion = (window as any)
    .ATHENA_FRIENDLY_VERSION as string;

  useEffect(() => {
    dispatch(fetchAthenaUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('release_notes_never')) {
        return;
      }

      if (
        athenaFriendlyVersion !== undefined &&
        viewedReleaseNotesVersion !== undefined &&
        viewedReleaseNotesVersion !== athenaFriendlyVersion
      ) {
        const url = `/other/release-notes/${athenaFriendlyVersion}.md`;
        const response = await fetch(url);
        const body = await response.text();

        setReleaseNotesBody(body);
        dispatch(globalModalShow(MODAL_ID.RELEASE_NOTES));
      }
    })();
  }, [viewedReleaseNotesVersion, athenaFriendlyVersion, dispatch]);

  return (
    <ReleaseNotesModal
      athenaFriendlyVersion={athenaFriendlyVersion}
      releaseNotesBody={releaseNotesBody}
    />
  );
};

export default ReleaseNotes;
