import { GlobalApiFailError } from 'store/actions/types';

export type DocumentTableProps = {
  modalIsOpen: boolean;
  modalId: string;
};

export enum DocumentPanelActionTypes {
  DOCUMENT_DOWNLOAD_GET_SIGNED_URL = 'DOCUMENT_DOWNLOAD_GET_SIGNED_URL',
  DOCUMENT_DOWNLOAD_API_FAIL = 'DOCUMENT_DOWNLOAD_API_FAIL',
  DOCUMENT_DOWNLOAD_API_SUCCESS = 'DOCUMENT_DOWNLOAD_API_SUCCESS',
  DOCUMENT_DOWNLOAD_RESET = 'DOCUMENT_DOWNLOAD_RESET',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  DELETE_DOCUMENT_API_FAIL = 'DELETE_DOCUMENT_API_FAIL',
  DELETE_DOCUMENT_API_SUCCESS = 'DELETE_DOCUMENT_API_SUCCESS',
  DOCUMENT_FILTERS = 'DOCUMENT_FILTERS',
  DOCUMENT_RESET_FILTERS = 'DOCUMENT_RESET_FILTERS',
}

export type DocumentFilter = {
  type: string;
  filters: string[];
};

export type SignedUrlPayload = {
  filename: string;
  entityId: string;
};

export type DocumentDownloadGetSignedURLAction = {
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_GET_SIGNED_URL;
  payload: SignedUrlPayload;
};

export type DocumentDownloadApiSuccessAction = {
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_API_SUCCESS;
  payload: {
    signedUrl: string;
  };
};

export type DocumentDownloadApiFailAction = {
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_API_FAIL;
  payload: GlobalApiFailError;
};

export type DocumentDownloadResetAction = {
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_RESET;
};

export type DeleteFilePayload = {
  filename: string;
  entityId: string;
};

export type DeleteFromFormPayload = {
  preventDetailRefresh: boolean;
};

export type DocumentDeleteAction = {
  type: DocumentPanelActionTypes.DELETE_DOCUMENT;
  payload: DeleteFilePayload;
  meta?: DeleteFromFormPayload;
};

export type DocumentDeleteApiFailAction = {
  type: DocumentPanelActionTypes.DELETE_DOCUMENT_API_FAIL;
  payload: GlobalApiFailError;
};

export type DocumentDeleteSuccessAction = {
  type: DocumentPanelActionTypes.DELETE_DOCUMENT_API_SUCCESS;
  payload?: DeleteFromFormPayload;
};

export type DocumentFiltersAction = {
  type: DocumentPanelActionTypes.DOCUMENT_FILTERS;
  payload: DocumentFilter[];
};
export type DocumentResetFiltersAction = {
  type: DocumentPanelActionTypes.DOCUMENT_RESET_FILTERS;
};

export type DocumentPanelActions =
  | DocumentDownloadGetSignedURLAction
  | DocumentDownloadResetAction
  | DocumentDownloadApiFailAction
  | DocumentDownloadApiSuccessAction
  | DocumentDeleteAction
  | DocumentDeleteApiFailAction
  | DocumentFiltersAction
  | DocumentResetFiltersAction
  | DocumentDeleteSuccessAction;
