import { Epic } from 'redux-observable';
import { filter, tap, delay, map } from 'rxjs/operators';

import { fileUploadSuccessMessageShown } from 'store/actions/documentUploaderActions';
import { DocumentUploaderActionTypes } from 'connected/DocumentUploader/types';

const documentUploaderSuccessEpic: Epic = (action$) => {
  return action$.pipe(
    filter(
      (action) =>
        action.type ===
          DocumentUploaderActionTypes.DOCUMENT_UPLOADER_API_SUCCESS &&
        !!action.callback,
    ),
    delay(2000),
    tap((action) => {
      action.callback();
    }),
    map(() => fileUploadSuccessMessageShown()),
  );
};

export default documentUploaderSuccessEpic;
