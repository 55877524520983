import { SortTypes } from 'components/Table/types';

export const PROFILE_PAGE_COPY = {
  heading: 'My Profile',
  navLinks: {
    queries: 'Queries',
  },
  queryPanel: {
    title: 'My Saved Queries',
    noResults:
      'You haven’t saved any queries yet. Start by clicking “Search” in the main navigation.',
    headers: {
      name: 'Query Name',
      created: 'Created',
      updated: 'Updated',
      schedule: 'Schedule',
      scheduleStatus: 'Schedule Status',
      view: '',
    },
  },
  watchTables: {
    company: {
      title: 'My Watched Companies',
      noResults: 'You are not currently watching any companies',
      tableId: 'watchCompanyTable',
      headers: {
        company: 'Company',
        view: '',
      },
    },
    building: {
      title: 'My Watched Buildings',
      noResults: 'You are not currently watching any buildings',
      tableId: 'watchBuildingTable',
      headers: {
        building: 'Building',
        view: '',
      },
    },
  },
};

export const PANELS = {
  QUERIES: 'listQueries',
};

export const profileSectionLinks = [
  {
    label: PROFILE_PAGE_COPY.navLinks.queries,
    anchor: `#${PANELS.QUERIES}`,
  },
];

export const QUERY_TABLE_HEADERS = [
  {
    title: PROFILE_PAGE_COPY.queryPanel.headers.name,
    isSortable: {
      key: 'name',
      type: SortTypes.string,
    },
    isWide: true,
  },
  {
    title: PROFILE_PAGE_COPY.queryPanel.headers.created,
    isSortable: {
      key: 'created',
      type: SortTypes.date,
    },
  },
  {
    title: PROFILE_PAGE_COPY.queryPanel.headers.updated,
    isSortable: {
      key: 'updated',
      type: SortTypes.date,
    },
  },
  {
    title: PROFILE_PAGE_COPY.queryPanel.headers.scheduleStatus,
    isSortable: {
      key: 'scheduleStatus',
      type: SortTypes.string,
    },
  },
  {
    title: PROFILE_PAGE_COPY.queryPanel.headers.schedule,
    isSortable: {
      key: 'schedule',
      type: SortTypes.string,
    },
  },
  {
    title: PROFILE_PAGE_COPY.queryPanel.headers.view,
    isHeadingHidden: true,
  },
];

export const WATCH_BUILDING_TABLE_HEADERS = [
  {
    title: PROFILE_PAGE_COPY.watchTables.building.headers.building,
    isSortable: {
      key: 'building',
      type: SortTypes.string,
    },
  },
  {
    title: PROFILE_PAGE_COPY.watchTables.building.headers.view,
    isHeadingHidden: true,
  },
];

export const WATCH_COMPANY_TABLE_HEADERS = [
  {
    title: PROFILE_PAGE_COPY.watchTables.company.headers.company,
    isSortable: {
      key: 'company',
      type: SortTypes.string,
    },
  },
  {
    title: PROFILE_PAGE_COPY.watchTables.company.headers.view,
    isHeadingHidden: true,
  },
];
