import React from 'react';
import styled from 'styled-components/macro';
import { FormSummaryItemProps } from './types';

type FormSummaryProps = {
  summaryFields?: FormSummaryItemProps[];
};

const FormSummaryWrapper = styled.div`
  padding: 0;
  margin: ${(props) => props.theme.spacing.small} 0;
  font-family: ${(props) => props.theme.fonts.secondary};
`;

const FormSummaryContent = styled.div`
  display: inline-flex;
  border-radius: 4px;
  background: ${(props) => props.theme.colorPalette.secondary.backgroundGrey};
`;

const FormSummaryItem = styled.span`
  padding: ${(props) => props.theme.spacing.small};
  color: ${(props) => props.theme.colorPalette.text.dark};
`;

const FormSummaryTitle = styled.span`
  font-size: 9px;
  text-transform: uppercase;
  display: block;
  min-width: 60px;
  padding: 0 0 ${(props) => props.theme.spacing.xsmall};
`;

const FormSummaryBody = styled.span`
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  display: block;
  font-weight: bold;
  text-transform: capitalize;
`;

const FormSummary = ({ summaryFields }: FormSummaryProps) => {
  if (!summaryFields) return <></>;
  return (
    <FormSummaryWrapper>
      <FormSummaryContent>
        {summaryFields.map((field) => (
          <FormSummaryItem key={field.title}>
            <FormSummaryTitle>{field.title}</FormSummaryTitle>
            <FormSummaryBody>{field.body}</FormSummaryBody>
          </FormSummaryItem>
        ))}
      </FormSummaryContent>
    </FormSummaryWrapper>
  );
};

export default FormSummary;
