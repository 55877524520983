import React from 'react';
import { LinearLoader } from 'components/LinearLoader/LinearLoader';
import { CustomToastProps } from './types';
import {
  LoaderContainer,
  SuccessTick,
  ToastContainer,
  ToastContents,
  ToastMessage,
} from './CustomToastStyled';

const CustomToast: React.FC<CustomToastProps> = ({
  message,
  isSuccess = false,
  isUnknown = false,
}) => (
  <ToastContainer>
    <ToastContents>
      <ToastMessage>{message}</ToastMessage>
      {isSuccess && <SuccessTick />}
    </ToastContents>
    {!isSuccess && !isUnknown && (
      <LoaderContainer>
        <LinearLoader mode="dark" />
      </LoaderContainer>
    )}
  </ToastContainer>
);

export default CustomToast;
