import { GlobalApiFailError } from 'store/actions/types';

import {
  DetailsActionTypes,
  UpdateDetailsAction,
  UpdateDetailsApiFailAction,
  UpdateDetailsApiSuccessAction,
} from 'pages/Details/types';

type UpdateMetaPage = 'DETAILS' | 'RESULTS';

export const updateDetailsSave = (
  payload: any,
  meta: UpdateMetaPage,
): UpdateDetailsAction => ({
  type: DetailsActionTypes.UPDATE_DETAILS,
  payload,
  meta,
});

export const updateDetailsApiSuccess = (): UpdateDetailsApiSuccessAction => ({
  type: DetailsActionTypes.UPDATE_DETAILS_API_SUCCESS,
});

export const updateDetailsApiFail = (
  payload: GlobalApiFailError,
): UpdateDetailsApiFailAction => ({
  type: DetailsActionTypes.UPDATE_DETAILS_API_FAIL,
  payload,
});
