import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import {
  EditRecordActionTypes,
  addBreakRentEventSuccess,
  addBreakRentEventFail,
} from 'store/actions/editRecordActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const addBreakRentEventEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(EditRecordActionTypes.ADD_BREAK_REVIEW_EVENT),
    switchMap((action) => {
      const url = endpoints.addBreakRentEvent(action.payload.eventType);

      return dependencies
        .authRequest(state$, {
          url,
          method: 'PUT',
          body: action.payload.payload,
        })()
        .pipe(
          map((results) => {
            if (results.status > 204) {
              throw new Error(`error loading ${url}`);
            }

            return addBreakRentEventSuccess();
          }),
          catchError((error) => of(addBreakRentEventFail(error))),
        );
    }),
  );
};

export default addBreakRentEventEpic;
