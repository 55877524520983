import { Reducer } from 'redux';
import {
  SearchQueryActions,
  FILTER_ACTION_TYPES,
} from 'store/actions/searchQuery';
import { DrawTools } from 'components/GoogleMap';
import { MapPolygon } from 'components/GoogleMap/types';
import { formatPlaces } from 'pages/QueryBuilder/services/googleMap';
import {
  DefaultPlaceType,
  MapActions,
  MapActionTypes,
  PlacesTypes,
  SelectedPlaceType,
  MapShapes,
  BoundariesType,
} from 'pages/QueryBuilder/types';

export interface GoogleMapState {
  isFetching: boolean;
  isSaving: boolean;
  hasError?: boolean;
  errorMessage?: string;
  queryPlacename?: string;
  places?: PlacesTypes[];
  boundaries?: BoundariesType[];
  selectedPlace?: SelectedPlaceType;
  selectedBoundary?: BoundariesType;
  defaultSettings: DefaultPlaceType;
  activeDrawTool: DrawTools | null;
  activeShapes: MapShapes;
}

export const initialState: GoogleMapState = {
  isFetching: false,
  isSaving: false,
  defaultSettings: {
    name: 'United Kingdom',
    zoom: 6,
    latLng: { lat: 55.378051, lng: -3.435973 },
  },
  activeDrawTool: null,
  activeShapes: {},
};

type MapReducerActions = MapActions | SearchQueryActions;

const mapsReducer: Reducer<GoogleMapState, MapReducerActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case MapActionTypes.MAP_FETCH_BOUNDARY: {
      return {
        ...state,
        selectedBoundary: action.payload,
        activeShapes: {},
      };
    }

    case MapActionTypes.MAP_FETCH_BOUNDARY_API_SUCCESS: {
      return {
        ...state,
        activeShapes: {
          boundaries: action.payload.paths,
        },
        activeDrawTool: null,
      };
    }

    case MapActionTypes.MAP_FETCH_SUGGESTED_PLACES: {
      return {
        ...state,
        isFetching: true,
        queryPlacename: action.payload.placeName,
      };
    }

    case MapActionTypes.MAP_SUGGESTED_PLACES_API_SUCCESS: {
      const { results, boundaries } = action.payload;

      return {
        ...state,
        isFetching: false,
        places: formatPlaces(results),
        boundaries,
      };
    }

    case MapActionTypes.MAP_SUGGESTED_PLACES_API_FAIL: {
      return {
        ...initialState,
        isFetching: false,
        hasError: true,
        errorMessage: action.payload.error,
      };
    }

    case MapActionTypes.MAP_UPDATE_SELECTED_PLACE: {
      return {
        ...state,
        selectedPlace: {
          ...state.selectedPlace,
          placeId: action.payload.place,
        },
      };
    }

    case MapActionTypes.MAP_GET_GEOCODE_INFO_API_SUCCESS: {
      const selectedPlace = {
        ...state.selectedPlace,
        latLng: action.payload.latLng,
        boundaries: action.payload.bounds,
        userFriendlyName: action.payload.placeName,
      } as SelectedPlaceType;

      return {
        ...state,
        selectedPlace,
      };
    }

    case MapActionTypes.MAP_RESET: {
      return {
        ...initialState,
        selectedPlace: state.selectedPlace,
        selectedBoundary: state.selectedBoundary,
        activeShapes: state.activeShapes,
      };
    }

    case MapActionTypes.MAP_INIT_SET: {
      return {
        ...initialState,
        activeShapes: action.payload.activeShapes,
        selectedPlace: undefined,
        selectedBoundary: undefined,
      };
    }

    case FILTER_ACTION_TYPES.SET_VERTICAL: {
      return {
        ...initialState,
      };
    }

    case MapActionTypes.SELECT_DRAW_TOOL: {
      return {
        ...state,
        activeDrawTool: action.payload,
        activeShapes: {},
        selectedBoundary: undefined,
      };
    }

    case MapActionTypes.MAP_ADD_SHAPE: {
      return {
        ...state,
        activeShapes: action.payload,
        activeDrawTool: null,
      };
    }

    case MapActionTypes.MAP_CIRCLE_MOVE_END: {
      return {
        ...state,
        activeShapes: {
          circle: action.payload,
        },
      };
    }

    case MapActionTypes.MAP_POLYGON_EDIT: {
      const path = action.payload.path.map((coord) => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }));
      const lastPoint = path[path.length - 1];

      if (path[0].lat !== lastPoint.lat && path[0].lng !== lastPoint.lng) {
        path.push({
          lat: path[0].lat,
          lng: path[0].lng,
        });
      }

      const polygon = ({
        ...action.payload,
        path,
      } as unknown) as MapPolygon;

      return {
        ...state,
        activeShapes: {
          polygon,
        },
      };
    }

    default:
      return state;
  }
};

export default mapsReducer;
