import { GlobalApiFailError } from 'store/actions/types';

export type CompanyGetDeletionDataFormPayload = {
  companyId: string;
};

export enum CompanyGetDeletionDataActionTypes {
  COMPANY_GET_DELETION_DATA = 'COMPANY_GET_DELETION_DATA',
  COMPANY_GET_DELETION_DATA_API_SUCCESS = 'COMPANY_GET_DELETION_DATA_API_SUCCESS',
  COMPANY_GET_DELETION_DATA_API_FAIL = 'COMPANY_GET_DELETION_DATA_API_FAIL',
  COMPANY_GET_DELETION_DATA_RESET = 'COMPANY_GET_DELETION_DATA_RESET',
}

export type CompanyGetDeletionDataAction = {
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA;
  payload: CompanyGetDeletionDataFormPayload;
};

export type CompanyGetDeletionDataApiSuccessAction = {
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_API_SUCCESS;
  payload: CompanyGetDeletionDataResult;
};

export type CompanyGetDeletionDataApiFailAction = {
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_API_FAIL;
  payload: GlobalApiFailError;
};

export type CompanyPGetDeletionDataResetAction = {
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_RESET;
};

export type CompanyRelatedEntity = {
  entityId: string;
  entityType: string;
  relationRole: string;
};

export type CompanyGetDeletionDataResult = {
  relatedLandlordOrTenantInLeaseRecords?: CompanyRelatedEntity[];
  relatedLandlordInToLetRecords?: CompanyRelatedEntity[];
  relatedBuyerSellerInSalePortfolioSaleRecords?: CompanyRelatedEntity[];
  relatedRelatedPartiesRecords?: CompanyRelatedEntity[];
};

export type CompanyGetDeletionDataActions =
  | CompanyGetDeletionDataAction
  | CompanyGetDeletionDataApiSuccessAction
  | CompanyGetDeletionDataApiFailAction
  | CompanyPGetDeletionDataResetAction;

export const companyGetDeletionData = (
  payload: CompanyGetDeletionDataFormPayload,
): CompanyGetDeletionDataAction => ({
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA,
  payload,
});

export const companyGetDeletionDataApiSuccess = (
  payload: CompanyGetDeletionDataResult,
): CompanyGetDeletionDataApiSuccessAction => ({
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_API_SUCCESS,
  payload,
});

export const companyGetDeletionDataApiFail = (
  payload: GlobalApiFailError,
): CompanyGetDeletionDataApiFailAction => ({
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_API_FAIL,
  payload,
});

export const companyGetDeletionDataReset = (): CompanyPGetDeletionDataResetAction => ({
  type: CompanyGetDeletionDataActionTypes.COMPANY_GET_DELETION_DATA_RESET,
});
