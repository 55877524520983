import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';

import {
  quickSearchQuery,
  quickSearchReset,
  quickSearchSetVertical,
  quickSearchToggleVertical,
} from 'store/actions/quickSearchActions';

import { ToggleButton } from 'components/CallToActions/ToggleButton/ToggleButton';
import SearchTextInput from 'components/Forms/Inputs/SearchInput/SearchTextInput';
import QuickSearchResults from './SearchResults/SearchResults';

import {
  FieldsetWrapper,
  QuickSearchTitle,
  QuickSearchWrapper,
  TextInputWrapper,
  BuildingIconStyled,
  CompanyIconStyled,
  SearchIconStyled,
  customInputStyles,
  SearchTypeIndicator,
} from './QuickSearchStyled';

import searchOptions, {
  QUICK_SEARCH_COPY,
  QUICK_SEARCH_OPTIONS,
} from './constants';

import { QuickSearchProps, QuickSearchFormProps } from './types';
import CloseButtonWithFade from './CloseButtonWithFade/CloseButtonWithFade';

const SearchWrapper: React.FC<QuickSearchFormProps> = ({
  onSubmit,
  formEmbed = false,
  children,
}) => {
  return formEmbed ? (
    <div>{children}</div>
  ) : (
    <form name="quick-search" onSubmit={onSubmit}>
      {children}
    </form>
  );
};

const QuickSearch: React.FC<QuickSearchProps> = ({
  onInputFocus,
  onCloseButtonClick,
  onResultItemClick,
  onSearchTermSet,
  searchIsActive,
  showSummary = true,
  defaultSearchType = searchOptions.buildings,
  isSingleSearchType = false,
  hideCloseButton = false,
  mode = 'light',
  disableNonCanonicalResults = false,
  formEmbed = false,
  resetDetails = true,
}) => {
  const dispatch = useDispatch();
  const [
    resourceId,
    searchTerm,
    vertical,
  ] = useSelector(({ details, quickSearch }: RootState) => [
    details.resourceID,
    quickSearch.searchTerm,
    quickSearch.vertical,
  ]);
  const [
    currentPageNumber,
    totalPages,
  ] = useSelector(({ quickSearch }: RootState) => [
    quickSearch.meta.page,
    quickSearch.meta.pages,
  ]);

  const [searchType, setSearchType] = useState(
    vertical === searchOptions.companies.name
      ? searchOptions.companies
      : defaultSearchType,
  );

  const resetPanelOnMount = () => {
    if (searchTerm.length > 0) if (onSearchTermSet) onSearchTermSet();

    if (vertical !== searchType.name) {
      if (vertical === QUICK_SEARCH_OPTIONS.buildings.name)
        dispatch(quickSearchSetVertical(QUICK_SEARCH_OPTIONS.buildings.name));
      else
        dispatch(quickSearchSetVertical(QUICK_SEARCH_OPTIONS.companies.name));
    }
  };

  useEffect(resetPanelOnMount, []);

  const closePanel = () => {
    dispatch(quickSearchReset(!resetDetails));

    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  const onSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputedTerm = event.target.value;

    const payload = {
      keyword: [inputedTerm, searchType.name],
      skipResetDetails: !resetDetails,
      pageNumber: 0,
    };

    dispatch(quickSearchQuery(payload));
  };

  const onSearchInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape' && searchIsActive) {
      dispatch(quickSearchReset(!resetDetails));
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) =>
    event.preventDefault();

  const toggleSearchType = () => {
    const newSearchType =
      searchType.name === QUICK_SEARCH_OPTIONS.buildings.name
        ? searchOptions.companies
        : searchOptions.buildings;

    setSearchType(newSearchType);
    dispatch(quickSearchToggleVertical(newSearchType.name));
  };

  const handleShowMoreResultsClick = () => {
    const payload = {
      keyword: [searchTerm, searchType.name],
      skipResetDetails: !resetDetails,
      pageNumber: currentPageNumber + 1,
    };

    dispatch(quickSearchQuery(payload));
  };

  const icon =
    searchType.name === QUICK_SEARCH_OPTIONS.buildings.name ? (
      <BuildingIconStyled aria-hidden="true" />
    ) : (
      <CompanyIconStyled aria-hidden="true" />
    );

  const canShowMoreResults =
    searchType === QUICK_SEARCH_OPTIONS.companies &&
    totalPages > currentPageNumber + 1;

  return (
    <QuickSearchWrapper>
      <div>
        {!formEmbed && (
          <QuickSearchTitle searchIsActive={searchIsActive}>
            {QUICK_SEARCH_COPY.title}
          </QuickSearchTitle>
        )}
        <SearchWrapper onSubmit={handleSubmit} formEmbed={formEmbed}>
          <FieldsetWrapper>
            {isSingleSearchType ? (
              <SearchTypeIndicator>{icon}</SearchTypeIndicator>
            ) : (
              <ToggleButton onClick={toggleSearchType}>
                {icon}
                {searchType.label}
              </ToggleButton>
            )}
            <TextInputWrapper>
              <SearchTextInput
                name="quick-search"
                label={searchType.formLabel}
                onFocus={onInputFocus}
                labelIsHidden
                placeholder={searchType.formPlaceholder}
                customStyles={customInputStyles}
                onChange={onSearchInputChange}
                onKeyUp={onSearchInputKeyUp}
                value={searchTerm}
              />
              <SearchIconStyled />
            </TextInputWrapper>
          </FieldsetWrapper>
        </SearchWrapper>
        {!hideCloseButton && searchIsActive && (
          <CloseButtonWithFade onClick={closePanel} />
        )}
        {(!resourceId || !resetDetails) && searchTerm.length > 1 && (
          <QuickSearchResults
            showSummary={showSummary}
            onResultItemClick={onResultItemClick}
            mode={mode}
            disableNonCanonicalResults={disableNonCanonicalResults}
            onShowMoreResultsClick={handleShowMoreResultsClick}
            canShowMoreResults={canShowMoreResults}
          />
        )}
      </div>
    </QuickSearchWrapper>
  );
};

export default QuickSearch;
