import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import { differenceInYears, parseISO, isValid } from 'date-fns';
import { FORM_BUILDER_COPY, STATE_FIELDS } from '../constants';
import {
  FIELD_COMPONENTS,
  ValidationProps,
  FieldProps,
  GroupProps,
  EquityProp,
  ValidationEntityKey,
  ErrorProps,
} from '../types';

export const checkEquity = (state: any) => {
  return (
    (Array.isArray(state) &&
      state.reduce((accum: number, buyer: { buyerEquityStake: EquityProp }) => {
        if (buyer.buyerEquityStake && buyer.buyerEquityStake.value) {
          return accum + buyer.buyerEquityStake.value;
        }

        return accum;
      }, 0)) ||
    (Array.isArray(state) &&
      state.reduce(
        (
          accum: number,
          holdingEntity: { holdingEntityEquityStake: EquityProp },
        ) => {
          if (
            holdingEntity.holdingEntityEquityStake &&
            holdingEntity.holdingEntityEquityStake.value
          ) {
            return accum + holdingEntity.holdingEntityEquityStake.value;
          }

          return accum;
        },
        0,
      ))
  );
};

export const getNewSaleErrors = (viewState: any, groupId: string) => {
  const equity = checkEquity(viewState[groupId]?.value);
  const errors = [];

  if (equity && equity > 1) {
    errors.push({
      field: STATE_FIELDS.BUYER.EQUITY,
      message: FORM_BUILDER_COPY.buyerSeller.equity,
      display: true,
    });
  }

  if (
    viewState[groupId] &&
    viewState[groupId]['longLeaseholdInformation|endDate'] &&
    viewState[groupId]['longLeaseholdInformation|endDate'].value &&
    viewState[groupId]['longLeaseholdInformation|startDate'] &&
    viewState[groupId]['longLeaseholdInformation|startDate'].value
  ) {
    const startDate = parseISO(
      viewState[groupId]['longLeaseholdInformation|startDate'].value,
    );
    const endDate = parseISO(
      viewState[groupId]['longLeaseholdInformation|endDate'].value,
    );

    if (differenceInYears(endDate, startDate) > 999 || !isValid(endDate)) {
      errors.push({
        field: 'longLeaseholdInformation|endDate',
        message: FORM_BUILDER_COPY.errors.leaseEndDate,
        display: true,
      });
    }
  }
  return errors;
};

export const configureErrorMessage = (
  key: string,
  rule: ValidationProps,
  defaultMessage: string,
) => ({
  field: key,
  message: rule.message || defaultMessage,
});

export const testAgainstRegularExpression = (
  sample: string | number | Date | boolean | undefined,
  match: any,
  fieldType: FIELD_COMPONENTS,
) => {
  if (
    typeof sample === 'undefined' ||
    sample instanceof Date ||
    typeof sample === 'boolean'
  ) {
    return false;
  }

  if (typeof sample === 'number') {
    const string = sample.toString();

    switch (fieldType) {
      case FIELD_COMPONENTS.PERCENTAGE: {
        const testString = (sample * 100).toFixed(2);
        return testString.match(/^(\d|[1-9]\d|100|\d)(\.\d{1,4})?%?$/);
      }
      case FIELD_COMPONENTS.SQUARE_FT_INPUT: {
        return string.match(/^[1-9]\d*$/);
      }
      default: {
        return string.match(new RegExp(match));
      }
    }
  }
  if (Array.isArray(sample)) return true;

  return sample.length > 0 && sample.match(match);
};

export const isValueMissingFromViewState = (
  validationEntity: any,
  // validationEntity: FieldProps | GroupProps,
  key: keyof FieldProps | keyof GroupProps,
  // key: keyof typeof validationEntity,
  state: any,
) => {
  // If a minLength has been declared on an array value in validation config for entity.
  if (
    state &&
    state[validationEntity[key]]?.value &&
    validationEntity.validation?.minLength
  ) {
    return (
      state[validationEntity[key]].value.length <
      validationEntity.validation.minLength
    );
  }

  // if free text field and blank space entered...
  if (
    state &&
    state[validationEntity[key]]?.value &&
    typeof state[validationEntity[key]]?.value === 'string' &&
    isEmpty(state[validationEntity[key]]?.value.trim())
  ) {
    return true;
  }

  // Test if the value exists when required and no matching regex provided
  if (validationEntity.validation) {
    if (
      validationEntity.validation.isRequired &&
      !validationEntity.validation.match
    ) {
      if (!state) return true;

      if (state) {
        return (
          !Object.prototype.hasOwnProperty.call(state, validationEntity[key]) ||
          state[validationEntity[key]]?.value?.length === 0 ||
          isNil(state[validationEntity[key]]?.value) ||
          isEmpty(state[validationEntity[key]]?.value)
        );
      }
    }

    // if field required but field doesn't exist in state or no value at all
    if (validationEntity.validation.isRequired && state) {
      if (
        !state[validationEntity[key]] ||
        isEmpty(state[validationEntity[key]].value)
      ) {
        return true;
      }
    }
  }

  // Test if the value matches a regular expression defined in entity configs.
  return (
    state &&
    (state[validationEntity[key]]?.value ||
      state[validationEntity[key]]?.value === 0) &&
    validationEntity.validation?.match &&
    !testAgainstRegularExpression(
      state[validationEntity[key]].value,
      validationEntity.validation.match,
      validationEntity.componentType,
    )
  );
};

export const mapMultilineFieldValidationErrors = (
  currentGroup: GroupProps,
  allErrors: ErrorProps[],
  viewState?: any,
) => (stateLine: any) => {
  return currentGroup.fields?.reduce(
    (fieldAccum, field) => {
      // dependentOn logic needs to fit in here...
      // Implemented specifically for demises and duplicate floors
      // requiring updated mandatory logic for partition field...
      if (
        field.validation &&
        field.validation.dependentOn &&
        field.validation.dependentRowKey &&
        viewState
      ) {
        const dependentKeyValueCurrentLine =
          stateLine[field.validation.dependentRowKey]?.value;
        const dependentRows = viewState[field.validation.dependentOn]
          ?.value as any[];
        const foundDuplicates = dependentRows?.filter((o) => {
          const dependentKeyVal =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            o[field.validation!.dependentRowKey as string]?.value;

          return (
            !isNil(dependentKeyVal) &&
            dependentKeyVal === dependentKeyValueCurrentLine
          );
        });

        if (foundDuplicates.length > 1) {
          const fieldWithUpdatedValidation = {
            ...field,
            validation: {
              ...field.validation,
              isRequired: true,
            },
          };

          if (
            isValueMissingFromViewState(
              fieldWithUpdatedValidation,
              ValidationEntityKey.fieldId,
              stateLine,
            )
          ) {
            return [
              ...fieldAccum,
              configureErrorMessage(
                field.fieldId,
                field.validation,
                FORM_BUILDER_COPY.errors.emptyFieldMessage,
              ),
            ];
          }
        }
      }

      // standard row validation follows..
      if (
        field.validation &&
        isValueMissingFromViewState(
          field,
          ValidationEntityKey.fieldId,
          stateLine,
        )
      ) {
        return [
          ...fieldAccum,
          configureErrorMessage(
            field.fieldId,
            field.validation,
            FORM_BUILDER_COPY.errors.emptyFieldMessage,
          ),
        ];
      }

      return fieldAccum;
    },
    [...allErrors] as ErrorProps[],
  );
};

export const mapMultilineChildrenValidationErrors = (
  currentGroup: any,
  allErrors: ErrorProps[],
) => (stateLine: any) => {
  return currentGroup.fields?.reduce((accum: [], item: any) => {
    const errors = item.childFields?.reduce((fieldAccum: [], field: any) => {
      return field.validation &&
        isValueMissingFromViewState(
          field,
          ValidationEntityKey.fieldId,
          stateLine,
        )
        ? allErrors.concat(
            configureErrorMessage(
              field.fieldId,
              field.validation,
              FORM_BUILDER_COPY.errors.emptyFieldMessage,
            ),
          )
        : fieldAccum;
    }, [] as ErrorProps[]);
    if (errors) return [...accum, ...errors];
    return accum;
  }, []);
};
