import { GlobalApiFailError } from 'store/actions/types';

import {
  DocumentPanelActionTypes,
  DocumentDownloadApiSuccessAction,
  DocumentDownloadGetSignedURLAction,
  DocumentDownloadApiFailAction,
  DocumentDownloadResetAction,
  DocumentDeleteAction,
  DeleteFilePayload,
  DeleteFromFormPayload,
  DocumentDeleteApiFailAction,
  DocumentDeleteSuccessAction,
  DocumentFilter,
  DocumentFiltersAction,
  DocumentResetFiltersAction,
} from 'connected/DocumentPanel/types';

export const documentDownloadGetSignedURL = (payload: {
  filename: string;
  entityId: string;
}): DocumentDownloadGetSignedURLAction => ({
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_GET_SIGNED_URL,
  payload,
});

export const documentDownloadApiSuccess = (payload: {
  signedUrl: string;
}): DocumentDownloadApiSuccessAction => ({
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_API_SUCCESS,
  payload,
});

export const documentDownloadApiFail = (
  payload: GlobalApiFailError,
): DocumentDownloadApiFailAction => ({
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_API_FAIL,
  payload,
});

export const documentDownloadReset = (): DocumentDownloadResetAction => ({
  type: DocumentPanelActionTypes.DOCUMENT_DOWNLOAD_RESET,
});

export const documentDelete = (
  payload: DeleteFilePayload,
  deleteFromForm?: boolean,
) => {
  const action: DocumentDeleteAction = {
    type: DocumentPanelActionTypes.DELETE_DOCUMENT,
    payload,
  };

  if (deleteFromForm) {
    action.meta = { preventDetailRefresh: true };
  }

  return action;
};

export const documentDeleteApiFail = (
  payload: GlobalApiFailError,
): DocumentDeleteApiFailAction => ({
  type: DocumentPanelActionTypes.DELETE_DOCUMENT_API_FAIL,
  payload,
});

export const documentDeleteApiSuccess = (
  payload?: DeleteFromFormPayload,
): DocumentDeleteSuccessAction => ({
  type: DocumentPanelActionTypes.DELETE_DOCUMENT_API_SUCCESS,
  payload,
});

export const documentActiveFilters = (
  payload: DocumentFilter[],
): DocumentFiltersAction => ({
  type: DocumentPanelActionTypes.DOCUMENT_FILTERS,
  payload,
});
export const documentResetFilters = (): DocumentResetFiltersAction => ({
  type: DocumentPanelActionTypes.DOCUMENT_RESET_FILTERS,
});
