import { FormStateValue } from 'connected/FormFlow/types';
import { format } from 'date-fns';
import { globalContent, globalVariables } from 'globalConstants';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MAX_DAYS_IN_MONTH = 29;

export const formatDate = (date: Date) => {
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const formatDateForInput = (date: string) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

type MinMaxDateProps = {
  minDate?: string;
  maxDate?: string;
};

export const getMinMaxDateProps = (
  minVal: FormStateValue | null,
  maxVal: FormStateValue | null,
) => {
  const props: MinMaxDateProps = {};

  if (minVal && minVal.value) {
    props.minDate = formatDateForInput(minVal.value);
  }

  if (maxVal && maxVal.value) {
    props.maxDate = formatDateForInput(maxVal.value);
  }

  return props;
};

export const getTimeOptions = () => {
  const options = [];
  for (let i = 0; i < globalVariables.HOURS_IN_DAY; i += 1) {
    const time = i < 10 ? `0${i}:00 - 0${i}:59` : `${i}:00 - ${i}:59`;
    options.push({
      id: `${i}`,
      label: time,
      value: `${i}`,
    });
  }
  return options;
};
export const getMonthOptions = () => {
  const options = [];
  for (let i = 1; i < MAX_DAYS_IN_MONTH; i += 1) {
    options.push({
      id: `${i}`,
      label: `${i}`,
      value: `${i}`,
    });
  }
  options.push({
    id: 'L',
    label: globalContent.endOfMonth,
    value: 'L',
  });
  return options;
};
