import { createSelector } from 'reselect';

import { RootState } from 'store/rootReducer';

export const getScheduleTeamQuery = (state: RootState) =>
  state.scheduleTeamQuery;

export const getScheduleTeamQueryResultSuccessIds = createSelector(
  getScheduleTeamQuery,
  (state) => state.successIds,
);

export const getScheduleTeamQueryResultFailedIds = createSelector(
  getScheduleTeamQuery,
  (state) => state.failedIds,
);
