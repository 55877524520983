import { ValidationProps } from 'connected/FormFlow/types';

export type DatepickerType = {
  label: string;
  name: string;
  validation?: ValidationProps;
  labelIsHidden?: boolean;
  value?: string;
  minDate?: string;
  maxDate?: string;
  onChange?: (date: string) => void;
  isYear?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  prompt?: string;
  defaultValue?: string;
};

export type LabelType = {
  labelIsHidden?: boolean;
  labelIsMinimized: boolean;
};

export type RelativeDatepickerType = {
  label: string;
  name: string;
  onChange?: (event: any) => void;
  isDisabled?: boolean;
  value?: RelativeDateType;
  isPastDateOnly?: boolean;
};

export enum RelativeDatepickerTypes {
  STARTING_AMOUNT = 'StartingAmount',
  STARTING_PERIOD = 'StartingPeriod',
  STARTING_LENGTH = 'StartingLength',
  FINISHING_AMOUNT = 'FinishingAmount',
  FINISHING_LENGTH = 'FinishingLength',
}

export enum RelativeFixedDateTypes {
  FIXED = 'fixed',
  ROLLING = 'rolling',
}

type FormPayload = {
  value: string;
  label: string;
};

export type RelativeDateType = {
  [RelativeDatepickerTypes.STARTING_AMOUNT]: string;
  [RelativeDatepickerTypes.STARTING_PERIOD]: FormPayload;
  [RelativeDatepickerTypes.STARTING_LENGTH]: FormPayload;
  [RelativeDatepickerTypes.FINISHING_AMOUNT]: string;
  [RelativeDatepickerTypes.FINISHING_LENGTH]: FormPayload;
};
