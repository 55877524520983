import { Reducer } from 'redux';

import { QuickSearchActionTypes } from 'connected/QuickSearch/types';
import {
  GlobalModalActions,
  GlobalModalActionTypes,
} from 'connected/GlobalModal/types';
import { SearchActionTypes, SearchActions } from 'store/actions/searchActions';

import { MODAL_ID } from 'connected/GlobalModal/constants';
import {
  DetailsActions,
  DetailsActionTypes,
  BuildingState,
  Floor,
} from '../../../pages/Details/types';
import {
  BuildingDeleteFromPortfolioState,
  BuildingDeleteState,
  DetailsState,
  FloorDeleteState,
  QuickSearchDetailActions,
} from './types';
import { detailsInitialState } from './initialState';

const resetDetails = (payload: any, state: any) => {
  if (payload) {
    return state;
  }
  return detailsInitialState;
};

const detailsReducer: Reducer<
  DetailsState,
  DetailsActions | QuickSearchDetailActions | SearchActions | GlobalModalActions
> = (state = detailsInitialState, action) => {
  switch (action.type) {
    case DetailsActionTypes.DETAILS_FETCH: {
      return {
        ...state,
        isFetching: true,
        resourceID: action.payload.id,
        vertical: action.payload.vertical,
      };
    }

    case DetailsActionTypes.UPDATE_DETAILS: {
      return {
        ...state,
        isSaving: true,
        hasError: false,
        errorMessage: undefined,
        entityUpdating: action.payload.entity,
      };
    }

    case DetailsActionTypes.ENTITY_TO_CLEAR: {
      return {
        ...state,
        entityToClear: action.payload,
      };
    }

    case DetailsActionTypes.UPDATE_DETAILS_API_SUCCESS:
    case SearchActionTypes.FETCH_SEARCH_RESULTS_SILENTLY: {
      return {
        ...state,
        isSaving: false,
      };
    }

    case DetailsActionTypes.DETAILS_API_SUCCESS: {
      const {
        vertical,
        lease,
        building,
        sale,
        company,
        documents,
        toLet,
        portfolioSale,
      } = action.payload;
      const isLeaseType = lease || toLet;
      const isSaleType = sale || portfolioSale;
      return {
        ...state,
        isFetching: false,
        vertical,
        lease: isLeaseType,
        sale: isSaleType,
        building,
        company,
        documents,
      };
    }

    case DetailsActionTypes.DETAILS_API_FAIL: {
      return {
        ...detailsInitialState,
        hasError: true,
        errorMessage: action.payload.error,
        errorCode: action.payload.errorCode,
      };
    }

    case DetailsActionTypes.UPDATE_DETAILS_API_FAIL: {
      return {
        ...state,
        hasError: true,
        isSaving: false,
        errorMessage: action.payload.error,
      };
    }

    case DetailsActionTypes.UPDATE_BUILDING: {
      return {
        ...state,
        building: {
          ...state.building,
          floors: action.payload.building.floors,
          summary: action.payload.building,
          mostRecentSoldSale: action.payload.building.mostRecentSoldSale,
          landlords: action.payload.building.landlords,
        },
      };
    }

    case DetailsActionTypes.GET_BUILDING_LEASES_SALES: {
      return {
        ...state,
        buildingID: action.payload.id,
      };
    }

    case DetailsActionTypes.GET_BUILDING_LEASES_SALES_SUCCESS: {
      const buildingState = state.building as BuildingState;
      return {
        ...state,
        building: {
          ...buildingState,
          mostRecentSoldSale: action.payload.mostRecentSoldSale,
          landlords: action.payload.landlords,
          spv: action.payload.spv,
        },
      };
    }

    case DetailsActionTypes.MAP_PIN_DETAILS_FETCH: {
      return {
        ...state,
        mapPinCard: {
          isFetching: true,
          isError: false,
          data: {},
        },
      };
    }

    case DetailsActionTypes.MAP_PIN_DETAILS_API_SUCCESS: {
      return {
        ...state,
        mapPinCard: {
          isFetching: false,
          isError: false,
          data: {
            ...action.payload,
          },
        },
      };
    }

    case DetailsActionTypes.MAP_PIN_DETAILS_API_FAIL: {
      return {
        ...state,
        mapPinCard: {
          isFetching: true,
          isError: true,
          data: {},
        },
      };
    }

    case DetailsActionTypes.MAP_PIN_DETAILS_RESET:
    case DetailsActionTypes.PROPERTY_INTERESTS_UPDATE_TYPE:
    case DetailsActionTypes.PROPERTY_INTERESTS_UPDATE_USE: {
      return {
        ...state,
        mapPinCard: {
          isFetching: false,
          isError: false,
          data: {},
        },
      };
    }

    case DetailsActionTypes.DETAILS_RESET: {
      return detailsInitialState;
    }

    case QuickSearchActionTypes.QUICK_SEARCH_QUERY: {
      return resetDetails(action.payload, state);
    }

    case QuickSearchActionTypes.QUICK_SEARCH_RESET: {
      return resetDetails(action.payload, state);
    }

    case DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO: {
      return {
        ...state,
        buildingToDeleteFromPortfolio: {
          ...state.buildingToDeleteFromPortfolio,
          isDeleting: true,
        },
      };
    }

    case DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_FAIL: {
      return {
        ...state,
        buildingToDeleteFromPortfolio: {
          ...state.buildingToDeleteFromPortfolio,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS: {
      return {
        ...state,
        buildingToDeleteFromPortfolio: {
          ...state.buildingToDeleteFromPortfolio,
          isDeleting: false,
        },
      };
    }

    case DetailsActionTypes.DELETE_BUILDING: {
      return {
        ...state,
        buildingToDelete: {
          ...state.buildingToDelete,
          isDeleting: true,
        },
      };
    }

    case DetailsActionTypes.DELETE_BUILDING_API_FAIL: {
      return {
        ...state,
        buildingToDelete: {
          ...state.buildingToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case DetailsActionTypes.DELETE_BUILDING_API_SUCCESS: {
      return {
        ...state,
        buildingToDelete: {
          ...state.buildingToDelete,
          isDeleting: false,
        },
      };
    }

    case DetailsActionTypes.DELETE_COMPANY: {
      return {
        ...state,
        companyToDelete: {
          ...state.companyToDelete,
          isDeleting: true,
        },
      };
    }

    case DetailsActionTypes.DELETE_COMPANY_API_FAIL: {
      return {
        ...state,
        companyToDelete: {
          ...state.companyToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case DetailsActionTypes.DELETE_COMPANY_API_SUCCESS: {
      return {
        ...state,
        companyToDelete: {
          ...state.companyToDelete,
          isDeleting: false,
        },
      };
    }

    case DetailsActionTypes.DELETE_FLOOR_ACTION: {
      return {
        ...state,
        floorToDelete: {
          ...state.floorToDelete,
          isDeleting: true,
        },
      };
    }

    case DetailsActionTypes.DELETE_FLOOR_API_FAIL: {
      return {
        ...state,
        floorToDelete: {
          ...state.floorToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case DetailsActionTypes.DELETE_FLOOR_API_SUCCESS: {
      return {
        ...state,
        floorToDelete: {
          ...state.floorToDelete,
          isDeleting: false,
        },
      };
    }

    case GlobalModalActionTypes.SHOW_MODAL: {
      if (
        action.payload.modal === MODAL_ID.BUILDING_DELETE_FROM_PORTFOLIO &&
        action.meta?.id
      ) {
        const unitSale = action.meta;
        const toDelete: BuildingDeleteFromPortfolioState = {
          entity: unitSale.entity,
          id: unitSale.id,
          address: unitSale.address,
          isDeleting: false,
          error: '',
        };
        return {
          ...state,
          buildingToDeleteFromPortfolio: toDelete,
        };
      }
      if (
        action.payload.modal === MODAL_ID.BUILDING_DELETE &&
        action.meta?.summary.buildingId
      ) {
        const building = action.meta;
        const toDelete: BuildingDeleteState = {
          entity: building.entityName,
          id: building.summary.buildingId,
          isDeleting: false,
          error: '',
        };
        return {
          ...state,
          buildingToDelete: toDelete,
        };
      }
      if (action.payload.modal === 'floorDelete' && action.meta?.floorId) {
        const floor = action.meta as Floor;
        const toDelete: FloorDeleteState = {
          entity: floor.entityName,
          id: floor.floorId,
          isDeleting: false,
          error: '',
        };
        return {
          ...state,
          floorToDelete: toDelete,
        };
      }

      return state;
    }

    case GlobalModalActionTypes.HIDE_MODAL: {
      if (action.payload === MODAL_ID.BUILDING_DELETE_FROM_PORTFOLIO) {
        return {
          ...state,
          buildingToDeleteFromPortfolio:
            detailsInitialState.buildingToDeleteFromPortfolio,
        };
      }
      if (action.payload === MODAL_ID.BUILDING_DELETE) {
        return {
          ...state,
          buildingToDelete: detailsInitialState.buildingToDelete,
        };
      }

      return state;
    }

    case DetailsActionTypes.GET_UPDATE_HISTORY: {
      return {
        ...state,
        updateHistory: {
          currentEntity: undefined,
          isFetching: true,
          entityUpdates: state.updateHistory.entityUpdates,
        },
      };
    }

    case DetailsActionTypes.GET_UPDATE_HISTORY_API_SUCCESS: {
      const entity = action.payload.history[action.payload.entity];
      const entityHasSources =
        entity?.mostTrusted || entity?.otherSources.length > 0;
      let entitySourcesObj;

      if (entity && entityHasSources) {
        entitySourcesObj = action.payload.history[action.payload.entity];
      }

      return {
        ...state,
        updateHistory: {
          isFetching: false,
          currentEntity: {
            key: action.payload.entity,
            ...entitySourcesObj,
          },
          entityUpdates: {
            ...state.updateHistory.entityUpdates,
            ...action.payload.history,
          },
        },
      };
    }

    case DetailsActionTypes.GET_UPDATE_HISTORY_API_FAIL: {
      return {
        ...state,
        updateHistory: {
          currentEntity: undefined,
          isFetching: false,
          entityUpdates: state.updateHistory.entityUpdates,
        },
      };
    }

    case DetailsActionTypes.UPDATE_PANEL_FILTERS: {
      return {
        ...state,
        panelFilters: {
          ...state.panelFilters,
          ...action.payload,
        },
      };
    }

    case DetailsActionTypes.RECORDS_TO_MERGE: {
      return {
        ...state,
        mergeRecords: action.payload,
      };
    }

    case DetailsActionTypes.COMPARE_RECORDS: {
      return {
        ...state,
        recordCompareIsFetching: true,
        verticalToCompare: action.payload.vertical,
      };
    }

    case DetailsActionTypes.COMPARE_RECORDS_SUCCESS: {
      const {
        records,
        documents,
        trustscale,
        rawEntitiesToCompare,
      } = action.payload;

      if (!records) {
        return state;
      }

      const recordsToCompare = [...state.recordsToCompare, ...records];

      return {
        ...state,
        recordCompareIsFetching: false,
        recordsToCompare,
        documents,
        trustscale,
        rawEntitiesToCompare,
      };
    }

    case DetailsActionTypes.COMPARE_RECORDS_FAIL: {
      return {
        ...detailsInitialState,
        compareHasError: true,
        compareErrorMessage: action.payload.error,
        compareErrorCode: action.payload.errorCode,
      };
    }

    case DetailsActionTypes.COMPARE_RECORDS_RESET: {
      return {
        ...state,
        recordsToCompare: [],
        mergeRecords: {
          lease: [],
          sale: [],
        },
        compareHasError: false,
        compareErrorMessage: undefined,
        compareErrorCode: undefined,
        verticalToCompare: undefined,
        rawEntitiesToCompare: undefined,
      };
    }

    default:
      return state;
  }
};

export default detailsReducer;
