/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OPTION_SETS } from 'connected/FormFlow/constants';
import { FIELD_COMPONENTS, FormFlowTypes } from 'connected/FormFlow/types';
import { CommonDetailRecordProps, Vertical } from 'globalConstants';
import { Conditional } from 'services/conditions';
import {
  formatPriceWithPence,
  formatDate,
  formatBooleanishYesNo,
  formatPercentage,
  formatPrice,
  formatPricePerArea,
  formatArea,
  formatDecimal,
  formatSector,
  formatCountry,
  getFormattedPrice,
  formatYears,
  formatTransactionQuarter,
} from 'services/format';

import {
  BREEAM_RATING_MAP,
  FIT_OUT_CATEGORY_MAP,
  GRADE2_MAP,
  GRADE_MAP,
  INVESTMENT_CATEGORY_MAP,
  PRIMARY_USE_MAP,
  RENT_TYPE_MAP,
  SALE_STATUS,
  TENURE_MAP,
  TRUE_FALSE_MAP,
  WELL_CERTIFICATION_MAP,
  WIRED_SCORE_RATING_MAP,
} from './configMaps';

export enum SaleGroups {
  PORTFOLIO = 'Portfolio Details',
  STATUS = 'Status & Tenure',
  TRANSACTIONAL = 'Transactional Info',
  FURTHER = 'Further Info',
  AGENT = 'Agent Info',
}

export interface SaleField extends CommonDetailRecordProps {
  group: SaleGroups;
  valuePath: string[];
  formatter?: (field: SaleField) => string | undefined;
  conditional?: Conditional[];
  isHidden?: boolean;
}

export const recordEditableStatuses = [
  'available',
  'under-offer',
  'outcome-unknown',
];

export const TENURE_INLINE_EDIT_OPTIONS = [
  {
    label: 'Freehold',
    value: 'freehold',
  },
  {
    label: 'Feuhold',
    value: 'feuhold',
  },
  {
    label: 'Long Leasehold',
    value: 'leasehold',
  },
  {
    label: 'Part Freehold / Part Long Leasehold',
    value: 'part-freehold-part-leasehold',
  },
];

export const saleSummaryMap = {
  status: {
    key: 'status',
    valuePath: ['status'],
    isHidden: true,
  },
  address: {
    key: 'address',
    keyLabel: 'Address',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['relatedBuildings', '0', 'buildingAddress'],
    isWide: true,
    showLink: true,
    entityIdPath: ['relatedBuildings', '0', 'buildingId'],
    verticalType: Vertical.Building,
  },
  buildingAlias: {
    key: 'buildingAlias',
    keyLabel: 'Building Aliases',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['relatedBuildings', '0', 'buildingsOtherNames'],
  },
  owner: {
    key: 'owner',
    keyLabel: 'Buyer(s)',
    isWide: true,
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    valuePath: ['buyers'],
    entityIdPath: ['buyers', '0', 'companyId'],
    verticalType: Vertical.Company,
    showLink: true,
    subKey: 'companyName',
    conditional: [
      { ref: 'status', value: ['under-offer', 'sold', 'outcome-unknown'] },
    ],
    isPrivatePath: ['buyers', '0', 'isPrivate'],
    crmPath: ['buyers', '0', 'savCrmId'],
    crmNearestPath: ['buyers', '0', 'savCrmNearestId'],
  },
  buyerSPV: {
    key: 'buyerSPV',
    keyLabel: 'Buyer(s) SPV',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    valuePath: ['buyersSPV'],
    isWide: true,
    showLink: true,
    entityIdPath: ['buyersSPV', '0', 'companyId'],
    verticalType: Vertical.Company,
    subKey: 'companyName',
    conditional: [
      { ref: 'status', value: ['under-offer', 'sold', 'outcome-unknown'] },
    ],
    isPrivatePath: ['buyersSPV', '0', 'isPrivate'],
    crmPath: ['buyersSPV', '0', 'savCrmId'],
    crmNearestPath: ['buyersSPV', '0', 'savCrmNearestId'],
  },
  sellers: {
    key: 'sellers',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    keyLabel: 'Seller(s)',
    group: SaleGroups.TRANSACTIONAL,
    valuePath: ['sellers'],
    isWide: true,
    showLink: true,
    entityIdPath: ['sellers', '0', 'companyId'],
    fieldType: FormFlowTypes.SELLER,
    subKey: 'companyName',
    isPrivatePath: ['sellers', '0', 'isPrivate'],
    crmPath: ['sellers', '0', 'savCrmId'],
    crmNearestPath: ['sellers', '0', 'savCrmNearestId'],
  },
  availableDate: {
    key: 'availableDate',
    apiKey: 'onMarketDate',
    keyLabel: 'Available Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['listingDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['available', 'outcome-unknown'] }],
    inspectValues: true,
  },
  underOfferDate: {
    key: 'underOfferDate',
    apiKey: 'underOfferDate',
    keyLabel: 'Under Offer Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['underOfferDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['under-offer', 'outcome-unknown'] }],
    inspectValues: true,
  },
  soldDate: {
    key: 'soldDate',
    apiKey: 'transactionDate',
    keyLabel: 'Sold Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['soldDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['sold', 'outcome-unknown'] }],
    inspectValues: true,
  },
  withdrawnDate: {
    key: 'withdrawnDate',
    apiKey: 'offMarketDate',
    keyLabel: 'Withdrawn Date',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['withdrawnDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['withdrawn', 'outcome-unknown'] }],
    inspectValues: true,
  },
  askingPrice: {
    key: 'askingPrice',
    apiKey: 'askingPrice',
    keyLabel: 'Asking Price',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['askingPrice'],
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    isEditable: true,
    inspectValues: true,
  },
  offerPrice: {
    key: 'offerPrice',
    apiKey: 'offeredPrice',
    keyLabel: 'Offer Price',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['offeredPrice'],
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    conditional: [{ ref: 'status', value: ['under-offer', 'outcome-unknown'] }],
    isEditable: true,
    inspectValues: true,
  },
  soldPrice: {
    key: 'soldPrice',
    apiKey: 'achievedPrice',
    keyLabel: 'Sold Price',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['achievedPrice'],
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    conditional: [{ ref: 'status', value: ['sold', 'outcome-unknown'] }],
    isEditable: true,
    inspectValues: true,
  },
  purchaserCosts: {
    key: 'purchaserCosts',
    apiKey: 'purchaserCosts',
    keyLabel: 'Purchaser Costs',
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['purchaserCosts'],
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
  },
  areaSqFt: {
    key: 'totalAreaAtSale',
    apiKey: 'totalAreaAtSale',
    keyLabel: 'Total Area',
    componentType: FIELD_COMPONENTS.SQUARE_FT_INPUT,
    valuePath: ['totalAreaAtSale'],
    formatter: (field: SaleField) =>
      field.value ? formatArea(field.value) : null,
    inspectValues: true,
  },
  capitalPrice: {
    key: 'capitalPrice',
    keyLabel: 'Capital Price per sq ft',
    componentType: FIELD_COMPONENTS.PRICE_PER_SQ_FT_INPUT,
    valuePath: ['capitalPrice'],
    formatter: (field: SaleField) =>
      field.value ? formatPricePerArea(field.value) : null,
  },
  annualRentalIncome: {
    key: 'annualRentalIncome',
    apiKey: 'rentalIncome',
    keyLabel: 'Annual Rental Income',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['annualRentalIncome'],
    inspectValues: true,
    formatter: (field: SaleField) => getFormattedPrice(field.value),
  },
  netInitialYield: {
    key: 'netInitialYield',
    apiKey: 'netInitialYield',
    keyLabel: 'Net Initial Yield',
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['netInitialYield'],
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
  },
  equivalentYield: {
    key: 'equivalentYield',
    apiKey: 'equivalentYield',
    keyLabel: 'Equivalent Yield',
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['equivalentYield'],
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
  },
  reversionaryYield: {
    key: 'reversionaryYield',
    apiKey: 'reversionaryYield',
    keyLabel: 'Reversionary Yield',
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['reversionaryYield'],
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
  },
  waultc: {
    key: 'waultc',
    keyLabel: 'WAULTC (at transaction date)',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['waultc'],
    formatter: (field: SaleField) =>
      field.value ? formatDecimal(field.value) : null,
  },
  wault: {
    key: 'wault',
    keyLabel: 'WAULT (at transaction date)',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['wault'],
    formatter: (field: SaleField) =>
      field.value ? formatDecimal(field.value) : null,
  },
  rentalTopUpAmount: {
    key: 'rentalTopUpAmount',
    apiKey: 'rentalTopUpAmount',
    keyLabel: 'Rental Top-up Amount',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['rentalTopUpAmount'],
    inspectValues: true,
    formatter: (field: SaleField) => getFormattedPrice(field.value),
  },
  tenure: {
    key: 'tenure',
    apiKey: 'tenure',
    keyLabel: 'Tenure',
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['tenure'],
    valueLabels: TENURE_MAP,
    isEditable: true,
    editModal: { ref: 'tenure', name: 'Tenure' },
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
    inspectValues: true,
  },
  isConfidential: {
    key: 'isConfidential',
    apiKey: 'dealIsPrivate',
    componentType: FIELD_COMPONENTS.SELECT,
    keyLabel: 'Confidential',
    valuePath: ['isConfidential'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    isEditable: true,
    inspectValues: true,
  },
};

export const saleMap = {
  isDisputed: {
    key: 'isDisputed',
    apiKey: 'isDisputed',
    valuePath: ['isDisputed'],
    componentType: FIELD_COMPONENTS.SELECT,
    group: SaleGroups.STATUS,
    keyLabel: 'Disputed',
    formatter: (field: SaleField) => formatBooleanishYesNo(field.value),
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    inspectValues: true,
  },
  saleStatus: {
    key: 'status',
    apiKey: 'availabilityStatus',
    keyLabel: 'Status',
    group: SaleGroups.STATUS,
    valuePath: ['status'],
    valueLabels: SALE_STATUS,
    editStatusOptions: new Map([
      ['available', ['under-offer', 'sold', 'withdrawn']],
      ['outcome-unknown', ['sold', 'withdrawn']],
      ['under-offer', ['sold', 'withdrawn']],
    ]),
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  tenure: {
    key: 'tenure',
    keyLabel: 'Tenure',
    apiKey: 'tenure',
    isEditable: true,
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['tenure'],
    valueLabels: TENURE_MAP,
    options: TENURE_INLINE_EDIT_OPTIONS,
    editModal: { ref: 'tenure', name: 'updateTenure' },
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
    isClearable: false,
    inspectValues: true,
  },
  areaSqFt: {
    key: 'totalAreaAtSale',
    apiKey: 'totalAreaAtSale',
    keyLabel: 'Total Area',
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.SQUARE_FT_INPUT,
    valuePath: ['totalAreaAtSale'],
    formatter: (field: SaleField) =>
      field.value ? formatArea(field.value) : null,
    inspectValues: true,
    isEditable: true,
  },
  availableDate: {
    key: 'availableDate',
    apiKey: 'onMarketDate',
    keyLabel: 'Available Date',
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['listingDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['available'] }],
    isEditable: true,
    inspectValues: true,
  },
  availableDateUnderOffer: {
    key: 'availableDate',
    apiKey: 'onMarketDate',
    keyLabel: 'Available Date',
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['listingDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', inverse: true, value: ['available'] }],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  underOfferDate: {
    key: 'underOfferDate',
    keyLabel: 'Under Offer Date',
    apiKey: 'underOfferDate',
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['underOfferDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['under-offer'] }],
    isEditable: true,
    inspectValues: true,
  },
  underOfferDateSold: {
    key: 'underOfferDate',
    keyLabel: 'Under Offer Date',
    apiKey: 'underOfferDate',
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['underOfferDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [
      { ref: 'status', value: ['sold', 'withdrawn', 'outcome-unknown'] },
    ],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
  },
  soldDate: {
    key: 'soldDate',
    keyLabel: 'Sold Date',
    apiKey: 'transactionDate',
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['soldDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['sold', 'outcome-unknown'] }],
    isEditable: true,
    inspectValues: true,
  },
  transactionQuarter: {
    key: 'transactionQuarter',
    apiKey: 'transactionQuarter',
    valuePath: ['soldDate'],
    group: SaleGroups.STATUS,
    keyLabel: 'Transaction Quarter',
    formatter: (field: SaleField) => formatTransactionQuarter(field.value),
  },
  withdrawnDate: {
    key: 'withdrawnDate',
    keyLabel: 'Withdrawn Date',
    apiKey: 'offMarketDate',
    group: SaleGroups.STATUS,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['withdrawnDate'],
    formatter: (field: SaleField) => formatDate(field.value),
    conditional: [{ ref: 'status', value: ['withdrawn', 'outcome-unknown'] }],
    isEditable: true,
    inspectValues: true,
  },
  isConfidential: {
    key: 'isConfidential',
    componentType: FIELD_COMPONENTS.SELECT,
    apiKey: 'dealIsPrivate',
    keyLabel: 'Confidential',
    group: SaleGroups.STATUS,
    valuePath: ['isConfidential'],
    valueLabels: TRUE_FALSE_MAP,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
    isEditable: true,
    inspectValues: true,
  },
  leaseStartDate: {
    key: 'longLeaseStartDate',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    keyLabel: 'Lease Start Date',
    apiKey: 'startDate',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'startDate'],
    forceLineBreak: true,
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => formatDate(field.value),
  },
  leaseLength: {
    key: 'longLeaseLength',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    keyLabel: 'Lease Length',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'leaseLength'],
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    inspectValues: true,
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
  },
  leaseEndDate: {
    key: 'longLeaseEndDate',
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    keyLabel: 'Lease End Date',
    apiKey: 'endDate',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'endDate'],
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    isClearable: true,
    formatter: (field: SaleField) => formatDate(field.value),
    inspectValues: true,
  },
  leaseGearing: {
    key: 'longLeaseGearing',
    componentType: FIELD_COMPONENTS.SELECT,
    keyLabel: 'Lease Gearing',
    apiKey: 'gearing',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'gearing'],
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    valueLabels: TRUE_FALSE_MAP,
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    inspectValues: true,
    formatter: (field: SaleField) => field.valueLabels!.get(field.value),
    isClearable: true,
  },
  leaseGearingProportion: {
    key: 'longLeaseProportion',
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    keyLabel: 'Lease Gearing Proportion',
    apiKey: 'gearingProportion',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'gearingProportion'],
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    validation: {
      match: '^((([0-9]|[1-9][0-9])(\\.[0-9]{0,2})?)|100)%?$',
      message: 'Please enter a valid gearing proportion',
    },
    isEditable: true,
    formatter: (field: SaleField) => formatPercentage(field.value),
    isClearable: true,
    inspectValues: true,
  },
  fixedValue: {
    key: 'longLeaseFixedValue',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    keyLabel: 'Fixed Value',
    apiKey: 'gearingFixed',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'fixedValue'],
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    validation: {
      isRequired: false,
      match: '^£?[0-9]+(,\\d{3})*\\.?[0-9]{0,2}$',
      message: 'Please enter a valid fixed value',
    },
    isEditable: true,
    formatter: (field: SaleField) => formatPrice(field.value),
    isClearable: true,
    inspectValues: true,
  },
  rentType: {
    key: 'longLeaseRentType',
    componentType: FIELD_COMPONENTS.SELECT,
    keyLabel: 'Rent Type',
    apiKey: 'gearingBasisType',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'rentType'],
    entityNamePath: ['longLease', '0', 'entityName'],
    entityIdPath: ['longLease', '0', 'entityId'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    valueLabels: RENT_TYPE_MAP,
    formatter: (field: SaleField) => field.valueLabels!.get(field.value),
    isClearable: true,
    inspectValues: true,
  },
  reviewPeriod: {
    key: 'longLeaseReviewPeriod',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    keyLabel: 'Review Period',
    apiKey: 'gearingReviewFrequency',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'review'],
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    formatter: (field: SaleField) => formatYears(field.value),
    isClearable: true,
    inspectValues: true,
  },
  minimumRent: {
    key: 'longLeaseMinRent',
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    keyLabel: 'Minimum Rent',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'minRent'],
    apiKey: 'gearingMinRent',
    entityIdPath: ['longLease', '0', 'entityId'],
    entityNamePath: ['longLease', '0', 'entityName'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    formatter: (field: SaleField) => formatPriceWithPence(field.value),
    isClearable: true,
    inspectValues: true,
  },
  otherDetails: {
    key: 'otherDetails',
    componentType: FIELD_COMPONENTS.TEXTAREA,
    entityIdPath: ['longLease', '0', 'notes', 'id'],
    entityNamePath: ['longLease', '0', 'notes', '__entityName__'],
    keyLabel: 'Other Details',
    apiKey: 'description',
    group: SaleGroups.STATUS,
    valuePath: ['longLease', '0', 'notes', 'description'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    isPrepopulated: true,
    isClearable: true,
    inspectValues: true,
  },
  freeholder: {
    key: 'freeholder',
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    optionSet: OPTION_SETS.SECTOR,
    entityIdPath: ['freeholder', '0', 'companyId'],
    keyLabel: 'Freeholder',
    apiKey: '$Freeholder',
    group: SaleGroups.STATUS,
    valuePath: ['freeholder', '0', 'companyName'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    isPrepopulated: true,
    inspectValues: true,
    editModal: { ref: 'freeholder', name: 'Freeholder' },
    formatter: (field: SaleField) => formatSector(field.value),
  },
  freeholderSector: {
    key: 'freeholderSector',
    componentType: FIELD_COMPONENTS.SELECT,
    optionSet: OPTION_SETS.SECTOR,
    entityIdPath: ['freeholder', '0', 'primaryBusinessType'],
    keyLabel: 'Freeholder Sector',
    apiKey: '$Freeholder',
    group: SaleGroups.STATUS,
    valuePath: ['freeholder', '0', 'companySector'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    isPrepopulated: true,
    inspectValues: true,
    formatter: (field: SaleField) => formatSector(field.value),
  },
  freeholderNationality: {
    key: 'freeholderNationality',
    componentType: FIELD_COMPONENTS.COUNTRY_SELECT,
    keyLabel: 'Freeholder Nationality',
    formatter: (field: SaleField) => formatCountry(field.value),
    entityIdPath: ['freeholder', '0', 'companyId'],
    apiKey: 'freeholderNationality',
    group: SaleGroups.STATUS,
    valuePath: ['freeholder', '0', 'companyNationality'],
    conditional: [
      {
        ref: 'tenure',
        value: ['part-freehold-part-leasehold', 'leasehold'],
      },
    ],
    isEditable: true,
    isPrepopulated: true,
    inspectValues: true,
  },
  sellers: {
    key: 'sellers',
    apiKey: '$Vendor',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    keyLabel: 'Seller',
    group: SaleGroups.TRANSACTIONAL,
    isEditable: true,
    editModal: { ref: 'companyName', name: 'EditSellers' },
    valuePath: ['sellers'],
    isWide: true,
    entityIdPath: ['sellers', '0', 'companyId'],
    fieldType: FormFlowTypes.SELLER,
    isPrivatePath: ['tenant', 'isPrivate'],
    crmPath: ['sellers', '0', 'savCrmId'],
    crmNearestPath: ['sellers', '0', 'savCrmNearestId'],
  },
  buyers: {
    key: 'buyers',
    apiKey: '$Purchaser',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    keyLabel: 'Buyer',
    group: SaleGroups.TRANSACTIONAL,
    isEditable: true,
    editModal: { ref: 'companyName', name: 'EditBuyers' },
    valuePath: ['buyers'],
    entityIdPath: ['buyers', '0', 'companyId'],
    fieldType: FormFlowTypes.BUYER,
    isPrivatePath: ['buyers', '0', 'isPrivate'],
    crmPath: ['buyers', '0', 'savCrmId'],
    crmNearestPath: ['buyers', '0', 'savCrmNearestId'],
    conditional: [
      { ref: 'status', value: ['under-offer', 'sold', 'outcome-unknown'] },
    ],
  },
  buyersSPV: {
    key: 'buyerHoldingEntityName',
    apiKey: '$Holding Entity',
    componentType: FIELD_COMPONENTS.COMPANY_SELECT,
    keyLabel: "Buyer's Holding Entity",
    group: SaleGroups.TRANSACTIONAL,
    isEditable: true,
    editModal: { ref: 'companyName', name: 'EditSPV' },
    valuePath: ['buyersSPV'],
    entityIdPath: ['buyersSPV', '0', 'companyId'],
    fieldType: FormFlowTypes.SPV,
    isPrivatePath: ['buyersSPV', '0', 'isPrivate'],
    crmPath: ['buyersSPV', '0', 'savCrmId'],
    crmNearestPath: ['buyersSPV', '0', 'savCrmNearestId'],
    conditional: [
      { ref: 'status', value: ['under-offer', 'sold', 'outcome-unknown'] },
    ],
  },
  askingPrice: {
    key: 'askingPrice',
    apiKey: 'askingPrice',
    group: SaleGroups.TRANSACTIONAL,
    keyLabel: 'Asking Price',
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['askingPrice'],
    isEditable: true,
    inspectValues: true,
    formatter: (field: SaleField) => getFormattedPrice(field.value),
  },
  offerPrice: {
    key: 'offerPrice',
    keyLabel: 'Offer Price',
    apiKey: 'offeredPrice',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['offeredPrice'],
    isEditable: true,
    inspectValues: true,
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    conditional: [{ ref: 'status', value: ['under-offer', 'outcome-unknown'] }],
  },
  soldPrice: {
    key: 'soldPrice',
    keyLabel: 'Price Achieved',
    apiKey: 'achievedPrice',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['achievedPrice'],
    isEditable: true,
    inspectValues: true,
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    conditional: [{ ref: 'status', value: ['sold', 'outcome-unknown'] }],
  },
  annualRentalIncome: {
    key: 'annualRentalIncome',
    keyLabel: 'Annual Rental Income',
    apiKey: 'rentalIncome',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
    valuePath: ['annualRentalIncome'],
    isEditable: true,
    inspectValues: true,
    formatter: (field: SaleField) => getFormattedPrice(field.value),
    isClearable: true,
  },
  netInitialYield: {
    key: 'netInitialYield',
    keyLabel: 'Net Initial Yield',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['netInitialYield'],
    apiKey: 'netInitialYield',
    isEditable: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    validation: {
      match: '^((([0-9]|[1-9][0-9])(\\.[0-9]{0,2})?)|100)%?$',
      message: 'Please enter a valid net initial yield',
    },
    isClearable: true,
    inspectValues: true,
  },
  purchaserCosts: {
    key: 'purchaserCosts',
    apiKey: 'purchaserCosts',
    keyLabel: 'Purchaser Costs',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['purchaserCosts'],
    isEditable: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    validation: {
      match: '^((([0-9]|[1-9][0-9])(\\.[0-9]{0,2})?)|100)%?$',
      message: 'Please enter a valid purchase costs percentage',
    },
    isClearable: true,
    inspectValues: true,
  },
  totalAreaAtSale: {
    key: 'totalAreaAtSale',
    apiKey: 'totalAreaAtSale',
    keyLabel: 'Toal Area (sq ft)',
    componentType: FIELD_COMPONENTS.SQUARE_FT_INPUT,
    formatter: (field: SaleField) =>
      field.value ? formatArea(field.value) : null,
    validation: {
      match: '^[1-9]|[1-9][0-9]+(,\\d{3})*$',
      message: 'Please enter a valid total area value',
    },
    isHidden: true,
    isClearable: true,
    inspectValues: true,
  },
  equivalentYield: {
    key: 'equivalentYield',
    apiKey: 'equivalentYield',
    keyLabel: 'Equivalent Yield',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['equivalentYield'],
    isEditable: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    validation: {
      match: '^((([0-9]|[1-9][0-9])(\\.[0-9]{0,2})?)|100)%?$',
      message: 'Please enter a valid equivalent yield',
    },
    isClearable: true,
    inspectValues: true,
  },
  reversionaryYield: {
    key: 'reversionaryYield',
    apiKey: 'reversionaryYield',
    keyLabel: 'Reversionary Yield',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.PERCENTAGE,
    valuePath: ['reversionaryYield'],
    isEditable: true,
    formatter: (field: SaleField) =>
      field.value ? formatPercentage(field.value) : null,
    validation: {
      match: '^((([0-9]|[1-9][0-9])(\\.[0-9]{0,2})?)|100)%?$',
      message: 'Please enter a valid reversionary yield',
    },
    isClearable: true,
    inspectValues: true,
  },
  waultc: {
    key: 'waultc',
    apiKey: 'weightedAverageUnexpiredLeaseTermCertain',
    keyLabel: 'WAULTC',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.NUMERIC_INPUT,
    valuePath: ['waultc'],
    isEditable: true,
    formatter: (field: SaleField) =>
      field.value ? formatDecimal(field.value) : null,
    validation: {
      match: '^([0-9]|[1-9][0-9]|\\d)(\\.\\d{1,2})?$',
      message: 'Please enter a valid WAULTC',
    },
    decimals: 2,
    isClearable: true,
    inspectValues: true,
  },
  wault: {
    key: 'wault',
    apiKey: 'weightedAverageUnexpiredLeaseTerm',
    keyLabel: 'WAULT',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.NUMERIC_INPUT,
    valuePath: ['wault'],
    isEditable: true,
    formatter: (field: SaleField) =>
      field.value ? formatDecimal(field.value) : null,
    validation: {
      match: '^([0-9]|[1-9][0-9]|\\d)(\\.\\d{1,2})?$',
      message: 'Please enter a valid WAULTC',
    },
    decimals: 2,
    isClearable: true,
  },
  rentalTopUpAmount: {
    key: 'rentalTopUpAmount',
    apiKey: 'rentalTopUpAmount',
    keyLabel: 'Rental Top Up Amount',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.PRICE_INPUT,
    valuePath: ['rentalTopUpAmount'],
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value ? formatPrice(field.value) : null,
  },
  onMarketTransaction: {
    key: 'onMarketTransaction',
    keyLabel: 'On Market Transaction',
    apiKey: 'onMarketTransaction',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['onMarketTransaction'],
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
  },
  vacantPossession: {
    key: 'vacantPossession',
    keyLabel: 'Vacant Possession',
    apiKey: 'vacantPossession',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['vacantPossession'],
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
  },
  investmentCategory: {
    key: 'investmentCategory',
    keyLabel: 'Investment Category',
    apiKey: 'investmentCategory',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['investmentCategory'],
    valueLabels: INVESTMENT_CATEGORY_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  hasBuyerAgent: {
    key: 'hasBuyerAgent',
    keyLabel: "Has Buyer's Agent",
    apiKey: 'hasBuyerAgent',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['hasBuyerAgent'],
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
  },
  hasSellerAgent: {
    key: 'hasSellerAgent',
    keyLabel: "Has Seller's Agent",
    apiKey: 'hasSellerAgent',
    group: SaleGroups.TRANSACTIONAL,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['hasSellerAgent'],
    valueLabels: TRUE_FALSE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value !== null ? formatBooleanishYesNo(field.value) : null,
  },
  propertyUrl: {
    key: 'propertyUrl',
    keyLabel: 'Property Website',
    apiKey: 'propertyUrl',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['propertyUrl'],
    isEditable: true,
    inspectValues: true,
    validation: {
      isRequired: false,
      match:
        "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
      message: 'Please enter a valid URL',
    },
    showLink: true,
    isClearable: true,
  },
  numberOfOffers: {
    key: 'numberOfOffers',
    keyLabel: 'Number of Offers',
    group: SaleGroups.FURTHER,
    apiKey: 'numberOfOffers',
    componentType: FIELD_COMPONENTS.INTEGER,
    valuePath: ['numberOfOffers'],
    isEditable: true,
    inspectValues: true,
    validation: {
      isRequired: false,
      message: 'Please enter a valid number of offers',
      match: '^([0-9]*)?$',
    },
    isClearable: true,
  },
  numberOfEnquiries: {
    key: 'numberOfEnquiries',
    keyLabel: 'Number of Enquiries',
    group: SaleGroups.FURTHER,
    apiKey: 'numberOfEnquiries',
    componentType: FIELD_COMPONENTS.INTEGER,
    valuePath: ['numberOfEnquiries'],
    isEditable: true,
    inspectValues: true,
    validation: {
      isRequired: false,
      message: 'Please enter a valid number of offers',
      match: '^([0-9]*)?$',
    },
    isClearable: true,
  },
  numberOfViewings: {
    key: 'numberOfViewings',
    keyLabel: 'Number of Viewings',
    group: SaleGroups.FURTHER,
    apiKey: 'numberOfViewings',
    componentType: FIELD_COMPONENTS.INTEGER,
    valuePath: ['numberOfViewings'],
    isEditable: true,
    inspectValues: true,
    validation: {
      isRequired: false,
      message: 'Please enter a valid number of offers',
      match: '^([0-9]*)?$',
    },
    isClearable: true,
  },
  jobCode: {
    key: 'jobCode',
    keyLabel: 'Job Code',
    apiKey: 'jobCode',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    valuePath: ['jobCode'],
    isEditable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value !== null ? `${field.value}` : null,
    validation: {
      isRequired: false,
      message: 'Job Codes consist of six numbers',
      match: '^([0-9]{6,6})?$',
    },
    isClearable: true,
  },
  instructionDate: {
    key: 'instructionDate',
    keyLabel: 'Instruction Date',
    apiKey: 'instructionDate',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['instructionDate'],
    isEditable: true,
    inspectValues: true,
    isClearable: true,
    formatter: (field: SaleField) =>
      field.value ? formatDate(field.value) : null,
  },
  practicalCompletionDate: {
    key: 'practicalCompletionDate',
    keyLabel: 'Practical Completion Date',
    apiKey: 'practicalCompletion',
    isEditable: true,
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['practicalCompletionDate'],
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value ? formatDate(field.value) : null,
  },
  readyToOccupyDate: {
    key: 'readyToOccupyDate',
    keyLabel: 'Ready to Occupy Date',
    apiKey: 'readyToOccupyDate',
    isEditable: true,
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.DATE_PICKER,
    valuePath: ['readyToOccupyDate'],
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) =>
      field.value ? formatDate(field.value) : null,
  },
  assetClass: {
    key: 'assetClass',
    keyLabel: 'Asset Class',
    apiKey: 'primaryUse',
    componentType: FIELD_COMPONENTS.SELECT,
    valueLabels: PRIMARY_USE_MAP,
    isEditable: true,
    isClearable: false,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
    isHidden: true,
  },
  grade: {
    key: 'grade',
    keyLabel: 'Grade',
    apiKey: 'grade',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['grade'],
    valueLabels: GRADE_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  grade2: {
    key: 'grade2',
    keyLabel: 'Grade 2',
    apiKey: 'grade2',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['grade2'],
    valueLabels: GRADE2_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  fitOutCategory: {
    key: 'fitOutCategory',
    keyLabel: 'Fit Out Category',
    apiKey: 'fitOutCategory',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['fitOutCategory'],
    valueLabels: FIT_OUT_CATEGORY_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  breeamRating: {
    key: 'breeamRating',
    keyLabel: 'BREEAM Rating',
    apiKey: 'breeamRating',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['breeamRating'],
    valueLabels: BREEAM_RATING_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  wiredScoreRating: {
    key: 'wiredScoreRating',
    keyLabel: 'WiredScore Rating',
    apiKey: 'wiredScoreRating',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['wiredScoreRating'],
    valueLabels: WIRED_SCORE_RATING_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
  wellCertification: {
    key: 'wellCertification',
    keyLabel: 'WELL Certification',
    apiKey: 'wellCertification',
    group: SaleGroups.FURTHER,
    componentType: FIELD_COMPONENTS.SELECT,
    valuePath: ['wellCertification'],
    valueLabels: WELL_CERTIFICATION_MAP,
    isEditable: true,
    isClearable: true,
    inspectValues: true,
    formatter: (field: SaleField) => {
      return field.valueLabels!.get(field.value);
    },
  },
};

export enum SaleAvailabilityStatusEnum {
  Available = 'available',
  UnderOffer = 'under-offer',
  Sold = 'sold',
  Withdrawn = 'withdrawn',
  OutcomeUnknown = 'outcome-unknown',
}

export const saleAuditMap = {
  availabilityStatus: {
    keyLabel: 'Availability Status',
    apiKey: 'availabilityStatus',
    formatter: (field: string) => {
      return SALE_STATUS.get(field);
    },
  },
  sellers: {
    apiKey: '$Vendor',
    keyLabel: 'Seller',
  },
  buyers: {
    apiKey: '$Purchaser',
    keyLabel: 'Buyer',
  },
  buyersSPV: {
    apiKey: '$Holding Entity',
    keyLabel: "Buyer's Holding Entity",
  },
  ownerOccupierPurchase: {
    keyLabel: 'Owner Occupier Purchase',
    apiKey: 'ownerOccupierPurchase',
    formatter: (field: string) => formatBooleanishYesNo(field),
  },
  tenure: {
    keyLabel: 'Tenure',
    apiKey: 'tenure',
    formatter: (field: string) => {
      return TENURE_MAP.get(field);
    },
  },
  availableDate: {
    apiKey: 'onMarketDate',
    keyLabel: 'Available Date',
    formatter: (field: string) => formatDate(field),
  },
  underOfferDate: {
    keyLabel: 'Under Offer Date',
    apiKey: 'underOfferDate',
    formatter: (field: string) => formatDate(field),
  },
  soldDate: {
    keyLabel: 'Sold Date',
    apiKey: 'transactionDate',
    formatter: (field: string) => formatDate(field),
  },
  withdrawnDate: {
    keyLabel: 'Withdrawn Date',
    apiKey: 'offMarketDate',
    formatter: (field: string) => formatDate(field),
  },
  isConfidential: {
    apiKey: 'dealIsPrivate',
    keyLabel: 'Confidential',
    formatter: (field: string) => formatBooleanishYesNo(field),
  },
  leaseStartDate: {
    keyLabel: 'Lease Start Date',
    apiKey: 'startDate',
    formatter: (field: string) => formatDate(field),
  },
  leaseEndDate: {
    keyLabel: 'Lease End Date',
    apiKey: 'endDate',
    formatter: (field: string) => formatDate(field),
  },
  leaseGearing: {
    keyLabel: 'Lease Gearing',
    apiKey: 'gearing',
    formatter: (field: string) => formatBooleanishYesNo(field),
  },
  leaseGearingProportion: {
    keyLabel: 'Lease Gearing Proportion',
    apiKey: 'gearingProportion',
    formatter: (field: number) => formatPercentage(field),
  },
  fixedValue: {
    keyLabel: 'Fixed Value',
    apiKey: 'gearingFixed',
    formatter: (field: number) => formatPrice(field),
  },
  rentType: {
    keyLabel: 'Rent Type',
    apiKey: 'gearingBasisType',
    formatter: (field: string) => RENT_TYPE_MAP.get(field),
  },
  reviewPeriod: {
    keyLabel: 'Review Period',
    apiKey: 'gearingReviewFrequency',
    formatter: (field: number) => formatYears(field),
  },
  minimumRent: {
    keyLabel: 'Minimum Rent',
    apiKey: 'gearingMinRent',
    formatter: (field: number) => formatPriceWithPence(field),
  },
  otherDetails: {
    keyLabel: 'Other Details',
    apiKey: 'description',
  },
  askingPrice: {
    apiKey: 'askingPrice',
    keyLabel: 'Asking Price',
    formatter: (field: number) => getFormattedPrice(field),
  },
  offerPrice: {
    keyLabel: 'Offer Price',
    apiKey: 'offeredPrice',
    formatter: (field: number) => getFormattedPrice(field),
  },
  soldPrice: {
    keyLabel: 'Price Achieved',
    apiKey: 'achievedPrice',
    formatter: (field: number) => getFormattedPrice(field),
  },
  annualRentalIncome: {
    keyLabel: 'Annual Rental Income',
    apiKey: 'rentalIncome',
    formatter: (field: number) => getFormattedPrice(field),
  },
  netInitialYield: {
    keyLabel: 'Net Initial Yield',
    apiKey: 'netInitialYield',
    formatter: (field: number) => (field ? formatPercentage(field) : null),
  },
  purchaserCosts: {
    apiKey: 'purchaserCosts',
    keyLabel: 'Purchaser Costs',
    formatter: (field: number) => (field ? formatPercentage(field) : null),
  },
  totalAreaAtSale: {
    apiKey: 'totalAreaAtSale',
    keyLabel: 'Toal Area (sq ft)',
    formatter: (field: number) => (field ? formatArea(field) : null),
  },
  equivalentYield: {
    apiKey: 'equivalentYield',
    keyLabel: 'Equivalent Yield',
    formatter: (field: number) => (field ? formatPercentage(field) : null),
  },
  reversionaryYield: {
    apiKey: 'reversionaryYield',
    keyLabel: 'Reversionary Yield',
    formatter: (field: number) => (field ? formatPercentage(field) : null),
  },
  wault: {
    apiKey: 'weightedAverageUnexpiredLeaseTerm',
    keyLabel: 'WAULT',
    formatter: (field: number) => (field ? formatDecimal(field) : null),
  },
  waultc: {
    apiKey: 'weightedAverageUnexpiredLeaseTermCertain',
    keyLabel: 'WAULTC',
    formatter: (field: number) => (field ? formatDecimal(field) : null),
  },
  rentalTopUpAmount: {
    apiKey: 'rentalTopUpAmount',
    keyLabel: 'Rental Top Up Amount',
    formatter: (field: number) => getFormattedPrice(field),
  },
  onMarketTransaction: {
    keyLabel: 'On Market Transaction',
    apiKey: 'onMarketTransaction',
    formatter: (field: string) => formatBooleanishYesNo(field),
  },
  propertyUrl: {
    keyLabel: 'Property Website',
    apiKey: 'propertyUrl',
  },
  numberOfOffers: {
    keyLabel: 'Number of Offers',
    apiKey: 'numberOfOffers',
  },
  numberOfEnquiries: {
    keyLabel: 'Number of Enquiries',
    apiKey: 'numberOfEnquiries',
  },
  numberOfViewings: {
    keyLabel: 'Number of Viewings',
    apiKey: 'numberOfViewings',
  },
  jobCode: {
    keyLabel: 'Job Code',
    apiKey: 'jobCode',
  },
  instructionDate: {
    keyLabel: 'Instruction Date',
    apiKey: 'instructionDate',
    formatter: (field: string) => (field ? formatDate(field) : null),
  },
  practicalCompletionDate: {
    keyLabel: 'Practical Completion Date',
    apiKey: 'practicalCompletion',
    formatter: (field: string) => (field ? formatDate(field) : null),
  },
  readyToOccupyDate: {
    keyLabel: 'Ready to Occupy Date',
    apiKey: 'readyToOccupyDate',
    formatter: (field: string) => (field ? formatDate(field) : null),
  },
  vacantPossession: {
    keyLabel: 'Vacant Possession',
    apiKey: 'vacantPossession',
    formatter: (field: string) => formatBooleanishYesNo(field),
  },
  investmentCategory: {
    keyLabel: 'Investment Category',
    apiKey: 'investmentCategory',
  },
  grade: {
    keyLabel: 'Grade',
    apiKey: 'grade',
  },
  grade2: {
    keyLabel: 'Grade 2',
    apiKey: 'grade2',
  },
  fitOutCategory: {
    keyLabel: 'Fit Out Category',
    apiKey: 'fitOutCategory',
  },
  breeamRating: {
    keyLabel: 'BREEAM Rating',
    apiKey: 'breeamRating',
    formatter: (field: string) => {
      return BREEAM_RATING_MAP.get(field);
    },
  },
  wiredScoreRating: {
    keyLabel: 'WiredScore Rating',
    apiKey: 'wiredScoreRating',
  },
  wellCertification: {
    keyLabel: 'WELL Certification',
    apiKey: 'wellCertification',
  },
};
