import {
  GlobalModalActions,
  GlobalModalActionTypes,
} from 'connected/GlobalModal/types';
import {
  ValuationsActions,
  ValuationsActionTypes,
  Valuation,
} from 'connected/ValuationPanel/types';

export interface ValuationDeleteState {
  id: string;
  entity: string;
  isDeleting: boolean;
  error?: string;
}

export interface ValuationsPanelState {
  isFetching: boolean;
  hasError: boolean;
  error?: string;
  valuationToDelete: ValuationDeleteState;
}

export const initialState: ValuationsPanelState = {
  isFetching: false,
  hasError: false,
  error: '',
  valuationToDelete: {
    id: '',
    entity: '',
    isDeleting: false,
    error: '',
  },
};

const valuationsReducer = (
  state = initialState,
  action: ValuationsActions | GlobalModalActions,
) => {
  switch (action.type) {
    case ValuationsActionTypes.DELETE_VALUATION: {
      return {
        ...state,
        valuationToDelete: {
          ...state.valuationToDelete,
          isDeleting: true,
        },
      };
    }

    case GlobalModalActionTypes.SHOW_MODAL: {
      if (action.payload.modal === 'valuationDelete' && action.meta?.id) {
        const valuation = action.meta as Valuation;
        const toDelete: ValuationDeleteState = {
          // eslint-disable-next-line no-underscore-dangle
          entity: valuation.__entityName__,
          id: valuation.id,
          isDeleting: false,
          error: '',
        };

        return {
          ...state,
          valuationToDelete: toDelete,
        };
      }

      return state;
    }

    case GlobalModalActionTypes.HIDE_MODAL: {
      if (action.payload === 'valuationDelete') {
        return {
          ...state,
          valuationToDelete: initialState.valuationToDelete,
        };
      }

      return state;
    }

    case ValuationsActionTypes.DELETE_VALUATION_API_FAIL: {
      return {
        ...state,
        valuationToDelete: {
          ...state.valuationToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case ValuationsActionTypes.DELETE_VALUATION_API_SUCCESS: {
      return {
        ...state,
        valuationToDelete: {
          ...state.valuationToDelete,
          isDeleting: false,
        },
      };
    }

    default:
      return state;
  }
};

export default valuationsReducer;
