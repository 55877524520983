import { format } from 'date-fns';

export const MAX_DATE = format(new Date('9999-12-31'), 'yyyy-MM-dd');

export const MIN_DATE = 1;

export const DATE_VALIDATION = '^[1-9]|([0-9]*)|$';

export const DISABLED_DEFAULT = '9999';

export const PLACEHOLDER = '####';

export const FIELDS = {
  LENGTH: {
    LABEL: 'Lease Length',
    KEY: 'longLeaseholdInformation|leaseLength',
  },
};
