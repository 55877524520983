import styled from 'styled-components/macro';

export const SidebarWrapper = styled.div`
  grid-column: 1;
  grid-row: 2 span;
  word-break: break-word;
  max-width: 240px;
  background: ${(props) => props.theme.colorPalette.secondary.backgroundGrey};

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    min-width: 210px;
  }
`;

export const SidebarCard = styled.div`
  padding: ${(props) => props.theme.spacing.small};
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    padding: ${(props) => props.theme.spacing.default};
  }

  h1 {
    font-size: ${(props) => props.theme.typography.paragraph.default};
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
      font-size: ${(props) => props.theme.typography.heading.xsmall};
    }
    margin: 0 0 ${(props) => props.theme.spacing.small};
  }
`;
