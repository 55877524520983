import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';

import { globalContent } from 'globalConstants';
import {
  capitalize,
  convertCurrency,
  convertNumberThousandSeparator,
} from 'utils/string';
import countries from 'components/Forms/Inputs/CountrySelect/countries.json';
import { OPTION_SETS } from 'connected/FormFlow/constants';
import { Option } from 'components/Forms/types';
import { SaleAvailabilityStatusEnum } from 'constants/sales';
import { isValid } from 'date-fns';
import { SOURCE_TYPE_MAP } from 'constants/configMaps';

export const formatDate = (dateString?: string) => {
  if (!dateString) return '';
  if (!isValid(new Date(dateString))) return dateString;

  // some date/time strings (from Audit log entries) come in as MM/dd/yyyy HH:MM:SS
  // which is NOT ISO format so if we first need to use date-fns/parse to test for that
  let parsedDate: Date;

  parsedDate = parse(dateString, 'MM/dd/yyyy HH:mm:ss', new Date());

  if (!isValid(parsedDate)) {
    parsedDate = parseISO(dateString);
  }

  return format(parsedDate, 'dd/MM/yyyy');
};

export const formatInputDate = (dateString: string) => {
  if (!dateString) return dateString;
  return format(parseISO(dateString), 'yyyy-MM-dd');
};

export const formatPrice = (input?: number) => {
  if (!input) {
    return '';
  }
  if (typeof input === 'string') {
    return input;
  }
  return convertCurrency(input);
};
export const formatPriceWithDecimal = (input: number) => {
  if (!input) {
    return '';
  }
  if (typeof input === 'string') {
    return input;
  }
  return convertCurrency(input, 2);
};

export const formatPriceWithPence = (input: number) => {
  if (!input) return null;
  return convertCurrency(input, 2);
};

export const formatPercentage = (input?: number) => {
  if (!input) return null;
  return `${parseFloat((input * 100).toFixed(2))}%`;
};

export const formatArea = (input: number) => {
  if (!input) return null;
  return `${convertNumberThousandSeparator(input)} ${globalContent.squareFt}`;
};

export const formatPricePerArea = (input?: number) => {
  if (!input) return null;
  return `${convertCurrency(input, 2)} ${globalContent.perSqFt}`;
};

export const formatMonths = (input: number) => {
  if (!input) return null;
  return input === 1
    ? `${input} ${globalContent.month}`
    : `${input} ${globalContent.months}`;
};

export const formatYears = (input: number) => {
  if (!input) return false;
  return input === 1
    ? `${input} ${globalContent.year}`
    : `${input} ${globalContent.years}`;
};

export const formatBooleanishYesNo = (input?: boolean | string) => {
  let value: boolean | undefined;
  if (!input) {
    return 'No';
  }
  if (typeof input === 'string') {
    try {
      value = JSON.parse(input.toLowerCase());
    } catch (e) {
      throw new Error(`Invalid JSON: ${input}`);
    }
  } else {
    value = input;
  }

  return value ? 'Yes' : 'No';
};

export const formatTrueFalseArrayYesNo = (input: boolean[] | boolean) => {
  if (!Array.isArray(input)) return formatBooleanishYesNo(input);
  const labels = input.map((value) => (value ? 'Yes' : 'No'));

  return labels.join(', ');
};

type CountryOption = {
  [key: string]: string;
};

export const formatCountry = (unformattedCountry: string) => {
  if (!unformattedCountry) return false;
  const listOfCountries = countries.reduce((accum, curr) => {
    const options = curr.options.reduce(
      (allCountries, country) => ({
        ...allCountries,
        [country.value]: country.label,
      }),
      {} as CountryOption,
    );
    return {
      ...accum,
      ...options,
    };
  }, {} as CountryOption);

  return (
    listOfCountries[unformattedCountry] ||
    capitalize(unformattedCountry.replace('-', '/'))
  );
};

// don't be tempted to use lodash/startCase - it doesn't work with number range strings
// if the word is just one character, we just return that, unchanged
export const titleCase = (str: string) =>
  str
    ?.split(' ')
    .map((word) =>
      word.length > 1
        ? word[0].toUpperCase() + word.substring(1).toLowerCase()
        : word,
    )
    .join(' ');

export const formatSector = (sector: string) => {
  const selectedSector = OPTION_SETS.SECTOR.find(
    (match: Option) => match.value === sector,
  );
  if (selectedSector) return selectedSector.label;
  return sector;
};

interface CompanyRecord {
  id: string;
  name: string;
  registrationNumber: string;
  savCrmId?: string;
  savCrmNearestId?: string;
  introhiveCompanyId?: number;
  introhiveName?: string;
  introhiveRelationshipStrengthScore?: number;
  introhiveNearestRelationshipStrengthScore?: number;
  introhiveScoreId?: number;
}

export const formatSearchResultsCompany = (
  company: CompanyRecord,
  renderTitleCase = false,
) => {
  return {
    value: renderTitleCase ? titleCase(company?.name) : company?.name,
    savCrmId: company?.savCrmId,
    savCrmNearestId: company?.savCrmNearestId,
    introhiveCompanyId: company?.introhiveCompanyId,
    introhiveName: company?.introhiveName,
    introhiveRelationshipStrengthScore:
      company?.introhiveRelationshipStrengthScore,
    introhiveNearestRelationshipStrengthScore:
      company?.introhiveNearestRelationshipStrengthScore,
    introhiveScoreId: company?.introhiveScoreId,
  };
};

export const formatSearchResultsCompanies = (
  companies?: CompanyRecord[],
  renderTitleCase = false,
) => {
  if (!companies || companies.length === 0) {
    return [];
  }
  return companies?.map((c) => formatSearchResultsCompany(c, renderTitleCase));
};

export const formatCompanyNames = (companies: CompanyRecord[]) =>
  companies.map((c) => c.name).join(', ');

export const formatArray = (array: string[]) => {
  if (!array) return null;
  return array.reduce((accum: string, item: string, i: number) => {
    if (array.length === 1 || array.length === i + 1) return `${accum}${item}`;
    return `${accum}${item}, `;
  }, '');
};

export const formatArrayMapLabel = (array: string[], labels: any) => {
  if (!array) return null;
  let formattedArray = array;
  if (!Array.isArray(array)) {
    const typedArr = array as string;
    formattedArray = typedArr.split(',');
  }

  return formattedArray.reduce((accum: string, item: string, i: number) => {
    if (labels.get(item)) {
      if (formattedArray.length === 1 || formattedArray.length === i + 1) {
        return `${accum}${labels.get(item)}`;
      }
      return `${accum}${labels.get(item)}, `;
    }
    return accum;
  }, '');
};

export const formatFromMap = (input: string, map: Map<string, string>) => {
  return map.get(input);
};

export const formatDecimal = (input?: number) => {
  if (!input && input !== 0) return null;
  return Number(input).toFixed(2);
};

export const formatNumberToString = (input: number): string => {
  if (!input || input === 0) return `0`;
  return `${input}`;
};

export const formatUpper = (input?: string) => {
  if (!input) return undefined;

  return input.toUpperCase();
};

export const formatMultiSelectField = (input: string[] | string) => {
  return Array.isArray(input) ? input?.join(', ') : input;
};

export const getPriceByStatus = (data: any, decimal = false) => {
  if (
    data.status === SaleAvailabilityStatusEnum.Available ||
    data.status === SaleAvailabilityStatusEnum.OutcomeUnknown
  ) {
    return {
      label: 'Asking Price',
      value: decimal
        ? formatPriceWithDecimal(data?.askingPrice)
        : formatPrice(data?.askingPrice),
    };
  }

  if (data.status === SaleAvailabilityStatusEnum.Sold) {
    return {
      label: 'Price Achieved',
      value: decimal
        ? formatPriceWithDecimal(data?.achievedPrice)
        : formatPrice(data?.achievedPrice),
    };
  }

  if (
    data.status === SaleAvailabilityStatusEnum.UnderOffer ||
    data.status === SaleAvailabilityStatusEnum.Withdrawn
  ) {
    return {
      label: 'Offered Price',
      value: decimal
        ? formatPriceWithDecimal(data?.offeredPrice)
        : formatPrice(data?.offeredPrice),
    };
  }

  return null;
};

export const getDateByStatus = (data: any) => {
  if (
    data.status === SaleAvailabilityStatusEnum.Available ||
    data.status === SaleAvailabilityStatusEnum.OutcomeUnknown
  ) {
    return {
      label: 'Available Date',
      value: formatDate(data?.listingDate),
    };
  }

  if (data.status === SaleAvailabilityStatusEnum.Sold) {
    return {
      label: 'Available Date',
      value: formatDate(data?.soldDate),
    };
  }

  if (data.status === SaleAvailabilityStatusEnum.UnderOffer) {
    return {
      label: 'Under Offer Date',
      value: formatDate(data?.underOfferDate),
    };
  }

  if (data.status === SaleAvailabilityStatusEnum.Withdrawn) {
    return {
      label: 'Withdrawn Date',
      value: formatDate(data?.withdrawnDate),
    };
  }

  return null;
};

export const getCompanyName = (companies: any) => {
  if (companies.length > 0) {
    return companies.reduce((accum: string, company: any, i: number) => {
      if (companies.length === 1 || companies.length === i + 1)
        return `${accum}${company.companyName}`;
      return `${accum}${company.companyName}, `;
    }, '');
  }
  return '-';
};

export const getFormattedPrice = (value: any) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string') {
    return formatPriceWithPence(parseInt(value, 10));
  }

  return formatPriceWithPence(value);
};

/**
 * Formats a part of the address with a trailing comma and space, if the supplied address part exists, otherwise returns an empty string
 * @param part
 * @returns
 */
export const formatAddressPart = (part: string | undefined): string => {
  return part ? `${part}, ` : '';
};

export const formatTransactionQuarter = (dateString: any) => {
  if (!dateString) return globalContent.emptyCell;
  const parsedDate = parseISO(dateString);
  const day = parsedDate.getDate();
  const month = parsedDate.getMonth() + 1;
  if (day >= 1 && month >= 1 && day <= 31 && month <= 3) return 'Q1';
  if (day >= 1 && month >= 4 && day <= 30 && month <= 6) return 'Q2';
  if (day >= 1 && month >= 7 && day <= 30 && month <= 9) return 'Q3';
  if (day >= 1 && month >= 10 && day <= 31 && month <= 12) return 'Q4';

  return globalContent.emptyCell;
};

export const formatSource = (source: string) => {
  if (SOURCE_TYPE_MAP.has(source)) {
    return formatFromMap(source, SOURCE_TYPE_MAP);
  }
  return source;
};
