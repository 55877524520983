import { Middleware } from 'redux';
import { History } from 'history';

import { removeItem } from 'services/localStorage';
import { unauthorizedUser } from 'store/actions/auth';
import { urls } from 'globalConstants';

const unauthorizedRedirect = (history: History): Middleware => () => (next) => (
  action,
) => {
  if (
    action.type.includes('_API_FAIL') &&
    action.payload &&
    action.payload.errorCode &&
    action.payload.errorCode === 403
  ) {
    removeItem('credentials');
    next(unauthorizedUser());
    history.push(urls.login);
  }

  return next(action);
};

export default unauthorizedRedirect;
