import { MARKER_TYPES } from 'components/GoogleMap/types';
import { DETAILS_PAGE_COPY, PANELS } from '../constants';

export const COMPANY_PAGE_COPY = {
  detailsPanel: {
    title: 'Company Details',
    edit: 'Edit',
    done: 'Done',
  },
  propertyOwnershipPanel: {
    title: 'Property Interests',
    noResults: 'This company does not have any interests in any buildings.',
    interestTitle: 'Interest Type: ',
    useTitle: 'Use: ',
    ownership: 'Ownership',
    occupation: 'Occupation',
    ownerOccupation: 'Owner Occupation',
    buildings: 'building(s) / ',
    area: 'sq ft',
    seeResults: 'Open visible pins in search results',
    seeResultsLeases: 'Open leases for visible pins',
  },
  companyStructurePanel: {
    title: 'Company Structure',
    current: ' (Current Company)',
  },
};

export const TYPE_TYPES = {
  OWNERSHIP: 'ownership',
  OCCUPATION: 'occupation',
  OWNER_OCCUPATION: 'owner-occupation',
};

export const TYPE_RADIOS = [
  { label: 'Ownership', value: TYPE_TYPES.OWNERSHIP, isDisabled: false },
  { label: 'Occupation', value: TYPE_TYPES.OCCUPATION, isDisabled: false },
  {
    label: 'Owner Occupation',
    value: TYPE_TYPES.OWNER_OCCUPATION,
    isDisabled: false,
  },
];

export const USE_TYPES = {
  ALL: 'all',
  RETAIL: 'retail',
  OFFICE: 'office',
  INDUSTRIAL: 'industrial',
};

export const PRIMARY_USE_RADIOS = [
  { label: 'All', value: USE_TYPES.ALL, isDisabled: false },
  {
    label: 'Retail',
    value: USE_TYPES.RETAIL,
    isDisabled: false,
    icon: MARKER_TYPES.RETAIL,
  },
  {
    label: `Office`,
    value: USE_TYPES.OFFICE,
    isDisabled: false,
    icon: MARKER_TYPES.OFFICE,
  },
  {
    label: 'Industrial',
    value: USE_TYPES.INDUSTRIAL,
    isDisabled: false,
    icon: MARKER_TYPES.INDUSTRIAL,
  },
];

export const companySectionLinks = [
  {
    label: DETAILS_PAGE_COPY.navLinks.summary,
    anchor: `#${PANELS.SUMMARY}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.propertyInvestments,
    anchor: `#${PANELS.PROPERTY_OWNERSHIP}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.companyDetails,
    anchor: `#${PANELS.COMPANY_DETAILS}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.companyStructure,
    anchor: `#${PANELS.COMPANY_STRUCTURE}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.debts,
    anchor: `#${PANELS.DEBTS}`,
    arrayKey: 'debts',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.documents,
    anchor: `#${PANELS.DOCUMENTS}`,
    arrayKey: 'documents',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.relatedParties,
    anchor: `#${PANELS.RELATED_PARTIES}`,
    arrayKey: 'relatedParties',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.notes,
    anchor: `#${PANELS.NOTES}`,
    arrayKey: 'notes',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.auditLog,
    anchor: `#${PANELS.AUDIT_LOG}`,
  },
];
