import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ReactComponent as Information } from 'assets/icons/info-outline.svg';
import { ReactComponent as Verified } from 'assets/icons/verified.svg';
import { ReactComponent as Calculated } from 'assets/icons/function.svg';

const linkStyles = css`
  text-decoration: underline;
  color: ${(props) => props.theme.colorPalette.text.dark};
  display: inline-block;
  padding: 0 0 ${(props) => props.theme.spacing.xsmall} 0;
  overflow-wrap: anywhere;
`;

export const CellItemWrapper = styled.span<{
  hasLink?: boolean;
}>`
  display: ${(props) => (props.hasLink ? 'flex' : 'inline-block')};
  padding-right: 25px;
  position: relative;
  overflow-wrap: anywhere;
  display: flex;

  > ul {
    li {
      display: flex;
    }
  }
`;

export const CellItemLink = styled(Link)`
  ${linkStyles}
`;

export const CellItemNestedLink = styled(Link)`
  ${linkStyles}
`;

export const CellItemExternalLink = styled.a`
  ${linkStyles}
`;

export const CellItem = styled.span`
  display: block;
  padding: 0 0 ${(props) => props.theme.spacing.xsmall} 0;
`;

export const EditableItem = styled.span`
  text-decoration: underline;
  word-break: break-word;
`;

export const PillsContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
`;

export const PillItem = styled.span`
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  padding: 4px 6px;
  margin-right: 3px;
  margin-bottom: 3px;
`;

export const ValueIcon = styled(Information)`
  fill: ${(props) => props.theme.colorPalette.notifications.success};
  margin: -3px 0 0 ${(props) => props.theme.spacing.xsmall};
  border-radius: 30px;
  height: 18px;
  width: 18px;
  position: absolute;
`;

export const CompetingValueIcon = styled(ValueIcon)`
  fill: ${(props) => props.theme.colorPalette.notifications.error};
`;

export const VerifiedIcon = styled(Verified)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const CalculatedIcon = styled(Calculated)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const TooltipContentStyled = styled.span`
  white-space: pre-wrap;
`;
