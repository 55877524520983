import { SortTypes, TableHeaderCellProps } from 'components/Table/types';
import { DETAILS_PAGE_COPY, PANELS } from '../constants';

export const BUILDING_DETAILS_PAGE_COPY = {
  investmentPanel: {
    title: 'Investments',
    headers: {
      private: 'Confidential',
      status: 'Status',
      date: 'Date',
      buyers: 'Buyers',
      sellers: 'Sellers',
      tenure: 'Tenure',
      netInitialYield: 'NIY',
      equivalentYield: 'EY',
      reversionaryYield: 'RY',
      price: 'Price',
      totalRent: 'Total Rent',
      purchaserCosts: 'Purchaser Costs',
      waultc: 'WAULTC at Transaction',
      view: 'View',
      capitalValue: 'Capital Value',
      disputed: 'Disputed',
    },
    noResults: 'No sale records were found',
    viewRecord: 'View record',
    addNewSaleButton: 'Add New Sale',
  },
  leasePanel: {
    title: 'Tenancy Schedule',
    addNewLeaseButton: 'Add New Lease',
    noResults: 'No lease information recorded for this building yet',
    headers: {
      dealIsPrivate: 'Confidential',
      landlord: 'Landlord',
      tenant: 'Tenant',
      demise: 'Demise',
      startDate: 'Start Date',
      nextBreak: 'Next Break',
      nextRentReview: 'Next Rent Review',
      endDate: 'Expiry Date',
      area: 'Area',
      rent: 'Rent',
      rentFigure: 'Annual Rent',
      view: 'View',
      status: 'Status',
      type: 'Type',
      useClauses: 'Use Clauses',
      disputed: 'Disputed',
      icons: 'Icons',
    },
  },
  toLetPanel: {
    title: 'To Let',
    addNewToLetButton: 'Add New To Let',
    noResults: 'No To Let information recorded for this building yet',
    headers: {
      landlord: 'Landlord',
      landlordAgent: "Landlord's Agent",
      priceFrom: 'Asking Rent',
      minSqFt: 'Min sq ft',
      maxSqFt: 'Max sq ft',
      serviceCharge: 'Service Charge',
      usage: 'Usage',
      leaseType: 'Lease type',
      dealIsPrivate: 'Confidential',
      readyToOccupy: 'Ready to Occupy',
      availableTo: 'Available to',
      description: 'Description',
      view: 'View',
      status: 'Status',
      type: 'Type',
      disputed: 'Disputed',
    },
  },
  summaryPanel: {
    title: 'Summary',
  },
  detailsPanel: {
    title: 'Building Details',
    edit: 'Edit',
    done: 'Done',
  },
  nonCanonicalAddress: {
    title: 'To view investment transactions, ',
    link: 'view the main address record for this building',
  },
  accommodationSchedule: {
    headers: {
      floorLevel: 'Level',
      refLevel: 'Ref:',
      floorName: 'Floor Name',
      floorUnitName: 'Floor/Unit Name',
      partitionName: 'Partition Name',
      usage: 'Usage',
      area: 'Area',
      measurementStandard: 'Measurement Standard',
      delete: 'Delete',
    },
  },
  jobHistory: {
    title: 'Job History',
    noRecords: 'No Job History to display.',
  },
  debtPanel: {
    title: 'Debt Details',
    headers: {
      building: 'Building',
      role: 'Role',
      lenders: 'Lender(s)',
      borrowers: 'Borrower(s)',
      debtAmount: 'Debt Amount',
      interest: 'Interest Rate',
      base: 'Base Rate',
      startDate: 'Facility Start Date',
      maturity: 'Maturity Date',
      notes: 'Notes',
    },
    noResults: 'No debts were found',
    addNewButton: 'Add New Debt',
    addDebt: {
      title: 'Add debt',
      btns: {
        add: 'Add debt',
        cancel: 'Cancel',
      },
      error: 'Error occurred adding debt',
    },
  },
  epcCertificatesPanel: {
    title: 'EPCs',
    noRecords: 'No EPCs to display.',
    markAsSuperseded: 'Mark as superseded',
    markAsNotSuperseded: 'Mark as not superseded',
    nonDomestic: {
      title: 'Non Domestic',
      headers: {
        address: 'Address',
        floorArea: 'Floor Area (Sq Ft)',
        assetRatingNumber: 'Efficiency Rating',
        assessmentDate: 'Assessment Date',
        expiryDate: 'Expiry Date',
        mainHeatingFuel: 'Main Heating Fuel',
        transactionType: 'Transaction Type',
        assessmentLevel: 'Assessment Level (building level)',
        buildingEmissions: 'Building Emissions (kgCO2/m2 per year)',
        isSuperseded: 'Superseded',
      },
    },
    dec: {
      title: 'DEC',
      headers: {
        address: 'Address',
        floorArea: 'Floor Area (Sq Ft)',
        operationalRating: 'Operational Rating',
        electricCo2: 'Electric (kgCO2/m2 per year)',
        heatingCo2: 'Heating (kgCO2/m2 per year)',
        renewablesCo2: 'Renewables (kgCO2/m2 per year)',
        year1ElectricCo2: 'Y1 Electric (kgCO2/m2 per year)',
        year1HeatingCo2: 'Y1 Heating (kgCO2/m2 per year)',
        year1RenewablesCo2: 'Y1 Renewables (kgCO2/m2 per year)',
        lodgementDate: 'Lodgement Date',
        mainHeatingFuel: 'Main Heating Fuel',
        annualEnergyUse: 'Annual Energy Use (thermal fuel usage) (kWh/m2)',
        typicalEnergyUse: 'Typical Energy Use (thermal fuel usage) (kWh/m2)',
        isSuperseded: 'Superseded',
      },
    },
    supersedeCertificateModal: {
      markAsSuperseded: {
        title: 'Supersede EPC Certificate',
        errorMessage: 'Error occurred superseding EPC Certificate',
        message:
          'Are you sure you want to mark this EPC Certificate as superseded?',
        ok: 'ok',
        cancel: 'cancel',
      },
      markAsNotSuperseded: {
        title: 'Mark as not superseded',
        errorMessage:
          'Error occurred marking EPC Certificate as not superseded',
        message:
          'Are you sure you want to mark this EPC Certificate as not superseded?',
        ok: 'ok',
        cancel: 'cancel',
      },
    },
  },
};

export const INVESTMENT_TABLE_HEADERS = [
  {
    title: '',
    isHeadingHidden: true,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.status,
    isSortable: {
      key: 'status',
      type: SortTypes.string,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.date,
    isSortable: {
      key: 'date',
      type: SortTypes.date,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.sellers,
    isSortable: {
      key: 'sellers',
      type: SortTypes.string,
    },
    isWide: true,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.buyers,
    isWide: true,
    isSortable: {
      key: 'buyers',
      type: SortTypes.string,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.netInitialYield,
    isSortable: {
      key: 'netInitialYield',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.equivalentYield,
    isSortable: {
      key: 'equivalentYield',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.reversionaryYield,
    isSortable: {
      key: 'reversionaryYield',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.price,
    isSortable: {
      key: 'price',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.totalRent,
    isSortable: {
      key: 'totalRent',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.purchaserCosts,
    isSortable: {
      key: 'purchaserCosts',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.waultc,
    isSortable: {
      key: 'waultc',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.capitalValue,
    isSortable: {
      key: 'capitalValue',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.investmentPanel.headers.view,
    isHeadingHidden: true,
  },
];

export const LEASE_TABLE_HEADERS: TableHeaderCellProps[] = [
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.icons,
    isHeadingHidden: true,
    isVisible: true,
    key: 'icons',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.type,
    isSortable: {
      key: 'leaseType',
      type: SortTypes.string,
    },
    isVisible: true,
    key: 'leaseType',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.status,
    isSortable: {
      key: 'status',
      type: SortTypes.string,
    },
    isVisible: true,
    key: 'status',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.landlord,
    isSortable: {
      key: 'landlord',
      type: SortTypes.string,
    },
    isWide: true,
    isVisible: true,
    key: 'landlord',
  },
  {
    title: 'Tenant',
    isSortable: {
      type: SortTypes.string,
      key: 'tenant',
    },
    isFixed: false,
    isVisible: true,
    key: 'tenant',
    childrenVisible: false,
    isParent: true,
    children: [
      {
        title: 'Tenant Equivalent Bond Rating',
        isSortable: {
          type: SortTypes.string,
          key: 'tenantEquivalentBondRating',
        },
        isFixed: false,
        isVisible: false,
        key: 'tenantEquivalentBondRating',
      },
      {
        title: 'Tenant Incans Global Score',
        isSortable: {
          type: SortTypes.numeric,
          key: 'tenantIncansGlobalScore',
        },
        isFixed: false,
        isVisible: false,
        key: 'tenantIncansGlobalScore',
      },
    ],
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.demise,
    isSortable: {
      key: 'demise.subValue',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'demise',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.startDate,
    isSortable: {
      key: 'startDate',
      type: SortTypes.date,
    },
    isVisible: true,
    key: 'startDate',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.nextBreak,
    isSortable: {
      key: 'nextBreak',
      type: SortTypes.date,
    },
    isVisible: true,
    key: 'nextBreak',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.nextRentReview,
    isSortable: {
      key: 'nextRentReview',
      type: SortTypes.string,
    },
    isVisible: true,
    key: 'nextRentReview',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.endDate,
    isSortable: {
      key: 'endDate',
      type: SortTypes.date,
    },
    isVisible: true,
    key: 'endDate',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.area,
    isSortable: {
      key: 'area',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'area',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.rent,
    isSortable: {
      key: 'rent',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'rent',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.rentFigure,
    isSortable: {
      key: 'rentFigure',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'rentFigure',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.useClauses,
    isSortable: {
      key: 'useClauses',
      type: SortTypes.string,
    },
    isVisible: true,
    key: 'useClauses',
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.leasePanel.headers.view,
    isHeadingHidden: true,
    isVisible: true,
    key: 'view',
  },
];

export const TO_LET_TABLE_HEADERS = [
  {
    title: '',
    isHeadingHidden: true,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.landlord,
    isSortable: {
      key: 'landlord',
      type: SortTypes.string,
    },
    isWide: true,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.leaseType,
    isSortable: {
      key: 'leaseType',
      type: SortTypes.string,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.usage,
    isSortable: {
      key: 'usage',
      type: SortTypes.string,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.priceFrom,
    isSortable: {
      key: 'priceFrom',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.minSqFt,
    isSortable: {
      key: 'minSqFt',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.maxSqFt,
    isSortable: {
      key: 'maxSqFt',
      type: SortTypes.numeric,
    },
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.landlordAgent,
    isSortable: {
      key: 'landlordAgents',
      type: SortTypes.string,
    },
    isWide: true,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.toLetPanel.headers.view,
    isHeadingHidden: true,
  },
];

export const EPC_CERTIFICATE_NON_DOMESTIC_TABLE_HEADERS: TableHeaderCellProps[] = [
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .address,
    isSortable: {
      key: 'address',
      type: SortTypes.string,
    },
    isWide: true,
    isVisible: true,
    key: 'address',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .floorArea,
    isSortable: {
      key: 'floorArea',
      type: SortTypes.numeric,
    },
    key: 'floorArea',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .assetRatingNumber,
    isSortable: {
      key: 'assetRatingNumber',
      type: SortTypes.numeric,
    },
    key: 'assetRatingNumber',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .assessmentDate,
    isSortable: {
      key: 'assessmentDate',
      type: SortTypes.date,
    },
    isVisible: true,
    key: 'assessmentDate',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .expiryDate,
    isSortable: {
      key: 'expiryDate',
      type: SortTypes.date,
    },
    isVisible: true,
    key: 'expiryDate',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .mainHeatingFuel,
    isSortable: {
      key: 'mainHeatingFuel',
      type: SortTypes.string,
    },
    isVisible: true,
    key: 'mainHeatingFuel',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .transactionType,
    isSortable: {
      key: 'transactionType',
      type: SortTypes.string,
    },
    isVisible: true,
    key: 'transactionType',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .assessmentLevel,
    isSortable: {
      key: 'assessmentLevel',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'assessmentLevel',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .buildingEmissions,
    isSortable: {
      key: 'buildingEmissions',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'buildingEmissions',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.nonDomestic.headers
        .isSuperseded,
    isVisible: true,
    key: 'isSuperseded',
  },
  {
    title: '',
    key: 'view',
    isHeadingHidden: true,
  },
];

export const EPC_CERTIFICATE_DEC_TABLE_HEADERS: TableHeaderCellProps[] = [
  {
    title: BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers.address,
    isSortable: {
      key: 'address',
      type: SortTypes.string,
    },
    isWide: true,
    isVisible: true,
    key: 'address',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers.floorArea,
    isSortable: {
      key: 'floorArea',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'floorArea',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers
        .operationalRating,
    isSortable: {
      key: 'operationalRatingNumber',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'operationalRatingNumber',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers.electricCo2,
    isSortable: {
      key: 'electricCo2',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'electricCo2',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers.heatingCo2,
    isSortable: {
      key: 'heatingCo2',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'heatingCo2',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers.renewablesCo2,
    isSortable: {
      key: 'renewablesCo2',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'renewablesCo2',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers
        .year1ElectricCo2,
    isSortable: {
      key: 'yr1ElectricityCo2',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'yr1ElectricityCo2',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers
        .year1HeatingCo2,
    isSortable: {
      key: 'yr1HeatingCo2',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'yr1HeatingCo2',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers
        .year1RenewablesCo2,
    isSortable: {
      key: 'yr1RenewablesCo2',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'yr1RenewablesCo2',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers.lodgementDate,
    isSortable: {
      key: 'lodgementDate',
      type: SortTypes.date,
    },
    isVisible: true,
    key: 'lodgementDate',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers
        .mainHeatingFuel,
    isSortable: {
      key: 'mainHeatingFuel',
      type: SortTypes.string,
    },
    isVisible: true,
    key: 'mainHeatingFuel',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers
        .annualEnergyUse,
    isSortable: {
      key: 'annualThermalFuelUsage',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'annualThermalFuelUsage',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers
        .typicalEnergyUse,
    isSortable: {
      key: 'typicalThermalFuelUsage',
      type: SortTypes.numeric,
    },
    isVisible: true,
    key: 'typicalThermalFuelUsage',
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.epcCertificatesPanel.dec.headers.isSuperseded,
    isVisible: true,
    key: 'isSuperseded',
  },
  {
    title: '',
    key: 'view',
    isHeadingHidden: true,
  },
];

export const FLOOR_DELETE_COPY = {
  title: 'Remove Floor?',
  deletePrompt: 'Are you sure you want to permanently delete this floor?',
  confirmButton: 'Yes, delete',
  cancelButton: 'Cancel',
  deleteFailPart1: 'Sorry, the operation failed whilst trying to delete',
  deleteFailPart2: 'Please try again later.',
  closeButton: 'Close',
};

export const buildingSectionLinks = [
  {
    label: DETAILS_PAGE_COPY.navLinks.summary,
    anchor: `#${PANELS.SUMMARY}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.toLet,
    anchor: `#${PANELS.TO_LET}`,
    arrayKey: 'toLet',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.stackingPlan,
    anchor: `#${PANELS.STACKING_PLAN}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.accommodationSchedule,
    anchor: `#${PANELS.ACCOMMODATION_SCHEDULE}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.leases,
    anchor: `#${PANELS.LEASES}`,
    arrayKey: 'leases',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.investments,
    anchor: `#${PANELS.INVESTMENTS}`,
    arrayKey: 'sales',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.debts,
    anchor: `#${PANELS.DEBTS}`,
    arrayKey: 'debts',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.valuations,
    anchor: `#${PANELS.VALUATIONS}`,
    arrayKey: 'valuations',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.buildingDetails,
    anchor: `#${PANELS.BUILDING_DETAILS}`,
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.epcCertificates,
    anchor: `#${PANELS.EPC_CERTIFICATES}`,
    arrayKey: 'epcCertificates',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.documents,
    anchor: `#${PANELS.DOCUMENTS}`,
    arrayKey: 'documents',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.relatedParties,
    anchor: `#${PANELS.RELATED_PARTIES}`,
    arrayKey: 'relatedParties',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.jobHistory,
    anchor: `#${PANELS.JOB_HISTORY}`,
    arrayKey: 'instructions',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.notes,
    anchor: `#${PANELS.NOTES}`,
    arrayKey: 'notes',
  },
  {
    label: DETAILS_PAGE_COPY.navLinks.auditLog,
    anchor: `#${PANELS.AUDIT_LOG}`,
  },
];
