import styled from 'styled-components/macro';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import withIconStyles from 'components/Icon/Icon';

export const ReleaseNotesBodyWrapper = styled.div`
  margin: ${(props) => props.theme.spacing.default};

  ul > li {
    list-style-type: disc;
    margin-left: ${(props) => props.theme.spacing.small};
  }
`;

export const InfoIcon = styled(withIconStyles(Info))`
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 24px;
`;
