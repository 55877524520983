export enum NOTIFICATION_TYPES {
  WARNING = 'WARNING',
  INFORMATION = 'INFORMATION',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export type NotificationPanelProps = {
  notificationTitle: string;
  notificationBody: string | React.ReactNode;
  notificationType: NOTIFICATION_TYPES;
};
