import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import {
  unfreezeUserSuccessAction,
  unfreezeUserFailAction,
  UsersListActions,
} from 'store/actions/usersListActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const adminUserUnFreezeEndpoint = endpoints.unfreezeUser;

const userUnfreezeEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(UsersListActions.UNFREEZE_USER),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'PUT',
          url: adminUserUnFreezeEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`PUT ${adminUserUnFreezeEndpoint} failed`);
            }
            return of(unfreezeUserSuccessAction(response.response));
          }),
          catchError((error) => {
            return of(unfreezeUserFailAction(error));
          }),
        );
    }),
  );
};

export default userUnfreezeEpic;
