import { Observable } from 'rxjs';
import { map, mergeMap, debounceTime, filter } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import sortBy from 'ramda/src/sortBy';
import prop from 'ramda/src/prop';

import savillsBoundaries from 'assets/savills-boundaries.json';
import {
  mapSuggestedPlacesApiSuccess,
  mapSuggestedPlacesApiFail,
} from 'store/actions/mapsActions';
import {
  BoundariesType,
  BoundaryType,
  MapActionTypes,
  mapErrorStatus,
} from 'pages/QueryBuilder/types';

const MAX_SAVILLS_BOUNDARY_RESULTS = 6;
const BOUNDARY_TYPE_RESULT_PRIORITY = [
  BoundaryType.Market,
  BoundaryType.SubMarket,
  BoundaryType.LocalAuthority,
  BoundaryType.PostcodeGroup,
  BoundaryType.Postcode,
];

const transformSavillsBoundary = (
  boundary: typeof savillsBoundaries[0],
): BoundariesType => ({
  id: boundary.link,
  label: boundary.label,
  type: boundary.type as BoundaryType,
});

const matchSavillsBoundariesByName = (
  searchTerm: string,
): [string, BoundariesType[]] => {
  const hasSearchTerm = new RegExp(searchTerm, 'i');
  const startsWithSearchTerm = new RegExp(`^${searchTerm}`, 'i');

  const matchingBoundaries = savillsBoundaries
    .map((boundary) => {
      const matchedAtPhraseStart = startsWithSearchTerm.test(boundary.label);

      return {
        ...boundary,
        isMatch: matchedAtPhraseStart || hasSearchTerm.test(boundary.label),
        matchedAtPhraseStart,
      };
    })
    .filter((b) => b.isMatch);

  const matchingBoundariesSortedByMatchedAtPhraseStart = sortBy(
    prop('matchedAtPhraseStart'),
    matchingBoundaries,
  ).reverse();

  const boundariesOrderedByTypePriority: typeof matchingBoundaries = [];
  BOUNDARY_TYPE_RESULT_PRIORITY.forEach((bt) => {
    matchingBoundariesSortedByMatchedAtPhraseStart
      .filter((b) => b.type === bt)
      .forEach((b) => boundariesOrderedByTypePriority.push(b));
  });

  const boundaries = boundariesOrderedByTypePriority
    .slice(0, MAX_SAVILLS_BOUNDARY_RESULTS)
    .map(transformSavillsBoundary);

  return [searchTerm, boundaries];
};

const mapEpic: Epic = (action$) =>
  action$.pipe(
    ofType(MapActionTypes.MAP_FETCH_SUGGESTED_PLACES),
    debounceTime(300),
    filter((action) => action.payload.placeName.length >= 2),
    map((action) => matchSavillsBoundariesByName(action.payload.placeName)),
    mergeMap(([placeName, boundaries]) => {
      const request = {
        input: placeName,
        types: ['geocode'],
        componentRestrictions: { country: 'gb' },
      };

      const service = new google.maps.places.AutocompleteService();

      return Observable.create((observer: any) => {
        service.getPlacePredictions(request, (results, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK ||
            (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS &&
              boundaries.length > 0)
          ) {
            observer.next(
              mapSuggestedPlacesApiSuccess({ results, boundaries }),
            );
          } else {
            const error = mapErrorStatus(status);
            if (error) observer.next(mapSuggestedPlacesApiFail(error));
          }
          observer.complete();
        });
      });
    }),
  );

export default mapEpic;
