import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import {
  UserQueryActions,
  updateUserQueryAPIFail,
  updateUserQueryAPISuccess,
  getUserQueriesAction,
} from 'store/actions/userQueriesActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const userQueryEndpoint = endpoints.userQuery;

/**
 * Save user Search Query
 *
 * @param action$
 * @param state$
 * @param dependencies
 * @returns
 */
const userQueryUpdate: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(UserQueryActions.UPDATE_USER_QUERY),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'PUT',
          url: userQueryEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`POST ${userQueryEndpoint} failed`);
            }
            return of(updateUserQueryAPISuccess());
          }),

          // fetch update list of queries
          concatMap(() => {
            return of(getUserQueriesAction());
          }),

          catchError((error) => {
            return of(updateUserQueryAPIFail(error));
          }),
        );
    }),
  );
};

export default userQueryUpdate;
