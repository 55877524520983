import styled from 'styled-components/macro';

import { NegativeActionButton } from 'components/CallToActions/Button/Button';
import { zIndex } from 'styled/Global';

export const StyledFormNav = styled.nav`
  grid-column: 2;
  grid-row: 2;
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.default} 0;
  margin: 0 ${(props) => props.theme.spacing.default};
  border-top: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
  box-shadow: 0 -3px 11px 7px white;
  z-index: ${zIndex.formNav};
  position: relative;
`;

export const StyledNextSteps = styled.div`
  margin-left: auto;
  display: flex;

  button {
    margin: 0 0 0 ${(props) => props.theme.spacing.small};
  }
`;

export const PreviousButton = styled(NegativeActionButton)`
  margin-right: auto;
`;
