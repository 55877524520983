import styled from 'styled-components/macro';

export const LengthWrapper = styled.div`
  padding: 0;
  &:first-child {
    padding: 0 9px 0 0;
  }
  &:last-child {
    padding: 0 0 0 11px;
  }
`;
