import { GlobalApiFailError } from 'store/actions/types';

export type AthenaValue = {
  value: string;
  subSource?: string;
};

export type CompanyParentFormPayload = {
  companyId: AthenaValue;
  companyName: AthenaValue;
  ownershipStake: AthenaValue;
};

export type CompanyParentsFormPayload = {
  companyId: string;
  parents: CompanyParentFormPayload[];
};

export enum CompanyParentActionTypes {
  COMPANY_PARENT_EDIT = 'COMPANY_PARENT_EDIT',
  COMPANY_PARENT_API_SUCCESS = 'COMPANY_PARENT_API_SUCCESS',
  COMPANY_PARENT_API_FAIL = 'COMPANY_PARENT_API_FAIL',
  COMPANY_PARENT_RESET = 'COMPANY_PARENT_RESET',
}

export type CompanyParentAction = {
  type: CompanyParentActionTypes.COMPANY_PARENT_EDIT;
  payload: CompanyParentsFormPayload;
};

export type CompanyParentApiSuccessAction = {
  type: CompanyParentActionTypes.COMPANY_PARENT_API_SUCCESS;
  payload: CompanyParentResult;
};

export type CompanyParentApiFailAction = {
  type: CompanyParentActionTypes.COMPANY_PARENT_API_FAIL;
  payload: GlobalApiFailError;
};

export type CompanyParentResetAction = {
  type: CompanyParentActionTypes.COMPANY_PARENT_RESET;
};

export type CompanyParentResult = {};

export type CompanySelectActions =
  | CompanyParentApiSuccessAction
  | CompanyParentApiFailAction
  | CompanyParentResetAction;
