export enum LoginActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export type LoginActionPayload = {
  code: string;
  destination: string;
};

export type LoginAction = {
  type: LoginActionTypes.LOGIN;
  payload: LoginActionPayload;
};

export type LogoutAction = {
  type: LoginActionTypes.LOGOUT;
};
