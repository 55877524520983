import React from 'react';
import useMultiline from '../hooks/useMultiline';
import MultilineFieldset from './FormMultiline';
import { MultilineProps } from '../types';
import RepeatableControl from './RepeatableControl';

interface MultiLineWithRepeatProps extends MultilineProps {
  removeLabel?: string;
  multilineTitle?: string;
  addRepeatable?: string;
  repeatableKeys?: string[];
}

const MultiLineWithRepeat = ({
  addButton,
  lineConfig,
  handleOnChange,
  formStateItems,
  multilineTitle,
  lineLimit,
  prompt,
  description,
  repeatableKeys,
  addRepeatable,
  groupId,
}: MultiLineWithRepeatProps) => {
  const {
    onFieldChange,
    addRows,
    addRepeatableRow,
    handleDeleteRow,
    rowFields,
  } = useMultiline(formStateItems, handleOnChange);

  const handleAddSingleRepeat = () => {
    addRows(1);
  };

  const handleAddRepeatable = () => {
    if (repeatableKeys) addRepeatableRow(repeatableKeys, 5);
  };

  return (
    <div>
      <MultilineFieldset
        prompt={prompt}
        description={description}
        rowFields={rowFields}
        lineConfig={lineConfig}
        onFieldChange={onFieldChange}
        handleDeleteLine={handleDeleteRow}
        multilineTitle={multilineTitle}
        groupId={groupId}
      />

      {!lineLimit || rowFields.length < lineLimit ? (
        <RepeatableControl
          addButton={addButton}
          addRepeatable={addRepeatable}
          handleAddSingleRepeat={handleAddSingleRepeat}
          handleAddRepeatable={handleAddRepeatable}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MultiLineWithRepeat;
