import React from 'react';
import styled, { css } from 'styled-components/macro';
import { lighten } from 'polished';
import { Link, LinkProps } from 'react-router-dom';
import { zIndex } from 'styled/Global';

export const MenuList = styled.ul`
  display: flex;
  height: 100%;
  padding: 0 ${(props) => props.theme.spacing.xsmall};
  align-items: center;
`;

export const MenuListItem = styled.li<{
  hasChildren?: boolean;
  isMenuVisible?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  z-index: ${zIndex.globalNav};

  ul {
    display: none;
  }

  ${(props) =>
    css`
      &:active,
      &:hover,
      &:focus {
        cursor: pointer;
        background: ${lighten('0.2')(props.theme.colorPalette.branding.ink)};
      }
    `}

  ${(props) =>
    props.isMenuVisible &&
    css`
      cursor: pointer;
      background: ${lighten('0.2')(props.theme.colorPalette.branding.ink)};

      ul {
        display: flex;
      }
    `}
`;

interface MenuLinkProps extends LinkProps {
  isActive: boolean;
}

const MenuLinkStyles = css`
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-family: ${(props) => props.theme.fonts.secondary};
  padding: 0 ${(props) => props.theme.spacing.default};
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  color: ${(props) => props.theme.colorPalette.branding.white};
`;

const getActiveStyles = (color: string, isActive?: boolean) => {
  if (isActive) {
    return css`
      color: ${color};
      border-bottom: 3px solid ${color};
    `;
  }

  return ``;
};

export const MenuTitle = styled.span<{ isActive: boolean }>`
  ${MenuLinkStyles}

  ${(props) =>
    getActiveStyles(props.theme.colorPalette.branding.yellow, props.isActive)}
`;

export const MenuLink = styled(({ isActive, ...rest }: MenuLinkProps) => (
  <Link {...rest} />
))`
  ${MenuLinkStyles}

  ${(props) =>
    getActiveStyles(props.theme.colorPalette.branding.yellow, props.isActive)}
`;

export const SubMenuList = styled(MenuList)`
  position: absolute;
  padding: 0;
  top: ${(props) => props.theme.fixedHeights.header};
  ${(props) =>
    css`
      top: calc((${props.theme.fixedHeights.header}) - 1px);
    `};
  width: 100%;
  height: auto;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 6px #565971;
`;

export const SubMenuListItem = styled(MenuListItem)`
  justify-content: center;
  width: 100%;
  background: ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;

export const SubMenuLink = styled(MenuLink)`
  font-size: ${(props) => props.theme.typography.paragraph.small};
  padding: ${(props) => props.theme.spacing.small};
  border-top: 0px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
  border-bottom: 3px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
  width: 100%;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    border-bottom: 3px solid
      ${(props) => props.theme.colorPalette.branding.yellow};
    background: ${(props) => props.theme.colorPalette.branding.white};
  }
`;
