import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'ramda/src/isEmpty';

import { globalContent, globalVariables } from 'globalConstants';
import { isJsonString } from 'utils/string';
import useFieldInputValidation from 'hooks/useFieldInputValidation';
import { Partition } from 'pages/Details/types';
import { Option } from 'components/Forms/types';
import { FIELD_COMPONENTS, ValidationProps } from 'connected/FormFlow/types';
import SelectSearch from '../../components/Forms/Inputs/SelectSearch/SelectSearch';
import { FloorSelectCopy } from './constants';
import { getAllBuildingPartitions } from './store/selectors';
import { RowField } from './FloorSelect';

type PartitionSelectProps = {
  onChange: (option: Option) => void;
  selectedValue?: string;
  name: string;
  validation?: ValidationProps;
  lineValue?: any;
  rowFields: RowField[];
  setRowFields?: React.Dispatch<any>;
  onDelete?: () => void;
};

const getPartitionOptions = (knownBuildingFloorPartitions: Partition[]) => {
  return knownBuildingFloorPartitions
    ? knownBuildingFloorPartitions.map((partition) => ({
        label: partition.isHistoric
          ? `${partition.partitionName} (${globalContent.historic})`
          : partition.partitionName,
        value: JSON.stringify({
          partitionName: partition.partitionName,
          partitionId: partition.partitionId ?? partition.id,
        }),
      }))
    : [];
};

const isFieldMandatory = (
  floorLevel: number,
  matchedExistingRows: RowField[],
) => {
  if (typeof floorLevel === 'undefined') return false;

  return matchedExistingRows?.length > 1;
};

const PartitionSelect = ({
  onChange,
  selectedValue,
  lineValue,
  onDelete,
  rowFields,
  setRowFields,
  name,
}: PartitionSelectProps) => {
  const existingBuildingPartitions = useSelector(getAllBuildingPartitions);

  const floorLevel = lineValue.floorLevel?.value;
  const selectedFloor = lineValue.floor || lineValue.floorName;
  const knownBuildingFloorPartitions = useMemo(
    () =>
      existingBuildingPartitions ? existingBuildingPartitions[floorLevel] : [],
    [floorLevel, existingBuildingPartitions],
  );
  const partitionOptions = getPartitionOptions(knownBuildingFloorPartitions);
  const allMatchingExistingRows = useMemo(
    () =>
      rowFields.filter((demise) => {
        return demise.floorLevel?.value === floorLevel;
      }),
    [floorLevel, rowFields],
  );

  const fieldIsMandatory = useMemo(
    () => isFieldMandatory(floorLevel, allMatchingExistingRows),
    [floorLevel, allMatchingExistingRows],
  );

  const updatedValidation = {
    isRequired: fieldIsMandatory,
    message: 'Please enter the partition name for this demise',
  };

  const { hasErrors } = useFieldInputValidation(
    selectedValue,
    FIELD_COMPONENTS.PARTITION_SELECT,
    updatedValidation,
  );

  let selectedPartitionOptions: Option[] = partitionOptions?.filter(
    (option: Option) => {
      return option.value === selectedValue;
    },
  );

  const disablePartitions = !selectedFloor || selectedFloor.value === '';
  const setPrepopulatedAreaAndMeasurement = (existingPartition: Partition) => {
    if (setRowFields) {
      let rowsPayload: RowField[] = [];
      let updatedLine: RowField | null = null;

      if (existingPartition.areaSqFt) {
        updatedLine = {
          ...lineValue,
          totalArea: {
            componentType: 'SquareFtInput',
            index: lineValue.index,
            label: 'Demise sq ft',
            value: existingPartition.areaSqFt,
          },
        };
      }

      if (existingPartition.measurementStandard) {
        updatedLine = updatedLine || ({ ...lineValue } as RowField);

        updatedLine.measurementStandard = {
          componentType: 'SelectInput',
          index: lineValue.index,
          label: 'Measurement Standard',
          value: existingPartition.measurementStandard,
        };
      }

      if (updatedLine) {
        rowsPayload = rowFields.map((row) => {
          return row.index === updatedLine?.index ? updatedLine : row;
        });
      }

      if (!isEmpty(rowsPayload)) setRowFields(rowsPayload);
    }
  };

  const onPartitionSelect = (selected: Option) => {
    onChange(selected);

    if (isJsonString(selected.value)) {
      const parsed = JSON.parse(selected.value);
      const existingPartition = knownBuildingFloorPartitions?.find(
        (partition) => {
          return parsed.partitionId === partition.partitionId;
        },
      );

      if (existingPartition) {
        setPrepopulatedAreaAndMeasurement(existingPartition);
      }
    }
  };

  if (!selectedPartitionOptions.length && selectedValue) {
    selectedPartitionOptions = [{ value: selectedValue, label: selectedValue }];
  }

  return (
    <SelectSearch
      id={`${name}_partition-select`}
      name={`${name}_partition-select`}
      options={partitionOptions}
      label={FloorSelectCopy.partitionLabel}
      onChange={onPartitionSelect}
      multipleSelect={false}
      maxWidth={globalVariables.SELECT_MAX_WIDTH}
      placeholder=""
      selectedOptions={disablePartitions ? undefined : selectedPartitionOptions}
      validation={updatedValidation}
      isDisabled={disablePartitions}
      canAddNew
      onDelete={onDelete}
      hasErrors={hasErrors}
    />
  );
};

export default PartitionSelect;
