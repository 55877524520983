import { isAfter, parseISO } from 'date-fns';
import { TABLE_ACTIONS, TABLE_CELL_TYPE } from 'components/Table/constants';
import { TableRowProps } from 'components/Table/types';
import { MODAL_ID } from 'connected/GlobalModal/constants';
import { FREQUENCY_MAP } from 'constants/configMaps';
import { formatDate, formatFromMap } from 'services/format';
import { UserQueryPayloadType } from 'store/actions/userQueriesActions';
import { UserScheduledQueriesPayloadType } from 'store/actions/userProfileActions';
import { urls } from 'globalConstants';
import { SCHEDULE_QUERY_COPY } from './constants';

export const getScheduledQuery = (
  scheduledQueries: UserScheduledQueriesPayloadType[],
  queryId: string,
) => {
  return scheduledQueries.find(
    (query: UserScheduledQueriesPayloadType) =>
      queryId === query.scheduledQueryId,
  );
};

export const getUserQuery = (
  userQueries: UserQueryPayloadType[],
  queryId: string,
) => {
  return userQueries.find(
    (query: UserQueryPayloadType) => queryId === query.userQueryId,
  );
};

export const getUsersScheduledQueries = (
  scheduledQueries: UserScheduledQueriesPayloadType[],
  userQueryId: string,
) => {
  const scheduledQuery = scheduledQueries.find(
    (query: UserScheduledQueriesPayloadType) =>
      userQueryId === query.userQueryId,
  );

  if (!scheduledQuery) {
    return {
      status: 'None',
      frequency: '',
    };
  }

  if (isAfter(parseISO(scheduledQuery.endDate), new Date())) {
    return {
      scheduledQueryId: scheduledQuery.scheduledQueryId,
      status: 'Active',
      frequency: formatFromMap(scheduledQuery.repeatFrequency, FREQUENCY_MAP),
    };
  }

  return {
    scheduledQueryId: scheduledQuery.scheduledQueryId,
    status: 'Expired',
    frequency: formatFromMap(scheduledQuery.repeatFrequency, FREQUENCY_MAP),
  };
};

export const mapQueriesToTableFormattedData = (
  record: UserQueryPayloadType,
  scheduledQuery: any,
): TableRowProps => {
  const scheduleExists = scheduledQuery.status !== 'None';

  return {
    name: {
      value: record.queryName,
      linkTo: `${urls.searchFilters}?selectedUserQuery=${record.queryName}&${record.query}`,
    },
    created: {
      value: record.created ? formatDate(record.created) : null,
    },
    updated: {
      value: record.updated ? formatDate(record.updated) : null,
    },
    scheduleStatus: {
      value: scheduledQuery.status,
    },
    schedule: {
      value: scheduledQuery.frequency,
    },
    view: {
      value: '',
      cellType: TABLE_CELL_TYPE.ACTION,
      actions: [
        {
          type: TABLE_ACTIONS.MODAL,
          isDisabled: !scheduleExists,
          modalConfig: {
            modalId: MODAL_ID.SCHEDULE_QUERY,
            triggerButton: SCHEDULE_QUERY_COPY.editButton,
            recordId: record.userQueryId,
          },
        },
        {
          type: TABLE_ACTIONS.MODAL,
          isDisabled: scheduleExists,
          modalConfig: {
            modalId: MODAL_ID.SCHEDULE_QUERY,
            triggerButton: SCHEDULE_QUERY_COPY.addButton,
            recordId: record.userQueryId,
          },
        },
        {
          type: TABLE_ACTIONS.MODAL,
          isDisabled: scheduleExists,
          modalConfig: {
            modalId: MODAL_ID.DELETE_USER_QUERY,
            triggerButton: SCHEDULE_QUERY_COPY.deleteQueryButton,
            recordId: record.userQueryId,
          },
        },
        {
          type: TABLE_ACTIONS.MODAL,
          isDisabled: !scheduleExists,
          modalConfig: {
            modalId: MODAL_ID.DELETE_SCHEDULED_QUERY,
            triggerButton: SCHEDULE_QUERY_COPY.deleteButton,
            recordId: scheduledQuery.scheduledQueryId,
          },
        },
        {
          type: TABLE_ACTIONS.MODAL,
          isDisabled: !scheduleExists,
          modalConfig: {
            modalId: MODAL_ID.DELETE_USER_QUERY,
            triggerButton: SCHEDULE_QUERY_COPY.deleteQueryButton,
            recordId: record.userQueryId,
          },
        },
      ],
    },
  };
};
