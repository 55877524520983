import { BUILDING_DETAILS_PAGE_COPY } from '../constants';

export const ACCOMMODATION_SCHEDULE_COPY = {
  heading: 'Accommodation Schedule',
  noResults: 'No data exists for this building yet.',
  addBtn: 'Manage',
  tooltip:
    'You cannot delete this item as it has one or more leases associated with it',
  upper: 'UPPER GROUND',
  ground: 'GROUND',
  lower: 'LOWER GROUND',
};

export const ACCOMMODATION_SCHEDULE_TABLE_HEADERS = [
  {
    title: BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.floorLevel,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.floorName,
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.partitionName,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.usage,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.area,
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers
        .measurementStandard,
  },
];

export const ACCOMMODATION_SCHEDULE_TABLE_HEADERS_NON_OFFICE = [
  {
    title: BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.refLevel,
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.floorUnitName,
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.partitionName,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.usage,
  },
  {
    title: BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers.area,
  },
  {
    title:
      BUILDING_DETAILS_PAGE_COPY.accommodationSchedule.headers
        .measurementStandard,
  },
];
