import { IndividualCompany } from 'connected/InvestmentPanel/types';
import { GlobalApiFailError } from 'store/actions/types';

import {
  DetailsActionTypes,
  DetailsApiSuccessAction,
  DetailsApiFailAction,
  DetailsFetchAction,
  ResourceIdentifiers,
  DetailsPayload,
  MapPinDetailsPayload,
  DetailsResetAction,
  DetailsRefreshAction,
  UpdateBuildingAction,
  BuildingRecord,
  SaleRecord,
  GetBuildingLeasesSalesAction,
  GetBuildingLeasesSalesSuccessAction,
  GetMapPinDetailsFetchAction,
  GetMapPinDetailsSuccessAction,
  GetMapPinDetailsFailAction,
  MapPinDetailsReset,
  ClearEntityAction,
  BuildingDeleteFromPortfolioAction,
  BuildingDeleteFromPortfolioApiFailAction,
  BuildingDeleteFromPortfolioSuccessAction,
  BuildingDeleteAction,
  BuildingDeleteApiFailAction,
  BuildingDeleteSuccessAction,
  DeleteBuildingPayload,
  GetUpdateHistoryAction,
  GetUpdateHistorySuccessAction,
  UpdateHistoryPayload,
  GetUpdateHistoryPayload,
  DeleteFloorAction,
  DeleteFloorPayload,
  FloorDeleteApiFailAction,
  FloorDeleteSuccessAction,
  UpdatePanelFilterPayload,
  UpdatePanelFilterAction,
  CompanyDeleteAction,
  CompanyDeleteApiFailAction,
  CompanyDeleteSuccessAction,
  DeleteCompanyPayload,
  MoveEntityAction,
  MoveEntityFailAction,
  MoveEntitySuccessAction,
  MoveEntityPayload,
  RenewEntityAction,
  RenewEntityFailAction,
  RenewEntitySuccessAction,
  RenewEntityPayload,
  SplitEntityPayload,
  SplitEntityAction,
  SplitEntityFailAction,
  SplitEntitySuccessAction,
  DeleteEntityPayload,
  DeleteEntityAction,
  HideEntityPayload,
  HideEntityAction,
  HideEntityFailAction,
  DeleteEntitySuccessAction,
  DeleteEntityFailAction,
  HideEntitySuccessAction,
  WatchPayload,
  DeleteWatchPayload,
  AddWatchRecordAction,
  DeleteWatchRecordAction,
  AddWatchRecordFailAction,
  AddWatchRecordSuccessAction,
  DeleteWatchRecordFailAction,
  DeleteWatchRecordSuccessAction,
  MergeRecordsPayload,
  MergeRecordsAction,
  RecordsToMergeAction,
  RecordsToMergePayload,
  MergeRecordsFailAction,
  MergeRecordsSuccessAction,
  CompareRecordsFailAction,
  CompareRecordsSuccessAction,
  CompareRecordsPayload,
  CompareRecordsAction,
  CompareRecordsResetAction,
  DeleteBuildingFromPortfolioPayload,
  BuildingProtectPayload,
  BuildingProtectAction,
  BuildingProtectApiFailAction,
  BuildingProtectSuccessAction,
} from 'pages/Details/types';

export const detailsFetch = (
  payload: ResourceIdentifiers,
): DetailsFetchAction => ({
  type: DetailsActionTypes.DETAILS_FETCH,
  payload,
});

export const detailsApiSuccess = (
  payload: DetailsPayload,
): DetailsApiSuccessAction => ({
  type: DetailsActionTypes.DETAILS_API_SUCCESS,
  payload,
});

export const detailsApiFail = (
  payload: GlobalApiFailError,
): DetailsApiFailAction => ({
  type: DetailsActionTypes.DETAILS_API_FAIL,
  payload,
});

export const detailsReset = (): DetailsResetAction => ({
  type: DetailsActionTypes.DETAILS_RESET,
});

export const detailsRefresh = (): DetailsRefreshAction => ({
  type: DetailsActionTypes.DETAILS_REFRESH,
});

export const updateBuilding = (payload: {
  building: BuildingRecord;
}): UpdateBuildingAction => ({
  type: DetailsActionTypes.UPDATE_BUILDING,
  payload,
});

export const entityToClear = (payload: {
  entityName: string;
  entityId: string;
  key: string;
}): ClearEntityAction => ({
  type: DetailsActionTypes.ENTITY_TO_CLEAR,
  payload,
});

export const getBuildingLeasesSales = (
  payload: ResourceIdentifiers,
): GetBuildingLeasesSalesAction => ({
  type: DetailsActionTypes.GET_BUILDING_LEASES_SALES,
  payload,
});

export const getBuildingLeasesSalesSuccess = (payload: {
  mostRecentSoldSale?: SaleRecord;
  landlords?: IndividualCompany[];
  spv?: IndividualCompany;
}): GetBuildingLeasesSalesSuccessAction => ({
  type: DetailsActionTypes.GET_BUILDING_LEASES_SALES_SUCCESS,
  payload,
});

export const getMapPinDetails = (
  payload: ResourceIdentifiers,
): GetMapPinDetailsFetchAction => ({
  type: DetailsActionTypes.MAP_PIN_DETAILS_FETCH,
  payload,
});

export const mapPinDetailsApiSuccess = (
  payload: MapPinDetailsPayload,
): GetMapPinDetailsSuccessAction => ({
  type: DetailsActionTypes.MAP_PIN_DETAILS_API_SUCCESS,
  payload,
});

export const mapPinDetailsApiFail = (
  payload: GlobalApiFailError,
): GetMapPinDetailsFailAction => ({
  type: DetailsActionTypes.MAP_PIN_DETAILS_API_FAIL,
  payload,
});

export const mapPinDetailsReset = (): MapPinDetailsReset => ({
  type: DetailsActionTypes.MAP_PIN_DETAILS_RESET,
});

export const buildingDeleteFromPortfolio = (
  payload: DeleteBuildingFromPortfolioPayload,
) => {
  const action: BuildingDeleteFromPortfolioAction = {
    type: DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO,
    payload,
  };

  return action;
};

export const buildingDelete = (payload: DeleteBuildingPayload) => {
  const action: BuildingDeleteAction = {
    type: DetailsActionTypes.DELETE_BUILDING,
    payload,
  };
  return action;
};

export const buildingProtect = (payload: BuildingProtectPayload) => {
  const action: BuildingProtectAction = {
    type: DetailsActionTypes.PROTECT_BUILDING,
    payload,
  };
  return action;
};

export const buildingProtectApiFail = (
  payload: GlobalApiFailError,
): BuildingProtectApiFailAction => ({
  type: DetailsActionTypes.PROTECT_BUILDING_API_FAIL,
  payload,
});

export const buildingProtectApiSuccess = (): BuildingProtectSuccessAction => ({
  type: DetailsActionTypes.PROTECT_BUILDING_API_SUCCESS,
});

export const buildingsDeleteFromPortfolioApiFail = (
  payload: GlobalApiFailError,
): BuildingDeleteFromPortfolioApiFailAction => ({
  type: DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_FAIL,
  payload,
});

export const buildingsDeleteApiFail = (
  payload: GlobalApiFailError,
): BuildingDeleteApiFailAction => ({
  type: DetailsActionTypes.DELETE_BUILDING_API_FAIL,
  payload,
});

export const floorDeleteApiSuccess = (): FloorDeleteSuccessAction => ({
  type: DetailsActionTypes.DELETE_FLOOR_API_SUCCESS,
});

export const floorDeleteApiFail = (
  payload: GlobalApiFailError,
): FloorDeleteApiFailAction => ({
  type: DetailsActionTypes.DELETE_FLOOR_API_FAIL,
  payload,
});

export const buildingsDeleteFromPortfolioApiSuccess = (): BuildingDeleteFromPortfolioSuccessAction => ({
  type: DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS,
});

export const buildingsDeleteApiSuccess = (): BuildingDeleteSuccessAction => ({
  type: DetailsActionTypes.DELETE_BUILDING_API_SUCCESS,
});

export const companyDelete = (payload: DeleteCompanyPayload) => {
  const action: CompanyDeleteAction = {
    type: DetailsActionTypes.DELETE_COMPANY,
    payload,
  };
  return action;
};
export const companysDeleteApiFail = (
  payload: GlobalApiFailError,
): CompanyDeleteApiFailAction => ({
  type: DetailsActionTypes.DELETE_COMPANY_API_FAIL,
  payload,
});
export const companysDeleteApiSuccess = (): CompanyDeleteSuccessAction => ({
  type: DetailsActionTypes.DELETE_COMPANY_API_SUCCESS,
});

export const getUpdateHistory = (
  payload: GetUpdateHistoryPayload,
): GetUpdateHistoryAction => ({
  type: DetailsActionTypes.GET_UPDATE_HISTORY,
  payload,
});

export const updateHistoryApiFail = (
  payload: GlobalApiFailError,
): BuildingDeleteApiFailAction => ({
  type: DetailsActionTypes.DELETE_BUILDING_API_FAIL,
  payload,
});

export const updateHistoryApiSuccess = (
  payload: UpdateHistoryPayload,
): GetUpdateHistorySuccessAction => ({
  type: DetailsActionTypes.GET_UPDATE_HISTORY_API_SUCCESS,
  payload,
});

export const deleteFloorAction = (
  payload: DeleteFloorPayload,
): DeleteFloorAction => ({
  type: DetailsActionTypes.DELETE_FLOOR_ACTION,
  payload,
});

export const updatePanelFilters = (
  payload: UpdatePanelFilterPayload,
): UpdatePanelFilterAction => ({
  type: DetailsActionTypes.UPDATE_PANEL_FILTERS,
  payload,
});

export const moveEntity = (payload: MoveEntityPayload): MoveEntityAction => ({
  type: DetailsActionTypes.MOVE_ENTITY,
  payload,
});

export const moveEntityFailure = (
  payload: GlobalApiFailError,
): MoveEntityFailAction => ({
  type: DetailsActionTypes.MOVE_ENTITY_FAIL,
  payload,
});

export const moveEntitySuccess = (): MoveEntitySuccessAction => ({
  type: DetailsActionTypes.MOVE_ENTITY_SUCCESS,
});

export const renewEntity = (
  payload: RenewEntityPayload,
): RenewEntityAction => ({
  type: DetailsActionTypes.RENEW_ENTITY,
  payload,
});

export const renewEntityFailure = (
  payload: GlobalApiFailError,
): RenewEntityFailAction => ({
  type: DetailsActionTypes.RENEW_ENTITY_FAIL,
  payload,
});

export const renewEntitySuccess = (): RenewEntitySuccessAction => ({
  type: DetailsActionTypes.RENEW_ENTITY_SUCCESS,
});

export const splitEntity = (
  payload: SplitEntityPayload,
): SplitEntityAction => ({
  type: DetailsActionTypes.SPLIT_ENTITY,
  payload,
});

export const splitEntityFailure = (
  payload: GlobalApiFailError,
): SplitEntityFailAction => ({
  type: DetailsActionTypes.SPLIT_ENTITY_FAIL,
  payload,
});

export const splitEntitySuccess = (): SplitEntitySuccessAction => ({
  type: DetailsActionTypes.SPLIT_ENTITY_SUCCESS,
});

export const deleteEntity = (
  payload: DeleteEntityPayload,
): DeleteEntityAction => ({
  type: DetailsActionTypes.DELETE_ENTITY,
  payload,
});

export const deleteEntityFailure = (
  payload: GlobalApiFailError,
): DeleteEntityFailAction => ({
  type: DetailsActionTypes.DELETE_ENTITY_FAIL,
  payload,
});

export const deleteEntitySuccess = (): DeleteEntitySuccessAction => ({
  type: DetailsActionTypes.DELETE_ENTITY_SUCCESS,
});

export const hideEntity = (payload: HideEntityPayload): HideEntityAction => ({
  type: DetailsActionTypes.HIDE_ENTITY,
  payload,
});

export const hideEntityFailure = (
  payload: GlobalApiFailError,
): HideEntityFailAction => ({
  type: DetailsActionTypes.HIDE_ENTITY_FAIL,
  payload,
});

export const hideEntitySuccess = (): HideEntitySuccessAction => ({
  type: DetailsActionTypes.HIDE_ENTITY_SUCCESS,
});

export const addWatchRecord = (
  payload: WatchPayload,
): AddWatchRecordAction => ({
  type: DetailsActionTypes.ADD_WATCH_RECORD,
  payload,
});

export const addWatchRecordFailure = (
  payload: GlobalApiFailError,
): AddWatchRecordFailAction => ({
  type: DetailsActionTypes.ADD_WATCH_RECORD_FAIL,
  payload,
});

export const addWatchRecordSuccess = (): AddWatchRecordSuccessAction => ({
  type: DetailsActionTypes.ADD_WATCH_RECORD_SUCCESS,
});

export const deleteWatchRecord = (
  payload: DeleteWatchPayload,
): DeleteWatchRecordAction => ({
  type: DetailsActionTypes.DELETE_WATCH_RECORD,
  payload,
});

export const deleteWatchRecordFailure = (
  payload: GlobalApiFailError,
): DeleteWatchRecordFailAction => ({
  type: DetailsActionTypes.DELETE_WATCH_RECORD_FAIL,
  payload,
});

export const deleteWatchRecordSuccess = (): DeleteWatchRecordSuccessAction => ({
  type: DetailsActionTypes.DELETE_WATCH_RECORD_SUCCESS,
});

export const saveRecordsToMerge = (
  payload: RecordsToMergePayload,
): RecordsToMergeAction => ({
  type: DetailsActionTypes.RECORDS_TO_MERGE,
  payload,
});

export const mergeRecords = (
  payload: MergeRecordsPayload,
): MergeRecordsAction => ({
  type: DetailsActionTypes.MERGE_RECORDS,
  payload,
});

export const mergeRecordsFailure = (
  payload: GlobalApiFailError,
): MergeRecordsFailAction => ({
  type: DetailsActionTypes.MERGE_RECORDS_FAIL,
  payload,
});

export const mergeRecordsSuccess = (): MergeRecordsSuccessAction => ({
  type: DetailsActionTypes.MERGE_RECORDS_SUCCESS,
});

export const compareRecords = (
  payload: CompareRecordsPayload,
): CompareRecordsAction => ({
  type: DetailsActionTypes.COMPARE_RECORDS,
  payload,
});

export const compareRecordsFailure = (
  payload: GlobalApiFailError,
): CompareRecordsFailAction => ({
  type: DetailsActionTypes.COMPARE_RECORDS_FAIL,
  payload,
});

export const compareRecordsSuccess = (
  payload: DetailsPayload,
): CompareRecordsSuccessAction => ({
  type: DetailsActionTypes.COMPARE_RECORDS_SUCCESS,
  payload,
});

export const compareRecordsReset = (): CompareRecordsResetAction => ({
  type: DetailsActionTypes.COMPARE_RECORDS_RESET,
});
