import { DetailsState } from './types';

export const detailsInitialState: DetailsState = {
  isFetching: false,
  isSaving: false,
  resourceID: '',
  buildingID: '',
  documents: [],
  mapPinCard: {
    isFetching: false,
    isError: false,
  },
  buildingToDeleteFromPortfolio: {
    id: '',
    entity: '',
    address: '',
    isDeleting: false,
    error: '',
  },
  buildingToDelete: {
    id: '',
    entity: '',
    isDeleting: false,
    error: '',
  },
  companyToDelete: {
    id: '',
    isDeleting: false,
    error: '',
  },
  floorToDelete: {
    id: '',
    entity: '',
    isDeleting: false,
    error: '',
  },
  updateHistory: {
    isFetching: false,
  },
  recordsToCompare: [],
  recordCompareIsFetching: false,
  mergeRecords: {
    sale: [],
    lease: [],
  },
  trustscale: [],
};
