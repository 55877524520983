import { createSelector } from 'reselect';
import pick from 'ramda/src/pick';

import { RootState } from 'store/rootReducer';
import { LeaseStatusEnum } from 'constants/leases';
import { FilterCategories } from 'components/Filter/types';
import { getOrderedLeasesByLevelDescending } from 'services/verticalDetails';
import { EpcCertificate } from 'connected/EpcCertificatesPanel/types';
import {
  getCompanyDebts,
  getSelectedDebtPanelFilters,
  getSelectedEpcPanelFilters,
  getSelectedLeasePanelFilters,
  getSelectedSalePanelFilters,
  getSelectedToLetPanelFilters,
} from '../detailsSelectors';
import { getFilteredItems } from './helpers';

export const getLeasesUnderOfferAndLetAndTerminated = createSelector(
  (state: RootState) => state.details.building?.leases,
  (leases) =>
    leases?.filter((lease) =>
      [
        LeaseStatusEnum.UnderOffer,
        LeaseStatusEnum.Let,
        LeaseStatusEnum.Terminated,
      ].includes(lease.availabilityStatus as LeaseStatusEnum),
    ),
);

export const getLeasesToLet = createSelector(
  (state: RootState) => state.details.building?.leases,
  (leases) =>
    leases?.filter(
      (lease) => lease.availabilityStatus === LeaseStatusEnum.ToLet,
    ),
);

// individual filters are joined with an AND, but selections within filters are joined with an OR
export const getFilteredLeases = createSelector(
  getLeasesUnderOfferAndLetAndTerminated,
  getSelectedLeasePanelFilters,
  (leases, selectedLeaseFilters) => {
    return getFilteredItems(leases, selectedLeaseFilters);
  },
);
export const getLeasesUnderOfferAndLet = createSelector(
  (state: RootState) => state.details.building?.leases,
  (leases) =>
    leases?.filter((lease) =>
      [LeaseStatusEnum.UnderOffer, LeaseStatusEnum.Let].includes(
        lease.availabilityStatus as LeaseStatusEnum,
      ),
    ),
);

export const getBuildingDocXLeases = createSelector(
  getLeasesUnderOfferAndLet,
  getSelectedLeasePanelFilters,
  (leases, selectedLeaseFilters) => {
    const currentRecordVisibilityFilterValue =
      selectedLeaseFilters[FilterCategories.RECORD_VISIBILITY];

    const filteredLeases = getFilteredItems(leases, {
      [FilterCategories.LEASE_DATE_CATEGORY]: ['current', 'future'],
      [FilterCategories.RECORD_VISIBILITY]: currentRecordVisibilityFilterValue,
    });

    return getOrderedLeasesByLevelDescending(filteredLeases);
  },
);

export const getBuildingToDeleteFromPortfolioSelector = (state: RootState) => {
  const keys: (keyof typeof state.details.buildingToDeleteFromPortfolio)[] = [
    'entity',
    'id',
    'address',
  ];

  return pick(keys, state.details.buildingToDeleteFromPortfolio);
};

export const getBuildingDeleteFromPortfolioErrorSelector = (state: RootState) =>
  state.details.buildingToDeleteFromPortfolio.error;

export const getBuildingIsDeletingFromPortfolioSelector = (state: RootState) =>
  state.details.buildingToDeleteFromPortfolio.isDeleting;

export const getBuildingToDeleteSelector = (state: RootState) => {
  const keys: (keyof typeof state.details.buildingToDelete)[] = [
    'entity',
    'id',
  ];

  return pick(keys, state.details.buildingToDelete);
};

export const getBuildingDeleteErrorSelector = (state: RootState) =>
  state.details.buildingToDelete.error;

export const getBuildingIsDeletingSelector = (state: RootState) =>
  state.details.buildingToDelete.isDeleting;

export const getSales = (state: RootState) => state.details.building?.sales;
export const getEpcCertificates = (state: RootState) =>
  state.details.building?.epcCertificates;

export const getFilteredSales = createSelector(
  getSales,
  getSelectedSalePanelFilters,
  (sales, selectedSaleFilters) => {
    return getFilteredItems(sales, selectedSaleFilters);
  },
);

export const getFilteredEpcCertificates = createSelector(
  getEpcCertificates,
  getSelectedEpcPanelFilters,
  (certificates, filters) => {
    return getFilteredItems(certificates, filters) as EpcCertificate[];
  },
);

export const getFilteredToLets = createSelector(
  getLeasesToLet,
  getSelectedToLetPanelFilters,
  (leases, selectedToLetFilters) => {
    return getFilteredItems(leases, selectedToLetFilters);
  },
);

export const getBuildingType = (state: RootState) =>
  state.details.building?.primaryUse;

export const getDebts = (state: RootState) => state.details.building?.debts;

export const getFilteredDebts = createSelector(
  getDebts,
  getSelectedDebtPanelFilters,
  (debts, selectedDebtFilters) => {
    return getFilteredItems(debts, selectedDebtFilters);
  },
);
export const getDebtToDeleteSelector = (state: RootState) => {
  const keys: (keyof typeof state.debts.debtToDelete)[] = ['entity', 'id'];

  return pick(keys, state.debts.debtToDelete);
};

export const getDebtDeleteErrorSelector = (state: RootState) =>
  state.debts.debtToDelete.error;

export const getDebtIsDeletingSelector = (state: RootState) =>
  state.debts.debtToDelete.isDeleting;

export const getFilteredCompanyDebts = createSelector(
  getCompanyDebts,
  getSelectedDebtPanelFilters,
  (debts, selectedDebtFilters) => {
    return getFilteredItems(debts, selectedDebtFilters);
  },
);

export const getValuations = (state: RootState) =>
  state.details.building?.valuations;
