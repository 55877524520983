import styled from 'styled-components/macro';
import { hideVisually } from 'polished';

import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';

export const FileItem = styled.div`
  background: ${(props) => props.theme.colorPalette.panel.default};
  padding: ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.default};
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-size: ${(props) => props.theme.typography.paragraph.default};
  font-family: ${(props) => props.theme.fonts.secondary};
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  border-radius: 3px;
  position: relative;

  > span svg {
    top: 2px;
  }

  > div {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const DeleteIcon = styled(withIconStyles(Delete))`
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 0;
`;

export const DeleteButton = styled.button`
  position: relative;

  span {
    ${hideVisually()}
  }
`;
