import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled(Spinner)<{
  color?: string;
}>`
  animation: ${rotate360} 1s linear infinite;
  height: 16px;
  width: 16px;
  fill: ${(props) => props.color || props.theme.colorPalette.branding.ink};
`;
