import { mergeMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';

import { PortfolioNameSelectActionTypes } from 'connected/PortfolioNameSelect/types';
import { getPortfolioNamesSelector } from 'store/selectors/portfolioNameSelectors';
import { portfolioNameFilteredSuccess } from 'store/actions/portfolioNameSelectActions';
import { Observable } from 'rxjs';

const portfolioNameSelectEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(PortfolioNameSelectActionTypes.PORTFOLIO_NAME_SELECT_QUERY),
    mergeMap((action) => {
      return Observable.create((observer: any) => {
        const searchTerm = action.payload;
        const allPortfolioNames = getPortfolioNamesSelector(state$.value);
        observer.next(
          portfolioNameFilteredSuccess(
            allPortfolioNames.filter((result: string) =>
              result.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
          ),
        );
        observer.complete();
      });
    }),
  );

export default portfolioNameSelectEpic;
