import {
  map,
  switchMap,
  withLatestFrom,
  catchError,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import isEmpty from 'ramda/src/isEmpty';

import { endpoints } from 'globalConstants';
import { getSearchQuery } from 'store/selectors/searchQuery';
import { MapActionTypes } from 'pages/QueryBuilder/types';
import { AuthenticatedRequestObservable } from 'apis/request';
import {
  fetchSearchResultsCountFail,
  fetchSearchResultsCountSuccess,
  SearchActionTypes,
} from 'store/actions/searchActions';
import { FilterPanelActionTypes } from 'store/actions/filterPanelActions';

type DetailsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const searchCountEpic: Epic = (
  action$,
  state$,
  { authRequest }: DetailsEpicDependencies,
) =>
  action$.pipe(
    ofType<any>(
      SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT,
      MapActionTypes.MAP_ADD_SHAPE,
      MapActionTypes.MAP_FETCH_BOUNDARY_API_SUCCESS,
      MapActionTypes.MAP_CIRCLE_MOVE_END,
      MapActionTypes.MAP_POLYGON_EDIT,
      FilterPanelActionTypes.ADD_SINGLE_FILTER,
      FilterPanelActionTypes.REMOVE_FILTER_KEYS,
      FilterPanelActionTypes.REMOVE_SINGLE_FILTER,
    ),
    filter((action) => {
      return !isEmpty(action.payload);
    }),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const searchQuery = getSearchQuery(state);
      const params = new URLSearchParams(searchQuery as Record<string, string>);
      const url = `${
        endpoints.searchQuery
      }?${params.toString()}&countOnly=true`;

      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }

          const count = results.response.meta.rows;

          return fetchSearchResultsCountSuccess(count);
        }),
        takeUntil(
          action$.pipe(
            ofType(
              MapActionTypes.SELECT_DRAW_TOOL,
              MapActionTypes.MAP_CIRCLE_MOVE_END,
              MapActionTypes.MAP_POLYGON_EDIT,
              MapActionTypes.MAP_ADD_SHAPE,
              MapActionTypes.MAP_FETCH_BOUNDARY_API_SUCCESS,
            ),
          ),
        ),
        catchError(() => {
          return of(fetchSearchResultsCountFail());
        }),
      );
    }),
  );

export default searchCountEpic;
