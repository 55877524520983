import React from 'react';
import { DOCUMENT_UPLOADER_COPY } from './constants';
import {
  SuccessConfirmationTitle,
  UploadCompleteStatus,
} from './DocumentUploaderStyled';

const DocumentUploaderSuccess = ({ name }: { name: string }) => (
  <>
    <SuccessConfirmationTitle>
      {DOCUMENT_UPLOADER_COPY.success}
    </SuccessConfirmationTitle>
    <UploadCompleteStatus>
      {name} {DOCUMENT_UPLOADER_COPY.uploaded}
    </UploadCompleteStatus>
  </>
);

export default DocumentUploaderSuccess;
