import React, { Suspense, lazy } from 'react';
import { Store } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import SearchRoute from 'connected/RouteWrappers/SearchRoute';

import GlobalStyle from 'styled/Global';
import styledTheme from 'styled/theme';

import { GlobalScaffold } from 'components/Layouts/GlobalScaffold/GlobalScaffoldStyled';
import GlobalHeader from 'connected/GlobalHeader/GlobalHeader';
import GlobalErrorToast from 'connected/GlobalErrorToast/GlobalErrorToast';
import { urls, Vertical } from 'globalConstants';

import 'react-toastify/dist/ReactToastify.css';
import { RootState } from 'store/rootReducer';
import { Admin } from 'pages/Admin/Admin';
import { AdminRoute } from 'connected/RouteWrappers/AdminRoute';
import RecordNotFoundPage from 'pages/Error/RecordNotFound';

const DetailsPage = lazy(() => import('pages/Details/Details'));
const LandingPage = lazy(() => import('pages/Landing/Landing'));
const PrimaryFilterPage = lazy(() =>
  import('pages/QueryBuilder/BuildQueryPrimaryFilter'),
);
const QueryBuilderMap = lazy(() => import('pages/QueryBuilder/BuildQueryMap'));
const FiltersPage = lazy(() => import('pages/QueryBuilder/BuildQueryFilters'));
const ResultsPage = lazy(() => import('pages/QueryBuilder/Results'));
const LoginPage = lazy(() => import('pages/Login/Login'));
const NewSalePage = lazy(() => import('pages/NewSale/NewSale'));
const NewPortfolioSalePage = lazy(() =>
  import('pages/NewPortfolioSale/NewPortfolioSale'),
);
const NewLeasePage = lazy(() => import('pages/NewLease/NewLease'));
const NotFoundPage = lazy(() => import('pages/Error/NotFound'));
const ServerErrorPage = lazy(() => import('pages/Error/ServerError'));
const Profile = lazy(() => import('pages/Profile/Profile'));
const IFrameAnalytics = lazy(() =>
  import('pages/IFrameAnalytics/IFrameAnalytics'),
);

type AppProps = {
  store: Store<RootState>;
};

const App = ({ store }: AppProps) => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={styledTheme}>
        <GlobalStyle />
        <GlobalScaffold>
          <GlobalHeader />
          <GlobalErrorToast />
          <Suspense fallback={<div>Loading</div>}>
            <Switch>
              <SearchRoute
                path={urls.searchDetails(':vertical' as Vertical, ':id')}
                component={DetailsPage}
              />
              <SearchRoute
                path={urls.searchPrimaryFilter}
                component={PrimaryFilterPage}
              />
              <SearchRoute path={urls.searchMap} component={QueryBuilderMap} />
              <SearchRoute path={urls.searchFilters} component={FiltersPage} />
              <SearchRoute path={urls.searchResults} component={ResultsPage} />
              <SearchRoute path={urls.search} component={LandingPage} />
              <SearchRoute path={urls.newSale.single} component={NewSalePage} />
              <SearchRoute
                path={urls.newSale.portfolio}
                component={NewPortfolioSalePage}
              />
              <SearchRoute
                path={urls.newLease.single}
                component={NewLeasePage}
              />
              <SearchRoute path={urls.profile} component={Profile} />

              <SearchRoute
                path={urls.analytics.teamLeaderboard.page}
                component={IFrameAnalytics}
              />
              <SearchRoute
                path={urls.analytics.athenaContribution.page}
                component={IFrameAnalytics}
              />
              <SearchRoute
                path={urls.analytics.lendingAnalytics.page}
                component={IFrameAnalytics}
              />
              <SearchRoute
                path={urls.analytics.epcAnalytics.page}
                component={IFrameAnalytics}
              />
              <SearchRoute
                path={urls.analytics.marketAnalytics.page}
                component={IFrameAnalytics}
              />
              <SearchRoute
                path={urls.analytics.tenantDashboard.page}
                component={IFrameAnalytics}
              />
              <SearchRoute
                path={urls.recordNotFound(':id', ':vertical' as Vertical)}
                component={RecordNotFoundPage}
              />
              <Route path={urls.login}>
                <LoginPage />
              </Route>
              <AdminRoute path={urls.admin} component={Admin} />
              <Redirect exact from="/" to={urls.search} />
              <Route path="/500" component={ServerErrorPage} />
              <SearchRoute component={NotFoundPage} />
            </Switch>
          </Suspense>
        </GlobalScaffold>
      </ThemeProvider>
    </ReduxProvider>
  );
};

export default App;
