import {
  FormFlowActionTypes,
  InitializeFormFlow,
  InitializeFormFlowPayload,
  InstantiateSection,
  UpdateActiveSection,
  UpdateActiveSectionPayload,
  ResetFormFlow,
  GetPortfolioSalesMatchesAction,
  GetPortfolioSalesMatchesSuccessAction,
} from 'connected/FormFlow/types';
import { LeaseRecord, SaleRecord } from 'pages/Details/types';

export const setFormFlowSection = (
  payload: UpdateActiveSectionPayload,
): UpdateActiveSection => ({
  type: FormFlowActionTypes.SET_ACTIVE_SECTION,
  payload,
});

export const getPortfolioSalesMatches = (): GetPortfolioSalesMatchesAction => ({
  type: FormFlowActionTypes.GET_PORTFOLIO_MATCHING_RECORDS,
});

export const initializeFormFlow = (
  payload: InitializeFormFlowPayload,
): InitializeFormFlow => ({
  type: FormFlowActionTypes.INITIALIZE_FORM_BUILDER,
  payload,
});

export const getPortfolioSalesMatchesSuccess = (payload: {
  sales: SaleRecord[];
  leases?: LeaseRecord[];
}): GetPortfolioSalesMatchesSuccessAction => ({
  type: FormFlowActionTypes.GET_PORTFOLIO_MATCHING_RECORDS_SUCCESS,
  payload,
});

export const instantiateSection = (): InstantiateSection => ({
  type: FormFlowActionTypes.INSTANTIATE_SECTION,
});

export const resetFormFlow = (): ResetFormFlow => ({
  type: FormFlowActionTypes.RESET_FORM_BUILDER,
});
