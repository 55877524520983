import React, { MouseEvent } from 'react';
import styled from 'styled-components/macro';
import { lighten, hideVisually } from 'polished';

import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import { DOCUMENT_UPLOADER_COPY } from '../constants';

export const FileItemLi = styled.li`
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  background: ${(props) => lighten(0.8, props.theme.colorPalette.branding.ink)};
  padding: 16px;
`;

export const FileItemHeader = styled.header`
  margin-bottom: ${(props) => props.theme.spacing.small};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FileItemHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: 700;
  word-break: break-word;

  span {
    font-weight: 300;
  }
`;

const CancelIcon = styled(Cancel)`
  height: 20px;
  width: 20px;
  fill: currentColor;
`;

const RemoveButton = styled.button`
  margin-left: ${(props) => props.theme.spacing.small};
  padding: 0;

  span {
    ${hideVisually()}
  }

  &:hover svg,
  &:focus svg {
    fill: ${(props) => props.theme.colorPalette.secondary.red};
  }
`;

type RemoveItemButtonProps = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const RemoveItemButton = ({ onClick }: RemoveItemButtonProps) => (
  <RemoveButton type="button" onClick={onClick}>
    <span>{DOCUMENT_UPLOADER_COPY.removeItem}</span>
    <CancelIcon />
  </RemoveButton>
);
