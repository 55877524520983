import React from 'react';
import { BoldEmphasized } from 'styled/Global';
import { DOCUMENT_UPLOADER_COPY } from './constants';
import {
  UploadCompleteStatus,
  UploadFailedNextPrompt,
} from './DocumentUploaderStyled';

const DocumentUploaderError = ({
  name,
  fileIsDuplicateError = false,
  showErrorPrompts = false,
}: {
  name: string;
  fileIsDuplicateError?: boolean;
  showErrorPrompts?: boolean;
}) => (
  <>
    <UploadCompleteStatus>
      <BoldEmphasized>{name}</BoldEmphasized>{' '}
      {fileIsDuplicateError
        ? DOCUMENT_UPLOADER_COPY.uploadFailedDuplicate
        : DOCUMENT_UPLOADER_COPY.uploadFailed}
    </UploadCompleteStatus>
    {showErrorPrompts && (
      <UploadFailedNextPrompt>
        {DOCUMENT_UPLOADER_COPY.failedNextActionPrompt}
      </UploadFailedNextPrompt>
    )}
  </>
);

export default DocumentUploaderError;
