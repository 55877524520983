import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';

export const getPortfolioNameSearchQuery = (state: RootState) =>
  state.portfolioNameSelect.searchTerm;

export const getPortfolioNamesSelector = (state: RootState) =>
  state.portfolioNameSelect.results;

export const getPortfolioNamesFilteredSelector = (state: RootState) =>
  state.portfolioNameSelect.filteredResults;

export const getFilteredAndAllResultsSelector = createSelector(
  getPortfolioNamesSelector,
  getPortfolioNamesFilteredSelector,
  (allResults, filteredResults) => ({ allResults, filteredResults }),
);
