import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { DetailsActionTypes } from 'pages/Details/types';
import {
  deleteWatchRecordFailure,
  deleteWatchRecordSuccess,
} from 'store/actions/detailsActions';

type WatchRecordDeleteEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const deleteWatchRecordEndpoint = endpoints.watchRecord;

const watchRecordDeleteEpic: Epic = (
  action$,
  state$,
  dependencies: WatchRecordDeleteEpicDependencies,
) => {
  return action$.pipe(
    ofType(DetailsActionTypes.DELETE_WATCH_RECORD),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'DELETE',
          url: deleteWatchRecordEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((deleteResponse) => {
            if (deleteResponse.status > 204) {
              throw Error(`DELETE ${deleteWatchRecordEndpoint} failed`);
            }

            return of(deleteWatchRecordSuccess());
          }),
          catchError((error) => {
            return of(deleteWatchRecordFailure(error));
          }),
        );
    }),
  );
};

export default watchRecordDeleteEpic;
