import { INPUT_FORMAT_TYPE } from 'globalConstants';
import { FIELD_COMPONENTS } from '../types';
import { NUMBER_FORMATS_MATCH } from './constants';

export const convertToPercentage = (val: string | number) => {
  if (!val) return `${val}`;
  return typeof val === 'string'
    ? `${parseInt(val, 10) * 100}`
    : `${parseFloat((val * 100).toFixed(2))}`;
};

export const stripFormatValueAndHandle = (
  handleOnChange: (value: any) => void,
  isPercentage?: boolean,
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.value.length > 0) {
    let rawValue = parseInt(
      event.target.value.replace(NUMBER_FORMATS_MATCH, ''),
      10,
    );

    if (event.target.value.includes('.')) {
      rawValue = parseFloat(
        event.target.value.replace(NUMBER_FORMATS_MATCH, ''),
      );
    }

    handleOnChange(
      isPercentage ? parseFloat((rawValue / 100).toFixed(4)) : rawValue,
    );
  } else {
    handleOnChange(event.target.value);
  }
};

export const getGroupProps = (
  isCountry: boolean,
  groupId?: string,
  name?: string,
) => {
  if (isCountry) {
    return { id: groupId };
  }

  if (groupId) {
    return { groupId, id: name };
  }

  return { id: name };
};

export const getDefaultValue = (existingValues?: any) => {
  if (!existingValues || existingValues !== '') {
    return existingValues;
  }

  return null;
};

export const convertValueToArray = (value: any) => {
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};

export const getCustomPropsType = (
  type: FIELD_COMPONENTS,
  decimals?: number,
) => {
  if (type === FIELD_COMPONENTS.NUMERIC_INPUT) {
    return {
      componentType: FIELD_COMPONENTS.NUMERIC_INPUT,
      additionalProps: {
        decimalScale: decimals,
        formatInput: INPUT_FORMAT_TYPE.NUMERIC,
      },
    };
  }

  if (type === FIELD_COMPONENTS.INTEGER) {
    return {
      componentType: FIELD_COMPONENTS.INTEGER,
      additionalProps: {
        formatInput: INPUT_FORMAT_TYPE.INTEGER,
      },
    };
  }

  if (type === FIELD_COMPONENTS.MONTH_INPUT) {
    return {
      componentType: FIELD_COMPONENTS.MONTH_INPUT,
      additionalProps: {
        formatInput: INPUT_FORMAT_TYPE.MONTH,
      },
    };
  }

  if (type === FIELD_COMPONENTS.YEAR_INPUT) {
    return {
      componentType: FIELD_COMPONENTS.YEAR_INPUT,
      additionalProps: {
        formatInput: INPUT_FORMAT_TYPE.YEAR,
      },
    };
  }

  if (type === FIELD_COMPONENTS.DECIMAL_PRICE_INPUT) {
    return {
      componentType: FIELD_COMPONENTS.DECIMAL_PRICE_INPUT,
      additionalProps: {
        formatInput: INPUT_FORMAT_TYPE.CURRENCY,
        decimalScale: 2,
      },
    };
  }

  if (type === FIELD_COMPONENTS.PRICE_INPUT) {
    return {
      componentType: FIELD_COMPONENTS.PRICE_INPUT,
      additionalProps: {
        formatInput: INPUT_FORMAT_TYPE.CURRENCY,
      },
    };
  }

  if (type === FIELD_COMPONENTS.SQUARE_FT_INPUT) {
    return {
      componentType: FIELD_COMPONENTS.SQUARE_FT_INPUT,
      additionalProps: {
        formatInput: INPUT_FORMAT_TYPE.SQUARE_FT,
      },
    };
  }

  if (type === FIELD_COMPONENTS.PRICE_PER_SQ_FT_INPUT) {
    return {
      componentType: FIELD_COMPONENTS.PRICE_PER_SQ_FT_INPUT,
      additionalProps: {
        formatInput: INPUT_FORMAT_TYPE.PRICE_PER_SQ_FT,
        decimalScale: 2,
      },
    };
  }

  return {
    componentType: FIELD_COMPONENTS.TEXT_INPUT,
    additionalProps: {},
  };
};
