import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import SortPaginationTable from 'components/Table/SortPaginationTable';
import { TableRowProps } from 'components/Table/types';
import {
  ADMIN_STATISTICS_HEADERS,
  ADMIN_STATISTICS_PAGE_COPY,
} from './constants';
import { NotFoundStyled } from './StatisticsPanelTableStyled';
import { Statistic } from './types';

const NotFound = () => (
  <NotFoundStyled>{ADMIN_STATISTICS_PAGE_COPY.notFound}</NotFoundStyled>
);

export const mapStatisticsToTableRows = (
  statistics: Statistic[],
): TableRowProps[] => {
  return (
    statistics &&
    statistics.map((statistic) => {
      return {
        id: {
          value: uuid(),
        },
        key: {
          value:
            ADMIN_STATISTICS_PAGE_COPY.rowTitle[statistic.key] ?? statistic.key,
        },
        sevenDays: {
          value: statistic.sevenDays,
        },
        thirtyOneDays: {
          value: statistic.thirtyOneDays,
        },
        allTime: {
          value: statistic.allTime,
        },
      };
    })
  );
};

type Props = {
  statistics?: Statistic[];
};

const AdminUsersPanelTable = ({ statistics }: Props) => {
  const statisticsDataRows = useMemo(() => {
    if (!statistics || statistics.length === 0) return [];
    return mapStatisticsToTableRows(statistics);
  }, [statistics]);

  if (!statistics || statistics.length === 0) return <NotFound />;

  return (
    <SortPaginationTable
      tableId="statisticsTable"
      columns={9}
      rows={statisticsDataRows}
      headers={ADMIN_STATISTICS_HEADERS}
    />
  );
};

export default AdminUsersPanelTable;
