import { GlobalApiFailError } from './types';

export enum UserQueryActions {
  SAVE_USER_QUERY = 'SAVE_USER_QUERY',
  SAVE_QUERY_API_SUCCESS = 'SAVE_QUERY_API_SUCCESS',
  SAVE_QUERY_API_FAIL = 'SAVE_QUERY_API_FAIL',

  UPDATE_USER_QUERY = 'UPDATE_USER_QUERY',
  UPDATE_QUERY_API_SUCCESS = 'UPDATE_QUERY_API_SUCCESS',
  UPDATE_QUERY_API_FAIL = 'UPDATE_QUERY_API_FAIL',

  FETCH_USER_QUERIES = 'FETCH_USER_QUERIES',
  FETCH_USER_QUERIES_SUCCESS = 'FETCH_USER_QUERIES_SUCCESS',
  FETCH_USER_QUERIES_FAIL = 'FETCH_USER_QUERIES_FAIL',

  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  DELETE_USER_QUERY = 'DELETE_USER_QUERY',
  DELETE_SCHEDULE_API_SUCCESS = 'DELETE_SCHEDULE_API_SUCCESS',
  DELETE_SCHEDULE_API_FAIL = 'DELETE_SCHEDULE_API_FAIL',
}

export type UserQueryPayloadType = {
  queryName: string;
  query: string;
  userQueryId: string;
  userId: string;
  created: string;
  updated: string;
  schedule?: string;
  scheduleStatus?: string;
};

// GET user-queries init
export type FetchUserQueriesAction = {
  type: UserQueryActions.FETCH_USER_QUERIES;
};
export const getUserQueriesAction = (): FetchUserQueriesAction => ({
  type: UserQueryActions.FETCH_USER_QUERIES,
});
// GET user-queries OK
export type FetchSuccessActionType = {
  type: UserQueryActions.FETCH_USER_QUERIES_SUCCESS;
  payload: UserQueryPayloadType[];
};
export const fetchUserQueryAPISuccess = (
  payload: UserQueryPayloadType[],
): FetchSuccessActionType => ({
  type: UserQueryActions.FETCH_USER_QUERIES_SUCCESS,
  payload,
});
// GET user-queries NOK
export type FetchFailActionType = {
  type: UserQueryActions.FETCH_USER_QUERIES_FAIL;
  payload: GlobalApiFailError;
};
export const fetchUserQueryAPIFail = (
  payload: GlobalApiFailError,
): FetchFailActionType => ({
  type: UserQueryActions.FETCH_USER_QUERIES_FAIL,
  payload,
});

// SAVE user-queries
export type UserSaveQueryPayload = {
  queryName: string;
  query: string;
};
export type SaveUserAction = {
  type: UserQueryActions.SAVE_USER_QUERY;
  payload: UserSaveQueryPayload;
};
export const saveUserQuery = (
  payload: UserSaveQueryPayload,
): SaveUserAction => ({
  type: UserQueryActions.SAVE_USER_QUERY,
  payload,
});
export const saveUserQueryAPISuccess = () => ({
  type: UserQueryActions.SAVE_QUERY_API_SUCCESS,
});
export const saveUserQueryAPIFail = (payload: GlobalApiFailError) => ({
  type: UserQueryActions.SAVE_QUERY_API_FAIL,
  payload,
});

// UPDATE user-queries
export type UpdateUserQueryPayload = {
  userQueryId: string;
  queryName: string;
  query: string;
};
export type UpdateUserQueryAction = {
  type: UserQueryActions.UPDATE_USER_QUERY;
  payload: UpdateUserQueryPayload;
};
export const updateUserQuery = (
  payload: UpdateUserQueryPayload,
): UpdateUserQueryAction => ({
  type: UserQueryActions.UPDATE_USER_QUERY,
  payload,
});
export const updateUserQueryAPISuccess = () => ({
  type: UserQueryActions.UPDATE_QUERY_API_SUCCESS,
});
export const updateUserQueryAPIFail = (payload: GlobalApiFailError) => ({
  type: UserQueryActions.UPDATE_QUERY_API_FAIL,
  payload,
});

export type DeleteSchedulePayload = {
  scheduledQueryId: string;
};

export type DeleteUserQueryPayload = {
  userQueryId: string;
};

export type UserQueryDeleteAction = {
  type: UserQueryActions.DELETE_USER_QUERY;
  payload: DeleteUserQueryPayload;
};

export type ScheduleDeleteAction = {
  type: UserQueryActions.DELETE_SCHEDULE;
  payload: DeleteSchedulePayload;
};

export type ScheduleDeleteApiFailAction = {
  type: UserQueryActions.DELETE_SCHEDULE_API_FAIL;
  payload: GlobalApiFailError;
};

export type ScheduleDeleteSuccessAction = {
  type: UserQueryActions.DELETE_SCHEDULE_API_SUCCESS;
};

export const userQueryDelete = (payload: DeleteUserQueryPayload) => {
  const action: UserQueryDeleteAction = {
    type: UserQueryActions.DELETE_USER_QUERY,
    payload,
  };

  return action;
};

export const scheduleDelete = (payload: DeleteSchedulePayload) => {
  const action: ScheduleDeleteAction = {
    type: UserQueryActions.DELETE_SCHEDULE,
    payload,
  };

  return action;
};

export const scheduleDeleteApiFail = (
  payload: GlobalApiFailError,
): ScheduleDeleteApiFailAction => ({
  type: UserQueryActions.DELETE_SCHEDULE_API_FAIL,
  payload,
});

export const scheduleDeleteApiSuccess = (): ScheduleDeleteSuccessAction => ({
  type: UserQueryActions.DELETE_SCHEDULE_API_SUCCESS,
});
