import { Button } from 'components/CallToActions/Button/Button';
import React from 'react';
import styled from 'styled-components/macro';
import { QUICK_SEARCH_COPY } from '../constants';

const ShowMoreResultsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface ShowMoreResultsButtonProps {
  onClick: () => void;
}

const ShowMoreResultsButton: React.FC<ShowMoreResultsButtonProps> = ({
  onClick,
}) => {
  return (
    <ShowMoreResultsButtonContainer>
      <Button onClick={onClick}>
        {QUICK_SEARCH_COPY.showMoreResultsButtonText}
      </Button>
    </ShowMoreResultsButtonContainer>
  );
};

export default ShowMoreResultsButton;
