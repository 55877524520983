import {
  UserQueryPayloadType,
  FetchUserQueriesAction,
  FetchSuccessActionType,
  FetchFailActionType,
  SaveUserAction,
  UpdateUserQueryAction,
  UserQueryActions,
  UserQueryDeleteAction,
  ScheduleDeleteAction,
  ScheduleDeleteApiFailAction,
  ScheduleDeleteSuccessAction,
} from 'store/actions/userQueriesActions';

export interface UserQueryDeleteState {
  userQueryId: string;
  isDeleting: boolean;
  error?: string;
}

export interface ScheduledQueryDeleteState {
  scheduledQueryId: string;
  isDeleting: boolean;
  error?: string;
}

export type UserQueriesPayload = {
  isFetching: boolean;
  hasError: boolean;
  error?: string;
  results: UserQueryPayloadType[];
  userQueryToDelete: UserQueryDeleteState;
  scheduleQueryToDelete: ScheduledQueryDeleteState;
};

export const initialState: UserQueriesPayload = {
  isFetching: false,
  hasError: false,
  error: '',
  results: [],
  userQueryToDelete: {
    userQueryId: '',
    isDeleting: false,
    error: '',
  },
  scheduleQueryToDelete: {
    scheduledQueryId: '',
    isDeleting: false,
    error: '',
  },
};

const userQueriesReducer = (
  state = initialState,
  action:
    | SaveUserAction
    | UpdateUserQueryAction
    | FetchUserQueriesAction
    | FetchSuccessActionType
    | UserQueryDeleteAction
    | ScheduleDeleteAction
    | ScheduleDeleteApiFailAction
    | ScheduleDeleteSuccessAction
    | FetchFailActionType,
) => {
  switch (action.type) {
    case UserQueryActions.FETCH_USER_QUERIES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UserQueryActions.FETCH_USER_QUERIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    }

    case UserQueryActions.DELETE_SCHEDULE: {
      return {
        ...state,
        scheduleQueryToDelete: {
          ...state.scheduleQueryToDelete,
          isDeleting: true,
        },
      };
    }

    case UserQueryActions.DELETE_USER_QUERY: {
      return {
        ...state,
        userQueryToDelete: {
          ...state.userQueryToDelete,
          isDeleting: true,
        },
      };
    }

    case UserQueryActions.DELETE_SCHEDULE_API_FAIL: {
      return {
        ...state,
        scheduleQueryToDelete: {
          ...state.scheduleQueryToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
        userQueryToDelete: {
          ...state.userQueryToDelete,
          isDeleting: false,
          error: action.payload.error,
        },
      };
    }

    case UserQueryActions.DELETE_SCHEDULE_API_SUCCESS: {
      return {
        ...state,
        scheduleQueryToDelete: {
          ...state.scheduleQueryToDelete,
          isDeleting: false,
        },
        userQueryToDelete: {
          ...state.userQueryToDelete,
          isDeleting: false,
        },
      };
    }

    default:
      return state;
  }
};

export default userQueriesReducer;
