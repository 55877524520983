import React, { useState, useEffect } from 'react';
import { CloseButton } from 'components/CallToActions/CloseButton/CloseButton';
import { CloseButtonWrapper } from '../QuickSearchStyled';

export type CloseButtonWithFadeProps = {
  onClick: () => void;
};

const CloseButtonWithFade = (props: CloseButtonWithFadeProps) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsHidden(false), 500);
  }, []);

  return isHidden ? null : (
    <CloseButtonWrapper>
      <CloseButton {...props} />
    </CloseButtonWrapper>
  );
};

export default CloseButtonWithFade;
