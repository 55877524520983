import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { DetailsActionTypes } from 'pages/Details/types';
import {
  addWatchRecordFailure,
  addWatchRecordSuccess,
} from 'store/actions/detailsActions';

type EpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const addWatchRecordEndpoint = endpoints.watchRecord;

const watchRecordAddEpic: Epic = (
  action$,
  state$,
  dependencies: EpicDependencies,
) => {
  return action$.pipe(
    ofType(DetailsActionTypes.ADD_WATCH_RECORD),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'POST',
          url: addWatchRecordEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((response) => {
            if (response.status > 204) {
              throw Error(`POST ${addWatchRecordEndpoint} failed`);
            }

            return of(addWatchRecordSuccess());
          }),

          catchError((error) => {
            return of(addWatchRecordFailure(error));
          }),
        );
    }),
  );
};

export default watchRecordAddEpic;
