import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType, Epic } from 'redux-observable';

import { UnauthenticatedRequestObservable } from 'apis/request';

import { LoginActionTypes } from 'pages/Login/types';
import { authLoginSuccess, authError } from 'store/actions/auth';

import { endpoints } from 'globalConstants';
import { parseFeaturesFromQueryString } from 'feature_flags/util';

type LoginEpicDependencies = {
  request: UnauthenticatedRequestObservable;
};

const loginEpic: Epic = (action$, _, { request }: LoginEpicDependencies) => {
  return action$.pipe(
    ofType(LoginActionTypes.LOGIN),
    mergeMap((action) =>
      request({
        withCredentials: true,
        url: endpoints.auth.login,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          code: action.payload.code,
        },
      }).pipe(
        map(({ response }) => {
          const qs = action.payload.destination?.split('?').pop();
          const parsedFeatures = parseFeaturesFromQueryString(qs);

          return {
            destination: action.payload.destination,
            state: {
              user: response.user,
              features: parsedFeatures || response.features,
            },
          };
        }),
      ),
    ),
    map((payload: Parameters<typeof authLoginSuccess>[0]) => {
      return authLoginSuccess(payload);
    }),
    catchError((error) => {
      return of(authError(error.message));
    }),
  );
};

export default loginEpic;
