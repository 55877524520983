import { GlobalApiFailError } from './types';

export enum ScheduleTeamQueryActions {
  SCHEDULE_TEAM_QUERY = 'SCHEDULE_TEAM_QUERY',
  SCHEDULE_TEAM_QUERY_SUCCESS = 'SCHEDULE_TEAM_QUERY_SUCCESS',
  SCHEDULE_TEAM_QUERY_FAIL = 'SCHEDULE_TEAM_QUERY_FAIL',
}

export type ScheduleTeamQueryPayloadType = {
  usersIds: string;
  userQueryId: string;
  repeatFrequency: string;
  trigger: string;
  hourToSend: number;
  endDate: string;
};

export type ScheduleTeamQuerySuccessPayloadType = {
  successIds: string[];
  failedIds: string[];
};

export type ScheduleTeamQueryAction = {
  type: ScheduleTeamQueryActions.SCHEDULE_TEAM_QUERY;
  payload: ScheduleTeamQueryPayloadType;
};

export type ScheduleTeamQuerySuccessAction = {
  type: ScheduleTeamQueryActions.SCHEDULE_TEAM_QUERY_SUCCESS;
  payload: ScheduleTeamQuerySuccessPayloadType;
};

export const scheduleTeamQuery = (
  payload: ScheduleTeamQueryPayloadType,
): ScheduleTeamQueryAction => ({
  type: ScheduleTeamQueryActions.SCHEDULE_TEAM_QUERY,
  payload,
});
export const scheduleTeamQuerySuccess = (
  payload: ScheduleTeamQuerySuccessPayloadType,
) => ({
  type: ScheduleTeamQueryActions.SCHEDULE_TEAM_QUERY_SUCCESS,
  payload,
});
export const scheduleTeamQueryFail = (payload: GlobalApiFailError) => ({
  type: ScheduleTeamQueryActions.SCHEDULE_TEAM_QUERY_FAIL,
  payload,
});
