import { GlobalApiFailError } from 'store/actions/types';

import {
  CompanyParentAction,
  CompanyParentActionTypes,
  CompanyParentApiFailAction,
  CompanyParentApiSuccessAction,
  CompanyParentResetAction,
  CompanyParentResult,
  CompanyParentsFormPayload,
} from 'connected/EditModals/Company/types';

export const companyParentEdit = (
  payload: CompanyParentsFormPayload,
): CompanyParentAction => ({
  type: CompanyParentActionTypes.COMPANY_PARENT_EDIT,
  payload,
});

export const companyParentApiSuccess = (
  payload: CompanyParentResult,
): CompanyParentApiSuccessAction => ({
  type: CompanyParentActionTypes.COMPANY_PARENT_API_SUCCESS,
  payload,
});

export const companyParentApiFail = (
  payload: GlobalApiFailError,
): CompanyParentApiFailAction => ({
  type: CompanyParentActionTypes.COMPANY_PARENT_API_FAIL,
  payload,
});

export const companyParentReset = (): CompanyParentResetAction => ({
  type: CompanyParentActionTypes.COMPANY_PARENT_RESET,
});
