import { of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';

import { endpoints } from 'globalConstants';
import {
  AthenaUserActionTypes,
  saveAthenaUserAPIFail,
  saveAthenaUserAPISuccess,
} from 'store/actions/athenaUserActions';

type AthenaUserEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const athenaUserPost: Epic = (
  action$,
  state$,
  dependencies: AthenaUserEpicDependencies,
) => {
  return action$.pipe(
    ofType(AthenaUserActionTypes.SAVE_ATHENA_USER_VIEWED_RELEASE_NOTES_VERSION),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'POST',
          url: endpoints.athenaUserPostVersion,
          body: action.payload,
        })()
        .pipe(
          map((ajaxResponse) => {
            if (ajaxResponse.status !== 201) {
              throw new Error(`error loading`);
            }

            return saveAthenaUserAPISuccess();
          }),
          catchError((error) => {
            return of(saveAthenaUserAPIFail(error));
          }),
        );
    }),
  );
};

export default athenaUserPost;
