import styled from 'styled-components/macro';
import { ReactComponent as Build } from 'assets/icons/build.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';

import { ReactComponent as More } from 'assets/icons/more.svg';

export const Menu = styled.div`
  position: absolute;
  top: calc(100% - 5px);
  right: 0;
  min-width: calc(220px + 26px);
  background: ${(props) => props.theme.colorPalette.branding.white};
  z-index: 1;
  border: 1px solid ${(props) => props.theme.colorPalette.branding.ink};
`;

export const ActionsContainer = styled.div`
  position: relative;
  display: flex;
`;

export const MenuButton = styled.button`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: 800;
  color: ${(props) => props.theme.colorPalette.text.dark};
  padding: 0 20px 0 25px;
  position: relative;
  margin-left: 12px;
`;

export const InlineMenu = styled(Menu)`
  min-width: 140px;
`;

export const MenuListItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const BuildIcon = styled(Build)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const ArrowDownIcon = styled(ArrowDown)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

export const ArrowUpIcon = styled(ArrowUp)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

const getFill = (theme: any, isActive?: boolean) => {
  if (isActive) {
    return theme.secondary.midGrey;
  }
  return theme.branding.ink;
};
export const MoreIcon = styled(More)``;

export const InlineMenuButton = styled(MenuButton)<{
  isActive?: boolean;
}>`
  display: flex;

  svg {
    fill: ${(props) => getFill(props.theme.colorPalette, props.isActive)};
  }
`;
