import React from 'react';
import {
  ExternalLinkStyled,
  CRMIcon,
} from 'connected/BasePanel/BasePanelStyled';
import { urls } from 'globalConstants';
import { CRMLinkProps } from './types';

const CRMLink: React.FC<CRMLinkProps> = ({ crmId }) => (
  <ExternalLinkStyled
    href={`${urls.crm}${crmId}`}
    rel="noopener noreferrer"
    target="_blank"
    title="view CRM record"
  >
    <CRMIcon />
  </ExternalLinkStyled>
);

export default CRMLink;
