import React from 'react';
import {
  MatchingRecordItem,
  LineItem,
  ItemTitle,
  ItemBody,
  StyledLink,
  EditIcon,
  StyledInputWrapper,
  LinkText,
  RadioLabel,
  StyledSelectedRadio,
  MatchingRecordList,
  MatchingRecordActions,
  MatchingRecordWrapper,
  MatchingRecordNotice,
  WarningIcon,
  StyledMatchingRecord,
} from './MatchingRecordStyled';
import {
  RowProps,
  EditProps,
  SelectProps,
  MatchingRecordProps,
  ItemProps,
  NoticeProps,
} from './types';

const RecordItem = ({ title, body, isWide }: RowProps) => {
  const content =
    typeof body === 'string'
      ? body
      : body.map((line: string) => <LineItem key={line}>{line}</LineItem>);

  return (
    <MatchingRecordItem isWide={isWide}>
      <ItemTitle>{title}</ItemTitle>
      <ItemBody>{content}</ItemBody>
    </MatchingRecordItem>
  );
};

const EditAction = ({ link, text, onEditLinkClick }: EditProps) => (
  <StyledLink as="button" type="button" onClick={() => onEditLinkClick(link)}>
    <EditIcon />
    <LinkText>{text}</LinkText>
  </StyledLink>
);

const SelectAction = ({ name, value, isSelected }: SelectProps) => {
  return (
    <StyledInputWrapper>
      <RadioLabel htmlFor={name}>
        <StyledSelectedRadio
          id={name}
          name={name}
          value={value}
          readOnly
          checked={isSelected}
          type="radio"
        />
      </RadioLabel>
    </StyledInputWrapper>
  );
};

export const Notice = ({
  noticeText,
  width = 24,
  height = 24,
}: NoticeProps) => (
  <MatchingRecordNotice>
    <WarningIcon width={width} height={height} />
    <p>{noticeText}</p>
  </MatchingRecordNotice>
);

const MatchingRecord = ({
  matchingRecordItems,
  columnsToSpan,
  isEditable,
  isSelectable,
  showNotice,
  onEditLinkClick,
}: MatchingRecordProps) => {
  const record = (
    <>
      <MatchingRecordList columns={columnsToSpan}>
        {matchingRecordItems.map((item: ItemProps) => (
          <RecordItem
            key={item.title}
            isWide={item.isWide || false}
            title={item.title}
            body={item.body}
          />
        ))}
      </MatchingRecordList>
      <MatchingRecordActions>
        {isEditable && onEditLinkClick && (
          <EditAction
            link={isEditable.link}
            text={isEditable.text}
            onEditLinkClick={onEditLinkClick}
          />
        )}
        {isSelectable && (
          <SelectAction
            name={isSelectable.name}
            value={isSelectable.value}
            onSelect={isSelectable.onSelect}
            isSelected={isSelectable.isSelected || false}
          />
        )}
      </MatchingRecordActions>
    </>
  );

  return isSelectable ? (
    <MatchingRecordWrapper
      showNotice={!!showNotice}
      role="button"
      tabIndex={0}
      isSelectable
      onClick={() => isSelectable.onSelect(isSelectable.value)}
      onKeyPress={() => isSelectable.onSelect(isSelectable.value)}
    >
      {showNotice && <Notice noticeText={showNotice} />}
      <StyledMatchingRecord> {record}</StyledMatchingRecord>
    </MatchingRecordWrapper>
  ) : (
    <MatchingRecordWrapper showNotice={!!showNotice}>
      {showNotice && <Notice noticeText={showNotice} />}
      <StyledMatchingRecord>{record}</StyledMatchingRecord>
    </MatchingRecordWrapper>
  );
};

export default MatchingRecord;
