export const DATA_ITEM_COPY = {
  annualRent: {
    key: 'totalRent',
    calculated: {
      id: 'calculatedTotalRent',
      title: 'Calculated',
      tooltip:
        'This value has been calculated from the lease’s <strong>Area</strong> and <strong>Rent per sq ft</strong> data.',
    },
    confirmed: {
      id: 'confirmedTotalRent',
      title: 'Confirmed',
      tooltip: 'This value has been provided by Leverton.',
    },
  },
};
