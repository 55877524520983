import React, { ChangeEvent, KeyboardEvent } from 'react';

import { KEYBOARD_EVENT_VALUES } from 'globalConstants';
import useFieldInputValidation from 'hooks/useFieldInputValidation';
import RequiredFlag from 'components/Forms/RequiredFlag';
import FieldValidation from 'components/Forms/FieldValidation';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import { CheckboxType } from './types';
import {
  CheckboxWrapper,
  GroupPrompt,
  GroupSubtitle,
  StyledCheckbox,
  StyledCustomCheckbox,
  StyledLabel,
  TickIcon,
} from './CheckboxStyled';

const Checkbox = ({
  label,
  name,
  onChange,
  onKeyPress,
  value,
  isChecked,
  validation,
  align,
  prompt,
  subtitle,
  verticalAlign,
  disabled,
}: CheckboxType) => {
  const { hasErrors } = useFieldInputValidation(
    value,
    FIELD_COMPONENTS.CHECKBOX,
    validation,
  );
  const handleKeyEvent = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (
      event.keyCode === KEYBOARD_EVENT_VALUES.ENTER ||
      event.keyCode === KEYBOARD_EVENT_VALUES.SPACE
    ) {
      onKeyPress(value, !isChecked);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event, !isChecked);
  };

  return (
    <CheckboxWrapper verticalAlign={verticalAlign}>
      {prompt && <GroupPrompt>{prompt}</GroupPrompt>}
      {subtitle && <GroupSubtitle>{subtitle}</GroupSubtitle>}
      <StyledLabel
        isChecked={isChecked || false}
        align={align}
        onKeyDown={handleKeyEvent}
        htmlFor={name}
        role="button"
        tabIndex={0}
        isDisabled={disabled || false}
      >
        <StyledCheckbox
          name={name}
          type="checkbox"
          onChange={handleOnChange}
          value={value}
          id={name}
          checked={isChecked}
          disabled={disabled}
        />
        <StyledCustomCheckbox>
          {isChecked && <TickIcon disabled={disabled || false} />}
        </StyledCustomCheckbox>
        {label}
        {validation?.isRequired && <RequiredFlag />}
      </StyledLabel>{' '}
      <FieldValidation hasErrors={hasErrors} validation={validation} />
    </CheckboxWrapper>
  );
};

export default Checkbox;
