import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';

import { globalModalHide } from 'store/actions/globalModalActions';

import { RelatedPartyActionTypes } from 'connected/RelatedPartiesPanel/types';
import {
  relatedPartyDeleteApiFail,
  relatedPartyDeleteApiSuccess,
} from 'store/actions/relatedPartyActions';

type RelatedPartyDeleteEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const relatedPartyDeleteEndpoint = endpoints.deleteEntity;

const relatedPartyDeleteEpic: Epic = (
  action$,
  state$,
  dependencies: RelatedPartyDeleteEpicDependencies,
) => {
  return action$.pipe(
    ofType(RelatedPartyActionTypes.DELETE_RELATED_PARTY),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'DELETE',
          url: relatedPartyDeleteEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((deleteResponse) => {
            if (deleteResponse.status > 204) {
              throw Error(`DELETE ${relatedPartyDeleteEndpoint} failed`);
            }

            return of(relatedPartyDeleteApiSuccess(), globalModalHide());
          }),
          catchError((error) => {
            return of(relatedPartyDeleteApiFail(error));
          }),
        );
    }),
  );
};

export default relatedPartyDeleteEpic;
