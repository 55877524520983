import React, { useState } from 'react';
import { parse } from 'date-fns';
import _ from 'lodash';
import { PAGINATION_NAV } from 'components/Pagination/constants';
import {
  SortConfig,
  SortDirections,
  SortTypes,
  TableConfigProps,
  TableRowProps,
} from './types';
import { noPagination } from './constants';

export const santizeStr = (str: string, type: SortTypes) => {
  if (type === 'numeric') {
    if (typeof str === 'string') {
      const strippedStr = str.replace(/[^\d.]+/g, '');
      const numericStr = parseFloat(strippedStr);
      return Number.isNaN(numericStr) ? 0 : numericStr;
    }
    return str;
  }

  return str && typeof str === 'string' ? str.toLowerCase() : '';
};

const getFormattedValues = (type: SortTypes, val: any) => {
  if (type === SortTypes.date) {
    return parse(val, 'dd/MM/yyyy', new Date());
  }

  if (type === SortTypes.dateString) {
    return parse(val, 'd MMMM yyyy', new Date());
  }

  if (Array.isArray(val)) {
    return val.map((item) => santizeStr(item, type)).join(', ');
  }

  return santizeStr(val, type);
};

const getValue = (item: TableRowProps) => {
  if (!item) {
    return null;
  }
  if (item.link && item.label) {
    return item.link.label;
  }

  if (item.subValue) {
    return item.subValue;
  }

  return item.value;
};

const getSortOrder = (
  aFormatted: string | number | Date,
  bFormatted: string | number | Date,
  sortConfig: SortConfig,
) => {
  if (aFormatted === '') {
    return 1;
  }

  if (bFormatted === '') {
    return -1;
  }

  if (aFormatted < bFormatted) {
    return sortConfig.direction === SortDirections.ASC ? -1 : 1;
  }

  if (aFormatted > bFormatted) {
    return sortConfig.direction === SortDirections.ASC ? 1 : -1;
  }

  return 0;
};

const sortItems = (items: TableRowProps[], sortConfig: SortConfig) => {
  return items.sort((a, b) => {
    if (!sortConfig || !sortConfig.key || !sortConfig.type) return 0;

    const aItem = _.get(a, sortConfig.key);
    const bItem = _.get(b, sortConfig.key);
    const aVal = getValue(aItem);
    const bVal = getValue(bItem);
    const aFormatted = getFormattedValues(sortConfig.type, aVal);
    const bFormatted = getFormattedValues(sortConfig.type, bVal);

    return getSortOrder(aFormatted, bFormatted, sortConfig);
  });
};

const getDirection = (key: string, sortConfig?: SortConfig) => {
  if (!sortConfig) {
    return SortDirections.ASC;
  }

  if (sortConfig.key === key && sortConfig.direction === SortDirections.ASC) {
    return SortDirections.DESC;
  }

  return SortDirections.ASC;
};

const getPage = (nav: PAGINATION_NAV, page: number, lastPage: number) => {
  let currentPage = page + 1;
  const onLastPage = currentPage > lastPage;
  const onFirstPage = currentPage === 0;

  if (nav === PAGINATION_NAV.LAST) {
    return lastPage;
  }

  if (nav === PAGINATION_NAV.NEXT && onLastPage) {
    return lastPage;
  }

  if (nav === PAGINATION_NAV.NEXT && !onLastPage) {
    return currentPage;
  }

  if (nav === PAGINATION_NAV.PREV && !onFirstPage) {
    currentPage = page - 1;
    return currentPage;
  }

  return 1;
};

export const useRefinedData = (
  items: TableRowProps[],
  paginationConfig: {
    limit: string;
    page: number;
    label: string;
    total?: number;
    totalPages?: number;
  },
  config?: SortConfig,
  tableItemsConfig?: TableConfigProps,
  useDefault = true,
) => {
  const [sortConfig, setSortConfig] = useState(config);
  const [pagination, setPagination] = useState(paginationConfig);

  let updatedLimit = parseInt(pagination.limit, 10);

  if (pagination.limit === noPagination.limit) {
    updatedLimit = items.length;
  }

  const refinedItems = React.useMemo(() => {
    if (!useDefault) {
      return items;
    }
    const { page } = pagination;
    let refinableItems = [...items];

    if (sortConfig) {
      refinableItems = sortItems(refinableItems, sortConfig);
    }

    return refinableItems.slice((page - 1) * updatedLimit, page * updatedLimit);
  }, [items, sortConfig, pagination, updatedLimit, useDefault]);

  const sortRows = (
    key: string,
    type: SortTypes,
    updatedDirection?: SortDirections,
  ) => {
    let direction = getDirection(key, sortConfig);
    if (updatedDirection) {
      direction = updatedDirection;
    }

    setPagination({
      limit: pagination.limit,
      page: 1,
      label: pagination.label,
    });
    setSortConfig({ key, direction, type });
  };

  const updatePaginationPage = (
    nav: PAGINATION_NAV,
    limit: string,
    total: number,
  ) => {
    const { page } = pagination;
    const lastPage = Math.ceil(total / updatedLimit);

    setPagination({
      limit,
      page: getPage(nav, page, lastPage),
      label: pagination.label,
    });
  };

  const resetPaginationPage = () => {
    setPagination((prevState) => ({
      limit: prevState.limit,
      page: 1,
      label: prevState.label,
    }));
  };

  const updatePaginationLimit = (limit: string, label: string) => {
    setPagination({ limit, page: 1, label });
  };

  return {
    items: refinedItems,
    sortRows,
    sortConfig,
    updatePaginationPage,
    resetPaginationPage,
    updatePaginationLimit,
    pagination,
    updatedLimit,
    toggleChildren: tableItemsConfig?.toggleChildren,
  };
};

export const getRowId = (row: TableRowProps) => {
  const id = typeof row.id === 'object' ? row.id.value : row.id;
  // Item has no ID assigned
  // Example: Uploaded Documents don't have an ID
  if (!id) {
    return row.filename
      ? // for Documents get the document filename
        row.filename.value
      : // finally just stringify the entire thing
        JSON.stringify(`${row}`);
  }

  return `${id}`;
};

export const getRowKey = (row: TableRowProps, i?: number) => {
  const id = typeof row.id === 'object' ? row.id.value : row.id;
  // Item has no ID assigned
  // Example: Uploaded Documents don't have an ID
  if (!id) {
    return row.filename
      ? // for Documents get the document filename
        row.filename.value
      : // finally just stringify the entire thing
        JSON.stringify(`${row}${i}`);
  }

  return `${id}${i}`;
};

export const getEntity = (row: TableRowProps) => {
  return typeof row.entity === 'object' ? row.entity.value : row.entity;
};
