import styled, { css } from 'styled-components/macro';

import expand from 'assets/icons/expand-more.svg';
import { inputWrapperStyles, labelStyles, inputStyles } from 'styled/Global';

import {
  getHiddenStyles,
  getMinimizedStyles,
  getDisabledStyles,
  getErrorStyles,
} from 'styled/helpers';
import { LabelType, StyledInputProps } from './types';

const filledStyles = (palette: any, isFilled?: boolean) => {
  if (isFilled) {
    return css`
      color: ${palette.secondary.darkGrey};

      &:after {
        background-color: ${palette.secondary.midGrey};
      }
    `;
  }
  return ``;
};

export const Label = styled.label<LabelType>`
  ${labelStyles}
  pointer-events: none;
  ${(props) => getHiddenStyles(props.labelIsHidden)};
  ${(props) => getMinimizedStyles(props.labelIsMinimized)};
  padding: 0 ${(props) => props.theme.spacing.small};
`;

export const StyledSelect = styled.select<StyledInputProps>`
${inputStyles}

padding: 8px ${(props) => props.theme.spacing.default} ${(props) =>
  props.labelIsHidden ? '8px' : '0'} ${(props) => props.theme.spacing.small};
appearance: none;
position: relative;
min-width: 70px;
width: 100%;
margin: 0;
text-overflow: ellipsis;

option {
  &:disabled {
    color: ${(props) => props.theme.colorPalette.text.placeholder};
  }
}
`;

export const StyledInputField = styled.div<{
  isDisabled: boolean;
  hasErrors: boolean;
  isFilled: boolean;
}>`
${inputWrapperStyles}

${(props) => getErrorStyles(props.hasErrors)}
${(props) => getDisabledStyles(props.isDisabled)}

padding: 0;

&:hover {
  &:after {
    color: ${(props) => props.theme.colorPalette.secondary.midGrey};
  }
}

&:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  position: absolute;
  right: ${(props) => props.theme.spacing.tablet};
  pointer-events: none;
  background-color: ${(props) => props.theme.colorPalette.secondary.midGrey};
  mask-image: url(${expand});
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    right: ${(props) => props.theme.spacing.small};
  }
}
${(props) => filledStyles(props.theme.colorPalette, props.isFilled)}
`;

export const StyledPrompt = styled.div`
  margin: ${(props) => props.theme.spacing.default} 0 20px;
`;
