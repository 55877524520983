import styled, { css } from 'styled-components/macro';

import {
  inputWrapperStyles,
  minimizedLabelStyles,
  labelStyles,
  inputStyles,
} from 'styled/Global';

import { getErrorStyles, getHiddenStyles } from 'styled/helpers';
import { LabelType, StyledInputProps } from './types';

const getInlineStyles = (isInline?: boolean) => {
  if (isInline) {
    return `240px`;
  }
  return `140px`;
};

export const StyledTextareaField = styled.div<{
  hasErrors: boolean;
  isInline: boolean;
}>`
  ${inputWrapperStyles}
  height: ${(props) => getInlineStyles(props.isInline)};
  ${(props) => getErrorStyles(props.hasErrors)}
`;

const getMinimizedStyles = (isMinimized?: boolean) => {
  if (isMinimized) {
    return css`
      ${minimizedLabelStyles}
      transform: translate3d(0, -58px, 0);
    `;
  }
  return ``;
};

export const StyledLabel = styled.label<LabelType>`
  ${(props) => getHiddenStyles(props.labelIsHidden)};
  ${labelStyles}
  transform: translate3d(0, -50px, 0);
  ${(props) => getMinimizedStyles(props.labelIsMinimized)};
`;

export const StyledTextarea = styled.textarea<StyledInputProps>`
  ${inputStyles}
  padding: ${(props) => props.theme.spacing.small} 0;
  resize: none;

  ::placeholder {
    color: ${(props) => props.theme.colorPalette.text.placeholder};
  }

  ${(props) => props.customStyles}

  + label {
    top: 60px;
  }

  &:focus {
    + label {
      ${minimizedLabelStyles}
      transform: translate3d(0, -58px, 0);
    }
  }
`;
