export const ADMIN_STATISTICS_PAGE_COPY = {
  title: 'Usage Statistics',
  colTitleEvent: 'Event',
  colTitle7days: 'Last 7 days',
  colTitle31days: 'Last 31 days',
  colTitleAllItems: 'All Time',
  rowTitle: {
    uniqueActiveUsers: 'Unique Active Users',
    totalLogins: 'Total Logins',
    newSaleFlowsStarted: 'New Sale Flows Started',
    newSaleFlowsCompleted: 'New Sale Flows Completed',
    newLeaseFlowsStarted: 'New Lease Flows Started',
    newLeaseFlowsCompleted: 'New Lease Flows Completed',
    queriesExecuted: 'Queries Executed',
    exportsGenerated: 'Exports Generated',
    buildingsFoundinQuicksearch: 'Buildings Found in Quicksearch',
    companiesFoundinQuicksearch: 'Companies Found in Quicksearch',
  } as Record<string, any>,
  notFound: 'No results found.',
};

export const ADMIN_STATISTICS_HEADERS = [
  {
    title: ADMIN_STATISTICS_PAGE_COPY.colTitleEvent,
    isWide: true,
  },
  {
    title: ADMIN_STATISTICS_PAGE_COPY.colTitle7days,
  },
  {
    title: ADMIN_STATISTICS_PAGE_COPY.colTitle31days,
  },
  {
    title: ADMIN_STATISTICS_PAGE_COPY.colTitleAllItems,
  },
];
