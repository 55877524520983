import React from 'react';

import { BoldEmphasized } from 'styled/Global';
import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Error } from 'assets/icons/telescope.svg';

import {
  NoResultsContainer,
  NoResultsHeader,
  NoResultsText,
} from './NoResultsStyled';
import { QUICK_SEARCH_COPY } from '../constants';

type NoResultsProps = {
  searchTerm: string;
  error?: boolean;
};

const ErrorIcon = withIconStyles(Error);
const WarningIcon = withIconStyles(Warning);

const NoResultsWithSearchTerm = ({
  searchTerm,
}: Pick<NoResultsProps, 'searchTerm'>) => (
  <NoResultsText>
    {QUICK_SEARCH_COPY.noResultsForTerm} &apos;
    <BoldEmphasized>{searchTerm}</BoldEmphasized>&apos;.
    <br />
    {QUICK_SEARCH_COPY.noResultsAlterYourTerm}
  </NoResultsText>
);

const NoResultsHeaderWithContent = ({
  error = false,
}: Pick<NoResultsProps, 'error'>) => {
  const title = error
    ? QUICK_SEARCH_COPY.errorTitle
    : QUICK_SEARCH_COPY.noResultsTitle;
  const Icon = error ? ErrorIcon : WarningIcon;

  return (
    <NoResultsHeader>
      <Icon />
      <h3>{title}</h3>
    </NoResultsHeader>
  );
};

const ErrorText = () => (
  <NoResultsText>{QUICK_SEARCH_COPY.errorText}</NoResultsText>
);

const NoResults = ({ searchTerm, error }: NoResultsProps) => (
  <NoResultsContainer>
    <NoResultsHeaderWithContent error={error} />
    {error ? (
      <ErrorText />
    ) : (
      <NoResultsWithSearchTerm searchTerm={searchTerm} />
    )}
  </NoResultsContainer>
);

export default NoResults;
