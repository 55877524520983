import styled from 'styled-components/macro';
import { IconPropTypes } from 'components/Icon/Icon';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLast } from 'assets/icons/last-arrow.svg';
import { ReactComponent as ChevronFirst } from 'assets/icons/first-arrow.svg';

const withInlineIconStyles = (IconSVG: React.FC) => styled(IconSVG)<
  IconPropTypes
>`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  position: relative;
  height: 24px;
  width: 24px;
`;

export const PaginationButtonStyled = styled.button`
  padding: 0;

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
`;

const LastIconStyled = withInlineIconStyles(ChevronLast);
export const LastIcon = styled(LastIconStyled)``;

const FirstIconStyled = withInlineIconStyles(ChevronFirst);
export const FirstIcon = styled(FirstIconStyled)``;

const PrevIconStyled = withInlineIconStyles(ChevronLeft);
export const PrevIcon = styled(PrevIconStyled)``;

const NextIconStyled = withInlineIconStyles(ChevronRight);
export const NextIcon = styled(NextIconStyled)``;

export const PaginationStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 48px;
  align-items: center;
`;

export const PaginationNavStyled = styled.div`
  display: flex;
  margin: 0 12px;
`;

export const SortSelectStyled = styled.div`
  display: flex;
  margin: 0 12px 0 0;
  align-items: center;

  div {
    height: 38px;
    margin: 0;
  }

  select {
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

export const PaginationTextStyled = styled.p`
  margin: -4px 12px 0;
  align-self: center;
  position: relative;
`;

export const StyledLabel = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-weight: 700;
`;
