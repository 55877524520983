import { ReactNode } from 'react';

export enum PanelTheme {
  PINK = 'pink',
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
  DEFAULT = 'default',
  GREY = 'grey',
}

export type PanelWrapperProps = {
  Menu?: React.FC;
  title?: string;
  children: ReactNode;
  isPanelCollapsible?: boolean;
  isPanelExpanded?: boolean;
  useTheme?: PanelTheme;
  id: string;
  anchorId?: string;
  onPanelToggleCb?: (anchorId: string, isPanelExpanded: boolean) => void;
  containsMultiChildren?: boolean;
  count?: number;
  isExpanded?: boolean;
};

export interface PanelWrapperStyledProps {
  useTheme: PanelTheme;
}
