import compose from 'ramda/src/compose';
import styled from 'styled-components/macro';
import { desaturate, lighten, darken } from 'polished';

import { BasicButton } from 'components/CallToActions/Button/Button';
import withIconStyles from 'components/Icon/Icon';
import { ReactComponent as ToggleIcon } from 'assets/icons/import-export.svg';

export const StyledToggleIcon = styled(withIconStyles(ToggleIcon))`
  width: 1.2em;
  height: 1.2em;
  color: ${(props) =>
    compose(
      desaturate('0.5'),
      lighten('0.3'),
    )(props.theme.colorPalette.branding.ink)};
  margin-left: 10px;
`;

export const StyledToggleButton = styled(BasicButton)`
  border-radius: 3px 0 0 3px;
  border: 1px solid
    ${(props) => darken('0.2', props.theme.colorPalette.branding.yellow)};
  border-right: 0;
`;
