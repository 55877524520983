import styled from 'styled-components/macro';
import { lighten } from 'polished';

import withIconStyles from 'components/Icon/Icon';
import { LinearLoader } from 'components/LinearLoader/LinearLoader';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ModalPromptActionProps } from './types';

export const WarningIcon = styled(withIconStyles(Warning))`
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 24px;
`;

export const ActionErrorIcon = styled(withIconStyles(Error))`
  fill: ${(props) => props.theme.colorPalette.tertiary.datavizPurple};
  width: 3em;
  height: 3em;
  margin-bottom: 5px;
`;

export const ModalPromptMessage = styled.p`
  margin: 0;
  text-align: left;
`;

const getActionErrorStyled = (actionError?: boolean) => {
  if (actionError) {
    return `
    button {
      max-width: 240px;
      margin: 0 auto;
    }
    grid-template-columns: 1fr;
  `;
  }

  return `
  grid-template-columns: 1fr 1fr;`;
};

export const ModalPromptActions = styled.div<ModalPromptActionProps>`
  padding: ${(props) => props.theme.spacing.default};
  border-top: 1px solid
    ${(props) => lighten(0.5, props.theme.colorPalette.secondary.lightInk)};
  display: grid;
  column-gap: ${(props) => props.theme.spacing.default};
  ${(props) => getActionErrorStyled(props.actionError)};
`;

export const ModalPromptWrapper = styled.div`
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.default};
  line-height: 1.5;
  padding: ${(props) => props.theme.spacing.default};
  text-align: center;
`;

export const ModalPromptLinearLoader = styled(LinearLoader)`
  position: absolute;
  bottom: 0;
  height: 6px;
`;
