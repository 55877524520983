import pathOr from 'ramda/src/pathOr';

import {
  GeoJSONPoints,
  MapPolygonWithBoundary,
} from 'components/GoogleMap/types';
import { getCenterFromCoords } from 'components/GoogleMap/utils/polygon';
import {
  convertToGoogleLatLngLiteral,
  convertToGoogleLatLng,
} from 'components/GoogleMap/utils/corridor';

export const parseResponseToMapBoundaries = (
  shape: any,
  boundaryId: string,
) => {
  const geometry = pathOr(
    [],
    ['features', 0, 'geometry', 'coordinates'],
    shape,
  ) as GeoJSONPoints[][];

  const boundaryPaths = geometry.map((boundary) => {
    const path = boundary[0].map((o) => {
      return o.reverse();
    });

    return {
      path: convertToGoogleLatLng(path),
      center: getCenterFromCoords(convertToGoogleLatLngLiteral(path)),
    };
  });

  const center = getCenterFromCoords(
    boundaryPaths.map((b) => ({
      latitude: b.center.lat,
      longitude: b.center.lng,
    })),
  );

  return ({
    paths: boundaryPaths,
    center,
    boundaryId,
  } as unknown) as MapPolygonWithBoundary;
};

export const getSavillsBoundary = async (): Promise<any> => {
  const params = new URL(document.location.href).searchParams;
  const boundaryId = params.get('boundaryId');

  if (!boundaryId) return undefined;

  const response = await fetch(
    `https://boundaries.athena-savills.com/Boundaries/${boundaryId}.geojson`,
  );

  if (response.ok) {
    const boundaryResponse = await response.json();
    const shape = JSON.parse(boundaryResponse[0].to_json);

    return parseResponseToMapBoundaries(shape, boundaryId);
  }

  return undefined;
};
