import { lighten } from 'polished';
import styled, { css } from 'styled-components/macro';

const actionTooltipStyles = (isActionTooltip?: boolean) => {
  if (isActionTooltip) {
    return css`
      width: 20px;

      > button {
        margin: 0;
        padding: 0;
        display: flex;
      }
    `;
  }

  return ``;
};

export const TooltipWrapper = styled.div<{ isActionTooltip: boolean }>`
  ${(props) => actionTooltipStyles(props.isActionTooltip)}
  display: flex;
  align:items: start;
  .__react_component_tooltip {
    border-radius: 8px;
    box-shadow: ${(props) => props.theme.cardBoxShadow};
    padding: 18px 10px 10px;
    border: 0;
    max-width: 200px;
  }
`;

export const TooltipContent = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  text-align: left;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 8px;
    width: 100%;
    background: linear-gradient(
      270deg,
      ${(props) => props.theme.colorPalette.branding.yellow} 0%,
      ${(props) => lighten('0.1', props.theme.colorPalette.branding.yellow)}
        100%
    );
    left: 0;
  }
  white-space: normal;
`;

export const TooltipButton = styled.button`
  padding: 0;
  display: flex;
  align-items: start;
`;
