import styled, { css } from 'styled-components/macro';
import compose from 'ramda/src/compose';
import { lighten, desaturate } from 'polished';

export const BasicButton = styled.button`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  font-weight: 700;
  padding: 1em 2em;
  min-width: 14em;
  line-height: 1;
  min-height: 3.5em;
  background-color: ${(props) => props.theme.colorPalette.branding.yellow};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    min-width: 14em;
  }

  &:disabled {
    background-color: #dcdcdc;
    color: ${(props) =>
      compose(
        desaturate('1'),
        lighten('0.2'),
      )(props.theme.colorPalette.branding.ink)};
  }
`;

export const Button = styled(BasicButton)`
  background-image: linear-gradient(
    90deg,
    ${(props) => props.theme.colorPalette.branding.ink} 50%,
    ${(props) => props.theme.colorPalette.branding.yellow} 0
  );
  background-position: 100% 0;
  background-size: 200% auto;
  transition: background-position 0.25s, color 0.25s;

  &:focus:enabled,
  &:hover:enabled {
    background-position: -10px 0;
    color: ${(props) => props.theme.colorPalette.branding.yellow};
  }

  &:disabled {
    background-image: none;
  }
`;

export const NegativeActionButton = styled(Button)`
  border: 2px solid ${(props) => props.theme.colorPalette.secondary.lightInk};
  background-image: linear-gradient(
    90deg,
    ${(props) => props.theme.colorPalette.branding.ink} 50%,
    white 0
  );

  &:focus:enabled,
  &:hover:enabled {
    color: ${(props) => props.theme.colorPalette.text.white};
  }
`;

export const TertiaryActionButton = styled(Button)`
  color: ${(props) => props.theme.colorPalette.text.white};
  background-image: linear-gradient(
    90deg,
    ${(props) => props.theme.colorPalette.secondary.steel} 50%,
    ${(props) => props.theme.colorPalette.branding.ink} 0
  );

  &:focus:enabled,
  &:hover:enabled {
    color: ${(props) => props.theme.colorPalette.text.white};
  }
`;

export const InlineActionButton = styled.button`
  color: ${(props) => props.theme.colorPalette.branding.ink};
  text-decoration: underline;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  padding: 1em 2em;
  font-weight: bold;
  position: relative;

  &:focus:enabled,
  &:hover:enabled {
    text-decoration: none;
  }
`;

const getDisabledStyled = (disabled?: boolean) => {
  if (disabled) {
    return css`
      color: ${(props) => props.theme.colorPalette.secondary.midGrey};
      pointer: default;
    `;
  }

  return css``;
};
export const TableInlineActionButton = styled.button<{ disabled?: boolean }>`
  color: ${(props) => props.theme.colorPalette.branding.ink};
  text-decoration: underline;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.small};
  padding: 0 1em;
  font-weight: bold;
  position: relative;
  text-align: left;

  ${(props) => getDisabledStyled(props.disabled)}

  &:focus:enabled,
  &:hover:enabled {
    text-decoration: none;
  }
`;

export const InlineModalActionButton = styled(InlineActionButton)`
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
`;
