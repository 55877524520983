/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  map,
  mergeMap,
  withLatestFrom,
  catchError,
  filter,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { Action } from 'redux';

import { endpoints, Vertical } from 'globalConstants';
import { getSearchQuery } from 'store/selectors/searchQuery';
import { AuthenticatedRequestObservable } from 'apis/request';

import { DetailsActionTypes } from 'pages/Details/types';
import {
  SearchActionTypes,
  fetchSearchResultsMapPinsSuccess,
  fetchSearchResultsFail,
} from 'store/actions/searchActions';
import { SORT_PAGE_ACTION_TYPES } from 'store/actions/searchQuery';
import { ResultView } from 'connected/SearchResults/constants';

type DetailsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const searchMapPinsEpic: Epic = (
  action$,
  state$,
  { authRequest }: DetailsEpicDependencies,
) =>
  action$.pipe(
    ofType<
      Action,
      Action,
      SearchActionTypes | SORT_PAGE_ACTION_TYPES | DetailsActionTypes
    >(
      SearchActionTypes.FETCH_SEARCH_RESULTS_MAP_PINS,
      SORT_PAGE_ACTION_TYPES.UPDATE_SORT,
      DetailsActionTypes.UPDATE_DETAILS,
    ),
    withLatestFrom(state$),
    filter(([_, state]) => {
      return state.searchResults.resultView === ResultView.Map;
    }),
    mergeMap(([_, state]) => {
      const { page, count } = state.searchResults.mapPins;
      const searchQuery = getSearchQuery(state);
      const params = new URLSearchParams(searchQuery as Record<string, string>);

      params.set('page', `${page - 1}` || '0');
      params.set('pageSize', count);

      const url = `${endpoints.searchQuery}?${params.toString()}`;
      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }

          const payload = results.response.results.map((result: any) => {
            return searchQuery.vertical === Vertical.Building
              ? {
                  buildings: [
                    {
                      ...result,
                    },
                  ],
                }
              : { ...result };
          });

          return fetchSearchResultsMapPinsSuccess(
            payload,
            results.response.meta,
          );
        }),
        catchError((error) => {
          return of(fetchSearchResultsFail(error));
        }),
      );
    }),
  );

export default searchMapPinsEpic;
