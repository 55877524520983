import { ReactNode } from 'react';

export type FormSidebarProps = {
  parentSection: string;
  progressSections: any[];
  children: ReactNode;
  hideProgressSteps?: boolean;
  sidebarCardType?: SIDEBAR_CARD_TYPES;
  visibleViews: any[];
};

export enum SIDEBAR_CARD_TYPES {
  MOVE_RECORD = 'moveRecord',
}
