import { of } from 'rxjs';
import { catchError, mergeMap, concatMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints, Vertical } from 'globalConstants';

import { DetailsActionTypes } from 'pages/Details/types';
import {
  buildingsDeleteApiFail,
  detailsFetch,
} from 'store/actions/detailsActions';

type BuildingDeleteEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const buildingDeleteEndpoint = endpoints.editDetails;

const buildingDeleteEpic: Epic = (
  action$,
  state$,
  dependencies: BuildingDeleteEpicDependencies,
) => {
  return action$.pipe(
    ofType(DetailsActionTypes.DELETE_BUILDING),
    mergeMap((action) => {
      return dependencies
        .authRequest(state$, {
          method: 'PUT',
          url: buildingDeleteEndpoint,
          body: action.payload,
        })()
        .pipe(
          concatMap((deleteResponse) => {
            if (deleteResponse.status > 204) {
              throw Error(`PUT ${buildingDeleteEndpoint} failed`);
            }

            // now refetch the building and the Building Details page will redirect to the deleted placeholder page
            return of(
              detailsFetch({
                id: action.payload.id,
                vertical: Vertical.Building,
              }),
            );
          }),
          catchError((error) => {
            return of(buildingsDeleteApiFail(error));
          }),
        );
    }),
  );
};

export default buildingDeleteEpic;
