/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { lighten } from 'polished';

import { ReactComponent as Office } from 'assets/icons/office.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';

import { AccessibleText } from 'styled/Global';
import QuickSearch from 'connected/QuickSearch/QuickSearch';
import {
  QuickSearchOnResultItemClick,
  QuickSearchResult,
} from 'connected/QuickSearch/types';
import { quickSearchReset } from 'store/actions/quickSearchActions';
import { FORM_BUILDER_COPY } from '../constants';
import { GroupProps } from '../types';

interface IdentifyBuildingsViewProps {
  group: GroupProps;
  onChange: (value: any, label: string, key: string, rules: GroupProps) => void;
  value: any;
}

interface SelectedBuildingProps {
  id: string;
  address: string;
  onRemoveClick: (id: string) => void;
}

const Section = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.default};
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.h4`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colorPalette.text.dark};
`;

const SelectedBuildingItem = styled.li`
  background: ${(props) => props.theme.colorPalette.secondary.backgroundGrey};
  padding: 10px 10px 10px 36px;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.lightGrey};
  border-radius: 4px;
  font-size: ${(props) => props.theme.typography.paragraph.small};
  color: ${(props) => props.theme.colorPalette.text.dark};
  font-family: ${(props) => props.theme.fonts.secondary};
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RemoveSingleButton = styled.button`
  position: relative;
`;

const OfficeIcon = styled(Office)`
  width: 15px;
  height: 15px;
  fill: ${(props) => lighten(0.2, props.theme.colorPalette.secondary.darkGrey)};
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
`;

const DeleteIcon = styled(Delete)`
  width: 20px;
  height: 20px;
  fill: ${(props) => lighten(0.2, props.theme.colorPalette.secondary.darkGrey)};
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const RemoveAllButton = styled(SectionTitle)<{
  confirmRemove: boolean;
}>`
  text-decoration: underline;
  text-transform: uppercase;

  ${(props) =>
    props.confirmRemove &&
    css`
      color: ${props.theme.colorPalette.secondary.red};
    `}
`;

const SelectedBuilding: React.FC<SelectedBuildingProps> = ({
  id,
  address,
  onRemoveClick,
}) => (
  <SelectedBuildingItem>
    <OfficeIcon />
    <span>{address}</span>
    <RemoveSingleButton type="button" onClick={() => onRemoveClick(id)}>
      <AccessibleText>Remove</AccessibleText>
      <DeleteIcon />
    </RemoveSingleButton>
  </SelectedBuildingItem>
);

const IdentifyBuildingsView: React.FC<IdentifyBuildingsViewProps> = ({
  onChange,
  group,
  value,
}) => {
  const defaultSelectedBuildings =
    (value && value.map((item: any) => JSON.parse(item.building.value))) || [];
  const dispatch = useDispatch();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [selectedBuildings, setSelectedBuildings] = useState<
    QuickSearchResult[]
  >(defaultSelectedBuildings);
  const removeAllTimeout = useRef<number | null>(null);

  const onResultItemClick: QuickSearchOnResultItemClick = (payload) => {
    const building = payload.entity!;
    const allBuildings = [...selectedBuildings, building];
    const selectedNoDupeBuildings = Array.from(
      new Set(allBuildings.map((o) => o.id)),
    ).reduce((accum, id) => {
      const entity = allBuildings.find((o) => o.id === id);
      return entity ? [...accum, entity] : accum;
    }, [] as QuickSearchResult[]);

    setSelectedBuildings(selectedNoDupeBuildings);
    dispatch(quickSearchReset(true));
  };

  const onSelectedItemRemoveClick = (id: string) => {
    const updatedBuildings = selectedBuildings.filter((o) => o.id !== id);

    setSelectedBuildings(updatedBuildings);
  };

  const removeAllSelected = () => {
    if (!confirmRemove) {
      setConfirmRemove(true);
      return;
    }

    setSelectedBuildings([]);
    setConfirmRemove(false);

    if (removeAllTimeout.current) {
      clearTimeout(removeAllTimeout.current);
    }
  };

  useEffect(() => {
    if (confirmRemove) {
      removeAllTimeout.current = setTimeout(() => {
        setConfirmRemove(false);
      }, 5000);
    }
  }, [confirmRemove]);

  useEffect(() => {
    const payload = selectedBuildings.map((buildingDetails) => ({
      building: {
        label: 'Address',
        value: JSON.stringify(buildingDetails),
      },
    }));

    onChange(payload, group.sourceLabel!, group.groupId, group);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuildings]);

  return (
    <div>
      <Section>
        <SectionTitle>
          {FORM_BUILDER_COPY.portfolioIdentifyBuildings.searchTitle}
        </SectionTitle>
        <QuickSearch
          searchIsActive
          showSummary={false}
          isSingleSearchType
          onResultItemClick={onResultItemClick}
          hideCloseButton
          disableNonCanonicalResults
          formEmbed
          resetDetails={false}
        />
      </Section>

      {selectedBuildings.length > 0 && (
        <Section>
          <SectionHeader>
            <SectionTitle>
              {FORM_BUILDER_COPY.portfolioIdentifyBuildings.selectedTitle}
            </SectionTitle>
            <RemoveAllButton
              as="button"
              type="button"
              onClick={removeAllSelected}
              confirmRemove={confirmRemove}
            >
              {confirmRemove
                ? FORM_BUILDER_COPY.portfolioIdentifyBuildings.confirmRemove
                : FORM_BUILDER_COPY.portfolioIdentifyBuildings.removeAll}
            </RemoveAllButton>
          </SectionHeader>
          <ul>
            {selectedBuildings.map((building) => (
              <SelectedBuilding
                key={building.id}
                address={building.address}
                id={building.id}
                onRemoveClick={onSelectedItemRemoveClick}
              />
            ))}
          </ul>
        </Section>
      )}
    </div>
  );
};

export default IdentifyBuildingsView;
