import { Option } from 'components/Forms/types';

export const createLinesToInsertOptions = () => {
  const options: Option[] = [];

  for (let i = 1; i <= 10; i += 1) {
    options.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  return options;
};
