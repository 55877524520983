import { GlobalApiFailError } from 'store/actions/types';

import {
  DebtsActionTypes,
  DebtDeleteAction,
  DeleteDebtPayload,
  DebtDeleteApiFailAction,
  DebtDeleteSuccessAction,
  AddDebtPayload,
  AddDebtAction,
  AddDebtApiFailAction,
  AddDebtSuccessAction,
  EditDebtAction,
  EditDebtApiFailAction,
  EditDebtSuccessAction,
  EditDebtPayload,
} from 'connected/DebtPanel/types';

export const addDebt = (payload: AddDebtPayload) => {
  const action: AddDebtAction = {
    type: DebtsActionTypes.ADD_DEBT,
    payload,
  };

  return action;
};

export const addDebtApiFail = (
  payload: GlobalApiFailError,
): AddDebtApiFailAction => ({
  type: DebtsActionTypes.ADD_DEBT_API_FAIL,
  payload,
});

export const addDebtApiSuccess = (): AddDebtSuccessAction => ({
  type: DebtsActionTypes.ADD_DEBT_API_SUCCESS,
});

export const editDebt = (payload: EditDebtPayload) => {
  const action: EditDebtAction = {
    type: DebtsActionTypes.EDIT_DEBT,
    payload,
  };

  return action;
};

export const editDebtApiFail = (
  payload: GlobalApiFailError,
): EditDebtApiFailAction => ({
  type: DebtsActionTypes.EDIT_DEBT_API_FAIL,
  payload,
});

export const editDebtApiSuccess = (): EditDebtSuccessAction => ({
  type: DebtsActionTypes.EDIT_DEBT_API_SUCCESS,
});

export const debtsDelete = (payload: DeleteDebtPayload) => {
  const action: DebtDeleteAction = {
    type: DebtsActionTypes.DELETE_DEBT,
    payload,
  };

  return action;
};

export const debtsDeleteApiFail = (
  payload: GlobalApiFailError,
): DebtDeleteApiFailAction => ({
  type: DebtsActionTypes.DELETE_DEBT_API_FAIL,
  payload,
});

export const debtsDeleteApiSuccess = (): DebtDeleteSuccessAction => ({
  type: DebtsActionTypes.DELETE_DEBT_API_SUCCESS,
});
