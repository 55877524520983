/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext } from 'react';
import { BoldEmphasized } from 'styled/Global';
import { FormStateContext } from '../context';
import { ConfirmParagraph } from './ConfirmChangesStyled';
import { IDENTIFY_ADDRESSES_COPY } from './constants';

const MoveSaleConfirm: React.FC = () => {
  const contextFormState = useContext(FormStateContext);

  return (
    <ConfirmParagraph>
      {IDENTIFY_ADDRESSES_COPY.confirm.moveSales}
      <BoldEmphasized>
        {contextFormState.formState.moveRecord.identifyAddress.value.address ||
          contextFormState.formState.moveRecord.identifyAddress.value
            .buildingAddress}
      </BoldEmphasized>
    </ConfirmParagraph>
  );
};

export default MoveSaleConfirm;
