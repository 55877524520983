import React from 'react';
import { BoldEmphasized } from 'styled/Global';
import { QUICK_SEARCH_COPY } from '../constants';
import { ResultsSummaryContainer } from './SearchResultsStyled';

interface SearchResultsSummaryProps {
  total: number;
}

const SearchResultsSummary: React.FC<SearchResultsSummaryProps> = ({
  total,
}: {
  total: number;
}) => {
  const { displaying, results } = QUICK_SEARCH_COPY.resultsSummary;
  const resultsText = total === 1 ? results.singular : results.plural;

  return (
    <ResultsSummaryContainer>
      {displaying.lessThanCap}
      <BoldEmphasized>{` ${total} `}</BoldEmphasized>
      {resultsText}
    </ResultsSummaryContainer>
  );
};

export default SearchResultsSummary;
