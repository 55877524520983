/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Vertical } from 'globalConstants';
import omit from 'ramda/src/omit';
import isEmpty from 'ramda/src/isEmpty';

import {
  FilterPanelActionTypes,
  FilterActions,
} from 'store/actions/filterPanelActions';
import { SortDirections, SortTypes } from 'components/Table/types';
import { MapActions, MapActionTypes } from 'pages/QueryBuilder/types';
import {
  VerticalPrimaryUse,
  LatLngQueryParam,
  DEFAULT_SORT_KEYS,
  DEFAULT_SORT_TYPE,
  DEFAULT_SORT_DIRECTION,
} from '../types/searchQuery';
import {
  FILTER_ACTION_TYPES,
  SORT_PAGE_ACTION_TYPES,
  SearchQueryActions,
  QUERY_URL_ACTION_TYPES,
} from '../actions/searchQuery';

import {
  constructLocationStateFromCircle,
  constructLocationStateFromPolygon,
  constructLocationStateFromCorridor,
} from './helpers/searchQuery';

interface SingleFilter {
  [paramKey: string]: any;
}

export interface SearchQueryState extends SingleFilter {
  vertical?: Vertical;
  primaryUse?: VerticalPrimaryUse;
  'sales.isPortfolio'?: boolean;
  location?: LatLngQueryParam;
  locationRadius?: string;
  locationPath?: string[];
  locationPolyline?: string[];
  boundaryId?: string;
  limit?: string;
  page?: number;
  sortKey?: string;
  sortDirection?: SortDirections;
  sortType?: SortTypes;
  selectedUserQuery?: string;
}

export const permanentSearchQueryKeys: (keyof SearchQueryState)[] = [
  'vertical',
  'primaryUse',
  'sales.isPortfolio',
  'location',
  'locationRadius',
  'locationPath',
  'locationPolyline',
  'locationBuffer',
  'boundaryId',
  'sortDirection',
  'sortKey',
  'sortType',
  'pageSize',
  'page',
  'selectedUserQuery',
];

export const initialState: SearchQueryState = {
  vertical: Vertical.Lease,
  sortDirection: SortDirections.DESC,
  sortKey: DEFAULT_SORT_KEYS[Vertical.Lease],
  sortType: DEFAULT_SORT_TYPE[Vertical.Lease],
  pageSize: '15',
  page: 0,
};

const searchQueryReducer = (
  state = initialState,
  action: SearchQueryActions | MapActions | FilterActions,
): SearchQueryState => {
  switch (action.type) {
    case FILTER_ACTION_TYPES.SET_VERTICAL: {
      const newState = {
        ...initialState,
        vertical: action.payload,
        sortType: DEFAULT_SORT_TYPE[action.payload],
        sortKey: DEFAULT_SORT_KEYS[action.payload],
        sortDirection: DEFAULT_SORT_DIRECTION[action.payload],
      };

      if (action.payload === Vertical.PortfolioSale) {
        newState.vertical = Vertical.Sale;
        newState.primaryUse = VerticalPrimaryUse.All;
        newState['sales.isPortfolio'] = true;
      }

      return newState;
    }

    case FILTER_ACTION_TYPES.SET_PRIMARY_USE: {
      return {
        ...state,
        primaryUse: action.payload,
      };
    }

    case FilterPanelActionTypes.ADD_SINGLE_FILTER:
    case SORT_PAGE_ACTION_TYPES.UPDATE_PAGINATION: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case FilterPanelActionTypes.REMOVE_SINGLE_FILTER:
    case FilterPanelActionTypes.REMOVE_FILTER_KEYS: {
      const keysToRemove = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      const retainedQuery = omit(keysToRemove, state);

      return {
        ...retainedQuery,
      };
    }

    case SORT_PAGE_ACTION_TYPES.UPDATE_SORT: {
      return {
        ...state,
        ...action.payload,
        page: 0,
      };
    }

    case MapActionTypes.MAP_FETCH_BOUNDARY_API_SUCCESS: {
      const { center, boundaryId } = action.payload;
      const { locationRadius, locationPolyline, locationPath, ...rest } = state;
      const locationState = {
        location: [center.lat, center.lng],
        boundaryId,
      };

      return {
        ...rest,
        ...locationState,
      };
    }

    case MapActionTypes.MAP_ADD_SHAPE: {
      const { location, locationRadius, locationPath, ...rest } = state;
      const { circle, polygon, corridor } = action.payload;
      let locationState: any = {};

      if (action.contextIgnore) return state;

      if (circle && !isEmpty(circle)) {
        locationState = constructLocationStateFromCircle(circle);
      }

      if (polygon) {
        locationState = constructLocationStateFromPolygon(polygon);
      }

      if (corridor) {
        locationState = constructLocationStateFromCorridor(corridor);
      }

      return {
        ...rest,
        ...locationState,
      };
    }

    case MapActionTypes.MAP_CIRCLE_MOVE_END: {
      const locationState = constructLocationStateFromCircle(action.payload);

      return {
        ...state,
        ...locationState,
      };
    }

    case MapActionTypes.MAP_POLYGON_EDIT: {
      const locationState = constructLocationStateFromPolygon(action.payload);

      return {
        ...state,
        ...locationState,
      };
    }

    case MapActionTypes.SELECT_DRAW_TOOL: {
      const {
        location,
        locationRadius,
        locationPath,
        locationPolyline,
        boundaryId,
        ...rest
      } = state;
      return {
        ...rest,
        location: [],
      };
    }

    case QUERY_URL_ACTION_TYPES.SET_QUERY_FROM_URL: {
      return {
        ...initialState,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default searchQueryReducer;
