export type SectionProps = {
  sectionLabel: string;
  sectionType: FilterCategories;
  sectionFilters: FilterProps[];
};

export enum FilterCategories {
  DOCUMENT_CATEGORY = 'fileType',
  LEASE_DATE_CATEGORY = 'leaseDate',
  LEASE_DISPUTED_CATEGORY = 'disputed',
  LEASE_USE_CLAUSE_CATEGORY = 'leaseUseClause',
  LEASE_STATUS_CATEGORY = 'leaseStatus',
  RECORD_VISIBILITY = 'recordVisibility',
  STATUS = 'status',
  DEBT_TYPE = 'debtType',
  DEBT_ROLE = 'debtRole',
  EPC_STATUS = 'epcStatus',
  EPC_TYPE = 'epcType',
}

export type SelectedFilterProps = {
  type: string;
  filters: string[];
};

export type FilterProps = {
  value: string;
  label: string;
};

export type SelectedGroupFilters = { [groupKey: string]: string[] };

export type FilterComponentProps = {
  selectAll: () => void;
  clearAll: () => void;
  toggleFilter: (filter: string, type: string) => void;
  filterCategories: SectionProps[];
  selectedFilters?: SelectedGroupFilters;
};

export type FilterCheckboxesProps = {
  sectionType: FilterCategories;
  sectionFilters: FilterProps[];
  toggleFilter: (filter: string, type: string) => void;
  filterCategories: SectionProps[];
  selectedFilters?: SelectedGroupFilters;
  sectionLabel?: string;
};
