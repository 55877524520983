export const DETAILS_PAGE_COPY = {
  sale: {
    statusBar: 'Status',
  },
  lease: {
    statusBar: 'Status',
  },
  navLinks: {
    summary: 'Summary',
    toLet: 'To Let',
    stackingPlan: 'Stacking Plans',
    accommodationSchedule: 'Accommodation Schedule',
    leases: 'Tenancy Schedule',
    investments: 'Investments',
    buildingDetails: 'Building Details',
    documents: 'Documents',
    relatedParties: 'Related Parties',
    jobHistory: 'Job History',
    notes: 'Notes',
    auditLog: 'Audit Log',
    toLetDetails: 'To Let Details',
    saleDetails: 'Sale Details',
    propertyMap: 'Property Map',
    propertyBuildings: 'Portfolio Buildings',
    propertyInvestments: 'Property Interests',
    companyDetails: 'Company Details',
    companyStructure: 'Company Structure',
    debts: 'Debt Details',
    valuations: 'Valuations',
    epcCertificates: 'EPCs',
  },
};

export const PANELS = {
  SUMMARY: 'summary',
  TO_LET: 'to-let',
  STACKING_PLAN: 'stacking-plan',
  ACCOMMODATION_SCHEDULE: 'accommodation-schedule',
  LEASES: 'leases',
  INVESTMENTS: 'investments',
  BUILDING_DETAILS: 'building-details',
  DOCUMENTS: 'documents',
  RELATED_PARTIES: 'related-parties',
  JOB_HISTORY: 'job-history',
  NOTES: 'notes',
  AUDIT_LOG: 'audit-log',
  COMPANY_STRUCTURE: 'company-structure',
  LEASE_DETAILS: 'lease-details',
  TO_LET_DETAILS: 'to-let-details',
  SALE_DETAILS: 'sale-details',
  PORTFOLIO_BUILDINGS_MAP: 'portfolio-buildings-map',
  PORTFOLIO_BUILDINGS: 'portfolio-buildings',
  COMPANY_DETAILS: 'company-details',
  PROPERTY_OWNERSHIP: 'property-ownership',
  DEBTS: 'debts',
  VALUATIONS: 'valuations',
  EPC_CERTIFICATES: 'epc-certificates',
};

export const ATHENA_API_SEARCH_URL_PARAMETERS = {
  KEY: 'sortKey',
  TYPE: 'sortType',
  DIRECTION: 'sortDirection',
  OWNER: 'buildings.occupiedByOwner',
  USE: 'primaryUse',
  NAME: 'tenantName',
  ID: 'tenantId',
  OP_ID: 'tenantId.op',
  VERTICAL: 'vertical',
  LOCATION: 'location',
  LOCATION_PATH: 'locationPath',
  LANDLORD_NAME: 'buildingLandlordName',
  LANDLORD_ID: 'buildingLandlordId',
  LANDLORD_ID_OP: 'buildingLandlordId.op',
  PAGE_SIZE: 'pageSize',
  PAGE: 'page',
  PORTFOLIO_ID: 'portfolioId',
  POSTCODE: 'buildings.postcode',
  ASC: 'ASC',
  DESC: 'DESC',
  INCLUDE_CHILDREN_OP: 'include_children',
};
