export const GOOGLE_MAP_COPY = {
  show: 'Show on map',
  zoomToArea: 'Zoom to area',
  loadingMap: 'Loading...',
  error: 'Map Error...',
};

export const CLUSTER_MARKER_IMG =
  'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

// Zoom / Miles
export const CORRIDOR_ZOOM_MAP = new Map<number, number>([
  [1, 100],
  [2, 100],
  [3, 50],
  [4, 30],
  [5, 20],
  [6, 20],
  [7, 10],
  [8, 6],
  [9, 3],
  [10, 2],
  [11, 1],
  [12, 1],
  [13, 0.5],
  [14, 0.2],
  [15, 0.2],
  [16, 0.2],
  [17, 0.15],
  [18, 0.1],
  [19, 0.1],
  [20, 0.1],
  [21, 0.1],
  [22, 0.1],
]);

export const CORRIDOR_MAX_SCALE = new Map<number, number>([
  [1, 500],
  [2, 500],
  [3, 250],
  [4, 180],
  [5, 100],
  [6, 100],
  [7, 50],
  [8, 30],
  [9, 15],
  [10, 10],
  [11, 5],
  [12, 5],
  [13, 2.5],
  [14, 1.5],
  [15, 0.5],
  [16, 0.3],
  [17, 0.25],
  [18, 0.2],
  [19, 0.2],
  [20, 0.2],
  [21, 0.1],
  [22, 0.1],
]);

export const CORRIDOR_MIN_SCALE = new Map<number, number>([
  [1, 50],
  [2, 50],
  [3, 25],
  [4, 18],
  [5, 10],
  [6, 10],
  [7, 5],
  [8, 3],
  [9, 1.5],
  [10, 1],
  [11, 0.5],
  [12, 0.5],
  [13, 0.25],
  [14, 0.15],
  [15, 0.05],
  [16, 0.05],
  [17, 0.05],
  [18, 0.05],
  [19, 0.02],
  [20, 0.02],
  [21, 0.02],
  [22, 0.02],
]);

export enum MapTypeIds {
  GoogleRoadMap = 'roadmap',
  Terrain = 'terrain',
  OSM = 'OSM',
  Satellite = 'satellite',
  Hybrid = 'hybrid',
}

export enum MapTypeLabels {
  GoogleRoadMap = 'Google Roadmap',
  Terrain = 'Terrain',
  OSM = 'OSM Roadmap',
  Satellite = 'Satellite',
  Hybrid = 'Hybrid',
}
