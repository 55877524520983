import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add, format } from 'date-fns';

import {
  globalModalHide,
  globalModalShow,
} from 'store/actions/globalModalActions';
import { FORM_FLOWS } from 'connected/FormFlow/constants';
import { FormFlowConfigs } from 'connected/FormFlow/types';
import EditRecordForm from 'connected/EditModals/EditRecordForm';
import { getUserProfileQueryResults } from 'store/selectors/userProfileSelectors';

import { SCHEDULE_QUERY_COPY } from 'pages/Profile/QueryPanel/constants';
import { getUserQuery } from 'pages/Profile/QueryPanel/helpers';
import {
  ScheduleTeamQueryPayloadType,
  scheduleTeamQuery,
} from 'store/actions/scheduleTeamQueryActions';
import { MODAL_ID } from 'connected/GlobalModal/constants';
import configs from './scheduleTeamQuery.json';

interface ScheduleTeamQueryProps {
  recordId: string;
}

const constructPreloadedState = () => {
  const today = new Date();
  const defaultValue = format(add(today, { months: 6 }), 'yyyy-MM-dd');

  return {
    scheduleQuery: {
      endDate: {
        value: defaultValue,
      },
      earliestEndDate: {
        value: Date.now(),
      },
    },
  };
};

const ScheduleTeamQuery: React.FC<ScheduleTeamQueryProps> = ({ recordId }) => {
  const dispatch = useDispatch();
  const userQueries = useSelector(getUserProfileQueryResults);
  const currentQuery = getUserQuery(userQueries, recordId);

  const initialFormState = useMemo(() => {
    return { ...constructPreloadedState() };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const constructFormPayload = (formState: any) => {
    const payload: ScheduleTeamQueryPayloadType = {
      userQueryId: recordId,
      repeatFrequency: formState.repeatFrequency.value,
      trigger: formState.trigger.value,
      hourToSend: parseInt(formState.hourToSend.value, 10),
      endDate: formState.endDate.value,
      usersIds: formState.usersIds.value,
    };

    dispatch(scheduleTeamQuery(payload));
    dispatch(globalModalHide(FORM_FLOWS.SCHEDULE_TEAM_QUERY));
    dispatch(globalModalShow(MODAL_ID.SCHEDULE_TEAM_QUERY_RESULT));
  };

  return (
    <EditRecordForm
      recordId={recordId}
      copy={SCHEDULE_QUERY_COPY}
      onSubmission={constructFormPayload}
      formId={FORM_FLOWS.SCHEDULE_QUERY}
      configs={configs as FormFlowConfigs}
      initialFormState={initialFormState}
      formTitle={currentQuery?.queryName}
    />
  );
};

export default ScheduleTeamQuery;
